import { useRef, useEffect, useState } from 'react';
import { Button, Input, Modal, message } from 'antd';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { get } from '../../Utils/helpers';
import { logEvent } from '../../Service/LogEvent';
import { EVENT_TYPES } from '../../Utils/constants';

function Download({ download }: any) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const { show_id, selectPackage, isModalOpen, setIsModalOpen, authValue, getPassword, buttonLoader, setRefreshState, refreshState } = download;
  const password_required = get(selectPackage, 'passwordRequired', false);
  const [receivedPassword, setPasswordRetrieved] = useState(false);
  const { key, recipient_doc_id } = selectPackage;
  useEffect(() => {
    passwordRef.current?.focus();
  });
  const handleOk = async () => {
    try {
      setDownloadLoading(true);
      const passwordValue = get(passwordRef, 'current.input.value', '');
      let password = '';
      if (passwordValue !== '') {
        password = passwordValue;
      }
      console.log('passwordValue', password);
      const downloadPayload = {
        api: api.massive.download,
        urlParam: key,
        payLoad: JSON.stringify({
          show_id,
          password,
          recipient_doc_id
        }),
      };
      const downloadResult = await backend.save(
        downloadPayload,
        get(authValue, 'accessToken', ''),
      );

      const link: any = document.createElement('a');
      link.href = downloadResult;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleSuccess();
    } catch (error:any) {
      const {errMessage, data, errCode } = error;
      const packageName = selectPackage.package_name;
      const senderName = selectPackage.sender_name;
      const fileDownloadDetails: any = {
        show_id,
        packageName,
        senderName,
        senderID: get(authValue, 'uid', ''),
      };
      fileDownloadDetails.errorMessage = errMessage;
      console.log("FILE_DOWNLOAD_DETAILS:",fileDownloadDetails)
      const fileDownloadFailureEvent = logEvent(
        get(authValue, 'accessToken', ''),
        EVENT_TYPES.FILE_DOWNLOAD_FAILURE,
        fileDownloadDetails,
      );
      console.log('FileUpload:', fileDownloadFailureEvent);
      handleError(error);
    }
    finally {
      setDownloadLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDownloadLoading(false);
    setPasswordRetrieved(false);
    if(password_required){
      const passwordHtml = get(passwordRef, 'current.input', '');
      passwordHtml.value = '';
    }
  };

  const handleSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Your file has been successfully downloaded.',
    });
    setTimeout(() => handleCancel(), 0);
    setRefreshState(!refreshState);
  };

  const handleError = (error: any) => {
    const { errMessage, data, errCode } = error;
    if (errCode === 41107) {
      setErrorMessage(errMessage);
    } else if (errCode === 41108) {
      setErrorMessage(errMessage);
    } else if (errCode === 41110) {
      setErrorMessage(errMessage);
    } else {
      const attemptsLeft = data ? data.attempts_left : null;
      if (attemptsLeft) {
        setErrorMessage(`Password Mismatches, Try again. You have ${attemptsLeft} attempts left.`);
      }
    }
    setTimeout(() => handleCancel(), 4000);
    setRefreshState(!refreshState);
  };

  return (
    <Modal
      className='confirm-download'
      title={password_required ? <span className='Header-pop-up'>These files are password protected.</span> : <span className='Header-pop-up'>Download File.</span>}
      okText='Continue'
      okButtonProps={{ className: 'download-Btn',  disabled: (password_required && !receivedPassword) || !!errorMessage}}
      open={isModalOpen}
      onOk={handleOk}
      confirmLoading={downloadLoading}
      onCancel={handleCancel}
    >
      {contextHolder}
      <div className='modal-content-container'>
        {password_required ? (
          <div className='mailbox-downloader'>
            <p>Enter the password provided by the sender.</p>
            <div className='password-wrapper'>
              <Input.Password
                ref={(element: any) => {
                  if (element) passwordRef.current = element;
                }}
                addonBefore='Password'
                autoComplete="new-password"
                style={{ width: '70%' }}
                onChange={(e) => setPasswordRetrieved(!!e.target.value)}
              />
              <Button type='link' className='get-Pass-Btn' loading={buttonLoader} onClick={() => getPassword(get(selectPackage, 'id', ''))}>
                Get password
              </Button>
            </div>
          </div>
        ) : (
          <div className='mailbox-downloader'>
            <p>Please click on Continue to download </p>
          </div>
        )}
        {errorMessage && (
          <div className='error-Msg-Pop-Up'
          >
            {errorMessage}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default Download;
