import { AxiosInstance } from 'axios';

import { GUEST_TOKEN_STORAGE_KEY, GUEST_TOKEN_HEADER_KEY } from './constants';
import { decrypt, encrypt } from './storageHelpers';

export const getGuestToken = () => {
  const encryptedGuestToken = sessionStorage.getItem(GUEST_TOKEN_STORAGE_KEY);
  return encryptedGuestToken ? decrypt(GUEST_TOKEN_STORAGE_KEY, encryptedGuestToken) : encryptedGuestToken;
}

export const setGuestToken = (guestToken: string) => {
  const encryptedGuestToken = encrypt(GUEST_TOKEN_STORAGE_KEY, guestToken);
  sessionStorage.setItem(GUEST_TOKEN_STORAGE_KEY, encryptedGuestToken);
}

export const setGuestTokenHeader = (axiosInstance: AxiosInstance) => {
  const guestToken = getGuestToken();
  if (guestToken) {
    axiosInstance.defaults.headers.common[GUEST_TOKEN_HEADER_KEY] = guestToken;
  }
}

export const isGuestAuthenticated = () => {
  return Boolean(getGuestToken());
}

export const logOutGuestUser = () =>{
  sessionStorage.removeItem(GUEST_TOKEN_STORAGE_KEY)
}