import React, { useContext, useState } from 'react';
import "./finduser.css";
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { Table, Space, Badge, Dropdown, notification, Button,} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { get, sortTableDate } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import { convertDateTimeFromUTCEpocWithYear } from '../../Utils/utils';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import { ArrowLeftOutlined } from '@ant-design/icons';
import useNavigateToPage from '../../hooks/useNavigateToPage';

interface UserDetails {
  user_name: string;
  user_name_first: string;
  user_name_last: string;
  email: string;
  title: string;
  user_phone: string;
  country_code: string;
  user_status: string;
  last_login_time: any;
  created_ts: any;
}

interface UserActiveShows {
  show_id: any;
  user_id: any;
  show_name: any;
  show_status: any;
  roleType: any;
  show_code: any;
  created_ts: any;
}
interface UserAssociatedStreams {
  stream_id: any;
  stream_name: any;
  created_ts: any;
  stream_label: any;
  show_id: any;
  show_name: any;
  stream_status: any;
  role_type: any;
  role_id: any;
  user_id: any;
  user_entered_stream_label: any;
  created_user_id: any;
}

const FindUser = () => {
  const [inputValue, setInputValue] = useState('');
  const [inputValid, setInputValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [userDetailsData, setUserDetailsData] = useState<UserDetails[]>([]);
  const [showData, setShowData] = useState<UserActiveShows[]>([]);
  const [streamData, setStreamData] = useState<UserAssociatedStreams[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorEmailMessage, setemailErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const navigateToPage = useNavigateToPage();

  const validateInput = (e: { target: { value: any; }; }) => {
    const value = e.target.value;
    setInputValue(value);

    if (value === '') {
      setInputValid(true);
      setErrorMessage('');
      setemailErrorMessage('');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const phonePattern = /^(\+91-|0)?[6-9]\d{9}$/; 

    if (!emailPattern.test(value)) {
      setInputValid(false);
      setErrorMessage('Enter a valid Email');
    } else {
      setInputValid(true);
      setErrorMessage('');
    }
  };
  const handleBack = () => {
    navigateToPage('/superadmin', { state: { navigateToListAllUsersNew: true } });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue) {
      setErrorMessage('Please enter a valid Email');
      return;
    }
    setLoading(true);
    setemailErrorMessage('');
    setFormSubmitted(true);
    try {
      // Fetch user details
      const userDetailsRequest = {
        queryParam: { useremail: inputValue },
        api: api.users.fetchUserDetails,
      };
      const fetchUserDetails: any = await backend.fetch(
        userDetailsRequest,
        get(currentUser, 'user.accessToken', ''),
      );
      console.log('User_data', fetchUserDetails);
      const data = Array.isArray(fetchUserDetails) ? fetchUserDetails : [fetchUserDetails];
      setUserDetailsData(data);

      // Fetch associated shows based on the entered user email
      const userShowsRequest = {
        api: api.shows.getShowsByUserId,
        urlParam: data[0]?.uid,
      };
     
        const userActiveShows = await backend.fetch(
          userShowsRequest,
          get(currentUser, 'user.accessToken', ''),
        );
        console.log('User_shows', userActiveShows);
        
        if (userActiveShows && typeof userActiveShows === 'object') {
          const userActiveShowsArray = Object.values(userActiveShows);
          const userAssociatedShows = userActiveShowsArray.map((show: any) => ({
            show_name: show.show_name,
            show_status: show.show_status,
            roleType: show.roles.map((role: any) => role.roleType).join(', '),
            created_ts: show.created_ts,
            show_code: show.show_code,
            show_id: show.show_id,
            user_id: show.user_id,
          }));
          setShowData(userAssociatedShows); 
        } else {
          setShowData([]);
        }
       // Fetch associated streams based on the entered user email
      const userStreamRequest = {
        api: api.streams.getStreamsByUserId,
        urlParam: data[0]?.uid,
      };
      const userActiveStreams = await backend.fetch(
        userStreamRequest,
        get(currentUser, 'user.accessToken', ''),
      );
      console.log('User_streams', userActiveStreams);

      if (Array.isArray(userActiveStreams) && userActiveStreams.length > 0) {
        const userAssociatedStreams = userActiveStreams.map((stream: any) => ({
          show_id: stream.show_id,
          show_name: stream.show_name,
          stream_id: stream.stream_id,
          stream_name: stream.stream_name,
          stream_label: stream.stream_label,
          created_ts: stream.created_ts,
          stream_status: stream.stream_status,
          role_type: stream.role_type,
          role_id: stream.role_id,
          user_id: stream.user_id,
          user_entered_stream_label: stream.user_entered_stream_label,
          created_user_id: stream.created_user_id,
        }));
        setStreamData(userAssociatedStreams);
      } else {
        setStreamData([]);
      }
    } catch (err: any) {
      console.error(err);
      if (err.errCode === 10204) {
        setemailErrorMessage("User account does not exist");
      } else {
        setemailErrorMessage('Error fetching user details, shows, or streams');
    }
      setUserDetailsData([]);
      setShowData([]);
      setStreamData([]);
    }  finally {
      setLoading(false);
    }
  };

  const renderUserDetails = (userDetails: UserDetails) => {
    const formattedLastLoginTime = userDetails.last_login_time 
      ? convertDateTimeFromUTCEpocWithYear(userDetails.last_login_time._seconds) 
      : '';
    const formattedCreatedTime = userDetails.created_ts 
      ? convertDateTimeFromUTCEpocWithYear(userDetails.created_ts._seconds) 
      : '';

    return (
      <div className="hit-content" key={userDetails.user_name}>
        <div>Title : {userDetails.title}</div>
        <div>Name : {`${get(get(userDetails, "name", ""), "user_name_first", "")} ${get(get(userDetails, "name", ""), "user_name_last", "")}`} </div>
        <div className="hit-name">
          <div className="hit-email">
              Email : {userDetails.email}
          </div>
        </div>
        <div>Phone : {`${get(get(userDetails, "phone", ""), "country_code", "")} ${get(get(userDetails, "phone", ""), "user_phone", "")}`}</div>
        <div>Last Login Time: {formattedLastLoginTime}</div>
        <div>Date Added: {formattedCreatedTime}</div>
        <div>User Status: {userDetails.user_status}</div>
      </div>
    );
  };

  const truncateString = (str: string | any[], length: number) => {
    if (str.length <= length) return str;
    return str.slice(0, length);
  };
  const combinedShowStreamData = showData.map((show) => {
    const associatedStreams = streamData.filter((stream) => stream.show_name === show.show_name);
    return {
      ...show,
      is_showAdmin: get(show,"roleType",'').includes('ShowAdmin'),
      role_type: show.roleType,
      streams: associatedStreams,
    };
  });
  console.log(combinedShowStreamData,'test');
  const expandedRowRender = (record: UserAssociatedStreams & UserActiveShows) => {
    const columns = [
      { title: 'Stream ID', dataIndex: 'stream_id', key: 'stream_id' },
      { title: 'Created User Id', dataIndex: 'created_user_id', key: 'created_user_id' },
      { title: 'User Entered Stream Name', dataIndex: 'user_entered_stream_label', key: 'user_entered_stream_label' },
      { title: 'Stream Status', dataIndex: 'stream_status', key: 'stream_status' },
      { title: 'Stream Name', dataIndex: 'stream_name', key: 'stream_name' },
      { title: 'Stream Label', dataIndex: 'stream_label', key: 'stream_label' },
      { title: 'User Role', dataIndex: 'role_type', key: 'role_type',
        render: (roleType: string) => {
        const filteredRoleTypes = roleType.split(', ').filter(role => role === 'ShowPublisher' || role === 'ShowViewer').join(', ');
        return filteredRoleTypes;
      }},
    ];
  
    const filteredStreamData = streamData
      .filter(stream => stream.show_id === record.show_id)
      .map(stream => {
        const isCreatedUser = stream.created_user_id === record.user_id;
        const hasShowPublisher = record.roleType.includes('ShowPublisher');
        const hasShowViewer = record.roleType.includes('ShowViewer');
  
        let userRole = '';
        if (isCreatedUser && hasShowPublisher && hasShowViewer) {
          // Show both roles if created_user_id and user_id match and both roles are present
          userRole = 'ShowPublisher, ShowViewer';
        } else if (isCreatedUser && hasShowViewer) {
          // Show only ShowViewer role for created user
          userRole = 'ShowViewer';
        } else if (!isCreatedUser && hasShowViewer) {
          // Show ShowViewer role for non-created users
          userRole = 'ShowViewer';
        } else {
          // Exclude other roles or display 'No role'
          userRole = 'No role';
        }
  
        return {
        ...stream,
        stream_name: truncateString(stream.stream_name, 20),
        stream_label: truncateString(stream.stream_label, 20),
        created_user_id: truncateString(stream.created_user_id, 20),
          role_type: userRole
        };
      });
  
    return <Table columns={columns} dataSource={filteredStreamData} pagination={false} className='expanded-box-wrapper'/>;
  };
  // Filter out duplicate show names from streamData
  const getUniqueShowStreams = () => {
    const uniqueStreams = new Map();
    streamData.forEach((stream) => {
      if (!uniqueStreams.has(stream.show_name)) {
        uniqueStreams.set(stream.show_name, stream);
      }
    });
    return Array.from(uniqueStreams.values());
  };
 
  const streamColumns = [
    { title: 'Show Name', dataIndex: 'show_name', key: 'show_name' },
    { title: 'Show Code', dataIndex: 'show_code', key: 'show_code' },
    { title: 'Show ID', dataIndex: 'show_id', key: 'show_id' },
    { title: 'Show Status', dataIndex: 'show_status', key: 'show_status' },
    { title: 'User ID', dataIndex: 'user_id', key: 'user_id' },
    { title: "Created TS", dataIndex: 'created_ts', key: 'created_ts', render: (created_ts: any) => created_ts ? convertDateTimeFromUTCEpocWithYear(created_ts._seconds) : '', },
    { title: "Is ShowAdmin", dataIndex: 'is_showAdmin', key: 'is_showAdmin', render: (is_showAdmin: boolean) => (is_showAdmin ? 'True' : 'False'), },
  ];
  const uniqueStreamData = getUniqueShowStreams();
  return (
    <div className='reports'>
      {/* {formSubmitted && ( 
        <>
          <Button type="text" className='back-button' onClick={handleBack} icon={<ArrowLeftOutlined />}>Back</Button>
        </>
      )}
      )} */}

         <>
          <Button type="text" className='back-button' onClick={handleBack} icon={<ArrowLeftOutlined />}>Back</Button>
        </>

      <p className="reports-text">User Reports: </p>
      <form className='email-form-field' onSubmit={handleSubmit}>
        <label className='email-form-input-label'>Enter User E-mail : </label>
        <input
          className={inputValid === false ? 'form-input error-border' : 'form-input'}
          type='text'
          name='emailOrPhone'
          value={inputValue}
          onChange={validateInput}
        />
        {inputValid === false && <span className='err-msg'>{errorMessage}</span>}
      </form>
      <button type='submit' onClick={handleSubmit} className='submit-button'>Submit</button>
      {errorEmailMessage && <div className='error-message'>{errorEmailMessage}</div>}
      {loading ? (
        <div className="loading-spinner">
          <ProgressLoader loading={loading} />
        </div>
      ) : (
        formSubmitted && userDetailsData.length > 0 && (
          <div>
            <div className='user-details-heading'>User Details : </div>
            <div>
              {userDetailsData.map(userDetails => (
                <ul className='user-details-box' key={userDetails.email}>
                  {renderUserDetails(userDetails)}
                </ul>
              ))}
            </div>
            
            <div className='associated-streams'>
              <div className='streams-table'>
                <p>Associated Streams: </p>
                <Table columns={streamColumns}
                  expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                  dataSource={combinedShowStreamData.map((stream:any , index: any) => ({ ...stream, key: index }))}
                  className="table-bordered"
                />
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FindUser;
