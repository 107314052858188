import { Form, Button, Divider, Spin, notification } from 'antd';
import InputComponent from "../../Components/UIKit/InputComponent";
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import { useContext, useState } from 'react';

interface MfaLoginProps {
  show_id: string;
  setRefreshKey: (value: boolean) => void;
  refreshKey: boolean;
  onMfaLoginSuccess: () => void;
  user_email: string;
  user_password: string;
  user_description: string;
}

const MfaLogin = (props: MfaLoginProps) => {
  const { show_id, setRefreshKey, refreshKey, onMfaLoginSuccess, user_email, user_password, user_description } = props;
  const authValue = useContext(AuthContext);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [email, setEmail] = useState<string>(user_email);
  const [emailValid, setEmailValid] = useState<boolean>(!!user_email);
  const [password, setPassword] = useState<string>(user_password);
  const [passwordValid, setPasswordValid] = useState<boolean>(!!user_password);
  const [description, setDescription] = useState<string>(user_description);
  const [mfaCode, setMfaCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    try {
      setErrorMessage("");
      setLoading(true);
      const { email, description, password, mfaCode } = values;
      const data = {
        api: api.massive.mfaLogin,
        urlParam: show_id,
        payLoad: JSON.stringify({
          email,
          description,
          password,
          mfaCode
        }),
      };
      const result = await backend.save(data, get(authValue, 'currentUser.user.accessToken', ''));
      if (result) {
        setRefreshKey(!refreshKey);
        notification.success({
          message: result,
        });
        clearFormFields();
        onMfaLoginSuccess();
      }
    } catch (err: any) {
      console.error(err);
      setErrorMessage("Login failed! Please check your information and try again.");
    } finally {
      setLoading(false);
    }
  };

  const clearFormFields = () => {
    form.resetFields();
    setErrorMessage("");
  }

  const validateEmail = (value: string) => {
    setEmail(value.trim());
    setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim()));
  };

  const validatePassword = (value: string) => {
    setPassword(value.trim());
    setPasswordValid(!!value.trim());
  };

  const validateDescription = (value: string) => {
    setDescription(value.trim());
  };

  const validateMfaCode = (value: string) => {
    setMfaCode(value.trim());
  };

  const renderForm = () => {
    const disableSubmit = !email || !emailValid || !password || !passwordValid || !description || !mfaCode;
    return (
      <div className='section'>
        <Divider className='section-header-form'>
          <h3>Login with MFA massive users details</h3>
        </Divider>
        <Form
          name='basic'
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 400, margin: '3% 0', width: '500px' }}
          initialValues={{
            email: user_email,
            password: user_password,
            description: user_description,
            remember: true
          }}
          onFinish={onFinish}
          autoComplete='none'
        >
          <div className='right-side-mail-pass'>
            {/* Loader component */}
            {loading && (
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Spin />
              </div>
            )}
            <Form.Item label='Massive Email'
              name='email'
              rules={[
                { required: true, message: <span className="error-message">Please Enter Email</span> },
                {
                  validator: (_, val) => {
                    if (!emailValid) {
                      return Promise.reject(<span className="error-message">Invalid Email Format</span>);
                    }
                    return Promise.resolve();
                  },
                },
              ]} >
              <InputComponent
                maxLength={35}
                key={"email"}
                type="email"
                value={email}
                onChange={validateEmail}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item label='Password' name='password' rules={[
              { required: true, message: <span className="error-message">Please Enter Password</span> }]}>
              <InputComponent
                key={"password"}
                type="password"
                value={password}
                onChange={validatePassword}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item label='Description' name='description' rules={[
              { required: true, message: <span className="error-message">Please Enter Description</span> },
              { max: 35 }
            ]}>
              <InputComponent
                key={"description"}
                type="description"
                value={description}
                onChange={validateDescription}
              />
            </Form.Item>
            <Form.Item label='MFA Code' name='mfaCode' rules={[
              { required: true, message: <span className="error-message">Please Enter MFA Code</span> },
            ]}>
              <InputComponent
                key={"mfaCode"}
                type="text"
                value={mfaCode}
                onChange={validateMfaCode}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 18, offset: 4 }} style={{ marginTop: '8%' }}>
              <div className='create-api-btn'>
                <Button type='primary' htmlType='submit'
                  disabled={disableSubmit || loading}
                  className='create-api-right'>
                  CREATE API
                </Button>
              </div>
            </Form.Item>
            <div className='err-message'>{errorMessage}</div>
          </div>
        </Form>
      </div>
    );
  };

  return renderForm();
};

export default MfaLogin;