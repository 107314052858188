import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useLocationStore } from '../../store/useLocationStore';
import { get } from '../../Utils/helpers';

const RedirectPage = () => {
  const locationState = useLocationStore((state) => state.locationState);
  const isGuest = get(locationState,'state.isGuest',false);

  React.useEffect(() => {
    setTimeout(() => window.location.replace('https://pacpost.com/'), isGuest ? 4000 : 0);
  }, []);

  return (
    <div className='redirect-page-content'>
      <Dimmer active inverted>
        <Loader size='massive' />
      </Dimmer>
    </div>
  );
};

export default RedirectPage;
