import React from "react";
import { Layout } from "../../Components/PageLayout/Layout";
import { PAGES } from "../../Utils/constants";
import useNavigateToPage from "../../hooks/useNavigateToPage";

const StripeFailure = () => {
  const navigateToPage = useNavigateToPage();

  const gotToShowAdmin = () => {
    const show_id = sessionStorage.getItem("show_id");
    const show_code = sessionStorage.getItem("show_code");
    const team_admin = sessionStorage.getItem("team_admin");
    navigateToPage("/showadmin", { state: { show_id, team_admin, show_code } });
  };
  return (
    <Layout page={PAGES.STRIPE}>
      <div style={{ margin: "15% auto", textAlign: "center" }}>
        <h2> Sorry, there is an issue processing your subscription !! </h2>
        <button className="ui violet button" onClick={gotToShowAdmin}>
          Go to Show Admin
        </button>
      </div>
    </Layout>
  );
};

export default StripeFailure;
