import { auth } from "../Firebase";
import api from "../Service/Api";
import backend from "../Service/Backend";
import * as Bowser from 'bowser';

export interface LooseObject {
    [key: string]: any
}

export const logEvent = async (token:string, eventType: string, event?:any, targetUserId?: string, targetUserEmail?: string, ) => {
   
    let log_data: LooseObject = {};

    const browser = Bowser.getParser(window.navigator.userAgent);

    log_data.browser = browser.getBrowser();
    log_data.os = browser.getOS();
    log_data.platform = browser.getPlatform();

    const ipData = {
        endPoint: 'https://ipapi.co/json/'
    };

    let ipInfo = await backend.get(ipData);
    if (ipInfo) {
        log_data.city = ipInfo.data.city;
        log_data.country = ipInfo.data.country_name;
        log_data.regionName = ipInfo.data.region;
        log_data.location = {
            lat: ipInfo.data.latitude,
            lon: ipInfo.data.longitude
        };
        log_data.ip = ipInfo.data.ip;
        log_data.timezone = ipInfo.data.timezone;
        log_data.utc_offset = ipInfo.data.utc_offset;
    } else {
        console.log("couldn't get ip information, sending log_data which ever is present!");
    }

    let payload: LooseObject = {
        user_id: auth.getUserId(),
        user_email: auth.getUserEmail(),
        event_data: log_data,
        event_type: eventType,
        event_ts: new Date(),
        target_user_id: targetUserId,
        target_user_email: targetUserEmail,
    };

    switch (String(eventType.toLowerCase())) {
      case "stream log":
        log_data.streamEvent = event;
        break;
      case "user deletion":
        log_data.deleteUserEvent = event;
        break;
      case "show deletion":
        log_data.deleteShowEvent = event;
        break;
      case "login success": 
        log_data.loginEvent = event;
        break;
      case "show user deletion":
        log_data.deleteShowUserEvent = event;
        break;
      case 'invite show user':
        log_data.inviteShowUserEvent = event;
        break;
      case 'file upload success':
        log_data.fileUploadSuccessEvent = event;
        break;
      case 'file upload failure':
        log_data.fileUploadFailureEvent = event;
        break;
      case 'file download failure':
        log_data.fileDownloadFailureEvent = event;
        break;
      case 'activate user':
        log_data.ActivateUserEvent = event;
        break;
      case 'send passcode':
        log_data.sendPasscodeEvent = event;
        break;    
      default:
        console.error(`Invalid event type: ${eventType}`);
        break;
    }

    const logData = {
        api: api.log.create,
        payLoad: JSON.stringify(payload)
    };
    await backend.save(logData, token);
    return "succesfully logged";
}
