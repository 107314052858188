/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Input, Button, Dropdown, Popup, TextArea } from 'semantic-ui-react';
import settingsImg from '../../../Images/settings.svg';
import clear from '../../../Images/clear.svg';
import editPencil from '../../../Images/pencil.png';
import download_chat from '../../../Images/download_arrow.png';
import sendMsgIcon from '../../../Images/send-chat.svg';
import crossTick from '../../../Images/red-cancel.png';
import greeTick from '../../../Images/green-tick.png';
import GiphyModal from '../GiphyModal';
import ProgressLoader from "../../Common/ProgressLoader";
import { convertDateTimeFromUTCEpoc } from "../../../Utils/utils";
import { auth } from '../../../Firebase';
import { get } from "../../../Utils/helpers";

interface Props {
    isVCHost: boolean,
    user_imageurl: string,
    annotateMessagesRef: any,
    isModalOpen: boolean,
    hideGiphyModal: any,
    handleChatCopy: any,
    annotateChatClearLoading: boolean,
    typingInd: any,
    downloadAnnotateMessages: any,
    VFXcolor: string,
    SFXcolor: string,
    MXcolor: string,
    PIXcolor: string,
    annotateLoading: boolean,
    clearAnnotationChat: any,
    editingAnnotateMessageId: any,
    onChangeAnnotateEditMessage: any,
    annotateInput: string,
    editAnnotateInput: any,
    setEditAnnotateMessage: any,
    setEditingAnnotateMessageId: any,
    setAnnotateEditInput: any,
    pubnub: any,
    channels: any,
    handleAnnotateEditMessageClick: any,
    editAnnotateMessage: any,
    onChangeAnnotateMessage: any,
    sendAnnotationMessage: any,
    setAnnotateInput: any,
    setModalAnnotateColorOpen: any
}

const AnnotationChat: React.FC<Props> = ({ 
    isVCHost, user_imageurl, annotateMessagesRef, isModalOpen, hideGiphyModal,
    handleChatCopy,
    annotateChatClearLoading,
    typingInd,
    downloadAnnotateMessages,
    VFXcolor,
    SFXcolor,
    MXcolor,
    PIXcolor,
    annotateLoading,
    clearAnnotationChat,
    editingAnnotateMessageId,
    onChangeAnnotateEditMessage,
    annotateInput,
    editAnnotateInput,
    setEditAnnotateMessage,
    setEditingAnnotateMessageId,
    setAnnotateEditInput,
    pubnub,
    channels,
    handleAnnotateEditMessageClick,
    editAnnotateMessage,
    onChangeAnnotateMessage,
    sendAnnotationMessage,
    setAnnotateInput,
    setModalAnnotateColorOpen }) => {
    const renderAnnotationChatSettings = () => {
        return (
            <ul className={isVCHost ? '' : 'hidden'}>
                <li>
                    <Dropdown className='chat-settings' pointing='top right' icon={<img src={settingsImg} alt="settings" />}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text='Clear Locators'
                                icon={<img className='clear-image' src={clear} alt="clear" />}
                                onClick={clearAnnotationChat}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        );
    };

    const renderAnnotateMessage = (messageDesc: any, messageIndex: number) => {
        let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        let div = messageDesc?.split(' ').map((part: any) => {
            if (URL_REGEX.test(part)) {
                let hrefUrl = part;
                if (part.startsWith('www')) {
                    hrefUrl = '//' + hrefUrl;
                }

                return (
                    <div>
                        <a key={messageIndex} href={hrefUrl} target='_blank'>
                            {part}
                        </a>
                    </div>
                );
            } else {
                return part + ' ';
            }
        });

        return div;
    };


    const renderAnnotateMessageWithEditButton = (message: any, messageIndex: number) => {
        let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

        let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
        let div = message.description.split(' ').map((part: any) => {
            if (URL_REGEX.test(part)) {
                let hrefUrl = part;
                if (part.startsWith('www')) {
                    hrefUrl = '//' + hrefUrl;
                }

                return (
                    <div>
                        <a key={messageIndex} href={hrefUrl} target='_blank'>
                            {part}
                        </a>
                    </div>
                );
            } else {
                return part + ' ';
            }
        });

        return (
            <div>
                <div id={message.timetoken} className='annote-chat-message-grey-bg'>
                    {div}
                </div>
                <div className='chat-time chat-time-box-parent'>
                    <span>{message.timecode ? message.timecode : formatted_time}</span>
                    {message.annotateNote && (
                        <span className='chat-annote' style={{ backgroundColor: message.annotateColor }}>
                            {message.annotateNote}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const renderEditableAnnotateMessage = (message: any, messageIndex: number) => {
        if (editingAnnotateMessageId === message.timetoken) {
            let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

            let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
            return (
                <div>
                    <TextArea
                        className='editTxtArea'
                        autoFocus
                        placeholder='Send a message here'
                        rows='2'
                        size='small'
                        spellCheck='true'
                        value={editAnnotateInput}
                        onChange={(event, data) => onChangeAnnotateEditMessage(data.value as string)}
                        onKeyPress={(event: any) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                updateAnnotateMessage(editAnnotateInput, message, messageIndex);
                                let hideEdit = document.getElementById('edit_button' + message.timetoken);
                                hideEdit?.removeAttribute('style');
                            } else if (event.key === 'Esc') {
                                event.preventDefault();
                                let hideEdit = document.getElementById('edit_button' + message.timetoken);
                                hideEdit?.removeAttribute('style');
                                renderAnnotateMessage(message.description, messageIndex);
                            }
                        }}
                    />
                    <div className='chat-time'>
                        <span>{message.timecode ? message.timecode : formatted_time}</span>
                        <span className='float-right'>
                            <a
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditAnnotateMessage(false);
                                    let hideEdit = document.getElementById('edit_button' + message.timetoken);
                                    hideEdit?.removeAttribute('style');
                                    setEditingAnnotateMessageId('');
                                    renderAnnotateMessageWithEditButton(message, messageIndex);
                                }}
                            >
                                <img src={crossTick} className='cancelTick-img' alt='cancel' />
                            </a>
                            <a
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    let hideEdit = document.getElementById('edit_button' + message.timetoken);
                                    hideEdit?.removeAttribute('style');
                                    updateAnnotateMessage(editAnnotateInput, message, messageIndex);
                                }}
                            >
                                <img src={greeTick} className='greenTick-img' alt='cancel' />
                            </a>
                        </span>
                        {message.annotateNote && (
                            <span
                                className='chat-annote'
                                style={{
                                    backgroundColor: message.annotateColor,
                                    marginRight: '20px',
                                }}
                            >
                                {message.annotateNote}
                            </span>
                        )}
                    </div>
                </div>
            );
        } else {
            return renderAnnotateMessageWithEditButton(message, messageIndex);
        }
    };

    const updateAnnotateMessage = async (input: any, message: any, messageIndex: any) => {
        const user = auth.getUser();

        let hideEdit = document.getElementById('edit_button' + message.timetoken);
        hideEdit?.removeAttribute('style');
        const uid = get(user, 'uid', '');
        const displayName = get(user, 'displayName', '');
        await pubnub.publish({
            channel: channels[1],
            message: {
                message_id: message.message_id,
                annotationTitle: message.annotationTitle,
                userDisplay: displayName,
                description: input,
                userid: uid, // Needed for old msgs as they dont have publisher id
                imageurl: user_imageurl,
                annotateNote: message.annotateNote,
                annotateColor: message.annotateColor,
                timecode: message.timecode,
                usecase: 'update',
                deleted: false,
                is_update: true,
            },
        });

        await pubnub.signal({
            channel: channels[1],
            message: `typing_off:${displayName}`,
        });

        setAnnotateEditInput('');
        renderAnnotateMessage(input, messageIndex);
    };

    return (
        <div className='avid-locators-panel-wrapper'>
            <nav className='main-menu-right-chat' id='annotate-chat-tray'>
                <ProgressLoader loading={annotateChatClearLoading} size='small' />
                <div className='chat-h1'>
                    <span className='chat-text'>Locators for Avid</span>
                    {renderAnnotationChatSettings()}
                </div>

                <div className='chat-content'>
                    {annotateMessagesRef.current.map((message: any, messageIndex: number) => {
                        let editAnnotateMessageIndex = annotateMessagesRef.current.findIndex(
                            (item: any) => item.timetoken === editingAnnotateMessageId,
                        );

                        if (editAnnotateMessageIndex > -1) {
                            setTimeout(function () {
                                var chatTray = document.getElementById('annotate-chat-tray');
                                var currScrollHeight = chatTray?.scrollHeight || 0;
                                var scrollHeight: number =
                                    currScrollHeight *
                                    (editAnnotateMessageIndex / annotateMessagesRef.current.length);
                                chatTray?.scrollTo(0, scrollHeight - 50);
                            }, 250);
                        } else {
                            setTimeout(function () {
                                var chatTray = document.getElementById('annotate-chat-tray');
                                var currScrollHeight = chatTray?.scrollHeight || 0;
                                chatTray?.scrollTo(0, currScrollHeight);
                            }, 250);
                        }

                        let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

                        let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);

                        if (message.publisher === auth.getUserId()) {
                            // Loggedin user
                            let style = {};
                            if (user_imageurl) {
                                style = {
                                    background:
                                        'transparent url("' + user_imageurl + '") 0% 0% no-repeat padding-box',
                                };
                            }
                            return (
                                <div className='chat-list-item' key={`message-${messageIndex}`}>
                                    <div className='chat-message-body'>
                                        <div className='chat-heading'>
                                            {message.annotationTitle}
                                            <div className='chat-message-hover-tools-row'>
                                                <div className='chat-message-tool-icon-container'>
                                                    <Popup
                                                        content='Copy To Clipboard'
                                                        trigger={
                                                            <span
                                                                className='duplicate-icon'
                                                                onClick={() => handleChatCopy(message)}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div className='chat-message-tool-icon-container'>
                                                    <a
                                                        id={'edit_button' + message.timetoken}
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            handleAnnotateEditMessageClick(
                                                                message.timetoken,
                                                                message.description,
                                                            )
                                                        }
                                                    >
                                                        <img src={editPencil} className='editPencil-img' alt='Edit' />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {!editAnnotateMessage &&
                                            renderAnnotateMessageWithEditButton(message, messageIndex)}
                                        {editAnnotateMessage && renderEditableAnnotateMessage(message, messageIndex)}
                                    </div>
                                </div>
                            );
                        } else {
                            // Other users
                            let style = {};
                            if (message.imageurl) {
                                const profile_Image_url = message.imageurl;
                                style = {
                                    background:
                                        'transparent url("' + profile_Image_url + '") 0% 0% no-repeat padding-box',
                                };
                            }
                            return (
                                <div className='chat-list-item' key={`message-${messageIndex}`}>
                                    <div className='chat-image' style={style}></div>
                                    <div className='chat-message-body'>
                                        <div className='chat-heading'>
                                            {message.annotationTitle}
                                            {message?.file ? null : (
                                                <div className='float-right'>
                                                    <Popup
                                                        content='Copy To Clipboard'
                                                        position='top right'
                                                        trigger={
                                                            <span
                                                                className='duplicate-icon'
                                                                onClick={() => handleChatCopy(message)}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className='chat-message-white-bg'>
                                            {renderAnnotateMessage(message.description, messageIndex)}
                                            <div className='chat-time'>
                                                <span>{message.timecode ? message.timecode : formatted_time}</span>
                                                {message.annotateNote && (
                                                    <span
                                                        className='chat-annote'
                                                        style={{ backgroundColor: message.annotateColor }}
                                                    >
                                                        {message.annotateNote}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                <div className='annotation-chat-footer'>
                    <div className='annotation-chat-msg-input'>
                        <Input
                            className='sendTxt'
                            // icon={<Icon name="smile outline" link />}
                            placeholder='Type annotation here...'
                            size='small'
                            spellCheck='true'
                            value={annotateInput}
                            onChange={(e) => onChangeAnnotateMessage(e.target.value)}
                            onKeyPress={(event: any) => {
                                if (event.key === 'Enter') {
                                    const annotateInputText = annotateInput;
                                    setAnnotateInput('');
                                    event.preventDefault();
                                    if (annotateInputText.length > 0)
                                        sendAnnotationMessage(annotateInputText, '', '');
                                }
                            }}
                        >
                            <input />

                            <Button
                                color='violet'
                                floated='right'
                                className='send-chat-btn'
                                size='medium'
                                onClick={(e) => {
                                    e.preventDefault();
                                    sendAnnotationMessage(annotateInput, '', '');
                                }}
                            >
                                <img src={sendMsgIcon} alt="send message" />
                            </Button>
                        </Input>
                    </div>
                    <div className='annotation-chat-btn'>
                        <ProgressLoader loading={annotateLoading} size='small' />
                        <button
                            className='annotation-chat-color-btn'
                            style={{ backgroundColor: VFXcolor }}
                            onClick={(e) => {
                                e.preventDefault();
                                sendAnnotationMessage(annotateInput, 'VFX Note', VFXcolor);
                            }}
                        >
                            VFX
                        </button>
                        <button
                            className='annotation-chat-color-btn'
                            style={{ backgroundColor: SFXcolor }}
                            onClick={(e) => {
                                e.preventDefault();
                                sendAnnotationMessage(annotateInput, 'SFX Note', SFXcolor);
                            }}
                        >
                            SFX
                        </button>
                        <button
                            className='annotation-chat-color-btn'
                            style={{ backgroundColor: MXcolor }}
                            onClick={(e) => {
                                e.preventDefault();
                                sendAnnotationMessage(annotateInput, 'MX Note', MXcolor);
                            }}
                        >
                            MX
                        </button>
                        <button
                            className='annotation-chat-color-btn'
                            style={{ backgroundColor: PIXcolor }}
                            onClick={(e) => {
                                e.preventDefault();
                                sendAnnotationMessage(annotateInput, 'PIX Note', PIXcolor);
                            }}
                        >
                            PIX
                        </button>

                        <Button
                            circular
                            style={{ marginLeft: '10px' }}
                            color='grey'
                            onClick={(e) => {
                                e.preventDefault();
                                setModalAnnotateColorOpen(true);
                            }}
                        >
                            Color
                        </Button>

                        <a
                            className='cursor-pointer download_tc'
                            onClick={(e) => {
                                e.preventDefault();
                                downloadAnnotateMessages();
                            }}
                        >
                            <img className='' src={download_chat} alt='Download' />
                        </a>
                    </div>
                    <div className='chat-typing-indicator'>{typingInd}</div>
                </div>
                {isModalOpen && (
                    <div>
                        <GiphyModal hideGiphyModal={hideGiphyModal}></GiphyModal>
                    </div>
                )}
            </nav>
        </div>
    );
}

export default AnnotationChat