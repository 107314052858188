import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './Components/App/Index';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './Context/authContext';
import { initDataZoom } from './dataZoom';
const { REACT_APP_SENTRY_DSN, REACT_APP_DATA_ZOOM_CONFIG_ID } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});
initDataZoom(REACT_APP_DATA_ZOOM_CONFIG_ID);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={<div className='not-found'>Sorry there was a problem !</div>}
    showDialog
  >
    <AuthProvider>
      <App />
    </AuthProvider>
    
  </Sentry.ErrorBoundary>
);
serviceWorker.unregister();
