import React, { useState, useEffect, memo, ChangeEvent } from 'react';
import { List, Skeleton, Input, Switch, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import InfiniteScroll from 'react-infinite-scroll-component';
import Algolia from '../../Algolia/Algolia';
import { get } from '../../../Utils/helpers';
import useCommandChannel from '../hooks/useCommandChannel';
import { useWatermarkStore } from '../../../store/slices/viewer/watermarkSlice';

const { Search } = Input;
const { REACT_APP_ALGOLIA_USER_INDEX_NAME } = process.env;

const WatermarkViewerPanelHOC = (props: any) => {
  const {
    showId,
    watermarkDetails,
    // setWatermarkDetails,
    // dataTrackRef,
    zmClient,
    updateWatermarkHandler,
    enableAllGuests,
    setEnableAllGuests,
    isGuestInviteIncluded ,
  } = props;
  const { user } = watermarkDetails;
  const [switchState, setSwitchState] = useState({} as any);
  const [list, setList] = useState([] as any);
  const [searchText, setSearchText] = useState('' as string);
  const {sendChunksViaCommandChannel} = useCommandChannel();
  const {setWatermarkDetails} = useWatermarkStore();

  useEffect(() => {
    if (list.length) {
      const switchStateVal: any = {};
      list.forEach((item: any) => {
        switchStateVal[item?.user_id] = get(user, `${item?.user_id}`, true);
      });
      setSwitchState(switchStateVal);
    }
  }, [list, watermarkDetails]);
  const config = {
    algoliaIndex: REACT_APP_ALGOLIA_USER_INDEX_NAME, // Algolia Index Mandatory
    hitsPerPage: 10000, // No of records per page
    filters: `show_id:${showId} AND role_type:ShowViewer`, // Algolia Filters
    setDataList: setList, // Temp set list of hits value
    dataList: list, // Algolia list of hits value,
    refresh: true,
    algoliaLoading: false,
  };

  const onSearch = (val: ChangeEvent<HTMLInputElement> | string) => {
    const searchString = typeof val === 'string' ? val : val?.target?.value;
    setSearchText(searchString);
  };

  const handleViewers = async (checked: boolean, userId: string) => {
    try {
      const watermark: any = {
        ...watermarkDetails,
        user: {
          ...user,
          ...switchState,
        },
      };
      const switchStateVal: any = {
        ...switchState,
        [userId]: checked,
      };
      watermark.user[userId] = checked;
      setSwitchState(switchStateVal);
      setWatermarkDetails(watermark);
      const result = await updateWatermarkHandler(watermark);
      sendChunksViaCommandChannel(watermark,'watermark');
      // zmClient?.getCommandClient().send(JSON.stringify({ watermark }));
    } catch (err: any) {
      const msg = ' Error while storing watermark values !';
      console.error(msg);
    }
  };
  const changeAll = async (event: CheckboxChangeEvent) => {
    const switchStateValue: any = {};
    for (const all in switchState) {
      switchStateValue[all] = event.target.checked;
    }
    setSwitchState(switchStateValue);
    const watermark: any = {
      ...watermarkDetails,
      user: {
        ...user,
        ...switchStateValue,
      },
    };
    setWatermarkDetails(watermark);
    const result = await updateWatermarkHandler(watermark);
    sendChunksViaCommandChannel(watermark,'watermark');
    
    // zmClient?.getCommandClient().send(JSON.stringify({ watermark })).catch((err:any)=>{
    //   console.log("::::::error",err)
    // });
  };

  const switchStateKeys = Object.keys(switchState);

  const sortList = (a: any, b: any) => {
    const nameA = `${a?.user_name?.user_name_first} ${a?.user_name?.user_name_last}`.toUpperCase();
    const nameB = `${b?.user_name?.user_name_first} ${b?.user_name?.user_name_last}`.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const handleEnableAllGuestsCheck = async (event: CheckboxChangeEvent) => {
    const guestAll = event.target.checked;
    setEnableAllGuests(guestAll);
    const watermark: any = {
      ...watermarkDetails,
      enableAllGuests: guestAll,
    };
    setWatermarkDetails(watermark);
    const result = await updateWatermarkHandler(watermark);
    sendChunksViaCommandChannel(watermark,'watermark');
    // zmClient?.getCommandClient().send(JSON.stringify({ watermark }));
  };

  const ViewerPanel = () => {
    const filterData = !!searchText
      ? list
          .filter(
            (item: any) =>
              `${item?.user_name?.user_name_first} ${item?.user_name?.user_name_last}`
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              `${item?.user_email}`.toLowerCase().includes(searchText.toLowerCase()),
          )
          .sort(sortList)
      : list.sort(sortList);
    return (
      <div id='scrollableDiv'>
        <List className='demo-loadmore-list header-list'>
          <List.Item
            actions={[
              <Checkbox
                indeterminate={
                  switchStateKeys.some((all: any) => !!switchState[all]) ===
                  switchStateKeys.some((all: any) => !switchState[all])
                }
                checked={switchStateKeys.every((all: any) => !!switchState[all])}
                onChange={changeAll}
              />,
            ]}
          >
            <List.Item.Meta title={`Enable All Viewers`} />
          </List.Item>
          {!!isGuestInviteIncluded && (
            <List.Item
              actions={[
                <Checkbox
                  checked={get(watermarkDetails, 'enableAllGuests', false)}
                  onChange={handleEnableAllGuestsCheck}
                />,
              ]}
            >
              <List.Item.Meta title={`Enable All Guests`} />
            </List.Item>
          )}
        </List>
        <InfiniteScroll
          dataLength={filterData.length}
          next={() => {}}
          hasMore={true}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget='scrollableDiv'
        >
          <List
            className='demo-loadmore-list'
            loading={!list.length}
            itemLayout='horizontal'
            dataSource={filterData}
            locale={{ emptyText: ' No Data Found' }}
            renderItem={(item: any) => (
              <List.Item
                actions={[
                  <Switch
                    checked={switchState[item?.user_id]}
                    onChange={(checked: boolean) => handleViewers(checked, item?.user_id)}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<div className='chat-image'></div>}
                  title={`${item?.user_name?.user_name_first} ${item?.user_name?.user_name_last}`}
                  description={item?.user_email}
                />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className='watermark-viewer-panel'>
      <Search
        placeholder='Search Viewer'
        value={searchText}
        onSearch={onSearch}
        onChange={onSearch}
      />
      {Algolia(ViewerPanel, { config })}
    </div>
  );
};

export default memo(WatermarkViewerPanelHOC);
