import React, { useState,useEffect } from "react";
import { Room } from "twilio-video";
import useGuestUsers from "./hooks/useGuestUsers";
import useGuestViewerCommands from "./hooks/useGuestViewerCommands";
import useToggle from '../../Utils/useToggle';
import ConfirmationDialog from '../ConfirmationDialog/Index';

interface GuestUsersListProps {
  room?: Room;
  auto_allow: boolean;
  guestInviteCount:Number;
  setRedIcon:(value: boolean) => void;
  setGuestStatusCheck:(value: boolean) => void;
  guestStatusCheck:boolean;
}

const GuestUsersList = (props: GuestUsersListProps) => {
  const { room , guestInviteCount,guestStatusCheck } = props;
  const [updated, setUpdated] = useState(false);
  const { remove, allow } = useGuestViewerCommands(updated, setUpdated, room);
  const guests = useGuestUsers(room);
  const [
    removeConfirmationOpen,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmationData
  ] = useToggle<{ displayName: string; identity: string }>();

  const changeState = () => {
    props.setGuestStatusCheck(!props.guestStatusCheck);
  };
  
  return (
    <>
      <div className="guest-viewer-url-container">
        <div className="guest-viewer-url-container-item">
          <div className="guest-users-list-title">Guests:</div>
          </div>
          <div className="guest-viewer-url-container-item">({guests.length} / {guestInviteCount})</div>
        </div>
      
      {guests.map(
        //({ allowed, displayName, identity }) => {
        ({ displayName, identity, sid }) => {
          let allowedGuest: any = sessionStorage.getItem("allowedGuest") || {};
          allowedGuest =
            typeof allowedGuest === "string"
              ? JSON.parse(allowedGuest)
              : allowedGuest;
          const allowed = allowedGuest[identity]?.status === "allowed"
          const status = allowed ? "Active" : "Waiting";
          return (
            <div className="guest-viewer-url-container" key={identity}>
              <div className="guest-viewer-url-container-item">
                <div>
                  <div>{`${displayName}`}</div>
                  <div>
                    Status:&nbsp;<i>{status}</i>
                  </div>
                </div>
              </div>
              <div className="guest-viewer-url-container-item" onClick={changeState}>
                <div className="guest-user-remove-btn-wrapper">
                  {Boolean(allowed) && (
                    <button
                     key={identity}
                      className="guest-user-list-remove-btn"
                      onClick={() => showRemoveConfirmation.withData({
                        displayName,
                        identity,
                      })}
                    >
                      Remove
                    </button>
                  )}
                  {!allowed && (
                    <button
                    key={identity}
                      className="guest-user-list-remove-btn"
                      onClick={() => allow(identity, sid)}
                    >
                      Allow
                    </button>
                  )}
                </div>

                <div className="guest-user-status-circle">
                  <div
                    className={
                      !allowed
                        ? "guest-user-status-waiting"
                        : "guest-url-status-active"
                    }
                  ></div>
                </div>
              </div>
            </div>
          );
        }
      )}

      <ConfirmationDialog 
        open={removeConfirmationOpen}
        onClose={hideRemoveConfirmation}
        onConfirm={() => {
          confirmationData?.identity && remove(confirmationData?.identity);
          hideRemoveConfirmation();
        }}
        onCancel={hideRemoveConfirmation}
        title={`Remove ${confirmationData?.displayName}`}
        message="Are you sure you want to remove this guest?"
      />
    </>
  );
};

export default GuestUsersList;
