import { Modal, Drawer } from 'antd';
import { get } from '../../Utils/helpers';
import MasvLeftSection from './MasvLeftSection';
import './styles.css';
import { useContext, useEffect, useState } from 'react';
import MasvRightSection from './MasvRightSection';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { AuthContext } from '../../Context/authContext';

interface MasvPopupProps {
  masvShowDetails: {};
  drawerOpen: boolean;
  setDrawerOpen: (arg: boolean) => void;
}

const MasvPopup = (props: MasvPopupProps) => {
  const { drawerOpen, setDrawerOpen, masvShowDetails } = props;
  const [openKeys, setOpenKeys] = useState(['register']);
  const [status, setStatus] = useState(['loader']);
  const [apiList, setApiList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(false);
  const show_id = get(get(masvShowDetails, 'values', ''), 'objectID', '');
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  useEffect(() => {
    const getApiList = async () => {
      try {
        const data = {
          api: api.massive.listapikeys,
          urlParam: show_id,
        };
        const result = await backend.fetch(data, get(authValue, 'accessToken', ''));
        setApiList(result);
        if (result.length) {
          setOpenKeys(['createKeys']);
        } else {
          setOpenKeys(['register'])
        }
      } catch (err: any) {
        console.error(err);
      } finally {
        setStatus(['complete']);
      }
    };
    if (drawerOpen) {
      getApiList();
    }
  }, [drawerOpen, refreshKey]);

  const renderModalBody = () => {
    switch (status.toString()) {
      case 'loader':
        return <ProgressLoader loading={true} />;
      case 'complete':
        return renderModalContent();
      default:
        return status.toString();
    }
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const renderModalContent = () => {
    return (
      <div className='masv-body-content'>
        <MasvLeftSection setOpenKeys={setOpenKeys} apiList={apiList} />
        <MasvRightSection
          openKeys={openKeys}
          show_id={show_id}
          apiList={apiList}
          refreshKey={refreshKey}
          setRefreshKey={setRefreshKey}
        />
      </div>
    );
  };
  return (
    <Drawer
      height={900}
      onClose={closeDrawer}
      open={drawerOpen}
      placement={'top'}
      destroyOnClose={true}
    >
      <div className='inbox-wrapper'>
        {renderModalBody()}
      </div>
    </Drawer>
  );
};
export default MasvPopup;
