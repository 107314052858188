import React, { useState, useEffect, useRef, useContext } from "react";
import { Layout } from "../PageLayout/Layout";
import { PAGES } from "../../Utils/constants";
import CreateStream from "../../Containers/CreateStream";
import MangeUser from "../../Containers/MangeShowViewers";
import ListStreams from "../../Containers/ListOfPublisherStreams/ListStreams";
import Subscribe from "../../Containers/Subscribe";
import ListOfInvitedViewer from "../../Containers/ListOfInvitedViewer";
import ProgressLoader from "../Common/ProgressLoader";

import CreatePublisherViewer from "../../Containers/ManageShowUsers/CreatePublisherViewer";
import EditPublisherViewer from "../../Containers/ManageShowUsers/EditPublisherViewer";
import ListOfShowUsers from "../../Containers/ListOfShowUsers";
import InvitedUsersList from "../../Containers/InvitedShowUsersList";
import ListOfShowStreams from "../../Containers/ListOfShowStreams";

import { checkAccess } from "../ShowPublisher/DataProvider";
import { getShowDetails, getShowFlagsDetails} from "../ShowAdmin/DataProvider";
import { getSubscriptionList } from "../../Containers/Subscribe/DataHandler";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { ManageShowGroupUsers } from "../../Containers/ManageShowGroup/GroupTab/CardOptions/ManageUsers";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import { useLocationStore } from "../../store/useLocationStore";
import useNavigateToPage from "../../hooks/useNavigateToPage";

const TeamAdmin = () => {
 
  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const show_id = get(locationState,'state.show_id','');
  const streamIdLocation = get(locationState,'state.stream_id','');
  const streamLabel = get(locationState,'state.stream_label','');
  const usersGroupsFlag = get(locationState,'state.usersGroupsFlag',false);

  

  const [loading, setLoading] = useState(false);

  const [teamAdmin, setTeamAdmin] = useState(false);
  const [show_name, setShowName] = useState("");
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [streamupdated, setStreamUpdated] = useState(false);
  const [showCode, setShowCode] = useState("");
  const [stripeFlag, setStripeFlag] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [activeSubscriptionList, setActiveSubscriptionList] = useState([]);

  const [selectedUser, setSelectedUser] = useState({} as any);
  const [userUpdated, setUserUpdated] = useState(false);

  const [manageShowGroupUsersStatus, setManageShowGroupUsersStatus] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({} as any)
  const [showGroupUsers, setShowGroupUsers] = useState([] as any)
  const [showGroup, setShowGroup] = useState([] as any);
  const [isUserGroup, setIsUserGroup] = useState([] as any);

  const [allGroupsUsersList, setAllGroupsUsersList] = useState([] as any[])


  const [page, setPage] = useState("CreatePublisherViewer");

  const showAdminHeaderMenuRef = useRef<any>([]);
  const addMenuToRef = (ele: any, menu: string, index: number) => {
    if (!!ele && !showAdminHeaderMenuRef.current[index]) {
      showAdminHeaderMenuRef.current[index] = {ele, menu};
    }
  }

  useEffect(() => {
    const validateAccess = async () => {
      setLoading(true);
      const publisherShowUserRole = await checkAccess(show_id, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      if (publisherShowUserRole.team_admin) {
        setTeamAdmin(true);
        setLoading(true);
        const show: any = await getShowDetails(show_id, get(currentUser, "user.accessToken", ""));
        const flag: any = await getShowFlagsDetails(show_id, get(currentUser, "user.accessToken", ""));

        if(!!flag && flag.length > 0) {
          setStripeFlag(flag[0].feature_enable);
        }
        setStripeCustomerId(!!show && !!show.stripe_customer_id ? show.stripe_customer_id : "");
        setShowName(!!show && !!show.show_name ? show.show_name : "");
        setShowCode(!!show && !!show.show_code ? show.show_code : "");
        let subList = [];
        if ((!!flag && flag.length > 0 && !!flag[0].feature_enable) && !!show.stripe_customer_id) {
          subList = await getSubscriptionList(show.stripe_customer_id, "active",get(currentUser, "user.accessToken", ""));
          setActiveSubscriptionList(subList);
        }
        setLoading(false);
      } else {
        navigateToPage("/unauthorised");
      }
    };

    if (show_id) {
      validateAccess();
    } else {
      //history.push("/unauthorised");
      navigateToPage("/login")
    }
  }, [show_id]);


  // Get ShowGroup Data
  const fetchShowGroupData = async () => {
    try {
      const getShowGroupListData = {
        api: api.group.listShowGroup,
        queryParam: { show_id: !!show_id && show_id },
      };
      // setLoading(true);
      // call fetch country code data function to get country code data
      const listGroupRes = await backend.fetch(getShowGroupListData, get(currentUser, "user.accessToken", ""));
      if (listGroupRes) {
        setShowGroup(listGroupRes);

      }
    }
    catch (err) {
      console.log(err)
      setLoading(false);
    }
  };


 


  useEffect(() => {

    if (showGroup.length === 0 && show_id) {
      fetchShowGroupData();

    }
  }, [show_id])

  useEffect(() => { 
    if(!!stripeFlag && (!stripeCustomerId || activeSubscriptionList.length === 0)) {
      setPage("subscribe");
    }
  }, [stripeFlag])

  useEffect(() => {
    if (manageShowGroupUsersStatus === true) {
      setPage("ManageShowGroupUser");
      // setSelectedGroup(selectedGroup)
    }
    // else {
    //   setPage("CreatePublisherViewer");
    // }
  }, [manageShowGroupUsersStatus, selectedGroup]);




  const handleNavCreateStreamClick = () => {
    setPage("createStream");
  };

  const handleNavMangeUsersClick = () => {
    setPage("mangeUsers");
  };

  const handleNavListStreamsClick = () => {
    setPage("streamList");
  };

    //fetch list of users filtered by showID and show_viewer role
    const fetchShowGroupUsers = async () => {
      try {
        const input = {
          api: api.group.listUsersByShowId,
          queryParam: { show_id },
        };
        const getShowGroupUsersByShowId = await backend.fetch(input, get(currentUser, "user.accessToken", ""));
  
        setAllGroupsUsersList(getShowGroupUsersByShowId)
      }
      catch (err) {
        console.log(err)
      } finally {
        setLoading(false);
      }
    };

  const renderStreamCreate = () => {
    return (
      <>
        <div className="left-content">
          <div className="create-stream"></div>
        </div>
        <div className="right-content">
          <div className="right-container">
            <CreateStream
              showId={show_id}
              setStreamUpdated={setStreamUpdated}
              teamAdmin={teamAdmin}
              usersGroupsFlag={usersGroupsFlag}
            />
          </div>
        </div>
      </>
    );
  };

  const renderMangeUser = () => {
    return <MangeUser showId={show_id} teamAdmin={teamAdmin} gotoCreateStream={handleNavCreateStreamClick} usersGroupsFlag={usersGroupsFlag}/>;
  };

  const renderListStreams = () => {
    return (
      <div className="right-container">
        <ListStreams
          key={Math.random()}
          showId={show_id}
          setStreamUpdated={setStreamUpdated}
          gotoCreateStream={handleNavCreateStreamClick}
          teamAdmin={teamAdmin}
          usersGroupsFlag={usersGroupsFlag}
        />
      </div>
    );
  }; 

  const handleEditUserClick = (selectedUser: any) => {
    if (selectedUser) {
      setSelectedUser(selectedUser);
    }
    setPage("EditPublisherViewer");
  };

  const renderCreateEditUser = () => (
    <>
     <div className={usersGroupsFlag ? "left-nav left-nav-new show-admin-left-nav" : "left-nav"}>
          <div className={usersGroupsFlag ? "vertical-menu vertical-menu-new" : "vertical-menu"}>
          <InvitedUsersList
           showGroupUsers={showGroupUsers}
           showGroup={showGroup}
           fetchShowGroupData={fetchShowGroupData}
            showId={show_id}
            setManageShowGroupUsersStatus={setManageShowGroupUsersStatus}
            manageShowGroupUsersStatus={manageShowGroupUsersStatus}
            setSelectedGroup={setSelectedGroup}
            invitedUsers={invitedUsers}
            setInvitedUsers={setInvitedUsers}
            selectedUser={selectedUser}
            showAdminEditUserClick={handleEditUserClick}
            userUpdated={userUpdated}
            setUserUpdated={setUserUpdated}
            handleNavCreateUserClick={() => {
              setSelectedUser({})
              setPage("CreatePublisherViewer")
            }}
            allGroupsUsersList={allGroupsUsersList}
            usersGroupsFlag={usersGroupsFlag}
          />
        </div>
      </div>
      {page === "CreatePublisherViewer" ? (
        <div className="right-form-container">
          <div className="right-show-admin-createuser">
            <CreatePublisherViewer
              showGroup={showGroup}
              fetchShowGroupData={fetchShowGroupData}
              manageShowGroupUsersStatus={manageShowGroupUsersStatus}
              setManageShowGroupUsersStatus={setManageShowGroupUsersStatus}
              showId={show_id}
              invitedUsers={invitedUsers}
              setInvitedUsers={setInvitedUsers}
              showName={show_name}
              setUserUpdated={setUserUpdated}
              setLoading={setLoading}
              fetchShowGroupUsers={fetchShowGroupUsers}
              usersGroupsFlag={usersGroupsFlag}
            />
          </div>
        </div>
      ) : (page === "ManageShowGroupUser" ? (

        <div className="right-form-container-new">
          <div className="right-show-admin-createuser">
            <ManageShowGroupUsers
              setShowGroupUsers={setShowGroupUsers}
              showGroupUsers={showGroupUsers}
              showId={show_id}
              fetchShowGroupData={fetchShowGroupData}
              invitedUsers={invitedUsers}
              setLoading={setLoading}
              setSelectedGroup={setSelectedGroup}
              selectedGroup={selectedGroup}
              manageShowGroupUsersStatus={manageShowGroupUsersStatus}
              fetchShowGroupUsers={fetchShowGroupUsers}
              allGroupsUsersList={allGroupsUsersList}
            />
          </div>
        </div>
      ) : (
        <div className="right-form-container">
          <div className="right-show-admin-createuser">
            <EditPublisherViewer
             listShowGroup={showGroup}
             isUserGroup={isUserGroup}
              showId={show_id}
              invitedUsers={invitedUsers}
              setInvitedUsers={setInvitedUsers}
              selectedUser={selectedUser}
              setUserUpdated={setUserUpdated}
              setLoading={setLoading}
              fetchShowGroupUsers={fetchShowGroupUsers}
              usersGroupsFlag={usersGroupsFlag}
            />
          </div>
        </div>
      )
      )}
    </>
  );

  const renderListUsers = () => (
    <div className="right-container">
      <ListOfShowUsers
        show_id={show_id}
        showAdminEditUserClick={handleEditUserClick}
        setUserUpdated={setUserUpdated}
        userUpdated={userUpdated}
        usersGroupsFlag={usersGroupsFlag}
      />
    </div>
  );

  const renderListAllStream = () => (
    <div className="right-container">
      <ListOfShowStreams show_id={show_id} usersGroupsFlag={usersGroupsFlag}/>
    </div>
  );

  const renderInvitedViewer = () => (
    <div className="right-container">
      <ListOfInvitedViewer show_id={show_id} usersGroupsFlag={usersGroupsFlag}/>
    </div>
  );

  const renderSubscribe = () =>  (!!showCode && <Subscribe
    show_id={show_id}
    show_code={showCode}
    customerId={stripeCustomerId}
    showAdminHeaderMenuRef= {showAdminHeaderMenuRef}
    setStripeCustomerId= {setStripeCustomerId}
    activeSubscriptionList = {activeSubscriptionList}
    usersGroupsFlag={usersGroupsFlag}
  />)

  const renderBodyContent = () => {
    switch (page) {
      case "createStream":
        return renderStreamCreate();
      case "mangeUsers":
        return renderMangeUser();
      case "streamList":
        return renderListStreams();
      case "CreatePublisherViewer":
      case "ManageShowGroupUser":
      case "EditPublisherViewer":
        return renderCreateEditUser();
      case "ListOfInvitedViewer":
        return renderInvitedViewer();
      case "ShowStreamList":
        return renderListAllStream();
      case "subscribe":
        return renderSubscribe();
      default:
        return renderListUsers();
    }
  };

  return (
    <Layout
      page={PAGES.TEAM_ADMIN_PAGE}
      streamId={streamIdLocation}
      showId={show_id}
      activeClassStream={page === "createStream" ? "active" : ""}
      activeClassUser={page === "mangeUsers" ? "active" : ""}
      activeClassListOfStream={page === "streamList" ? "active" : ""}
      showPublisherCreateStreamClick={handleNavCreateStreamClick}
      showPublisherMangeUsersClick={handleNavMangeUsersClick}
      showPublisherListStreamsClick={handleNavListStreamsClick}
      showAdminListClick={() => setPage("ShowUserList")}
      showAdminCreateUserClick={() => setPage("CreatePublisherViewer")}
      showAdminStreamsListClick={() => setPage("ShowStreamList")}
      showInvitedViewerListClick={()=>setPage("ListOfInvitedViewer")}
      showAdminUserEditView={page === "EditPublisherViewer"}
      showAdminSubscribeClick={() => setPage("subscribe")}
      teamAdmin={teamAdmin}
      stream_label={streamLabel}
      currentPage = {page}
      stripeFlag={stripeFlag}
      addMenuToRef= {addMenuToRef}
      usersGroupsFlag={usersGroupsFlag}
    >
      <ProgressLoader loading={loading} />
      {teamAdmin ? renderBodyContent() : <div> </div>}
    </Layout>
  );
};

export default TeamAdmin;
