import React, { useState, useEffect } from "react";
import { Checkbox, CheckboxProps, Dropdown, Button } from "semantic-ui-react";
import {
  Switch,
  Checkbox as AntCheckbox,
  Row,
  Col,
  Slider,
  Tooltip,
} from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { get } from "../../../Utils/helpers";
import { auth } from "../../../Firebase";
import backend from "../../../Service/Backend";

const watermarkchecks = [
  { text: "Name", value: "Name", checked: false },
  { text: "Email", value: "Email", checked: false },
  { text: "DateTime", value: "DateTime", checked: false },
  { text: "IPAddress", value: "IPAddress", checked: false },
  { text: "CustomTexts", value: "CustomTexts", checked: false },
];

const fontsize = [
  { text: "Small", value: "12px" },
  { text: "Normal", value: "14px" },
  { text: "Bold", value: "16px" },
];
export interface LooseObject {
  [key: string]: any;
}
const WatermarkUIPanel = (props: any) => {
  const {
    setWatermarkDetails,
    dataTrackRef,
    updateWatermarkHandler,
    watermarkDetails,
    setProgramaticChange,
    preview,
    setPreview,
    setWatermarkChecks,
    watermarkChecks = watermarkchecks
  } = props;
  let log_data: LooseObject = {};
  let user = auth.getUser();
  let name: any = get(user, "displayName", "");
  let useremail: any = get(user, "email", "");
  let today: any = new Date().toLocaleString();
  const [checks, setChecks] = useState(watermarkchecks);
  const [fontWeight, setFontWeight] = useState(get(watermarkDetails, "styles.fontSize", "12px"));
  const [hexColor, setHexColor] = useState(get(watermarkDetails, "styles.color", "#AE2AFF"));
  const [textInput, setTextInput] = useState("");
  const [displayName, setDisplayName] = useState(name);
  const [email, setEmail] = useState(useremail);
  const [dateTime, setDateTime] = useState(today);
  const [openCustomInput, setOpenCustomInput] = useState(false);
  const [range, setRange] = useState("100");
  const [positionLeftRight, setPositionLeftRight] = useState(10 as number);
  const [positionTopBottom, setPositionTopBottom] = useState(
    get(watermarkDetails, "styles.topValue", 10) as number
  );
  const [scroll, setScroll] = useState(
    get(watermarkDetails, "scroll", false) as boolean
  );
  const [scrollDirection, setScrollDirection] = useState("left");
  const [ipAddress, setIPAddress] = useState("");
  const [cancelLoading, setCancelLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);

  useEffect(() => {
    if (!!watermarkDetails) {
      resetWatermark();
    }
  }, [dataTrackRef]);

  useEffect(() => {
    getWaterMark();
  }, [preview]);

  const resetWatermark = async () => {
    try {
      setCancelLoading(true);
      let watermarkValues_DB = watermarkDetails;
      if (!!watermarkValues_DB.values) {
        const checked_values = watermarkValues_DB.values;
        const keys: any[] = Object.keys(checked_values);
        if (!!keys) {
          checks.forEach((item: any) => {
            item.checked = keys.includes(item.value);
          });
          setChecks(checks);
        }
        setWatermarkChecks(watermarkValues_DB.values);
        setOpenCustomInput(
          watermarkValues_DB.values.CustomTexts ? true : false
        );
        setTextInput(watermarkValues_DB.values.CustomTexts);
        setFontWeight(watermarkValues_DB.styles.fontSize);
        setHexColor(watermarkValues_DB.styles.color);
        const opacity = watermarkValues_DB.styles.opacity.slice(0, -1);
        setRange(opacity);
        setScroll(!!watermarkValues_DB.scroll ? true : false);
        setScrollDirection(watermarkValues_DB.scroll || "left");
        const style_values: any = watermarkValues_DB.styles;
        const style_keys: any[] = Object.keys(style_values);
        if (style_keys.includes("topValue")) {
          setPositionTopBottom(Number(style_values.topValue));
        } else {
          setPositionTopBottom(10);
        }
        if (style_keys.includes("left")) {
          setPositionLeftRight(Number(style_values.left.slice(0, -1)));
        } else {
          setPositionLeftRight(10);
        }
      } else {
        checks.filter((item: any) => {
          item.checked = false;
        });
        setChecks(checks);
        setWatermarkChecks({});
        setTextInput("");
        setOpenCustomInput(false);
        setFontWeight("12px");
        setHexColor("#AE2AFF");
        setRange("100");
        setScrollDirection("left");
        setScroll(false);
        setIPAddress("");
      }
      // await updateWatermarkHandler(!!watermarkValues_DB ? watermarkValues_DB : {});
      if (!!watermarkValues_DB) {
        dataTrackRef.current.send(
          JSON.stringify({ watermark: watermarkValues_DB })
        );
      } else {
        dataTrackRef.current.send(JSON.stringify({ watermark: {} }));
      }
      setCancelLoading(false);
    } catch (err: any) {
      const msg = " Error while resetting the Watermark !";
      console.log(msg);
    } finally {
      setCancelLoading(false);
    }
  };

  const handleIpAddress = async () => {
    const ipData = {
      endPoint: "https://ipapi.co/json/",
    };

    let ipInfo = await backend.get(ipData);
    if (ipInfo) {
      log_data.ip = ipInfo.data.ip;
      setIPAddress(ipInfo.data.ip);
      setWatermarkChecks({
        ...watermarkChecks,
        IPAddress: ipInfo.data.ip,
      });
    } else {
      console.log(
        "couldn't get ip information, sending log_data which ever is present!"
      );
    }
  };

  const handleCheckboxChecked = async (data: any) => {
    const value = data.value;
    const checked: any = data.checked;
    if (checked) {
      if (value === "Name") {
        setDisplayName(name);
        setWatermarkChecks({
          ...watermarkChecks,
          Name: name,
        });
      } else if (value === "Email") {
        setEmail(email);
        setWatermarkChecks({
          ...watermarkChecks,
          Email: email,
        });
      } else if (value === "DateTime") {
        setDateTime(dateTime);
        setWatermarkChecks({
          ...watermarkChecks,
          DateTime: dateTime,
        });
      } else if (value === "IPAddress") {
        await handleIpAddress();
      } else if (value === "CustomTexts") {
        setOpenCustomInput(true);
        setTextInput("");
      }
    } else {
      let newObj = Object.keys(watermarkChecks)
        .filter((key) => key !== value)
        .reduce((acc: any, key) => {
          acc[key] = watermarkChecks[key];
          return acc;
        }, {});
      setWatermarkChecks(newObj);
      if (value === "CustomTexts") {
        setOpenCustomInput(false);
      }
    }
    const newchecks = [...checks];
    const index = newchecks.findIndex((Item: any) => Item.value === data.value);
    newchecks[index].checked = checked;
    setChecks(newchecks);
  };

  const handleCustomeText = (e: any) => {
    const customText = e.target.value;
    if (customText.length > 30) {
      return false;
    }
    setTextInput(customText);
    setWatermarkChecks({
      ...watermarkChecks,
      CustomTexts: customText,
    });
  };

  const renderCheckboxContent = () => {
    return checks.map((data, index) => {
      return (
        <div className="watermark-panel-checkbox" key={index}>
          <Checkbox
            label={data.value}
            value={data.value}
            checked={data.checked}
            onChange={(event, data: CheckboxProps) => {
              handleCheckboxChecked(data);
            }}
          />
        </div>
      );
    });
  };
  const renderInputField = () => {
    return (
      <div>
        <input
          className="watermarkInput-field"
          type="text"
          value={textInput}
          onChange={handleCustomeText}
        />
      </div>
    );
  };
  const fontOnChange = (data: any) => {
    setFontWeight(data);
  };

  const handleScrollOn = (event: any) => {
    const { checked = false } = event.target;
    setPositionLeftRight(10);
    setPositionTopBottom(10);
    setScroll(checked);
  };

  const handleScroll = (checked: boolean) => {
    setScrollDirection(!!checked ? "right" : "left");
  };

  const getWaterMark = async (apply:boolean = false) => {
    let watermark: any = {
      ...watermarkDetails
    };
    if (Object.keys(watermarkChecks).length > 0) {
      watermark = {
        values: {
          ...watermarkChecks,
        },
        styles: {
          fontSize: fontWeight,
          color: hexColor,
          opacity: range + "%",
          display: preview ? "block" : "none"
        },
      };
      if (scroll) {
        watermark.scroll = scrollDirection;
        watermark.styles = {
          width: "100%",
          top: "45%",
          zIndex: 1,
          ...watermark.styles,
        };
      } else {
        watermark.styles = {
          zIndex: 3,
          width: "300px",
          left: `${positionLeftRight}%`,
          topValue: positionTopBottom,
          wordWrap: "break-word",
          top: `calc(${positionTopBottom}% - 50px)`,
          ...watermark.styles,
        };
      }
    }else {
      watermark.values = {}
    }
    //tocheck
    if (!!get(watermarkDetails, "user", false)) {
      watermark.user = watermarkDetails.user;
    }
    if (!!get(watermarkDetails, "enableAllGuests", false)) {
      watermark.enableAllGuests = watermarkDetails.enableAllGuests;
    }
    if(apply)setWatermarkDetails(watermark);
    return watermark;
  };

  const applyChanges = async () => {
    try {
      setProgramaticChange(true);
      setApplyLoading(true);
      const watermark = await getWaterMark(true);
      dataTrackRef.current.send(JSON.stringify({ watermark }));
      setApplyLoading(false);
      await updateWatermarkHandler(watermark);
    } catch (err: any) {
      const msg = " Error while storing watermark values !";
      console.log(msg);
    } finally {
      setApplyLoading(false);
    }
  };

  const renderFontSize = () => {
    return (
      <Dropdown
        placeholder="Select"
        className="form-input watermark-dropdown"
        fluid
        search
        selection
        options={fontsize}
        value={fontWeight}
        onChange={(e, data) => {
          fontOnChange(data.value);
        }}
      />
    );
  };

  const renderCssChanges = () => {
    const formatter: any = (value: number) => `${value}%`;

    return (
      <form className="ui form">
        <Row>
          <Col span={12}>
            <label className="watermark-label">Font Size</label>
          </Col>
          <Col span={12}>
            <label className="watermark-label">Color</label>
          </Col>
        </Row>
        <Row>
          <Col span={12}>{renderFontSize()}</Col>
          <Col span={8}>
            <input
              type="text"
              readOnly={!!hexColor}
              value={hexColor}
              className="color-hex-value"
              onChange={(e) => setHexColor(e.target.value)}
            />
          </Col>
          <Col span={4}>
            <input
              type="color"
              onChange={(e) => setHexColor(e.target.value)}
              className="color-picker"
              value={hexColor ? hexColor : "#AE2AFF"}
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <label className="watermark-label">Opacity</label>
          </Col>
        </Row>
        <Row className="watermark-row">
          <Col span={20}>
            <Slider
              onChange={(value: number) => setRange(`${value}`)}
              value={Number(range)}
              tooltip={{ formatter }}
            />
          </Col>
          <Col span={2} offset={1}>
            <span className="watermark-slider-range-label">{range}%</span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label className="watermark-label">Location</label>
          </Col>
        </Row>
        <Row className="watermark-row">
          <Col span={3}>
            <span className="watermark-slider-range-label">Left</span>
          </Col>
          <Col span={17}>
            <Slider
              min={0}
              max={50}
              disabled={scroll}
              onChange={(value: number) => setPositionLeftRight(value)}
              value={Number(positionLeftRight)}
              tooltip={{ formatter }}
            />
          </Col>
          <Col span={3} offset={1}>
            <span className="watermark-slider-range-label">Right</span>
          </Col>
        </Row>
        <Row className="watermark-row">
          <Col span={3}>
            <span className="watermark-slider-range-label">Top</span>
          </Col>
          <Col span={17}>
            <Slider
              min={8}
              max={85}
              disabled={scroll}
              onChange={(value: number) => setPositionTopBottom(value)}
              value={Number(positionTopBottom)}
              tooltip={{ formatter }}
            />
          </Col>
          <Col span={3} offset={1}>
            <span className="watermark-slider-range-label">Bottom</span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label className="watermark-label">Scroll</label>
          </Col>
        </Row>
        <Row className="watermark-row">
          <Col span={8}>
            <AntCheckbox
              className="watermark-slider-range-label"
              checked={scroll}
              onChange={handleScrollOn}
            >
              Enable
            </AntCheckbox>
          </Col>
          <Col span={16} className="watermark-slider-range-label">
            <Row>
              <Col span={8}>
                <span className="watermark-slider-range-label">Left</span>
              </Col>
              <Col span={8}>
                <Switch
                  checked={scrollDirection === "right"}
                  disabled={!scroll}
                  onChange={handleScroll}
                />
              </Col>
              <Col span={8}>
                <span className="watermark-slider-range-label">Right</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  };
  const waterMarkPreview = () => {
    const eyeStyle = { fontSize: "22px", cursor: "pointer", color: "#FFFFFF" };
    return !preview ? (
      <Tooltip placement="bottom" title={`Show Preview`}>
        <EyeInvisibleFilled style={eyeStyle} onClick={() => setPreview(true)} />
      </Tooltip>
    ) : (
      <Tooltip placement="bottom" title={`Hide Preview`}>
        <EyeFilled
          style={eyeStyle}
          onClick={() => setPreview(false)}
        />
      </Tooltip>
    );
  };

  return (
    <div>
      <Row>
        <Col span={20}>{renderCheckboxContent()}</Col>
        <Col span={4}>{waterMarkPreview()}</Col>
      </Row>

      {!!openCustomInput && renderInputField()}
      {renderCssChanges()}
      <div className="watermark-actions">
        <div className="action-clear-changes" onClick={resetWatermark}>
          <Button loading={cancelLoading}>Cancel</Button>
        </div>
        <div className="action-clear-changes">
          <Button
            onClick={applyChanges}
            loading={applyLoading}
            disabled={applyLoading}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WatermarkUIPanel;
