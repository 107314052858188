import { useState, useEffect, useRef } from 'react';

function OtpResendCounter(props: any) {
  const { resendDisable, setResendDisable } = props;
  const [seconds, setSeconds] = useState(260);
  const timeId: any = useRef();
  const setTimer = () => {
    timeId.current = setInterval(() => {
      setSeconds((prevTime) => prevTime - 1);
    }, 1000);
  };

  useEffect(() => {
      console.log("resendDisable", resendDisable)
    if(resendDisable) {
        setSeconds(260);
        setTimer();
    } else {
      clearInterval(timeId.current)
    }
        
    return () => clearInterval(timeId.current);
  }, [resendDisable]);

  if(seconds === 0) {
      clearInterval(timeId.current);
      setResendDisable(false);
      setSeconds(260);
  }

  return  seconds < 260 ? <span>Resend code in {seconds} seconds</span> : <></>;
}

export default OtpResendCounter;
