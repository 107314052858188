import mixpanel from 'mixpanel-browser';

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  //console.log('Initialise Mixpanel')
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { persistence: "localStorage" });
}


let isActive = process.env.REACT_APP_MIXPANEL_ACTIVE;

let actions = {
  identify: (id: string) => {
    if (isActive === 'true') {
      //console.log('Mixpanel Identify')
      mixpanel.identify(id);
    }
  },
  alias: (id: string) => {
    if (isActive === 'true') mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    if (isActive === 'true') {
      // console.log('Mixpanel Track')
      // console.log(name)
      // console.log(props)
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props: any) => {
      if (isActive === 'true') {
        // console.log('Mixpanel People Props')
        // console.log(props)
        mixpanel.people.set(props);
      }
    },
    set_once: (props: any) => {
      if (isActive === 'true') mixpanel.people.set_once(props);
    },
  },
};

export let Mixpanel = actions;