import React, { useState, useEffect, useContext } from "react";
import { firstBy } from "thenby";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { Button as SemanticButton, Checkbox, CheckboxProps, Confirm, Popup } from "semantic-ui-react";
import { PAGES, SHOWPUBLISHER_PAGES } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import { get } from "../../Utils/helpers";
import { Tabs, Button, Modal, notification, Tooltip } from "antd";
import classNames from "classnames";
import TabPane from "antd/es/tabs/TabPane";
import { UserAddOutlined } from '@ant-design/icons';
import GroupCard from "./GroupCard";
import SearchBox from "../../Components/Common/SearchBox";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import { AuthContext } from '../../Context/authContext';
import ConfirmationModal from "../../Components/UIKit/ConfirmationModal/ConfirmationModal";
import { searchClient } from "../../Service/show";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IInviteViewersProps {
  showId: string;
  activeStream: any;
  refreshUninviteList: (newInvitedUsers: any[]) => void;
  refreshUninviteGroupList: (newInvitedGroups: any[]) => void;
  unInvitedUsersList: any[];
  unInvitedGroupsList: any[];
  teamAdmin: boolean;
  setLoading(loadingStatus: boolean): void;
  loading: boolean;
  usersGroupsFlag?: boolean;
}

const InviteViewers = (props: IInviteViewersProps) => {

  const currentUser = get(useContext(AuthContext), "currentUser", {} );

  const [apiAntd, contextHolder] = notification.useNotification();

  const { unInvitedUsersList = [], unInvitedGroupsList = [], setLoading, loading } = props;
  unInvitedUsersList.sort((a: any, b: any) => get(a, "user_name.user_name_first", "").localeCompare(get(b, "user_name.user_name_first", "")));
  const { REACT_APP_ENABLE_LOGGER = false } = process.env;
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [show_id, setShowId] = useState("");

  const [activeStream, setActiveStream] = useState({} as any);
  const [activeShowUsers, setActiveShowUsers] = useState(unInvitedUsersList);
  const [activeShowGroups, setActiveShowGroups] = useState(unInvitedGroupsList);

  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [selectAllGroupsCheckBox, setSelectAllGroupsCheckBox] = useState(false);

  const [selectedTab, setSelectedTab] = useState("users");

  const [searchText, setSearchText] = useState("");
  const [selectAllUserAlertOpen, setselectAllUserAlertOpen] = useState(false);
  
  useEffect(() => {
    // Mixpanel
    searchClient.clearCache();
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name":
        PAGES.SHOW_PUBLISHER_PAGE +
        "_" +
        SHOWPUBLISHER_PAGES.INVITE_UNINVITE_STREAM,
    });

    if (props.showId) {
      setShowId(props.showId);
      setActiveStream(props.activeStream);

      const unInvitedUsersListArr = [...props.unInvitedUsersList]
      unInvitedUsersListArr.sort((a: any, b: any) => get(a, "user_name.user_name_first", "").localeCompare(get(b, "user_name.user_name_first", "")));
      setActiveShowUsers([...unInvitedUsersListArr]);

      const unInvitedGroupsListArr = [...props.unInvitedGroupsList]
      unInvitedGroupsListArr.sort((a: any, b: any) => get(a, "show_group_name", "").localeCompare(get(b, "show_group_name", "")));
      setActiveShowGroups([...unInvitedGroupsListArr]);
    }
  }, [
    props,
    REACT_APP_ENABLE_LOGGER
  ]);

  useEffect(() => {
    setSelectAllCheckBox(false)
    setSelectAllGroupsCheckBox(false)
  }, [selectedTab])

  //checking/ clearing all checkboxes selection based on select all checkbox for users
  useEffect(() => {
    const users = [...activeShowUsers];
    users.forEach((user: any) => {
      user.isChecked = selectAllCheckBox;
    });
    setActiveShowUsers([...users]);
  }, [selectAllCheckBox])

  //checking/ clearing all checkboxes selection based on select all checkbox for groups
  useEffect(() => {
    const groups = activeShowGroups;
    groups.forEach((group: any) => {
      group.isChecked = selectAllGroupsCheckBox;
    });

    setActiveShowGroups([...groups]);
  }, [selectAllGroupsCheckBox])

  const openNotificationWithIcon = (type: NotificationType, title: any) => {
    apiAntd[type]({
      message: title
    });
  };

  const inviteGroups = async () => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
      let stream_id: string = "";
      let user_entered_stream_label: string = "";
      if (activeStream) {
        stream_id = activeStream.id;
        user_entered_stream_label = activeStream.user_entered_stream_label;
      }
      if (!stream_id) {
        const errMessage = "No active stream found. Can not invite user";
        const err = { errMessage };
        throw err;
      }

      //groups and users in groups
      let groups: any[] = [];
      let checkedGroups: any[] = [];
      activeShowGroups.forEach((group: any) => {
        if (group.isChecked) {
          // groupIDs.push(group.show_group_id);
          // checkedGroups.push(user);
          const groupID: any = group.show_group_id
          const users: any = group.show_group_users.map((user: any) => user.user_id)
          groups.push({
            [groupID]: users
          })
          checkedGroups.push(group)
        }
      });

      if (!groups.length) {
        const errMessage = "Please select groups to invite";
        alert(errMessage);
        const err = { errMessage };
        throw err;
      }

      const input = { show_id, stream_id, show_groups: groups };

      const data = {
        api: api.streams.inviteGroupsToStream,
        payLoad: JSON.stringify(input),
      };
      const callStartTime = new Date();

      setLoading(true);
      const response = await backend.save(data, get(currentUser, "user.accessToken", ""));
      console.log('response :>> ', response);
      setLoading(false);

      const callEndTime = new Date();
      const timeTaken: any = callEndTime.getTime() - callStartTime.getTime();

      const logData = {
        api: api.log.log,
        payLoad: JSON.stringify({
          logFrom: PAGES.SHOW_PUBLISHER_PAGE,
          data: {
            startTime: callStartTime,
            endTime: callEndTime,
            timeTaken: timeTaken,
            api: api.streams.inviteGroupsToStream.endPoint,
            show_id: show_id,
          },
        }),
      };

      if (REACT_APP_ENABLE_LOGGER) {
        backend.save(logData, get(currentUser, "user.accessToken", ""));
      }

      if (response) {
        const message = "Successfully invited the selected groups"
        openNotificationWithIcon('success', message)
        // Refresh Uninvite User List
        checkedGroups.forEach((group: any) => {
          if (group.isChecked) {
            group.isChecked = false;
          }
        });
        props.refreshUninviteGroupList(checkedGroups);
      }

    } catch (err: any) {
      const msg = "Error while pulling data";
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
        openNotificationWithIcon('error', err.errMessage)
      } else {
        setErrorMessage(msg);
        const message = "Error while inviting the selected groups"
        openNotificationWithIcon('error', message)
      }
    } finally {
      setLoading(false);
    }
  }

  const innviteUsers = async () => {
    try {
      setSuccessMessage("");
      setErrorMessage("");

      let stream_id: string = "";
      let user_entered_stream_label: string = "";
      if (activeStream) {
        stream_id = activeStream.id;
        user_entered_stream_label = activeStream.user_entered_stream_label;
      }
      if (!stream_id) {
        const errMessage = "No active stream found. Can not invite user";
        const err = { errMessage };
        throw err;
      }

      let userids: string[] = [];
      let checkedUsers: any[] = [];
      activeShowUsers.forEach((user: any) => {
        if (user.isChecked) {
          userids.push(user.user_id);
          checkedUsers.push(user);
        }
      });

      if (!userids.length) {
        const errMessage = "Please select users to invite";
        alert(errMessage);
        const err = { errMessage };
        throw err;
      }

      const input = { show_id, stream_id, userids: userids.toString() };

      const data = {
        api: api.streams.inviteViewersToStream,
        payLoad: JSON.stringify(input),
      };

      const callStartTime = new Date();

      setLoading(true);
      const response = await backend.save(data, get(currentUser, "user.accessToken", ""));
      console.log(response);
      setLoading(false);

      const callEndTime = new Date();
      const timeTaken: any = callEndTime.getTime() - callStartTime.getTime();

      const logData = {
        api: api.log.log,
        payLoad: JSON.stringify({
          logFrom: PAGES.SHOW_PUBLISHER_PAGE,
          data: {
            startTime: callStartTime,
            endTime: callEndTime,
            timeTaken: timeTaken,
            api: api.streams.inviteViewersToStream.endPoint,
            show_id: show_id,
          },
        }),
      };

      if (REACT_APP_ENABLE_LOGGER) {
        backend.save(logData, get(currentUser, "user.accessToken", ""));
      }

      if (response) {
        Mixpanel.track("Publisher Stream Action", {
          Platform: "Web",
          "Stream Label": user_entered_stream_label,
          "Action Type": "InviteUsers",
        });

        setSelectAllCheckBox(false);

        let msg = "";
        const {
          showStreamUsers,
          usersNotPartOfShow,
          alreadyInvitedUsers,
        } = response;
        if (showStreamUsers && showStreamUsers.length) {
          const message = "Selected users invited successfully"
          openNotificationWithIcon('success', message)

          msg = msg + "Users";
          activeShowUsers
            .filter((activeUser: any) =>
              showStreamUsers.includes(activeUser.user_id)
            )
            .map((user: any) => (msg = msg + `\n${user.user_email}, `));
          msg = msg + " are invited successfully.";
        }
        if (usersNotPartOfShow && usersNotPartOfShow.length) {
          msg = msg + "\nFollowing Users";
          activeShowUsers
            .filter((activeUser: any) =>
              usersNotPartOfShow.includes(activeUser.user_id)
            )
            .map((user: any) => (msg = msg + `\n${user.user_email}, `));
          msg = msg + "are not part of the show.Couldn't invite";
        }
        if (alreadyInvitedUsers && alreadyInvitedUsers.length) {
          msg = msg + "\nFollowing Users are already invited";
          activeShowUsers
            .filter((activeUser: any) =>
              alreadyInvitedUsers.includes(activeUser.user_id)
            )
            .map((user: any) => (msg = msg + `\n${user.user_email}, `));
        }
        setSuccessMessage(msg);
        let remainingUsers = activeShowUsers.filter(
          (user: any) => !userids.includes(user.user_id)
        );
        setActiveShowUsers(remainingUsers);

        // Refresh Uninvite User List
        checkedUsers.forEach((user: any) => {
          if (user.isChecked) {
            user.isChecked = false;
          }
        });
        props.refreshUninviteList(checkedUsers);
      }
    } catch (err: any) {
      const msg = "Error while pulling data";
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
        openNotificationWithIcon('error', err.errMessage)
      } else {
        setErrorMessage(msg);
        const message = "Error while inviting the selected users"
        openNotificationWithIcon('error', message)
      }
    } finally {
      setLoading(false);
      destroyAll();
    }
  };

  const handleAlertCancel = () => {
    setselectAllUserAlertOpen(false);
    setSelectAllCheckBox(false);
  };

  const handleAlertConfirm = async () => {
    setselectAllUserAlertOpen(false);

    setSelectAllCheckBox(true);

    const users = activeShowUsers;
    users.forEach((user: any) => {
      user.isChecked = true;
    });

    setActiveShowUsers([...users]);
  };

  const handleUserSelect = (data: CheckboxProps) => {
    const users = activeShowUsers;
    const checked: any = data.checked;
    if (data.value === "checkAll") {
      if (!props.usersGroupsFlag && checked) {
        //Select All
        setselectAllUserAlertOpen(true);
      } else {
        setSelectAllCheckBox(checked);

        const users = activeShowUsers;
        users.forEach((user: any) => {
          user.isChecked = checked;
        });

        setActiveShowUsers([...users]);
      }

    } else {
      // Single select
      users.forEach((user: any) => {
        if (user.user_id === data.value) {
          user.isChecked = data.checked;
          setSelectAllCheckBox(false);
        }
      });

      setActiveShowUsers([...users]);
    }
  };

  const handleGroupSelect = (data: CheckboxProps) => {
    const groups = activeShowGroups;
    const checked: any = data.checked;
    if (data.value === "checkAll") {
      setSelectAllGroupsCheckBox(checked);

      groups.forEach((group: any) => {
        group.isChecked = checked;
      });

      setActiveShowGroups([...groups]);
    }
    else {
      // Single select
      groups.forEach((group: any) => {
        if (group.show_group_id === data.value) {
          group.isChecked = checked;
          setSelectAllGroupsCheckBox(false);
        }
      });

      setActiveShowGroups([...groups]);
    }
  }

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const renderActiveShowUsersNew = () => {
    const activeShowUsersList = [...activeShowUsers];
    activeShowUsersList.sort(firstBy(function (v: any) { return get(v, "user_name.user_name_first", "").toLowerCase(); }).thenBy(function (v: any) { return get(v, "user_name.user_name_last", "").toLowerCase(); }));
    return activeShowUsersList.map((user: any, index: number) => {
      let checked = false;
      if(user.isChecked) { checked = true}
      return (
        <div className="user-card" key={index}>
          <Tooltip title={user.user_email}>
            <div className="data">
              <b>{user.user_name.user_name_first} {user.user_name.user_name_last}</b>
              <div><span>{user.user_email}</span></div>
            </div>
          </Tooltip>
          <Checkbox
            label=""
            name="options"
            value={user.user_id}
            checked={checked}
            onChange={(event, data: CheckboxProps) => {
              handleUserSelect(data);
            }}
          />
        </div>
      );
    });
  };

  const renderActiveShowGroups = () => {
    return activeShowGroups.map((group: any, index: number) => {
      return (
        <div className="data" key={index}>
          <GroupCard group={group} handleGroupSelect={handleGroupSelect} setActiveShowGroups={setActiveShowGroups} activeShowGroups={activeShowGroups} />
        </div>
      )
    })
  }

  const renderActiveShowUsers = () => {
    const activeShowUsersList = [...activeShowUsers];
    activeShowUsersList.sort(firstBy(function (v: any) { return get(v, "user_name.user_name_first", "").toLowerCase(); }).thenBy(function (v: any) { return get(v, "user_name.user_name_last", "").toLowerCase(); }));
    const filteredUsers = searchText ? activeShowUsersList.filter((item: any) => {
      let phone: any = get(item, "user_phone", "");
      phone = (typeof phone === "string" ? phone : phone.user_phone) || ""; 
      const itemTemp = `${item?.user_name?.user_name_first} ${item?.user_name?.user_name_last} ${phone}`
      if (!itemTemp) {
        return false
      }
      return itemTemp.toLowerCase().includes(searchText.toLowerCase())
    }) : activeShowUsersList;
    if(filteredUsers.length === 0) {
      return <p className="invited-users-search-result">No Data Found</p>
    }
    return  filteredUsers.map((user: any, index: number) => 
         ( <li
            key={user.user_id}
            className="vertical-list"
            data-user_id={user.user_id}
          >
            <Popup
              content={user.user_email}
              size="large"
              trigger={
                <span>
                  <img
                    alt=""
                    src={require("../../Images/logo.png")}
                    className="user-image-icon"
                  />
                  <span className="lm-user-name lm-user-container">
                    {user?.user_name?.user_name_first +
                      " " +
                      user?.user_name?.user_name_last}
                  </span>
                </span>
              }
            />
            <span className="add-checkbox">
              <label className="checkbox-container">
                <Checkbox
                  label=""
                  name="options"
                  value={user.user_id}
                  checked={user.isChecked}
                  onChange={(event, data: CheckboxProps) => {
                    handleUserSelect(data);
                  }}
                />
              </label>
            </span>
            </li> )
      );
  };

  const { confirm } = Modal;

  const destroyAll = () => {
    Modal.destroyAll();
    setSelectAllCheckBox(false);
    setSelectAllGroupsCheckBox(false);
  };

  const showConfirm = () => {
    confirm({
      title: `Are you sure you want to add all the ${selectedTab}?`,
      icon: <></>,
      closable: true,
      centered: true,
      footer: <div className="modal-footer-show-pub">
        <Button onClick={destroyAll}>
          No
        </Button>
        <Button key="submit" type="primary"
          loading={loading}
          onClick={() => {
            destroyAll();
            if (selectedTab === "groups") {
              inviteGroups()
            } else if (selectedTab === "users") {
              innviteUsers()
            }
          }}>
          Yes
        </Button>
      </div>,
    });
  };

  const renderAddToStreamButton = (type: string) => {
    return <div className="select-all select-all-left">
      <Button
        type="primary"
        className={classNames("add-btn")}
        onClick={() => {
          if ((type === "users" && selectAllCheckBox) || (type === "groups" && selectAllGroupsCheckBox)) {
            showConfirm()
          } else {
            if (selectedTab === "groups") {
              inviteGroups()
            } else if (selectedTab === "users") {
              innviteUsers()
            }
          }
        }}
        disabled={(selectedTab === "users" && activeShowUsers.length <= 0) || (selectedTab === "groups" && activeShowGroups.length <= 0)}
      >
        <UserAddOutlined /> Add to Stream
      </Button>
      <Tooltip title="Select All">
        <Checkbox
          className="select-all-cb"
          disabled={type === "users" ? activeShowUsers.length === 0 : activeShowGroups.length === 0}
          value="checkAll"
          checked={type === "users" ? selectAllCheckBox : selectAllGroupsCheckBox}
          onChange={(event, data: CheckboxProps) => {
            if (type === "users") {
              handleUserSelect(data);
            } else if (type === "groups") {
              handleGroupSelect(data)
            }
          }}
        />
      </Tooltip>
    </div>
  }

  return (
    <>
      {
        props.usersGroupsFlag ?
          <div className="">
            {contextHolder}
            <div className="left-container-wrapper">
              <Tabs
                centered
                size="middle"
                defaultActiveKey="users"
                activeKey={selectedTab}
                onChange={(activeKey) => {
                  setSelectedTab(activeKey)
                }}>
                <TabPane
                  tab="USERS"
                  key="users">
                  {renderAddToStreamButton("users")}
                  {renderActiveShowUsersNew()}
                </TabPane>
                <TabPane
                  tab="GROUPS"
                  key="groups">
                  {renderAddToStreamButton("groups")}
                  {renderActiveShowGroups()}
                </TabPane>
              </Tabs>

            </div>
          </div>
          :
          <div className="publisher-invite-users">
            <div className="vertical-header fixed-header-left">
              <SemanticButton
                className="invite-users-to-stream"
                color="violet"
                onClick={innviteUsers}
                disabled={activeShowUsers.length <= 0}
              >
                Add Users To Stream
              </SemanticButton>
              <span className="add-checkbox-header">
                <label className="checkbox-container">
                  <Checkbox
                    label=""
                    disabled={activeShowUsers.length <= 0}
                    value="checkAll"
                    checked={selectAllCheckBox}
                    onChange={(event, data: CheckboxProps) => {
                      handleUserSelect(data);
                    }}
                  />
                </label>
              </span>
            </div>
            <div className="publisher-user-search">
              <SearchBox searchSubmit={handleSearch} searchText={searchText} />
            </div>
            <div className="vertical-menu">
              <ProgressLoader loading={loading} />

              <ul className="padding-left-0 invite-user-left-nav-scroll">{renderActiveShowUsers()}</ul>
            </div>
            <ConfirmationModal
              open={selectAllUserAlertOpen}
              data={"Are you Sure you want to add all"}
              onCancel={handleAlertCancel}
              onOk={handleAlertConfirm}
            />
          </div>
      }
    </>
  );
};
export default InviteViewers;
