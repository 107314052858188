import { Button,Divider } from 'antd';
import './styles.css';
interface MasvLeftSectionProps {
  setOpenKeys: (arg: string[]) => void;
  apiList: never[];
}
const MasvLeftSection = (props: MasvLeftSectionProps) => {
  const { setOpenKeys, apiList } = props;

  return (
    <div className='masv-body-content-left'>
      <div className='left-header'>
        <h3> Massive Onboarding</h3>
      </div>
      <div className='create-api-btn'>
      <Button className='masv-body-content-left-btn'
        onClick={() => {
          setOpenKeys(['register']);
        }}
        disabled={apiList.length !== 0}
        type='primary'
      >
        REGISTRATION
      </Button>
      <br />
      <Button className='masv-body-content-left-btn create-btn-left'
        onClick={() => {
          setOpenKeys(['createKeys']);
        }}
        disabled={apiList.length !== 0}
        type='primary'
      >
        CREATE API
      </Button>
      </div>
    </div>
  );
};
export default MasvLeftSection;
