import React from "react";
import { PAGES } from "../../Utils/constants";
import { Layout } from "../PageLayout/Layout";

const NotFound :React.FC = () => {
  return (
    <Layout page={PAGES.NOT_FOUND_PAGE}>
        <div className="not-found-text">
            Sorry Requested Page Not Found !
        </div>
      
    </Layout>
  );
};
export default NotFound;
