import React, { useEffect, useState } from 'react'
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Tooltip } from 'antd';
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import api from '../../Service/Api';
import backend from '../../Service/Backend';

const { Panel } = Collapse;


const GroupCard = (props: any) => {
    const { group, handleGroupSelect } = props
    const { show_group_users } = group
    const [tabStatus, setTabStatus] = useState(false)
    const [isShowGroupUsers, setIsShowGroupUsers] = useState(show_group_users)

    useEffect(() => {
        setIsShowGroupUsers(show_group_users)
    }, [group])

    const PanelHeader = (visibility: boolean) => {
        return (
            <>
                <b>
                    {group?.show_group_name}
                </b>
                <div className="group-list-header-wrapper">
                    <span>{isShowGroupUsers && isShowGroupUsers?.length} Users</span>
                </div>
            </>
        )
    }

    const cardOption = () => {
        return (
            <Checkbox
            label=""
            name="options"
            disabled={!(!!group.show_group_users && group.show_group_users.length > 0)}
            value={group.show_group_id}
            checked={group.isChecked}
            onChange={(event, data: CheckboxProps) => {
              handleGroupSelect(data);
            }}
          />
        )
    }

    return (
        <div className="group-card-wrapper">
            <Collapse
                collapsible='icon'
                expandIconPosition="left"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel
                    header={PanelHeader(tabStatus)} key="1"
                    extra={cardOption()}
                >
                    <div
                        style={{
                            maxHeight: "180px",
                            overflowY: "auto"
                        }}
                    >
                        {!!isShowGroupUsers && isShowGroupUsers.length > 0 ? isShowGroupUsers.map((user: any) => {
                            // console.log('isShowGroupUsers :>> ', isShowGroupUsers);
                            return (<div className="card-item-wrapper">
                                <p className='first-child'>{user.full_name}</p>
                                <Tooltip title={user.email}>
                                   <p className='last-child'>{user.email}</p>
                                </Tooltip>
                            </div>)
                        }) :
                            (<>   no users </>)
                        }
                    </div>
                </Panel>
            </Collapse>
        </div>
    )
}

export default GroupCard;