
export default function useShareGuestSessions(zmClient: any) {
  // const zmClient = useContext(zoomContext);
  const isEmpty = (object: {}) => !Object.keys(object).length;

  const shareGuestSessions = (sessions: any) => {
    const chunkSize = 1;

    if (isEmpty(sessions)) {
      setTimeout(async () => {
        await zmClient
          ?.getCommandClient()
          .send(JSON.stringify({ allowedGuestSession_end: {} }))
          .then(() => {
            console.log(`Chunk  sent successfully`);
          })
          .catch((error: any) => {
            console.error(`Error sending chunk`, error);
          });
      },100);

      return;
    }

    const splitObjects: Record<string, { status: string }>[] = splitObjectIntoChunksWithKeys(
      sessions,
      chunkSize,
    );

    splitObjects.forEach((chunk, index) => {
      setTimeout(async () => {
        await zmClient
          .getCommandClient()
          .send(JSON.stringify(chunk))
          .then(() => {
            console.log(`Chunk ${chunk} sent successfully`, chunk);
          })
          .catch((error: any) => {
            console.error(`Error sending chunk ${index + 1}`, error);
          });
      }, index * 100);

      console.log(splitObjects);
    });
  };
  const splitObjectIntoChunksWithKeys = <T>(
    obj: Record<string, T>,
    chunkSize: number,
  ): Record<string, T>[] => {
    const entries = Object.entries(obj);
    const result: Record<string, T>[] = [];

    for (let i = 0; i < entries.length; i += chunkSize) {
      const chunk = entries.slice(i, i + chunkSize);
      const chunkObject: Record<string, T> = Object.fromEntries(chunk);

      // Determine if this is the last chunk
      const isLastChunk = i + chunkSize >= entries.length;

      // Assign the appropriate key to the chunk
      const key = isLastChunk
        ? 'allowedGuestSession_end'
        : `allowedGuestSession_${i / chunkSize + 1}`;
      const chunkWithKey: Record<string, T> = { [key]: chunkObject } as Record<string, T>;

      result.push(chunkWithKey);
    }
    return result;
  };

  return { shareGuestSessions };
}
