import React from "react";
import Algolia from "./Algolia";
import Table from "./Table";
import "./AlgoliaTable.css";

const AlgoliaTable = (props: any) => {
  const { config = {} } = props;
  const { columns = [],  sortBy = [], tooltip = "", pageSize = 10 } = config;

  const TableRow = (props: any) => {
    const { data } = props;
    const { hits = [] } = data;

    return (
      <>
        <Table 
          columns = {columns} 
          data = {hits} 
          sortBy = {sortBy}
          tooltip = {tooltip}
          pageSize= {pageSize || 10}  />
      </>
    );
    
  };

  return <> {Algolia(TableRow, props)} </>;
};

export default AlgoliaTable;
