import { useContext } from 'react';
import zoomContext from '../context/zoom-context';

export default function useCommandChannel() {
  const zmClient = useContext(zoomContext);
  const sendChunksViaCommandChannel = (object: {}, keyName: string) => {
    const jsonString = JSON.stringify(object);
    const maxChunkSize = 400;
    const chunks = [];

    for (let i = 0; i < jsonString.length; i += maxChunkSize) {
      chunks.push(jsonString.substring(i, i + maxChunkSize));
    }

    chunks.forEach((chunk, index) => {
      setTimeout(() => {
        const jsonObject = {
          [`${keyName}${index}${index + 1 === chunks.length ? 'end' : ''}`]: chunk,
        };
        
        console.log(jsonObject);
        zmClient
          ?.getCommandClient()
          .send(JSON.stringify(jsonObject))
          .then(() => {
            // console.log(`Chunk ${index + 1} sent successfully`,chunk);
          })
          .catch((error: any) => {
            console.error(`Error sending chunk ${index + 1}`, error);
          });
      }, index * 100); // Delay between chunks to avoid flooding the channel
    });
  };
  return { sendChunksViaCommandChannel };
  
}
