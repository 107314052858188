import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {  Dropdown } from "semantic-ui-react";
import { auth } from "../../Firebase";

import { AUTH_ROUTES } from "../App/AllRoutes";
import { HeaderProps } from "./structs";
import { PAGES, SHOWFINANCE_PAGES } from "../../Utils/constants";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { Mixpanel } from "../../Service/Mixpanel";
import ProgressLoader from "../Common/ProgressLoader";
import { get } from "../../Utils/helpers";
import { Avatar, Dropdown as AntdDropdown, Menu } from "antd";
import trustedDeviceIcon from "../../Images/trusted-device.svg"
import appleTVIcon from "../../Images/Apple icon.png";
import { AuthContext } from '../../Context/authContext';
import ImageCanvas from "../UserSettings/ImageCanvas";
import { useLocationStore } from "../../store/useLocationStore";
import useNavigateToPage from "../../hooks/useNavigateToPage";
import useNavigateToViewer from "../../hooks/useNavigateToViewer";

export const Header = (props: HeaderProps) => {
  const location: any = useLocation();
  const locationState = useLocationStore((state) => state.locationState);
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const navigate = useNavigate();
  const navigateToPage = useNavigateToPage();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [profile_Image_url, setProfileImageURL] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [currentPage, setCurrentPage] = useState("");
  const navigatefromFindUsersPage = get(locationState,'state.navigateToListAllUsersNew',false)
  // Super Admin
  const [superAdminCreateShowActive, setSuperAdminCreateShow] = useState(
    !navigatefromFindUsersPage? "active" : ""
  );
  const [superAdminInviteActive, setSuperAdminInvite] = useState("");
  const [superAdminListShowsActive, setSuperAdminListShows] = useState("");
  const [superAdminListStreamsActive, setSuperAdminListStreams] = useState("");
  const [superAdminListUsersActive, setSuperAdminListUsers] = useState("");
  const [superAdminListUsersNewActive, setSuperAdminListUsersNew] = useState(
    navigatefromFindUsersPage? "active" : ""
  );
  const [superAdminPPLDevice, setsuperAdminPPLDevice] = useState(
    ""
  );
  const [superAdminVcList, setsuperAdminVcList] = useState(
    ""
  );
  // Show Admin

  const [showAdminSubscribeActive, setShowAdminSubscribeActive] = useState("active");
  const [showAdminCreateUserActive, setShowAdminCreateUserActive] = useState("");
  const [showAdminListUserAPublisher, setShowAdminListUserActive] = useState(
    ""
  );
  const [showAdminStreamListActive, setShowAdminStreamListActive] = useState(
    ""
  );
  const [showAdminInviteUser, setShowAdminInviteUser] = useState(
    ""
  );

  // Show Admin parent show
  const [showAdminCreateChildShowActive, setShowAdminCreateChildShowActive] = useState(
    "active"
  );
  const [showAdminChildShowAdminActive, setShowAdminChildShowAdminActive] = useState(
    ""
  );
  const [showAdminChildShowActive, setShowAdminChildShowActive] = useState(
    ""
  );
  const [showAdminChildStreamActive, setShowAdminChildStreamActive] = useState(
    ""
  );
  const [showAdminChildUserActive, setShowAdminChildUserActive] = useState(
    ""
  );

  // Show Publisher
  const [
    showPublisherCreateStreamActive,
    setShowPublisherCreateStreamActive,
  ] = useState("");
  const [
    showPublisherManageUserActive,
    setShowPublisherManageUserActive,
  ] = useState("");
  const [
    showPublisherListStreamActive,
    setShowPublisherListStreamActive,
  ] = useState("");

  // Show Finance
  const [showFinanceActiveMenuItem, setShowFinanceActiveMenuItem] = useState(SHOWFINANCE_PAGES.SHOW_LIST);

  const [isShowViewer, setIsShowViewer] = useState(false);

  //users & groups flag
  const { usersGroupsFlag } = props;


  //trusted device flag
  const [trustedDeviceEnable, setTrustedDeviceEnable] = useState(false)
  const { navigateToViewer } = useNavigateToViewer(setLoading)

  useEffect(() => {
    (async () => {
      if (props.showId) {
        const response = await backend.fetch({
          api: api.users.isShowViewer,
          queryParam: { show_id: props.showId },
        }, get(authValue, 'accessToken', {}));
  
        setIsShowViewer(response);
      }
    })();
  }, [props.showId]);

  const updateTrustedDeviceStatus = async () => {
    const featureData = {
    api: api.featureFlags.listFeatureFlags,
   };
   const flags = await backend.fetch(featureData, get(authValue, 'accessToken', {}));
   const trustedDeviceFeature = get(get(flags,'TRUSTED_DEVICE',''),'feature_enable',false);
    setTrustedDeviceEnable(trustedDeviceFeature)
  };

  useEffect(() => {
    const refreshProfile = async (uid: string) => {
      // Pull user data to show new profile image if it is changed
      const data = {
        api: api.users.get,
      };
      const profiledata = await backend.fetch(data, get(authValue, 'accessToken', {}));
      const {name = {} } = profiledata;
      
      setFirstName(name.user_name_first || "")
      setLastName(name.user_name_last || "")

      if (profiledata?.imageUrlPath !==" ") {
        const { imageUrlPath } = profiledata;
        setProfileImageURL(imageUrlPath);
      } else {
        setProfileImageURL("");
      }
    };

     
    if (authValue && authValue.uid) {
      const { uid, email, photoURL } = authValue;
      setEmail(email);
      if (photoURL) {
        setProfileImageURL(photoURL);
      }else {
        setProfileImageURL("");
      }
      if (!props.profile_image_url) {
        refreshProfile(uid);
      }      
      setCurrentPage(props.page);
      updateTrustedDeviceStatus();
    }

    if (props.showAdminUserEditView) {
      setShowAdminCreateUserActive("active");
      setShowAdminListUserActive("");
    }

    if (props.profile_image_url) {
      setProfileImageURL(props.profile_image_url);
    }

  }, [
    props.page,
    navigate,
    props.showAdminUserEditView,
    props.profile_image_url,
    authValue
  ]);



  const signOut = async() => {
    try {
      setLoading(true);
      await auth.doSignOut();
      
      Mixpanel.track("Logout", { Platform: "Web" });
      setLoading(false);
    } catch (err: any) {
      console.log(err);
    } finally {
      navigateToPage('/logoutredirect', {replace : true});
    }
  };

  const gotoViewer = async () => {
    const show_id = props.showId || null;
    const stream_id = props.streamId || "";
    const stream_label = props.stream_label || "";
    console.log("Navigate to show viewer dashboard");
    if (show_id) {
      if (stream_id !== "" && stream_label !== "") {
        sessionStorage.setItem("show_id", show_id);
        sessionStorage.setItem("stream_id", stream_id);
        sessionStorage.setItem("stream_label", stream_label);
      }
      if (currentPage === PAGES.UNAUTHORISED_PAGE) {
        navigateToPage("/");
      } else {
        navigateToViewer(show_id,{ state: { show_id }  })
      }
    }
  };

  const handleLogoClick = async () => {
    const user = auth.getUser();
    const uid = get(user, "uid", null);
    if (currentPage === PAGES.UNAUTHORISED_PAGE) {
      navigateToPage("/");
    } else {
      navigateToPage("/showselection", { state: { userId: uid } });
    } 
  };

  const profile = async () => {
    if (currentPage === PAGES.UNAUTHORISED_PAGE) {
      navigateToPage("/");
    } else {
      const user = auth.getUser();
      const uid = get(user, "uid", null);
      navigateToPage("/profile", { state: { userId: uid }});
    }
  };

  const resetPassword = async () => {
    const show_id = props.showId || null;
    if (currentPage === PAGES.UNAUTHORISED_PAGE) {
      navigateToPage("/");
    } else {
      const user = auth.getUser();
      const uid = get(user, "uid", null);
      navigateToPage("/resetpassword", { state: { userId: uid, trustedDeviceFeature: trustedDeviceEnable, from: get(location,'pathname',''), show_id:show_id}});
    }
  };

  const goToAppleTv = () =>{
    navigateToPage('/tvOs');
  }

  const trustedDevice = async () => {
    if (currentPage === PAGES.UNAUTHORISED_PAGE) {
      navigateToPage("/");
    } else {
      const user = auth.getUser();
      const uid = get(user, "uid", null);
      navigateToPage("/trusteddevice", { state: { userId: uid }});
    }
  };

  /* Super Admin Actions */
  const superAdminCreateShowClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.superAdminCreateShowClick) {
      setSuperAdminCreateShow("active");
      setSuperAdminInvite("");
      setSuperAdminListShows("");
      setSuperAdminListStreams("");
      setSuperAdminListUsers("");
      setSuperAdminListUsersNew("");
      setsuperAdminPPLDevice("")
      setsuperAdminVcList("")
      props.superAdminCreateShowClick();
    }
  };

  /* Super Admin Actions */
  const superAdminInviteUserClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.superAdminInviteUserClick) {
      setSuperAdminCreateShow("");
      setSuperAdminInvite("active");
      setSuperAdminListShows("");
      setSuperAdminListStreams("");
      setSuperAdminListUsers("");
      setSuperAdminListUsersNew("");
      setsuperAdminPPLDevice("")
      setsuperAdminVcList("")
      props.superAdminInviteUserClick();
    }
  };

  const superAdminListShowsClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.superAdminListShowsClick) {
      setSuperAdminCreateShow("");
      setSuperAdminInvite("");
      setSuperAdminListShows("active");
      setSuperAdminListStreams("");
      setSuperAdminListUsers("");
      setSuperAdminListUsersNew("");
      setsuperAdminPPLDevice("")
      setsuperAdminVcList("")
      props.superAdminListShowsClick();
    }
  };

  const superAdminListStreamsClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.superAdminListStreamsClick) {
      setSuperAdminCreateShow("");
      setSuperAdminInvite("");
      setSuperAdminListShows("");
      setSuperAdminListStreams("active");
      setSuperAdminListUsers("");
      setSuperAdminListUsersNew("");
      setsuperAdminPPLDevice("")
      setsuperAdminVcList("")
      props.superAdminListStreamsClick();
    }
  };
  const superAdminListUsersClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.superAdminListUsersClick) {
      setSuperAdminCreateShow("");
      setSuperAdminInvite("");
      setSuperAdminListShows("");
      setSuperAdminListStreams("");
      setSuperAdminListUsers("active");
      setSuperAdminListUsersNew("");
      setsuperAdminPPLDevice("")
      setsuperAdminVcList("")
      props.superAdminListUsersClick();
    }
  };
  const superAdminListUsersNewClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.superAdminListUsersNewClick) {
      setSuperAdminCreateShow("");
      setSuperAdminInvite("");
      setSuperAdminListShows("");
      setSuperAdminListStreams("");
      setSuperAdminListUsers("");
      setSuperAdminListUsersNew("active");
      setsuperAdminPPLDevice("")
      setsuperAdminVcList("")
      props.superAdminListUsersNewClick();
    }
  };

  const superAdminPPLdevicecheck = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.superAdminPPLDeviceClick) {
      setSuperAdminCreateShow("");
      setSuperAdminInvite("");
      setSuperAdminListShows("");
      setSuperAdminListStreams("");
      setSuperAdminListUsers("");
      setSuperAdminListUsersNew("");
      setsuperAdminPPLDevice("active")
      setsuperAdminVcList("")
      props.superAdminPPLDeviceClick();
    }
  };

  const superAdminVcListClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.superAdminVcListsClick) {
      setSuperAdminCreateShow("");
      setSuperAdminInvite("");
      setSuperAdminListShows("");
      setSuperAdminListStreams("");
      setSuperAdminListUsers("");
      setSuperAdminListUsersNew("");
      setsuperAdminPPLDevice("")
      setsuperAdminVcList("active")
      props.superAdminVcListsClick();
    }
  };

  /* Show Finance Actions */

  const showFinanceMenuItemClick = (event: React.MouseEvent<HTMLAnchorElement>, tabItem: any) => {
    event.preventDefault();
    event.stopPropagation();

    setShowFinanceActiveMenuItem(tabItem);
    
    switch(tabItem) {
      case SHOWFINANCE_PAGES.SHOW_LIST :
        if(props.showFinanceListShowsClick) {
          props.showFinanceListShowsClick();
        }
        break;
      
      case SHOWFINANCE_PAGES.STREAM_LIST:
        if(props.showFinanceListStreamsClick) {
          props.showFinanceListStreamsClick();
        }
        break;
      
      case SHOWFINANCE_PAGES.USER_LIST:
        if(props.showFinanceListUsersClick) {
          props.showFinanceListUsersClick()
        }
        break;

      case SHOWFINANCE_PAGES.INVITE_SHOW_ADMIN:
        if(props.showFinanceInviteUserClick){
          props.showFinanceInviteUserClick()
        } 
        break;

      default: break;  

    }
  }

  /* Show Admin Parent Show Actions */
  const showAdminCreateChildShowClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminCreateChildShowClick) {
      setShowAdminCreateChildShowActive("active");
      setShowAdminChildShowAdminActive("");
      setShowAdminChildShowActive("");
      setShowAdminChildStreamActive("");
      setShowAdminChildUserActive("");

      props.showAdminCreateChildShowClick();
    }
  };

  const showAdminCreateChildShowAdminClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminChildShowAdminClick) {
      setShowAdminCreateChildShowActive("");
      setShowAdminChildShowAdminActive("active");
      setShowAdminChildShowActive("");
      setShowAdminChildStreamActive("");
      setShowAdminChildUserActive("");

      props.showAdminChildShowAdminClick();
    }
  }; 

  
  const showAdminChildShowClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminChildShowClick) {
      setShowAdminCreateChildShowActive("");
      setShowAdminChildShowAdminActive("");
      setShowAdminChildShowActive("active");
      setShowAdminChildStreamActive("");
      setShowAdminChildUserActive("");

      props.showAdminChildShowClick();
    }
  };

  const showAdminChildStreamClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminChildStreamClick) {
      setShowAdminCreateChildShowActive("");
      setShowAdminChildShowAdminActive("");
      setShowAdminChildShowActive("");
      setShowAdminChildStreamActive("active");
      setShowAdminChildUserActive("");

      props.showAdminChildStreamClick();
    }
  };

  const showAdminChildUserClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminChildUserClick) {
      setShowAdminCreateChildShowActive("");
      setShowAdminChildShowAdminActive("");
      setShowAdminChildShowActive("");
      setShowAdminChildStreamActive("");
      setShowAdminChildUserActive("active");
      
      props.showAdminChildUserClick();
    }
  };

  /* Show Admin Actions */
  const showAdminCreateUserClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminCreateUserClick) {
      setShowAdminCreateUserActive("active");
      setShowAdminListUserActive("");
      setShowAdminStreamListActive("");
      setShowPublisherCreateStreamActive("");
      setShowPublisherManageUserActive("");
      setShowPublisherListStreamActive("");
      setShowAdminSubscribeActive("");
      setShowAdminInviteUser("");
      props.showAdminCreateUserClick();
    }
  };

  const showAdminListClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminListClick) {
      setShowAdminCreateUserActive("");
      setShowAdminListUserActive("active");
      setShowAdminStreamListActive("");
      setShowPublisherCreateStreamActive("");
      setShowPublisherManageUserActive("");
      setShowPublisherListStreamActive("");
      setShowAdminSubscribeActive("");
      setShowAdminInviteUser("");
      props.showAdminListClick();
    }
  };

  const showAdminStreamsListClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showAdminStreamsListClick) {
      setShowAdminCreateUserActive("");
      setShowAdminListUserActive("");
      setShowAdminStreamListActive("active");
      setShowPublisherCreateStreamActive("");
      setShowPublisherManageUserActive("");
      setShowPublisherListStreamActive("");
      setShowAdminSubscribeActive("");
      setShowAdminInviteUser("");
      props.showAdminStreamsListClick();
    }
  };
  const showInvitedViewerListClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.showInvitedViewerListClick) {
      setShowAdminCreateUserActive("");
      setShowAdminListUserActive("");
      setShowAdminStreamListActive("");
      setShowPublisherCreateStreamActive("");
      setShowPublisherManageUserActive("");
      setShowPublisherListStreamActive("");
      setShowAdminInviteUser("active");

      props.showInvitedViewerListClick();
    }
  };

  const showManageLicenseViewerClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    props.showManageLicenseViewerClick?.();
  }

  /* Show Publisher */
  const showPublisherCreateStreamClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.showPublisherCreateStreamClick) {
      setShowPublisherCreateStreamActive("active");
      setShowPublisherManageUserActive("");
      setShowPublisherListStreamActive("");
      setShowAdminCreateUserActive("");
      setShowAdminListUserActive("");
      setShowAdminStreamListActive("");

      props.showPublisherCreateStreamClick();
    }
  };

  const showPublisherManageUserClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.showPublisherMangeUsersClick) {
      setShowPublisherCreateStreamActive("");
      setShowPublisherManageUserActive("active");
      setShowPublisherListStreamActive("");
      setShowAdminCreateUserActive("");
      setShowAdminListUserActive("");
      setShowAdminStreamListActive("");

      props.showPublisherMangeUsersClick();
    }
  };

  const showPublisherListStreamClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.showPublisherListStreamsClick) {
      setShowPublisherCreateStreamActive("");
      setShowPublisherManageUserActive("");
      setShowPublisherListStreamActive("active");
      setShowAdminCreateUserActive("");
      setShowAdminListUserActive("");
      setShowAdminStreamListActive("");

      props.showPublisherListStreamsClick();
    }
  };

  /* Super Admin Action buttons */
  const renderSuperAdminActionButtons = () => {
    return (
      <Fragment>
        <Link
          to={{ hash: "#hash" }} 
          data-tooltip="Create Show"
          data-position="bottom center"
          className={`superadmin-actionbutton add-icon ${superAdminCreateShowActive}`}
          onClick={superAdminCreateShowClick}
        />

        <Link
          to={{ hash: "#hash" }} 
          data-tooltip="Invite Show Admin"
          data-position="bottom center"
          className={`people-icon ${superAdminInviteActive}`}
          onClick={superAdminInviteUserClick}
        />

        <Link
          to={{ hash: "#hash" }} 
          data-tooltip="List of Shows"
          data-position="bottom center"
          className={`list-shows-icon ${superAdminListShowsActive}`}
          onClick={superAdminListShowsClick}
        />

        <Link
          to={{ hash: "#hash" }} 
          data-tooltip="List of Streams"
          data-position="bottom center"
          className={`active-stream-icon ${superAdminListStreamsActive}`}
          onClick={superAdminListStreamsClick}
        />

        {/* <a
          href="#"
          className={`list-icon ${superAdminListUsersActive}`}
          onClick={superAdminListUsersClick}
        /> */}

        <Link
          to={{ hash: "#hash" }}
          data-tooltip="List of Users"
          data-position="bottom center"
          className={`list-icon ${superAdminListUsersNewActive}`}
          onClick={superAdminListUsersNewClick}
        />

        <Link
          to={{ hash: "#hash" }}
          data-tooltip="PPL-Devices"
          data-position="bottom center"
          className={`ppl-devices-icon ${superAdminPPLDevice}`}
          onClick={superAdminPPLdevicecheck}
        />
          <Link
          to={{ hash: "#hash" }}
          data-tooltip="VC Lists"
          data-position="bottom center"
          className={`vc-list-icon ${superAdminVcList}`}
          onClick={superAdminVcListClick}
        />
      </Fragment>
    );
  };

  // Show Finance Action buttons

  const renderShowFinanceActionButtons = () => {
    return (
      <Fragment>
        <Link
          to={{ hash: "#hash" }}
          className={`list-shows-icon ${showFinanceActiveMenuItem === SHOWFINANCE_PAGES.SHOW_LIST ? "active": ""}`}
          onClick={(event) => showFinanceMenuItemClick(event, SHOWFINANCE_PAGES.SHOW_LIST)}
        />

        <Link
          to={{ hash: "#hash" }}
          className={`active-stream-icon ${showFinanceActiveMenuItem === SHOWFINANCE_PAGES.STREAM_LIST ? "active" : ""}`}
          onClick={(event) => showFinanceMenuItemClick(event, SHOWFINANCE_PAGES.STREAM_LIST)}
        />

        <Link
          to={{ hash: "#hash" }}
          className={`list-icon ${showFinanceActiveMenuItem === SHOWFINANCE_PAGES.USER_LIST ? "active" : ""}`}
          onClick={(event) => showFinanceMenuItemClick(event, SHOWFINANCE_PAGES.USER_LIST)}
        />

        <Link
          to={{ hash: "#hash" }}
          className={`people-icon ${showFinanceActiveMenuItem === SHOWFINANCE_PAGES.INVITE_SHOW_ADMIN ? "active" : ""}`}
          onClick={(event) => showFinanceMenuItemClick(event, SHOWFINANCE_PAGES.INVITE_SHOW_ADMIN)}
        />
        </Fragment>
    )
  }

  const renderShowAdminMenu = () => {
    const { currentPage = "", licenseMangerFlag,addMenuToRef } = props;
    //console.log("currentPage", currentPage);
    return (
      <>
        <Link
            to={{ hash: "#hash" }} 
            data-tooltip="Invite User"
            data-position="left center"
            ref={(ele) => addMenuToRef(ele, "CreatePublisherViewer", 0)}
            className={`ui ${usersGroupsFlag ? "people-icon-new" : "people-icon"} ${currentPage === "CreatePublisherViewer" 
              || currentPage === "EditPublisherViewer" ? "active" : ""}`}
            onClick={showAdminCreateUserClick}
          />
          <Link
            to={{ hash: "#hash" }} 
            data-tooltip="List of Streams"
            data-position="bottom center"
            ref={(ele) => addMenuToRef(ele, "ShowStreamList", 1)}
            className={`ui ${usersGroupsFlag ? "active-stream-icon-new" : "active-stream-icon"} ${currentPage === "ShowStreamList" ? "active" : ""}`}
            onClick={showAdminStreamsListClick}
          />
          <Link
            to={{ hash: "#hash" }} 
            data-tooltip="List of Users"
            data-position="bottom center"
            ref={(ele) => addMenuToRef(ele, "ShowUserList", 2)}
            className={`ui ${usersGroupsFlag ? "list-icon-new" : "list-icon"} ${currentPage === "ShowUserList" ? "active" : ""}`}
            onClick={showAdminListClick}
          />
          <Link
            to={{ hash: "#hash" }} 
            data-tooltip="List of Invited Users"
            data-position="bottom center"
            ref={(ele) => addMenuToRef(ele, "ShowUserList", 3)}
            className={`ui ${usersGroupsFlag ? "list-report-icon-new" : "list-report-icon"} ${currentPage === "ListOfInvitedViewer" ? "active" : ""}`}
            onClick={showInvitedViewerListClick}
          />
          {!!licenseMangerFlag && 
          <Link
            to={{ hash: "#hash" }} 
            data-tooltip="Manage License"
            data-position="bottom center"
            ref={(ele) => addMenuToRef(ele, "ManageLicenseViewer", 4)}
            className={`ui ${usersGroupsFlag ? "md-icon-new" : "md-icon"} ${currentPage === "ManageLicenseViewer" ? "active" : ""}`}
            onClick={showManageLicenseViewerClick}
          >
          </Link>} 

      </>
    )
  }

  /* Show Admin Action buttons */
  const renderShowAdminActionButtons = () => {
    const { stripeFlag,licenseMangerFlag, currentPage } = props;
    return (
      <> 
        {renderShowAdminMenu()}
        {!!stripeFlag && <Link 
            to={{ hash: "#hash" }} 
            data-tooltip="Subscribe"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              event.preventDefault();
              event.stopPropagation();
              if (props.showAdminSubscribeClick) {
                props.showAdminSubscribeClick();
              }
            }} 
            data-position="bottom center"
            className={`ui subscribe-icon ${currentPage === "subscribe" ? "active" : ""}`} 
          />} 
      </>
    );
  };

  const renderShowAdminParentActionButtons = () => {
    return (
      <Fragment>
        <Link 
          to={{ hash: "#hash" }} 
          className={`ui superadmin-actionbutton add-icon ${showAdminCreateChildShowActive}`}
          onClick={showAdminCreateChildShowClick}
          data-tooltip="Create Child Show"
          data-position="bottom center"
        />

        <Link 
          to={{ hash: "#hash" }} 
          className={`ui people-icon ${showAdminChildShowAdminActive}`}
          onClick={showAdminCreateChildShowAdminClick}
          data-tooltip="Add Child Show Admin"
          data-position="bottom center"
        />

        <Link 
          to={{ hash: "#hash" }} 
          className={`ui list-shows-icon ${showAdminChildShowActive}`}
          onClick={showAdminChildShowClick}
          data-tooltip="Child Show List"
          data-position="bottom center"
        />

        <Link 
          to={{ hash: "#hash" }} 
          className={`ui active-stream-icon ${showAdminChildStreamActive}`}
          onClick={showAdminChildStreamClick}
          data-tooltip="Child Stream List"
          data-position="bottom center"
        />

        <Link 
          to={{ hash: "#hash" }}
          className={`ui list-icon ${showAdminChildUserActive}`}
          onClick={showAdminChildUserClick}
          data-tooltip="Child User List"
          data-position="bottom center"
        />
      </Fragment>
    );
  }

  /* Show Publisher Action buttons */
  const renderShowPublisherActionButtons = () => {
    return (
      <Fragment>
        <Link 
          to={{ hash: "#hash" }}
          data-tooltip="Create Stream"
          data-position="bottom center"
          className={`ui ${usersGroupsFlag ? 'list-shows-icon-new': 'showpublisher-actionbutton list-shows-icon'} ${showPublisherCreateStreamActive}`}
          onClick={showPublisherCreateStreamClick}
        />

        <Link 
          to={{ hash: "#hash" }}
          data-tooltip="Manage Users"
          data-position="bottom center"
          className={`ui ${usersGroupsFlag ? 'people-icon-new': 'people-icon'} ${showPublisherManageUserActive}`}
          onClick={showPublisherManageUserClick}
        />

        <Link 
          to={{ hash: "#hash" }}
          data-tooltip="List Of Streams"
          data-position="bottom center"
          className={`ui ${usersGroupsFlag ? 'active-stream-icon-new': 'active-stream-icon'} ${showPublisherListStreamActive}`}
          onClick={showPublisherListStreamClick}
        />
      </Fragment>
    );
  };
  
  const renderDropDown = () => {
    let style = null;
    if (currentPage === PAGES.UNAUTHORISED_PAGE) {
      return null;
    }
    if (profile_Image_url){ 
      style = {
        cursor: 'pointer'
      };
    }
  return (
  <Fragment>
          <Dropdown
            className={`right-header-image ${profile_Image_url ? '' : ''}`}
            style={style}
            pointing="top right"
            icon={(<ImageCanvas imageUrl={profile_Image_url}  className={'profile-image-property'}/>)}
          >

          <Dropdown.Menu>
            {props.showId && isShowViewer && !props.teamAdmin && (
              <Dropdown.Item
                text="Go to Viewer"
                icon="user circle outline"
                onClick={gotoViewer}
              />
            )}
            <Dropdown.Item
              text="Account Settings"
              icon="user circle outline"
              onClick={profile}
            />
            {/* <Dropdown.Item text="Help" icon="help circle" /> */}
            <Dropdown.Item
              text="Reset Password"
              icon="key"
              onClick={resetPassword}
            />
            {!isShowViewer && <Dropdown.Item
              text="Apple TV"
              icon={<img src={appleTVIcon} alt="My Icon"  style={{ width: '18px', height: '20px', marginTop: '-5px' }} />}
              onClick={goToAppleTv}
            />}
          {trustedDeviceEnable && (
            <Dropdown.Item
              text="Trusted Devices"
              icon={<img src={trustedDeviceIcon} alt="My Icon"  style={{ width: '18px', height: '24px', marginTop: '-5px' }} />}
              onClick={trustedDevice}
            />
            )}
            <Dropdown.Item text="Log Out" icon="power" onClick={signOut} />
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
     );
    };
  const renderShowAdminDropDown = () => {
    let style = null;
    if (currentPage === PAGES.UNAUTHORISED_PAGE) {
      return null;
    }
    if (profile_Image_url) {
      style = {
        background:
          'transparent url("' +
          profile_Image_url +
          '") 0% 0% no-repeat padding-box',
      };
    }
    const menuItems = [
      { label: "Go to Viewer", onclick: gotoViewer, page: PAGES.SHOW_PUBLISHER_PAGE },
      { label: "Account Settings", onclick: profile },
      { label: "Change Password", onclick: resetPassword },
      { label: "Logout", onclick: signOut },
    ]
    const filteredMenuItems = menuItems.filter((item) => {
      if (!!item.page) {
        if (item.page === currentPage) return item
      } else return item
    })
    const menu = (
      <Menu
        items={
          filteredMenuItems.map((item, index) => {
            return {
              key: index,
              label: <div onClick={item.onclick}>{item.label}</div>
            }
          })
        }
      />
    ) 
    
    return (
      <span style={{ top: "14px", right: "20px", position:"absolute", cursor:"pointer" }}>
      <AntdDropdown
        overlay={menu}
        placement="topRight"
        arrow
        trigger={["click"]}
        className={!!profile_Image_url ? "right-header-image right-header-image-new" : "right-header-image-new"}
      >
        <Avatar
          className="user-avatar"
          style={(profile_Image_url) ? {background:
            'transparent url("' +
            profile_Image_url +
            '") 0% 0% no-repeat padding-box',} : { backgroundColor: "#f56a00", verticalAlign: "middle", marginRight:"2px" }}
          size="large"
          gap={4}
        >
          {!profile_Image_url && `${firstName[0] || ""}${lastName[0] || ""}`}
        </Avatar>
      </AntdDropdown>
    </span>
    );
  };
  const renderPageSpecificHeader = () => {
    if (currentPage === PAGES.SUPER_ADMIN_PAGE || currentPage === PAGES.SHOW_FINANCE_PAGE) {
      return (
        <Fragment>
          <div className="logo" onClick={handleLogoClick}></div>
          <div className="navbar">
            {currentPage === PAGES.SUPER_ADMIN_PAGE && renderSuperAdminActionButtons()}
            {currentPage === PAGES.SHOW_FINANCE_PAGE && renderShowFinanceActionButtons()}
            {renderDropDown()}
          </div>
        </Fragment>
      );
    }
    if (currentPage === PAGES.SHOW_ADMIN_PAGE || currentPage === PAGES.SHOW_PUBLISHER_PAGE) {
      setTimeout(function () {
        if (props.activeClassUser === "active") {
          setShowPublisherManageUserActive(props.activeClassUser);
          setShowPublisherCreateStreamActive("");
          setShowPublisherListStreamActive("");
        }
        if (props.activeClassStream === "active") {
          setShowPublisherCreateStreamActive(props.activeClassStream);
          setShowPublisherManageUserActive("");
          setShowPublisherListStreamActive("");
        }
        if (props.activeClassListOfStream === "active") {
          setShowPublisherCreateStreamActive("");
          setShowPublisherManageUserActive("");
          setShowPublisherListStreamActive(props.activeClassListOfStream);
        }
      });
      return (
        usersGroupsFlag ? 
        (<Fragment>
        <div className="logo-new" onClick={handleLogoClick}></div>
        <div className="navbar navbar-new">
        <div className="navbar-wrapper">
          {currentPage === PAGES.SHOW_ADMIN_PAGE && renderShowAdminActionButtons()}
          {currentPage === PAGES.SHOW_ADMIN_PAGE && renderShowAdminDropDown()}
          {currentPage === PAGES.SHOW_PUBLISHER_PAGE && renderShowPublisherActionButtons()}
          {currentPage === PAGES.SHOW_PUBLISHER_PAGE && renderShowAdminDropDown()}
        </div>
        </div>
      </Fragment>)
      : 
      <Fragment>
      <div className="logo" onClick={handleLogoClick}></div>
      <div className="navbar">
        {currentPage === PAGES.SHOW_ADMIN_PAGE && renderShowAdminActionButtons()}
        {currentPage === PAGES.SHOW_PUBLISHER_PAGE && renderShowPublisherActionButtons()}
        {renderDropDown()}
      </div>
    </Fragment>

      );
    }

    if (currentPage === PAGES.TEAM_ADMIN_PAGE) {
      const { currentPage = "", stripeFlag, addMenuToRef } = props;
      setTimeout(function () {
        if (props.activeClassUser === "active") {
          setShowPublisherManageUserActive(props.activeClassUser);
          setShowPublisherCreateStreamActive("");
          setShowPublisherListStreamActive("");
        }
        if (props.activeClassStream === "active") {
          setShowPublisherCreateStreamActive(props.activeClassStream);
          setShowPublisherManageUserActive("");
          setShowPublisherListStreamActive("");
        }
        if (props.activeClassListOfStream === "active") {
          setShowPublisherCreateStreamActive("");
          setShowPublisherManageUserActive("");
          setShowPublisherListStreamActive(props.activeClassListOfStream);
        }
      });
      return (
        <Fragment>
          <div className={usersGroupsFlag ? "logo-new" : "logo"} onClick={handleLogoClick}></div>
          <div className={usersGroupsFlag ? "navbar flex-navbar navbar-new" : "navbar flex-navbar"}>
            <div className={usersGroupsFlag ? "team-admin-header team-admin-header-new" : "team-admin-header"}>
              {/* {!!stripeFlag && <div className="team-admin-stripe-menu"><Link 
                  to={{ hash: "#hash" }} 
                  data-tooltip="Subscribe"
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (props.showAdminSubscribeClick) {
                      props.showAdminSubscribeClick();
                    }
                  }} 
                  data-position="bottom center"
                  className={`ui subscribe-icon ${currentPage === "subscribe" ? "active" : ""}`} 
                /> </div>} */}
              <div className="pipe-line" ref={(ele) => addMenuToRef(ele, "ShowAdminUsers", 0)}>
                <div className={usersGroupsFlag ? "team-admin-header-name team-admin-header-name-new" : "team-admin-header-name"}>Show Admin</div>
                <Link
                  to={{ hash: "#hash" }}
                  data-tooltip="Invite User"
                  data-position="bottom center"
                  className={`ui ${usersGroupsFlag ? "people-icon-new" : "people-icon"} ${currentPage === "CreatePublisherViewer" 
                    || currentPage === "EditPublisherViewer" ? "active" : ""}`}
                  onClick={showAdminCreateUserClick}
                  ref={(ele) => addMenuToRef(ele, "ShowAdminUsers", 1)}
                />
                <Link
                  to={{ hash: "#hash" }}
                  data-tooltip="List of Show Admin Streams"
                  data-position="bottom center"
                  className={`ui ${usersGroupsFlag ? "active-Show-admin-stream-icon-new" : "active-Show-admin-stream-icon"} ${currentPage === "ShowStreamList" ? "active" : ""}`}
                  onClick={showAdminStreamsListClick}
                  ref={(ele) => addMenuToRef(ele, "ShowAdminUsers", 2)}
                />
                <Link
                  to={{ hash: "#hash" }}
                  data-tooltip="List of Users"
                  data-position="bottom center"
                  className={`ui ${usersGroupsFlag ? "list-icon-new" : "list-icon"} ${currentPage === "ShowUserList" ? "active" : ""}`}
                  onClick={showAdminListClick}
                  ref={(ele) => addMenuToRef(ele, "ShowAdminUsers", 3)}
                  
                />
                <Link
                  to={{ hash: "#hash" }}
                  data-tooltip="List of Invited Users"
                  data-position="bottom center"
                  className={`ui ${usersGroupsFlag ? "list-report-icon-new" : "list-report-icon"} ${currentPage === "ListOfInvitedViewer" ? "active" : ""}`}
                  onClick={showInvitedViewerListClick}
                  ref={(ele) => addMenuToRef(ele, "ListOfInvitedViewer", 4)}
                />
                {!!stripeFlag && <Link 
                  to={{ hash: "#hash" }} 
                  data-tooltip="Subscribe"
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (props.showAdminSubscribeClick) {
                      props.showAdminSubscribeClick();
                    }
                  }} 
                  data-position="bottom center"
                  className={`ui subscribe-icon ${currentPage === "subscribe" ? "active" : ""}`} 
                />}
                
              </div>
              <div className="pipe-line" ref={(ele) => addMenuToRef(ele, "publisher", 4)}>
                <div className={usersGroupsFlag ? "team-admin-header-name team-admin-header-name-new" : "team-admin-header-name"}>Show Publisher</div>
                <Link
                  to={{ hash: "#hash" }}
                  data-tooltip="Create Stream"
                  data-position="bottom center"
                  className={`ui ${usersGroupsFlag ? "list-shows-icon-new" : "list-shows-icon"} ${currentPage === "createStream" ? "active" : ""}`}
                  onClick={showPublisherCreateStreamClick}
                />
                <Link
                  to={{ hash: "#hash" }}
                  data-tooltip="Manage Show Users"
                  data-position="bottom center"
                  className={`ui ${usersGroupsFlag ? "people-icon-new" : "people-icon"} ${currentPage === "mangeUsers" ? "active" : ""}`}
                  onClick={showPublisherManageUserClick}
                />

                <Link
                  to={{ hash: "#hash" }}
                  data-tooltip="List Of Show Publisher Streams"
                  data-position="bottom center"
                  className={`ui ${usersGroupsFlag ? "active-stream-icon-new" : "active-stream-icon"} ${currentPage === "streamList" ? "active" : ""}`}
                  onClick={showPublisherListStreamClick}
                />
              </div>
              <div ref={(ele) => addMenuToRef(ele, "viewer", 5)}>
                <div className={usersGroupsFlag ? "team-admin-header-name team-admin-header-name-new" : "team-admin-header-name"}>Show Viewer</div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <Link
                      className={`ui ${usersGroupsFlag ? "viewer-icon-new" : "viewer-icon"}`}
                      data-tooltip="Go to viewer"
                      data-position="bottom center"
                      to={{ hash: "#hash" }}
                      onClick={gotoViewer}
                      state = {{show_id: props.showId, team_admin: true} }
              
                      />
                  </div>
                </div>
              </div>
            <div className="team-admin-header-drop-down">{usersGroupsFlag ?  renderShowAdminDropDown() : renderDropDown()}</div>
          </div>
        </Fragment>
      );
    }
    if (currentPage === PAGES.SHOW_ADMIN_PARENT_PAGE) {
      return (
        <Fragment>
          <div className="logo" onClick={handleLogoClick}></div>
          <div className="navbar">
            {renderShowAdminParentActionButtons()}
            {renderDropDown()}
          </div>
        </Fragment>
      );
    }
    if (
      currentPage === PAGES.SHOW_VIEWER_PAGE ||
      currentPage === PAGES.INTERMEDIATE_PAGE ||
      currentPage === PAGES.UNAUTHORISED_PAGE ||
      currentPage === PAGES.USER_PROFILE_SETTINGS_PAGE ||
      currentPage === PAGES.TRUSTED_DEVICE_LIST ||
      currentPage === PAGES.MAILBOX
    ) {
      return (
        <Fragment>
          <div className="logo" onClick={handleLogoClick}></div>
          <div className="navbar">{renderDropDown()}</div>;
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="logo"></div>
        <div className="navbar" style={{background:"white"}}></div>;
      </Fragment>
    );
  };

  const renderHeader = () => {
    if (!email) {
      return (
        <Fragment>
          <div className="logo"></div>
          <div className="navbar"  />
        </Fragment>
      );
    }
    return <Fragment>
      {renderPageSpecificHeader()}</Fragment>;
  };

  return (
    <div className={((currentPage === PAGES.SHOW_ADMIN_PAGE || currentPage === PAGES.SHOW_PUBLISHER_PAGE || currentPage === PAGES.TEAM_ADMIN_PAGE || currentPage === PAGES.TRUSTED_DEVICE_LIST) && usersGroupsFlag) ? 'header-new' : 'app-header'}>
      <ProgressLoader loading={loading} />

      {renderHeader()}
    </div>
  );
};

