import React, { useState, useEffect, Fragment } from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import { ROLES } from "../../../Utils/constants";
import { MESSAGES } from "../../../Utils/constants";
import { get } from "../../../Utils/helpers";
import './PubViewerForm.css'

interface IProps {
  isGuestInviteIncluded: boolean;
  lmconfigValue: any;
  countryCodes: any[];
  successMessage: string;
  errorMessage: string;
  licenseMangerFlag?: boolean;
  createUser: (userdata: any, isPublisherChecked: boolean, setPublisherCheck: any, setRoles: any) => void;
  usersGroupsFlag?: boolean;
  isMasvFeature?: boolean;
}

const PubViewerForm = (props: IProps) => {
  const [user_title, setUserTitle] = useState("");
  const [user_name_first, setFirstName] = useState("");
  const [user_name_last, setLastName] = useState("");
  const [user_email, setEmail] = useState("");
  const [user_phone, setPhone] = useState("");
  const [comments, setComments] = useState("");

  const [roles, setRoles] = useState({
    [ROLES.SHOW_VIEWER]: false,
    [ROLES.SHOW_PUBLISHER]: false,
    [ROLES.MAILBOX]: false
  });

  const [isViewerChecked, setViewerCheck] = useState(false);
  const [isPublisherChecked, setPublisherCheck] = useState(false);
  const [isGuestInviteChecked, setGuestInviteCheck] = useState(false);
  const [isMailBoxChecked, setMailBoxChecked] = useState(false);
  const [showRequestPub, setShowRequestPub] = useState(false);

  const [emailValid, setEmailValid] = useState(true);
  const [titleValid, setTitleValid] = useState(true);

  const [fNameValid, setfNameValid] = useState(true);
  const [lNameValid, setlNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [checkboxValid, setCheckboxValid] = useState(true);

  const [countryCodes, setCountryCodes] = useState([] as any);
  const [countryCode, setCountryCode] = useState("");
  const [ccValid, setCCValid] = useState(true); // cc --> Country Code
  const [errorCountryCode, setErrorCountryCode] = useState("");
  const [country_code, setUserCountryCode] = useState("");

  const [errorEmail, setErrorEmail] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorfName, setErrorfName] = useState("");
  const [errorlName, setErrorlName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorCheckbox, setErrorCheckbox] = useState("");

  useEffect(() => {    
    if (
      props.successMessage &&
      props.successMessage === MESSAGES.CREATE_USER_SUCCESS
    ) {
      console.log("Clear fields");
      clearFormFields();
    }
    if(props.countryCodes){
      const countryCodes = props.countryCodes.sort((a:any, b:any) => get(a, "value", "").localeCompare(get(b, "value", "")));
      setCountryCodes(countryCodes)
    }

  }, [props.successMessage,props.countryCodes]);

  const clearFormFields = () => {
    setUserTitle("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setCountryCode("");
    setPhone("");
    setComments("");
    setRoles({ [ROLES.SHOW_VIEWER]: false, [ROLES.SHOW_PUBLISHER]: false , [ROLES.MAILBOX]: false });
    setViewerCheck(false);
    setPublisherCheck(false);
    setGuestInviteCheck(false);
    setMailBoxChecked(false);
  };

  const createUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      const input = {
        user_title,
        user_name_first,
        user_name_last,
        user_email,
        user_phone,
        country_code,
        roles,
        comments,
        guest_invite: isGuestInviteChecked,
      };
      props.createUser(input, isPublisherChecked, setPublisherCheck, setRoles);
    } catch (err: any) {
      console.log(err);
    }
  };
  const hideShowPubRequest =()=>{
    setShowRequestPub(false)
   // props.pageName("ManageLicenseViewer")
  
  }
  
  const handelRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (value === ROLES.SHOW_PUBLISHER) {
      if (!checked) {
        setGuestInviteCheck(false);
      }
      setPublisherCheck(!!checked);
      if (checked) setMailBoxChecked(!!checked);
      setRoles({
        ...roles,
        [ROLES.SHOW_PUBLISHER]: !!checked,
        [ROLES.MAILBOX]: props.isMasvFeature ? (checked ? checked : isMailBoxChecked) : false,
      });
      //console.log("create page configValue:::",props.lmconfigValue)
      if (props.lmconfigValue === 0 && props.licenseMangerFlag === true && checked === true) {
        setShowRequestPub(true)
        setPublisherCheck(false);
        setRoles({
          ...roles,
          [ROLES.SHOW_PUBLISHER]: false,
        });
      } else if (props.lmconfigValue === 0 && props.licenseMangerFlag === false && checked === true) {
        setShowRequestPub(false)
        setPublisherCheck(true);
        if (props.isGuestInviteIncluded) {
          setGuestInviteCheck(true);
        }
      } else if (props.lmconfigValue > 0 && checked === true) {
        setShowRequestPub(false)
        setPublisherCheck(true);
        if (props.isGuestInviteIncluded) {
          setGuestInviteCheck(true);
        }
      }
    }
    else if (value === ROLES.SHOW_VIEWER) {
      setViewerCheck(!!checked);
      if (checked) setMailBoxChecked(true);
      setRoles({
        ...roles,
        [ROLES.SHOW_VIEWER]: !!checked,
        [ROLES.MAILBOX]: props.isMasvFeature ? (checked ? checked : isMailBoxChecked) : false,
      });
    } else if (value === "AllowGuestInvite") {
      setGuestInviteCheck(!!checked);
    } else if (value === ROLES.MAILBOX) {
      setRoles({
        ...roles,
        [ROLES.MAILBOX]: !!checked,
      });
      setMailBoxChecked(!!checked)
    }

    //Handle the validation from current value and previous state
    const viewerStatus =
      value === ROLES.SHOW_VIEWER ? !!checked : isViewerChecked;
    const publisherStatus =
      value === ROLES.SHOW_PUBLISHER ? !!checked : isPublisherChecked;
    const mailBoxStatus =
      props.isMasvFeature && (value === ROLES.MAILBOX ? !!checked : isMailBoxChecked);

    setCheckboxValid(!!viewerStatus || !!publisherStatus || !!mailBoxStatus);
    setErrorCheckbox(
      !!viewerStatus || !!publisherStatus || !!mailBoxStatus ? "" : "Please check your role"
    );
  };

  // validation of form fields
  const validateEmail = (event: any) => {
    const { value }  = event.target;
    setEmail(value.trim());
    let validateEmail = value.trim();
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail("Please enter email");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail("Invalid email format");
    } else {
      setEmailValid(true);
      setErrorEmail("");
    }
  };

  const validateTitle = (event: any) => {
    const { value }  = event.target;
    setUserTitle(value.trim());
  };

  const validateFirstName = (event: any) => {
    const { value }  = event.target;
    setFirstName(value.trim());
    let validatefName = value.trim();
    if (!validatefName) {
      setfNameValid(false);
      setErrorfName("Please enter your first name");
    } else {
      setfNameValid(true);
      setErrorfName("");
    }
  };

  const validateLastName = (event: any) => {
    const { value }  = event.target;
    setLastName(value.trim());
    let validatelName = value.trim();
    if (!validatelName) {
      setlNameValid(false);
      setErrorlName("Please enter your last name");
    } else {
      setlNameValid(true);
      setErrorlName("");
    }
  };

  const validatePhoneText = (data: any) => {
    const currCountry: any = props.countryCodes.find(
      (cc: any) => get(cc, "value", "") === data.value
    );

    if (data && currCountry) {
      if (
        user_phone &&
        !currCountry?.ignore_count &&
        user_phone.length !== currCountry.count
      ) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone("");
      }
    }
  };

  const validatePhone = (event: any) => {
    const { value }  = event.target;
    const validatePhone = value.replace(/[^0-9]/,'');
    setPhone(validatePhone);
    if (!validatePhone) {
      setPhoneValid(false);
      setErrorPhone("Please enter phone number");
    } else if (countryCode) {
      const currCountry: any = props.countryCodes.find(
        (cc: any) => get(cc, "value", "") === countryCode
      );
      const regexString = "^\\d{" + currCountry.count + "}$";
      const regex = new RegExp(regexString);

      if (
        countryCode === get(currCountry, "value", "") &&
        !currCountry?.ignore_count &&
        !regex.test(validatePhone)
      ) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone("");
      }
    } else if (!countryCode && !/^\d{5}$/.test(validatePhone)) {
      setPhoneValid(false);
      setErrorPhone("Please enter 10 digit phone no");
    } else {
      setPhoneValid(true);
      setErrorPhone("");
    }
  };

  const validateCountryCode = (event: any, data: any) => {
    if (!data.value) {
      setCCValid(false);
      setErrorCountryCode("Please select country code");
    } else {
      setCCValid(true);
      setErrorCountryCode("");
      validatePhoneText(data);
    }
  };
  const searchCountryCode = (event: any) => {
    const { value }  = event.target;
    if(value == ""){
      const countryCodes = props.countryCodes.sort((a:any, b:any) => get(a, "value", "").localeCompare(get(b, "value", "")));
        setCountryCodes(countryCodes)
    }else{
      const lower_val = value.charAt(0).toLowerCase();
      const startsWith = countryCodes.filter((code:any) =>code.value.toLowerCase().indexOf(lower_val) === 0 );
      setCountryCodes(startsWith)
    }
  };

  const handleCountryCodeChange = (e: any, data: any) => {
    setCountryCode(data.value);
    const currCountry: any = props.countryCodes.find(
      (cc: any) => get(cc, "value", "") === data.value
    );
    setUserCountryCode(currCountry.code);
    validateCountryCode(e, data);
  };

  return (
    <>
      <form className="ui form">
        {/* Semantic UI form */}
        <div className="field-and-icon">
          <div className="field-icon-name left-icon"></div>
          <div className="form-field  icon-input-field">
            <label className="form-input-label">Title</label>
            <input
              className={
                titleValid === false ? "form-input error-border" : "form-input"
              }
              type="text"
              value={user_title}
              onChange={validateTitle}
            />
            {titleValid === false && (
              <span className="error-message">{errorTitle}</span>
            )}
          </div>
        </div>
        <div className="field-and-icon">
          <div className="field-icon-name left-icon"></div>
          <div className="form-field icon-input-field">
            <div className="first-name">
              <label className="form-input-label mandatory">First Name</label>
              <input
                className={
                  fNameValid === false
                    ? "form-input error-border"
                    : "form-input"
                }
                type="text"
                value={user_name_first}
                onChange={validateFirstName}
                maxLength={20}
              />
              {fNameValid === false && (
                <span className="error-message">{errorfName}</span>
              )}
            </div>
            <div className="last-name">
              <label className="form-input-label mandatory">Last Name</label>
              <input
                className={
                  lNameValid === false
                    ? "form-input error-border"
                    : "form-input"
                }
                type="text"
                value={user_name_last}
                onChange={validateLastName}
                maxLength={20}
              />
              {lNameValid === false && (
                <span className="error-message">{errorlName}</span>
              )}
            </div>
          </div>
        </div>
        <div className="field-and-icon">
          <div className="field-icon-email left-icon"></div>
          <div className="form-field  icon-input-field">
            <label className="form-input-label mandatory">E-mail</label>
            <input
              className={
                emailValid === false ? "form-input error-border" : "form-input"
              }
              type="text"
              value={user_email}
              onChange={validateEmail}
            />
            {emailValid === false && (
              <span className="error-message">{errorEmail}</span>
            )}
          </div>
        </div>
        <div className="field-and-icon">
          <div className="field-icon-phone left-icon"></div>

          <div className="form-field icon-input-field">
            <div className="country-code">
              <label className="form-input-label mandatory">Country Code</label>
              <Dropdown
                placeholder="Select Code"
                className={
                  ccValid === false ? "form-input error-border" : "form-input"
                }
                fluid
                search
                selection
                options={countryCodes}
                value={countryCode}
                onKeyUp={searchCountryCode}
                onChange={handleCountryCodeChange}
              />
              {ccValid === false && (
                <span className="error-message">{errorCountryCode}</span>
              )}
            </div>
            <div className="phone-number">
              <label className="form-input-label mandatory">Phone</label>

              <input
                className={
                  phoneValid === false
                    ? "form-input-phone error-border"
                    : "form-input-phone"
                }
                type="text"
                value={user_phone}
                onChange={validatePhone}
              />
              {phoneValid === false && (
                <span className="error-message">{errorPhone}</span>
              )}
            </div>
          </div>
        </div>
        <div className="field-and-icon">
          <div className="field-icon-note left-icon"></div>
          <div className="form-field icon-input-field">
            <label className="form-input-label">Notes</label>
            <textarea
              className="form-textarea"
              placeholder="Notes goes here"
              rows={2}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
        </div>
        <div
          className="form-field flex"
          id="checkitems"
          style={{ marginBottom: "5px" }}
        >
          {" "}
          {/* semantic checkbox */}
          <div className="ui checkbox  margin-50">
            <input
              className={checkboxValid === false ? "error-border" : ""}
              type="checkbox"
              value={ROLES.SHOW_VIEWER}
              checked={isViewerChecked}
              onChange={(e) => handelRole(e)}
            />
            <label>
              <span className="label-checkbox">Viewer</span>
            </label>
          </div>
          <div className="ui checkbox margin-50">
            <input
              type="checkbox"
              value={ROLES.SHOW_PUBLISHER}
              checked={isPublisherChecked}
              onChange={(e) => handelRole(e)}
            />
            <label>
              <span className="label-checkbox">Publisher</span>
            </label>
          </div>
          { props.isMasvFeature && (
            <div className="ui checkbox  margin-50">
              <input
                type="checkbox"
                value={ROLES.MAILBOX}
                checked={isMailBoxChecked}
                onChange={(e) => handelRole(e)}
              />
              <label>
                <span className="label-checkbox">Mail Box</span>
              </label>
            </div>
          )}
          {isPublisherChecked && props.isGuestInviteIncluded && (
            <div className="ui checkbox">
              <input
                type="checkbox"
                value="AllowGuestInvite"
                checked={isGuestInviteChecked}
                onChange={(e) => handelRole(e)}
              />
              <label>
                <span className="label-checkbox">Allow Guest Invite</span>
              </label>
            </div>
          )}
        </div>
        <div className="text-center" style={{ minHeight: "20px" }}>
          {checkboxValid === false && (
            <span className="error-message">{errorCheckbox}</span>
          )}
        </div>

        <div className="text-center" style={{ minHeight: "20px" }}>
            <span className="copy-text">If invited user already has an active account they will not receive an e-mail, only new users will get an e-mail invitation to set up an account</span>
        </div>
        <div className="center-button" style={{ marginTop: "2rem" }}>
          <Button
            className="violet-button"
            color="violet"
            onClick={createUser}
            disabled={
              fNameValid === false ||
              user_name_first === "" ||
              lNameValid === false ||
              user_name_last === "" ||
              emailValid === false ||
              user_email === "" ||
              ccValid === false ||
              country_code === "" ||
              phoneValid === false ||
              user_phone === "" ||
              checkboxValid === false ||
              (isPublisherChecked === false && isViewerChecked === false && (props.isMasvFeature ? !isMailBoxChecked : true))
            }
          >
            Invite User
          </Button>
        </div>
        {props.errorMessage && (
          <Fragment>
            <div className="flex">
              <span className="alert-icon"></span>
              <span className="error-message alert-text-message">
                {props.errorMessage}
              </span>
            </div>
          </Fragment>
        )}
      </form>
      {showRequestPub && 
                <Modal
                open={showRequestPub}
                size={"tiny"}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                className="licence-modal"
                closeIcon 
                onClose={() => setShowRequestPub(false)}
                >
               <Modal.Content className="licence-content">
                    <div className="mlv-viewer-license-content">     
                    As there is no Publisher, Please request licenses from License Manager and then add users to the show.
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="confirm-button" onClick={() => setShowRequestPub(false)}>
                      Ok
                    </Button>
                </Modal.Actions>
                </Modal>
                }   
    </>
  );
};

export default PubViewerForm;
