import React, { useState, useEffect, useContext } from "react";
import { Button, Modal} from "semantic-ui-react";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import UserMessage from "../../Components/Common/UserMessage";
import Search from "./search";
import { PAGES, SUPERADMIN_PAGES } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import { firstBy } from "thenby";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";

const ListAllUsers = () => {
  const currentUser = get(useContext(AuthContext), "currentUser", {} );
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [duser, setDeleteUser] = useState({} as any);
  const [showUsers, setShowUsers] = useState([] as any[]);
  const [pageUsers, setPageUsers] = useState([] as any[]);

  const [searchTerm, setSearchTerm] = useState("");
  const [clearSearch, setClearSearch] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([] as any[]);
  const [noSearchDataAlert, setNoSearchDataAlert] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;

  useEffect(() => {
    console.log("listing all users");
    loadPageUsers(currentPage);
    if (!!searchTerm) {
      handleSearchSubmit(searchTerm);
    }
  },[showUsers])

  useEffect(() => {
    // Mixpanel

    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SUPER_ADMIN_PAGE + "_" + SUPERADMIN_PAGES.USER_LIST,
    });

    const fetchData = async () => {
      try {
        setSuccessMessage("");
        setErrorMessage("");

        //Pull all users of the show
        const data = {
          api: api.users.listAllUsers,
        };

        setLoading(true);
        const allUsers: any[] = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        
        if (allUsers && allUsers.length) {
          console.log("** Default Context");
          setSearchTerm("");

          allUsers.sort(
            firstBy(function (v: any) {
              return v.show_name.toLowerCase();
            })
              .thenBy(function (v: any) {
                return v.role_type.toLowerCase();
              })
              .thenBy(function (v: any) {
                return v.user_status.toLowerCase();
              })
              .thenBy(function (v: any) {
                return get(v, "user_name.user_name_first", "").toLowerCase();
              })
              .thenBy(function (v: any) {
                return get(v, "user_name.user_name_last", "").toLowerCase();
              })
          );
          console.log(allUsers);

          setShowUsers(allUsers);
          setSuccessMessage(`Successfully pulled all users`);

          setTotalRecords(allUsers.length);
          console.log("Total records ", allUsers.length);

          const pagesCount = Math.ceil(allUsers.length / pageSize);
          console.log("Total Pages ", pagesCount);
          setTotalPages(pagesCount);
          setCurrentPage(1);

          const pUsers = allUsers.slice(0, pageSize);
          //console.log(pUsers);
          setPageUsers(pUsers);

          checkPageButtons(1);
        }
      } catch (err: any) {
        const msg = "Error while pulling data";
        console.error(msg);
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const sendPasscode = async (user: any) => {
    try {
      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.sendpasscode,
        payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
      };

      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      console.log(result);

      if (result) {
        setSuccessMessage(
          "You have sent 8 digit passcode successfully to user mobile"
        );
        if (!modalOpen) {
          setModalOpen(true);
        }
      }
    } catch (err: any) {
      const msg = "Error while sending passcode to user";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteAlertOpen(false);
  };

  const handleDeleteConfirm = async () => {
    setDeleteAlertOpen(false);
    await deleteUser();
  };

  const showDeleteConfirmAlert = (user: any) => {
    setDeleteAlertOpen(true);
    setDeleteUser(user);
  };

  const deleteUser = async () => {
    try {
      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.delete,
        urlParam: duser.user_id,
      };

      setLoading(true);
      const result = await backend.remove(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      console.log(result);

      if (result) {
        setSuccessMessage(result);

        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": duser.user_email,
          "Action Type": "DeleteUser",
          "Admin Type": "SuperAdmin",
        });

        let filteredusers = showUsers.filter(
          (user: any) => user.user_id !== duser.user_id
        );
        setShowUsers([...filteredusers]);
      }
    } catch (err: any) {
      const msg = "Error while deleting user";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const inactiveUser = async (user: any) => {
    try {
      if (!user || (user && user.user_status !== "Active")) {
        console.log("User is not active");
        throw new Error("User is not active");
      }

      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.inactivateUser,
        payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
      };
      console.log(data);

      setLoading(true);
      await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

      Mixpanel.track("Admin User Action", {
        Platform: "Web",
        "Action Email": user.user_email,
        "Action Type": "InactivateUser",
        "Admin Type": "SuperAdmin",
      });

      showUsers.forEach((u: any) => {
        if (u.user_id === user.user_id) {
          u.user_status = "Inactive";
        }
      });
      loadPageUsers(currentPage);
    } catch (err: any) {
      const msg = "Error while inactivating user";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const activateUser = async (user: any) => {
    try {
      if (!user || (user && user.user_status === "Active")) {
        console.log("User is already active");
        throw new Error("User is already active");
      }

      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.activateUserBySuperAdmin,
        payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
      };

      setLoading(true);
      await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

      Mixpanel.track("Admin User Action", {
        Platform: "Web",
        "Action Email": user.user_email,
        "Action Type": "ActivateUser",
        "Admin Type": "SuperAdmin",
      });

      showUsers.forEach((u: any) => {
        if (u.user_id === user.user_id) {
          u.user_status = "Active";
        }
      });
      loadPageUsers(currentPage);
    } catch (err: any) {
      const msg = "Error while activating user";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const inactivateShowAdmin = async (user: any) => {
    try {
      if (!user) {
        throw new Error("No user");
      }
      console.log("inactivateShowAdmin");
      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.inactivateShowadmin,
        payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
      };

      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

      if (result) {
        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": user.user_email,
          "Action Type": "InactivateShowAdmin",
          "Admin Type": "SuperAdmin",
        });
  
        
        let filteredusers = showUsers.filter(
          (u: any) => u.show_user_role_id !== user.show_user_role_id
        );
        setShowUsers([...filteredusers]);
      }
    } catch (err: any) {
      const msg = "Error while removing showadmin role";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const renderSendPasscode = (user: any) => {
    return (
      <Button
        color="violet"
        onClick={() => {
          sendPasscode(user);
        }}
      >
        SEND PASSCODE
      </Button>
    );
  };

  const renderDeleteButton = (user: any) => {
    return (
      <Button
        color="violet"
        onClick={() => {
          showDeleteConfirmAlert(user);
        }}
      >
        DELETE
      </Button>
    );
  };

  const checkPageButtons = async (pageNumber: number) => {
    const nextElem: HTMLInputElement = document.getElementById(
      "next"
    ) as HTMLInputElement;
    nextElem.disabled = pageNumber === totalPages ? true : false;

    const previousElem: HTMLInputElement = document.getElementById(
      "previous"
    ) as HTMLInputElement;
    previousElem.disabled = pageNumber === 1 ? true : false;

    const firstElem: HTMLInputElement = document.getElementById(
      "first"
    ) as HTMLInputElement;
    firstElem.disabled = pageNumber === 1 ? true : false;

    const lastElem: HTMLInputElement = document.getElementById(
      "last"
    ) as HTMLInputElement;
    lastElem.disabled = pageNumber === totalPages ? true : false;
  };

  const handleNoDataSearchAlert = async () => {
    setNoSearchDataAlert(false);
    setSearchTerm("");
    setClearSearch("");
  };

  const handleSearchSubmit = async (searchTerm: any) => {
    try {
      if (searchTerm) {
        /* Search Context */
        console.log("** search Context");
        setSearchTerm(searchTerm);
        const searchedData = showUsers.filter((user: any) => {
          return user.user_email.toLowerCase().indexOf(searchTerm) >= 0;
        });

        if (searchedData && searchedData.length) {
          console.log(searchedData);

          setSearchedUsers(searchedData);
          setTotalRecords(searchedData.length);
          console.log("Total Search records ", searchedData.length);

          const pagesCount = Math.ceil(searchedData.length / pageSize);
          console.log("Total Search Pages ", pagesCount);
          setTotalPages(pagesCount);
          setCurrentPage(1);

          const pSearchedUsers = searchedData.slice(0, pageSize);
          //console.log(pSearchedUsers);
          setPageUsers(pSearchedUsers);

          checkPageButtons(1);
        } else {
          console.log("No data found");
          setNoSearchDataAlert(true);
        }
      } else {
        /* Default Context */
        console.log("** Default Context");
        setSearchTerm("");

        setTotalRecords(showUsers.length);
        console.log("Total records ", showUsers.length);

        const pagesCount = Math.ceil(showUsers.length / pageSize);
        console.log("Total Pages ", pagesCount);
        setTotalPages(pagesCount);
        setCurrentPage(1);

        const allUsers = showUsers;
        const pUsers = allUsers.slice(0, pageSize);
        //console.log(pUsers);
        setPageUsers(pUsers);

        checkPageButtons(1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadPageUsers = async (pageNumber: number) => {
    try {
      let begin = (pageNumber - 1) * pageSize;
      let end = begin + pageSize;

      const allUsers = searchTerm ? searchedUsers : showUsers;
      const pUsers = allUsers.slice(begin, end);

      setPageUsers(pUsers);

      checkPageButtons(pageNumber);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageNext = async () => {
    try {
      let nextpage = currentPage + 1;
      setCurrentPage(nextpage);

      loadPageUsers(nextpage);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagePrev = async () => {
    try {
      let prevpage = currentPage - 1;
      setCurrentPage(prevpage);

      loadPageUsers(prevpage);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFirstPage = async () => {
    try {
      let currentpage = 1;
      setCurrentPage(currentpage);

      loadPageUsers(currentpage);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLastPage = async () => {
    try {
      let currentpage = totalPages;
      setCurrentPage(currentpage);

      loadPageUsers(currentpage);
    } catch (error) {
      console.log(error);
    }
  };

  const renderInactiveButton = (user: any) => {
    if (user && user.user_status !== "Active") {
      return;
    }
    return (
      <Button
        color="violet"
        onClick={() => {
          inactiveUser(user);
        }}
      >
        INACTIVATE
      </Button>
    );
  };

  const renderActiveButton = (user: any) => {
    if (user && user.user_status === "Active") {
      return;
    }
    return (
      <Button
        color="violet"
        onClick={() => {
          activateUser(user);
        }}
      >
        ACTIVATE
      </Button>
    );
  };

  const renderShowAdminInactiveButton = (user: any) => {
    if (user && user.role_type !== "ShowAdmin") {
      return;
    }
    return (
      <Button
        color="violet"
        onClick={() => {
          inactivateShowAdmin(user);
        }}
      >
        INACTIVATE SHOWADMIN
      </Button>
    );
  };

  const renderShowUserRow = () => {
    return pageUsers.map((user: any, index: any) => {
      let name = "";
      if (user && user.user_name && user.user_name.user_name_first) {
        name = name + user.user_name.user_name_first;
      }
      if (user && user.user_name && user.user_name.user_name_last) {
        name = name + " " + user.user_name.user_name_last;
      }
      return (
        <tr className="header-cell-without-border" key={index}>
          <td className="list-icon-user"></td>
          <td>{user.user_title ? user.user_title : ""}</td>
          <td>{name}</td>
          <td>{user.country_code + user.user_phone}</td>
          <td>{user.user_email}</td>
          <td>{user.show_name}</td>
          <td>{user.role_type}</td>
          <td>{user.user_status}</td>
          <td>{renderSendPasscode(user)}</td>
          <td>{renderDeleteButton(user)}</td>
          <td>{renderInactiveButton(user)}</td>
          <td>{renderActiveButton(user)}</td>
          <td>{renderShowAdminInactiveButton(user)}</td>
        </tr>
      );
    });
  };

  const renderShowUsersTable = () => {
    return (
      <>
        <table className="ui inverted compact selectable table list-table-view">
          <thead className="">
            <tr className="">
              <th className="custom-border list-icon-name"></th>
              <th className="custom-border">Title</th>
              <th className="custom-border">Name</th>
              <th className="custom-border">Phone</th>
              <th className="custom-border">Email</th>
              <th className="custom-border">Show Name</th>
              <th className="custom-border">Role</th>
              <th className="custom-border">User Status</th>
              <th className="custom-border">Send Passcode</th>
              <th className="custom-border">Delete</th>
              <th className="custom-border">Inactivate User</th>
              <th className="custom-border">Activate User</th>
              <th className="">Remove ShowAdmin Role</th>
            </tr>
          </thead>
          <tbody className="">{renderShowUserRow()}</tbody>
        </table>
      </>
    );
  };

  return (
    <div className="list-show-users">
      <ProgressLoader loading={loading} />
      <UserMessage errorMsg={errorMessage} />

      <div className="list-heading">
        <p className="heading-text">All Users</p>
      </div>

      <div className="admin-user-search">
        <Search
          searchSubmit={handleSearchSubmit}
          searchLabel="Email Search"
          searchText={clearSearch}
        />
      </div>

      <div className="page-buttons">
        <button className="ui button" id="first" onClick={handleFirstPage}>
          First
        </button>
        <button className="ui button" id="previous" onClick={handlePagePrev}>
          Previous
        </button>
        <button className="ui button" id="next" onClick={handlePageNext}>
          Next
        </button>
        <button className="ui button" id="last" onClick={handleLastPage}>
          Last
        </button>
      </div>

      <div className="list-content">{renderShowUsersTable()}</div>
      <ConfirmationalPopup
        open={deleteAlertOpen}
        data={"This opeartion will delete user from system, do you want to continue ?"}
         onCancel={handleDeleteCancel}
         onOk={handleDeleteConfirm}
      />

      <ConfirmationalPopup
        open={noSearchDataAlert}
        data={`No data found with the search text ${searchTerm}`}
        onCancel={handleNoDataSearchAlert}
        onOk={handleNoDataSearchAlert}
      />

      <Modal open={modalOpen} onClose={handleModalClose} basic size={"tiny"}>
        <Modal.Content>
          <h3>{successMessage}</h3>
        </Modal.Content>

        <Modal.Actions>
          <Button
            loading={loading}
            color="violet"
            inverted
            onClick={handleModalClose}
          >
            CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default ListAllUsers;
