import { create } from 'zustand';

interface LayoutState {
  layoutMode: string;
  setLayoutMode: (mode: string) => void;
}

export const useLayoutSlice = create<LayoutState>((set) => ({
  layoutMode: 'normal',
  setLayoutMode: (mode: string) => {
    const rowWrapper = document.getElementById('zoom-container-wrapper');
    if (rowWrapper) {
      rowWrapper.style.flexDirection = mode === 'inverse' ? 'column-reverse' : 'column';
    }
    set({ layoutMode: mode });
  },
}));
