import { useState, useRef } from 'react';
import { get} from '../../../Utils/helpers';

export default function useCanvas() {
  // State
  const inMemCanvas: HTMLCanvasElement = document.createElement('canvas');
  
  const [vratio, _setVRatio] = useState(1.6);
  const vratioRef = useRef(vratio);
  
  const [canvasMT, setCanvasMT] = useState(0);
  const [canvasML, setCanvasML] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);


  // Methods
  const getPlayerBox = (rect: DOMRect, ratio: number): DOMRect => {
    let r = new DOMRect();
    const rectWidth = get(rect, 'width', 0);
    const rectHeight = get(rect, 'height', 0);
    let ar = rectWidth / rectHeight;

    if (ar < ratio) {
      let vidh = rectWidth / ratio;
      r.width = rectWidth; // Width is OK
      r.height = Math.floor(vidh); // We know the aspect ratio so we can calculate the height
      r.x = 0; // The width fits
      r.y = Math.floor((rectHeight - vidh) / 2); // But there's a vertical gap
    } else {
      let vidw = rectHeight * ratio;
      r.width = Math.floor(vidw); // We know the aspect ratio so we can calculate the width
      r.height = rectHeight; // Height is OK
      r.x = Math.floor((rectWidth - vidw) / 2); // Horizontal gap
      r.y = 0; // No vertical gap
    }

    return r;
  };

  const playbackStarted = () => {
    const video_player: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;

    if (!!video_player && !!video_player.videoHeight && video_player.videoHeight !== 0) {
      let new_ratio = video_player.videoWidth / video_player.videoHeight;
      vratioRef.current = new_ratio;
      const canvas: any = document.getElementById('canvas');
      renderCanvas(canvas, new_ratio);
    }
  };

  const renderCanvas = (canvas: any, ratio: number = vratioRef.current) => {
    const video_player: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
    
    if (video_player && !!canvas) {
      // The width and height of the video element
      let bb = video_player?.getBoundingClientRect();
      let player_box = getPlayerBox(bb, ratio);
      const player_box_width = get(player_box, 'width', 0);
      const player_box_height = get(player_box, 'height', 0);
      canvas.width = player_box_width;
      canvas.height = player_box_height;

      canvas.style.width = `${player_box_width}px`;
      canvas.style.height = `${player_box_height}px`;
      canvas.style.marginTop = `${player_box.y}px`;
      canvas.style.marginLeft = `${player_box.x}px`;

      setCanvasMT(player_box.y);
      setCanvasML(player_box.x);
      setCanvasWidth(player_box_width);

      return { w: player_box_width, h: player_box_height };
    }
    return { w: 0, h: 0 };
  };

  const resizeCanvas = () => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 25);
  };

  const setupCanvas = () => {
    window.addEventListener('resize', () => {
      const canvas: any = document.getElementById('canvas');
      if (canvas) {
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        inMemCanvas.width = canvas.width;
        inMemCanvas.height = canvas.height;
        const memCtx = inMemCanvas.getContext('2d');
        
        if (memCtx) {
          memCtx.drawImage(canvas, 0, 0);
        }

        const size = renderCanvas(canvas, vratioRef.current); // The new size
        if (memCtx) {
          let ctx2: CanvasRenderingContext2D = canvas.getContext('2d');
          ctx2.drawImage(inMemCanvas, 0, 0, size.w, size.h);
        }
      }
    });
  };

  const videoReady = () => {
    const video_player: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;

    video_player.addEventListener('loadedmetadata', playbackStarted);
    setupCanvas();
  };

  return { 
    canvasMT,
    canvasML,
    canvasWidth, 
    renderCanvas, 
    videoReady,
    resizeCanvas 
  };
}
