import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Checkbox, ModalActions } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { Layout } from "../PageLayout/Layout";
import { PAGES } from "../../Utils/constants";
import TermsOfService from "../Common/TermsOfService";
import PrivacyPolicy from "../Common/PrivacyPolicy";
import backend from "../../Service/Backend";
import api from "../../Service/Api";
import NotFound from "../App/NotFound";
import ProgressLoader from "../Common/ProgressLoader";
import { setGuestToken } from "../../Utils/guestHelpers";
import { AUTH_ROUTES } from "../App/AllRoutes";
import ReCAPTCHA from "react-google-recaptcha";
import { get } from "../../Utils/helpers";
import Bowser from "bowser";	
import { EVENT_TYPES } from "../../Utils/constants";
import { AuthContext } from '../../Context/authContext';
import useNavigateToViewer from "../../hooks/useNavigateToViewer";

let streamId = "";
let showId = "";
let streamLabel = "";
const reCaptchaSiteKey: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
const GuestLoginForm = ({ passcode, setPasscode }: { passcode: string, setPasscode?: (passcode: string) => void }) => {
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const location = useLocation();
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const {navigateToViewer} = useNavigateToViewer(setLoginLoading);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  // const [passcode, setPasscode] = useState("");

  const [firstnameValid, setFirstnameValid] = useState(true);
  const [lastnameValid, setLastnameValid] = useState(true);
  const [passcodeValid, setPasscodeValid] = useState(true);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [checked, setChecked] = useState(false);

  const [errorfirstname, setErrorFirstname] = useState("");
  const [errorlastname, setErrorLastname] = useState("");
  const [errorpasscode, setErrorPasscode] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [maxCountModal, setMaxCountModal] = useState(false);
  const [errorModal, setErrorModal] = useState("");


  const [tosModalOpen, setToSModal] = useState(false);
  const [ppModalOpen, setPPModal] = useState(false);
  const currentDate = new Date();
  
   const validateFirstName = (event: any) => {
    const { value }  = event.target;
    const fname = value.trim()
    setFirstname(fname);
    let validatefName = value.trim();
    if (!validatefName) {
      setFirstnameValid(false);
      setErrorFirstname("Please enter your first name");
    } 
    else if(validatefName.length > 20) {
      setFirstnameValid(false);
      setErrorFirstname("First name should not exceed 20 Characters");
    }else {
      setFirstnameValid(true);
      setErrorFirstname("");
    }
  };

  const validateLastName = (event: any) => {
    const { value }  = event.target;
    const lname = value.trim()
    setLastname(lname);
    let validatelName = value.trim();
    if (!validatelName) {
      setLastnameValid(false);
      setErrorLastname("Please enter your last name");
    } 
    else if(validatelName.length > 20) {
      setLastnameValid(false);
      setErrorLastname("Last name should not exceed 20 Characters");
    }else {
      setLastnameValid(true);
      setErrorLastname("");
    }
  };

  const handleVerificationSuccess = (value: any) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const validatePasscode = (event: any) => {
   const value = event.target.value;
    let validatePasscode = event.target.value;
    if (!validatePasscode) {
      setPasscodeValid(false);
      setErrorPasscode("Please enter the passcode");
    } else {
      setPasscodeValid(true);
      setErrorPasscode("");
    }
  };

  const logUser = async () => {	
    try {	
      let log_data: any = {};	
      const browser = Bowser.getParser(window.navigator.userAgent);	

      log_data.browser = browser.getBrowser();	
      log_data.os = browser.getOS();	
      log_data.platform = browser.getPlatform();	
      log_data.guestLockEvent = {	
        streamId: streamId,	
        showId: showId,	
        streamLabel: streamLabel,	
        guestName: `${firstname} ${lastname}`,	
      };	

      const ipData = {	
        endPoint: "https://ipapi.co/json/",	
      };	

      let ipInfo = await backend.get(ipData);	
      if (ipInfo) {	
        log_data.city = ipInfo.data.city;	
        log_data.country = ipInfo.data.country_name;	
        log_data.regionName = ipInfo.data.region;	
        log_data.location = {	
          lat: ipInfo.data.latitude,	
          lon: ipInfo.data.longitude,	
        };	
        log_data.ip = ipInfo.data.ip;	
        log_data.timezone = ipInfo.data.timezone;	
        log_data.utc_offset = ipInfo.data.utc_offset;	
      }	
      const payLoad = {	
        event_type: EVENT_TYPES.GUEST_LOCKED,	
        event_data: log_data,	
        event_ts: currentDate,	
        user_id: null,	
        user_email: null,	
      };	

      const requestConfig = {	
        api: api.guestUsers.loglockevent,	
        payLoad: payLoad,	
      };	
     await backend.save(requestConfig, get(currentUser, "user.accessToken", ""));	
    } catch (err) {	
      console.log(err);	
    }	
  };	


  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoginLoading(true);
    setLoginErrorMessage("");
    try {
      const token = new URLSearchParams(location.search).get("token");

      const requestConfig = {
        api: api.guestUsers.login,
        payLoad: JSON.stringify({
          firstName: firstname,
          lastName: lastname,
          passcode,
          token,
        }),
      };

      const {
        token: userToken,
        streamId,
        showId,
        streamLabel,
        pubName,
        uid
      } = await backend.save(requestConfig, get(currentUser, "user.accessToken", ""));
      setGuestToken(userToken);
      sessionStorage.setItem("stream_id", streamId);
      sessionStorage.setItem("stream_label", streamLabel);
      sessionStorage.setItem("pubname",pubName)

      navigateToViewer(showId, {state: {
        show_id: showId,
        stream_id: streamId,
        guestLoader: true,
        isGuestViewer: true,
        guestUid: uid,
        guestDisplayName: `${firstname} ${lastname}`,
      }});
    } catch (error) {
      const err = JSON.stringify(error);
      console.log(err);
      if (err.includes("31103")) {
        setMaxCountModal(true);
        setErrorModal("Maximum number of guests are admitted.");
      }
      if (err.includes("31106")) {
        await logUser();
        setMaxCountModal(true);
        setErrorModal("You have reached max retries.");
      }
      if (err.includes("31107")) {
        setErrorModal("You have been locked. Please try after sometime.");
        setMaxCountModal(true);
      }
      setLoginErrorMessage("Failed to join the room. Please try again.");
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <Layout page={PAGES.LOGIN_PAGE}>
      <div className="left-content">
        <div className="login_image"></div>
      </div>

      <div className="right-content ipad-forgot-password">
        <div className="right-container">
          <div className="auth-heading">
            <p className="auth-heading-text">Guest Login</p>
            <hr className="top" />
          </div>

          <div className="form-content">
            <form className="ui form" autoComplete="off">
              <div className="form-field">
                <div className="guest-invite-field">
                  <div className="guest-invite-field-name">
                    <label className="form-input-label mandatory">First Name</label>
                    <input
                      className={
                        firstnameValid === false
                          ? "form-input error-border"
                          : "form-input"
                      }
                      value={firstname}
                      type="text"
                      name="email"
                      onChange={validateFirstName}
                      maxLength={20}
                    />
                    {firstnameValid === false && (
                      <span className="error-message">{errorfirstname}</span>
                    )}
                  </div>
                  <div className="guest-invite-field-name">
                    <label className="form-input-label mandatory">Last Name</label>
                    <input
                      className={
                        lastnameValid === false
                          ? "form-input error-border"
                          : "form-input"
                      }
                      value={lastname}
                      type="text"
                      name="email"
                      onChange={validateLastName}
                      maxLength={20}
                    />
                    {lastnameValid === false && (
                      <span className="error-message">{errorlastname}</span>
                    )}
                  </div>
                </div>
              <div>
                <label className="form-input-label mandatory">Passcode</label>
                <input
                  className={
                    passcodeValid === false
                      ? "form-input error-border"
                      : "form-input"
                  }
                  value={passcode}
                  type="password"
                  disabled
                  // onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  onChange={validatePasscode}
                />
                {passcodeValid === false && (
                  <span className="error-message">{errorpasscode}</span>
                )}
              </div> 
            <Checkbox
              label="I agree"
              name="agree"
              className='checkbox-button'
              onChange={() => {
                setChecked(true);
              }}
              disabled={checked === true}
            />
              <div className="captcha-container">
                  <ReCAPTCHA
                    sitekey={reCaptchaSiteKey}
                    onChange={handleVerificationSuccess}
                  />
                </div>
              </div>
              
              <div className="auth-button">
                <Button
                  className="auth-violet-button"
                  color="violet"
                  loading={loginLoading}
                  onClick={handleLogin}
                  disabled={
                    firstnameValid === false ||
                    lastnameValid === false ||
                    passcodeValid === false ||
                    firstname === "" ||
                    lastname === "" ||
                    passcode === "" ||
                    checked === false ||
                    captchaVerified === false
                  }
                >
                  Join Room
                </Button>
              </div>
              {loginErrorMessage && (
                <>
                  <div className="flex">
                    <span className="alert-icon"></span>
                    <span className="error-message alert-text-message">
                      {loginErrorMessage}
                    </span>
                  </div>
                  
                </>
              )}
            </form>
          </div>

          <hr className="bottom" />
          <div className="form-content form">            
            <label className="login-terms">
              By creating an account or continuing to use a PacPost Live you
              acknowledge and agree that you have accepted the{" "}
              <b
                onClick={() => {
                  setToSModal(true);
                }}
              >
                Terms of Service
              </b>{" "}
              and reviewed the{" "}
              <b
                onClick={() => {
                  setPPModal(true);
                }}
              >
                Privacy Policy
              </b>
            </label>
          </div>
        </div>
      </div>

      <Modal
        open={modalOpen}
        size={"tiny"}
        onClick={() => setModalOpen(false)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Alert</Modal.Header>
        <Modal.Content>You are not part of any shows</Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setModalOpen(false)}>
            CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
      {tosModalOpen === true && (
        <Modal
          centered
          open={tosModalOpen}
          size={"large"}
          onClose={() => setToSModal(false)}
          onOpen={() => setToSModal(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <TermsOfService />
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="purple-button"
              onClick={() => setToSModal(false)}
            >
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {ppModalOpen === true && (
        <Modal
          centered
          open={ppModalOpen}
          size={"large"}
          onClose={() => setPPModal(false)}
          onOpen={() => setPPModal(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <PrivacyPolicy />
          </Modal.Content>
          <Modal.Actions>
            <Button className="purple-button" onClick={() => setPPModal(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      <Modal centered open={maxCountModal} size={"tiny"}>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>{errorModal}</div>
        </Modal.Content>
        <ModalActions>
          <Button
            className="purple-button"
            onClick={() => setMaxCountModal(false)}
          >
            CLOSE
          </Button>
        </ModalActions>
      </Modal>
    </Layout>
  );
};

const GuestLogin = () => {
  const location = useLocation();
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const token = new URLSearchParams(location.search).get("token");
  const [status, setStatus] = useState("idle");
  const [passcode, setPasscode] = useState("");
  useEffect(() => {
    if (!token) {
      setStatus("invalid");
      return;
    }
    setStatus("loading");

    const tokenDecoded = JSON.parse(atob(get(token.split("."), "1", "")));

    backend
      .fetch(
        {
          api: api.guestUsers.validateGuestToken,
          queryParam: {
            token,
          },
        },
        get(currentUser, "user.accessToken", "")
      ).then(() => {
        streamId = tokenDecoded.sti;
        showId = tokenDecoded.shi;
        streamLabel = tokenDecoded.slb;
        setPasscode(tokenDecoded.passcode); // Autofill the passcode field
        setStatus("valid");
      })
      .catch((error) => {
        setStatus("invalid");
      });
  }, [token]);

  const loader = <ProgressLoader loading />;
  const content: { [key: string]: React.ReactElement } = {
    idle: loader,
    loading: loader,
    valid: <GuestLoginForm passcode={passcode} />,
    invalid: <NotFound />,
  };

  return content[status] || null;
};

export default GuestLogin;
