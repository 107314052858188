import React, { useState, useEffect } from "react";
import ListStreamTableRow from "./ListStreamTableRow";
import { Table, Tooltip } from 'antd';
import CopyIcon from "../../Images/copy_icon_new.svg";
import KeyIcon from "../../Images/key_icon.svg";
import DeleteStream from "./DeleteStream";

interface IProps {
  show_id: string;
  streams: any[];
  teamAdmin: boolean;
  handleDelete: (deleteStream: any) => void;
  usersGroupsFlag?: boolean;
}

const ListStreamTable = (props: IProps) => {
  const [hiddenColumns, setHiddenColumns] = useState([] as any[])

  useEffect(() => {
    if (!props.teamAdmin) {
      setHiddenColumns(['assigned_to'])
    }
  }, [props.teamAdmin])

  const getAssignedTO = (stream: any) => {
    let name = "";
    if (stream && stream.assigned_to) {
      const user: any = stream.assigned_to;
      if (user && user.user_name) {
        const user_name = user.user_name;
        if (user_name) {
          name = user_name.user_name_first || "";

          name = user_name.user_name_last
            ? name + " " + user_name.user_name_last
            : name;
        }
      }
    }
    return name;
  };

  const renderRow = (props: IProps) => {
    console.log("streams", props.streams);
    return props.streams.map((stream: any, index) => {
      return (
        <ListStreamTableRow
          show_id={props.show_id}
          stream={stream}
          index={index}
          key={index}
          teamAdmin={props.teamAdmin}
          handleDelete={props.handleDelete}
        />
      );
    });
  };

  const renderListStreamTable = () => {
    return (
      <table className="ui inverted selectable fixed table list-streams-table-view">
        <thead className="">
          <tr className="">
            <th className="custom-border">Stream Label</th>
            <th className="custom-border">Status</th>

            {props.teamAdmin && <th className="custom-border">Assigned To</th>}

            <th className="custom-border">Stream Name</th>
            <th className="custom-border stream-name-th"></th>
            <th className="custom-border key-th"></th>

            {props.teamAdmin && <th className="custom-border"></th>}

            <th className="custom-border" id="delete-th"></th> 
            <th id="advanced-settings-th"></th>                
          </tr>
        </thead>
        <tbody className="">{renderRow(props)}</tbody>
      </table>
    );
  };

  const columns = [
    { title: 'Stream Label', dataIndex: 'user_entered_stream_label', key: 'user_entered_stream_label' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Assigned To', dataIndex: 'assigned_to', key: 'assigned_to', render: (record: any) => <div>{getAssignedTO(record)}</div> },
    { title: 'Stream Name', dataIndex: 'stream_name', key: 'stream_name' },
    {
      title: 'Action', key: 'action', render: (_: any, record: any) => (
        <div className="action-col-stream-list">
          <Tooltip title="Copy Stream Name">
          <button className="action-icon" onClick={() => { navigator.clipboard.writeText(record.stream_name) }}><img src={CopyIcon} alt="copy stream name" /></button>
          </Tooltip>
          <Tooltip title="Copy Token Name">
          <button className="action-icon" onClick={() => { navigator.clipboard.writeText(record.token) }}><img src={KeyIcon} alt="copy stream name" /></button>
          </Tooltip>
          <Tooltip title="Copy  Name">
         <button className="action-icon" onClick={() => {navigator.clipboard.writeText(record.settings) }}><img src={KeyIcon} alt="copy stream name" /></button>
        </Tooltip>
          {
            record.status !== "Deleted" &&
            <Tooltip title="Delete Stream">
              <DeleteStream
                show_id={props.show_id}
                stream={record}
                handleDelete={props.handleDelete}
                usersGroupsFlag={props.usersGroupsFlag}
              />
            </Tooltip>
          }
        </div>
      )
    }
  ];

  const filteredColumns = columns.filter((column: any) => !hiddenColumns.includes(column.key))
  console.log('filteredColumns :>> ', filteredColumns);

  const renderListStreamTableNew = () => {
    return <Table columns={filteredColumns} dataSource={props.streams} />
  }
  return <div>
    {props.usersGroupsFlag ? renderListStreamTableNew() : renderListStreamTable()}
  </div>;
};
export default ListStreamTable;
