import useShareGuestSessions from './useShareGuestSessions';

export default function useGuestViewerCommands(updated: boolean, setUpdated: any,zmClient:any) {
  const { shareGuestSessions } = useShareGuestSessions(zmClient);

  const remove = (guestIdentity: string) => {
    let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};

    allowedGuestSession =
      typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession;

    if (allowedGuestSession) {
      // Remove the guest user from session
      delete allowedGuestSession[guestIdentity];
      console.log('Admin - Guests present');
      sessionStorage.setItem(
        'allowedGuest',
        typeof allowedGuestSession === 'string'
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession),
      );
      console.log('remove-sessionStorage', allowedGuestSession, typeof allowedGuestSession);
      shareGuestSessions(allowedGuestSession);
      setUpdated(!updated);
    }
  };

  const allow = (guestIdentity: string) => {
    let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
    allowedGuestSession =
      typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession;
    // Add the guest user into session
    allowedGuestSession[guestIdentity] = { status: 'allowed' };
    sessionStorage.setItem(
      'allowedGuest',
      typeof allowedGuestSession === 'string'
        ? allowedGuestSession
        : JSON.stringify(allowedGuestSession),
    );
    setTimeout(() => {
      shareGuestSessions(allowedGuestSession);
    }, 3500);

    setUpdated(!updated);
    //   const guestDiv: any = document.getElementById(sid);
    //   guestDiv.style.display = 'block';
  };

  return { remove, allow };
}
