import React, { useState, useEffect } from "react";
import { PAGES, SUPERADMIN_PAGES } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import AlgoliaTable from "../Algolia/AlgoliaTable";
import { sortTableDate } from "../../Utils/helpers";
import { searchClient } from "../../Service/show";

const { REACT_APP_ALGOLIA_STREAM_INDEX_NAME } = process.env;

interface IProps {
  parent_show_id: string | undefined;
  usersGroupsFlag?:boolean;
}

const ListAllChildStreams = (props: IProps) => {
  const { parent_show_id } = props;
  const [childStreams, setChildStreams] = useState([]);

  useEffect(() => {
    searchClient.clearCache();
    // Mixpanel
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SUPER_ADMIN_PAGE + "_" + SUPERADMIN_PAGES.STREAM_LIST,
    });
  }, [childStreams]);

  const renderCreatedTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.created_ts._seconds * 1000 +
        original.created_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const renderStartTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.stream_start_ts._seconds * 1000 +
        original.stream_start_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const renderEndTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return original.stream_end_ts
      ? new Date(
          original.stream_end_ts._seconds * 1000 +
            original.stream_end_ts._nanoseconds / 1000000
        ).toDateString()
      : "NA";
  };

  const getTableConfig = () => {
    return {
      columns: [
        // Headers array for the table
        {
          Header: "Id",
          accessor: "stream_vendor_id",
        },
        {
          Header: "Show Name",
          accessor: "show_name",
        },
        {
          Header: "Stream Label",
          accessor: "stream_label",
        },
        {
          Header: "Stream Name",
          accessor: "stream_name",
        },
        {
          Header: "Created TS",
          accessor: "created_ts",
          Cell: renderCreatedTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.created_ts._seconds"),
        },
        {
          Header: "Status",
          accessor: "stream_status",
        },
        {
          Header: "Start TS",
          accessor: "stream_start_ts",
          Cell: renderStartTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.stream_start_ts._seconds"),
        },
        {
          Header: "End TS",
          accessor: "stream_end_ts",
          Cell: renderEndTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.stream_end_ts._seconds"),
        },
        {
          Header: "Created By",
          accessor: "created_user_email",
        },
      ],
      algoliaIndex: REACT_APP_ALGOLIA_STREAM_INDEX_NAME, // Algolia Index Mandatory
      filters: `parent_show_id:${parent_show_id}`, // Algolia Filters
      setDataList: setChildStreams, // Temp set list of hits value
      dataList: childStreams, // Algolia list of hits value
      sortBy: [
        {
          id: "stream_name",
          desc: false,
        },
      ],
      attributesToRetrieve: [
        // Response attributes
        "stream_vendor_id",
        "show_name",
        "stream_label",
        "stream_name",
        "created_ts",
        "stream_status",
        "stream_start_ts",
        "stream_end_ts",
        "created_user_email",
      ],
    };
  };

  return (
    <div className="superadmin-list-streams" style={{width:"97%", margin: "0 auto"}}>
      <div className="superadmin-list-heading">
        <p className="heading-text">
          Show Admin :<span className="grey-text"> Child Stream List </span>
        </p>
      </div>
      <AlgoliaTable config={getTableConfig()} />
    </div>
  );
};

export default ListAllChildStreams;
