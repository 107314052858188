import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Checkbox, CheckboxProps } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { UserCredential, User } from 'firebase/auth';
import { Layout } from '../PageLayout/Layout';
import { auth, analytics } from '../../Firebase';
import { EVENT_TYPES, PAGES } from '../../Utils/constants';
import { Mixpanel } from '../../Service/Mixpanel';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import TermsOfService from '../Common/TermsOfService';
import PrivacyPolicy from '../Common/PrivacyPolicy';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import SubscriptionAgreement from '../Common/SubscriptionAgreement';
import useMaxRetries from '../../Utils/useMaxRetries';
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext'; 
import { logEvent } from '../../Service/LogEvent';

const ErrorMessage = ({ message }: { message: string | undefined | null }) =>
  !!message ? (
    <div style={{ marginTop: 8, textAlign: 'center' }}>
      <span style={{ marginRight: 8 }} className='alert-icon'></span>
      <span className='error-message alert-text-message'>{message}</span>
    </div>
  ) : null;

const Login = (props: any) => {
  const { signInSuccess, signInNoActiveShow, signInFailure } = analytics;
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const setCurrentUser = get(useContext(AuthContext), 'setCurrentUser', {});
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [passAttemptMessage, setPassAttemptMessage] = useState('');

  const [loginloading, setLoginLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  //const [passwordShown, setPasswordShown] = useState(false);
  //const [eyeIcon, setEyeIcon] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [agreeModal, setAgreeModal] = useState(false);

  const [tosModalOpen, setToSModal] = useState(false);
  const [ppModalOpen, setPPModal] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [checkAgree, setCheckAgree] = useState(false);
  const [localData, setLocalData] = useState([] as any);
  const [localAgree, setLocalAgree] = useState([] as any);

  useEffect(() => {
    //check for I Agree checkbox
    if (localStorage.getItem('encryptAgree')) {
      const value = localStorage.getItem('encryptAgree');
      if (value) {
        const decryptText = CryptoAES.decrypt(value, 'encryptAgree');
        let decryptString: any = decryptText.toString(CryptoENC);
        localStorage.setItem('decryptAgree', decryptString);
        const data: any = JSON.parse(decryptString);
        setLocalData(data);
        localData.forEach((user: any) => {
          if (user?.email.length > 0) {
            setCheckAgree(true);
          }
        });
      }
    }
    // Mixpanel
    Mixpanel.track('Visit', {
      Platform: 'Web',
      'Page Name': PAGES.LOGIN_PAGE,
    });
  }, []);

  const validateEmail = (event: any) => {
    setEmail(event.target.value);
    let validateEmail = event.target.value;
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail('Please enter email');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail('Invalid email format');
    } else {
      setEmailValid(true);
      setErrorEmail('');
    }
    //check for i agree checkbox checked
    const index = localData.findIndex((data: any) => data.email === validateEmail);
    if (index > -1) {
      setCheckAgree(true);
    } else {
      setCheckAgree(false);
    }
  };

  const validatePassword = (event: any) => {
    const { value } = event.target;
    let validatePassword = value.trim();
    setPassword(validatePassword);
    if (!validatePassword) {
      setPasswordValid(false);
      setErrorPassword('Please enter Password');
    } else {
      setPasswordValid(true);
      setErrorPassword('');
    }
  };
  

  const { maxRetriesReached, updateRetries } = useMaxRetries(email, async (emailToLock: string) => {
    const lockAPI = await backend.save(
      {
        api: api.users.lock,
        payLoad: JSON.stringify({ user_email: emailToLock }),
      },
      get(authValue, 'accessToken', {}),
    );
    lockAPI();
  });

  const getDeviceStatus = async (token: string) => {
    const device = {
      api: api.trustedDevice.deviceStatus,
    }
    const deviceStatus = (await backend.fetch(device, token));
     return deviceStatus
    
  }

  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setErrorMessage('');
      setPassAttemptMessage('');
      setLoginLoading(true);

      const userCredentials: UserCredential = await auth.doSignInWithEmailAndPassword(
        email,
        password,
      );

      let policy_terms: any = {
        agreed: true,
        agreed_ts: new Date().toUTCString(),
      };
      if (!userCredentials) {
        setErrorMessage('Error while login. Please check your credentials');
        throw new Error('Error while login. Please check your credentials');
      }

      if (userCredentials && userCredentials.user) {
        const user: User = userCredentials.user;
        const { displayName, email, uid } = user;

        // MixPanel
        if (email) {
          Mixpanel.identify(email);
        }
        Mixpanel.track('Successful login', {
          'Login Method': 'E-mail',
        });
        Mixpanel.people.set({
          'Platforms Used': ['Web'],
          $name: displayName,
          $email: email,
        });
        Mixpanel.people.set_once({
          'First Login': new Date().toISOString(),
        });

        // Here check if the password is force set and if yes see if the time is past the exp time
        const data = {
          api: api.users.getUserForcePass,
        };
        console.log('userCredentials', userCredentials)
        const token = get(userCredentials, 'user.stsTokenManager.accessToken', '')
        const userForcePassData = await backend.fetch(data, token);
        //TrustedDevice Feature Flag
        const featureData = {
          api: api.featureFlags.listFeatureFlags
        };
        const flags = await backend.fetch(featureData, token);
        let deviceStatus = { status: ''} 

        const trustedDeviceFeature = get(get(flags,'TRUSTED_DEVICE',''),'feature_enable',false);
        const showEmailOtpOption = get(get(flags,'SHOW_EMAIL_OTP_OPTION',''),'feature_enable',false);
        if (trustedDeviceFeature) {
          deviceStatus = await getDeviceStatus(token);
        }
        //getting new token forcefully to refresh authcontext is2FASuccess
        setCurrentUser((preUser: any) => ({...preUser, loading: true}))
        await auth.getToken();
        if (userForcePassData && userForcePassData.forcePassword) {
          const currTime = new Date(userForcePassData.forcePasswordCurr);
          const expTime = new Date(
            userForcePassData.forcePasswordExp._seconds * 1000 +
              userForcePassData.forcePasswordExp._nanoseconds / 1000000,
          );
          if (currTime < expTime) {
            // Reset Password retries to 0
            backend.save({ api: api.users.passwordRetriesReset }, token);
            //Check active show user
            const showDetails: any = await getShowDetails();
            const { activeShowUser = false, googleAuth = false } = showDetails;
            //Validate the show user
            if (!!activeShowUser) {
              //Show OTP Page
              if (checkAgree === true) {
                signInSuccess();
                navigate('/otp', {
                  state: {
                    isAuthenticated: auth.isAuthenticated(),
                    googleAuth: googleAuth,
                    privacyTerm: policy_terms,
                    trustedDeviceFeature,
                    showEmailOtpOption:showEmailOtpOption
                  },
                });
              } else {
                signInFailure();
                setAgreeModal(true);
              }
            } else {
              signInNoActiveShow();
              //No Active Show for this user
              await auth.doSignOut();
              setModalOpen(true);
            }
          } else {
            signInFailure();
            // Go Back to login page with errr message here.
            setErrorMessage('Your Temporary Password Expired. Contact Admin!');
          }
        } else {
          // Reset Password retries to 0
          backend.save({ api: api.users.passwordRetriesReset }, token);
          //Check active show user
          const showDetails: any = await getShowDetails();
          const { activeShowUser = false, googleAuth = false } = showDetails;
          //Validate the show user
          if (!!activeShowUser) {
            //Show OTP Page
            if (checkAgree === true) {
              if (deviceStatus.status === "active" && trustedDeviceFeature){
                logEvent(token, EVENT_TYPES.LOGIN_SUCCESS);
                navigate('/showselection', { state: { userId: uid } });
                return;
              }
              else navigate('/otp', {
                state: {
                  isAuthenticated: auth.isAuthenticated(),
                  googleAuth: googleAuth,
                  privacyTerm: policy_terms,
                  trustedDeviceFeature,
                  showEmailOtpOption:showEmailOtpOption
                },
              });
            } else {
              signInFailure();
              setAgreeModal(true);
            }
          } else {
            //No Active Show for this user
            signInFailure();
            await auth.doSignOut();
            setModalOpen(true);
          }
        }
      } else {
        signInNoActiveShow();
        setErrorMessage('Error while login');
        throw new Error('Error while login');
      }
    } catch (err: any) {
      console.error(err.code);
      if (err.code == "auth/user-disabled") {
        setErrorMessage(
          'Your account is suspended, as you have exceeded the maximum number of verification attempts. Contact Admin!',
        );
      } else {
        setErrorMessage(
          'Failed to sign in. Please check your credentials or network connection and try again.',
        );
      }
      signInFailure();
      // MixPanel
      Mixpanel.track('UnSuccessful login', {
        'Login Method': 'E-mail',
        Email: email,
      });

      updateRetries();
    } finally {
      setLoginLoading(false);
    }
  };

  const getShowDetails = async () => {
    try {
      return {
        activeShowUser: true,
        googleAuth: false,
      };
      // eslint-disable-next-line no-unreachable
    } catch (err: any) {
      return false;
    }
  };

  const handleAgree = (event: any, data: CheckboxProps) => {
    event.preventDefault();
    event.stopPropagation();
    const localAgree = [...localData];
    const checked: any = data.checked;
    if (email && password && checked) {
      let obj = { email: email, password: password, agree: 'agreed' };
      localAgree.push(obj);
    } else {
      const index = localData.findIndex((data: any) => data.email === validateEmail);
      if (index > -1) {
        localAgree.splice(index, 1);
      }
    }
    setLocalAgree(localAgree);
    var encryptText = CryptoAES.encrypt(JSON.stringify(localAgree), 'encryptAgree');
    const encryptedString = encryptText.toString();
    localStorage.setItem('encryptAgree', encryptedString);
    setCheckAgree(checked);
  };
  return (
    <Layout page={PAGES.LOGIN_PAGE}>
      <div className='left-content'>
        <div className='login_image'></div>
      </div>

      <div className='right-content ipad-view'>
        <div className='right-container'>
          <div className='auth-heading'>
            <p className='auth-heading-text'>Sign in to your account</p>
            <hr className='top' />
          </div>
          <div className='form-content'>
            <form className='ui form'>
              {/* Semantic UI form */}
              <div className='form-field'>
                <label className='form-input-label'>E-mail</label>
                <input
                  className={emailValid === false ? 'form-input error-border' : 'form-input'}
                  type='text'
                  // onChange={(e) => setEmail(e.target.value)}
                  name='email'
                  onChange={validateEmail}
                />
                {emailValid === false && <span className='error-message'>{errorEmail}</span>}
                {/* <a href="#" className="forgot-message">Forgot Email?</a>  */}
              </div>
              <div className='form-field'>
                <label className='form-input-label'>Password</label>
                <input
                  className={passwordValid === false ? 'form-input error-border' : 'form-input'}
                  placeholder='Password'
                  //type={passwordShown ? "text" : "password"}
                  type='password'
                  name='password'
                  value={password}
                  onChange={validatePassword}
                  //onPaste={trimPassword}
                />

                {passwordValid === false && <span className='error-message'>{errorPassword}</span>}

                <a href='/forgotpassword' className='forgot-message'>
                  Forgot Password?
                </a>
              </div>
              <div className='form-field'>
                {/* semantic checkbox */}
                <div className='ui checkbox'>
                  <label></label>
                  <input placeholder='' type='checkbox' />
                  <label>
                    <span className='form-input-label'>Remember me</span>
                  </label>
                </div><br/>
                {/* semantic checkbox */}
                <Checkbox
                  label='I agree'
                  name='agree'
                  className='checkbox-label'
                  checked={checkAgree || false}
                  onChange={(event, data: CheckboxProps) => {
                    handleAgree(event, data);
                  }}
                  disabled={checkAgree === true}
                />
              </div>
              <div className='auth-button'>
                <Button
                  className='auth-violet-button'
                  color='violet'
                  loading={loginloading}
                  onClick={handleLogin}
                  disabled={
                    passwordValid === false ||
                    password === '' ||
                    emailValid === false ||
                    email === '' ||
                    maxRetriesReached
                  }
                >
                  Sign In
                </Button>
              </div>
              <ErrorMessage message={errorMessage} />
              {maxRetriesReached && (
                <ErrorMessage message='Max retries reached. Your account may have been locked. Please contact admin.' />
              )}
            </form>
          </div>

          <hr className='bottom' />
          <div className='form-content'>
            <label className='login-terms'>
              By creating an account or continuing to use a Pacpost Live you acknowledge and agree
              that you have accepted the
              <b className='terms-policy' onClick={() => setToSModal(true)}>
                Terms of Service
              </b>
              and reviewed the
              <b className='terms-policy' onClick={() => setPPModal(true)}>
                Privacy Policy
              </b>
              and
              <b className='terms-policy' onClick={() => setSubscriptionModalOpen(true)}>
                Subscription Agreement
              </b>
            </label>
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        size={'tiny'}
        onClick={() => setModalOpen(false)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Alert</Modal.Header>
        <Modal.Content>You are not part of any shows</Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={() => setModalOpen(false)}>
            CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
      {agreeModal === true && (
        <Modal open={agreeModal} size={'tiny'} closeOnEscape={false} closeOnDimmerClick={false}>
          <Modal.Header>Alert</Modal.Header>
          <Modal.Content>Please select the "I Agree" checkbox to proceed further.</Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setAgreeModal(false)}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {tosModalOpen === true && (
        <Modal
          centered
          open={tosModalOpen}
          size={'large'}
          onClose={() => setToSModal(false)}
          onOpen={() => setToSModal(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <TermsOfService />
          </Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setToSModal(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {ppModalOpen === true && (
        <Modal
          centered
          open={ppModalOpen}
          size={'large'}
          onClose={() => setPPModal(false)}
          onOpen={() => setPPModal(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <PrivacyPolicy />
          </Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setPPModal(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {subscriptionModalOpen === true && (
        <Modal
          centered
          open={subscriptionModalOpen}
          size={'large'}
          onClose={() => setSubscriptionModalOpen(false)}
          onOpen={() => setSubscriptionModalOpen(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <SubscriptionAgreement />
          </Modal.Content>
          <Modal.Actions>
            <Button className='purple-button' onClick={() => setSubscriptionModalOpen(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Layout>
  );
};

export default Login;
