import { get } from "../../../Utils/helpers";
import { ParticipantType } from "../types/index-types";

export const sortedParticipants = (
  participantsList: ParticipantType[],
  currentUserId: string
) => {
  return participantsList.sort((a, b) => {
    if (
      get(a, "userIdentity", "") === currentUserId &&
      get(b, "userIdentity", "") !== currentUserId
    ) {
      return -1;
    } else if (
      get(a, "userIdentity", "") !== currentUserId &&
      get(b, "userIdentity", "") === currentUserId
    ) {
      return 1;
    } else {
      return 0; // if you want to keep the order of other elements as is
    }
  });
};
