import { Button, Dropdown, Modal } from 'semantic-ui-react';

const annotationColorOptions = [
    { text: 'red', value: 'red' },
    { text: 'green', value: 'green' },
    { text: 'blue', value: 'blue' },
    { text: 'cyan', value: 'cyan' },
    { text: 'magenta', value: 'magenta' },
    { text: 'yellow', value: 'yellow' },
    { text: 'black', value: 'black' },
    { text: 'white', value: 'white' },
];

interface Props {
    VFXcolor: string,
    SFXcolor: string,
    MXcolor: string,
    PIXcolor: string,
    setVFXcolor: any,
    setSFXcolor: any,
    setMXcolor: any,
    setPIXcolor: any,
    modalAnnotateColorOpen: boolean, 
    setModalAnnotateColorOpen: any
}

const AnnotationChatModal: React.FC<Props>  = ({
    VFXcolor,
    SFXcolor,
    MXcolor,
    PIXcolor,
    setVFXcolor,
    setSFXcolor,
    setMXcolor,
    setPIXcolor,
    modalAnnotateColorOpen, 
    setModalAnnotateColorOpen
}) => {

    return (
        <Modal
            open={modalAnnotateColorOpen}
            size={'tiny'}
            onClick={() => setModalAnnotateColorOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>Choose Annotate Color</Modal.Header>
            <Modal.Content>
                <div>
                    <div>
                        <label className='form-input-label'>Choose VFX Color</label>
                        <Dropdown
                            placeholder='Select VFX Color'
                            className='form-input'
                            fluid
                            selection
                            options={annotationColorOptions}
                            value={VFXcolor}
                            onChange={(e: any, data: any) => {
                                const value: string = data?.value || '';
                                setVFXcolor(value);
                            }}
                        />
                    </div>
                    <div>
                        <label className='form-input-label'>Choose SFX Color</label>
                        <Dropdown
                            placeholder='Select VFX Color'
                            className='form-input'
                            fluid
                            selection
                            options={annotationColorOptions}
                            value={SFXcolor}
                            onChange={(e: any, data: any) => {
                                const value: string = data?.value || '';
                                setSFXcolor(value);
                            }}
                        />
                    </div>
                    <div>
                        <label className='form-input-label'>Choose MX Color</label>
                        <Dropdown
                            placeholder='Select VFX Color'
                            className='form-input'
                            fluid
                            selection
                            options={annotationColorOptions}
                            value={MXcolor}
                            onChange={(e: any, data: any) => {
                                const value: string = data?.value || '';
                                setMXcolor(value);
                            }}
                        />
                    </div>
                    <div>
                        <label className='form-input-label'>Choose PIX Color</label>
                        <Dropdown
                            placeholder='Select VFX Color'
                            className='form-input'
                            fluid
                            selection
                            options={annotationColorOptions}
                            value={PIXcolor}
                            onChange={(e: any, data: any) => {
                                const value: string = data?.value || '';
                                setPIXcolor(value);
                            }}
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color='grey' onClick={() => setModalAnnotateColorOpen(false)}>
                    CLOSE
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AnnotationChatModal;