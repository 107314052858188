const authKeyName = 'userInfo';

type UserCredential = {
  [key: string]: any;
};

export const setUserCredential = (userInfo: UserCredential) => {
  localStorage.setItem(authKeyName, JSON.stringify(userInfo));
};

export const getUserCredential = () => {
  try {
    const userInfo = localStorage.getItem(authKeyName);
    if (userInfo) {
      return JSON.parse(userInfo);
    }
    return null;
  } catch (error) {
    console.error('Error getting user credential:', error);
    throw new Error('Error getting user credential');
  }
};

export const clearUserCredential = () => {
  localStorage.removeItem(authKeyName);
};
