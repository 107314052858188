import { auth } from './firebase';
import { getAuth, updatePassword, signInWithCustomToken } from 'firebase/auth';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
  UserCredential,
  IdTokenResult,
} from 'firebase/auth';
import backend from '../Service/Backend';
import api from '../Service/Api';
import { ProxyConfig, shouldUseProxy } from '../Utils/featureFlags';
import { clearUserCredential, getUserCredential, setUserCredential } from './storate';

// Define auth endpoints
const AUTH_ENDPOINTS = {
  SIGN_IN: '/auth/firebase/signin',
  SIGN_UP: '/auth/firebase/signup',
  TOKEN_REFRESH: '/auth/firebase/token',
  PASSWORD_RESET: '/auth/firebase/password-reset',
  PASSWORD_UPDATE: '/auth/firebase/password-update',
  VERIFY_RESET_CODE: '/auth/firebase/verify-reset-code',
  CONFIRM_RESET: '/auth/firebase/confirm-reset',
};

// Sign In
export const doSignInWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<UserCredential | any> => {
  // Only use proxy if specifically enabled for firebase service
  if (shouldUseProxy('firebase')) {
    try {
      const response = await fetch(`${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.SIGN_IN}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Authentication failed');
      }

      const userCredential = await response.json();
      setUserCredential(userCredential);
      return userCredential;
    } catch (error) {
      console.error('Auth error:', error);
      throw error;
    }
  }

  // Default: direct Firebase call
  return signInWithEmailAndPassword(auth, email, password);
};

// Sign Up
export const doCreateUserWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<UserCredential> => {
  if (shouldUseProxy('firebase')) {
    try {
      const response = await fetch(`${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.SIGN_UP}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Sign up failed');
      }
      const userCredential = await response.json();
      setUserCredential(userCredential);
      return userCredential;
    } catch (error) {
      console.error('Sign up error:', error);
      throw error;
    }
  }

  return createUserWithEmailAndPassword(auth, email, password);
};

// Get Token - Always get from Firebase directly as we need it for backend calls
export const getToken = async (): Promise<string> => {
  if (shouldUseProxy('firebase')) {
    const userInfo = getUserCredential();
    if (userInfo?.user?.accessToken) {
      return userInfo?.user?.accessToken;
    }
    return Promise.reject('No auth.currentUser!');
  }
  if (!auth.currentUser) {
    return Promise.reject('No auth.currentUser!');
  }
  return auth.currentUser.getIdToken(true);
};

// Password Reset
export const doPasswordReset = async (email: string): Promise<void> => {
  if (shouldUseProxy('firebase')) {
    try {
      const response = await fetch(
        `${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.PASSWORD_RESET}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        },
      );

      if (!response.ok) {
        throw new Error('Password reset failed');
      }
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  } else {
    return sendPasswordResetEmail(auth, email);
  }
};

// Clear user claim (no proxy needed - backend call)
export const clear = async () => {
  try {
    const data = {
      api: api.otp.disableOTPAuthStatus,
    };
    const token = await getToken();
    await backend.save(data, token);
    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
};

// Sign out (always local)
export const doSignOut = async () => {
  if (shouldUseProxy('firebase')) {
    clearUserCredential();
    return true;
  }
  try {
    await clear();
    await auth.signOut();
    return true;
  } catch (error) {
    console.log('error', error);
  }
};

// Rest of the functions remain unchanged as they're either local operations
// or backend calls that don't need proxying

export const getTokenResult = async (): Promise<IdTokenResult> => {
  if (auth.currentUser) {
    return auth.currentUser.getIdTokenResult();
  }
  return Promise.reject('No auth.currentUser!');
};

export const getUserId = (): string => {
  return auth.currentUser ? auth.currentUser.uid : '';
};

export const getUserEmail = (): string => {
  return auth.currentUser && auth.currentUser.email ? auth.currentUser.email : '';
};

export const getUser = (): any => {
  if (shouldUseProxy('firebase')) {
    const userInfo = getUserCredential();
    if (userInfo?.user) {
      return userInfo.user;
    }
    return null;
  }
  return auth.currentUser ? auth.currentUser : null;
};

export const getUserRole = async () => {
  try {
    const idTokenResult: IdTokenResult = await getTokenResult();
    if (idTokenResult && idTokenResult.claims) {
      const { role } = idTokenResult.claims;
      if (!role) {
        return false;
      }
      return role;
    }
  } catch (err: any) {
    throw err;
  }
};

export const isAuthenticated = (): boolean => {
  const user: any = getUser();
  return !!user;
};

export const isInitialised = async () => {
  return new Promise((resolve) => {
    auth.onAuthStateChanged(resolve);
  });
};

// Password verification functions with proxy support
export const verifyPasswordResetCodeHandler = async (actionCode: string): Promise<string> => {
  if (shouldUseProxy('firebase')) {
    try {
      const response = await fetch(
        `${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.VERIFY_RESET_CODE}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ actionCode }),
        },
      );

      if (!response.ok) {
        throw new Error('Verify reset code failed');
      }

      const data = await response.json();
      return data.email;
    } catch (error) {
      console.error('Verify reset code error:', error);
      throw error;
    }
  }

  return verifyPasswordResetCode(auth, actionCode);
};

export const confirmPasswordResetHandler = async (
  actionCode: string,
  newPassword: string,
): Promise<void> => {
  if (shouldUseProxy('firebase')) {
    try {
      const response = await fetch(
        `${ProxyConfig.PROXY_GATEWAY_URL}${AUTH_ENDPOINTS.CONFIRM_RESET}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ actionCode, newPassword }),
        },
      );

      if (!response.ok) {
        throw new Error('Confirm password reset failed');
      }
    } catch (error) {
      console.error('Confirm password reset error:', error);
      throw error;
    }
  } else {
    return confirmPasswordReset(auth, actionCode, newPassword);
  }
};
