
import React, { useEffect, useState, useContext } from 'react'
import { CaretRightOutlined, MoreOutlined } from '@ant-design/icons';
import { Collapse, Dropdown, Menu, notification } from 'antd';
import { GroupCardItem } from './GroupCardItem';
import { get } from "../../../Utils/helpers";
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import ConfirmationModal from '../../../Components/UIKit/ConfirmationModal/ConfirmationModal';
import { AuthContext } from '../../../Context/authContext';
const { Panel } = Collapse;

export const GroupCardList = (props: any) => {
    const currentUser = get(useContext(AuthContext), "currentUser", {} );
    const [tabStatus, setTabStatus] = useState(false)
    const [openModal, setOpenModal] = useState(false);

    const [itemName, setItemName] = useState("Select User Name" as any);
    const [isShowGroupUsers, setIsShowGroupUsers] = useState([] as any)

    const { show,
        showId,
        setManageShowGroupUsersStatus,
        setSelectedGroup,
        setLoading,
        fetchShowGroupData,
        showGroupUsersList
    } = props

    const onChange = (key: string | string[]) => {
        setTabStatus(!tabStatus)
    };

    const items: any = [
        {
            key: '1',
            label: "Manage Group",
            data: show
        },
        {
            key: '2',
            label: "Delete Group",
            data: show

        },
    ];

    const showModal = () => {
        setOpenModal(true);
    };
    const handleCancel = () => {
        setOpenModal(false);
    };

    const handleModalOk = () => {
        deleteShowGroup(show.show_group_id)
    }

    const deleteShowGroup = async (showGroupId: string) => {
        setOpenModal(false);
        setLoading(true);
        try {

            const data = {
                api: api.group.deleteShowGroup,
                urlParam: showId,
                queryParam: { show_group_id: showGroupId }
            };

            console.log(data, 'check data')

            const result = await backend.remove(data, get(currentUser, "user.accessToken", ""))
            if (!!result) {
                fetchShowGroupData()
                notification.success({
                    message: "Group deleted successfully."
                })
            }
        }
        catch (err) {
            console.log(err)
            notification.error({
                message: "Error while deleting group."
            })

        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (showGroupUsersList.length > 0) {
            setIsShowGroupUsers(showGroupUsersList)
        } else {
            setIsShowGroupUsers([])
        }

    }, [itemName, setSelectedGroup, showGroupUsersList])

    const handleMenuItemClick = (props: any) => {
        const { key } = props

        if (key === '1') {
            if (show) {
                setItemName(items.find((x: any) => x.key === key))
                let result = items.find((x: any) => x.key === key)
                setSelectedGroup(result?.data)
                console.log('where is manage', result.data)
            }
            setManageShowGroupUsersStatus(true)
        }
        else {
            showModal()
        }
    }
    const menu = (
        <Menu
            items={items}
            selectable
            onClick={handleMenuItemClick}
        />
    );

    const PanelHeader = (visibility: boolean) => {
        return (
            <>
                <b>
                    {show?.show_group_name}
                </b>
                <div className="group-list-header-wrapper">
                    <span>{isShowGroupUsers && isShowGroupUsers?.length} Users</span>
                </div>
            </>
        )
    }

    const cardOption = () => (
        <div>
            <Dropdown
                overlay={menu}
                // menu={{ items, onClick }}
                trigger={['click']}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
            >
                {/* <MoreOutlined /> */}
                <MoreOutlined
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                />
            </Dropdown>
        </div>
    );

    return (
        <div className="group-card-wrapper">
            <Collapse
                // defaultActiveKey={['1']}
                collapsible='icon'
                onChange={onChange}
                expandIconPosition="left"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel
                    header={PanelHeader(tabStatus)} key="1" extra={cardOption()}>
                    <div
                        style={{
                            maxHeight: "180px",
                            overflowY: "auto"
                        }}
                    >
                        {!!isShowGroupUsers && isShowGroupUsers.length > 0 ? isShowGroupUsers.map((group: any) => {
                            return (<GroupCardItem data={group} />)
                        }) :
                            (<>   no users </>)
                        }
                    </div>
                </Panel>
            </Collapse>

            <ConfirmationModal
                open={openModal}
                onOk={handleModalOk}
                onCancel={handleCancel}
                data="Do you want to delete the Group"
            />
        </div>
    )
}
