import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css';
import '../Common/progressLoader.css';

import { AUTH_ROUTES, NON_AUTH_ROUTES } from './AllRoutes';
import { PrivateRoute, OTPRoute } from './PrivateRoute';
import HomeIndex from '../Home/Index';
import Login from '../Auth/Login';
import GuestLogin from '../Auth/GuestLogin';
import Otp from '../Auth/Otp';
import ForgotPassword from '../Auth/ForgotPassword';
import NotFound from './NotFound';
import SuperAdminDashboard from '../SuperAdmin/Index';
import ShowAdminDashboard from '../ShowAdmin/Index';
import ShowPublisherDashboard from '../ShowPublisher/Index';
import NewShowViewerDashboard from '../NewShowViewer/Index';
import ZoomViewer from '../ZoomViewer';
import ShowViewerDashboard from '../ShowViewer/Index';
import TeamAdmin from '../TeamAdmin';
import ActiveShowsSelection from '../Intermediate/ActiveShowsSelection';
import { auth } from '../../Firebase';
import UnAuthorised from './UnAuthorised';
import SessionHandler from './ SessionHandler';
import Profile from '../UserSettings/Profile';
import ResetPassword from '../Auth/resetPassword';
import StripeSuccess from '../../Containers/Subscribe/StripeSuccess';
import StripeFailure from '../../Containers/Subscribe/StripeFailure';
import Swagger from '../Swagger';
import ShowFinanceDashboard from '../ShowFinance/index';
import TermsOfService from '../Common/TermsOfService';
import PrivacyPolicy from '../Common/PrivacyPolicy';
import SubscriptionAgreement from '../Common/SubscriptionAgreement';
import { get } from '../../Utils/helpers';
import { fetchFeatures } from '../../Service/show';
import { ConfigProvider } from '../../Context/configContext';
import StatusPage from '../PPLStatusPage';
import UnSupported from './UnSupported';
import trustedDevice from '../Auth/trustedDevice';
import Mailbox from '../Mailbox'
import { AuthContext } from '../../Context/authContext';
import RedirectPage from '../PageRedirect';
import FindUser from '../../Containers/FindUserDetails';
import ActivateAppleTV from '../ActivateAppleTV';
import ProgressLoader from '../Common/ProgressLoader';
import { useLocationStore } from '../../store/useLocationStore';

const App: React.FC = () => {
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const [loader, setLoader] = useState(true);
  const [validCountry, setValidCountry] = useState(false);
  const [displayNewViewerDashboard, setDisplayNewViewerDashboard] = useState(true);
  const [browserCheck, setBrowserCheck] = useState('unknown');
  

  const isAppInitialized = async () => {
    const countryStatus = true; // disabling geo location
    setValidCountry(countryStatus);
    if (!!countryStatus) {
      const status: boolean = (await auth.isInitialised()) as boolean;
      setLoader(status);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem('is_firstVisit')) {
      const signOut = async () => {
        sessionStorage.setItem('is_firstVisit', '1');
        await auth.doSignOut();
      };
      signOut();
    }
    isAppInitialized();

    const agent = window.navigator.userAgent.toLowerCase();
    let browserName = 'unknown';

    switch (true) {
      case agent.indexOf('edge') > -1:
        browserName = 'unknown';
        break;
      case agent.indexOf('edg/') > -1:
        browserName = 'unknown';
        break;
      case agent.indexOf('opr') > -1:
        browserName = 'unknown';
        break;
      case agent.indexOf('chrome') > -1 && !!window.chrome:
        browserName = 'Google Chrome';
        break;
      case agent.indexOf('trident') > -1:
        browserName = 'unknown';
        break;
      case agent.indexOf('firefox') > -1:
        browserName = 'unknown';
        break;
      case agent.indexOf('safari') > -1:
        browserName = 'Apple Safari';
        break;
      default:
        browserName = 'unknown';
    }
    if (browserName === 'Chrome') {
      setBrowserCheck('Google Chrome');
    } else if (browserName === 'Safari') {
      setBrowserCheck('Apple Safari');
    } else {
      setBrowserCheck(browserName);
    }
  }, []);

  return (
    <ConfigProvider>
      {loader === true ? (
        <div>
          <Dimmer active inverted>
            <Loader size='massive' />
          </Dimmer>
        </div>
      ) : validCountry === true ? (
        <div className='App'>
          
          <Router>
          <SessionHandler />
            <Routes>
              <Route path={NON_AUTH_ROUTES.forgotpassword} element={<ForgotPassword />} />

              <Route
                path={NON_AUTH_ROUTES.login}
                element={browserCheck !== 'unknown' ? <Login /> : <UnSupported />}
              />
              <Route path={NON_AUTH_ROUTES.termsofservice} element={<TermsOfService />} />
              <Route path={NON_AUTH_ROUTES.privacypolicy} element={<PrivacyPolicy />} />
              <Route
                path={NON_AUTH_ROUTES.subscriptionagreement}
                element={<SubscriptionAgreement />}
              />
              <Route path={NON_AUTH_ROUTES.home} element={<HomeIndex />} />
              <Route path={NON_AUTH_ROUTES.logoutredirect} element={<RedirectPage />} />
                <Route
                  path={NON_AUTH_ROUTES.guestlogin}
                  element={
                    browserCheck !== "unknown" ? (
                      <GuestLogin />
                    ) : (
                      <UnSupported />
                    )
                  }
                />

                <Route
                  path={AUTH_ROUTES.resetpassword}
                  element={
                    <PrivateRoute
                      path={AUTH_ROUTES.resetpassword}
                      component={ResetPassword}
                    />
                  }
                />
                <Route
                path={AUTH_ROUTES.trusteddevice}
                element={
                  <PrivateRoute path={AUTH_ROUTES.trusteddevice} component={trustedDevice} />
                }
              />
                <Route
                  path={`${AUTH_ROUTES.otp}/*`}
                  element={<OTPRoute path={AUTH_ROUTES.otp} component={Otp} />}
                />

              <Route
                path={AUTH_ROUTES.showselection}
                element={
                  <PrivateRoute path={AUTH_ROUTES.showselection} component={ActiveShowsSelection} />
                }
              />
              <Route
                path={AUTH_ROUTES.superadmin}
                element={
                  <PrivateRoute path={AUTH_ROUTES.superadmin} component={SuperAdminDashboard} />
                }
              />
              <Route
                path={AUTH_ROUTES.finduser}
                element={
                  <PrivateRoute path={AUTH_ROUTES.finduser} component={FindUser} />
                }
              />
              <Route
                path={AUTH_ROUTES.showfinance}
                element={
                  <PrivateRoute path={AUTH_ROUTES.showfinance} component={ShowFinanceDashboard} />
                }
              />

              <Route
                path={AUTH_ROUTES.showadmin}
                element={
                  <PrivateRoute path={AUTH_ROUTES.showadmin} component={ShowAdminDashboard} />
                }
              />

              <Route
                path={AUTH_ROUTES.mailbox}
                element={
                  <PrivateRoute path={AUTH_ROUTES.mailbox} component={Mailbox} />
                }
              />
              
              <Route
                path={AUTH_ROUTES.tvOs}
                element={
                  <PrivateRoute path={AUTH_ROUTES.tvOs} component={ActivateAppleTV} />
                }
              />

              <Route
                path={AUTH_ROUTES.showpublisher}
                element={
                  <PrivateRoute
                    path={AUTH_ROUTES.showpublisher}
                    component={ShowPublisherDashboard}
                  />
                }
              />

              <Route
                path={AUTH_ROUTES.showviewer}
                element={
                  <PrivateRoute
                    path={AUTH_ROUTES.showviewer}
                    component={NewShowViewerDashboard}
                  />
                }
              />

              <Route
                path={AUTH_ROUTES.oldshowviewer}
                element={
                  <PrivateRoute
                    path={AUTH_ROUTES.oldshowviewer}
                    component={ShowViewerDashboard}
                  />
                }
              />

              <Route
                path={AUTH_ROUTES.zoomviewer}
                element={
                  <PrivateRoute
                    path={AUTH_ROUTES.zoomviewer}
                    component={ZoomViewer}
                  />
                }
              />

              <Route
                path={AUTH_ROUTES.teamadmin}
                element={<PrivateRoute path={AUTH_ROUTES.teamadmin} component={TeamAdmin} />}
              />

              <Route
                path={AUTH_ROUTES.stripesuccess}
                element={
                  <PrivateRoute path={AUTH_ROUTES.stripesuccess} component={StripeSuccess} />
                }
              />

              <Route
                path={AUTH_ROUTES.stripefailure}
                element={
                  <PrivateRoute path={AUTH_ROUTES.stripefailure} component={StripeFailure} />
                }
              />

              <Route
                path={AUTH_ROUTES.unauthorised}
                element={<PrivateRoute path={AUTH_ROUTES.unauthorised} component={UnAuthorised} />}
              />

              <Route
                path={AUTH_ROUTES.profile}
                element={<PrivateRoute path={AUTH_ROUTES.profile} component={Profile} />}
              />

              {process.env.REACT_APP_SWAGGER && (
                <Route
                  path={AUTH_ROUTES.swagger}
                  element={<PrivateRoute path={AUTH_ROUTES.swagger} component={Swagger} />}
                />
              )}
              <Route path={NON_AUTH_ROUTES.statuspage} element={<StatusPage />} />

              <Route element={<NotFound />} />
            </Routes>
          </Router>
        </div>
      ) : (
        <Router>
          <div>
            <NotFound></NotFound>
          </div>
        </Router>
      )}
    </ConfigProvider>
  );
};

export default App;
