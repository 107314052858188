import React, { useEffect, useContext } from 'react';
import { Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { EncoderContext } from '../hooks/useEncoderContext';
import useEncoder from '../hooks/useEncoder';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
function EncoderDeviceList() {
  const { getDeviceList } = useEncoder();
  const encoderValues = useContext(EncoderContext);
  const { loading, deviceList = [] } = encoderValues;

  useEffect(() => {
    getDeviceList();
  }, []);

  const handleDeviceList = () =>
    deviceList.map((device: any) => {
      const { device_id, name, mac_id } = device;
      return (
        <Row justify='space-around' key={device_id} className='encoder-device-item'>
          <Col span={24}>
            <b>Mac Address</b>
          </Col>
          <Col span={24}> {mac_id}</Col>
          <Col span={24}>
            <b>Name</b>
          </Col>
          <Col span={24}> {name}</Col>
        </Row>
      );
    });

  return (
    <>
      {loading ? (
        <div className='encoder-loader'>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        Object.keys(deviceList).length > 0  ?  handleDeviceList() : 'No Device Found'
      )}
    </>
  );
}

export default EncoderDeviceList;
