import React, { useState, useEffect, useContext } from "react";
import IdleTimer from 'react-idle-timer';
import { Button, Modal } from "semantic-ui-react";
import { AuthContext } from "../../Context/authContext";
import { auth } from "../../Firebase";
import { Mixpanel } from '../../Service/Mixpanel';
import { get } from "../../Utils/helpers";
import { isGuestAuthenticated, logOutGuestUser } from "../../Utils/guestHelpers";
import ProgressLoader from "../Common/ProgressLoader";
import useNavigateToPage from "../../hooks/useNavigateToPage";


const SessionHandler = (props: any) => {
    //const { path } = props;
    //Session time should be in minutes 
    const { REACT_APP_SESSION_TIME = 240  } = process.env; 
    const TimeInterval = 10;
    const [modalOpen, setModalOpen] = useState(false);
    const [timer, setTimer] = useState(false); 
    const [loading, setLoading] = useState(false);
    const [counterFunction, setCounterFun] = useState({
        counter: 0
    } as any); 
    const [modal, setModal] = useState({
        header: "You Have Been Idle!",
        msg: `You are about to be logged out in 60 seconds due to inactivity. Are you still here?`,
        actions: [
            {
                name: "Yes",
                color: "green",
                onClick: () => resetTimer()
            } 
        ]
    });

    const navigateToPage = useNavigateToPage();
    const resetTimerFn = () => {
        const {timerInterval} = counterFunction;
            if(!!timerInterval) {
                clearInterval(timerInterval);
                counterFunction.timerInterval = null;
                counterFunction.counter = 0; 
            }
    }
    const counterFn = () => {
        
        counterFunction.counter = 0;
        const oneMinuteBefore = (TimeInterval -1)*60;
            const timerInterval = setInterval(() => {
                if(counterFunction.counter === 0)setModalOnCounter();
                counterFunction.counter++;
                if(counterFunction.counter >= oneMinuteBefore && counterFunction.counter < TimeInterval*60){
                    setModalUpdate(60 - (counterFunction.counter - oneMinuteBefore));
                }
                if (counterFunction.counter === TimeInterval*60) {
                    resetTimerFn();
                    clearInterval(timerInterval);
                    signOut(); 
                    setModal({
                        header: "Session Timeout",
                        msg: `You are logged out due to inactivity for more than ${REACT_APP_SESSION_TIME} minutes`,
                        actions: [
                            {
                                name: "CLOSE",
                                color: "red",
                                onClick: () => {
                                    setTimer(false);
                                    setModalOpen(false)
                                }
                            }
                        ]
                    })
                    counterFunction.counter = 0;
                }
            }, 1000);
            counterFunction.timerInterval = timerInterval;
        }

    const resetTimer = () => {
        clearInterval(counterFunction.timerInterval);
        counterFunction.timerInterval = null;
        counterFunction.counter = 0; 
        setTimer(false);
        setModalOpen(false);
    }

    const signOut = async () => {
        if(auth.isAuthenticated()) {
            try {
                setLoading(true);
                await auth.doSignOut();
                Mixpanel.track("Logout", { Platform: "Web" }); 
            } catch (err: any) {
                console.log(err);
            } finally {
                navigateToPage("/");
               setLoading(false);
                //setModalOpen(true);
            }
        }
        if(isGuestAuthenticated()) {
            signOutGl();
        }
    } 
    const signOutGl = async () => {
        sessionStorage.removeItem('allowedGuest');
        logOutGuestUser();
    
        sessionStorage.removeItem('auto_allow');
        sessionStorage.removeItem('gt');
        sessionStorage.removeItem('guestAttached');
        navigateToPage('/logoutredirect', {state:{isGuest:true},replace : true});
        
      };
    const setModalOnCounter = () => setModal({
        header: "Timeout Warning",
        msg: `For your protection, you are about to be automatically signed out.
        Click Keep Working now to stay signed in.`,
        actions: [
            {
                name: "Keep Working",
                color: "violet",
                onClick: () => {
                    setTimer(false);
                    setModalOpen(false);
                    // counterFn();
                    resetTimer();
                    resetTimerFn();
                }
            },
            {
                name: "Sign Out",
                color: "red",
                onClick: () => {
                    setTimer(false);
                    setModalOpen(false);
                    // counterFn();
                    signOut();
                    resetTimerFn();
                }
            }
        ]
    })

    const setModalUpdate = (count:number) => {
        setModal({
            header: "You Have Been Idle!",
            msg: `You are about to be logged out in ${count} seconds due to inactivity. Are you still here?`,
            actions: [
                {
                    name: "Yes",
                    color: "green",
                    onClick: () => resetTimer()
                } 
            ]
        })
    }

    const onIdle = async (event: any) => {
        if (!timer) {
            setModalOpen(true);
            setTimer(true);
            counterFn();
        } 
        setModalOnCounter();
    }; 
    const currentUser = get(useContext(AuthContext), "currentUser", {});
    return (
        <div> 
            <Modal
                open={modalOpen}
                size={"tiny"}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                closeOnDocumentClick={false}
            >
                <Modal.Header>
                    {/* Session Timeout */}
                    {/* You Have Been Idle! */}
                    {modal.header}
                </Modal.Header>
                <Modal.Content>   
                    {/* You are logged out due to inactivity for more than {REACT_APP_SESSION_TIME} minutes */}
                    {/* You are about to be logged out due to inactivity. Are you still here? */}
                    {modal.msg}
                </Modal.Content> 
                <Modal.Actions>
                    {modal.actions.map((btn:any, i) => (
                        <Button key={i} color={btn.color}  onClick={btn.onClick} >
                            {btn.name} 
                        </Button>
                    ))}
                    
                </Modal.Actions>
            </Modal>
            <IdleTimer
                element={document}
                onIdle={!!currentUser.user || isGuestAuthenticated() ? onIdle:resetTimer}
                timeout={1000 * 60 * Number(REACT_APP_SESSION_TIME) - (1000 * 60 * TimeInterval)}
            />
            <ProgressLoader loading={loading} />
        </div>
      );

}

export default SessionHandler;