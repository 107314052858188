import React, { useContext, useEffect, useRef, useState } from 'react';
import backChevron from '../../../Assets/icons/back-chevron.svg';
import menuIcon from '../../../Assets/icons/hamburguer-menu.svg';
import streamPolygon from '../../../Assets/icons/stream-polygon.svg';
import streamGrid from '../../../Assets/icons/grid.svg';
import { auth } from '../../../Firebase';
import greeTick from '../../../Images/green-tick.png';
import crossTick from '../../../Images/red-cancel.png';
import streamEditIcon from '../../../Images/stream_edit.svg';
import { logEvent } from '../../../Service/LogEvent';
import { EVENT_TYPES, MULTI_STREAM_VIEWER } from '../../../Utils/constants';
import { get } from '../../../Utils/helpers';
import { AuthContext } from '../../../Context/authContext';
import { Button, Grid, Modal, Spin } from 'antd';
import { Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons';
import { FEATURE_NAME } from "../../../Utils/constants";
import { isFeatureIncluded } from '../../../Service/show';

interface Props {
  handleLogoClick: any;
  isGuestViewer: any;
  setShowStreamMenu: any;
  setStreamMenuActive: any;
  activeStreams: any;
  streamLabel: any;
  updatedStreamId: any;
  onStreamSubmit: any;
  updatedStreamLabel: any;
  setUpdatedStreamLabel: any;
  setStreamLabelUpdateMsg: any;
  setUpdatedStreamId: any;
  handleUpdateStreamLabel: any;
  streamLabelUpdateMsg: any;
  showId: any;
  show_name: any;
  show_code: any;
  setStreamLabel: any;
  setStream: any;
  setStreamKey: any;
  fetchVCRoomAndStartOrJoin: any;
  layoutMode: string;
  rpstate: any;
  setRPState: any;
  endorLeaveConferenceLoading:boolean;
  fetchStreamListLoader:boolean;
  isUserInvited:any;
  updateStreamLabelLoader:boolean;
  updateStreamLabelErrorModal:boolean;
  setUpdateStreamLabelErrorModal:(value: boolean) => void;
}

const Sidemenu: React.FC<Props> = ({
  activeStreams,
  handleLogoClick,
  isGuestViewer,
  setShowStreamMenu,
  setStreamMenuActive,
  streamLabel,
  updatedStreamId,
  onStreamSubmit,
  updatedStreamLabel,
  setUpdatedStreamLabel,
  setStreamLabelUpdateMsg,
  setUpdatedStreamId,
  handleUpdateStreamLabel,
  streamLabelUpdateMsg,
  showId,
  show_name,
  show_code,
  setStreamLabel,
  setStream,
  setStreamKey,
  fetchVCRoomAndStartOrJoin,
  layoutMode,
  rpstate,
  setRPState,
  endorLeaveConferenceLoading,
  fetchStreamListLoader,
  isUserInvited,
  updateStreamLabelLoader,
  updateStreamLabelErrorModal,
  setUpdateStreamLabelErrorModal
}) => {

  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  
  const leftBarRef = useRef<HTMLDivElement>(null);

  const [openMenu, setOpenMenu] = useState(false);
  const [menuDisabled, setMenuDisabled] = useState(false);
  const [streamLoader, setStreamLoader] = useState(false);
  const [streamNameEdit, setStreamNameEdit] = useState(false);
  const [streamNameEditBtnDisabled, setStreamNameEditBtnDisabled] = useState(false);
  const [isMultiStreamViewerAllowed, setIsMultiStreamViewerAllowed] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  let user_invited = true;
  const loaderIcon = <LoadingOutlined style={{ fontSize:20,color:"white" }} spin />;
  const streamLoaderIcon = <Loading3QuartersOutlined style={{ fontSize:15,color:"white" }} spin />;

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const checkMultiStreamFeatureAvailability = async () => {
    const isAvailable = await isFeatureIncluded(FEATURE_NAME.ALLOW_STREAM_MULTIVIEWER, showId);

    if (isAvailable) {
      setIsMultiStreamViewerAllowed(true);
    } else {
      setIsMultiStreamViewerAllowed(false);
    }
  }

  useEffect(() => {
    // If join vc room if vc id does not exist(when user comes from publisher page)
    const stream_id = sessionStorage.getItem('stream_id');
    const vc_id = sessionStorage.getItem('vc_id');
    if (!!stream_id && activeStreams.length && !vc_id) {
      const vcRoomIdList = activeStreams.filter((stream: any) => stream.stream_id === stream_id);
      const vc_id = get(vcRoomIdList, '0.vcRoomId', '');
      sessionStorage.setItem('vc_id', vc_id);
      const stream_label = get(vcRoomIdList, '0.stream_label', '');
      joinTroomAndSetStream(stream_id, stream_label, vc_id,true);
    }
    checkMultiStreamFeatureAvailability();
  }, [activeStreams.length]);

  const renderLeftMenuStreamLabels = () => {
    const streamList = activeStreams.map((stream: any, index: number) => {
      let streamId = stream.stream_id;
      let created_user_email = stream.created_user_email;
      const authUserEmail = auth.getUserEmail();
      return (
        <li
          key={streamId}
          data-stream_id={streamId}
          data-stream_label={stream.stream_label}
          onClick={updatedStreamId !== streamId ? handleStreamLabelClick : undefined}
          className={`sidemenu-item stream-selected${
            ((sessionStorage.getItem('stream_label') === stream.stream_label) && (sessionStorage.getItem('stream_id') === streamId) ) ? '-active' : '-inactive'
          }`}
          style={
            endorLeaveConferenceLoading || streamLoader
              ? { pointerEvents: 'none' }
              : {}
          }
        >
          <div className='sidemenu-item-icon stream-button'>
            {(stream.stream_label === sessionStorage.getItem('stream_label') && streamLoader) ||
            ((updatedStreamId === stream.stream_id) && updateStreamLabelLoader) ? (
              streamLoaderIcon
            ) : (
              <img src={streamPolygon} alt='img' />
            )}
          </div>
          {created_user_email === authUserEmail && !streamNameEdit && (
            <span id={streamId} className='sidemenu-hide-collapsed sidemenu-stream-name'>
              { created_user_email === authUserEmail ? !!updatedStreamLabel ? updatedStreamLabel : stream.stream_label : stream.stream_label }
            </span>
          )}
          {created_user_email !== authUserEmail && ( <span id={streamId} className='sidemenu-hide-collapsed sidemenu-stream-name'>
              { created_user_email === authUserEmail ? !!updatedStreamLabel ? updatedStreamLabel : stream.stream_label : stream.stream_label }
            </span>) }
          {created_user_email === authUserEmail && streamNameEdit && (
            <form onSubmit={onStreamSubmit} style={{ position: 'relative', flex: 1 }}>
              <span
                className='stream-label-edit-parent'
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >{console.log("updatedStreamLabel", updatedStreamLabel)}
                <input
                  type='texbox'
                  className='stream-label-edit-box'
                  value={updatedStreamLabel}
                  maxLength={20}
                  onChange={(e: any) => {
                    const { value } = e.target;
                    const streamNameRegex = /^[A-Za-z0-9\_]+$/;
                    e.preventDefault();
                    e.stopPropagation();
                    if (streamNameRegex.test(value)) {
                      setUpdatedStreamLabel(value);
                    } else if (value === '') {
                      setUpdatedStreamLabel('');
                    }
                    if (value === stream.stream_label) {
                      setStreamNameEditBtnDisabled(true);
                    } else {
                      setStreamNameEditBtnDisabled(false);
                    }
                  }}
                />
                <img
                  src={crossTick}
                  className='cancelTick-img cursor-pointer'
                  alt='cancel'
                  onClick={() => {
                    setUpdatedStreamId('');
                    setStreamNameEdit(false)
                    setStreamLabelUpdateMsg('');
                    setUpdatedStreamLabel('')
                  }}
                />
                <button
                  onClick={() => {
                    setStreamNameEdit(false)
                    handleUpdateStreamLabel()
                  }}
                  className='stream-label-submit cursor-pointer'
                  disabled={!/^[A-Za-z0-9\_]+$/.test(updatedStreamLabel) || updatedStreamLabel === '' ||  updatedStreamLabel === stream.stream_label  || streamNameEditBtnDisabled}
                >
                  <img src={greeTick} className='greenTick-img' alt='Save' />
                </button>
              </span>
              {!streamLabelUpdateMsg && (
                <span className='stream-label-helper-text'>
                  Note: Accepts only letters, numbers and underscore{' '}
                </span>
              )}
              {streamLabelUpdateMsg && (
                <span className='stream-label-error-text'>{streamLabelUpdateMsg}</span>
              )}
            </form>
          )}
          {created_user_email === authUserEmail && !streamNameEdit && (
            <span className='sidemenu-hide-collapsed editPencil-padding'>
              <img
                src={streamEditIcon}
                className='editPencil-img float-right'
                style={{ width: '20px', height: '20px' }}
                alt='Edit'
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setStreamNameEdit(true)
                  handleStreamName(stream);
                  setStreamNameEditBtnDisabled(true);
                }}
              />
            </span>
          )}
        </li>
      );
    });

    if (isMultiStreamViewerAllowed && streamList.length > 1) {
      streamList.push(
        <li
          key={MULTI_STREAM_VIEWER}
          data-stream_id={activeStreams[0].stream_id}
          data-stream_label={MULTI_STREAM_VIEWER}
          onClick={handleMultiViewerClick}
          className={`sidemenu-item stream-selected${
            sessionStorage.getItem('stream_label') === MULTI_STREAM_VIEWER ? '-active' : '-inactive'
          }`}
          style={
            endorLeaveConferenceLoading || streamLoader
              ? { pointerEvents: 'none' }
              : {}
          }
        >
          <div className='sidemenu-item-icon stream-button'>
            {(sessionStorage.getItem('stream_label') === MULTI_STREAM_VIEWER) && streamLoader ? (
              streamLoaderIcon
            ) : (
              <img src={streamGrid} alt='multi stream viewer' style={{ width: 22, height: 22, marginLeft: 7 }} />
            )}
          </div>
          <span className='sidemenu-hide-collapsed sidemenu-stream-name'>
            Multi Stream Viewer
          </span>
        </li>
      )
    }

    return streamList;
  };

  const handleStreamName = async (stream: any) => {
    const { stream_id = '', stream_label = '' } = stream;
    setUpdatedStreamId(stream_id);
    setUpdatedStreamLabel(!!updatedStreamLabel ? updatedStreamLabel : stream_label);
  };

  const handleStreamLabelClick = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      event.preventDefault();
      //disable event
      setMenuDisabled(true);
      const stream_id = event.currentTarget.dataset.stream_id || '';
      const stream_label = event.currentTarget.dataset.stream_label || '';
      const vcRoomIdList = activeStreams.filter((stream: any) => stream.stream_id === stream_id)
      const vc_id = get(vcRoomIdList, '0.vcRoomId', '');

      sessionStorage.setItem('stream_id', stream_id);
      sessionStorage.setItem('stream_label', stream_label);
      sessionStorage.setItem('vc_id', vc_id);
      
      sessionStorage.removeItem('publisher'); 

      if (!isGuestViewer) {
        setStreamLoader(true);
        const invited = await isUserInvited(vc_id);
        setStreamLoader(false);
        if (!invited) {
          user_invited = false;
          sessionStorage.removeItem('stream_id');
          sessionStorage.removeItem('stream_label');
          sessionStorage.removeItem('vc_id');
          setStreamLabel('');
          setStream({});
          const videoElement: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
          if(videoElement){
            videoElement.srcObject = null;
          }
          alert('You are not invited to the stream');
          throw new Error('You are not invited to the stream');
        }
      }
      //Close the chat panel
      setRPState(() => ({
        ...rpstate,
        chatWindow: false,
        closeDisable: false,
        chatDisable: true,
        watermarkPanel: false,
        showGuestInvite: false,
        showGuestInviteCloseDisable: false,
        showPublisherPanel: false,
        showPublisherPanelCloseDisable: false,
        hudStatsDisplay: false,
        encoder:false,
      }));

      joinTroomAndSetStream(stream_id,stream_label,vc_id)
      
    } catch (err: any) {
      console.error(err);
    } finally {
      setMenuDisabled(false);
      setShowStreamMenu(true);
      setStreamMenuActive(false);
      // Open chat window
      if (user_invited && !get(screens, 'xs', false)) {
        setTimeout(() => {
          setRPState(() => ({
            ...rpstate,
            chatWindow: true,
            closeDisable: true,
            chatDisable: false,
            watermarkPanel: false,
            showGuestInvite: false,
            showGuestInviteCloseDisable: false,
            showPublisherPanel: false,
            showPublisherPanelCloseDisable: false,
            hudStatsDisplay: false,
            encoder:false,
          }));
        }, 1000);
      }
    }
  };

  const handleMultiViewerClick = async () => {
    try {
      setMenuDisabled(true);
      const stream_id = activeStreams[0].stream_id || '';
      const stream_label = MULTI_STREAM_VIEWER;
      const vcRoomIdList = activeStreams.filter((stream: any) => stream.stream_id === stream_id)
      const vc_id = get(vcRoomIdList, '0.vcRoomId', '');

      sessionStorage.setItem('stream_id', stream_id);
      sessionStorage.setItem('stream_label', stream_label);
      sessionStorage.setItem('vc_id', vc_id);
      sessionStorage.removeItem('publisher'); 

      if (!isGuestViewer) {
        setStreamLoader(true);
        const invited = await isUserInvited(vc_id);
        setStreamLoader(false);
        if (!invited) {
          user_invited = false
          sessionStorage.removeItem('stream_id');
          sessionStorage.removeItem('stream_label');
          sessionStorage.removeItem('vc_id');
          setStreamLabel('');
          setStream({});
          const videoElement: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
          if(videoElement){
            videoElement.srcObject = null;
          }
          alert('You are not invited to the stream');
          throw new Error('You are not invited to the stream');
        }
      }

      //Close the chat panel
      setRPState(() => ({
        ...rpstate,
        chatWindow: false,
        closeDisable: true,
        chatDisable: true,
        watermarkPanel: false,
        showGuestInvite: false,
        showGuestInviteCloseDisable: false,
        showPublisherPanel: false,
        showPublisherPanelCloseDisable: false,
        hudStatsDisplay: false,
        encoder: false
      }))
       
      // Pull VC Room Details and start/join VC
      joinTroomAndSetStream(stream_id, MULTI_STREAM_VIEWER, vc_id);
  
    } catch (err: any) {
      console.error(err);
    } finally {
      setMenuDisabled(false);
      setShowStreamMenu(true);
      setStreamMenuActive(false);
    }
  };
 

  const joinTroomAndSetStream = async (
    stream_id: string,
    stream_label: string,
    vc_id: string,
    streamStartedFlag?: boolean,
  ) => {
    try {
      if (stream_id && stream_label) {
        let stream = { stream_id, stream_label };

        setStream(stream);
        setStreamLabel(stream_label);
        setStreamKey(Math.random());

        // Pull VC Room Details and start/join VC
        fetchVCRoomAndStartOrJoin(stream_id, stream_label);
        //LogEvent
        const streamDetails = {
          stream_id: stream_id,
          stream_label: stream_label,
          show_id: showId,
          show_name: show_name,
          show_code: show_code,
        };
        const streamEvent = logEvent(
          get(authValue, 'accessToken', {}),
          EVENT_TYPES.STREAM_LOG,
          streamDetails,
        );
        console.log(streamEvent);
      }
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
 
  const renderSpin = () =>{
    return(
      <Spin spinning={true} size={'small'} tip={"Loading streams..."} indicator={loaderIcon} />
    )
  }

  return (
    <nav ref={leftBarRef}>
      {!isGuestViewer ? (
        <div
          className={`show-viewer-leftmenu ${
            (openMenu || streamNameEdit) && 'sidemenu-container-open'
          } ${layoutMode === 'inverse' ? 'inverse-layout' : ''}`}
        >
          <div
            className='sidemenu-hamburguer-item sidemenu-item sidemenu-item-border'
            onClick={() => !streamNameEdit && handleOpenMenu()}
          >
            <div className='sidemenu-item-icon'>
              <img src={menuIcon} alt='img' />
            </div>
            <div className='sidemenu-hide-collapsed sidemenu-title'>Menu</div>
          </div>
          <div
            className='sidemenu-back-item sidemenu-item sidemenu-item-border'
            onClick={handleLogoClick}
          >
            <div className='sidemenu-item-icon'>
              <img src={backChevron}  alt='img'/>
            </div>
            <div className='sidemenu-hide-collapsed sidemenu-subtitle'>Back to home</div>
          </div>
          <div className='sidemenu-separator' />
          <div className='sidemenu-spaced-header'>STREAMS</div>
          <ul
            className={
              !menuDisabled ? 'streams-container' : 'streams-container streams-container-disabled'
            }
          >
            { fetchStreamListLoader ? renderSpin() :renderLeftMenuStreamLabels()}
          </ul>
        </div>
      ) : (
        <div
          className={`show-viewer-leftmenu-guestViewer ${
            layoutMode === 'inverse' ? 'inverse-layout' : ''
          } `}
        ></div>
      )}
      <Modal
        open={updateStreamLabelErrorModal}
        centered={true}
        footer={[
          <Button
            type='primary'
            onClick={() => {
              setUpdateStreamLabelErrorModal(false);
              setStreamLabelUpdateMsg("");
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className='btn-close'>{streamLabelUpdateMsg}</div>
      </Modal>
    </nav>
  );
};

export default Sidemenu;
