import React, { useState, useEffect, useContext } from "react";
import { Button, Modal} from "semantic-ui-react";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import UserMessage from "../../Components/Common/UserMessage";
import { PAGES, SUPERADMIN_PAGES, ROLES } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import { convertDateTimeFromUTCEpocWithYear } from "../../Utils/utils";
import { get } from "../../Utils/helpers";
import { logEvent } from "../../Service/LogEvent";
import "./Algolia_custom.css";
import { EVENT_TYPES } from "../../Utils/constants";
import AlertModal from "../../Components/UIKit/AlertModal/AlertModal";
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";
import { fetchActiveUserShows} from "../../Service/show";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Highlight,
  Stats,
  Pagination,
  RefinementList,
  ClearRefinements,
  Configure,
  connectHits,
} from "react-instantsearch-dom";
import { isTrustedDeviceFeatureEnabled } from "../../Utils/featureFlags";
import ImageCanvas from "../../Components/UserSettings/ImageCanvas";
import useNavigateToPage from "../../hooks/useNavigateToPage";


const {
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_USER_INDEX_NAME,
} = process.env;

const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID || "",
  REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

interface ListAllUsersProps {
  role: string
}

const ListAllUsersNew = (props: ListAllUsersProps) => {
  const navigateToPage = useNavigateToPage();
  const currentUser = get(useContext(AuthContext), "currentUser", {} );
  const [refreshState, setRefreshState] = useState(false);
  const [deleteUserDisplay, setDeleteUserDisplay] = useState(false);
  const [deleteUserMsg, setDeleteUserMsg] = useState(<></>);
  const [inactivateShowAdminrDisplay, setInactivateShowAdminDisplay] = useState(false);
  const [inactivateShowAdminMsg, setInactivateShowadminMsg] = useState("");
  const [trustedDeviceEnable, setTrustedDeviceEnable] = useState(false);

  useEffect(() => {
    //console.log("refreshState123", refreshState);

    searchClient.clearCache();
    if (!!refreshState) {
      setRefreshState(false);
    }
  }, [refreshState])

  useEffect(() => {
    // Mixpanel
    searchClient.clearCache();
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SUPER_ADMIN_PAGE + "_" + SUPERADMIN_PAGES.USER_LIST,
    });
    updateTrustedDeviceStatus();
  }, []);

  const updateTrustedDeviceStatus = async () => {
    const trustedDeviceFeature = await isTrustedDeviceFeatureEnabled(get(currentUser, "user.accessToken", ""));
    setTrustedDeviceEnable(!!trustedDeviceFeature)
  };
  
  const Hit = ({ hit, hits }: any) => {
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [removeAlertOpen, setRemoveAlertOpen] = useState(false);
    const [duser, setDeleteUser] = useState({} as any);
    const [resetPasswordAlertOpen, setResetPasswordAlertOpen] = useState(false);
    const [resetPassUser, setResetPassUser] = useState({} as any);
    const [userSuccessMessage, setUserSuccessMessage] = useState("");
    const [initBackToSMS, setInitBackToSMS] = useState(false)

    const handleModalClose = () => {
      setModalOpen(false);
    };

    const sendPasscode = async (user: any) => {
      try {
        setSuccessMessage("");
        setErrorMessage("");
        const user_id = duser.user_id;
        const show_id = duser.show_id;
        const sendPasscodeDetails = {
          user_id,
          show_id,
          target_user_id: user.user_id,
          target_user_email: user.user_email,
        };
        const data = {
          api: api.users.sendpasscode,
          payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
        };

        setLoading(true);
        const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        console.log(result);

        if (result) {
          const sendPasscodeEvent = logEvent(get(currentUser, 'accessToken', ''), EVENT_TYPES.SEND_PASSCODE, sendPasscodeDetails);
          if (trustedDeviceEnable) {
            const device = {
              api: api.trustedDevice.deactivateAllDevices,
              payLoad: {
                userId: user.user_id
               }
            }
            await backend.remove(device, get(currentUser, "user.accessToken", ""));
            Mixpanel.track("Admin User Action", {
              Platform: "Web",
              "Action Email": duser.user_email,
              "Action Type": "SendPasscode",
              "Admin Type": "SuperAdmin",
            });
          }

          if (result.indexOf('temporary password') > -1) {
            setUserSuccessMessage(result);
          } else {
            setSuccessMessage(
              "You have sent 8 digit passcode successfully to user mobile"
            );

            if (!modalOpen) {
              setModalOpen(true);
            }
          }
        }
      } catch (err: any) {
        const msg = "Error while sending passcode to user";
        console.error(msg);
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const handleDeleteCancel = () => {
      setDeleteAlertOpen(false);
    };

    const handleDeleteConfirm = async () => {
      setDeleteAlertOpen(false);
      await deleteUser();
      setRefreshState(true);
    };

    const showDeleteConfirmAlert = (user: any) => {
      setDeleteAlertOpen(true);
      setDeleteUser(user);
    };

    const deleteUser = async () => {
      try {
        setSuccessMessage("");
        setErrorMessage("");
        const user_id = duser.user_id;
        const user_email = duser.user_email;
        const data = {
          api: api.users.delete,
          urlParam: user_id,
        };
        const deleteUserDetails = {
          user_id,
          user_email,
        };
        setLoading(true);
        const result = await backend.remove(data, get(currentUser, "user.accessToken", ""));
        await searchClient.clearCache();
        setLoading(false);

        if (result) {
          const device = {
            api: api.trustedDevice.deactivateAllDevices,
            payLoad: {
              userId: duser.user_id
            }
          }
          await backend.remove(device, get(currentUser, "user.accessToken", ""));
          setSuccessMessage(result);
          setDeleteUserDisplay(true);
          setDeleteUserMsg(<p>User <span className="bold-font">"{user_email}"</span> has been successfully deleted.</p>);
          const deleteUserEvent = logEvent(get(currentUser, 'accessToken', ''), EVENT_TYPES.USER_DELETION, deleteUserDetails);
          console.log("User Deletion:", deleteUserEvent);
          Mixpanel.track("Admin User Action", {
            Platform: "Web",
            "Action Email": user_email,
            "Action Type": "DeleteUser",
            "Admin Type": "SuperAdmin",
          });
        }
      } catch (err: any) {
        const msg = "Error while deleting user";
        console.error(msg);
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const handleDeleteCancelShowUser = () => {
      setRemoveAlertOpen(false);
    };

    const handleDeleteShowUserConfirm = async () => {
      setRemoveAlertOpen(false);
      await deleteShowUser();
      setRefreshState(true);
    };

    const DeleteShowUserConfirmAlert = (user: any) => {
      setRemoveAlertOpen(true);
      setDeleteUser(user);
    };

 

    //User Delete From Specific Show
    const deleteShowUser = async () => {
      try {
        setSuccessMessage("");
        setErrorMessage("");
        const show_id = duser.show_id;
        const user_id = duser.user_id;
        const deleteShowUserDetails = {
          show_id,
          user_id,
        };

        const data = {
          api: api.users.deleteShowUser,
          urlParam: user_id,
          queryParam: { show_id },
        };
        setLoading(true);
        const result = await backend.remove(data, get(currentUser, "user.accessToken", ""));
        await searchClient.clearCache();
        const showList = (await fetchActiveUserShows(user_id))?.hits || [];
        const isUserPartOfOtherShows = showList?.find((show: any) => show_id && show.show_id !== show_id);
        if (result) {
          const device = {
            api: api.trustedDevice.deactivateAllDevices,
            payLoad: {
              userId: duser.user_id
            }
          }
          if (!isUserPartOfOtherShows)
            await backend.remove(device, get(currentUser, "user.accessToken", ""));
          const deleteShowUserEvent = logEvent(get(currentUser, 'accessToken', ''), EVENT_TYPES.SHOW_USER_DELETION, deleteShowUserDetails);
          console.log("Show User Deletion:", deleteShowUserEvent);
          Mixpanel.track("Admin User Action", {
            Platform: "Web",
            "Action Email": duser.user_email,
            "Action Type": "DeleteUser",
            "Admin Type": "SuperAdmin",
          });
        }
      } catch (err: any) {
        const msg = "Error while deleting user";
        console.error(msg);
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };


    const handleDeleteConfirm2 = async () => {
      setDeleteAlertOpen(false);
      await deleteShowUser();
      setRefreshState(true);
    };


    const inactiveUser = async (user: any) => {
      try {
        if (!user || (user && user.user_status !== "Active")) {
          console.log("User is not active");
          throw new Error("User is not active");
        }

        setSuccessMessage("");
        setErrorMessage("");

        const data = {
          api: api.users.inactivateUser,
          payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
        };

        setLoading(true);
        await backend.save(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        setRefreshState(true);

        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": user.user_email,
          "Action Type": "InactivateUser",
          "Admin Type": "SuperAdmin",
        });
      } catch (err: any) {
        const msg = "Error while inactivating user";
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const activateUser = async (user: any) => {
      try {
        if (!user || (user && user.user_status === "Active")) {
          console.log("User is already active");
          throw new Error("User is already active");
        }
        const user_id = duser.user_id;
        const show_id = duser.show_id;
        const activateAccountDetails = {
          user_id,
          show_id,
          target_user_id: user.user_id,
          target_user_email: user.user_email,
        };
        setSuccessMessage("");
        setErrorMessage("");

        const data = {
          api: api.users.activateUserBySuperAdmin,
          payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
        };

        setLoading(true);
        await backend.save(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        setRefreshState(true);
        const ActivateUserEvent = logEvent(get(currentUser, 'accessToken', ''), EVENT_TYPES.ACTIVATE_USER, activateAccountDetails);
        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": user.user_email,
          "Action Type": "ActivateUser",
          "Admin Type": "SuperAdmin",
        });
      } catch (err: any) {
        const msg = "Error while activating user";
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const inactivateShowAdmin = async (user: any) => {
      try {
        if (!user) {
          throw new Error("No user");
        }
        console.log("inactivateShowAdmin");
        setSuccessMessage("");
        setErrorMessage("");

        const data = {
          api: api.users.inactivateShowadmin,
          payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
        };

        setLoading(true);
        const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);

        if (result) {

          setInactivateShowAdminDisplay(true)
          setInactivateShowadminMsg(`User "${user.user_email}" has been successfully inactivated`)
          Mixpanel.track("Admin User Action", {
            Platform: "Web",
            "Action Email": user.user_email,
            "Action Type": "InactivateShowAdmin",
            "Admin Type": "SuperAdmin",
          });
          setRefreshState(true);
        }
      } catch (err: any) {
        const msg = "Error while removing showadmin role";
        console.error(msg);
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const inactivateTeamAdmin = async (user: any) => {
      try {
        if (!user) {
          throw new Error("No user");
        }
        console.log("inactivateTeamAdmin");
        setSuccessMessage("");
        setErrorMessage("");

        const data = {
          api: api.users.inactivateTeamadmin,
          payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
        };

        setLoading(true);
        const result = await backend.save(data, get(currentUser, "user.accessToken", ""));        
        setLoading(false);
        setRefreshState(true);
        console.log(result)

        if (result) {
          Mixpanel.track("Admin User Action", {
            Platform: "Web",
            "Action Email": user.user_email,
            "Action Type": "InactivateShowAdmin",
            "Admin Type": "SuperAdmin",
          });
        }
      } catch (err: any) {
        const msg = "Error while removing team admin role";
        console.error(err);
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const backToSms = async (user: any) => {
      console.log('its here')
      try {
        if (!user || (user && user.two_fa_type && user.two_fa_type === "SMS")) {
          throw new Error("User's 2fa is already set to SMS");
        }

        setSuccessMessage("");
        setErrorMessage("");

        const data = {
          api: api.users.editTwoFactorAuth,
          urlParam: user.user_id,
          payLoad: JSON.stringify({ two_fa_type: 'SMS' }),
        };

        setLoading(true);
        const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
        console.log('check back succes:', result)
        setRefreshState(true);
      } catch (err: any) {
        const msg = "Error while changing 2fa";
        console.log('check back failed:', err)

        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const handleSetToSMS = async (user: any) => {
      setRefreshState(true);
      setInitBackToSMS(false)
      backToSms(user)
      setLoading(true);
    }

    //Reset password
    const showResetPasswordAlert = (user: any) => {
      setResetPasswordAlertOpen(true);
      setResetPassUser(user);
    };

    const handleResetPasswordConfirm = async () => {
      setResetPasswordAlertOpen(false);
      await resetUserPassword(resetPassUser);
      setRefreshState(true);
    };

    const resetUserPassword = async (user: any) => {
      try {
        if (!user || (user && user.user_status !== "Active")) {
          console.log("User is not active");
          throw new Error("User is not active");
        }

        setSuccessMessage("");
        setErrorMessage("");

        const data = {
          api: api.users.resetUserpasswordByAdmin,
          payLoad: JSON.stringify({ id: user.user_id, show_id: user.show_id }),
        };

        setLoading(true);
        const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        setRefreshState(true);
        if (result) {
          if (trustedDeviceEnable) {
            const device = {
              api: api.trustedDevice.deactivateAllDevices,
              payLoad: {
                userId: user.user_id
              }
            }  
            await backend.remove(device, get(currentUser, "user.accessToken", ""));
          }
          setSuccessMessage(
            "You have successfully sent the reset password email to user"
          );
          if (!modalOpen) {
            setModalOpen(true);
          }
        }
        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": user.user_email,
          "Action Type": "ResetPassword",
          "Admin Type": "SuperAdmin",
        });
      } catch (err: any) {
        const msg = "Error while resetting user passwd";
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    const renderSendPasscode = (user: any) => {
      if (user && user.user_status !== "Active") {
        return;
      }
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          onClick={() => {
            sendPasscode(user);
          }}
        >
          SEND PASSCODE
        </button>
      );
    };

    const renderDeleteButton = (user: any) => {
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          onClick={() => {
            showDeleteConfirmAlert(user);
          }}
        >
          DELETE USER
        </button>
      );
    };


    const renderDeleteShowUserButton = (user: any) => {
      if (
        (user && user.role_type === "SuperAdmin") ||
        (user && user.role_type === "ShowFinance")
      ) {
        return;
      }
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          onClick={() => {
            DeleteShowUserConfirmAlert(user);
          }}
        >
          REMOVE USER FROM SHOW
        </button>
      );
    };
    const renderInactiveButton = (user: any) => {
      if (user && user.user_status !== "Active") {
        return;
      }
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          onClick={() => {
            inactiveUser(user);
          }}
        >
          INACTIVATE USER
        </button>
      );
    };

    const renderActiveButton = (user: any) => {
      if (user && user.user_status === "Active") {
        return;
      }
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          color="violet"
          onClick={() => {
            activateUser(user);
          }}
        >
          ACTIVATE USER
        </button>
      );
    };

    const renderShowAdminInactiveButton = (user: any) => {
      if (user && user.role_type !== "ShowAdmin") {
        return;
      }
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          onClick={() => {
            inactivateShowAdmin(user);
          }}
        >
          INACTIVATE SHOWADMIN
        </button>
      );
    };

    const renderTeamAdminInactiveButton = (user: any) => {
      if (user && !user.team_admin) {
        return;
      }
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          onClick={() => {
            inactivateTeamAdmin(user);
          }}
        >
          INACTIVATE TEAMADMIN
        </button>
      );
    };

    const renderResetPasswordButton = (user: any) => {
      if (user && user.user_status !== "Active") {
        return;
      }
      return (
        <button
          style={{ marginBottom: "5px", color: "blue" }}
          onClick={() => {
            showResetPasswordAlert(user);
          }}
        >
          RESET PASSWORD
        </button>
      );
    };


    const renderButtonsForSuperAdmin = () => {
      if (props.role === ROLES.SUPER_ADMIN) {
        return (
          <>
            <div>{renderSendPasscode(hit)}</div>
            <div>{renderDeleteButton(hit)}</div>
            {/* <div>{renderInactiveButton(hit)}</div>  client doesn't want this button in the page  */}
            <div>{renderActiveButton(hit)}</div>
            <div>{renderDeleteShowUserButton(hit)}</div>
            <div>{renderShowAdminInactiveButton(hit)}</div>
            <div>{renderTeamAdminInactiveButton(hit)}</div>
            <div>{renderBackToSmsButton(hit)}</div>
            <div>{renderResetPasswordButton(hit)}</div>
          </>
        )
      }
    }

    const renderBackToSmsButton = (user: any) => {
      if (user && user.two_fa_type && user.two_fa_type !== 'SMS') {
        console.log("user.two_fa_type", user, user.two_fa_type)
        return (
          <button
            style={{ marginBottom: "5px", color: "blue" }}
            onClick={() => {
              setInitBackToSMS(true)
            }}
          >
            2FA Back to SMS
          </button>
        );
      }
      return;
    };

    let name = "";
    if (hit && hit.user_name && hit.user_name.user_name_first) {
      name = name + hit.user_name.user_name_first;
    }
    if (hit && hit.user_name && hit.user_name.user_name_last) {
      name = name + " " + hit.user_name.user_name_last;
    }
    let formatted_created_time = ""
    if (hit && hit.created_ts && hit.created_ts._seconds) {
      const created_ts_timestamp = hit.created_ts._seconds
      formatted_created_time = convertDateTimeFromUTCEpocWithYear(created_ts_timestamp);
    }
    let formatted_last_login_time = ""
    if (hit && get(hit, "last_login_time", "") && get(hit, "last_login_time._seconds", "")) {
      const created_ts_timestamp = hit.last_login_time._seconds
      formatted_last_login_time = convertDateTimeFromUTCEpocWithYear(created_ts_timestamp);
    }
    return (
      <div>
        <ProgressLoader loading={loading} />
        {
          userSuccessMessage && <UserMessage successMsg={userSuccessMessage} />
        }
        <UserMessage errorMsg={errorMessage} />

        <div className="hit">
          <div className="avatar">
            {hit.user_image_url_path && (<ImageCanvas imageUrl={hit.user_image_url_path} className={
                     !!hit.user_image_url_path
                       ? "user_list_image"
                       : "user_list_image user_no_image"
                   } />
            )}
          </div>

          <div className="hit-content">
            <div>Title : {hit.user_title ? hit.user_title : ""}</div>
            <div>Name : {name}</div>
            <div>Phone : {hit.country_code + hit.user_phone}</div>

            <div className="hit-name">
              <div className="hit-email">
                Email : <Highlight attribute="user_email" hit={hit} />
              </div>
            </div>

            <div className="hit-name">
              <div>
                Show Name : <Highlight attribute="show_name" hit={hit} />
              </div>
            </div>

            <div>Role : {hit.role_type}</div>
            <div>User Status: {hit.user_status}</div>
            <div>Last Login Time: {formatted_last_login_time}</div>
            <div>Date Added: {formatted_created_time}</div>
            {renderButtonsForSuperAdmin()}
          </div>
        </div>

        <ConfirmationalPopup
          open={deleteAlertOpen}
          data={"This opeartion will delete user from system, do you want to continue ?"}
          onCancel={handleDeleteCancel}
          onOk={handleDeleteConfirm}
        />



        <ConfirmationalPopup
          open={initBackToSMS}
          data={"Are you sure you want to set SMS?"}
          onCancel={() => setInitBackToSMS(false)}
          onOk={() => handleSetToSMS(hit)}
        />

        <ConfirmationalPopup
          open={removeAlertOpen}
          data={getRemoveUserDetails(hit.show_id, hit.user_id, hits)}
          onCancel={handleDeleteCancelShowUser}
          onOk={handleDeleteShowUserConfirm}
        />

        <ConfirmationalPopup
          open={resetPasswordAlertOpen}
          data={"Are you sure you want to Reset Password for the selected user?"}
          onCancel={() => setResetPasswordAlertOpen(false)}
          onOk={handleResetPasswordConfirm}
        />
        


        <Modal open={modalOpen} onClose={handleModalClose} basic size={"tiny"}>
          <Modal.Content>
            <h3>{successMessage}</h3>
          </Modal.Content>

          <Modal.Actions>
            <Button
              loading={loading}
              color="violet"
              inverted
              onClick={handleModalClose}
            >
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  };

  const Hits = ({ hits }: any) => {
    return (
      <ul className="ais-Hits-list">
        {hits.map((hit: any) => (
          <li className="ais-Hits-item">
            <Hit key={hit.objectID} hit={hit} hits={hits} />
          </li>
        ))}
      </ul>
    );
  };

  //filtering show name and  user roles
  const getRemoveUserDetails = (
    show_id: string,
    user_id: string,
    hits: any
  ) => {
    const detailsData = hits
      .filter((data: any) => data.show_id === show_id && data.user_id === user_id);
    return (
      <ul>
        <li>
          <strong>Show Name</strong> :&nbsp;
          {get(detailsData, '0.show_name', '-')}
        </li>
        <br></br>
        <li>
          <strong>Roles</strong> :&nbsp;
          {detailsData.map((data: any) => data.role_type)
            .join(",  ")}
        </li>
      </ul>
    );
  };
  const CustomHits = connectHits(Hits);

  return (
    <div className="list-show-users">
      <div className="list-heading">
      <div className="find-details">
          <button className="find-details-button"
          onClick={() => navigateToPage('/finduser')} >
              Find User Details
          </button>
      </div>
        <p className="heading-text">All Users</p>
      </div>

      <InstantSearch
        indexName={REACT_APP_ALGOLIA_USER_INDEX_NAME || ""}
        searchClient={searchClient}
        refresh={refreshState}
        key={Math.random()}
      >
        <header>
          <SearchBox translations={{ placeholder: "Search" }} />
        </header>
        <main>
          <div id="left-column">
            <h5>Shows</h5>
            <ClearRefinements />
            <RefinementList attribute="show_name" searchable limit={5}
              showMore={true} showMoreLimit={1000} />
            <Configure hitsPerPage={20} />
            {/* <h5>Roles</h5>
            <Menu attribute="role_type" /> */}
            <h5>User Roles</h5>
            <RefinementList attribute="role_type" limit={5} showMore={true} />
            <h5>User Status</h5>
            <RefinementList attribute="user_status" limit={5} showMore={true} />
          </div>
          <div id="right-column">
            <div className="pagination">
              <Pagination showLast />
            </div>
            <div className="info">
              <Stats />
            </div>
            {/* <Hits hitComponent={Hit} /> */}
            <CustomHits />
          </div>
        </main>
      </InstantSearch>
      <AlertModal
        onOk={() => setDeleteUserDisplay(false)}
        onCancel={() => setDeleteUserDisplay(false)}
        data={deleteUserMsg}
        open={deleteUserDisplay}
      />
      <AlertModal
        onOk={() => setInactivateShowAdminDisplay(false)}
        onCancel={() => setInactivateShowAdminDisplay(false)}
        data={inactivateShowAdminMsg}
        open={inactivateShowAdminrDisplay}
        />
    </div>
  );
};
export default ListAllUsersNew;
