import React, { useEffect, useState, useRef, memo, useContext } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import Avatar from 'react-avatar';
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../Common/ProgressLoader";
import Delete from "../../Images/delete_th.svg";
import DeleteDisabled from "../../Images/delete_grey.svg";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import { useLocationStore } from "../../store/useLocationStore";

const GuestInvite = (props: any) => {

    const {usersUpdated, setUsersUpdated} = props;
    const location: any = useLocation();
    
    const locationState = useLocationStore((state) => state.locationState);
    const currentUser = get(useContext(AuthContext), "currentUser", {});
    const show_id = locationState.state ? locationState.state.show_id : "";
    const stream_id = sessionStorage.getItem("stream_id");

    const [open, setOpen] = useState(false);
    const [usersList, setUsersList] = useState([] as any[]);
    const [participantsList, setParticipantsList] = useState([] as any[]);
    const [showUserInviteInfo, setShowUserInviteInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [getParticipantLoading, setParticipantLoading] = useState(false);
    const [userInviteloading, setUserInviteLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [expiryTime, setExpiryTime] = useState("");

    const [pin, setPin] = useState("");
    const [guestURL, setGuestURL] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    //StreamConfigList
    const [configValue, setConfigValue] = useState(0);
    const [streamConfigId, setStreamConfigId] = useState("");

    const [configActualValue, _setConfigActualValue] = useState(0);
    const configActualValueRef = useRef(configActualValue);
    const setConfigActualValue = (data: number) => {
        configActualValueRef.current = data;
        _setConfigActualValue(data);
    };

    const [maxNumberofGuestInvited, _setMaxNumberofGuestInvited] = useState(false);
    const maxNumberofGuestInvitedRef = useRef(maxNumberofGuestInvited);
    const setMaxNumberofGuestInvited = (data: boolean) => {
        maxNumberofGuestInvitedRef.current = data;
        _setMaxNumberofGuestInvited(data);
    };


    //Get Config Details
    const getStreamConfig = async () => {
        try {
            const data = {
                api: api.configs.listStreamConfigsByStreamId,
                queryParam: { config_name: "stream_guest_count", show_id, stream_id }
            };
            setLoading(true);
            const response = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
            if (response.length > 0) {
                setStreamConfigId(response[0].id);
                setConfigActualValue(response[0].config_actual_value);
                setConfigValue(response[0].config_value);
                setMaxNumberofGuestInvited(response[0].config_actual_value >= response[0].config_value);
            }


            setLoading(false);
        } catch (err: any) {
            const msg = "Error getting config info";
            console.error(msg);
            if (err.errMessage) {
                console.error(err.errMessage);
            }
        } finally {
            setLoading(false);
        }
    }

    const getTwilioRoomsParticipants = async () => {
        let participantsList: any = [];
        const { troom } = props;
        try {
            console.log("** Get Twilio Room Participants details");
            const data = {
                api: api.vc.twilioRoomParticipantsList,
                urlParam: troom.sid,
                queryParam: {
                    show_id: show_id,
                },
            };
            setParticipantLoading(true);
            const roomParticipants = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
            setParticipantLoading(false);
            roomParticipants.forEach(((user: any) => {
                if (user?.identity.length > 0) {
                    const userId = user?.identity.substring(
                        user?.identity.lastIndexOf("#") + 1,
                        user?.identity.lastIndexOf("_")
                    );
                    participantsList.push(userId);
                }
            }))
            setParticipantsList(participantsList);
        } catch (err: any) {
            console.error(err);
        } finally {
            setParticipantLoading(false);
        }
    };

    const getGuestUsersList = async () => {
        setLoading(true);
        try {
            const result = await backend.fetch({
                api: api.guestUsers.getGuestUsersList,
                queryParam: { show_id, stream_id }
            }, get(currentUser, "user.accessToken", ""));
            setUsersList(result);
            props.setGuestUsersList(result);
            console.log('result :>> ', result);
        }
        finally {
            setLoading(false);
            setShowUserInviteInfo(false);
        }

    }

    useEffect(() => {
        getStreamConfig();
        getGuestUsersList();
        getTwilioRoomsParticipants();
    }, [])

    useEffect(()=>{
        if(usersUpdated){
            getTwilioRoomsParticipants();
            setUsersUpdated(false);
        }
    }, [usersUpdated]);

    const handleInviteGuest = async () => {
        // setOpen(false);
        try {
            await getStreamConfig();

            var expiry = new Date();
            let expTime: any = parseInt(expiryTime);

            const userData = {
                "user_title": "Mr",
                "user_name_first": firstName,
                "user_name_last": lastName,
                "user_email": email,
                "user_phone": mobileNumber,
                "country_code": "+1",
                "show_id": show_id,
                "stream_id": stream_id,
                "expiry": Math.round((expiry.getTime() + (expTime * 60 * 60 * 1000)))
            }

            console.log('userData :>> ', userData);

            const data = {
                api: api.guestUsers.inviteGuestUser,
                payLoad: JSON.stringify(userData),
            };
            setUserInviteLoading(true);
            const result: any = await backend.save(data, get(currentUser, "user.accessToken", ""));
            if (result?.token) {
                setGuestURL(`${window.location.href.replace(location.pathname, '')}/gl`)
                setPin(result.token);
                setShowUserInviteInfo(true);
                setErrorMsg("");
            }

            const configInput = {
                show_id,
                stream_id,
                stream_config_id: streamConfigId,
                config_value: configValue,
                config_actual_value: configActualValueRef.current + 1
            }
            const config = {
                api: api.configs.updateStreamConfigs,
                payLoad: JSON.stringify(configInput),
            };

            await backend.save(config, get(currentUser, "user.accessToken", ""));
        }
        catch (err: any) {
            setErrorMsg(err.errMessage);
        }
        finally {
            setUserInviteLoading(false);
        }
    }

    const handleCancel = () => {
        if (showUserInviteInfo) {
            getGuestUsersList();
        }
        setOpen(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setExpiryTime("");
        setPin("");
        setGuestURL("");
        setErrorMsg("");
    }

    let style = {
        "zIndex": 1005,
        "backgroundColor": "#1c1d1d"
    }

    const handleDelete = async (user_id: any, joined: boolean) => {
        if (joined) {
            return;
        } else {
            try {
                await getStreamConfig();
                const data = {
                    api: api.guestUsers.unInviteGuestUser,
                    urlParam: user_id,
                    queryParam: { show_id }
                };
                setLoading(true);
                await backend.remove(data, get(currentUser, "user.accessToken", ""));
                const configInput = {
                    show_id,
                    stream_id,
                    stream_config_id: streamConfigId,
                    config_value: configValue,
                    config_actual_value: configActualValueRef.current - 1
                }
                const config = {
                    api: api.configs.updateStreamConfigs,
                    payLoad: JSON.stringify(configInput),
                };

                await backend.save(config, get(currentUser, "user.accessToken", ""));

            }
            finally {
                getGuestUsersList();
            }
        }
    }

    const handleCopy = (value: any) => {
        console.log('value :>> ', value);
        const copyElement = document.createElement("textarea");
        document.body.appendChild(copyElement);
        copyElement.value = value;
        copyElement.select();
        document.execCommand("copy");
        document.body.removeChild(copyElement);
    }

    return (
        <div className="guest-panel-wrapper show-viewer">
            <nav className="main-menu-right-chat" id="guest-invite-tray">
                <ProgressLoader loading={loading || getParticipantLoading} />
                <div className="chat-h1">
                    <div className="guest-invite-header">
                        <Modal
                            onOpen={() => setOpen(true)}
                            size="mini"
                            open={open}
                            trigger={<span className="guest-invite-modal-trigger">+</span>}
                            style={style}
                        >
                            {
                                maxNumberofGuestInvitedRef.current ?
                                    <> <Modal.Header>Number of Guest Users Limit Reached</Modal.Header>
                                        <Modal.Content>
                                            You have already invited {configValue} guest users. Please remove one guest user to add another
                                            </Modal.Content>
                                        <Modal.Actions>
                                            <Button color="green" onClick={() => setOpen(false)}>
                                                CLOSE
                                                </Button>
                                        </Modal.Actions> </> :
                                    <div className="guest-invite-modal-content">
                                        <ProgressLoader loading={userInviteloading} />
                                        <Modal.Header>
                                            <div className="guest-invite-modal-header"><b>Publisher: </b> Quick Invite</div>
                                        </Modal.Header>
                                        <Modal.Content>
                                            <Modal.Description>
                                                <div className="guest-invite-form">
                                                    {showUserInviteInfo &&
                                                        <>
                                                            <div className="guest-user-info">
                                                                <span><b>Guest URL: </b></span>
                                                                <span>{guestURL}</span>
                                                            </div>
                                                            <div className="guest-user-info">
                                                                <span><b>PIN: </b></span>
                                                                <span>{pin}</span>
                                                                <span className='duplicate-icon' data-tooltip='Copy' onClick={() => handleCopy(pin)} />
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="form-field">
                                                        <label className="form-input-label">First Name</label>
                                                        <input
                                                            className="form-input"
                                                            type="text"
                                                            value={firstName}
                                                            onChange={(e) => { setFirstName(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="form-field">
                                                        <label className="form-input-label">Last Name</label>
                                                        <input
                                                            className="form-input"
                                                            type="text"
                                                            value={lastName}
                                                            onChange={(e) => { setLastName(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="form-field">
                                                        <label className="form-input-label">Email Address</label>
                                                        <input
                                                            className="form-input"
                                                            type="text"
                                                            value={email}
                                                            onChange={(e) => { setEmail(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="form-field">
                                                        <label className="form-input-label">Mobile Number</label>
                                                        <input
                                                            className="form-input"
                                                            type="number"
                                                            value={mobileNumber}
                                                            onChange={(e) => { setMobileNumber(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="form-field expiryTime">
                                                        <label className="form-input-label">Expires in </label>
                                                        <input
                                                            className="form-input"
                                                            type="number"
                                                            value={expiryTime}
                                                            onChange={(e) => { setExpiryTime(e.target.value) }}
                                                        />
                                                        <label className="form-input-label"> hours</label>
                                                    </div>

                                                    {
                                                        errorMsg !== "" &&
                                                        <div className="form-field">
                                                            <label className="form-input-error-msg">{errorMsg}</label>
                                                        </div>
                                                    }
                                                </div>

                                            </Modal.Description>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <div className="publisher-actions">
                                                <div>
                                                    <button onClick={handleCancel} className="guest-invite-action-button">
                                                        {showUserInviteInfo ? "Close" : "Cancel"}
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={handleInviteGuest}
                                                        className="guest-invite-action-button"
                                                        disabled={
                                                            firstName === "" ||
                                                            lastName === "" ||
                                                            email === "" ||
                                                            mobileNumber === "" ||
                                                            expiryTime === "" || showUserInviteInfo
                                                        }
                                                    >
                                                        Invite Guest
                                        </button>
                                                </div>
                                            </div>
                                        </Modal.Actions>
                                    </div>
                            }
                        </Modal>
                        <span className="chat-text">Guest List</span>
                    </div>
                </div>
                <div className="guest-invite-content">
                    <div className="publisher-panel-content">
                        <div className="publisher-panel-item">
                            <div className="publisher-panel-image">
                            </div>
                            <div className="publisher-panel-user">
                            </div>
                            <div className="publisher-panel-action">
                            </div>
                            <div className="publisher-panel-status">
                                Joined
                            </div>
                        </div>
                        {
                            usersList.map((user: any, index: number) => {
                                let style = {
                                    background:
                                        'transparent 0% 0% no-repeat padding-box',
                                };
                                let backgroundColor = "red";
                                const joined: boolean = participantsList.includes(user?.user_id);
                                if (joined) { backgroundColor = "rgb(27, 204, 115)" }
                                return (
                                    <div className="publisher-panel-item" key={index}>
                                        <div className="publisher-panel-image">
                                            {/* <div className="chat-image" style={style}></div> */}
                                            <Avatar name={`${user?.user_name?.user_name_first} ${user?.user_name?.user_name_last}`} round={true} size="40px" />
                                        </div>
                                        <div className="publisher-panel-user">
                                            <div>{`${user?.user_name?.user_name_first} ${user?.user_name?.user_name_last}`}</div>
                                            <div>{`PIN: ${user?.user_token}`}</div>
                                        </div>
                                        <div className="publisher-panel-action">
                                            <span className='duplicate-icon' data-tooltip='Copy' onClick={() => handleCopy(user?.user_token)} />
                                            <img className={joined ? "delete_image disabled" : "delete_image"} src={joined ? DeleteDisabled : Delete} alt="" onClick={() => handleDelete(user?.user_id, joined)} />
                                        </div>
                                        <div className="publisher-panel-status">
                                            <div className="publisher-panel-status-circle" style={{ backgroundColor: backgroundColor }}>
                                            </div>
                                        </div>
                                    </div>);
                            })
                        }
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default memo(GuestInvite);

