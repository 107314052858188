import { notification } from 'antd';
import { useEffect, useState } from 'react';
import useDbTrigger from '../../../hooks/useDbTrigger';

let lockedGuests: any = [];

export default function useLockedGuestList(
  //   notificationList: any,
  mediaStream: any,
  //   updated: boolean,
  //   setUpdated: (value: boolean) => void,
  isGuestInviteIncluded: boolean,
) {
  const [lockedGuestList, setLockedGuestList] = useState([]);
  const notificationList = useDbTrigger('fe_notifications', isGuestInviteIncluded && !!mediaStream);

  useEffect(() => {
    async function checkLockedGuests() {
      const guestName = notificationList?.doc?.data()?.notification_metadata?.guestName;
      const guestId = notificationList?.doc?.data()?.notification_metadata?.guestId;

      const isPublisher = sessionStorage.getItem('publisher') === 'true' || false;
      const streamIdRef = sessionStorage.getItem('stream_id');

      const roomIsEmpty: boolean = !mediaStream;

      if (!isPublisher || !isGuestInviteIncluded) {
        return;
      }

      if (streamIdRef === notificationList?.doc?.data()?.notification_metadata?.streamID) {
        if (
          !!guestName &&
          !lockedGuests.some(
            (guest: any) => guest.guestName === guestName && guest.guestId === guestId,
          ) &&
          notificationList?.type !== 'removed' &&
          isPublisher &&
          !roomIsEmpty
        ) {
          notification.open({
            message: notificationList?.doc?.data()?.notification_message,
            description: `${guestName} is locked.`,
            placement: 'bottomRight',
          });

          lockedGuests.push({ guestName: guestName, id: guestId });
        } else if (notificationList?.type === 'removed') {
        //   const indexName = lockedGuests.indexOf(guestName);

        //   lockedGuests.splice(indexName, 1);
          lockedGuests = lockedGuests.filter(
            (guest: any) => !(guest.guestName === guestName && guest.guestId === guestId),
          );
        }
        setLockedGuestList(lockedGuests);
        // setUpdated(!updated);
      }
    }
    checkLockedGuests();
  }, [notificationList]);

  return lockedGuestList;
}
