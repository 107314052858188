import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getPerformance } from "firebase/performance";

import { config } from './firebase.config';

// Initialize Firebase
export const app = initializeApp(config);

const analyticsData = getAnalytics(app);
const storageData = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore();
export const functions = getFunctions(app);
// Initialize Performance Monitoring and get a reference to the service
export const performance  = getPerformance(app);
export const storage = {
  storageData,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
};
export const analytics = {
  analyticsData,
  logEvent,
};
