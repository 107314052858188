import { useCallback, useEffect } from 'react';
import { Room } from 'twilio-video';
import useSessionStorage from '../../Utils/useSessionStorage';
import useLocalAudioTrack from './useLocalAudioTrack';
import { isIOS } from '../../Utils/helpers';


export default function useNoiseCancellation(room: Room | undefined) {
  const localAudioTrack = useLocalAudioTrack(room);
  const [cancelNoise, _setCancelNoise, clearStorage] = useSessionStorage<boolean>('cancelNoise', false, false);

  const updateNoiseCancellationState = useCallback((cancel: boolean) => {
    if (localAudioTrack) {
      const { noiseCancellation } = localAudioTrack;
      try {
        if (!noiseCancellation) {
          throw new Error('No noise cancellation available');
        }
        
        if (cancel) {
          noiseCancellation.enable();
        } else {
          noiseCancellation.disable();
        }
        return true;
      } catch(err) {
        // TODO: show proper error to the user
        console.error('Error setting noise cancellation', err);
        return false;
      }
    }
  }, [localAudioTrack]);

  const setCancelNoise = useCallback((cancel: boolean) => {
    if (localAudioTrack) {
      const updateSuccessful = updateNoiseCancellationState(cancel);
      if (updateSuccessful) {
        _setCancelNoise(cancel);
      }
    }
  }, [_setCancelNoise, localAudioTrack, updateNoiseCancellationState]);

  const clear = useCallback(() => {
    clearStorage();
    if (localAudioTrack) {
      updateNoiseCancellationState(false);
    }
  }, [clearStorage, localAudioTrack, updateNoiseCancellationState]);

  // Initialization
  useEffect(() => {
    if (localAudioTrack?.noiseCancellation && cancelNoise !== localAudioTrack.noiseCancellation?.isEnabled) {
      // If the user has set a preference, we update the track to match it.
      // Or on initial load the track will be disabled.
      updateNoiseCancellationState(cancelNoise);
    }
  }, [cancelNoise, localAudioTrack, updateNoiseCancellationState]);

  const hasNoiseCancellation = localAudioTrack?.noiseCancellation != null && !isIOS();
 
  return [hasNoiseCancellation, cancelNoise ?? false, setCancelNoise, clear] as const;
}