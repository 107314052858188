import React, { useEffect, useState } from "react";
import { ExportToCsv } from "export-to-csv";
import "../../Components/ShowAdmin/showAdmin.css";
import AlgoliaTable from "../../Components/Algolia/AlgoliaTable";
import { getMilliSeconds, sortTableDate } from "../../Utils/helpers";
import { get } from "../../Utils/helpers";
import { Input, Table } from "antd";
import classNames from "classnames";
import { searchClient, showActiveUsers } from "../../Service/show";

const { REACT_APP_ALGOLIA_USER_INDEX_NAME } = process.env;

interface IProps {
  show_id: string | undefined;
  usersGroupsFlag?: boolean;
  isMasvFeature?: boolean;
}
const { Search } = Input;

const ListOfInvitedViewer = (props: IProps) => {
  const { show_id } = props;
  const [dataList, setDataList] = useState([]);
  const [showUsers, setShowUsers] = useState([] as any)

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const fetchShowUserData = async () => {
    try {
      let activeUsers = await showActiveUsers(show_id);
      console.log(activeUsers?.hits, 'see hereeee');
      if (activeUsers && activeUsers.hits) {
        const filteredData = activeUsers.hits.filter((user: any) => user.role_type !== "SuperAdmin");

        if (!props.isMasvFeature) {
          const finalFilteredData = filteredData.filter((user: any) => user.role_type !== "MailBox");
          setShowUsers(finalFilteredData);
        } else {
          setShowUsers(filteredData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    searchClient.clearCache();
    fetchShowUserData()
  }, [])

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    filename: `Pacpost Invited Viewer List - ${new Date()}`,
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  const setNewDataList = (data: any) => {
    const filteredData = data.filter((user: any) => user.role_type !== "SuperAdmin");
    if (!props.isMasvFeature) {
      const finalFilteredData = filteredData.filter((user: any) => user.role_type !== "MailBox");
      setShowUsers(finalFilteredData);
    } else {
      setShowUsers(filteredData);
    }
  };

  const getUserAccountStatus = (
    createdTS: { _seconds: number; _nanoseconds: number; } | null,
    lastLoginTime: { _seconds: number; _nanoseconds: number; } | null
  ) => {
    if (lastLoginTime && createdTS) {
      const createdTime = getMilliSeconds(createdTS._seconds, createdTS._nanoseconds);
      const lastLoginMilliSeconds = getMilliSeconds(lastLoginTime._seconds, lastLoginTime._nanoseconds);
      if (lastLoginMilliSeconds >= createdTime) {
        return "Activated";
      }
    }
    return "Pending";
  };


  const exportExcel = () => {
    const csvExporter = new ExportToCsv(options);
    const csvShows: any = [];
    showUsers.forEach((user: any) => {
      const temp: any = {};
      temp["Invited User"] = user?.user_name?.user_name_first + user?.user_name?.user_name_last;
      temp["User Role"] = user?.role_type;
      if (user?.invited_streams) {
        temp["Invited By"] = user.invited_streams[0].created_user_email;
      } else if (user?.invited_by) {
        temp["Invited By"] = user.invited_by.user_email;
      } else {
        temp["Invited By"] = "";
      }
      temp["Invited Date"] = new Date(
        getMilliSeconds(user.created_ts?._seconds || 0, user.created_ts?._nanoseconds || 0)
      ).toDateString();
      temp["User Account Status"] = getUserAccountStatus(
        get(user, "created_ts", null), get(user, "last_login_time", null)
      );
      csvShows.push(temp);
    });
    csvExporter.generateCsv(JSON.stringify(csvShows));
  }
  
  const renderCreatedTimeNew = (text: string, records: any) => {
    // const { original } = row;
    return new Date(
      getMilliSeconds(get(records, "invited_by.modified_ts._seconds", 0),
        get(records, "invited_by.modified_ts._nanoseconds", 0))
    ).toDateString();
  };

  const renderCreatedTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      getMilliSeconds(get(original, "invited_by.modified_ts._seconds", 0),
      get(original, "invited_by.modified_ts._nanoseconds", 0))
    ).toDateString();
  };

  const renderInviterUser = (props: any) => {
    const { row } = props;
    const { original } = row;
    return `${get(original, "user_name.user_name_first", "")} ${get(original, "user_name.user_name_last", "")}`;
  };

  const renderInvitedBy = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (get(original, "invited_by", null)) {
      return get(original, "invited_by.user_email", "")
    } else if (get(original, "invited_streams.0.created_user_email", null)) {
      return get(original, "invited_streams.0.created_user_email", "")
    }
    return "";
  };

  const renderUserStatus = (props: any) => {
    const { row: { original } } = props;
    return getUserAccountStatus(
      get(original, "created_ts", null), get(original, "last_login_time", null)
    );
  }

  const renderUserStatusNew = (text: string, records: any) => {
    return getUserAccountStatus(
      get(records, "created_ts", null), get(records, "last_login_time", null)
    );
  }

  const getNewTableConfig = () => {
    let filters = `show_id:${show_id} AND (role_type:ShowViewer OR role_type:ShowPublisher OR role_type:MailBox)`;
    if (!props.isMasvFeature) {
      filters += " AND NOT role_type:MailBox";
    }
    return {
      columns: [
        // Headers array for the table
        {
          title: "Invited User", // Header Name
          dataIndex: "user_name.user_name_first", // Algolia JSON key name,
          render: (text: string, record: any) => {
            return `${record.user_name.user_name_first} ${record.user_name.user_name_last} `;
          },
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_name.user_name_first} ${a.user_name.user_name_last}`.toLowerCase();
            const nameB = `${b.user_title}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },

        },
        {
          title: "User Role",
          dataIndex: "role_type",
          sorter: (a: any, b: any) => {
            const nameA = `${a.role_type}`.toLowerCase();
            const nameB = `${b.role_type}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Email",
          dataIndex: "user_email",
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_email}`.toLowerCase();
            const nameB = `${b.user_email}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },

        {
          title: "Invited By",
          dataIndex: "invited_by.user_email",
          // dataIndex: "invited_streams[0].created_user_email",
          render: (text: string, record: any) => {
            return `${record.invited_by.user_email} `;
          },
          sorter: (a: any, b: any) => {
            const nameA = `${a.invited_by.user_email}`.toLowerCase();
            const nameB = `${b.invited_by.user_email}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
          // Cell: renderInvitedBy
        },
        {
          title: "Invited Date",
          dataIndex: "created_ts",
          sort: (a: any, b: any) => sortTableDate(a, b, "values.created_ts._seconds"),
          render: renderCreatedTimeNew,
          sorter: (a: any, b: any) => {
            const nameA = `${a.renderCreatedTimeNew}`.toLowerCase();
            const nameB = `${b.renderCreatedTimeNew}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },


        },
        {
          title: "User Account Status",
          dataIndex: "last_login_time",
          render: renderUserStatusNew,
          sort: (a: any, b: any) =>
            sortTableDate(a, b, "values.last_login_time._seconds"),
        }

      ],
      algoliaIndex: REACT_APP_ALGOLIA_USER_INDEX_NAME, // Algolia Index Mandatory
      filters: filters,
      setDataList: setNewDataList, // Temp set list of hits value
      dataList: showUsers, // Algolia list of hits value,
      refresh: true,
      attributesToRetrieve: [
        // Response attributes
        "user_name",
        "role_type",
        "invited_streams",
        "created_ts",
        "invited_by",
        "last_login_time"
      ],
    };
  }
  const tableConfig = getNewTableConfig();

  const getTableConfig = () => {
    let filters = `show_id:${show_id} AND (role_type:ShowViewer OR role_type:ShowPublisher OR role_type:MailBox )`;
    if (!props.isMasvFeature) {
      filters += " AND NOT role_type:MailBox";
    }
    return {
      columns: [
        // Headers array for the table
        {
          Header: "Invited User",
          accessor: "user_name.user_name_first",
          Cell: renderInviterUser,
        },
        {
          Header: "User Role",
          accessor: "role_type",
        },
        {
          Header: "Invited By",
          accessor: "invited_streams[0].created_user_email",
          Cell: renderInvitedBy
        },
        {
          Header: "Invited Date",
          accessor: "created_ts",
          Cell: renderCreatedTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.created_ts._seconds"),
        },
        {
          Header: "User Account Status",
          accessor: "last_login_time",
          Cell: renderUserStatus,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.last_login_time._seconds"),
        }
      ],
      algoliaIndex: REACT_APP_ALGOLIA_USER_INDEX_NAME, // Algolia Index Mandatory
      hitsPerPage: 10000, // No of records per page
      filters: filters, // Algolia Filters
      setDataList: setNewDataList, // Temp set list of hits value
      dataList: dataList, // Algolia list of hits value,
      refresh: true,
      attributesToRetrieve: [
        // Response attributes
        "user_name",
        "role_type",
        "invited_streams",
        "created_ts",
        "invited_by",
        "last_login_time"
      ],
    };
  };

  const filterData = tableConfig.dataList.filter((user: any) => {
    const firstName: string = get(user, "user_name.user_name_first", "");
    const lastName: string = get(user, "user_name.user_name_last", "");
    const email: string = get(user, "user_email", "");
    let phone: any = get(user, "user_phone", "");
    phone = (typeof phone === "string" ? phone : phone.user_phone) || "";


    let result = tableConfig.dataList;

    if (searchText != "") {
      result = `${firstName} ${lastName} ${email}`
        .toLowerCase()
        .includes(searchText.toLowerCase());
    }

    return result
  });

  const listOfUsersSearchResult = !!searchText ? filterData : tableConfig.dataList;

  return (
    <div className="list-show-users">
      {
        props.usersGroupsFlag ?
          <>
            <div className="superadmin-list-heading users-group-list-invited-users">
              <p className="heading-text  inviteViewerlist-heading  heading-text-new">
                Show Admin :<span className="grey-text"> Invited Users List </span>
              </p>
              <span
                className="excel-button"
                onClick={exportExcel}
                data-tooltip="Export Excel"
                data-position="top center"
              ></span>
            </div>
            <div className="searchbar-wrapper searchbar-manage-users">
              <Search
                placeholder="Search"
                allowClear
                className={classNames('left-container-searchbar')}
                // onSearch={handleSearch}
                //@ts-ignore
                onChange={handleSearch}
              />
            </div>
            <Table className="antd-table-new"
              showSorterTooltip={{ title: 'Sort Alphabetically' }}
              columns={tableConfig.columns}
              dataSource={listOfUsersSearchResult.filter((user:any) => user?.role_type === "ShowPublisher" || user?.role_type === "ShowViewer")}
              pagination={{ pageSize: 20 }}
            />
          </>
          :
          <>
            <div className="showadmin-list-heading">
              <p className="heading-text  inviteViewerlist-heading">
                Show Admin :<span className="grey-text"> Invited Users List </span>
              </p>
              <span
                className="excel-button"
                onClick={exportExcel}
                data-tooltip="Export Excel"
                data-position="top center"
              ></span>
            </div>
            <AlgoliaTable config={getTableConfig()} />
          </>
      }

    </div>
  );
};

export default ListOfInvitedViewer;
