import useParticipants from "./useParticipants";
import { Room } from "twilio-video";
import * as identityHelpers from "../../../Utils/identityHelpers";

export default function useGuestUsers(room?: Room) {
  const participants = useParticipants(room);

  const guestParticipants = participants.filter((participant) =>
    identityHelpers.isGuest(participant.identity)
  );

  return guestParticipants.map((participant) => ({
    displayName: identityHelpers.getDisplayName(participant.identity),
    participant,
    identity: participant.identity,
    sid: participant.sid,
  }));
}
