/* eslint-disable react/jsx-no-undef */
import { useState, useEffect, useContext, useMemo } from 'react';
import type { TableProps } from 'antd';
import { Table, Space, Button, Drawer, Form, Popover, Input } from 'antd';
import { Checkbox } from "semantic-ui-react";
import { InfoCircleOutlined } from "@ant-design/icons";
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { AuthContext } from '../../Context/authContext';
import { get } from '../../Utils/helpers';
import Download from './Download';
import classNames from "classnames";
import ProgressLoader from '../Common/ProgressLoader';

interface DataType {
  reasonForFailed: string;
  key: string;
  package_name: string;
  description: string;
  file_name: any[];
  package_expiry: string;
  created_ts: string;
  status:string;
  download_limit:string;
  file_count:number;
  upload_id:string;
  dataString:string;
  downloaded_count: number
}
const { Search } = Input;
function Transfer(props: any) {
  const [form] = Form.useForm();
  const { show_id, usersList } = props;
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
  const [transferData, setTransferData] = useState<DataType[]>([]);
  const [selectPackage, setSelectPackage] = useState(null as any);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [failedTransactionDrawerOpen, setFailedTransactionDrawerOpen] = useState(false);
  const [failedTransactionData, setFailedTransactionData] = useState<DataType[]>([]);
  const [recipientsData, setRecipientsData] = useState<DataType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [activeChecked, setActiveChecked] = useState(true);
  const [checkedAll, setCheckedAll] = useState(false);
  const [recLoading, setRecLoading] = useState(false);
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [loading, setLoading] = useState(false);
  const usersListEmail: any = {};
  usersList.forEach((user: any) => {
    usersListEmail[get(user, 'user_id', '')] = {
    name:`${get(user, 'user_name.user_name_first', '')} ${get(user,'user_name.user_name_last','')}`,
    email: get(user, 'user_email', '')
    };
  });

  useEffect(() => {
    fetchInbox();

  }, [show_id, usersList]);

  const showDrawer = () => {
    setDrawerOpen(true);
  };
  const showFailedTransactionDrawer = () => {
    setFailedTransactionDrawerOpen(true);
  }
  const closeDrawer = () => {
    setDrawerOpen(false);
    setIsModalOpen(false);
    setFailedTransactionDrawerOpen(false);
  };

  const fetchInbox = async () => {
    try {
    setLoading(true);
    const inboxPayload = {
      api: api.massive.transfer,
      urlParam: show_id,
    };
    const inboxResult = await backend.fetch(inboxPayload, get(authValue, 'accessToken', ''));
    const data = inboxResult.map((data: any) => ({
      package_name: data.package_name,
      package_id: data.package_id,
      file_count: data.file.length,
      file_name: data.file.map((file: any) => ` ${file.name}`),
      created_ts: new Date(
        data.created_ts._seconds * 1000 + data.created_ts._nanoseconds / 1000000,
      ).toDateString(),
      package_expiry: new Date(data.package_expiry).toDateString(),
      key: data.id,
      description: data.description,
      access_limit: data.access_limit,
      upload_id: data.upload_id,
      status: new Date().getTime() < new Date(data.package_expiry).getTime() ? 'active' : 'inactive'
    })).map((data: DataType) => ({ ...data, dataString: getSearchString(data) }))
    .sort((a:any, b:any) => {
      const dateA = new Date(a.created_ts);
      const dateB = new Date(b.created_ts);
      return dateB.getTime() - dateA.getTime(); 
    });

    setTransferData(data);
  } catch (err: any) {
    console.error(err);
  } finally {
    setLoading(false);
  }
};

  const fetchRecipentsData = async (massive_id: any) => {
    try {
      setRecLoading(true);
      const inboxPayload = {
        api: api.massive.massiveRecipients,
        urlParam: massive_id,    
      };
      const inboxResult = await backend.fetch(inboxPayload, get(authValue, 'accessToken', ''));

      const data = inboxResult
        .map((data: any) => ({
          package_name: data.package_name,
          created_ts: new Date(
            data.created_ts._seconds * 1000 + data.created_ts._nanoseconds / 1000000,
          ).toDateString(),
          package_expiry: new Date(data.package_expiry).toDateString(),
          key: data.massive_upload_id,
          description: get(data, 'description', ''),
          id: get(data, 'package_id', ''),
          passwordRequired: data.password_required,
          download_limit: get(data, 'download_limit', 0),
          password_attempt_left: get(data, 'password_attempt_left', 0),
          access_count: get(data, 'access_count', 0),
          recipient_doc_id: get(data, 'id', ''),
          access_attempt: get(data, 'access_attempt', 0),
          status: data.status,
          email: data.email,
          downloaded_count: get(data, 'downloaded_count', 0),
        }));

      setRecipientsData(data);
    } catch (err: any) {
      console.error(err);
    } finally {
        setRecLoading(false);
    }
  }

  const fetchFailedTransactionsData = async () => {
    try {
      setRecLoading(true);
      const inboxPayload = {
        api: api.massive.failedTransactions,
        urlParam: show_id,    
      };
      const inboxResult = await backend.fetch(inboxPayload, get(authValue, 'accessToken', ''));
      setFailedTransactionData(inboxResult)
    } catch (err: any) {
      console.error(err);
    } finally {
        setRecLoading(false);
    }
  }

  const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setSortedInfo(sorter as SorterResult<DataType>);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Package Name',
      dataIndex: 'package_name',
      key: 'package_name',
      sorter: (a, b) => a.package_name.length - b.package_name.length,
      sortOrder: sortedInfo.columnKey === 'package_name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'File Count',
      key: 'file',
      render: (_,record) => {
        console.log("#column-render:",record)
        return (
          <div>
        <Space size='middle'>
          <span>{record.file_count}</span>
          <Popover title={'File Names'} className='file-info-icon' content={()=>(record.file_name.map(x => (<div>{x}</div>)))}>
          <InfoCircleOutlined />
          </Popover>
        </Space>
        </div>
      )},
    },
    
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Created At',
      dataIndex: 'created_ts',
      key: 'created_ts',
      sorter: (a, b) => {
      const dateA = new Date(a.created_ts);
      const dateB = new Date(b.created_ts);
      return dateB.getTime() - dateA.getTime() // Compare in reverse order (newest to oldest)
    },
    sortOrder: sortedInfo.columnKey === 'created_ts' ? sortedInfo.order : null,
    ellipsis: true,
    },
    {
      title: 'Expire At',
      dataIndex: 'package_expiry',
      key: 'package_expiry',
      sorter: (a, b) => {
      const dateA = new Date(a.package_expiry);
      const dateB = new Date(b.package_expiry);
      return dateB.getTime() - dateA.getTime() // Compare in reverse order (newest to oldest)
      },
      sortOrder: sortedInfo.columnKey === 'package_expiry' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (data, row) => (
        <Space size='middle'>
          <Button 
          className='upload-btn'
          type="primary"
          onClick={e => {showDrawer(); fetchRecipentsData(row.key)}}>
          Recipients List
        </Button>
        {/* <Button
           className='upload-btn'
            type='primary'
            disabled={row.status === "Inactive"}
            style={
              row.status === "Inactive"
                ? { backgroundColor: 'gray', color: 'white', cursor: 'not-allowed' }
                : {}
            }
            onClick={() => {
              console.log('row', row);
              setSelectPackage(row);
              setIsModalOpen(true);
            }}
          >
            Download
          </Button> */}
        </Space>
      ),
    },
  ];

  /* Recipients Column */
  const columnsRec: ColumnsType<DataType> = [
    {
      title: 'Sent',
      dataIndex: 'created_ts',
      key: 'created_ts',
      sorter: (a, b) => a.created_ts.length - b.created_ts.length,
      sortOrder: sortedInfo.columnKey === 'created_ts' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: 'Download Limit',
      dataIndex: 'download_limit',
      key: 'download_limit',
    },
    {
      title: 'Downloaded Count',
      dataIndex: 'downloaded_count',
      key: 'downloaded_count',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: 'Action',
    //   dataIndex: '',
    //   key: '',
    //   render: (data, row) => (
    //     <Space size='middle'>
    //       <Button
    //         className='upload-btn'
    //         type='primary'
    //         onClick={() => {
    //           console.log('row', row);
    //           setSelectPackage(row);
    //           setIsModalOpen(true);
    //         }}
    //       >
    //         Send Passcode
    //       </Button>
    //     </Space>
        
    //   )
      
    // },
  ];

   /* Failed Transactions Column */
   const columnsFailedTransaction: ColumnsType<DataType> = [
    {
      title: 'Package Name',
      dataIndex: 'packageName',
      key: 'packageName',
      sorter: (a, b) => {
        return get(a, "packageName", "").localeCompare(get(b, "packageName", ""));
    }    
  },
  {
      title: 'Created_ts',
      dataIndex: 'created_ts',
      key: 'created_ts',
      render: (_, data) => (
          <div>{new Date(data.created_ts).toDateString()}</div>
      ),
      sorter: (a, b) => new Date(a.created_ts).getTime() - new Date(b.created_ts).getTime(),
  },
    {
      title: 'File Count',
      dataIndex: 'filecount',
      key: 'filecount',
    },
    {
      title: 'Reason For Fail',
      dataIndex: '',
      key: '',
      render: (_,record) => {
        return (
          <div>
            <span>{record.reasonForFailed ? record.reasonForFailed : 'Upload Canceled'}</span>
        </div>
      )}
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (data, row) => (
        <span>Failed</span>
      )
    },
   
  ];

  const getDownloadProps = () => ({
    selectPackage,
    isModalOpen,
    setIsModalOpen,
    authValue,
    show_id,
  });
  
  const handleActiveChecked = (e: any, data:any) => {
    const value = data.checked;
    setCheckedAll(!value)
    setActiveChecked(value)
  }
  const handleCheckedAll = (e: any, data:any) => {
    const value = data.checked;
    setActiveChecked(!value)
    setCheckedAll(value)
  }

  const getSearchString = (rec:DataType) => {
    const packageName: string = get(rec, "package_name", "");
    const status: string = get(rec, "status", "");
    const desc: string = get(rec, "description", "");
    return`${packageName} ${status} ${desc}`
            .toLowerCase()
  }

  return (
    <div>

    {/* Transfer history table  */}
    <div className='inbox-wrapper'>
      <div>
        <p className="heading-text-mailbox">
          Transfer History
        </p>
      </div>
      <ProgressLoader loading={loading} />
      <Form form={form} className="table-content" component={false}>
        <div className='mailbox-search'>
          <Search
              placeholder="Search"
              allowClear
              className={classNames('')}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
          />
          
          <Checkbox
            label="Active"
            name="Active"
            className='checkbox-status'
            checked={activeChecked}
            onChange={handleActiveChecked}
          />
          <Checkbox
            label="All"
            name="All"
            className='checkbox-status'
            checked={checkedAll}
            onChange={handleCheckedAll}
          />
          <Button 
          className='upload-btn failed-tansaction-btn'
          type="primary"
          onClick={e => {showFailedTransactionDrawer(); fetchFailedTransactionsData()}}
          >
          Failed Transfers
        </Button>
        </div>
        <Table
          size={'small'}
          className='inbox-wrapper-table recipients-table table-row-without-border'
          dataSource={transferData
            .filter((data:DataType) => {
              const checks = (checkedAll && get(data,'status','')) || (activeChecked && get(data,'status','') === 'active');
              const searchFilter = searchText ? get(data,'dataString','').includes(searchText.toLowerCase()) : true;
              return checks && searchFilter;
            })}
          columns={columns}
          onChange={handleChange}
          rowClassName={rec => (rec.status === 'inactive' && "inactive-row") || ''}
        />
      </Form> 
      </div>

      {/* Recipients table */}
      {isModalOpen && <Download download={getDownloadProps()} />}
      <Drawer
        width={900}
        onClose={closeDrawer}
        open={drawerOpen}
      >
        <div className='inbox-wrapper'>
          <Form form={form} component={false}>
            <Table
              className='inbox-wrapper-table recipients-table'
              loading={recLoading}
              bordered
              dataSource={recipientsData}
              columns={columnsRec}
              onChange={handleChange}
            />
          </Form> 
        </div>
      </Drawer>

      {/* Failed Transaction table */}
      {isModalOpen && <Download download={getDownloadProps()} />}
      <Drawer
        width={900}
        onClose={closeDrawer}
        open={failedTransactionDrawerOpen}
      >
        <div className='inbox-wrapper'>
          <Form form={form} component={false}>
            <Table
              className='inbox-wrapper-table recipients-table'
              loading={recLoading}
              bordered
              dataSource={failedTransactionData}
              columns={columnsFailedTransaction}
              onChange={handleChange}
            />
          </Form> 
        </div>
      </Drawer>
      
    </div>
  );
}

export default Transfer;
