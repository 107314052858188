import React from 'react'

// antd
import { Select } from 'antd';
import type { SelectProps } from 'antd';

// UIKITS
import { Label } from '../Label';

// CSS
import "./styles.css"

const options: SelectProps['options'] = [];
for (let i = 10; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
}

const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
};


export const SelectPP = (props: any) => {
    const {
        label = 'input',
        entry = options,
        placeholder,
        mode,
        disabled = false,
        filterOption,
        showSearch = false,
        optionFilterProp,
        filterSort,
        onChange,
        defaultValue,
        key,
        className
    } = props;

    return (
        <div>
            {label && <Label>{label.toUpperCase()}</Label>}
            <Select
                className={className}
                // key={key}
                disabled={disabled}
                filterSort={filterSort}
                showSearch={showSearch}
                placeholder={placeholder}
                optionFilterProp={optionFilterProp}
                filterOption={filterOption}
                showArrow={true}
                mode={mode}
                allowClear
                style={{ width: '100%', minWidth: "100px" }}
                //placeholder=" select "
                defaultValue={defaultValue}
                onChange={onChange ? onChange : handleChange}
                options={entry}

            />
        </div>
    )
}
