// Auth Routes
export enum NON_AUTH_ROUTES {
  home = "/",
  login = "/login",
  guestlogin = "/gl",
  forgotpassword = "/forgotpassword",
  termsofservice = "/termsofservice",
  privacypolicy = "/privacypolicy",
  subscriptionagreement = "/subscriptionagreement",
  statuspage = "/status",
  logoutredirect = "/logoutredirect"
}

// Non Auth Routes
export enum AUTH_ROUTES {
  resetpassword="/resetpassword",
  otp = "/otp",
  showselection = "/showselection",
  superadmin = "/superadmin",
  showadmin = "/showadmin",
  showpublisher = "/showpublisher",
  oldshowviewer = "/oldshowviewer",
  showviewer = "/showviewer",
  zoomviewer = "/viewer",
  unauthorised = "/unauthorised",
  profile = "/profile",
  teamadmin = "/teamadmin",
  stripesuccess = "/stripesuccess",
  stripefailure = "/stripefailure",
  swagger = "/swagger",
  showfinance = "/showfinance",
  trusteddevice = "/trusteddevice",
  mailbox = "/mailbox",
  finduser= "/finduser",
  tvOs = "/tvOs",
}
