import { useContext, useEffect, useState } from 'react';
import { PAGES } from '../../Utils/constants';
import ProgressLoader from '../Common/ProgressLoader';
import { Layout } from '../PageLayout/Layout';
import classes from './activateTv.module.css';
import api from '../../Service/Api';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import backend from '../../Service/Backend';
import { notification, Table, Button, Space, Divider } from 'antd';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import ConfirmationModal from '../../Components/UIKit/ConfirmationModal/ConfirmationModal';
import useNavigateToPage from '../../hooks/useNavigateToPage';

interface ApiTableDataType {
  device_id: string;
  status: string;
  created_ts: string;
}

const ActivateAppleTV = () => {
  const [passcode, setPasscode] = useState('');
  const [loading, setLoading] = useState(false);
  const [appleTVList, setAppleTVList] = useState([]);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deviceIdToDelete, setDeviceIdToDelete] = useState<string | null>(null);
  const regexPattern = /^[0-9]+$/;
  const currentUser = get(useContext(AuthContext), 'currentUser', {});
  const authValue = get(currentUser, 'user', '');
  const navigateToPage = useNavigateToPage();

  useEffect(() => {
    const fetchAppleTVList = async () => {
      try {
        setLoading(true);
        const requestConfig = {
          api: api.tvOs.listOfAppleTV,
          payLoad: null,
        };
        const result = await backend.fetch(requestConfig, get(currentUser, 'user.accessToken', ''));
        if (result) {
          setAppleTVList(result);
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Failed to load API list',
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAppleTVList();
  }, [currentUser]);

  const validatePasscode = (event: any) => {
    if (
      event.target.value === '' ||
      (event.target.value.length <= 6 && regexPattern.test(event.target.value))
    ) {
      setPasscode(event.target.value);
    }
  };

  const verifyPasscode = async () => {
    try {
      setLoading(true);
      const requestConfig = {
        api: api.tvOs.verifyAccessCode,
        payLoad: JSON.stringify({
          accessCode: passcode,
          source: 'web',
        }),
      };
      const result = await backend.save(requestConfig, get(currentUser, 'user.accessToken', ''));
      if (result) {
        notification.success({
          message: 'Successfully verified',
        });
        navigateToPage('/showselection', { state: { userId: get(authValue, 'uid', '') } });
      }
    } catch (err: any) {
      console.error(err);
      if (err.errCode === 130400) {
        notification.error({
          message: 'Passcode expired',
        });
      } else {
        notification.error({
          message: err.errMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    if (deviceIdToDelete) {
      await deleteDeviceID(deviceIdToDelete);
    }
  };

  const handleDeleteCancel = () => {
    setDeviceIdToDelete(null);
    setDeleteAlertOpen(false);
  };

  const deleteDeviceID = async (device_id: string) => {
    try {
      setLoading(true);
      setDeleteAlertOpen(false);
      const data = {
        api: api.tvOs.deleteDeviceID,
        urlParam: device_id,
      };
      const result = await backend.remove(data, get(authValue, 'accessToken', ''));
      if (result) {
        notification.success({
          message: 'API Key deleted successfully',
        });
        setAppleTVList(appleTVList.filter((item: any) => item.device_id !== device_id));
      }
    } catch (err: any) {
      console.error(err);
      notification.error({
        message: 'Failed to delete API key',
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const tableColumns: ColumnsType<ApiTableDataType> = [
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      key: 'device_id',
      render: (device_id) => <span>{device_id}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span>{status}</span>,
    },
    {
      title: 'Created Timestamp',
      dataIndex: 'created_ts',
      key: 'created_ts',
      render: (created_ts) => {
        const formattedDate = moment(created_ts).format('YYYY-MM-DD HH:mm:ss');
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type='primary'
            className='delete-api-btn'
            onClick={() => {
              setDeviceIdToDelete(record.device_id);
              setDeleteAlertOpen(true);
            }}
          >
            DELETE
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Layout page={PAGES.INTERMEDIATE_PAGE}>
      <div className='left-content-intermediate'>
        <div className='intermediate_image'></div>
      </div>
      <div className='right-content-intermediate'>
        <div className='right-list-container'>
          <div className='intermediate-list-heading'>
            <span className={classes.appleTvheader}>
              {appleTVList.length > 0 ? 'Your Apple TV Device' : 'Activate Your Apple TV Device'}
            </span>
          </div>
          {appleTVList.length > 0 ? (
            <div>
              <Divider>
                <h3>Device Information</h3>
              </Divider>
              <div>
                <Table dataSource={appleTVList} className='customTable' columns={tableColumns} />
              </div>
            </div>
          ) : (
            <>
              <div className={classes.appleTvbanner}>Enter Apple TV Code</div>
              <div className={classes.appleTvcontainer}>
                <input
                  className={classes.appleTvcontainerInputfield}
                  type='text'
                  value={passcode}
                  onChange={validatePasscode}
                  placeholder='PASS CODE'
                />
                <button
                  className={classes.appleTvcontainerButton}
                  onClick={verifyPasscode}
                  disabled={!passcode}
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <ProgressLoader loading={loading} />
      <ConfirmationModal
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        data={'Are you sure you want to delete this device?'}
        open={deleteAlertOpen}
      />
    </Layout>
  );
};

export default ActivateAppleTV;
