import { createContext, useState, PropsWithChildren, useRef } from 'react';

const EncoderContext = createContext(null as any);

const EncoderProvider = ({ children }: PropsWithChildren<{}>) => {
  const [loading, setLoading] = useState(true);
  const [playLoading, setPlayLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [rebootLoading, setRebootLoading] = useState(false);
  const [streamingStarted, setStreamingStarted] = useState(false);
  const [showId, setShowId] = useState(null as any);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState('');
  const [settings, setSettings] = useState([]);
  const [deviceSettings, setDeviceSettings] = useState([]);
  const [videoResolution, setVideoResolution] = useState('4K');
  const [colorFormat, setColorFormat] = useState('');
  const [videoInput, setVideoInput] = useState('HDMI');
  const [videoBitrate, setVideoBitrate] = useState(16000);
  const [fps, setFps] = useState('23.98');
  const playBtnRef: any = useRef(null);
  const stopBtnRef: any = useRef(null);
  return (
    <EncoderContext.Provider
      value={{
        deviceList,
        selectedDevice,
        setDeviceList,
        setSelectedDevice,
        settings,
        setSettings,
        loading,
        setLoading,
        videoResolution,
        setVideoResolution,
        colorFormat,
        setColorFormat,
        fps,
        setFps,
        videoInput,
        setVideoInput,
        videoBitrate,
        setVideoBitrate,
        showId,
        setShowId,
        streamingStarted,
        setStreamingStarted,
        playLoading,
        setPlayLoading,
        stopLoading,
        setStopLoading,
        rebootLoading,
        setRebootLoading,
        setSelectedDeviceIndex,
        selectedDeviceIndex,
        playBtnRef,
        stopBtnRef,
        deviceSettings,
        setDeviceSettings,
      }}
    >
      {children}
    </EncoderContext.Provider>
  );
};

export { EncoderContext, EncoderProvider };
