import api from "../../Service/Api";
import backend from "../../Service/Backend";

export const addCustomer = async (payload: any, token: string) => {
  const customer = {
    api: api.stripe.createCustomer,
    payLoad: JSON.stringify(payload),
  };
  const result = await backend.save(customer, token);
  return result;
};

export const updateCustomer = async (payload: any, token: string) => {
  const customer = {
    api: api.stripe.updateCustomer,
    payLoad: JSON.stringify(payload),
  };
  const result = await backend.save(customer, token);
  return result;
};

export const getCustomerCard = async (stripe_customer_id: string, token: string) => {
  const subscriptionList = {
    api: api.stripe.getCustomerCard,
    queryParam: { stripe_customer_id },
  };
  const subscription: any = await backend.fetch(subscriptionList, token);
  return subscription;
};

export const getSubscriptionList = async (stripe_customer_id: string, status: string, token: string) => {
  const subscriptionList = {
    api: api.stripe.listUserSubscriptions,
    queryParam: { stripe_customer_id, status },
  };
  const subscription: any = await backend.fetch(subscriptionList, token);
  return subscription;
};

export const getProductList = async (token: string) => {
  const product = {
    api: api.stripe.getProduct,
  };
  const response = await backend.fetch(product, token);
  return response;
};

export const getPriceList = async (token: string) => {
  const product = {
    api: api.stripe.getPrice,
  };
  const response = await backend.fetch(product, token);
  return response;
};

export const getCustomerDetails = async (payload: any, token: string) => {
  const product = {
    api: api.stripe.getCustomer,
    queryParam: payload,
  };
  const response = await backend.fetch(product, token);
  return response;
};

export const createSubscription = async (payload: any, token: string) => {
  const subscription = {
    api: api.stripe.createSubscription,
    payLoad: JSON.stringify(payload),
  };

  const response = await backend.save(subscription, token);
  return response;
};

export const cancelSubscription = async (
  show_id: string,
  show_code: string,
  publisher_id: string,
  publisher_name: string,
  subscription_id: string, 
  token: string
) => {
  const subscription = {
    api: api.stripe.cancelSubscription,
    payLoad: JSON.stringify({
      show_id,
      show_code,
      publisher_id,
      publisher_name,
      subscription_id,
    }),
  };
  const response = await backend.save(subscription, token);
  return response;
};

export const retrieveSubscription = async (
  show_id: string,
  show_code: string,
  subscription_id: string,
  token: string
) => {
  const subscription = {
    api: api.stripe.retrieveSubscription,
    payLoad: JSON.stringify({
      show_id,
      show_code,
      subscription_id,
    }),
  };
  const response = await backend.save(subscription, token);
  return response;
};

export const createCheckoutSession = async (payload: any, token: string) => {
  const sessionInput = {
    api: api.stripe.createCheckoutSession,
    payLoad: JSON.stringify(payload),
  };
  const session = await backend.save(sessionInput, token);
  return session;
};

export const getActivePublishers = async (show_id: string, token: string) => {
  const getActiveShowPublishers = {
    api: api.users.listActiveShowPublisherInShow,
    urlParam: show_id,
  };
  const listOfShowPublishersInShow = await backend.fetch(
    getActiveShowPublishers, token
  );
  return listOfShowPublishersInShow;
};

export const getCountryCode = async (token: string) => {
  const countryCodesData = {
    api: api.countryCodes.listAll,
  };
  const countryCodesResponse = await backend.fetch(countryCodesData, token);
  return countryCodesResponse;
};
