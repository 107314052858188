import { useNavigate } from 'react-router-dom';
import { useLocationStore } from '../store/useLocationStore';

const useNavigateToPage = () => {
  const navigate = useNavigate();
  const updateLocationState = useLocationStore((state: any) => state.updateLocationState);
  const locationState = useLocationStore((state: any) => state.locationState);

  const navigateToPage = (route: any, json?: Record<string, any>, fromViewerPage?: boolean) => {
    if (fromViewerPage || route === '/resetpassword' || route === '/profile') {
      updateLocationState({ ...locationState, loading: true });
    }

    if (fromViewerPage || route === '/resetpassword') {
      setTimeout(() => {
        updateLocationState(json);
      }, 1000);
    } else {
      updateLocationState(json);
    }

    navigate(route, json);
  };

  return navigateToPage;
};

export default useNavigateToPage;
