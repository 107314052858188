import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

interface State {
  locationState: Record<string, any>;
  updateLocationState: (newState: Record<string, any>) => void;
}

type MyState = State & {
  updateLocationState: (newState: Record<string, any>) => void;
};

type MyPersist = (
  config: StateCreator<MyState>,
  options: PersistOptions<MyState>,
) => StateCreator<MyState>;

// const encrypt = (data: string): string => {
//   return CryptoAES.encrypt(data, 'location-state').toString();
// };

// const decrypt = (data: string): string => {
//   const bytes = CryptoAES.decrypt(data, 'location-state');
//   return bytes.toString(CryptoJS.enc.Utf8);
// };

export const useLocationStore = create<MyState>(
  (persist as MyPersist)(
    (set) => ({
      locationState: {},
      updateLocationState: (newState) =>
        set(() => ({
          locationState: newState,
        })),
    }),
    {
      name: 'location-state', // name of the item in storage
      getStorage: () => sessionStorage, // use localStorage for persistence
      // serialize: (state) => encrypt(JSON.stringify(state)),
      // deserialize: (str) => JSON.parse(decrypt(str)),
    },
  ),
);
