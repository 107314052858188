import React, { useState, useEffect, useContext } from "react";
import BillingInfo from "./BillingInfo";
import PaymentInfo from "./PaymentInfo";
import StepperAction from "./StepperAction";
import SubscriptionDetails from "./SubscriptionDetails";
import {
  addCustomer,
  updateCustomer,
  getProductList,
  getPriceList,
  getCustomerDetails,
  createSubscription,
  createCheckoutSession,
} from "./DataHandler";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import "./Subscribe.css";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';

interface IProps {
  show_id: string | undefined;
  show_code: string | undefined;
  customerId: string;
  showAdminHeaderMenuRef: any;
  setStripeCustomerId: any;
  activeSubscriptionList: any;
  usersGroupsFlag?:boolean;
}

const Subscribe = (props: IProps) => {
  const {
    show_id = "",
    show_code = "",
    customerId = "",
    showAdminHeaderMenuRef,
    setStripeCustomerId,
    activeSubscriptionList
  } = props;
  showAdminHeaderMenuRef.current.map(
    (ref: any) => (ref.ele.style.display = !!customerId && activeSubscriptionList.length > 0 ? "block" : "none")
  );
  const currentUser: any = useContext(AuthContext);
  const [step, setStep] = useState(!!customerId ? 2 : 1);
  const [productList, setProductList] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [priceList, setPriceList] = useState([] as any);
  const [customerID, setCustomerID] = useState(customerId);
  const [poCode, setPoCode] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [customPlanLabel, setCustomPlanLabel] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [disableActionButton, setDisableActionButton] = useState(true);
  const [detailsPage, setDetailsPage] = useState(!!customerId ? true : false);
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [customerDetails, setCustomerDetails] = useState({
    show_code,
    show_id,
    countryCode: "",
    phone: "",
    email: "",
    secondaryEmail: "",
    firstName: "",
    lastName: "",
    line1: "",
    city: "",
    state: "",
    productionName: "",
    note: "",
  } as any);

  useEffect(() => {
    const data = async () => {
      const res = await getProductList(get(currentUser, "user.accessToken", ""));
      const price = await getPriceList(get(currentUser, "user.accessToken", ""));
      setProductList(res.data);
      setPriceList(price.data);
      setLoading(false);
    };
    if (productList.length === 0) {
      setLoading(true);
      data();
    }
    isValid();
  });
  useEffect(() => {
     setDetailsPage(!!customerId);
     setCustomerID(customerId);
     setStep(!!customerId ? 2 : 1);
  }, [customerId])
  const isValid = () => {
    switch (step) {
      case 1:
        const validCustomer = Object.keys(customerDetails).every(
          (key: any) => !!customerDetails[key]
        );
        const isValid =
          validCustomer && phoneValid && emailValid && !!selectedProduct;
        setDisableActionButton(!isValid);
        break;
      default:
        setDisableActionButton(!selectedProduct);
    }
  };

  const getHeaderTabs = () => {
    return (
      <div className="steppers">
        {step > 1 && <div onClick={() => setStep(step - 1)}>Back</div>}
        {!!customerId && (
          <div onClick={() => setDetailsPage(true)}>View Licenses</div>
        )}
      </div>
    );
  };

  const stepperActionHandler = async (stepCount: any) => {
    setStep(stepCount);
    setDisableActionButton(true);
    console.log("currentUser", currentUser)
    switch (stepCount) {
      case 2:
        if (!!customerID) {
          setLoading(true);
          customerDetails.name = `${customerDetails.firstName} ${customerDetails.lastName}`;
          await updateCustomer({
            ...customerDetails,
            customerID
          },  get(currentUser, "user.accessToken", ""));
          setLoading(false);
        } else {
          setLoading(true);
          customerDetails.name = `${customerDetails.firstName} ${customerDetails.lastName}`;
          const customer = await addCustomer(customerDetails, get(currentUser, "user.accessToken", ""));
          setCustomerID(customer.id);
          setStripeCustomerId(customer.id);
          setLoading(false);
        }
        break;
      case 4:
        if (paymentType === "online") {
          setLoading(true);
          const sessionPayload = {
            stripe_customer_id: customerID,
            price_id: selectedProduct,
            show_code,
            show_id,
            item_quantity: 1,
            session_mode: "subscription",
            custom_plan_name: customPlanLabel,
          };
          const team_admin: any = false;
          sessionStorage.setItem("show_id", show_id);
          sessionStorage.setItem("show_code", show_code);
          sessionStorage.setItem("team_admin", team_admin);
          const session = await createCheckoutSession(sessionPayload, get(currentUser, "user.accessToken", ""));
          setLoading(false);
          if (session && session.url) {
            const hiddenElement = document.createElement("a");
            hiddenElement.href = session.url;
            hiddenElement.click();
          }
        }
        break;
    }
  };

  const getStepDetails = () => {
    switch (step) {
      case 1:
        return (
          <BillingInfo
            customerDetails={customerDetails}
            productList={productList}
            priceList={priceList}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setCustomerDetails={setCustomerDetails}
            setCustomPlanLabel={setCustomPlanLabel}
            firstNameValid={firstNameValid}
            setFirstNameValid={setFirstNameValid}
            lastNameValid={lastNameValid}
            setLastNameValid={setLastNameValid}
            phoneValid={phoneValid}
            setPhoneValid={setPhoneValid}
            emailValid={emailValid}
            setEmailValid={setEmailValid}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        );
      case 2:
        const paymentDetails = {
          show: show_code,
          plan: selectedProduct,
          productList,
          priceList,
          customPlanLabel,
          selectedProduct,
          setSelectedProduct,
          setCustomPlanLabel,
          poCode,
          setPoCode,
          paymentType,
          setPaymentType,
          offline: " =  PO Number",
          online: " =  Stripe Integration ACH or Credit Card Payment",
        };
        return <PaymentInfo paymentDetails={paymentDetails} />;
      default:
        return <></>;
    }
  };

  const handleSubscript = async () => {
    setLoading(true);
    if (paymentType === "online") {
      const sessionPayload = {
        stripe_customer_id: customerID,
        price_id: selectedProduct,
        show_code,
        show_id,
        item_quantity: 1,
        session_mode: "subscription",
        custom_plan_name: customPlanLabel,
      };
      const team_admin: any = false;
      sessionStorage.setItem("show_id", show_id);
      sessionStorage.setItem("show_code", show_code);
      sessionStorage.setItem("team_admin", team_admin);
      const session = await createCheckoutSession(sessionPayload, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      if (session && session.url) {
        const hiddenElement = document.createElement("a");
        hiddenElement.href = session.url;
        hiddenElement.click();
      }
    } else {
      const subscribeDetails = {
        stripe_customer_id: customerID,
        price_id: selectedProduct,
        custom_plan_name: customPlanLabel,
        show_code,
        show_id,
        item_quantity: 1,
        collection_method: "offline",
        poCode,
      };
      await createSubscription(subscribeDetails, get(currentUser, "user.accessToken", ""));
      setDetailsPage(true);
    }
    setLoading(false);
  };

  const handleAddNewSubscription = async () => {
    setDetailsPage(false);
    setLoading(true);
    setStep(2);
    setSelectedProduct("");
    setCustomPlanLabel("");
    setPoCode("");
    setPaymentType("");
    const customerDetails = await getCustomerDetails({
      stripe_customer_id: customerID,
      show_id,
    }, get(currentUser, "user.accessToken", ""));
    const { address, phone, email, name, metadata, invoice_settings } = customerDetails;
    const { line1, city, state } = address;
    setCustomerDetails({
      show_code,
      show_id,
      countryCode: metadata.countryCode || "",
      phone,
      secondaryEmail: metadata.secondaryEmail || "",
      email,
      firstName: name.split(" ")[0] || "",
      lastName: name.split(" ")[1] || "",
      line1,
      city,
      state,
      productionName: invoice_settings.custom_fields[0].value,
      note: metadata.note || "",
    });
    setLoading(false);
  };

  return (
    <div className="subscribe-wrapper">
      <ProgressLoader loading={loading} />
      {!!detailsPage ? (
        <SubscriptionDetails
          handleAddNewSubscription={handleAddNewSubscription}
          customerID={customerId}
          showAdminHeaderMenuRef={showAdminHeaderMenuRef}
        />
      ) : (
        <div className={`subscribe-steps-wrapper ${step === 1 ? "customer-details-form" : ""}`}>
          <div className="heading-text">{`${step === 1 ? "Customer Profile" : "Select License"}`}</div>
          {getHeaderTabs()}
          {getStepDetails()}
          <StepperAction
            stepperActionHandler={stepperActionHandler}
            step={step}
            setStep={setStep}
            customerID={customerId}
            disableActionButton={disableActionButton}
            handleSubscript={handleSubscript}
          />
        </div>
      )}
    </div>
  );
};
export default Subscribe;
