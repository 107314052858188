import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from 'antd';
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { Mixpanel } from "../../Service/Mixpanel";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
interface IProps {
  show_id: string;
  canvasRef: any;
  stream: any;
  iceServers: any[];
  refreshKey: number;
  setLoading: (value: boolean) => void;
  isReady: () => void;
  showDominantSpeaker: boolean;
  dominantSpeakerID: any;
  localAudioMuteValue: boolean;
  localParticipantID: string;
  localParticpantTrack: any;
  dominantSpeakerTrack: any;
  showStreamUpdate: any;
  volumeMute: any;
}

const ShowStream = (props: IProps) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  useEffect(() => {
    let pc: RTCPeerConnection;
    let ws: WebSocket;

    const script = document.createElement("script");
    script.src = "https://webrtc.github.io/adapter/adapter-latest.js"; //May want to locally load or statically host on local site
    script.async = true;
    document.body.appendChild(script);
    props.isReady();

    const connect = async () => {
      try {
        setSuccessMessage("");
        setErrorMessage("");

        // Subscribe to Stream
        console.log("** Subscribe to stream")
        props.setLoading(true);
        const input = {
          show_id: props.show_id,
          stream_id: props.stream.stream_id,
          client_type: "web"
        };
        const data = {
          api: api.streams.subscribeStream,
          payLoad: JSON.stringify(input),
        };

        const result: any = await backend.save(data, get(currentUser, "user.accessToken", ""));
        props.setLoading(false);

        if (result && result.jwt && result.url) {
          const jwt = result.jwt;
          const url = result.url;

          pc = new RTCPeerConnection({
            iceServers: props.iceServers,
            rtcpMuxPolicy: "require",
            bundlePolicy: "max-bundle",
          });

          pc.ontrack = function (event) {
            //Play it
            let vidWin = document.getElementsByTagName("video")[0];
            if (vidWin) {
              vidWin.srcObject = event.streams[0];
              vidWin.controls = false;
            }
          };

          console.log("connecting to: ", url + "?token=" + jwt);
          ws = new WebSocket(url + "?token=" + jwt);

          ws.onopen = async () => {
            try {

              const setMultiopus = (offer : any) => {
                const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
                console.log('isChrome: ',isChrome); 
                if(isChrome) { 
                  console.log('agent: ',navigator.userAgent);
                  //Find the audio m-line 
                  const res: any = /m=audio 9 UDP\/TLS\/RTP\/SAVPF (.*)\r\n/.exec(offer.sdp);
                  //Get audio line 
                  const audio = res[0]; 
                  //Get free payload number for multiopus 
                  const pt = Math.max(...res[1].split(" ").map( Number )) + 1; 
                  //Add multiopus 
                  const multiopus = audio.replace("\r\n"," ") + pt + "\r\n" + "a=rtpmap:" + pt + " multiopus/48000/6\r\n" + "a=fmtp:" + pt + " channel_mapping=0,4,1,2,3,5;coupled_streams=2;minptime=10;num_streams=4;useinbandfec=1\r\n"; 
                  //Change sdp
                  console.log('create multi-opus offer',offer); 
                  offer.sdp = offer.sdp.replace(audio,multiopus); 
                  return offer.sdp
                }
                else { 
                  console.log('no multi-opus support');
                  return offer.sdp; 
                }
              }

              const offer: any = await pc.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
              })
              
              offer.sdp = offer.sdp.replace("useinbandfec=1", "useinbandfec=1; stereo=1");
              try { 
                offer.sdp = setMultiopus(offer); 
              } 
              catch(e){ 
                console.log('create offer stereo',offer); 
              }
              
              const data = {
                streamId: props.stream.account_id,
                sdp: offer.sdp,
              };
              

              await pc.setLocalDescription(offer);

              const payload = {
                type: "cmd",
                transId: 0,
                name: "view",
                data,
              };

              ws.send(JSON.stringify(payload));
            } catch (err: any) {
              console.error(err);
            }
          };

          ws.addEventListener("message", async (evt) => {
            console.log("ws::message", evt);

            const message = JSON.parse(evt.data);
            const { type, name } = message;

            if (type === "event") {
              if (name === "active") {
                Mixpanel.track("Viewer Watch Stream", {
                  Platform: "Web",
                  "Video Play": "Active",
                  "Stream Label": props.stream.stream_label,
                });
              }
              if (name === "inactive") {
                Mixpanel.track("Viewer Watch Stream", {
                  Platform: "Web",
                  "Video Play": "Inactive",
                  "Stream Label": props.stream.stream_label,
                });
              }
            }

            if (type === "response") {
              const { sdp } = message.data;

              await pc.setRemoteDescription(
                new RTCSessionDescription({
                  type: "answer",
                  sdp: sdp,
                })
              );
            }
          });
        }
      } catch (err: any) {
        let message = "Stream is NOT Live !";
        console.log(err);

        Mixpanel.track("Viewer Watch Stream", {
          Platform: "Web",
          "Video Play": "NotLive",
          "Stream Label": props.stream.stream_label,
        });

        if (err && err.status === "fail") {
          message = err.data.message;
          console.log(message);
        }
        setErrorMessage(message);
        setShowModal(true);
      } finally {
        props.setLoading(false)
      }
    };

    if (props.stream && props.show_id) {
      connect();
    }

    return () => {
      if (pc) {
        pc.close();
      }
      if (ws) {
        ws.close();
      }
    };
  }, [props.show_id, props.stream, props.iceServers, props.refreshKey]);

  useEffect(() => {
    const dominantSpeakerContainer: any = document.getElementsByClassName("dominant-speaker-view");

    if (!props.showDominantSpeaker && !!dominantSpeakerContainer) {
      while (!!dominantSpeakerContainer[0] && dominantSpeakerContainer[0].firstChild) {
        dominantSpeakerContainer[0].removeChild(dominantSpeakerContainer[0].lastChild);
      }
    }
    const elem: any = document.getElementsByTagName("video")[0];
    if (!!elem) {
      elem.muted = props.showDominantSpeaker ? true : props.volumeMute;
    }

  }, [props.showDominantSpeaker, props.localAudioMuteValue, props.dominantSpeakerID, props.localParticpantTrack, props.dominantSpeakerTrack, props.showStreamUpdate]);

  const renderDominantSpeakerView = () => {
    // Get speaker div
    let dominantSpeakerDiv: any = null;
    let track: any = {};
    let tagName = "";

    if(props.showDominantSpeaker){
      if(!!props.dominantSpeakerID) {
        dominantSpeakerDiv = document.getElementById(props.dominantSpeakerID);
        track = props.dominantSpeakerTrack;
      } else {
        dominantSpeakerDiv = document.getElementById(props.localParticipantID);
        track= props.localParticpantTrack;
      }
    }

    if (!!dominantSpeakerDiv) {
      const div = document.createElement("div");
      div.id = `${dominantSpeakerDiv.id}_clone`;
      div.style.height = "100%";
      div.style.width = "calc(100% - 60px)";
      div.setAttribute('class', 'videoparticipant');

      let images = dominantSpeakerDiv.getElementsByTagName('img');

      if (images.length > 0) {
        const image = document.createElement("img");
        image.src = images[0].getAttribute("src");
        image.style.height = "100%";
        image.style.width = "100%";
        div.insertBefore(image, div.childNodes[0]);
        tagName="IMAGE";
      } else {
        if(!!track && !!track.attach){
          div.appendChild(track.attach());
          tagName="VIDEO";
        }
      }

      const dominantSpeakerContainer: any = document.getElementsByClassName("dominant-speaker-view");

      if(div.id !== dominantSpeakerContainer[0].childNodes[0]?.id){
        while (dominantSpeakerContainer[0].firstChild) {
          dominantSpeakerContainer[0].removeChild(dominantSpeakerContainer[0].lastChild);
        }
        dominantSpeakerContainer[0].appendChild(div);
      } else {
        // if(tagName !== dominantSpeakerContainer[0]?.childNodes[0]?.childNodes[0]?.tagName){
          while (dominantSpeakerContainer[0]?.firstChild) {
            dominantSpeakerContainer[0].removeChild(dominantSpeakerContainer[0].lastChild);
          }
          dominantSpeakerContainer[0].appendChild(div);
        // }
      }
    }

    return null;
  }

  const getDominantSpeakerShowStatus = () => {
      if(props.showDominantSpeaker){
        // if(!props.localAudioMuteValue) {
        //   return true;
        // } else if(!!props.dominantSpeakerID) {
        //   return true;
        // } else {
        //   return false;
        // }
        return true;
      }
      return false;
  };


  return (
    <div className="viewerstream">
      <div className="viewcontainer">
        {/* <div className="vieweraspectratio"> </div> */}

        <div className="dominant-speaker-view">
          {
            getDominantSpeakerShowStatus() &&
            renderDominantSpeakerView()
          }
        </div>
        <div className={getDominantSpeakerShowStatus() ? "d-none" : ""}>
          <video
            className="viewerstream-video"
            id="player"
            width="100%"
            height="100%"
            controls={false}
            autoPlay
            disablePictureInPicture
            playsInline
          ></video>
          <canvas
            className="viewerstream-overlay"
            ref={props.canvasRef}
            id="canvas"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              zIndex: 1,
              top: "0",
              left: "0"
            }}
          ></canvas>
        </div>
      </div>
      <Modal
        open={showModal}
        centered={true}
        onCancel={() => { setShowModal(false) }}
        footer={[
          <Button type="primary" onClick={() => { setShowModal(false) }}>
            OK
          </Button>
        ]}
      >
        <div className="btn-close">Stream is not currently running. Please reload the page once the publisher starts the stream.</div>
      </Modal>
    </div>
  );
};

export default ShowStream;
