import React from "react";
import { Layout } from "../PageLayout/Layout";
import { Link } from "react-router-dom";

const data = [
  {
    "path": "http://status.twilio.com",
    "StatusOfLable": "Twilio -",
    "label": "Twilio Status"
  },
  {
    "path": "http://status.algolia.com/",
    "StatusOfLable": "Algolia -",
    "label": "Algolia Status"
  },
  {
    "path": "https://status.pubnub.com/",
    "StatusOfLable": "Pubnub -",
    "label": "Pubnub Status"
  },
  {
    "path": "https://status.dolby.io/",
    "StatusOfLable": "Dolby -",
    "label": "Dolby Status"
  },
  {
    "path": "https://status.sendgrid.com/",
    "StatusOfLable": "Sendgrid -",
    "label": "Sendgrid Status"
  },
  {
    "path": "https://status.sentry.io/",
    "StatusOfLable": "Sentry -",
    "label": "Sentry Status"
  }
];

const listLinks = data.map(
  (element, index: any) => {
    return (
      <div className="statusLinks" key={index}>{element.StatusOfLable}
        {<Link className="mouse_hover"
        onClick={(e)=>{
          if (document.activeElement){
            if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();
          }}
        }
          to={element.path} target="_blank">{element.label}</Link>}
      </div>
    )
  }
)

function StatusPage() {
  return (
    <Layout page='statuspage'>
      <div className='status-wrapper'>
        <h3 className='status-header'>Status Page</h3>
        <div>{listLinks}</div>
      </div>
    </Layout>
  );
}

export default StatusPage

