import React, { useState, useEffect, useContext } from 'react';
import { View } from '@millicast/sdk';
import { Button, Modal, message } from 'antd';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import useDbTrigger from '../../hooks/useDbTrigger';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import ProgressLoader from '../Common/ProgressLoader';

interface IProps {
  show_id: string;
  stream: any;
  iceServers: any[];
  refreshKey: number;
}

const ShowStreamMultiView = (props: IProps) => {
  console.log('**** ShowStreamMultiViewer *****');

  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [noStreamModal, setNoStreamModal] = useState(false);
  const [streamLoading, setStreamLoading] = useState(true);
  const [refreshStream, setRefreshStream] = useState(Math.random());
  const [streamStatus, setStreamStatus] = useState(false);
  const [isStreamActive, setIsStreamActive] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const vcRoomNotifications = useDbTrigger('show_vc_room', true);
  const vcRoomTriggerData = vcRoomNotifications?.doc?.data();

  const [streamResponse, setStreamResponse] = useState({
    url: '',
    jwt: ''
  });

  const [showSwitchStreamError, setShowSwitchStreamError] = useState(false);

  useEffect(() => {
    const multiVideoElement: HTMLVideoElement = document.getElementById(`${props.stream.stream_id}_video`) as HTMLVideoElement;
    if (multiVideoElement) {
      multiVideoElement.muted = true;
    }
    /* 
      SAFARI STREAMING ISSUE:  
      We are changing the url to urls because each ice server may in 
      fact have multiple ways (URLs) in which it can be located, 
      depending on network topology.
    */
    props.iceServers.map((val: any) => {
      val.urls = val.url
      return val
    })
    const tokenGenerator = async () => {
      return {
        wsUrl: streamResponse?.url,
        urls: [streamResponse?.url],
        jwt: streamResponse?.jwt,
        iceServers: props.iceServers,
      };
    };

    const millicastMultiView = new View(props.stream.stream_label, tokenGenerator, multiVideoElement);

    millicastMultiView.on('track', (event: any) => {
      multiVideoElement.srcObject = event.streams[0];
    });

    millicastMultiView.connect().catch((e: any) => {
      console.log('Connection failed, handle error', e);
    });

    millicastMultiView.on('reconnect', (err: any) => {
      console.log('Error connecting in', err.timeout);
      console.error(err.error);
    });

    const activeSources = new Set();

    millicastMultiView.on('broadcastEvent', (event: any) => {
      const { name, data } = event;
      switch (name) {
        case 'active':
          activeSources.add(data.sourceId);
          setIsStreamActive(true);
          console.log('Active Stream.');
          break;
        case 'inactive':
          activeSources.delete(data.sourceId);
          if (activeSources.size === 0) {
            console.log('No active Stream.');
            setIsStreamActive(false);
          }
          break;
        default:
          break;
      }
    });

    return () => {
      millicastMultiView.stop();
    };
  }, [streamResponse, props.iceServers])


  useEffect(() => {
    const connectToStream = async () => {
      try {
        // Subscribe to Stream
        console.log('** Subscribe to stream');
        setStreamLoading(true);
        const input = {
          show_id: props.show_id,
          stream_id: props.stream.stream_id,
          client_type: 'web',
        };
        const data = {
          api: api.streams.subscribeStream,
          payLoad: JSON.stringify(input),
        };

        const result: any = await backend.save(data, get(authValue, 'accessToken', {}));

        if (result && result.jwt && result.url) {
          setStreamResponse(result)
          setNoStreamModal(false);
        }
      } catch (err: any) {
        let message = 'Stream is NOT Live !';
        const multiVideoElement: HTMLVideoElement = document.getElementById(`${props.stream.stream_id}_video`) as HTMLVideoElement;
        if(multiVideoElement) {
          multiVideoElement.srcObject = null;
          multiVideoElement.muted = true;
        }
        console.log(err);
        if (err && err.status === 'fail') {
          message = err.data.message;
          console.log(message);
        }
        setStreamResponse({
          url: '',
          jwt: ''
        })
        setNoStreamModal(true);
      } finally {
        console.log('**** Finally Connect Process is done !!');
        setStreamLoading(false);
      }
    };

    if (props.stream && props.show_id && props.iceServers.length) {
      connectToStream();
    }
  }, [props.refreshKey, refreshStream]);

  const handleStreamSwitching = () => {
    const currentStreamVideo: HTMLVideoElement = document.getElementById(`${props.stream.stream_id}_video`) as HTMLVideoElement;
    const mainStreamVideo: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;

    if (currentStreamVideo.srcObject && mainStreamVideo.srcObject && isStreamActive) {
      const currentSrc = currentStreamVideo.srcObject;
      const mainSrc = mainStreamVideo.srcObject;

      currentStreamVideo.srcObject = mainSrc;
      mainStreamVideo.srcObject = currentSrc;
    } else {
      setShowSwitchStreamError(true)
    }
  }

  useEffect(() => {
    if (
      vcRoomNotifications.type === 'modified' &&
      vcRoomTriggerData?.showStream_id?.id === props.stream.stream_id
    ) {
      if (!streamStatus) {
        setStreamStatus(get(vcRoomTriggerData, 'streaming', false));
        
        if (get(vcRoomTriggerData, 'streaming', false)) {
          messageApi.open({
            type: 'success',
            content: `${props.stream.stream_label} has started.`,
          });
  
          setRefreshStream(Math.random());
        }
      }
    }
  }, [vcRoomNotifications, vcRoomTriggerData]);

  return (
    <div className='viewerstream'>
      {contextHolder}
      <div className='viewcontainer'>
        <div
          className={'video-container'}
        >
          {!noStreamModal ?
            <video
              className='viewerstream-video'
              id={`${props.stream.stream_id}_video`}
              width='100%'
              height='100%'
              controls={false}
              autoPlay
              disablePictureInPicture
              playsInline
              key={'videoPlayer'}
              onClick={handleStreamSwitching}
            ></video>
              : <div className='inactive-stream-section'><span className='inactive-stream-text'>Stream not active !</span></div>
          }
        </div>
      </div>
      <ProgressLoader loading={streamLoading} size={'mini'} />
      <Modal
        open={showSwitchStreamError}
        centered={true}
        onCancel={() => {
          setShowSwitchStreamError(false);
        }}
        footer={[
          <Button
            type='primary'
            onClick={() => {
              setShowSwitchStreamError(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className='btn-close'>
          Only two active streams can be switched.
        </div>
      </Modal>
    </div>
  );
};

export default ShowStreamMultiView;
