import { useState, useCallback, useEffect } from 'react';
import { ZoomClient } from '../types/index-types';

export function useAudio(zmClient: ZoomClient, mediaStream: any) {
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [muteAll, setMuteAll] = useState(false);
  const excludedElementTypes = ["input", "textarea", "select"];

  const joinAudio = async () => {
    await mediaStream?.startAudio(
      { originalSound: {
        stereo: true,
        hifi: true
      }}
    )
  }

  const muteAudio = async () => {
    const stream = zmClient?.getMediaStream()
    await stream?.muteAudio()
  }

  const unmuteAudio = async () => {
    const stream = zmClient?.getMediaStream()
    await stream?.unmuteAudio()
  }

  const onCurrentAudioChange = useCallback((payload: any) => {
    const { action } = payload;
    if (action === 'muted') {
      setIsAudioOn(false)
    } else if (action === 'unmuted') {
      setIsAudioOn(true)
    } else if (action === 'join') {
      const currentUserInfo = zmClient.getCurrentUserInfo();
      const isMuted = currentUserInfo.muted;
      setIsAudioOn(!isMuted)
    }
  }, []);

  const onMicClick = async () => {
    const currentUserInfo = zmClient.getCurrentUserInfo();
    const isAudioEnabled = !!currentUserInfo.audio;
    const isMuted = currentUserInfo.muted;

    if (isAudioEnabled) {
      if (isMuted) {
        await unmuteAudio();
      } else {
        await muteAudio();
      }
    } else {
      await joinAudio();
      // if (zmClient.getCurrentUserInfo().muted) {
      //   await unmuteAudio();
      // }
    }
  };

  const handleGlobalMute = async () => {
    const currentUserInfo = zmClient.getCurrentUserInfo();
    const isAudioEnabled = !!currentUserInfo.audio;
    const isMuted = currentUserInfo.muted;

    const muteAllFlag = !muteAll
    setMuteAll(muteAllFlag)

    //when MuteAll is enabled
    if (muteAllFlag) {
      //if audio is enabled and user is not muted, mute audio
      if (isAudioEnabled) {
        if (isMuted === false) {
          await muteAudio();
        }
      }
    } else {
      if (isAudioEnabled) {
        if (isMuted) {
          await unmuteAudio();
        }
      } else {
        await joinAudio();
        if (zmClient.getCurrentUserInfo().muted !== false) {
          await unmuteAudio();
        }
      }
    }
    const commandChannel = zmClient.getCommandClient();
    commandChannel.send(JSON.stringify({ globalMute: muteAllFlag }))
  }

  const handleHostAskUnmuteAudio = useCallback(async (muteFlag: boolean) => {
    const currentUserInfo = zmClient.getCurrentUserInfo();
    const isAudioEnabled = !!currentUserInfo.audio;
    const isMuted = currentUserInfo.muted;

    //when MuteAll is enabled
    if (muteFlag) {
      //if audio is enabled and user is not muted, mute audio
      if (isAudioEnabled) {
        if (isMuted === false) {
          await muteAudio();
        }
      }
    } else {
      if (isAudioEnabled) {
        if (isMuted) {
          await unmuteAudio();
        }
      } else {
        await joinAudio();
        if (zmClient.getCurrentUserInfo().muted) {
          await unmuteAudio();
        }
      }
    }
  }, [zmClient, unmuteAudio, muteAudio, joinAudio]);

  const onCommandChannelMessage = useCallback((payload: any) => {
    const { globalMute } = JSON.parse(payload.text);

    if (globalMute !== undefined) {
      handleHostAskUnmuteAudio(globalMute);
    }
  }, [handleHostAskUnmuteAudio]);

  useEffect(() => {
    zmClient.on('current-audio-change', onCurrentAudioChange);
    zmClient.on('command-channel-message', onCommandChannelMessage);
    return () => {
      zmClient.off('current-audio-change', onCurrentAudioChange);
      zmClient.off('command-channel-message', onCommandChannelMessage);
    };
  }, [zmClient, onCurrentAudioChange, onCommandChannelMessage]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;
      if (excludedElementTypes.includes(target.tagName.toLowerCase())) {
        return;
      }
      if (event.key === "`") {
        onMicClick();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onMicClick]);

  return {
    isAudioOn,
    joinAudio,
    muteAudio,
    unmuteAudio,
    onMicClick,
    muteAll,
    handleGlobalMute,
    handleHostAskUnmuteAudio
  };
}
