import { List, Modal } from 'antd';

interface warningModalProps {
  openFlag: boolean;
  content: any[];
  onOk: () => void;
  onCancel: () => void;
}

const WarningModal = (props: warningModalProps) => {
  console.log(props.content)
  return (
    <Modal
      title={'Guest Locked'}
      open={props.openFlag}
      onOk={props.onOk}
      onCancel={props.onCancel}
      width={1000}
    >
      <List
        dataSource={props.content}
        renderItem={(item: any) => (
          <List.Item key={item.guestId}>
            `A user “{item.guestName}” has made many failed password attempts on the guest link. It’s
            recommended to delete the guest link and share the new link and password with your
            guest. Do you want to delete the link?`
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default WarningModal;
