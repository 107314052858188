import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "semantic-ui-react";
import PubViewerForm from "./PubViewerForm";
import PubViewerFormNew from "./PubViewerFormNew";
import api from "../../../Service/Api";
import backend from "../../../Service/Backend";
import {
  PAGES,
  SHOWADMIN_PAGES,
  FEATURE_NAME,
  ROLES,
} from "../../../Utils/constants";
import { Mixpanel } from "../../../Service/Mixpanel";
import { MESSAGES , EVENT_TYPES } from "../../../Utils/constants";
import OverLay from "../../../Components/Common/OverLay";
import { countryCodeData } from "../../../Service/countryCodes";
import { isFeatureIncluded } from "../../../Service/show";
import { searchClient } from "../../../Service/show";
import Title from "../../../Components/UIKit/Title";
import { get } from '../../../Utils/helpers';
import { AuthContext } from '../../../Context/authContext';
import { logEvent } from "../../../Service/LogEvent";

// CSS
import "./style.css"

interface IProps {
  showId: string;
  showName: string;
  setUserUpdated: any;
  currentShow?: any;
  currentConfig?: any;
  setLoading: any;
  pageName?: any;
  configValue?: any;
  licenseMangerFlag?: boolean;
  show_admin_id?: any;
  setInvitedUsers: any;
  invitedUsers: any;
  manageShowGroupUsersStatus?: any;
  setManageShowGroupUsersStatus?: any;
  fetchShowGroupData?: any;
  showGroup?: any;
  fetchShowGroupUsers?: any;
  usersGroupsFlag?: boolean;
  isUserGroupFeature?: any;
  isMasvFeature?: boolean;
}

const CreatePublisherViewer = (props: IProps) => {
  const { invitedUsers, setInvitedUsers } = props;

  const currentUser = get(useContext(AuthContext), "currentUser", {} );

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [overlayMsg, setOverlaymsg] = useState("");

  const [show_id, setShowId] = useState("");

  const [countryCodes, setCountryCodes] = useState([]);
  const [listShowGroup, setShowListGroup] = useState([]);

  const [configEnabled, setConfigEnabled] = useState(false);
  const [configActualValue, setConfigActualValue] = useState(0);
  const [newConfigValue, setnewConfigValue] = useState(0);
  const [showConfigId, setShowConfigId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [isGuestInviteIncluded, setGuestInviteIncluded] = useState(false);
  const [licenseManager, setLicenseManager] = useState(false);

  const {
    fetchShowGroupData,
    showGroup,
    setManageShowGroupUsersStatus,
    manageShowGroupUsersStatus,
    showId,
    usersGroupsFlag,
    isUserGroupFeature
  } = props
  useEffect(() => {
    // if (isFeatureIncluded(FEATURE_NAME.ALLOW_GUEST_INVITE, props.showId)) {
    //   setGuestInviteIncluded(true);
    // }
    algoliaFeatureFlags()
  }, []);

  const algoliaFeatureFlags = async () => {
    const result = await isFeatureIncluded(FEATURE_NAME.ALLOW_GUEST_INVITE, props.showId)
    await searchClient.clearCache();
    if (result) {
      setGuestInviteIncluded(true);
    }
  }


  useEffect(() => {
    //  if (isFeatureIncluded(FEATURE_NAME.ALLOW_LICENSE_MANGER, props.showId)) {
    //   setLicenseManager(true);
    // }
    const licenseManagerIncluded = async () => {
      const result = await isFeatureIncluded(FEATURE_NAME.ALLOW_LICENSE_MANGER, props.showId)
      if (result) {
        setLicenseManager(true);
      }
    }
    licenseManagerIncluded()
  }, []);

  useEffect(() => {
    fetchShowGroupData();
  }, []);

  useEffect(() => {
    setManageShowGroupUsersStatus(false)
  }, [])

  useEffect(() => {
    // Mixpanel
    searchClient.clearCache();
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SHOW_ADMIN_PAGE + "_" + SHOWADMIN_PAGES.INVITE_USER,
    });

    const fetchData = async () => {
      try {
        ;

        console.log('======== show id', showId)

        const getShowListData = {
          api: api.group.listShowGroup,
          queryParam: { show_id: showId },
        };
        props.setLoading(true);
        // call fetch country code data function to get country code data
        const countryCodesResponse = await countryCodeData(get(currentUser, "user.accessToken", ""));
        setCountryCodes(countryCodesResponse);
        // call fetch country code data function to get country code data
        if(isUserGroupFeature){
        const listGroupRes = await backend.fetch(getShowListData, get(currentUser, "user.accessToken", ""));
        // props.setLoading(false);
        setShowListGroup(listGroupRes)
        // Rename country code
        // const updatedCountryValues = countryCodes.map(({
        //   text: value, 
        //   value:text,
        //   code,
        //   country_name,
        //   id,
        //   count
        // })=>({
        //   text, 
        //   value,
        //   code,
        //   country_name,
        //   id,
        //   count
        // }));
        }
      } catch (err) {
        const msg = "Error getting Shome info";
        console.error(msg);
        if (err.errMessage) {
          console.error(err.errMessage);
        }
      }
      finally {
        props.setLoading(false);

      }
    };
    if (props.showId && props.showId !== show_id) {
      console.log("pros configValue", props.configValue)
      setShowId(props.showId);
      fetchData();
    }
  }, [props.showId]);

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showOverlayForThreeSeconds = async () => {
    setOverlayDisplay(true);
    await sleep(3000);
    setOverlayDisplay(false);
  };

  const createUser = async (
    userdata: any,
    isPublisherChecked: boolean,
    setPublisherCheck: any,
    setRoles: any
  ) => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
      console.log('check userdat here', userdata)
      // No show
      if (!props.showName) {
        throw new Error("No Show !");
      }

      // Validate publisher count limit
      if (!!props.currentShow) {
        const { parent_show_id } = props.currentShow;
        const currentConfig = props.currentConfig;
        try {
          const getShowsConfig = {
            api: api.configs.listShowConfigsByShowId,
            urlParam: !!parent_show_id ? parent_show_id : show_id,
            queryParam: { config_name: "publisher_count" },
          };

          const getActiveShowPublishers = {
            api: !!parent_show_id
              ? api.users.listActiveChildShowPublisherInShow
              : api.users.listActiveShowPublisherInShow,
            urlParam: !!parent_show_id ? parent_show_id : show_id,
          };
          props.setLoading(true);
          const showConfigDetails = await backend.fetch(getShowsConfig, get(currentUser, "user.accessToken", ""));
          const listOfShowPublishersInShow = await backend.fetch(
            getActiveShowPublishers, get(currentUser, "user.accessToken", "")
          );
          props.setLoading(false);
          const mergeConfig = showConfigDetails.map((showConfig: any) => {
            const filterConfig = currentConfig.filter(
              (config: any) => config["id"] === showConfig["config_id"]
            );
            showConfig["config_enable"] =
              filterConfig.length > 0 && !!filterConfig[0]["config_enable"];
            return showConfig;
          });
          if (mergeConfig.length > 0) {
            setConfigEnabled(mergeConfig[0].config_enable);
            setConfigActualValue(listOfShowPublishersInShow.length);
            setnewConfigValue(mergeConfig[0].config_value);
            setShowConfigId(mergeConfig[0].id);
            if (
              !!mergeConfig[0].config_enable &&
              !!isPublisherChecked &&
              mergeConfig[0].config_value > 0 &&
              listOfShowPublishersInShow.length + 1 >
              mergeConfig[0].config_value
            ) {
              setModalOpen(true);
              setPublisherCheck(false);
              setRoles({
                ...userdata.roles,
                [ROLES.SHOW_PUBLISHER]: false,
              });
              return false;
            }
          }
        } catch (err: any) {
          props.setLoading(false);
          console.log("err", err);
        }
      }

      const {
        user_title,
        user_name_first,
        user_name_last,
        user_email,
        user_phone,
        country_code,
        roles,
        comments,
        show_group_ids,
        guest_invite,
      } = userdata;

      if (!roles.ShowPublisher && !roles.ShowViewer && (props.isMasvFeature && !roles.MailBox)) {
        throw new Error("No Role !");
      }

      // Call Backend to Create Publisher and Viewer
      const input = {
        user_title,
        user_name_first,
        user_name_last,
        user_email,
        user_phone,
        country_code,
        show_id,
        roles,
        comments,
        show_group_ids,
        guest_invite,
      };
      
      const inviteShowUserDetails = {
        user_name_first,
        user_name_last,
        user_email,
        show_id,
        role: roles
      }

      const data = {
        api: api.users.createpubviewer,
        payLoad: JSON.stringify(input),
      };

      props.setLoading(true);
      const response = await backend.save(data, get(currentUser, "user.accessToken", ""));
      const inviteShowUserEvent = logEvent(get(currentUser, 'accessToken', ''), EVENT_TYPES.INVITE_SHOW_USER, inviteShowUserDetails);
      console.log("InviteShowUserEvent:", inviteShowUserEvent);
      await searchClient.clearCache();
      const user_id = response.newuser_id.trim()
      const allUserRoles: any[] = await backend.fetch({
        api: api.users.getAllUserRole,
        urlParam: user_id,
        queryParam: { show_id },
      }, get(currentUser, "user.accessToken", ""));
      const filterUserRole = allUserRoles
        .filter(
          (role) =>
            role.show_id === show_id &&
            [ROLES.SHOW_VIEWER, ROLES.SHOW_PUBLISHER, ROLES.MAILBOX].includes(role.role_type)
        )
      if (roles.ShowPublisher === true && props.licenseMangerFlag === true) {
        const historyPayload = {
          show_name: props.currentShow.show_name,
          show_id: props.showId,
          user_id: props.show_admin_id,
          action: "ADD_PUBLISHER_LICENSE",
          action_userid: user_id,
          action_time: new Date()
        }
        const historyData = {
          api: api.licenseHistory.addPublisherLicense,
          //urlParam: user_id,
          payLoad: JSON.stringify(historyPayload),
        };
        await backend.save(historyData, get(currentUser, "user.accessToken", ""));
      }
      if (
        !!configEnabled &&
        !!newConfigValue &&
        newConfigValue > 0 &&
        !!roles.ShowPublisher
      ) {
        const configInput = {
          show_id,
          show_config_id: showConfigId,
          config_value: newConfigValue,
          config_actual_value: configActualValue + 1,
        };
        const config = {
          api: api.configs.updateShowConfigs,
          payLoad: JSON.stringify(configInput),
        };
        await backend.save(config, get(currentUser, "user.accessToken", ""));
      }
      setSuccessMessage(MESSAGES.CREATE_USER_SUCCESS);
      props.setLoading(false);
      setOverlaymsg(
        `User ${user_name_first + " " + user_name_last} has been Added`
      );
      await showOverlayForThreeSeconds();

      props.fetchShowGroupUsers()

      Mixpanel.track("Admin User Action", {
        Platform: "Web",
        "Action Email": user_email,
        "Action Type": "InviteUser",
        "Admin Type": "ShowAdmin",
      });
      const invitedUsersArr = [...invitedUsers]
      for (const val of filterUserRole) {
        if(invitedUsersArr.findIndex((user: any) => user.user_id === val.user_id && user.role_type === val.role_type) < 0){
          invitedUsersArr.push(val);
        }
      }
      setInvitedUsers(JSON.parse(JSON.stringify(invitedUsersArr)));
    } catch (err: any) {
      const msg = "Error while creating user !";
      if (err.errMessage) {
        if (err.errMessage === "TOO_SHORT" || err.errMessage === "TOO_LONG") {
          setErrorMessage(`Given mobile number is ${err.errMessage}`);
        } else {
          setErrorMessage(err.errMessage);
        }
      } else {
        setErrorMessage(msg);
      }
    } finally {
      props.setLoading(false);
      //await showOverlayForThreeSeconds();
    }
  };


  return (
    <div className="createshowadmin">
      {/* <div className="form-content margin-left-0"> */}

      <div className={usersGroupsFlag ? "tab-wrapper" : ""}>
        {usersGroupsFlag ?
          <Title>Invite To: {props.showName}</Title>
          :
          <div className="heading margin-0 ">
            <p className="heading-text-center">
              Invite To:
              <span className="sub-heading-text">{props.showName}</span>
            </p>
            <hr className="top" />
          </div>
        }
        {
          usersGroupsFlag ?
            <PubViewerFormNew
              listShowGroup={showGroup}
              isGuestInviteIncluded={isGuestInviteIncluded}
              licenseMangerFlag={props.licenseMangerFlag}
              lmconfigValue={props.configValue}
              countryCodes={countryCodes}
              successMessage={successMessage}
              errorMessage={errorMessage}
              createUser={createUser}
              usersGroupsFlag={usersGroupsFlag}
            /> :
            <div className="form-content margin-left-0">
            <PubViewerForm
              isGuestInviteIncluded={isGuestInviteIncluded}
              licenseMangerFlag={props.licenseMangerFlag}
              lmconfigValue={props.configValue}
              countryCodes={countryCodes}
              successMessage={successMessage}
              errorMessage={errorMessage}
              createUser={createUser}
              usersGroupsFlag={usersGroupsFlag}
              isMasvFeature={props.isMasvFeature}
            />
            </div>
        }

        <Modal
          open={modalOpen}
          size={"tiny"}
          onClick={() => setModalOpen(false)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Publisher Limit Reached</Modal.Header>
          <Modal.Content>
            You have already assigned ({newConfigValue}) publishers. Please remove
            one or purchase another license to add another
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={() => setModalOpen(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
        <OverLay overlayMsg={overlayMsg} display={overlayDisplay} />
      </div>

    </div>
  );
};

export default CreatePublisherViewer;
