import { get } from '../../../Utils/helpers';

export default function useDeviceSettings(device_settings: []) {
  const colorFormatObject = get(get(device_settings, '0', ''), 'color_format', {});
  const framerateObject = get(get(device_settings, '0', ''), 'framerate', {});
  const videoResolutionObject = get(get(device_settings, '0', ''), 'video_resolution', {});
  const videoInputObject = get(get(device_settings, '0', ''), 'video_input', {});
  const videoBitrateObject = get(get(device_settings, '0', ''), 'video_bitrate', {});

  const getColourFormat = () => {
    const colorFormatKeys = Object.keys(colorFormatObject);
    const colorFormatOptions = colorFormatKeys.map((key: any) => {
      return { key: key, value: get(colorFormatObject, key, ''), label: key };
    });

    return colorFormatOptions;
  };

  const getFramerate = () => {
    const framerateKeys = Object.keys(framerateObject);
    const framerateValues = Object.values(framerateObject);

    const result = framerateKeys.map((key: any) => {
      return { key: key, value: framerateValues[framerateKeys.indexOf(key)], label: key };
    });

    return result;
  };

  const getVideoResolution = () => {
    const videoResolutionKeys = Object.keys(videoResolutionObject);
    const videoResolutionOptions = videoResolutionKeys.map((key: any) => {
      return { key: key, value: get(videoResolutionObject, key, ''), label: key };
    });
    return videoResolutionOptions;
  };

  const getVideoInput = () => {
    const videoInputKeys = Object.keys(videoInputObject);
    const videoInputOptions = videoInputKeys.map((key: any) => {
      return { key: key, value: get(videoInputObject, key, ''), label: key };
    });
    return videoInputOptions;
  };

  const getVideoBitrate = () => {
    const videoBitrateKeys = Object.keys(videoBitrateObject);
    const videoBitrateOptions = videoBitrateKeys.map((key: any) => {
      return { key: key, value: get(videoBitrateObject, key, ''), label: key };
    });
    return videoBitrateOptions;
  };

  return { getColourFormat, getFramerate, getVideoResolution, getVideoInput, getVideoBitrate };
}
