import React from "react";
import { useLocation } from 'react-router-dom';
import "../../App.css";
import "../../Assets/css/font-face.css";
import "./TermsAndPolicy.css"

const PrivacyPolicy = () => {
    //let location = useLocation();
    const url = new URL(window.location.href).origin
   // console.log("Location url:::",location)
  return (
    <div className="container">
        <div className="heading-center">PRIVACY POLICY</div>
        <div className="content-wrapper">
            <p>Pacific Post Rentals, Inc. (doing business as Pac Post.Live) (“we”, “us”, “our”) are committed to protecting and respecting your privacy.  We will never sell or rent your personal data to anyone.</p>
            <p>This privacy policy (“Privacy Policy”) and any other documents referred to herein, sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting <a href={url} target="_blank">{url}</a>(“Site”) you are accepting and consenting to the practices described in this Privacy Policy.</p>
            <ol>
                <li>
                    <h4>DATA CONTROLLER</h4>
                    <p>In relation to the personal data of individuals located in the EU and for the purposes of the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, (“GDPR”) or any subsequent amendment or replacement or supplementary legislation (“Data Protection Law”), the data controller is Pacific Post Rentals, Inc. of 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505, USA.</p>
                </li>
                <li>
                    <h4>LEGAL BASIS FOR PROCESSING</h4>
                    <p>We collect and use the personal data described below to provide you with access to our Site and Services in a reliable and secure manner. We also collect and use personal data for our legitimate business needs; to fulfil our contractual obligations to you; and to comply with our legal obligations.</p>
                </li>
                <li>
                    <h4>INFORMATION WE MAY COLLECT FROM YOU</h4>
                    <p>We may collect and process the following information about you:</p>
                    <p>Information you give us. You may give us information about you by filling in forms on our Site or by corresponding with us by phone, email or otherwise. This includes information you provide when you register to use our Site, apps and subscribe to our services, or participate in secure video meetings or conferences, sessions, or chats, as a publisher, viewer, or sub-user, and when you report a problem with our Site or Services (“Services”). The information you give us may include your name, address, email address and phone number, company, project, or associated business. When you choose to be an active, streaming participant in virtual PacPost.Live room, you grant us access to the audio and video from your connected devices. </p>
                    <p>When you register to use the Site and each time you use the Site and our Services, we will collect the following information in order to provide access to the Services to you: display name, email address, IP address, organization/enterprise associations (if any), date and time of access to different site features (i.e. recordings, rooms you enter and authentication requests).  We also collect your operating system version, browser version, and certain plug-ins related to our services for troubleshooting purposes.</p>
                    <p>We store transmitted RTP packets containing encoded audio and video information. We also store: service access attempts (both successful and unsuccessful) to various parts of our application; invitations created and accepted; rooms created, edited, and deleted; recordings created, edited, and deleted; any annotations or chat messages that take place in a live session; any interaction with the site, including but not limited to UI element clicks, text entered (whether submitted or not submitted), and any other actions taken within the user interface of the PacPost.Live application. We also attempt to differentiate between your devices and store different device identifiers. We store this information for additional authentication security, compliance, and auditing purposes, and also to provide you a better experience on each of your different devices.</p>
                    <p>Information we collect about you. With regard to each of your visits to our Site we may automatically collect the following information:</p>
                    <p>Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.</p>
                    <p>Information about your visit, including the full Uniform Resource Locators (URL) you visited and arrived from, including date and time of access; PacPost.Live virtual room description; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</p>
                    <p>We may collect any request headers your browser sends, WebRTC session call stats and signals, and (while streaming) data from any audio or video devices you have selected and granted us access to; we collect this information to provide better security and to troubleshoot stability and performance issues, as well as to deliver the service.</p>
                    <p>Information from other online accounts to which you have given us permission to collect data from within your settings or the privacy policies of these other online services. For example, this can be by choosing to send us mobile device permissions, your location data if/when accessing our Site from your smartphone; or it can be from the integrations and connections that you choose to install when using the Services like from an Enterprise Identity Provider for single sign on.
                    Information we receive from other sources. We may receive information about you if you use any other services we may provide. In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on this Site.
                    </p>
                    <p>Information collected by third parties. We are also working closely with third parties (including, for example, sub-contractors in technical, payment and delivery services, analytics providers, content delivery networks) and may receive information about you from them.<br/>
                    Zendesk:	to connect information about customer help and information inquiry requests (for support purposes) <br/><br/>
                    Google: 	to enable email communications with you and provide Site analytics data.<br/><br/>
                    Twilio: 	to allow software developers to programmatically make and receive phone calls, send
                    and receive text messages, and perform other communication functions using its web
                    service APIs for video conference.<br/><br/>
                    Milicat:	to broadcast video and audio streams at lowest latency streaming format across a custom-
	                built Web Real-Time Communication (WebRTC) content delivery network (CDN). 
                    </p>
                </li>
                <li>
                    <h4>COOKIES AND OTHER IDENTIFIERS</h4>
                    <p>We use cookies and browser storage (aka local Storage) on our Site to distinguish you from other users of our Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve the Site.</p>
                    <h5>What are cookies</h5>
                    <p>Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the Site. Cookies can be “persistent” or “session” cookies.
                    We use persistent cookies and session cookies on our Site.</p>
                    
                    <h5>Persistent Cookies</h5>
                    <p>A persistent cookie is stored on a user’s device in between browser sessions which allows the preferences or actions of a user across the Site (or in some cases across different websites) to be remembered. We use persistent cookies to save your login information for future logins to the Site.</p>
                
                    <h5>Session Cookies</h5>
                    <p>A session cookie allows the Site to link your actions during a browser session. We use session cookies to enable certain features of the Site, to better understand how you interact with the Site and to monitor aggregate usage by users and web traffic routing on the Site. Unlike persistent cookies, session cookies are deleted from your computer when you log off from the Site and then close your browser.</p>
                    
                </li>
                <li>
                    <h4>USES MADE OF THE INFORMATION</h4>
                    <p>We use information held about you in the following ways.</p>
                    <p>To carry out our obligations arising from any contracts entered into between you and us; to provide you with the information, products and services that you request from us; to notify you about changes to our Site and Services; to ensure that content from our Site and Services is presented in the most effective manner for you and for your computer; to administer our Site and Services and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes; to inform you about scheduled Service downtimes and new features; to improve our Site and; to allow you to participate in interactive features of our service, when you choose to do so; as part of our efforts to keep our Site and Services safe and secure; to monitor your use of the Site is in compliance with any legal agreements we have with you.</p>
                    <p>We may combine information we receive from other sources with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).</p>
                    <p>We will not share, sell, or rent your personal data to any 3rd party company or entity.</p>
                </li>
                <li>
                    <h4>DISCLOSURE OF YOUR INFORMATION</h4>
                    <p>We may share certain of your information with selected third parties including professional advisors acting as service providers to us in relation to the Site - including lawyers, bankers, auditors, and insurers on an as needed basis but not part of day-to-day activities; and analytics and search engine providers that assist us in the improvement and optimization of our Site such as Google Analytics. </p>
                    <p>We may disclose your personal data to third parties in the event that we sell or buy any business or assets; if we or a member of our group of companies or substantially all of their assets are acquired by a third party, in which case personal data held by them about their customers will be one of the transferred assets; if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our legal agreement with you and/or any other agreements; or to protect our rights, property, safety, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.</p>
                </li>
                <li>
                    <h4>WHERE WE STORE YOUR PERSONAL DATA</h4>
                    <p>Our Services are global and your information (including personal data) may be stored and processed in any country where we have operations or where we engage service providers, and we may transfer your information to countries outside of your country of residence, which may have data protection rules that are different from those of your country of residence.</p>
                    <p>We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. This means that your personal data will only be transferred to a country that provides an adequate level of protection (for example, where the European Commission has determined that a country provides an adequate level of protection) or where the recipient is bound by standard contractual clauses according to conditions provided by the European Commission (“Standard Contractual Clauses”).</p>
                    <p>Our Site and Services are accessible via the internet and may potentially be accessed by anyone around the world. Other users may access the Site or Services from outside the EEA. This means that where you chose to post your data on our Site or within the Services, it could be accessed from anywhere around the world and therefore a transfer of your data outside of the EEA may be deemed to have occurred. You consent to such transfer of your data for and by way of this purpose.</p>
                </li>
                <li>
                    <h4>PROTECTION OF YOUR INFORMATION</h4>
                    <p>All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Site or Services, you are responsible for keeping this password confidential. We ask you not to share any password with anyone.</p>
                    <p>Transmissions within PacPost.Live are encrypted and secure. Unfortunately, transmissions of information via the Internet to PacPost.Live, may not be completely secure. Although we will endeavor to protect your personal data. we cannot guarantee the security of your data transmitted to our Site or the Services. Any transmission is at your own risk. Once we have received your information, we do use strict procedures and security features in the effort to prevent unauthorized access.</p>
                </li>
                <li>
                    <h4>LINKS TO OTHER WEBSITES</h4>
                    <p>Our Site and Services may, from time to time, contain links to and from the websites of others. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies should you choose to submit any personal data to these websites.</p>
                </li>
                <li>
                    <h4>DATA RETENTION</h4>
                    <p>We retain personal data for as long as necessary for the relevant activity for which it was provided or collected. This will be for as long as we provide access to the Site or Services to you, your account with us remains open or any period set out in any relevant contract you have with us. However, we may keep some data after your account is closed or you cease using the Site or Services for the purposes set out below.</p>
                    <p>We may retain personal data where reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, maintain security, prevent fraud and abuse, resolve disputes and enforce our legal agreements with you. We will retain de-personalized information after your account has been closed.</p>
                    <p>Please note: After you have closed your account or deleted information from your account, any information you have shared with others will remain visible. We do not control data that other users may have copied from the Site or Services.</p>
                
                </li>
                <li>
                    <h4>AGE OF USERS</h4>
                    <p>This Site and the Services are not intended for and shall not be used by anyone under the age of 18.</p>
                </li>
                <li>
                    <h4>PROVISIONS APPLICABLE TO USERS LOCATED IN THE EU OR THE UK</h4>
                    <p>You have the right under Data Protection Law, free of charge, to request access to your personal data; rectification or deletion of your personal data; a restriction on the processing of your personal data; object to the processing of your personal data; a transfer of your personal data (data portability) in a structured, machine readable and commonly used format; and withdraw your consent to us processing your personal data, at any time.</p>
                    <p>You can make a request in relation to any of the above rights by contacting us as set out at the end of this Privacy Policy. We will respond to such queries within 30 days and deal with requests we receive from you, in accordance with the provisions of Data Protection Law.</p>
                    
                    <h5>Storage of your personal data</h5>
                    <p>The personal data that we collect from you may therefore be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers or partners. Such staff or subcontractors may be engaged in, among other things, the fulfilment of your order, the processing of your payment details or the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing outside of the EEA.</p>
                    
                    <h5>Marketing Communications</h5>
                    <p>We will send you marketing emails if you “opt in” to receive marketing emails when registering on our Site, or if you have enquired about, or purchased any of our goods or services.
                    Please note that, if you change your mind about being sent marketing emails you can “opt out” at any time by clicking the “unsubscribe” link at the bottom of any marketing email. Once you “opt out”, you will no longer receive any marketing emails from us. We will continue to communicate with you regarding your service billing and support via email.</p>
                    <p>We send push notifications from time to time in order to update you about any service updates, events and promotions we may be running. If you no longer wish to receive these communications, please disable these in the settings on your device.</p>
                   
                </li>
                <li>
                    <h4>COMPLAINTS</h4>
                    <p>If you have any complaints about our use of your personal data please contact us as set out at the end of this Privacy Policy or contact your local data protection supervisory authority in the country in which you are located.</p>
                    <p>Our EU representative is:</p>
                    <div className="address-para">[name]</div>
                    <div className="address-para">4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505, USA</div>
                    <div><a href="mailto:privacy@PacPost.Live.us">privacy@PacPost.Live.us</a></div>                    
                </li>
                <li>
                    <h4>CHANGES TO OUR PRIVACY POLICY</h4>
                    <p>Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Privacy Policy.</p>
                    <p>This Privacy Policy was last updated on November 15, 2021, and replaces any other Privacy Policy previously applicable from this date.</p>
                </li>
                <li>
                    <h4>CONTACT</h4>
                    <p>Questions, comments and requests regarding this Privacy Policy are welcomed and should be addressed to:</p>
                    <p>By post: Pacific Post Rentals, Inc. of 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505, USA; By email: support@PacPost.Live.us<br/>
                    Rev.#1 August 1, 2022</p>
                </li>
                
            </ol>
        </div>
    </div>
  );
};

export default PrivacyPolicy;
