import { create } from 'zustand';

interface StoreState {
  showSpeakerViewer: boolean;
  setShowSpeakerViewer: (showSpeakerViewer: boolean) => void;
}

export const useShowspeakerStore = create<StoreState>((set) => ({
  showSpeakerViewer: false,
  setShowSpeakerViewer: (showSpeakerViewer) => set({ showSpeakerViewer }),
}));
