import './styles.css';
import RegisterMasv from './RegisterMasv';
import CreateKeys from './CreateKeys';

interface MasvRightSectionProps {
  openKeys: string[];
  show_id: string;
  apiList: never[];
  setRefreshKey: (value: boolean) => void;
  refreshKey: boolean;
}

const MasvRightSection = (props: MasvRightSectionProps) => {
  const { openKeys, show_id, apiList, refreshKey, setRefreshKey } = props;

  const getMasvRightContent = () => {
    switch (openKeys.toString()) {
      case 'register':
        return <RegisterMasv />;
      case 'createKeys':
        return (
          <CreateKeys
            show_id={show_id}
            apiList={apiList}
            refreshKey={refreshKey}
            setRefreshKey={setRefreshKey}
          />
        );
      default:
        return openKeys.toString();
    }
  };

  return <div className='masv-body-content-right'>{getMasvRightContent()}</div>;
};

export default MasvRightSection;
