import React from "react";
import Select from "react-select";
import "./ReactSelect.css";

const ReactSelect = (props: any) => {
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: "35px",
            minHeight: "35px"
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
            padding: '0 6px',
            fontSize: "12px",
            lineHeight: "30px"
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            borderBottom: "1px dotted pink",
            color: state.selectProps.menuColor,
            zIndex: 100000
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            height: '35px'
        }),
        option: (provided: any, { isDisabled } : any) => ({
            ...provided,
            container: "black",
            wordBreak: "break-all",
            input: "black",
            opacity: isDisabled ? "0.4" : "1",
            cursor: isDisabled ? 'not-allowed' : 'default',
            option: "black",
            color: "#565656",
            zIndex: 100000,
            fontSize: "12px",
            height: "35px",
            lineHeight: "30px"
        })
    };
    const customTheme = (theme: any) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25:   "#dddddd",
            primary:   "#dddddd"
        }
    });
    const { name, value, className = "", isMulti = false, options = [], onChange } = props;
    const getSelectValue = () => {
        const selectedList = value;
        if(selectedList.length === 0 || options.length  === 0){
            return [];
        } 
        const selectedSet = new Set(selectedList);
        return options.filter((item: any) => selectedSet.has(item.value));
    };

    return (
        <Select
            value={getSelectValue()}
            name={name}
            className={className}
            onChange={onChange}
            isMulti={isMulti}
            options={options}
            styles={customStyles}
            theme={customTheme}
        />
    )
}
export default ReactSelect;