import { Col, message } from 'antd';
import useDbTrigger from '../../hooks/useDbTrigger';
import refreshIcon from '../../Images/reload.svg';
import { get } from '../../Utils/helpers';
import { useWhiteboardingStore } from '../../store';
let streamingStatus = false;

interface ReloadProps {
  showDomSpeaker: any;
  reloadPage: any;
  activeStreamStatus: boolean;
  setActiveStreamStatus: (value: boolean) => void;
  isMultiStreamEnabled: boolean;
  reloadSubStreams: any;
  resetDrawingButtonState: () => void;
  miniscreen: boolean;
  zmClient: any;
}
export default function Reload(props: ReloadProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const stream_id = sessionStorage.getItem('stream_id') || '';
  const {
    showDomSpeaker,
    reloadPage,
    activeStreamStatus,
    setActiveStreamStatus,
    isMultiStreamEnabled,
    reloadSubStreams,
    resetDrawingButtonState,
    zmClient,
  } = props;
  const vcRoomNotifications = useDbTrigger(
    'show_vc_room',
    !!zmClient && zmClient?.getSessionInfo()?.isInMeeting,
  );
  const vcRoomTriggerData = vcRoomNotifications?.doc?.data() || {};

  const { resetAllTools } = useWhiteboardingStore();

  if (vcRoomNotifications.type === 'modified' && vcRoomTriggerData) {
    if (
      Object.keys(vcRoomTriggerData).includes('streaming') &&
      !get(vcRoomTriggerData, 'streaming', false)
    ) {
      setActiveStreamStatus(false);
    }
    if (streamingStatus !== get(vcRoomTriggerData, 'streaming', false)) {
      streamingStatus = get(vcRoomTriggerData, 'streaming', false);

      if (
        streamingStatus &&
        !activeStreamStatus &&
        get(vcRoomTriggerData, 'showStream_id.id', '') === stream_id
      ) {
        messageApi.open({
          type: 'success',
          content: `The streaming has started.`,
        });
        console.log('reload');
        reloadPage();
      }
    }
  }
  return (
    <Col>
      <div
        onClick={() => {
          resetDrawingButtonState();
          resetAllTools();
          reloadPage();
          if (isMultiStreamEnabled) {
            reloadSubStreams();
          }
        }}
        className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
      >
        {contextHolder}
        <img src={refreshIcon} alt='' />
        {!props.miniscreen && <span>Reload Page</span>}
      </div>
    </Col>
  );
}
