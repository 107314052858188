import React, { useState, useContext } from "react";
import { Button, Confirm } from "semantic-ui-react";
import { Tooltip } from 'antd';
import { STATUS } from "../../Utils/constants";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import DeleteIcon from "../../Images/delete_red.svg";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";
import { searchClient } from "../../Service/show";

interface IProps {
  show_id: string;
  stream: any;
  handleDelete: (deleteStream: any) => void;
  usersGroupsFlag?: boolean;
}

const DeleteStream = (props: IProps) => {
  const currentUser = get(useContext(AuthContext), "currentUser", {} );
  const [loading, setLoading] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteStream, setDeleteStream] = useState({} as any);

  const handleDeleteCancel = () => {
    setDeleteAlertOpen(false);
  };

  const handleDeleteConfirm = async () => {
    setDeleteAlertOpen(false);
    try {
      const streamId = deleteStream.id ? deleteStream.id : deleteStream.objectID
      const data = {
        api: api.streams.update,
        urlParam: streamId,
        payLoad: {
          update_data: { stream_status: STATUS.DELETED },
        },
      };
      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
      await searchClient.clearCache();
      setLoading(false);
      if (result && result.update) {
        props.handleDelete(deleteStream);
      }
    } catch (err: any) {
      props.handleDelete(null);
    } finally {
      setLoading(false);
    }
  };

  const showDeleteConfirmAlert = (stream: any) => {
    stream.stream_status = STATUS.DELETED;
    setDeleteAlertOpen(true);
    setDeleteStream(stream);
  };

  return (
    <>
      {
        props.usersGroupsFlag ?
          <Tooltip title="Delete Stream">
            <button className="action-icon" onClick={() => {
              showDeleteConfirmAlert(props.stream);
            }}>
              <img src={DeleteIcon} alt="copy stream name" />
            </button>
          </Tooltip>
          :
          <Button
            color="violet"
            className="violet-button-list full-button-width"
            onClick={() => {
              showDeleteConfirmAlert(props.stream);
            }}
          >
            Delete Stream
          </Button>
      }
      <ProgressLoader loading={loading} />

      {/* <Button
        color="violet"
        className="violet-button-list full-button-width"
        onClick={() => {
          showDeleteConfirmAlert(props.stream);
        }}
      >
        Delete Stream
      </Button> */}


      <ConfirmationalPopup
        open={deleteAlertOpen}
        data={"Are you sure you want to delete the stream ?"}
        onCancel={handleDeleteCancel}
        onOk={handleDeleteConfirm}
      />
    </>
  );
};
export default DeleteStream;
