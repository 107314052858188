import { notification } from 'antd';
import { useEffect, useState } from 'react';

const lockedGuests: any = [];

export default function useLockedGuestList(
  notificationList: any,
  troom: {},
  updated: boolean,
  setUpdated: (value: boolean) => void,
  isGuestInviteIncluded:boolean,
) {
  const [lockedGuestList, setLockedGuestList] = useState([]);

  useEffect(() => {
    async function checkLockedGuests() {
      const guestName = notificationList?.doc?.data()?.notification_metadata?.guestName;

      const isPublisher = sessionStorage.getItem('publisher') === 'true' || false;
      const streamIdRef = sessionStorage.getItem('stream_id');

      const roomIsEmpty: boolean = Object.keys(troom).length === 0;

      if (!isPublisher || !isGuestInviteIncluded) {
        return;
      }

      if (streamIdRef === notificationList?.doc?.data()?.notification_metadata?.streamID) {
        if (
          !!guestName &&
          !lockedGuests.includes(guestName) &&
          notificationList?.type !== 'removed' &&
          isPublisher &&
          !roomIsEmpty
        ) {
          notification.open({
            message: notificationList?.doc?.data()?.notification_message,
            description: `${guestName} is locked.`,
            placement: 'bottomRight',
          });

          lockedGuests.push(guestName);
        } else if (notificationList?.type === 'removed') {
          const indexName = lockedGuests.indexOf(guestName);

          lockedGuests.splice(indexName, 1);
        }
        setLockedGuestList(lockedGuests);
        setUpdated(!updated);
      }
    }
    checkLockedGuests();
  }, [notificationList]);

  return lockedGuestList;
}
