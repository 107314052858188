import { Modal } from 'antd';
interface ConfirmationalPopupProps {
  onOk: any;
  data: any;
  onCancel: any;
  open: boolean;
  className?: string;
}
const ConfirmationalPopup = (props: ConfirmationalPopupProps) => {
  return (
    <Modal
      centered={true}
      bodyStyle={{ height: 'auto' ,  padding: '16px', wordBreak: 'break-word' }}
      open={props.open}
      onOk={props.onOk}
      onCancel={props.onCancel}
      className={props.className}
    //   footer={null}
    >
      {props.data}
    </Modal>
  );
};
export default ConfirmationalPopup;
