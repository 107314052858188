import axios from 'axios';
import api from './Api';
import { auth } from '../Firebase';
import { get as getObj } from '../Utils/helpers';
import { setGuestTokenHeader, isGuestAuthenticated } from '../Utils/guestHelpers';

let { baseUrl } = api;
const baseEndPoint = process.env.REACT_APP_BASE_END_POINT || '';
// const encoderURL = "https://us-central1-pacpostdev-vc.cloudfunctions.net";
console.log('base url :\n', baseUrl);

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
// const axiosEncoderInstance = axios.create({
//   baseURL: encoderURL,
//   headers: {
//     'Content-Type': 'application/json',
//     Accept: 'application/json',
//   },
// });
const errorHandler = (error: any, msgKey: string) => {
  if (error.response) {
    let e = {
      errMessage: `Error while ${msgKey} data`,
      data: null,
      errCode: null,
    };

    const errorResponse = error.response;
    if (errorResponse.data) {
      if (errorResponse.data.status === 'error') {
        e.errMessage = errorResponse.data.message;
        e.data = errorResponse.data.data;
        e.errCode = errorResponse.data.code;
      }
    }

    throw e;
  } else {
    console.error(error);
    throw error;
  }
};

const get = async (data: any) => {
  try {
    if (!data) {
      throw new Error(`No Data`);
    }
    if (data && !data.endPoint) {
      throw new Error(`No API Endpoint`);
    }

    if (data.isGuest) {
      setGuestTokenHeader(axiosInstance);
      axiosInstance.defaults.headers.common['Authorization'] = null;
    } else if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }
    axiosInstance.defaults.cancelToken = data?.cancelToken;

    // End Point
    let endPoint = data.endPoint;
    if (data.urlParam) {
      endPoint = endPoint + '/' + data.urlParam;
    } 
     
    // const response = endPoint.includes("/encoder") ? await axiosEncoderInstance.get(`/app${endPoint}`, { params: data.queryParam }) : await axiosInstance.get(endPoint, { params: data.queryParam });
    const response = await axiosInstance.get(endPoint, { params: data.queryParam });
    if (getObj(response, 'data.status', 'failed') === 'success') {
      return getObj(response, 'data.data', '');
    } else {
      // Ideally we should not come here if response is structured properly
      return response;
    }
  } catch (error: any) {
    errorHandler(error, 'getting');
  }
};

const post = async (data: any) => {
  try {
    if (!data) {
      throw new Error(`No Data`);
    }
    if (data && !data.endPoint) {
      throw new Error(`No API Endpoint`);
    }

    if (data.isGuest) {
      setGuestTokenHeader(axiosInstance);
      axiosInstance.defaults.headers.common['Authorization'] = null;
    } else if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }
    axiosInstance.defaults.cancelToken = data?.cancelToken;

    // End Point
    let endPoint = data.endPoint;
    if (data.urlParam) {
      endPoint = endPoint + '/' + data.urlParam;
    }

    // Payload
    let payLoad;
    if (data.payLoad) {
      payLoad = data.payLoad;
    }
    //const response = endPoint.includes("/encoder") ? await axiosEncoderInstance.post(`/app${endPoint}`, payLoad) : await  await axiosInstance.post(endPoint, payLoad);
    const response = await axiosInstance.post(endPoint, payLoad);
    // console.log(response)

    if (response && response.data && response.data.status === 'success') {
      const { data } = response.data;
      return data;
    } else {
      return response;
    }
  } catch (error: any) {
    errorHandler(error, 'posting');
  }
};

const patch = async (data: any) => {
  try {
    if (!data) {
      throw new Error(`No Data`);
    }
    if (!data.endPoint) {
      throw new Error(`No API Endpoint`);
    }
    if (!data.payLoad) {
      throw new Error(`No payload for update`);
    }

    if (data.isGuest) {
      setGuestTokenHeader(axiosInstance);
      axiosInstance.defaults.headers.common['Authorization'] = null;
    } else if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }

    // End Point
    let endPoint = data.endPoint;
    if (data.urlParam) {
      endPoint = endPoint + '/' + data.urlParam;
    }

    // Payload
    let payLoad = data.payLoad;

    //console.log(endPoint)

    const response = await axiosInstance.patch(endPoint, payLoad);

    if (response && response.data && response.data.status === 'success') {
      const { data } = response.data;
      return data;
    } else {
      // Ideally we should not come here if response is structured properly
      return response;
    }
  } catch (error: any) {
    errorHandler(error, 'updating');
  }
};

const uDelete = async (data: any) => {
  try {
    if (!data) {
      throw new Error(`No Data`);
    }
    if (data && !data.endPoint) {
      throw new Error(`No API Endpoint`);
    }

    if (data.isGuest) {
      setGuestTokenHeader(axiosInstance);
      axiosInstance.defaults.headers.common['Authorization'] = null;
    } else if (data.authToken) {
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.authToken;
    }
      axiosInstance.defaults.cancelToken = data?.cancelToken;

    // End Point
    if (data.urlParam) {
      let endPoint = data.endPoint;
      endPoint = endPoint + '/' + data.urlParam;

      const response = await axiosInstance.delete(endPoint, { params: data.queryParam });
      if (response && response.data && response.data.status === 'success') {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    } else if (data.payLoad) {
      const endPoint = data.endPoint;
      // Payload
      let payLoad;
      // console.log('confirm payload', data.payLoad)
      if (data.payLoad) {
        payLoad = data.payLoad;
      }
      const response = await axiosInstance.delete(endPoint, {
        data: data.payLoad,
      });
      if (response && response.data && response.data.status === 'success') {
        const { data } = response.data;
        return data;
      } else {
        return response;
      }
    }
  } catch (error: any) {
    errorHandler(error, 'deleting');
  }
};

const save = async (data: any, token: string) => {
  try {
    // Pre Validation
    if (!data) {
      throw new Error(`No Data`);
    }
    if (data && !data.api) {
      throw new Error(`No API Details`);
    }
    if (data && data.api && !data.api.method) {
      throw new Error(`No API Method`);
    }

    const endPoint = `${baseEndPoint}${data.api.endPoint}`;

    const isGuest = isGuestAuthenticated();

    const d = {
      endPoint: endPoint,
      urlParam: data.urlParam ? data.urlParam : null,
      payLoad: data.payLoad ? data.payLoad : null,
      authToken: token ? token : null,
      cancelToken:data.cancelToken ? data.cancelToken : null,
      isGuest,
    };

    if (data.api.method === 'POST') {
      console.log(data, 'on POST');

      return await post(d);
    }
    if (data.api.method === 'PATCH') {
      console.log(data, 'on PATCH');

      return await patch(d);
    }
    if (data.api.method === 'PUT') {
      console.log(data, 'on PUT');

      //return await put(d)
    }
    if (data.api.method === 'DELETE') {
      console.log(data, 'on delete');
      return await uDelete(d);
    }
  } catch (err) {
    console.log(err, 'on delete error');

    throw err;
  }
};

const fetch = async (data: any, token: string) => {
  try {
    // Pre Validation
    if (!data) {
      throw new Error(`No Data`);
    }
    if (data && !data.api) {
      throw new Error(`No API Details`);
    }
    if (data && data.api && !data.api.method) {
      throw new Error(`No API Method`);
    }

    const endPoint = `${baseEndPoint}${data.api.endPoint}`;
    const isGuest = isGuestAuthenticated();
    
    const d = {
      endPoint: endPoint,
      urlParam: data.urlParam ? data.urlParam : null,
      queryParam: data.queryParam ? data.queryParam : null,
      authToken: token ? token : null,
      isGuest,
    };

    if (data.api.method === 'GET') {
      return await get(d);
    }
  } catch (err: any) {
    throw err;
  }
};

const remove = async (data: any, token: string) => {
  try {
    // Pre Validation
    if (!data) {
      throw new Error(`No Data`);
    }
    if (data && !data.api) {
      throw new Error(`No API Details`);
    }
    if (data && data.api && !data.api.method) {
      throw new Error(`No API Method`);
    }

    const endPoint = `${baseEndPoint}${data.api.endPoint}`;

    const d = {
      endPoint: endPoint,
      urlParam: data.urlParam ? data.urlParam : null,
      queryParam: data.queryParam ? data.queryParam : null,
      authToken: token ? token : null,
      payLoad: data.payLoad ? data.payLoad : null,
    };
    // console.log('check d', d)
    if (data.api.method === 'DELETE') {
      return await uDelete(d);
    }
  } catch (err: any) {
    throw err;
  }
};

const backend = { get, save, fetch, remove };

export default backend;
