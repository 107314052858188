import { useContext, useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import ZoomContext from '../context/zoom-context';
import ZoomMediaContext from '../context/media-context';
import { useVideo } from "../hooks/useVideo";
import configSource from "../../../Images/config-source.svg";
import { get } from "../../../Utils/helpers";
import { Participant } from "@zoom/videosdk";
import { Grid, notification } from "antd";
import { useWhiteboardingStore } from "../../../store";

const Devices = (props: any) => {
    const { showDominantSpeaker, resetDrawingButtonState } = props;
    const zmClient = useContext(ZoomContext);
    const { mediaStream } = useContext(ZoomMediaContext);

    const { isVideoOn } = useVideo(zmClient, mediaStream);
    const { resetAllTools } = useWhiteboardingStore();
    const [cameraDropdownOpen, setCameraDropdownOpen] = useState(false);
    const [selectedCam, setSelectedCam] = useState(get(get(mediaStream?.getCameraList(),'0',''),'label',''))
    const currentUser = zmClient.getCurrentUserInfo();

    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    
    const toggleAudioVideo = async () => {
        setCameraDropdownOpen(!cameraDropdownOpen);
        resetDrawingButtonState();
        resetAllTools();
    };

    const getCameraList = () => {
        let cameraList: any = []
        if (mediaStream) {
            const camerasList = mediaStream.getCameraList()
            camerasList.filter((device: any) => device.deviceId !== "default").forEach((camera: any) => {
                cameraList.push({
                    value: camera.deviceId,
                    text: camera.label,
                    key: camera.label,
                })
            })
        }

        cameraList.push({
            value: "DESKTOP",
            text: "Share Your Desktop",
            key: "desktop",
        });

        return cameraList;
    }

    const getMicList = () => {
        let micList: any = []
        if (mediaStream) {
            const microphonesList = mediaStream.getMicList()
            microphonesList.filter((device: any) => device.deviceId !== "default").forEach((mic: any) => {
                micList.push({
                    value: mic.deviceId,
                    text: mic.label,
                    key: mic.label,
                })
            })
        }
        return micList;
    }

    const getActiveMicrophone = () => {
        if (!mediaStream) {
            return "";
        }

        const microphonesList = mediaStream.getMicList();
        const activeMic = mediaStream.getActiveMicrophone();

        // No microphones available
        if (microphonesList.length === 0) {
            return "";
        }

        // Handle only one microphone available
        if (microphonesList.length === 1) {
            return microphonesList[0].label;
        }

        // Find the active microphone, handle 'default' device
        if (activeMic === 'default') {
            // Assumes first microphone is the default if 'default' is specified
            return microphonesList[0].label.replace("Default - ", "");
        }

        // Find microphone with matching deviceId
        const activeMicDevice = microphonesList.find((mic: any) => mic.deviceId === activeMic);

        return activeMicDevice ? activeMicDevice.label : "";
    }



    const getActiveCamera = () => {
        if (!mediaStream) {
            return "";
        }

        const camerasList = mediaStream.getCameraList();
        const activeCamera = mediaStream.getActiveCamera();

        // checking if screen share is on
        if(mediaStream.getShareStatus() === "sharing"){
            return "Share Your Desktop";
        }

        // No cameras available
        if (camerasList.length === 0) {
            return "";
        }

        // Handle only one camera available
        if (camerasList.length === 1) {
            return camerasList[0].label;
        }

        // Find the active camera, handle 'default' device
        if (activeCamera === 'default') {
            // Assumes first camera is the default if 'default' is specified
            return camerasList[0].label.replace("Default - ", "");
        }

        // Find camera with matching deviceId
        const activeCameraDevice = camerasList.find((camera: any) => camera.deviceID === activeCamera);
        return activeCameraDevice ? activeCameraDevice.label : "";
    }


    const changeMicrophone = async (e: any, v: any) => {
        console.log('v.name', v.value)
        if (mediaStream) {
            await mediaStream.switchMicrophone(v.value)
        }
        setCameraDropdownOpen(false);
    };


    const changeCamera = async (e: any, v: any) => {
        if (v.value === 'DESKTOP') {
            onScreenShareClick()
        } else if (mediaStream) {
            if(mediaStream.getShareStatus() === "sharing"){
                mediaStream.stopShareScreen()
            } else {
                await mediaStream.switchCamera(v.value)
            }
        }
        setCameraDropdownOpen(false);
    };

    const onScreenShareClick = async () => {
        if(zmClient.getAllUser().some((participant:Participant)=> participant.sharerOn === true)){
            notification.warning({
                message:'Only one person can share screen at a time',
                placement:'topRight',
                duration:2
            })
            return
        }
        const stream: any = zmClient.getMediaStream();
        if (stream?.isStartShareScreenWithVideoElement()) {
          await stream.startShareScreen(
            document.querySelector(`#screen-share-content-video`)
          )
          // screen share successfully started and rendered
        } else {
          await stream.startShareScreen(
            document.querySelector(`#screen-share-content-canvas${currentUser.userIdentity}`)
          )
        }
      };

    useEffect(() => {
        if (mediaStream?.getShareStatus() !== "sharing") {
            setSelectedCam(get(get(mediaStream?.getCameraList(),'0',''),'label',''))
        }
    }, [mediaStream])

    return (
        <div
            onClick={toggleAudioVideo}
            className={get(screens, 'xs', false) ? 'miniheader-btn' :`top-menu-button-item audiovisual-source-dropdown ${showDominantSpeaker ? "disableddiv" : ""
                }`}
        >
            <img src={configSource} alt="" />
            <Dropdown icon="" open={cameraDropdownOpen} onClose={toggleAudioVideo}>
                <Dropdown.Menu className="cameraMenu">
                    <Dropdown.Item>
                        <Dropdown
                            className="cameraDropdown"
                            id="cameraDropdown"
                            pointing="left"
                            multiple={false}
                            fluid
                            selection
                            placeholder="camera"
                            options={getCameraList()}
                            text={getActiveCamera()}
                            onChange={changeCamera}
                            disabled={!isVideoOn}
                            value={selectedCam}
                        />
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Dropdown
                            id="cameraDropdown"
                            placeholder="microphone"
                            multiple={false}
                            fluid
                            selection
                            pointing="left"
                            text={getActiveMicrophone()}
                            onChange={changeMicrophone}
                            options={getMicList()}
                        />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {!get(screens, 'xs', false) &&<span>Select Camera</span>}
        </div>
    )
};

export default Devices;
