import React, { useState, useEffect } from "react";
import Home from "./Home";
import "../../Assets/css/global.css";
import "../../Assets/css/style.css";
import "../../Assets/css/responsive.css";

const HomeIndex = () => {
   const [page, setPage] = useState("home");

   const getPage = () => {
      switch(page) {
         case "home":
            return <Home pageHandler={setPage} />;
      }
   }

    return (
        <div className="home"> 
            {getPage()}
        </div>
    );
};

export default HomeIndex;