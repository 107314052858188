import { useEffect, useState } from 'react';
import { CloudUploadOutlined, MailOutlined, HistoryOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { showActiveUsers } from '../../Service/show';
import { PAGES, ROLES } from '../../Utils/constants';
import { Layout } from '../PageLayout/Layout';
import Inbox from './Inbox';
import Transfer from './Transfer';
import SendFiles from './SendFiles';
import { get } from '../../Utils/helpers';
import './Mailbox.css';
import { useLocationStore } from '../../store/useLocationStore';
type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    label: 'Inbox',
    key: 'inbox',
    icon: <MailOutlined />,
  },
  {
    label: 'Send Files',
    key: 'send',
    icon: <CloudUploadOutlined />,
  },
  {
    label: 'Transfer History',
    key: 'transfer',
    icon: <HistoryOutlined />,
  },
];

function Mailbox() {
  const locationState = useLocationStore((state) => state.locationState);
  const show_id = get(locationState,'state.show_id','');

  const [openKeys, setOpenKeys] = useState(['inbox']);
  const [usersList, setUserList] = useState([]);
  const [showName, setShowName] = useState('');

  useEffect(() => {
    fetchUsers();
  }, [show_id]);

  const fetchUsers = async () => {
    const result = await showActiveUsers(show_id);
    const userArr = get(result, 'hits', []);
    let showName = '';
    for (const user of userArr) {
      if (user.show_name && user.show_name.trim() !== '') {
        showName = user.show_name;
        break;
      }
    }
    setShowName(showName);
    const userData = userArr.filter(
      (userArr: any, index: number, self: any) =>
        index === self.findIndex((user: any) => user.user_email === userArr.user_email && user.role_type === ROLES.MAILBOX),
    );
    setUserList(userData);
  };

  const onClick: MenuProps['onClick'] = (event) => {
    const { key } = event;
    console.log('click ', key);
    setOpenKeys([key]);
  };

  const getMailboxContent = () => {
    switch (openKeys.toString()) {
      case 'inbox':
        return <Inbox show_id={show_id} usersList={usersList}/>;
      case 'send':
        return <SendFiles show_id={show_id} usersList={usersList} showName={showName} />;
      case 'transfer':
        return <Transfer show_id={show_id} usersList={usersList}/>;
      default:
        return openKeys.toString();
    }
  };

  return (
    <Layout page={PAGES.MAILBOX}>
      <div className='mailbox-wrapper'>
        <Menu
          mode='inline'
          onClick={onClick}
          defaultSelectedKeys={openKeys}
          style={{ width: '15%', height: '100%' }}
          items={items}
        />
        <div style={{ width: '85%' }}>{getMailboxContent()}</div>
      </div>
    </Layout>
  );
}

export default Mailbox;
