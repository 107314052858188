import React, {  useEffect, useState } from "react";
import { Button, Modal } from 'antd';
import { get } from "../../../Utils/helpers";

interface IProps {
    handleCloseHudStatsDisplayPanel:any;
    hudStatsDisplay:any;
    statsModalOpen:any;
    setStatsModalOpen:any;
    millicastView:any;
    activeStreamExists:boolean;
  }

const statsMetaData = { 
        audio: {
            'Audio TimeStamp' : [(x:string) => (new Date(x)).toLocaleString(),'timestamp'],
            'Audio Bitrate' : 'bitrate',
            'Audio Packet Loss' : 'totalPacketsLost',
            'Audio Total Received' : 'totalPacketsReceived',
            'Audio Jitter' : 'jitter',
            'Audio Id' : 'id',
            'Audio Mid' : 'mid',
            'Audio MimeType' : 'mimeType',
            'Audio PLDPS' : 'packetsLostDeltaPerSecond',
            'Audio PLRPS' : 'packetsLostRatioPerSecond',
            'Audio TBR' : 'totalBytesReceived',
            'Audio TotalPacketsLost' : 'totalPacketsLost'
        },
        video: {
            'FPS' : 'framesPerSecond',
            'Video Bitrate' : 'bitrate',
            'Video Total Received' : 'totalPacketsReceived',
            'Video Packet Loss' : 'totalPacketsLost',
            'Video Jitter' : 'jitter',
            'Video Resolution' : [(a:string,b:string) => (a + ' x '+ b), 'frameHeight','frameWidth'],
            'Video Id' : 'id',
            'Video MimeType' : 'mimeType',
            'Video PLDPS' : 'packetsLostDeltaPerSecond',
            'Video PLRPS' : 'packetsLostRatioPerSecond',
            'Video TimeStamp' :  [(x:string) => (new Date(x)).toLocaleString(),'timestamp'],
            'Video TBR' : 'totalBytesReceived',
            'Video TPR' : 'totalPacketsReceived'
        }

}
const evalObject = (resolvingObject:any, param:any) => {
    const [fn,...a] = param
    const aValues = a.map((ab:string)=>resolvingObject[ab]);
    return fn.apply(null,aValues)

}
const HudStats = (props: IProps) => {

    const [streamingData, setStreamingData] = useState('');
    const { handleCloseHudStatsDisplayPanel, statsModalOpen, setStatsModalOpen } =
    props;
    useEffect(() => {
      if (!!props.millicastView) {
       // add default params to initStats
        props.millicastView.webRTCPeer?.initStats({autoInitStats:true,statsIntervalMs:1000});
        //Capture new stats from event every second
        props.millicastView.webRTCPeer?.on('stats', (stats: any) => {
        // Set the stats to the Datazoom context
            if('datazoom' in window){
                (window as any).datazoom.setMetadata({ webRTCStats: stats });
            }
          setStreamingData(stats);
        });
      }

      return () => {
        props.millicastView?.webRTCPeer?.stopStats();
        setStreamingData('');
      };
    }, [props.millicastView, statsModalOpen]);

    const audio = get(streamingData, 'audio', '');
    const video = get(streamingData, 'video', '');
    
    const statsData = () => {
        const audioData = audio.inbounds[0], videoData = video.inbounds[0];
        const audioStats = () => (
            Object.keys(statsMetaData.audio)
            //@ts-ignore
            .map(statsKey => (<tr><td>{statsKey}</td><td>{typeof statsMetaData.audio[statsKey] === 'object' ? evalObject(audioData,statsMetaData.audio[statsKey]): audioData[statsMetaData.audio[statsKey]]}
            </td></tr>)))
        const VideoStats = () => (
                Object.keys(statsMetaData.video)
                //@ts-ignore
                .map(statsKey => (<tr><td>{statsKey}</td><td>{typeof statsMetaData.video[statsKey] === 'object' ? evalObject(videoData,statsMetaData.video[statsKey]): videoData[statsMetaData.video[statsKey]]}
                    </td></tr>)))
        return (<table className="stats-table">
            <thead>
                <tr>
                <td><b>Name</b></td>
                <td><b>Value</b></td>
                </tr>
            </thead>
            <tbody>
                <tr><td>Current RTT</td><td>{get(streamingData,'currentRoundTripTime','')}</td></tr>
                <tr><td>Available Outgoing Bitrate</td><td>{get(streamingData,'availableOutgoingBitrate','')}</td></tr>
                {(streamingData as any)?.candidateType && <tr><td>Candidate Type</td><td>{get(streamingData,'candidateType','')}</td></tr>}
                <tr><td>Total totalRoundTripTime</td><td>{get(streamingData,'totalRoundTripTime','')}</td></tr>

            {audioStats()}
            {VideoStats()}
            </tbody>
            </table>)
    }
    return (
        (props.activeStreamExists && streamingData) ? (<div className="hudStats-layout">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              id='hudStatsPanelArea'
              onClick={handleCloseHudStatsDisplayPanel}
              className='layout-close'>X</a>
            <div className="media-stats-scroll">
            <div className="stat">
                {statsData()}
            </div>
            </div>
        </div>
    ): (
                <Modal
                    open={statsModalOpen && !props.activeStreamExists}
                    centered={true}
                    onCancel={() => {
                        setStatsModalOpen(false);
                    } }
                    footer={[
                        <Button
                            type='primary'
                            onClick={() => {
                                setStatsModalOpen(false);
                            } }
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <div className='btn-close'>
                        Please wait for the stream to start.
                    </div>
                </Modal>
    ))

}
export default HudStats