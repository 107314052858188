// ICEServer URL : (To-do: Move to backend)
export const turnApiUrl = 'https://turn.millicast.com/webrtc/_turn'

// User Roles
export enum ROLES {
  SUPER_ADMIN = "SuperAdmin",
  SHOW_ADMIN = "ShowAdmin",
  SHOW_PUBLISHER = "ShowPublisher",
  SHOW_VIEWER = "ShowViewer",
  GUEST_VIEWER = "GuestViewer",
  SUPER_PUBLISHER = "SuperPublisher",
  SHOW_FINANCE = 'ShowFinance',
  MAILBOX = 'MailBox'
}

// Status
export enum STATUS {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  DELETED = "Deleted"
}

// Page Name
export enum PAGES {
  LOGIN_PAGE = "LOGIN_PAGE",
  FORGOT_PASSWORD_PAGE = "FORGOT_PASSWORD_PAGE",
  OTP_PAGE = "OTP_PAGE",
  INTERMEDIATE_PAGE = "INTERMEDIATE_PAGE",
  SUPER_ADMIN_PAGE = "SUPER_ADMIN_PAGE",
  SHOW_ADMIN_PAGE = "SHOW_ADMIN_PAGE",
  SHOW_ADMIN_PARENT_PAGE = "SHOW_ADMIN_PARENT_PAGE",
  SHOW_PUBLISHER_PAGE = "SHOW_PUBLISHER_PAGE",
  SHOW_VIEWER_PAGE = "SHOW_VIEWER_PAGE",
  NOT_FOUND_PAGE = "NOT_FOUND_PAGE",
  UNAUTHORISED_PAGE = "UNAUTHORISED_PAGE",
  USER_PROFILE_SETTINGS_PAGE = "USER_PROFILE_SETTINGS_PAGE",
  RESET_PASSWORD_PAGE = "RESET_PASSWORD_PAGE",
  TEAM_ADMIN_PAGE = "TEAM_ADMIN_PAGE",
  STRIPE = "STRIPE",
  SHOW_FINANCE_PAGE = "SHOW_FINANCE_PAGE",
  TRUSTED_DEVICE_LIST = "TRUSTED_DEVICE_LIST",
  MAILBOX = "MAILBOX_PAGE"
}
export enum SUPERADMIN_PAGES {
  CREATE_SHOW = "CREATE_SHOW",
  INVITE_SHOW_ADMIN = "INVITE_SHOW_ADMIN",
  SHOW_LIST = "SHOW_LIST",
  STREAM_LIST = "STREAM_LIST",
  USER_LIST = "USER_LIST"
}
export enum SHOWADMIN_PAGES {
  INVITE_USER = "INVITE_USER",
  EDIT_USER = "EDIT_USER",
  LIST_USERS = "LIST_USERS"
}
export enum SHOWPUBLISHER_PAGES {
  CREATE_STREAM = "CREATE_STREAM",
  LIST_STREAM = "LIST_STREAM",
  INVITE_UNINVITE_STREAM = "INVITE_UNINVITE_STREAM"
}

export enum SHOWFINANCE_PAGES {
  INVITE_SHOW_ADMIN = "INVITE_SHOW_ADMIN",
  SHOW_LIST = "SHOW_LIST",
  STREAM_LIST = "STREAM_LIST",
  USER_LIST = "USER_LIST"
}

// Feature Flags
export enum FEATURE_NAME {
  TIME_CODE = "TIME_CODE",
  CHILD_SHOWS = "CHILD_SHOWS",
  ALLOW_GUEST_INVITE="ALLOW_GUEST_INVITE",
  ALLOW_STRIPE_LICENCE="ALLOW_STRIPE_LICENCE",
  VC_DIRECT = "VC_DIRECT",
  ALLOW_LICENSE_MANGER = "ALLOW_LICENSE_MANGER",
  ALLOW_WATERMARK = "ALLOW_WATERMARK",
  ALLOW_DELETE_ACTIVE_STREAM = "ALLOW_DELETE_ACTIVE_STREAM",
  ALLOW_STREAM_MULTIVIEWER = "ALLOW_STREAM_MULTIVIEWER",
  ALLOW_ENCODER = "ALLOW_ENCODER"
}

// Event types	
export enum EVENT_TYPES {	
  LOGIN_SUCCESS = "Login Success",	
  STREAM_LOG = "Stream Log",	
  GUEST_LOCKED = "Guest Locked",	
  SHOW_DELETION = "Show Deletion",	
  USER_DELETION = "User Deletion",
  SHOW_USER_DELETION = "Show User Deletion",
  INVITE_SHOW_USER = "Invite Show User",
  FILE_UPLOAD_SUCCESS = "File Upload Success",
  FILE_UPLOAD_FAILURE = "File Upload Failure",
  FILE_DOWNLOAD_FAILURE = "File Download Failure",
  ACTIVATE_USER = "Activate User",
  SEND_PASSCODE = "Send Passcode",
}

export enum CHIME_VALUES {
  CHIME_ON = "CHIME_ON",
  CHIME_OFF = "CHIME_OFF"  
}

export enum MESSAGES {
  CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
}

export const GUEST_TOKEN_STORAGE_KEY = 'gt';
export const GUEST_TOKEN_HEADER_KEY = 'X-Guest-Token';

export const MULTI_STREAM_VIEWER = 'multi-stream-viewer';
