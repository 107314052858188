import { useEffect } from 'react';
import useEventCallback from './useEventCallback';
import useSessionStorage from './useSessionStorage';
import { useConfig } from '../Context/configContext';

export default function useMaxRetries(key: string, onMaxReached?: (key: string) => void) {
  const { maxPasswordRetries = 0 } = useConfig();
  const [retries, setRetries, clearRetries]: any = useSessionStorage<{  
     [key: string]: number;
  }>('retries', {});
  const [triggered, setTriggered, clearTriggered]: any = useSessionStorage<{
    [key: string]: boolean,
  }>('maxReachedTriggered', {});
  const maxRetriesReached = retries[key] >= maxPasswordRetries;


  const updateRetries = useEventCallback(() => {
    if (!key) {
      return;
    }

    retries[key] = retries[key] || 0;
    if ((retries[key]) < maxPasswordRetries) {
      setRetries((r:any) => ({
        ...r,
        [key]: (r[key]) + 1,
      }));
    }
  });

  const _onMaxReached = useEventCallback(onMaxReached);

  useEffect(() => {
    clearRetries()
    clearTriggered()
  }, [clearRetries, clearTriggered]);

  useEffect(() => {
    if (!key) {
      return;
    }

    retries[key] = retries[key] || 0;

    if (maxRetriesReached && !triggered[key]) {
      setTriggered((t:any) => ({
        ...t,
        [key]: true,
      }));
      _onMaxReached(key);
    }

  }, [_onMaxReached, key, maxPasswordRetries, maxRetriesReached, retries, setTriggered, triggered]);

  return { maxRetriesReached, updateRetries };
}