import React from "react";

const Pagination = (props: any) => {
  const { gotoPage, canPreviousPage, previousPage, pageIndex, pageOptions, nextPage, canNextPage, pageCount, totalRows } = props;

  return (
    <div className="algolia-footer">
      {/* <div>
        <b>{totalRows.length}</b> Records{" "}
      </div> */}
      <div>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
      </div>
      <div className="pagination">
          <button className="ais-Pagination-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          ‹‹
          </button>
          <button className="ais-Pagination-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
          ‹
          </button>
          {' '}
          <button className="ais-Pagination-link" onClick={() => nextPage()} disabled={!canNextPage}>
            ›
          </button>{' '}
          <button className="ais-Pagination-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            ››
          </button>{' '} 
      </div>
    </div>
  );
};

export default Pagination;
