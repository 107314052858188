import React, { useState } from "react";
import { Button } from "semantic-ui-react";

interface IProps {
  stepperActionHandler: any;
  step: number;
  disableActionButton: boolean;
  handleSubscript: any;
  customerID: string;
  setStep: any
}

const StepperAction = (props: IProps) => {
  const {
    step,
    stepperActionHandler,
    disableActionButton,
    handleSubscript,
    customerID,
    setStep,
  } = props;
  return (
    <div className="stepper-action">
      {step === 1 && (
        <div>
          <Button
            color="violet"
            disabled={disableActionButton}
            onClick={() => stepperActionHandler(step + 1)}
          >
            Payment Option
          </Button>
          {!!customerID && (
            <Button color="violet" onClick={() => setStep(step + 1)}>
              Skip
            </Button>
          )}
        </div>
      )}
      {step === 2 && (
        <div>
          <Button
            color="violet"
            disabled={disableActionButton}
            onClick={handleSubscript}
          >
            AGREE
          </Button>
        </div>
      )}
    </div>
  );
};
export default StepperAction;
