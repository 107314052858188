import React, { useState, useEffect, useContext } from "react";
import { Input, Table, Button as AntdButton } from "antd";
import { Modal, Button } from "semantic-ui-react";
import { useNavigationType } from "react-router";
import classNames from "classnames";
import {
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import { ROLES  , EVENT_TYPES } from "../../Utils/constants";
import UserMessage from "../../Components/Common/UserMessage";
import AlgoliaTable from "../../Components/Algolia/AlgoliaTable";
import { PAGES, SHOWADMIN_PAGES } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import { fetchActiveSuperAdmin, fetchActiveUserShows, showActiveUsers, searchClient } from "../../Service/show";
import { get } from "../../Utils/helpers";
import ConfirmationModal from "../../Components/UIKit/ConfirmationModal/ConfirmationModal";
import { AuthContext } from '../../Context/authContext';
import { logEvent } from "../../Service/LogEvent";
import { isTrustedDeviceFeatureEnabled } from "../../Utils/featureFlags";

// css
import "./style.css"

const { REACT_APP_ALGOLIA_USER_INDEX_NAME } = process.env;

interface IProps {
  show_id: string | undefined;
  showAdminEditUserClick(user: any): void;
  setUserUpdated: any;
  userUpdated: boolean;
  usersGroupsFlag?:boolean;
  setInvitedUsers?: any;
  invitedUsers?: any;
  isMasvFeature?: boolean;
}
const { Search } = Input;

const ListShowUsers = (props: IProps) => {
  const { show_id, invitedUsers, setInvitedUsers} = props;
  const currentUser = get(useContext(AuthContext), "currentUser", {} );
  const navigationType = useNavigationType();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [resetPasswordAlertOpen, setResetPasswordAlertOpen] = useState(false);
  const [suspendAlertOpen, setSuspendAlertOpen] = useState(false);
  const [activateAlertOpen, setActivateAlertOpen] = useState(false);
  const [duser, setDeleteUser] = useState({} as any);
  const [resetPassUser, setResetPassUser] = useState({} as any);
  const [suspendUser, setSuspendUser] = useState({} as any);
  const [activatedUser, setActivatedUser] = useState({} as any);
  const [userRoles, setUserRoles] = useState({} as any);
  const [isadmin, setIsAdmin] = useState([] as any);
  const [showUsers, setShowUsers] = useState([] as any);
  const [searchText, setSearchText] = useState("");
  const [show_name, setShowName] = useState("");

  const fetchShowUserData = async () => {
    try {
      let activeUsers = await showActiveUsers(show_id);
      console.log(activeUsers?.hits, 'see hereeee');
      if (activeUsers && activeUsers.hits) {
        const filteredData = activeUsers.hits.filter((user: any) => user.role_type !== "SuperAdmin");

        if (!props.isMasvFeature) {
          const finalFilteredData = filteredData.filter((user: any) => user.role_type !== "MailBox");
          setShowUsers(finalFilteredData);
        } else {
          setShowUsers(filteredData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchShowUserData()
  }, [])

  useEffect(() => {
    searchClient.clearCache();
    // Mixpanel
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SHOW_ADMIN_PAGE + "_" + SHOWADMIN_PAGES.LIST_USERS,
    });

    if (show_id) {
      // setShowId(props.show_id);
      isUserSuperAdmin()
    }
  }, [navigationType]);
  const isUserSuperAdmin = async () => {
    try {
      let response: any = await fetchActiveSuperAdmin();
      let listAdmin = response?.hits?.map((admins: any) => admins.user_id)
      setIsAdmin(listAdmin)
    } catch (error) {
    }
  }

  useEffect(() => {
    if (props.userUpdated) {
      // setShowUsers([]);
      props.setUserUpdated(false);
    }
  }, []);

  const handleDeleteCancel = () => {
    setDeleteAlertOpen(false);  
  };

  const handleDeleteConfirm = async () => {
    await deleteUser();
  };

  const showDeleteConfirmAlert = (user: any) => {
    setShowName(user.show_name);
    const deleteUserData = showUsers.filter((user_data: any) => user_data.user_id === user.user_id);
    if (deleteUserData.length > 0) {
      setDeleteUser(deleteUserData[0]);
      setUserRoles(deleteUserData.map((user_role: any) => user_role.role_type));
      setDeleteAlertOpen(true);
    } else {
      console.error("User not found in showUsers:", user);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      setSuccessMessage("");
      setErrorMessage("");
      setDeleteAlertOpen(false);
      const user_id = duser.user_id;
      const deleteShowUserDetails = {
        show_id,
        user_id,
      };

      const data = {
        api: api.users.deleteShowUser,
        urlParam: user_id,
        queryParam: { show_id },
      };
      const result = await backend.remove(data, get(currentUser, "user.accessToken", ""));
      await searchClient.clearCache();
      const showList = (await fetchActiveUserShows(user_id))?.hits || [];
      const isUserPartOfOtherShows = showList?.find((show:any) => show_id && show.show_id !== show_id);
      if (result) {
        const device = {
          api: api.trustedDevice.deactivateAllDevices,
          payLoad: {
            userId: duser.user_id
          }
        }
        if (!isUserPartOfOtherShows)
          await backend.remove(device, get(currentUser, "user.accessToken", ""));
        const deleteShowUserEvent = logEvent(get(currentUser, 'accessToken', ''), EVENT_TYPES.SHOW_USER_DELETION, deleteShowUserDetails);
        console.log("Show User Deletion:", deleteShowUserEvent);
        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": duser.user_email,
          "Action Type": "DeleteUser",
          "Admin Type": "ShowAdmin",
        });

        const filteredusers = showUsers.filter(
          (user: any) => user.user_id !== duser.user_id
        );
        setShowUsers([...filteredusers]);
      }
      const filteredInvitedUsers = invitedUsers.filter(
        (user: any) => user.user_id !== duser.user_id
      );
      setInvitedUsers([...filteredInvitedUsers]);
    } catch (err: any) {
      const msg = "Error while deleting user";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const showResetPasswordAlert = (user: any) => {
    setResetPasswordAlertOpen(true);
    setResetPassUser(user);
  }

  const handleResetPasswordConfirm = async () => {
    setResetPasswordAlertOpen(false);
    await resetUserPassword(resetPassUser);
  };

  const resetUserPassword = async (user: any) => {
    try {
      if (!user || (user && user.user_status !== "Active")) {
        console.log("User is not active");
        throw new Error("User is not active");
      }

      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.resetUserpasswordByAdmin,
        payLoad: JSON.stringify({ id: user.user_id, show_id: show_id }),
      };

      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      if (result) {
        setSuccessMessage(
          "You have successfully sent the reset password email to user"
        );
        if (!modalOpen) {
          setModalOpen(true);
        }
        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": user.user_email,
          "Action Type": "ResetPassword",
          "Admin Type": "ShowAdmin",
        });
      }
    } catch (err: any) {
      const msg = "Error while resetting user passwd";
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };
  const showSuspendAlert = (user: any) => {
    setSuspendAlertOpen(true);
    setSuspendUser(user);
  }
  
  const handleSuspendConfirm = async () => {
    setSuspendAlertOpen(false);
    await inactiveUser(suspendUser);
  };
  const inactiveUser = async (user: any) => {
    try {
      if (!user || (user && user.user_status !== "Active")) {
        console.log("User is not active");
        throw new Error("User is not active");
      }

      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.inactivateUser,
        payLoad: JSON.stringify({ id: user.user_id, show_id: show_id }),
      };

      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));

      Mixpanel.track("Admin User Action", {
        Platform: "Web",
        "Action Email": user.user_email,
        "Action Type": "InactivateUser",
        "Admin Type": "ShowAdmin",
      });

      let users = showUsers;
      users.forEach((u: any) => {
        if (u.user_id === user.user_id) {
          u.user_status = "Inactive";
        }
      });
      setShowUsers([...users]);
      setLoading(false);
    } catch (err: any) {
      const msg = "Error while inactivating user";
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };
  const showActivateUserAlert = (user: any) => {
    setActivateAlertOpen(true);
    setActivatedUser(user);
  }

  const handleActivateUserConfirm = async () => {
    setActivateAlertOpen(false);
    await activateUser(activatedUser);
  };
  const activateUser = async (user: any) => {
    try {
      if (!user || (user && user.user_status === "Active")) {
        throw new Error("User is already active");
      }

      setSuccessMessage("");
      setErrorMessage("");

      const data = {
        api: api.users.activateUserByShowAdmin,
        payLoad: JSON.stringify({ id: user.user_id, show_id: show_id }),
      };

      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));

      Mixpanel.track("Admin User Action", {
        Platform: "Web",
        "Action Email": user.user_email,
        "Action Type": "ActivateUser",
        "Admin Type": "ShowAdmin",
      });

      let users = showUsers;
      users.forEach((u: any) => {
        if (u.user_id === user.user_id) {
          u.user_status = "Active";
        }
      });
      setShowUsers([...users]);
      setLoading(false);
    } catch (err: any) {
      const msg = "Error while activating user";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const showAdminEditUserClick = async (user: any) => {
    if (props.showAdminEditUserClick) {
      props.showAdminEditUserClick(user);
    }
  };
  const handleSearch = (e: any) => {
    const { value } = e.target;
       let iswhiteSpace =  value.trim().length === 0;
       if(!iswhiteSpace) {
        setSearchText(value);
       } else {
        setSearchText("")
       }
  };
  const renderEditButton = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (original && original.user_status !== "Active") {
      return "";
    }
    if (original && original.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <Button
        color="violet"
        onClick={() => {
          showAdminEditUserClick(original);
        }}
      >
        EDIT
      </Button>
    );
  };

  const renderDeleteButton = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (original && original.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <Button
        color="violet"
        onClick={() => {
          showDeleteConfirmAlert(original);
        }}
      >
        DELETE
      </Button>
    );
  };

  const renderResetPasswordButton = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (original && original.user_status !== "Active") {
      return "";
    }
    if (original && original.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <Button
      disabled={isadmin?.includes(original.user_id)}
        color="violet"
        onClick={() => {
          showResetPasswordAlert(original)
        }}
      >
        RESET PASSWORD
      </Button>
    );
  };

  const renderInactiveButton = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (original && original.user_status !== "Active") {
      return "";
    }
    if (original && original.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <Button
        disabled={isadmin?.includes(original.user_id)}
        color="violet"
        onClick={() => {
          showSuspendAlert(original);
        }}
      >
        SUSPEND
      </Button>
    );
  };

  const renderActiveButton = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (original && original.user_status === "Active") {
      return "";
    }
    return (
      <Button
        color="violet"
        onClick={() => {
          showActivateUserAlert(original);
        }}
      >
        ACTIVATE
      </Button>
    );
  };

  const userIcon = (props: any) => {
    const { row } = props;
    const { original } = row;
    return (
      <div
        style={{ width: "100%", cursor: "pointer" }}
        className="list-icon-user"
        onClick={() => showAdminEditUserClick(original)}
        data-tooltip="Edit User"
      ></div>
    );
  };

  const renderEditButtonNew = (text: string, records: any) => {
    // const { row } = props;
    // const { original } = row;
    if (records && records.user_status !== "Active") {
      return "";
    }
    if (records && records.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <AntdButton
        icon={<EditOutlined />}
        type="link"
        onClick={() => {
          showAdminEditUserClick(records);
        }}
      >
        EDIT
      </AntdButton>
    );
  };

  const renderDeleteButtonNew = (text: string, records: any) => {
    // const { row } = props;
    // const { original } = row;
    if (records && records.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <AntdButton
        icon={<DeleteOutlined />}
        type="link"
        danger
        onClick={() => {
          showDeleteConfirmAlert(records);
        }}
      >
        DELETE
      </AntdButton>
    );
  };

  const renderResetPasswordButtonNew = (text: string, records: any) => {
    // const { row } = props;
    // const { original } = row;
    if (records && records.user_status !== "Active") {
      return "";
    }
    if (records && records.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <AntdButton
        icon={<SettingOutlined />}
        type="link"
        disabled={isadmin?.includes(records.user_id)}
        color="violet"
        onClick={() => {
          showResetPasswordAlert(records)
        }}
      >
        RESET PASSWORD
      </AntdButton>
    );
  };

  const renderInactiveButtonNew = (text: string, records: any) => {
    // const { row } = props;
    // const { original } = row;
    if (records && records.user_status !== "Active") {
      return "";
    }
    if (records && records.role_type === ROLES.SUPER_ADMIN) {
      return "";
    }
    return (
      <AntdButton
        icon={<LockOutlined />}
        type="link"
        danger
        disabled={isadmin?.includes(records.user_id)}
        color="violet"
        onClick={() => {
          showSuspendAlert(records);
        }}
      >
        SUSPEND
      </AntdButton>
    );
  };

  const renderActiveButtonNew = (text: string, records: any) => {
    // const { row } = props;
    // const { original } = row;
    if (records && records.user_status === "Active") {
      return "";
    }
    return (
      <AntdButton
        color="violet"
        onClick={() => {
          showActivateUserAlert(records);
        }}
      >
        ACTIVATE
      </AntdButton>
    );
  };

  const userIconNew = (text: string, records: any) => {
    // const { row } = props;
    // const { original } = row;
    return (
      <div
        style={{ width: "100%", cursor: "pointer" }}
        className="list-icon-user"
        onClick={() => showAdminEditUserClick(records)}
        data-tooltip="Edit User"
      ></div>
    );
  };

  const setDataList = (data: any) => {
    const filteredData = data.filter((user: any) => user.role_type !== "SuperAdmin");
    if (!props.isMasvFeature) {
      const finalFilteredData = filteredData.filter((user: any) => user.role_type !== "MailBox");
      setShowUsers(finalFilteredData);
    } else {
      setShowUsers(filteredData);
    }
    // setLoading(false); // Removing the loader after setting data
  };
  
const getTableConfig = () => {
    return {
      columns: [
        // Headers array for the table
        {
          Header: " ",
          Cell: userIcon, //Render function
        },
        {
          Header: "Title", // Header Name
          accessor: "user_title", // Algolia JSON key name
        },
        {
          Header: "First Name",
          accessor: "user_name.user_name_first",
        },
        {
          Header: "Last Name",
          accessor: "user_name.user_name_last",
        },
        {
          Header: "Phone",
          accessor: "user_phone",
        },
        {
          Header: "Email",
          accessor: "user_email",
        },
        {
          Header: "Role",
          accessor: "role_type",
        },
        {
          Header: "User Status",
          accessor: "user_status",
        },
        {
          Header: "Edit",
          Cell: renderEditButton,
        },
        {
          Header: "Delete User",
          Cell: renderDeleteButton,
        },
        {
          Header: "Reset Password",
          Cell: renderResetPasswordButton,
        },
        {
          Header: "Suspend User",
          Cell: renderInactiveButton,
        },
        {
          Header: "Activate User",
          Cell: renderActiveButton,
        },
      ],
      algoliaIndex: REACT_APP_ALGOLIA_USER_INDEX_NAME, // Algolia Index Mandatory
      filters: `show_id:${show_id}`, // Algolia Filters
      setDataList: setDataList, // Temp set list of hits value
      dataList: showUsers, // Algolia list of hits value,
      attributesToRetrieve: [
        // Response attributes
        "user_id",
        "user_title",
        "user_name",
        "user_phone",
        "user_email",
        "role_type",
        "user_status",
        "show_name",
        "country_code",
        "show_id"
      ],
      refresh: true
    };
  };
  const getTableConfigNew = () => {
    return {
      columns: [
        // Headers array for the table
        {
          title: "  ", // Header Name
          // dataIndex: "user_name.user_name_first", // Algolia JSON key name,
          render: userIconNew,

        },
        {
          title: "Title",
          dataIndex: "user_title",
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_title}`.toLowerCase();
            const nameB = `${b.user_title}`.toLowerCase();
            return nameA.localeCompare(nameB);
        },
        },
        {
          title: "First Name",
          dataIndex: "user_name.user_name_first",
          render: (text: string, record: any) => {
            return `${record.user_name.user_name_first} `;
          },
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_name.user_name_first}`.toLowerCase();
            const nameB = `${b.user_name.user_name_first}`.toLowerCase();
            return nameA.localeCompare(nameB);
        },
        },
        {
          title: "Last Name",
          dataIndex: "user_name.user_name_last",
          render: (text: string, record: any) => {
            return `${record.user_name.user_name_last}`;
          },
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_name.user_name_last}`.toLowerCase();
            const nameB = `${b.user_name.user_name_last}`.toLowerCase();
            return nameA.localeCompare(nameB);
        },
        },
        {
          title: "Phone",
          dataIndex: "user_phone",
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_phone}`.toLowerCase();
            const nameB = `${b.user_phone}`.toLowerCase();
            return nameA.localeCompare(nameB);
        },
        },
        {
          title: "Email",
          dataIndex: "user_email",
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_email}`.toLowerCase();
            const nameB = `${b.user_email}`.toLowerCase();
            return nameA.localeCompare(nameB);
        },
        },
        {
          title: "Role",
          dataIndex: "role_type",
          sorter: (a: any, b: any) => {
            const nameA = `${a.role_type}`.toLowerCase();
            const nameB = `${b.role_type}`.toLowerCase();
            return nameA.localeCompare(nameB);
        },
        },
        {
          title: "User Status",
          dataIndex: "user_status",
          sorter: (a: any, b: any) => {
            const nameA = `${a.user_status}`.toLowerCase();
            const nameB = `${b.user_status}`.toLowerCase();
            return nameA.localeCompare(nameB);
        },
        },
        {
          title: "Edit",
          render: renderEditButtonNew,
        },
        {
          title: "Delete User",
          render: renderDeleteButtonNew,
        },
        {
          title: "Reset Password",
          render: renderResetPasswordButtonNew,
        },
        {
          title: "Suspend User",
          render: renderInactiveButtonNew,
        },
        {
          title: "Activate User",
          render: renderActiveButtonNew,
        },
      ],
      algoliaIndex: REACT_APP_ALGOLIA_USER_INDEX_NAME, // Algolia Index Mandatory
      filters: `show_id:${show_id}`, // Algolia Filters
      setDataList: setDataList, // Temp set list of hits value
      dataList: showUsers, // Algolia list of hits value,
      attributesToRetrieve: [
        // Response attributes
        "user_id",
        "user_title",
        "user_name",
        "user_phone",
        "user_email",
        "role_type",
        "user_status",
        "show_name",
        "country_code",
        "show_id"
      ],
      refresh: true
    };
  }
  const tableConfig = getTableConfigNew();
  const renderPopup = () => (
      <div>
        <b>Do you want to delete user from the show?</b>
        <hr/>
        <ul>
          <li>
            <b>Show Name :</b> {show_name}
          </li>
          <li>
            <b>Roles :</b> {userRoles.toString()}
          </li>
        </ul>
      </div>
  );
  const filterData = tableConfig.dataList.filter((user: any) => {
    const firstName: string = get(user, "user_name.user_name_first", "");
    const lastName: string = get(user, "user_name.user_name_last", "");
    const title: string = get(user, "user_title", "");

    const email: string = get(user, "user_email", "");
    let phone: any = get(user, "user_phone", "");
    phone = (typeof phone === "string" ? phone : phone.user_phone) || "";

    let result = tableConfig.dataList;

    if(searchText !== "") {
        result  = `${firstName} ${lastName} ${email} ${phone} ${title}`
        .toLowerCase()
        .includes(searchText.toLowerCase());
    }

    return result
});

const listOfUsersSearchResult = !!searchText ? filterData : tableConfig.dataList;

  return (
    <div className="list-show-users">
      <ProgressLoader loading={loading} />
      <UserMessage errorMsg={errorMessage} />
      {
        props.usersGroupsFlag ?
          <>
            <div className="superadmin-list-heading users-group-list">
              <p className="heading-text heading-text-new">Show Admin :<span className="grey-text"> Users List </span>
              </p>
            </div>
            <div className="searchbar-wrapper searchbar-manage-users">
              <Search
                placeholder="Search"
                allowClear
                className={classNames('left-container-searchbar')}
                // onSearch={handleSearch}
                // @ts-ignore
                onChange={handleSearch}
              />
            </div>
            <Table className="antd-table-new"
              showSorterTooltip={{ title: 'Sort Alphabetically' }}
              scroll={{ x: true }}
              //onChange={tableConfig.onChange}
              columns={tableConfig.columns}
              dataSource={listOfUsersSearchResult}
              pagination={{ pageSize: 20 }}
            />
          </>
          :
          <>
            <div className="list-heading" style={{ marginTop: "10px" }}>
              <p className="heading-text">
                {showUsers.length > 0 ? showUsers[0]["show_name"] : ""} Users :{" "}
              </p>
            </div>
            {!!show_id ? <AlgoliaTable config={getTableConfig()} /> : ""}
          </>
      }
      <ConfirmationModal
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                data={renderPopup()}
                open={deleteAlertOpen}
      />
      <ConfirmationModal
                onOk={handleResetPasswordConfirm}
                onCancel={() => setResetPasswordAlertOpen(false)}
                data={"Are you sure you want to RESET PASSWORD for the selected user?"}
                open={resetPasswordAlertOpen}
      />
      <ConfirmationModal
                onOk={handleSuspendConfirm}
                onCancel={() => setSuspendAlertOpen(false)}
                data={"Do you want to Suspend the user ?"}
                open={suspendAlertOpen}
      />
      <ConfirmationModal
                onOk={handleActivateUserConfirm}
                onCancel={() => setActivateAlertOpen(false)}
                data={"Do you want to Activate the user ?"}
                open={activateAlertOpen}
      />
      <Modal open={modalOpen} onClose={handleModalClose} basic size={"tiny"}>
        <Modal.Content>
          <h3>{successMessage}</h3>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            color="violet"
            // inverted
            onClick={handleModalClose}
          >
            CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default ListShowUsers;

