import { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

import './Watermark.css';
import WatermarkUIPanel from './waterMarkUIPanel';
import WatermarkViewerPanel from './waterMarkViewerPanel';
import { get } from '../../../Utils/helpers';
import { useWatermarkStore } from '../../../store';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { AuthContext } from '../../../Context/authContext';
import { useWatermarkChecksStore } from '../../../store/slices/viewer/watermarkSlice';
import { useLocationStore } from '../../../store/useLocationStore';

const Watermark = (props: any) => {
  const [enableAllGuests, setEnableAllGuests] = useState(false);
  const locationState = useLocationStore((state) => state.locationState);
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const show_id = locationState.state ? locationState.state.show_id : '';
  const {
    zmClient,
    // setWatermarkDetails,
    // updateWatermarkHandler,
    vc_id,
    // watermarkDetails,
    // preview,
    // setPreview,
    isGuestInviteIncluded,
  } = props;

  const {
    preview,
    setPreview,
    watermarkDetails,
    // watermarkChecks,

    setWatermarkDetails,
    // setWatermarkChecks,
  } = useWatermarkStore();
  const {watermarkChecks, setWatermarkChecks } = useWatermarkChecksStore();
  useEffect(() => {
    if (
      Array.isArray(watermarkChecks) &&
      !watermarkChecks.length &&
      Object.keys(watermarkChecks).length
    ) {
      setWatermarkChecks(get(watermarkDetails, 'values', []));
    }
  }, []);

  const updateWatermarkHandler = async (watermark: any) => {
    try {
      const streamId = sessionStorage.getItem('stream_id');
      const data = {
        api: api.vc.updateWatermark,
        urlParam: vc_id,
        payLoad: {
          watermark,
          showStream_id: streamId,
          show_id: show_id,
        },
      };
      const result = await backend.save(data, get(authValue, 'accessToken', {}));
      return result;
    } catch (err: any) {
      console.error('Not able to update watermark in vc room');
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Watermark`,
      children: (
        <div className='publisher-panel-content watermark-panel'>
          <WatermarkUIPanel
            zmClient={zmClient}
            setWatermarkDetails={setWatermarkDetails}
            preview={preview}
            updateWatermarkHandler={updateWatermarkHandler}
            watermarkDetails={watermarkDetails}
            setPreview={setPreview}
            enableAllGuests={enableAllGuests}
            setEnableAllGuests={setEnableAllGuests}
            watermarkChecks={watermarkChecks}
            setWatermarkChecks={setWatermarkChecks}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: `Viewers List`,
      children: (
        <WatermarkViewerPanel
          zmClient={zmClient}
          showId={show_id}
          watermarkDetails={watermarkDetails}
          setWatermarkDetails={setWatermarkDetails}
          updateWatermarkHandler={updateWatermarkHandler}
          enableAllGuests={enableAllGuests}
          setEnableAllGuests={setEnableAllGuests}
          isGuestInviteIncluded={isGuestInviteIncluded}
        />
      ),
    },
  ];

  return (
    <div className='publisher-panel-wrapper'>
      <nav className='main-menu-right-chat' id='publisher-panel-tray'>
        <Tabs
          className='watermarkTab'
          defaultActiveKey={sessionStorage.getItem('tabKey') || '1'}
          items={items}
          centered
          onChange={(e: any) => {
            sessionStorage.setItem('tabKey', e);
          }}
        />
      </nav>
    </div>
  );
};

export default Watermark;
