import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../Assets/css/font-face.css";
import "./TermsAndPolicy.css"

const TermsOfService = () => {

  return (
    <div className="container">
        <div className="heading-center">SUBSCRIPTION LICENSE TERMS OF SERVICE</div>
        <div className="content-wrapper">
            <p>These terms of service (“Terms of Service”) govern the provision and use of a live video and audio streaming platform for motion picture and television editorial systems (“session”) hosted by a secure server and controlled via desktop infrastructure by a show administrator (“publisher”) and the publisher’s invited guests (“viewers”) (“the Services”).</p>
            <p>PLEASE READ THE TERMS OF SERVICE CAREFULLY. IT CONTAINS IMPORTANT TERMS THAT AFFECT YOU AND YOUR USE OF THE SERVICES. BY USING THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICES. IF YOU ARE ACCESSING OR USING THE SERVICES ON BEHALF OF ANY ENTITY OR OTHERWISE IN PERFORMANCE OF SERVICES FOR ANY ENTITY, YOU REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED TO ACCEPT THIS AGREEMENT ON SUCH ENTITY'S BEHALF, THAT ACCEPTANCE OF THIS AGREEMENT AND USE OF THE SERVICES WILL NOT VIOLATE ANY AGREEMENT YOU HAVE WITH SUCH ENTITY, AND THAT SUCH ENTITY AGREES TO INDEMNIFY YOU AND US FOR VIOLATIONS OF THIS AGREEMENT.</p>
            <p>The Services are provided by Pacific Post Rentals, Inc., a California corporation (doing business as PacPost.Live) having its principal office at 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505 (“us”, “we”, “our”).</p>
            <p>The terms of the subscription license granted to the publisher (“Licensee”) to use the Services to invite viewers to take part in sessions is set out in a separate agreement between the Licensee and us (“Agreement”). Unless otherwise expressly set forth in the Agreement these Terms of Service are hereby incorporated into the Agreement in relation to the Licensee’s use of the Services. These Terms of Service also apply to all users (including viewers) to whom the Licensee grants access to the Services under the Licensee’s account, (“you”). By clicking the acceptance button online to these Terms of Service before using the Services, you agree to comply with these Terms of Service and to be legally bound by these Terms of Service when using the Services. If you do not agree to these Terms of Services, you must not use the Services. By accepting the Terms of Service, we hereby grant you a non-exclusive, non-sublicensable, non-transferable license to stream, access, and use the Services during the term of this Agreement for your own internal business use (the “Permitted Purpose”).</p>
            <p>PacPost.live, Pacific Post Rentals Inc., and any other company associated with Pacific Post Rentals Inc. provide their Services “AS-IS” and without any warranties. PacPost.live explicitly disclaims all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement. PacPost.live does not guarantee that the Services will be uninterrupted, secure, or error-free. Your use of the Services is at your own risk. You acknowledge and agree that PacPost.live is not liable for any damages to your computer system, mobile device, or any third party's devices resulting from using the Services. PacPost.live is not responsible for any failure to store, transfer, or delete a file, or for the corruption or loss of any data, information, or content within a file.</p>
            <p>PacPost.live reserves the right to modify, terminate, or expand its Services and may limit access to or remove any features or functionality at its discretion.</p>
            <p>Certain Services may require registration and the provision of accurate data such as email address, password, or payment details. You are responsible for maintaining the accuracy of this information in your account settings. You must safeguard your account and not share your password; you are liable for any activities conducted through your account. PacPost.live reserves the right to delete accounts if registrations or account data are misused. PacPost.live is not responsible for any loss or damage arising from unauthorized use of your account.</p>
            <ol >
                <li>
                    <h4>OTHER TERMS THAT MAY APPLY TO YOU</h4>
                    <p>Our Privacy Policy also applies to your use of the Services in addition and explains our use of any personally identifiable information that you provide to us when you use the Services.</p>
                </li>
                <li>
                    <h4>ELIGIBILITY</h4>
                    <p>You represent and warrant that you are at least 18 years of age. If you are under age 18, you may not, under any circumstances or for any reason, use the Services. We may, in our sole discretion, refuse to offer the Services to any person or entity and change this eligibility criteria at any time. You are solely responsible for ensuring that these Terms of Service comply with all laws, rules, and regulations applicable to you and the right to access the Services is revoked where these Terms of Service or use of the Services is prohibited or to the extent offering access to the Services conflicts with any applicable law, rule, or regulation.</p>
                </li>
                <li>
                    <h4>GENERAL PROHIBITED USES</h4>
                    <p>You may use our Services only for lawful purposes. </p>
                    <p>You may not use the Services: </p>
                    <ul>
                        <li>a.	To breach any applicable local, national, or international law or regulation;</li>
                        <li>b.	For any unlawful or fraudulent purpose;</li>
                        <li>c.	To harm or attempt to harm minors or any person;</li>
                        <li>d.	To bully, insult, intimidate or humiliate any person;</li>
                        <li>e.	To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards set out below in these Terms of Service;</li>
                        <li>f.	To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware;</li>
                        <li>g.	If you are a minor, as legally defined in each applicable jurisdiction. The Services are designed for business use and are not intended to be used by minors;</li>
                        <li>h.	To reproduce, duplicate, copy or re-sell any part of our Services in contravention of the provisions of these Terms of Service;</li>
                        <li>i.	To access without authority, interfere with, damage, or disrupt:
                            <ul>
                                <li>i.	any part of our Services; any equipment or network on which our Services are stored or operate.</li>
                                <li>ii.	any software used in the provision of or use of our Services; any equipment or network or software owned or used by any third party.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <h4>SPECIFIC PROHIBITED USES</h4>
                    <h5>US Export controls:</h5>
                    <p>You warrant that you are not a person or entity appearing on the lists published by the U.S. Department of Commerce, the U.S. Department of State, the U.S. Department of Treasury, or any other list that may be published by the U.S. Government, as amended from time to time, that is prohibited from acquiring access to or control of items under these Terms of Service, or with which we are prohibited from doing business. Furthermore, you confirm that the Services shall not be used for or in connection with nuclear, chemical, or biological weapons, weapons of mass destruction, missiles, unmanned aerial vehicles, and/or to support terrorist activities, that would violate U.S. export controls or economic sanctions laws or regulations.</p>
                    
                    <h5>HIPPA:</h5>
                    <p>You will not cause, or otherwise request that we create, receive, maintain, or transmit protected health information (as defined at 45 C.F.R. § 160.103) for or on behalf of the Licensee in connection with the Services or in any manner that would make us a business associate (as defined at 45 C.F.R. §160.103) to the Licensee.</p>
                
                    <h5>Abusive Behavior:</h5>
                    <p>You will not harass, threaten, or defame any person or entity. You will not contact any person who has requested no further contact. You will not use ethnic or religious slurs against any person or group.</p>
                    
                    <h5>Privacy:</h5>
                    <p>You will not violate the privacy rights of any person. You will not collect or disclose any personal address, social security number, or other personally identifiable information without each holder’s written permission. </p>
                    <p>You will not cooperate in or facilitate identity theft.</p>

                    <h5>Intellectual Property:</h5>
                    <p>You will not infringe the copyrights, trademarks, trade secrets, or other intellectual property rights of any person or entity.</p>
                    <p>You will not reproduce, publish, or disseminate software, audio recordings, video recordings,
                        photographs, articles, or other works of authorship without the written permission of the copyright holder.
                    </p>
                
                    <h5>Hacking, Viruses, & Network Attacks:</h5>
                    <p>You will not access any computer or communications system without authorization, including the computers used to access the Services. </p>
                    <p>You will not attempt to penetrate or disable any security system. You will not intentionally distribute a computer virus, launch a denial-of-service attack, or in any other way attempt to interfere with the functioning of any computer, communications system, service, or website. You will not attempt to access or otherwise interfere with the accounts of other users of the Services.</p>
                
                    <h5>Spam:</h5>
                    <p>You will not send bulk unsolicited e-mails (“Spam”) or sell or market any product or service advertised by or connected with Spam. </p>
                    <p>You will not facilitate or cooperate in the dissemination of Spam in any way. </p>
                    <p>You will not violate the CAN-Spam Act of 2003.</p>
                    
                    <h5>Fraud:</h5>
                    <p>You will not issue fraudulent offers to sell or buy products, services, or investments. </p>
                    <p>You will not mislead anyone about the details or nature of a commercial transaction. </p>
                    <p>You will not commit fraud in any other way.</p>
                </li>
                <li>
                    <h4>INTERACTIVE SERVICES</h4>
                    <p>We provide interactive services within the Services. We do not oversee, monitor, or moderate any interactive service we provide via our Services, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards.</p>
                    <p>We <u>do not</u> provide the ability for the client who sets up and controls use of the virtual room, (“Client Room Owner”) to record or download sessions as part of the Service for the room owner. Recording of sessions is strictly prohibited. </p>
                </li>
                <li>
                    <h4>CONTENT STANDARDS</h4>
                    <p>You acknowledge and understand that any video, audio, data, and other input to or output which you contribute to our Services (“Content”) is entirely your responsibility. You acknowledge, agree, and agree to assert and acknowledge in legal proceedings that we exercise no control whatsoever over the Content and that we will not be liable for Content. These content standards apply to all material which you contribute to our Services (“Contribution”), and to any interactive services associated with it. These content standards must be complied with in spirit as well as to the letter. </p>
                </li>
                <li>
                    <h4>ACCOUNT SECURITY</h4>
                    <p>	The Services are accessible only to the Licensee and their invited viewers. Login credentials (username/password) are solely and exclusively provided for the Licensee (and for their viewers) own personal use. You agree that any login credentials provided by us will not be shared with any third party including those from the same company, same team, or project. You agree not to transfer, copy, disclose, provide, or otherwise make available any designated user account or any confidential information in any form, such as login credentials, to any third party without the prior written consent, or electronic authorization by us. You agree to implement reasonable security measures to protect all confidential account information, but without limitation to the foregoing, shall use best efforts to maintain the security of the access to the designated account. You will use best efforts to cooperate with and assist us in identifying and preventing any unauthorized use, copying, access to or disclosure relating to the Services.</p>
                </li>
                <li>
                    <h4>CONSEQUENCES OF YOUR BREACHES</h4>
                    <p>When we consider that a breach of these Terms of Service has occurred, we may take such action as we deem appropriate to correct, remediate, or restore the Services or functionality. Failure to comply with these Terms of Service constitutes a material breach of the terms upon which you are permitted to use our Services, and may result in us taking all or any of the following actions:</p>
                    <ul>
                        <li>a.	Immediate, temporary, or permanent withdrawal of your right to use us Services</li>
                        <li>b.	Immediate, temporary, or permanent removal of any Contribution uploaded by you to our Services.</li>
                        <li>c.	Issuing a warning to you.</li>
                        <li>d.	Taking legal proceedings against you, including for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                        <li>e.	Taking further legal action against you.</li>
                        <li>f.	Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.</li>
                    </ul>
                    <p>We exclude our liability for all action we may take in response to breaches of these Terms of Service. The actions we may take are not limited to those described above, and we may take any other action we reasonably deem appropriate.</p>
                </li>
                <li>
                    <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
                    <p>We own the full intellectual property rights in and to the Services, software contained therein and supporting documentation, including improvements, enhancements, modifications, and any feedback provided by you. You have no right to any ownership in or title to, the Services, software contained therein or any of our associated supporting documentation including improvements, enhancements, modifications, or feedback. Except as specifically permitted in writing you shall not: modify or create any derivative works from the Services, including – without limitation – translation or localization; (code written to published APIs (Application programming interfaces) for our software shall not be deemed derivative works); copy our software or Services; reverse engineer, decompile, or disassemble or otherwise attempt to derive the source code for the software within the Services (except to the extent applicable laws specifically prohibit such restriction); redistribute, encumber, sell, rent, lease, use the Services in a timesharing or service bureau arrangement, or otherwise transfer rights to the Services or our software. You may not transfer our software or the Services or a designated account under any circumstances; remove or alter any trademark, logo, copyright, or other proprietary notices, legends, symbols, or labels in the Services; publish or make public any results of benchmark tests or permit access to or offer the Services to a third party without our prior written consent.</p>
                </li>
                <li>
                    <h4>DISCLAIMER</h4>
                        <h5>Equipment:</h5>
                        <p>You are responsible for obtaining and maintaining any equipment and ancillary services including video-enabled devices, video communication services, modems, hardware, servers, software, operating systems, networking, web servers, internet, and telephone service (“Equipment”) needed to connect to, access or otherwise use the Services.</p>

                        <h5>Third Party Products:</h5>
                        <p>If third party services, applications, code, hardware, or products (“Third Party Products”) are integrated or used in connection with the Services, you agree we make no representations and disclaims all warranties, express or implied, regarding Third Party Products and we shall have, no liability for Third Party Products or any unavailability of Third-Party Products.</p>

                        <h5>Use of Services by Viewers:</h5>
                        <p>The Licensee is responsible for ensuring compliance with these Terms of Service by all viewers who participate in a session via the Services. Any use of the Services in a manner not permitted by these Terms of Service is a material breach of the Terms of Service. The Licensee is liable for all activities of users attending meetings that occur in a session using the Licensee’s accounts.</p>
                
                </li>
                <li>
                    <h4>WARRANTIES</h4>
                    <p>EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS OF SERVICE, ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF DESIGN, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES OF TITLE AND NON-INFRINGEMENT, ARE EXCLUDED. YOU ACKNOWLEDGE THAT THE SERVICES ARE PROVIDED “AS IS” AND THAT WE DO NOT WARRANT THAT THE OPERATION OF THE SERVICES WILL BE UNINTERRUPTED, ERROR FREE, FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, WILL MEET ANY SPECIFIC USER’S REQUIREMENTS OR THAT THE RESULTS OBTAINED FROM USING THE SERVICES WILL ACHIEVE ANY PARTICULAR RESULTS. THE USER BEARS THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE SERVICES. ANY DOWNLOAD AND USE OF THE SERVICES IS AT THE USER’S OWN RISK AND THE USER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO – WITHOUT LIMITATION – ANY COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES. THE USER SHALL BEAR THE COST OF ALL NECESSARY SERVICING, REPAIR AND/OR CORRECTION CAUSED BY SUCH ACTIVITIES. THE SERVICES SHOULD NOT BE USED FOR EXAMPLE WHERE SENSITIVE AND/OR VALUABLE DATA MAY BE LOST OR COMPROMISED.</p>
                </li>
                <li>
                    <h4>LIABILITY</h4>
                    <p>SUBJECT TO APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE TO YOU FOR – WITHOUT LIMITATION – ANY LOSS OF USE, INTERRUPTION OF BUSINESS, OR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY OR OTHERWISE, WHETHER ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICES (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING RENDERED INACCURATE OR LOSSES SUSTAINED BY USER OR THIRD PARTIES OR A FAILURE OF THE SERVICES TO OPERATE WITH ANY OTHER PROGRAMS), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                </li>
                <li>
                    <h4>INDEMNITY</h4>
                    <p>You agree to indemnify, defend, and hold harmless us, our affiliates, officers, directors, employees, consultants, agents, suppliers, and resellers from any and all third-party claims, liability, damages and/or costs (including, but not limited to, attorneys' fees) arising from your use of the Services, your breaches of these Terms of Service or the infringement or breach by you or any other user of your account, of any intellectual property or other right of any person or entity or applicable law.</p>
                </li>
                <li>
                    <h4>FORCE MAJEURE</h4>
                    <p>Neither party shall be in default by reason of any failure in performance of this Terms of Services, except for an obligation to pay money, if such failure arises, directly or indirectly, out of causes reasonably beyond the direct control or foreseeability of such party, including but not limited to, default by subcontractors or suppliers, acts of God or of the public enemy, terrorist act, United States or foreign governmental acts in either a sovereign or contractual capacity, fire, flood, restriction, strikes, or freight embargos, epidemic, pandemic, shelter-in-place orders, quarantines, government shutdowns, substantial interruption to air travel, substantial interruptions in supply chains, and other economic consequences caused by the
response to the foregoing.</p>
                </li>
                <li>
                    <h4>APPLICABLE LAW</h4>
                    <p>These Terms of Service, their subject matter and formation are governed by the laws of the State of California and are subject to the exclusive jurisdiction of the state and federal courts located in California.</p>
                </li>
                <li>
                    <h4>ARBITRATION FOR NON-U.S. LICENSEES. </h4>
                    <p>IF YOU RESIDE (IF YOU ARE AN INDIVIDUAL) OR ARE DOMICILED (IF YOU ARE ENTERING INTO THIS TERMS OF SERVICE ON BEHALF OF AN ENTITY) OUTSIDE OF THE UNITED STATES, ANY CLAIM OR DISPUTE BROUGHT BY YOU AND ARISING OUT OF OR RELATED TO THIS TERMS OF SERVICE OR THE SERVICES SHALL BE SUBMITTED TO BINDING ARBITRATION IN LOS ANGELES, CALIFORNIA, USA UNLESS WE AGREE IN WRITING TO WAIVE SUCH REQUIREMENT. </p>
                    <p>Such arbitration shall be before an arbitrator-member of the American Arbitration Association (“AAA”) to be mutually agreed to by the parties, or, in the event the parties cannot agree on a single such arbitrator-member, to a panel of three arbitrator-members selected in accordance with the rules of the AAA. The dispute shall be settled in accordance with the Commercial Arbitration Rules of the AAA and the decision of the arbitrator(s) shall be final and binding upon the parties and judgment may be obtained thereon in a court of competent jurisdiction. The prevailing party shall be entitled to recover from the other party the fees and expenses of the arbitration as well as reasonable attorneys' fees, costs and expenses incurred by the prevailing party. The Convention on the Recognition and Enforcement of Foreign Arbitral Awards shall apply to this Terms of Service if you reside or are domiciled outside of the United States.</p>
                </li>
                <li>
                    <h4>CHANGES</h4>
                    <p>We may amend these Terms of Service from time to time. Every time you wish to use the Services, please check these terms to ensure you understand the terms that apply at that time.</p>
                </li>
                <li>
                    <h4>INDEMNITY AND LIABILITY</h4>
                    <p>You agree to defend, indemnify, and hold harmless PacPost.live, Pacific Post Rentals Inc., and any associated companies (including their employees and affiliates) from any claims, liabilities, damages, losses, and expenses, including legal and accounting fees, arising from your access to or use of the Services or any breach of these Terms. This includes claims that content created, used, stored, or shared through the Services by you or via your account infringes or violates any third-party rights.</p>
                </li>
                <li>
                    <h4>INTEGRATION WITH THIRD-PARTY SERVICES</h4>
                    <p>The Services may integrate with third-party services. You acknowledge that PacPost.live, Pacific Post Rentals Inc., and associated companies are not responsible for the actions or omissions of such third-party services, are not their agents, and your use of those services is subject to applicable terms and conditions between you and the providers of such services.</p>
                </li>
                <li>
                    <h4>LIABILITY LIMITATION</h4>
                    <p>To the extent permitted by local mandatory law, PacPost.live, Pacific Post Rentals Inc., and associated companies are not liable for any damage or personal injury resulting from the use of the Services, including temporary unavailability or accidental removal of your Content or account. This limitation of liability doesn’t apply if the damage is caused by their intent or gross negligence. If they are liable for damage under mandatory law, their aggregate liability to you - to the extent permissible by local mandatory law - will not exceed one hundred euros ($100.00) per incident.</p>
                </li>
                <li>
                    <h4>EXPERIMENTAL SERVICES</h4>
                    <p>You agree that some of the software product or portions of the service may be experimental.</p>
                    <p>These Terms of Service were most recently updated on November 28, 2023.</p>
                </li>
            </ol>
        </div>
    </div>
  );
};

export default TermsOfService;
