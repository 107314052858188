import React from "react";
import "../../App.css";
import "../../Assets/css/font-face.css";
import "./TermsAndPolicy.css"

const SubscriptionAgreement = () => {

  return (
    <div className="container">
        <div className="heading-center">SUBSCRIPTION AGREEMENT</div>
        <div className="content-wrapper">
            <p>This Subscription Agreement (“Agreement”) governs the provision and use of a live video and audio streaming platform for motion picture and television editorial systems (“session”) hosted by a secure server and controlled via desktop infrastructure by a show administrator (“Customer”) and the publisher’s invited guests (“viewers”) (collectively “the Services”).</p>
            <p>The Services are provided by Pacific Post Rentals, Inc., a California corporation (doing business as PacPost.Live) having its principal office at 4400 W. Riverside Drive, Ste. 110-215, Burbank, CA 91505 (“Company”).</p>
            <p>The Agreement grants the publisher (“Licensee”) the right to use the Services to invite viewers to take part in sessions. The Agreement and the separate Terms of Services and Privacy Policy also applies to all users (including viewers) to whom the Licensee grants access to the Services under the Licensee’s account (“you”). By clicking the acceptance button online to the Terms of Service before using the Services, you agree to comply with this Agreement, the Terms of Service, and Privacy Policy, and to be legally bound thereby when using the Services. If you do not agree to the Agreement, the Terms of Services, or the Privacy Policy then you must not use the Services. (the “Permitted Purpose”).</p>
            <h4>IT IS AGREED BETWEEN THE PARTIES THAT:</h4>
            <ol >
                <li>
                    <h4>SERVICES </h4>
                    <ul>
                        <li>The Customer engages the Company, and the Company agrees to provide the Services to the Customer in accordance with the terms of this Agreement from the Effective Date for the Term.</li>
                    </ul>
                </li>
                <li>
                    <h4>LICENSE TO USE THE SERVICES</h4>
                    <ul>
                        <li>2.1	 &nbsp;&nbsp;&nbsp;Subject to the Customer's payment of the fees set out in the Customer Profile payable by the Customer during the Term, the Customer is granted a non-exclusive, non-transferable license to permit viewers to use the and Services from the means the effective date set out in the Customer Profile for the Customer's internal business operations. Such license permits the Customer to receive the Services via the Internet. Where open-source software is used as part of the Services, such software use by the Customer will be subject to the terms of the open-source licenses. No additional implied rights are granted beyond those specifically mentioned herein.</li>
                        <li>2.2	 &nbsp;&nbsp;&nbsp;The Customer may not lease, loan, resell, assign, license, distribute or otherwise permit access to the Services; nor use the Services to provide ancillary services related to Services; nor permit access to or use of the Services by or on behalf of any third party except as expressly permitted in this Agreement.</li>
                        <li>2.3	 &nbsp;&nbsp;&nbsp;The Company reserves the right to electronically monitor the Customer’s use of the Services.</li>
                    </ul>
                </li>
                <li>
                    <h4>INTELLECTUAL PROPERTY RIGHTS</h4>                    
                    <ul>
                        <li>3.1 &nbsp;&nbsp;&nbsp;All Company’s intellectual property rights, including copyrights, patents, utility models, trademarks, service marks, business names and logos  (“IPR”) and title to the Services shall remain with the Company and/or its licensors and subcontractors. No interest or ownership in the Services or IPR is transferred to the Customer under this Agreement.</li>
                        <li>3.2	&nbsp;&nbsp;&nbsp;The Customer shall retain sole ownership of all right, title and interest in and to its pre-existing IPR and to data imported by Customer into the Services for the purpose of using the Services or facilitating the Customer’s use of the Services (“Customer Data”), and it shall have the sole responsibility for the legality, reliability, integrity, accuracy and quality of the Customer Data. The Customer grants the Company a non-exclusive, license to use Customer Data, Customer IPR and any third party owned item from the Effective Date for the Term to the extent required for the provision of the Services.</li>
                        <li>3.3	&nbsp;&nbsp;&nbsp;The Customer is not allowed to remove any proprietary marks or copyright notices from the Services or training materials and user manuals relating to the use of the Services (“Documentation”).</li>
                        <li>3.4	&nbsp;&nbsp;&nbsp;The Customer grants the Company a non-exclusive, non-transferable, revocable license to display the Customer’s name, logo and trademarks, as designated and/or amended by the Customer from time to time and as required in the creation of correspondence, documentation and website front ends in the provision of the Services.</li>
                        <li>3.5	&nbsp;&nbsp;&nbsp;The Customer assigns all rights, title, and interest in any innovations or suggestions created by Customer or viewers regarding the attributes, performance or features of the Services (“Feedback”) to the Company. If for any reason such assignment is ineffective, the Customer shall grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</li>
                    </ul>
                </li>
                <li>
                    <h4>TERM</h4>
                    <ul>
                        <li>This Agreement shall commence on the Effective Date and continue until either party terminates the Agreement by giving the other at least 30 days’ notice at any time.</li>
                    </ul>
                </li>
                <li>
                    <h4>THIRD PARTY PROVIDERS AND INTERFACES</h4>
                    <ul>
                        <li>5.1 &nbsp;&nbsp;&nbsp;	The Customer acknowledges that use of the Services may enable or assist it to access the website content of, correspond with, and purchase products and services from, third parties via third-party websites and that it does so solely at its own risk. The Company makes no representation or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website, or any transactions completed, and any contract entered into by the Customer, with any such third party. Any contract entered into and any transaction completed via any third-party website is between the Customer and the relevant third party, and not the Company. </li>
                    </ul>
                </li>
                <li>
                    <h4>FEES AND INVOICING</h4>
                    <ul>
                        <li>6.1 &nbsp;&nbsp;&nbsp;	The Company shall invoice the Customer the fees set out in the Customer Profile payable by the Customer during the Term (“Fees”).  All invoices shall be issued and paid in the currency stated in the Customer Profile. All Fees exclude any Tax legally payable on the date of the invoice, which shall be paid by the Customer in addition, where applicable.</li>
                    </ul>
                </li>
                <li>
                    <h4>PAYMENT TERMS</h4>
                    <ul>
                        <li>7.1 &nbsp;&nbsp;&nbsp;The Customer shall pay the Company the Fees for the provision of the Services under this Agreement as set out in the Customer Profile.</li>
                        <li>7.2 &nbsp;&nbsp;&nbsp;	Payment of all Fees is due within 30 days of the date of invoices. If the Customer believes that any invoice is incorrect, it must notify the Company in writing within 30 days of the invoice date.</li>
                        <li>7.3	&nbsp;&nbsp;&nbsp;Where payment of any Fee is not received within 14 days of the due payment date, the Company may, without liability to the Customer, disable the Customer’s password, account and access to all or part of the Services and the Company shall be under no obligation to provide any or all of the Services while the invoice(s) concerned remains unpaid. The Company shall be entitled to charge interest on overdue Fees at the applicable statutory rate.</li>
                        <li>7.4	&nbsp;&nbsp;&nbsp;The Company reserves the right to recover any costs and reasonable legal fees it incurs in recovering overdue payments.</li>
                    </ul>
                </li>
                <li>
                    <h4>CONFIDENTIAL INFORMATION</h4>
                    <ul>
                        <li>8.1	&nbsp;&nbsp;&nbsp;Any and all information in any form whatsoever relating to the Company or the Customer, or the business, prospective business, finances, technical process, computer software (both source code and object code) and IPR of the Company or the Customer (as the case may be), which comes into a party’s possession as a result of this Agreement or provision of the Services, and which the party regards, or could reasonably be expected to regard, as confidential and any and all information which has been or may be derived or obtained from any such information (“Confidential Information”) shall be used by either party only for the purposes of this Agreement. Each party must keep confidential all Confidential Information disclosed to it, except where the recipient of Confidential Information is required to disclose the Confidential Information by law to any regulatory, governmental, or other authority with relevant powers to which either party is subject.</li>
                        <li>8.2	&nbsp;&nbsp;&nbsp;Each party may disclose the Confidential Information of the other party to those of its employees and agents who need to know the Confidential Information for the purposes of this Agreement, but only if the employee or agent is bound by confidentiality undertakings equivalent to those set out in this Agreement.</li>
                        <li>8.3	&nbsp;&nbsp;&nbsp;Both parties agree to return (or destroy) all documents, materials or data containing Confidential Information to the disclosing party without delay upon completion of the Services or termination or expiry of this Agreement.</li>
                    </ul>
                </li>
                <li>
                    <h4>DATA PROTECTION</h4>
                    <ul>
                        <li>9.1 &nbsp;&nbsp;&nbsp;Each party undertakes to comply with its obligations under relevant applicable data protection laws, principles and agreements.</li>
                        <li>9.2	&nbsp;&nbsp;&nbsp;To the extent that personal data is processed when the Customer or Viewers use the Solution and Services, the parties acknowledge that the Company is a data processor, and the Customer is a data controller.</li>
                        <li>9.3	&nbsp;&nbsp;&nbsp;The parties shall comply with their respective obligations under the terms of the DPA.</li>
                        <li>9.4	&nbsp;&nbsp;&nbsp;If a third party alleges infringement of its data protection rights, the Company shall be entitled to take measures necessary to prevent the infringement of a third party’s rights from continuing.</li>
                        <li>9.5	&nbsp;&nbsp;&nbsp;Where the Company collects and processes personal data of the Customer, as a data controller, when providing the Solution and Services to the Customer, such collection and processing shall be in accordance with the Privacy Policy.</li>
                    
                    </ul>
                </li>
                <li>
                    <h4>REPRESENTATIONS AND WARRANTIES</h4>
                    <ul>
                        <li>10.1 &nbsp;&nbsp;&nbsp;	Customer warrants and represents that it has full power and authority to enter into this Agreement and to perform the obligations required hereunder; the execution and performance of its obligations under this Agreement does not violate or conflict with the terms of any other agreement to which it is a party and is in accordance with any applicable laws; that it rightfully owns the necessary user rights, copyrights and ancillary copyrights and permits required for it to fulfil its obligations under this Agreement.</li>
                    </ul>
                </li>
                <li>
                    <h4>LIABILITY</h4>
                    <ul>
                        <li>11.1 &nbsp;&nbsp;&nbsp;Company shall not be liable for any consequential loss arising out of or related to this Agreement or in tort (including negligence or breach of statutory duty), misrepresentation or however arising.</li>
                        <li>11.2 &nbsp;&nbsp;&nbsp;The Customer shall be liable for any breaches of this Agreement caused by the acts, omissions or negligence of any viewers who access the Services as if such acts, omissions or negligence had been committed by the Customer itself</li>
                        <li>11.3 &nbsp;&nbsp;&nbsp;The Customer shall not raise any claim under this Agreement more than 1 year after the discovery of the circumstances giving rise to a claim; or the effective date of termination or expiry of this Agreement. The parties acknowledge and agree that in entering into this Agreement, each had recourse to its own skill and judgement and have not relied on any representation made by the other, their employees or agents. </li>
                    </ul>
                </li>
                <li>
                    <h4>TERMINATION</h4>
                    <ul>
                        <li>12.1 &nbsp;&nbsp;&nbsp;The Company may terminate this Agreement or the provision of any Services with immediate effect if the Customer has used or permitted use of the Services other than in accordance with this Agreement; or the Company is prohibited under applicable law, or otherwise from providing the Services.</li>
                        <li>12.2 &nbsp;&nbsp;&nbsp;Company may terminate this Agreement immediately, with cause, if the Customer ceases or threatens to cease or carry-on business; is unable to pay its debts or enters compulsory insolvency or voluntary liquidation; convenes a meeting of its creditors or has a receiver, manager or similar official appointed in respect of its assets; or a Force Majeure event (as defined the Terms and Conditions) lasts for more than 28 days.</li>
                        <li>12.3 &nbsp;&nbsp;&nbsp;Either party may terminate this Agreement for material breach of any term by giving the breaching party written notice. However, where the breach is capable of remedy, provided that the breach is specified and remedy of the breach is requested, the notice shall only be effective if the breaching party fails to remedy the breach within 10 days of receipt of the notice.</li>
                        <li>12.4 &nbsp;&nbsp;&nbsp;Termination of this Agreement for any reason shall not affect the accrued rights of the parties arising under this Agreement and without limitation the right to recover damages against the other. Termination of this Agreement for whatever reason shall not affect the accrued rights of the parties. All clauses which by their nature should continue after termination shall, for the avoidance of doubt, survive the expiration or sooner termination of this Agreement and shall remain in force and effect.</li>
                        <li>12.5 &nbsp;&nbsp;&nbsp;Upon termination of this Agreement, the Customer shall promptly pay the Company all unpaid Fees and all licenses granted under the Agreement shall terminate on the effective date of termination; and the Company shall cease providing the Services to the Customer. </li>
                    </ul>
                </li>
                <li>
                    <h4>SECURITY</h4>
                    <ul>
                        <li>13.1  &nbsp;&nbsp;&nbsp;The Company shall permit the Customer to specify which Viewers may access the Services. The Customer and Viewers must ensure that each password is only used by the user to which it has been assigned. The Customer is responsible for all activities that occur under the Customer’s account and via the Customer’s passwords. The Customer will immediately notify the Company if the Customer becomes aware of any unauthorized use of the Customer’s account, the Customer’s passwords or breach of security known to the Customer. The Company shall have no liability for any loss or damage arising from the Customer’s failure to comply with these requirements.</li>
                        <li>13.2  &nbsp;&nbsp;&nbsp;The Company may suspend access to the Services at any time, if in the Company’s sole reasonable discretion, the integrity or security of the Services is in danger of being compromised by acts of the Customer or Viewers. The Company shall give the Customer prior written notice, before suspending access to the Services, giving specific details of its reasons.</li>
                        <li>13.3  &nbsp;&nbsp;&nbsp;The Customer is solely responsible for the use and streaming rights of the content being streamed through the Services. This is including all room participants, streaming rights, recording rights, and playback rights.</li>
                    </ul>
                </li>
                <li>
                    <h4>CONTACTS</h4>
                    <ul>
                        <li>14.1 &nbsp;&nbsp;&nbsp;The Customer shall provide a designated application support contact who will be responsible for and have sufficient information to respond to support questions, and a designated billing contact with all relevant contact information to respond to billing and payment questions regarding the Services.</li>
                    </ul>
                </li>
                <li>
                    <h4>MISCELLANEOUS</h4>
                    <ul>
                        <li>15.1 &nbsp;&nbsp;&nbsp;This Agreement: (i) is governed by California substantive and procedural law and, without regard to choice of law rules, California courts shall have jurisdiction for all purposes; (ii) merges in it all prior representations and negotiations, and, together with any exhibits or schedules, is the final, exclusive statement of the terms and conditions of agreement; (iii) shall not be assignable without written consent of all parties; (iv) shall benefit and bind each party's successor, of whatever legal description; (v) may be executed by electronic facsimile and in counterparts, which nevertheless constitute one and the same agreement; (vi) is deemed the joint legal product of all parties, no matter the draftsman's identity; (vii) shall be kept in confidence by the parties except where required by a competent legal or administrative order; (viii) may not be amended, terminated or modified except by a writing signed by the party to be charged, and (ix) is severable and divisible as to all of its provisions, and if any provision is held invalid, the remaining provisions shall continue in full force and effect.</li>
                        <li>15.2 &nbsp;&nbsp;&nbsp;Except with respect to the Customer’s obligation to pay the Fees, if a party is wholly or partially unable to comply with its obligations under this Agreement due to Force Majeure, then that party’s obligation to perform in accordance with this Agreement will be suspended for the duration of the Force Majeure.</li>
                        <li>15.3 &nbsp;&nbsp;&nbsp;Neither party shall make any public statement, press release or other announcement relating to the terms or existence of this Agreement, or the business relationship of the parties, without the prior written consent of the other party. Notwithstanding the aforesaid the Company may use the Customer’s name and trademarks (logo only) to list the Customer as a client of the Company on its website and in other marketing materials and information.</li>
                    </ul>
                </li>
            </ol>
            <p>This document has been most recently updated on August 1, 2022</p>
        </div>
    </div>
  );
};

export default SubscriptionAgreement;
