import { Checkbox, Form, Input, Tabs, Button } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import React, { useState, useEffect, Fragment } from "react";
import { Dropdown, Modal } from "semantic-ui-react";
import InputComponent from "../../../Components/UIKit/InputComponent";
import { SelectPP } from "../../../Components/UIKit/Select";
import { ROLES } from "../../../Utils/constants";
import { MESSAGES } from "../../../Utils/constants";
import { get } from "../../../Utils/helpers";
import './PubViewerForm.css'
import './style.css'


interface IProps {
  isGuestInviteIncluded: boolean;
  lmconfigValue: any;
  countryCodes: any[];
  successMessage: string;
  errorMessage: string;
  licenseMangerFlag?: boolean;
  listShowGroup?: any[];
  createUser: (userdata: any, isPublisherChecked: boolean, setPublisherCheck: any, setRoles: any) => void;
  usersGroupsFlag?: boolean;
}

const PubViewerForm = (props: IProps) => {
  const [user_title, setUserTitle] = useState("");
  const [user_name_first, setFirstName] = useState("");
  const [user_name_last, setLastName] = useState("");
  const [user_email, setEmail] = useState("");
  const [user_phone, setPhone] = useState("");
  const [comments, setComments] = useState("");
  const [user_group, setUserGroup] = useState("");

  const [roles, setRoles] = useState({
    [ROLES.SHOW_VIEWER]: false,
    [ROLES.SHOW_PUBLISHER]: false,
  });

  const [isViewerChecked, setViewerCheck] = useState(false);
  const [isPublisherChecked, setPublisherCheck] = useState(false);
  const [isGuestInviteChecked, setGuestInviteCheck] = useState(false);
  const [showRequestPub, setShowRequestPub] = useState(false);

  const [emailValid, setEmailValid] = useState(true);
  const [titleValid, setTitleValid] = useState(true);

  const [fNameValid, setfNameValid] = useState(true);
  const [lNameValid, setlNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [checkboxValid, setCheckboxValid] = useState(true);

  const [countryCodes, setCountryCodes] = useState([] as any);
  const [countryCode, setCountryCode] = useState("");
  const [ccValid, setCCValid] = useState(true); // cc --> Country Code
  const [errorCountryCode, setErrorCountryCode] = useState("");
  const [country_code, setUserCountryCode] = useState("");

  const [errorEmail, setErrorEmail] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorfName, setErrorfName] = useState("");
  const [errorlName, setErrorlName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorCheckbox, setErrorCheckbox] = useState("");

  const [isListShowGroup, setIsShowListGroup] = useState([] as any);

  useEffect(() => {

    if (
      props.successMessage &&
      props.successMessage === MESSAGES.CREATE_USER_SUCCESS
    ) {
      console.log("Clear fields");
      form.resetFields();
      clearFormFields();
    }
    if (props.listShowGroup) {

      // update list
      const updateShowGroupList = props.listShowGroup.map(({
        show_group_id: value,
        show_group_name: label,
        show_group_status
      }) => ({
        value,
        label,
        // label:value,
        show_group_status
      }));

      setIsShowListGroup(updateShowGroupList)
    }

    if (props.countryCodes) {
      const countryCodes = props.countryCodes.sort((a: any, b: any) => get(a, "value", "").localeCompare(get(b, "value", "")));
      const updatedCountryValues = countryCodes.map(({
        text: value,
        value: text,
        code,
        country_name,
        id,
        count
      }) => ({
        text,
        value,
        code,
        country_name,
        id,
        count
      }));
      console.log('===countr', updatedCountryValues)

      setCountryCodes(updatedCountryValues)
    }

  }, [props.successMessage, props.countryCodes, props.listShowGroup]);

  const clearFormFields = () => {
    setUserTitle("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setCountryCode("");
    setPhone("");
    setComments("");
    setRoles({ [ROLES.SHOW_VIEWER]: false, [ROLES.SHOW_PUBLISHER]: false });
    setViewerCheck(false);
    setPublisherCheck(false);
    setGuestInviteCheck(false);
  };

  const createUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      const input = {
        user_title,
        user_name_first,
        user_name_last,
        user_email,
        user_phone,
        country_code: '+91', //TODO
        roles,
        comments,
        show_group_ids: isViewerChecked ? user_group || [] : [],
        guest_invite: isGuestInviteChecked,
      };
      console.log(input, 'check inpu')
      await props.createUser(input, isPublisherChecked, setPublisherCheck, setRoles);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleShowGroupChange = (value: any) => {
    setUserGroup(value)
  };

  const handelRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (value === ROLES.SHOW_PUBLISHER) {
      if (!checked) {
        setGuestInviteCheck(false);
      }
      setPublisherCheck(!!checked);
      setRoles({
        ...roles,
        [ROLES.SHOW_PUBLISHER]: !!checked,
      });
      //console.log("create page configValue:::",props.lmconfigValue)
      if (props.lmconfigValue === 0 && props.licenseMangerFlag === true && checked === true) {
        setShowRequestPub(true)
        setPublisherCheck(false);
        setRoles({
          ...roles,
          [ROLES.SHOW_PUBLISHER]: false,
        });
      } else if (props.lmconfigValue === 0 && props.licenseMangerFlag === false && checked === true) {
        setShowRequestPub(false)
        setPublisherCheck(true);
        if (props.isGuestInviteIncluded) {
          setGuestInviteCheck(true);
        }
      } else if (props.lmconfigValue > 0 && checked === true) {
        setShowRequestPub(false)
        setPublisherCheck(true);
        if (props.isGuestInviteIncluded) {
          setGuestInviteCheck(true);
        }
      }
    }
    else if (value === ROLES.SHOW_VIEWER) {
      setViewerCheck(!!checked);
      setRoles({
        ...roles,
        [ROLES.SHOW_VIEWER]: !!checked,
      });
    } else if (value === "AllowGuestInvite") {
      setGuestInviteCheck(!!checked);
    }

    //Handle the validation from current value and previous state
    const viewerStatus =
      value === ROLES.SHOW_VIEWER ? !!checked : isViewerChecked;
    const publisherStatus =
      value === ROLES.SHOW_PUBLISHER ? !!checked : isPublisherChecked;

    setCheckboxValid(!!viewerStatus || !!publisherStatus);
    setErrorCheckbox(
      !!viewerStatus || !!publisherStatus ? "" : "Please Check Your Role"
    );
  };

  // validation of form fields
  const validateEmail = (value: any) => {
    setEmail(value.trim());
  };


  const validateTitle = (value: any) => {
    setUserTitle(value.trim());
  };

  const validateFirstName = (value: any) => {
    setFirstName(value.trim());
    let validatefName = value.trim();
    if (!validatefName) {
      setfNameValid(false);
      setErrorfName("Please enter your first name");
    } else {
      setfNameValid(true);
      setErrorfName("");
    }
  };

  const validateLastName = (value: any) => {
    // const { value } = event.target;
    setLastName(value.trim());
    let validatelName = value.trim();
    if (!validatelName) {
      setlNameValid(false);
      setErrorlName("Please enter your last name");
    } else {
      setlNameValid(true);
      setErrorlName("");
    }
  };

  const validatePhoneText = (data: any) => {
    const currCountry: any = props.countryCodes.find(
      (cc: any) => get(cc, "text", "") === data.value
    );

    if (data && currCountry) {
      if (
        user_phone &&
        !currCountry?.ignore_count &&
        user_phone.length !== currCountry.count
      ) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone("");
      }
    }
  };

  const validatePhone = (value: any) => {
    // const { value } = event.target;
    const validatePhone = value.replace(/[^0-9]/, '');
    setPhone(validatePhone);
    if (!validatePhone) {
      setPhoneValid(false);
      setErrorPhone("Please enter phone number");
    } else if (countryCode) {
      const currCountry: any = props.countryCodes.find(
        (cc: any) => get(cc, "text", "") === countryCode
      );
      const regexString = "^\\d{" + currCountry.count + "}$";
      const regex = new RegExp(regexString);
      console.log('=====current code', currCountry)

      if (
        countryCode === get(currCountry, "text", "") &&
        !currCountry?.ignore_count &&
        !regex.test(validatePhone)
      ) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone("");
      }
    } else if (!countryCode) {
      setPhoneValid(false);
      setErrorPhone("Please Select Country Code");
    } else {
      setPhoneValid(true);
      setErrorPhone("");
    }
  };

  const validateCountryCode = (event: any, data: any) => {
    console.log(event, data)
    if (!data.value) {
      setCCValid(false);
      setErrorCountryCode("Please select country code");
    } else {
      setCCValid(true);
      setErrorCountryCode("");
      validatePhoneText(data);
    }
  };
  const searchCountryCode = (value: any) => {
    // const { value } = event.target;
    if (value === "") {
      const countryCodes = props.countryCodes.sort((a: any, b: any) => get(a, "value", "").localeCompare(get(b, "value", "")));
      // setCountryCodes(countryCodes)


    } else {
      const lower_val = value
      // const lower_val = value.charAt(0).toLowerCase();

      const startsWith = countryCodes.filter((code: any) => code.value.toLowerCase().indexOf(lower_val) === 0);
      // setCountryCodes(startsWith)
      // console.log('check starts', startsWith)

    }
  };

  const handleCountryCodeChange = (e: any, data: any) => {
    console.log(`selected ${data.value}`);

    setCountryCode(data.value);
    const currCountry: any = props.countryCodes.find(
      (cc: any) => get(cc, "value", "") === data.text
    );
    setUserCountryCode(currCountry.code);
    validateCountryCode(e, data);
  };

  const isGroupSelection = () => {
    let selection = true;
    if (isViewerChecked && isPublisherChecked) {
      return !selection
    }
    else if (isPublisherChecked) {
      return selection
    }
    return !selection
  }

  const isGroupActive = isGroupSelection()


  const [form] = Form.useForm();

  return (
    <>
    <div className="publisher-viewer-form-new">
      <Form name="form"
        form={form}
        layout="vertical">
        <div className="-form-wrapper">
          <Form.Item
            name="TITLE"
            label="TITLE"
            rules={[
              { max: 20 },
            ]}
          >
            <InputComponent
              key={"title"}
              type="text"
              value={user_title}
              onChange={validateTitle}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="EMAIL"
            rules={[
              { required: true },
              {
                validator: async (_: any, val: any) => {
                  let validEm = val.trim();
                  if (validEm === "") {
                    setEmailValid(false);
                    return Promise.reject(
                      <span className="error-message">Please Enter Email</span>
                    );
                  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validEm)) {
                    setEmailValid(false);
                    return Promise.reject(
                      <span className="error-message">Invalid Email Format</span>
                    );
                  } else {
                    setEmailValid(true);
                    setErrorEmail("");
                    Promise.resolve()
                  }
                },
              },
              { max: 35 },
            ]}
          >
            <InputComponent
              key={"email"}
              type="email"
              maxLength="35"
              value={user_email}
              onChange={validateEmail}
            />
          </Form.Item>
        </div>
        <div className="-form-wrapper">
          <Form.Item
            name="firstName"
            label="FIRST NAME"
            rules={[
              { required: true, message: <span className="error-message">Please Enter Your First Name</span> },
              { max: 20 },
            ]}
          >
            <InputComponent
              key={"firstname"}
              type="text"
              value={user_name_first}
              onChange={validateFirstName}
            />
          </Form.Item>
          <Form.Item
            label="LAST NAME"
            name="lastName"
            rules={[
              { required: true, message: <span className="error-message">Please Enter Your Last Name</span> },
              { max: 20 },
            ]}
          >
            <InputComponent
              key={"lastname"}

              type="text"
              value={user_name_last}
              onChange={validateLastName}
            />
          </Form.Item>
        </div>
        <div className="-form-wrapper">
          <Form.Item name="countryCode" label="COUNTRY CODE" rules={[{ required: true }]}>
            <SelectPP
              className="country-code-dropdown"
              label=""
              showSearch={true}
              optionFilterProp="children"
              filterOption={
                // @ts-ignore
                (input, option) => (option?.text ?? '').toLowerCase().includes(input.toLowerCase())
              }
              //filterSort={handleCountryCodeChange}
              filterSort={searchCountryCode}
              entry={countryCodes}
              onChange={handleCountryCodeChange}
            />
          </Form.Item>
          <Form.Item
            label="PHONE"
            name="phone"
            rules={[
              { required: true, message: <span className="error-message">Please Enter Phone Number</span> },
              { max: 20 },
            ]}
          >
            <InputComponent
              key="phone"
              type="tel"
              value={user_phone}
              onChange={validatePhone}
            />
            {phoneValid === false && (
              <span className="error-message">{errorPhone}</span>
            )}
          </Form.Item>
        </div>
        <div className="-form-wrapper">
          <Form.Item
            label="NOTE"
            //  rules={[
            //   { required: true, message: "Please Enter " },
            //   { max: 30 }
            // ]}
            name="note">
            <InputComponent
              key={"note"}
              placeholder="Notes goes here"
              type="text"
              rows={2}
              isTextArea
              value={comments}
              onChange={(value: any) => setComments(value)}
            />
          </Form.Item>
          <Form.Item name="group">
            <SelectPP
              disabled={isGroupActive}
              entry={isListShowGroup}
              mode='multiple'
              label="GROUP NAME"
              onChange={handleShowGroupChange}
            />
          </Form.Item>
        </div>
        <div className="checkbox-wrapper">
          <Checkbox
            value={ROLES.SHOW_VIEWER}
            checked={isViewerChecked}
            onChange={(e: any) => handelRole(e)}
          >
            Viewer
          </Checkbox>

          <Checkbox
            value={ROLES.SHOW_PUBLISHER}
            checked={isPublisherChecked}
            onChange={(e: any) => handelRole(e)}
          >
            Publisher
          </Checkbox>
          {isPublisherChecked && props.isGuestInviteIncluded && (
            <Checkbox
              value="AllowGuestInvite"
              checked={isGuestInviteChecked}
              onChange={(e: any) => handelRole(e)}
            >
              Allow Guest Invite
            </Checkbox>
          )}

        </div>
        <div className="text-center" style={{ margin: "8px" }}>
          {checkboxValid === false && (
            <span className="error-message">{errorCheckbox}</span>
          )}
        </div>
        <div className="text-center" style={{ margin: "20px" }}>
          <span className="copy-text">If invited user already has an active account they will not receive an e-mail, only new users will get an e-mail invitation to set up an account</span>
        </div>

        <div className="btn-wrapper">
          <Button
            type="primary"
            className="invite-btn"
            // @ts-ignore
            onClick={createUser}
            disabled={
              fNameValid === false ||
              user_name_first === "" ||
              lNameValid === false ||
              user_name_last === "" ||
              emailValid === false ||
              user_email === "" ||
              ccValid === false ||
              // country_code === "" ||
              // phoneValid === false ||
              // user_phone === "" ||
              checkboxValid === false ||
              (isPublisherChecked === false && isViewerChecked === false)
            }
          >
            Invite User
          </Button>
        </div>
        {props.errorMessage && (
          <Fragment>
            <div className="flex">
              <span className="alert-icon"></span>
              <span className="error-message alert-text-message">
                {props.errorMessage}
              </span>
            </div>
          </Fragment>
        )}
      </Form>
      </div>
      {showRequestPub &&
        <Modal
          open={showRequestPub}
          size={"tiny"}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="licence-modal"
          closeIcon
          onClose={() => setShowRequestPub(false)}
        >
          <Modal.Content className="licence-content">
            <div className="mlv-viewer-license-content">
              As there is no Publisher, Please request licenses from License Manager and then add users to the show.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className="confirm-button" onClick={() => setShowRequestPub(false)}>
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
      }
    </>
  );
};

export default PubViewerForm;
