import React, { useEffect, useState, useContext, memo } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { CheckboxProps } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import ProgressLoader from '../../Common/ProgressLoader';
import Algolia from '../../Algolia/Algolia';
import Search from './Search';
import { get } from '../../../Utils/helpers';
import { AuthContext } from '../../../Context/authContext';
import ImageCanvas from '../../UserSettings/ImageCanvas';
import { useGetRoomParticipants } from './PublisherPanelHelpers';
import { useLocationStore } from '../../../store/useLocationStore';



const { REACT_APP_ALGOLIA_USER_INDEX_NAME } = process.env;

const PublisherPanelHOC = (props: any) => {
  const {
  } = props;
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const roomParticipants = useGetRoomParticipants();
  const [roomParticipantsList, setRoomParticipantsList] = useState([] as any[]);
  const [dataList, setDataList] = useState([]);

  const locationState = useLocationStore((state) => state.locationState);
  const show_id = locationState.state ? locationState.state.show_id : '';

  const [config, setConfig] = useState({
    algoliaIndex: REACT_APP_ALGOLIA_USER_INDEX_NAME, // Algolia Index Mandatory
    hitsPerPage: 10000, // No of records per page
    filters: `show_id:${show_id} AND role_type:ShowViewer`, // Algolia Filters
    setDataList: setDataList, // Temp set list of hits value
    dataList: dataList, // Algolia list of hits value,
    refresh: true,
    algoliaLoading: false,
  });

  useEffect(() => {
    const roomParticipantsListArr = [...roomParticipantsList];
    if (roomParticipants.length > 0) {
      roomParticipants.forEach((item: any) => {
        const index = roomParticipantsListArr.findIndex(
          (participant: any) => participant.sid === item.sid,
        );
        if (index > -1) {
          roomParticipantsListArr[index] = item;
        } else {
          roomParticipantsListArr.push(item);
        }
      });
      setRoomParticipantsList(roomParticipantsListArr);
    }
  }, [roomParticipants]);

  const PublisherPanel = (props: any) => {
    const locationState = useLocationStore((state) => state.locationState);
    const show_id = locationState.state ? locationState.state.show_id : '';

    const stream_id = sessionStorage.getItem('stream_id');
    const stream_label: any = sessionStorage.getItem('stream_label');

    const [invitedUsers, setInvitedUsers] = useState([] as any[]);
    const [uninvitedUsers, setUninvitedUsers] = useState([] as any[]);

    const [loading, setLoading] = useState(false);

    const [updateUsersList, setUpdateUsersList] = useState([] as any[]);

    const [selectedTab, setSelectedTab] = useState('Invited');
    const [participantsList, setParticipantsList] = useState([] as any[]);
    const [searchText, setSearchText] = useState('');

    const fetchData = () => {
      console.log('dataList :>> ', dataList);
      const stream_id = sessionStorage.getItem('stream_id');
      let iUsers: any = [];
      let uUsers: any = [];

      if (!!stream_id) {
        dataList.forEach((item: any) => {
          if (!!item.invited_streams) {
            const index = item.invited_streams.findIndex(
              (item: any) => item.stream_id === stream_id,
            );
            if (index > -1) {
              iUsers.push(item);
            } else {
              uUsers.push(item);
            }
          } else {
            uUsers.push(item);
          }
        });
      }

      iUsers = iUsers.map((obj: any) => ({ ...obj, invitation_status: 'invited' }));
      uUsers = uUsers.map((obj: any) => ({ ...obj, invitation_status: 'uninvited' }));

      setInvitedUsers([...iUsers]);
      setUninvitedUsers([...uUsers]);
    };

    useEffect(() => {
      if (dataList.length === 0) {
        setLoading(true);
      }
      fetchData();
    }, []);

    // useEffect(() => {
    //   if (usersUpdated) {
    //     setConfig({
    //       ...config,
    //       dataList: [],
    //     });
    //     // fetchData();
    //     // setUsersUpdated(false);
    //   }
    // }, [usersUpdated]);

    const handleUserSelect = (data: CheckboxProps, user: any) => {
      if (
        roomParticipantsList
          .map((item) =>
            item.identity.substring(
              item?.identity.lastIndexOf('#') + 1,
              item?.identity.lastIndexOf('_'),
            ),
          )
          .includes(user?.user_id)
      ) {
        return;
      }
      let uninvitedUsersObj = [...uninvitedUsers];
      let invitedUsersObj = [...invitedUsers];
      let updateUsersListObj = [...updateUsersList];

      if (user.invitation_status === 'uninvited') {
        let index = uninvitedUsersObj.findIndex((item) => item.user_id === user.user_id);
        if (!!uninvitedUsersObj[index].action) {
          delete uninvitedUsersObj[index].action;
        } else {
          uninvitedUsersObj[index].action = 'invite';
        }
        // uninvitedUsersObj.splice(index, 1);
        // user.invitation_status = "invited";
        // invitedUsersObj.push(user);
      } else if (user.invitation_status === 'invited') {
        let index = invitedUsersObj.findIndex((item) => item.user_id === user.user_id);
        if (!!invitedUsersObj[index].action) {
          delete invitedUsersObj[index].action;
        } else {
          invitedUsersObj[index].action = 'uninvite';
        }
        // invitedUsersObj.splice(index, 1);
        // user.invitation_status = "uninvited";
        // uninvitedUsersObj.push(user);
      }

      if (updateUsersListObj.length > 0) {
        let index = updateUsersListObj.findIndex((item: any) => item.userid === user.user_id);
        if (index > -1) {
          updateUsersListObj.splice(index, 1);
        } else {
          updateUsersListObj.push({
            userid: user.user_id,
            action: data.checked ? 'invite' : 'uninvite',
          });
        }
      } else {
        updateUsersListObj.push({
          userid: user.user_id,
          action: data.checked ? 'invite' : 'uninvite',
        });
      }

      setUninvitedUsers(uninvitedUsersObj);
      setInvitedUsers(invitedUsersObj);
      setUpdateUsersList(updateUsersListObj);
    };

    const handleInviteUninviteUsers = async () => {
      let uninvitedUsersObj = [...uninvitedUsers];
      let invitedUsersObj = [...invitedUsers];
      let updateUsersListObj = [...updateUsersList];

      let usersToInvite = updateUsersListObj.filter((item: any) => item.action === 'invite');
      let usersToUnInvite = updateUsersListObj.filter((item: any) => item.action === 'uninvite');

      console.log('usersToInvite :>> ', usersToInvite);
      console.log('usersToUnInvite :>> ', usersToUnInvite);

      console.log('uninvitedUsersObj :>> ', uninvitedUsersObj);
      console.log('invitedUsersObj :>> ', invitedUsersObj);

      setLoading(true);
      if (usersToInvite.length > 0) {
        let userids: string[] = [];
        userids = usersToInvite.map((item: any) => item.userid);
        const input = { show_id, stream_id, userids: userids.toString() };

        const data = {
          api: api.streams.inviteViewersToStream,
          payLoad: JSON.stringify(input),
        };

        const response = await backend.save(data, get(authValue, 'accessToken', {}));
        console.log('response :>> ', response);
      }

      if (usersToUnInvite.length > 0) {
        let userids: string[] = [];
        userids = usersToUnInvite.map((item: any) => item.userid);
        const input = { show_id, stream_id, userids: userids.toString() };

        const data = {
          api: api.streams.unInviteViewersToStream,
          payLoad: JSON.stringify(input),
        };

        const response = await backend.save(data, get(authValue, 'accessToken', {}));
        console.log('response :>> ', response);
      }

      usersToInvite.forEach((item: any) => {
        let userIndex = uninvitedUsersObj.findIndex((user: any) => user.user_id === item.userid);
        console.log('userIndex :>> ', userIndex);
        let userObj = uninvitedUsersObj[userIndex];
        console.log('userObj-before :>> ', userObj);
        delete userObj.action;
        userObj.invitation_status = 'invited';
        console.log('userObj-after :>> ', userObj);
        invitedUsersObj.push(userObj);
        uninvitedUsersObj.splice(userIndex, 1);
      });

      usersToUnInvite.forEach((item: any) => {
        let userIndex = invitedUsersObj.findIndex((user: any) => user.user_id === item.userid);
        console.log('userIndex :>> ', userIndex);
        let userObj = invitedUsersObj[userIndex];
        console.log('userObj-before :>> ', userObj);
        delete userObj.action;
        userObj.invitation_status = 'uninvited';
        console.log('userObj-after :>> ', userObj);
        uninvitedUsersObj.push(userObj);
        invitedUsersObj.splice(userIndex, 1);
      });

      // for (let i = 0; i < uninvitedUsersObj.length; i++) {
      //   delete uninvitedUsersObj[i]?.action
      // }
      // for (let i = 0; i < invitedUsersObj.length; i++) {
      //   delete invitedUsersObj[i]?.action
      // }

      setUninvitedUsers([...uninvitedUsersObj]);
      setInvitedUsers([...invitedUsersObj]);
      setUpdateUsersList([]);
      setLoading(false);
    };

    const handleClearChanges = async () => {
      const uninvitedUsersObj = [...uninvitedUsers];
      const invitedUsersObj = [...invitedUsers];

      for (let i = 0; i < uninvitedUsersObj.length; i++) {
        delete uninvitedUsersObj[i]?.action;
      }
      for (let i = 0; i < invitedUsersObj.length; i++) {
        delete invitedUsersObj[i]?.action;
      }

      setUninvitedUsers(uninvitedUsersObj);
      setInvitedUsers(invitedUsersObj);
      setUpdateUsersList([]);
    };

    const publisherPanelContent = (data: any) => {
      console.log('data :>> ', data);

      data = data.filter((item: any) => {
        let item_temp = `${item?.user_name?.user_name_first} ${item?.user_name?.user_name_last}`;
        if (!item_temp) {
          return false;
        }
        //const i = item_temp.toLowerCase().includes(searchText.toLowerCase());
        //console.log(i, item_temp,searchText);
        return item_temp.toLowerCase().includes(searchText.toLowerCase());
      });
      data.forEach(
        (item: any) => (item.joined = participantsList.includes(item?.user_id) ? true : false),
      );

      data.sort((a: any, b: any) => (a.joined < b.joined ? 1 : -1));

      const dataJoined = data
        .filter((user: any) => !!user.joined)
        .sort((a: any, b: any) =>
          a?.user_name?.user_name_first.localeCompare(b?.user_name?.user_name_first),
        );
      const dataNotJoined = data
        .filter((user: any) => !user.joined)
        .sort((a: any, b: any) =>
          a?.user_name?.user_name_first.localeCompare(b?.user_name?.user_name_first),
        );
      const sortedData = dataJoined.concat(dataNotJoined);

      return (
        <div className='publisher-panel-content'>
          <div className='publisher-panel-item' style={{ borderBottom: '1px solid white' }}>
            <div className='publisher-panel-image'></div>
            <div className='publisher-panel-user'></div>
            <div className='publisher-panel-action'>
              {selectedTab !== 'Invited' ? 'Invite' : 'Invited'}
            </div>
            <div className='publisher-panel-status'>Status</div>
          </div>
          {sortedData.map((user: any, index: number) => {
            let checked = false;
            if (user.invitation_status === 'invited') {
              checked = user?.action === 'uninvite' ? false : true;
            } else if (user.invitation_status === 'uninvited') {
              checked = user?.action === 'invite' ? true : false;
            }

            const participantJoined = roomParticipants.some((participant:any)=> participant.identity.includes(user?.user_id))
            const joined = participantJoined ? 'connected' : 'disconnected';

            return (
              <div className='publisher-panel-item' key={user.user_id} data-tooltip={`${user.user_email}`} style={{ marginTop: '18px' }}>
                <div className='publisher-panel-image'>
                  <ImageCanvas imageUrl={user.user_image_url_path} className='chat-image' />
                </div>
                <div className='publisher-panel-user'>
                  {`${user?.user_name?.user_name_first} ${user?.user_name?.user_name_last}`}
                </div>
                <div className='publisher-panel-action'>
                  <Checkbox
                    checked={checked}
                    onClick={(event, data: CheckboxProps) => {
                      handleUserSelect(data, user);
                    }}
                    className={
                      roomParticipantsList
                        .map((item) =>
                          item.identity.substring(
                            item?.identity.lastIndexOf('#') + 1,
                            item?.identity.lastIndexOf('_'),
                          ),
                        )
                        .includes(user?.user_id) || participantJoined
                        ? 'disabled-action'
                        : ''
                    }
                    disabled={roomParticipantsList
                      .map((item) =>
                        item.identity.substring(
                          item?.identity.lastIndexOf('#') + 1,
                          item?.identity.lastIndexOf('_'),
                        ),
                      )
                      .includes(user?.user_id) || participantJoined}
                  />
                </div>
                <div className='publisher-panel-status'>
                  <div
                    className='publisher-panel-status-circle'
                    style={{
                      backgroundColor: joined === 'connected' ? 'rgb(27, 204, 115)' : 'red',
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    const handleSearch = (value: string) => {
      setSearchText(value);
    };

    return (
      <div className='publisher-panel-wrapper'>
        <nav className='main-menu-right-chat' id='publisher-panel-tray'>
          <ProgressLoader loading={loading} />
          <div className='chat-h1'>
            <span className='chat-text'>Publisher Panel</span>
          </div>

          <div className='publisher-panel-search'>
            <Search searchSubmit={handleSearch} searchText={searchText} />
          </div>

          <div className='tabs-wrapper'>
            <button
              className={`publisher-panel-tab-button ${selectedTab === 'Invited' ? 'active' : ''}`}
              onClick={(event) => {
                event?.stopPropagation();
                if (selectedTab !== 'Invited') {
                  setSelectedTab('Invited');
                }
              }}
            >
              Added
            </button>
            <button
              className={`publisher-panel-tab-button ${
                selectedTab === 'Not Invited' ? 'active' : ''
              }`}
              onClick={(event) => {
                event?.stopPropagation();
                if (selectedTab !== 'Not Invited') {
                  setSelectedTab('Not Invited');
                }
              }}
            >
              Not Added
            </button>
          </div>

          {selectedTab === 'Invited' && publisherPanelContent(invitedUsers)}
          {selectedTab === 'Not Invited' && publisherPanelContent(uninvitedUsers)}

          <div className='publisher-actions'>
            <div className='action-clear-changes'>
              <button
                onClick={handleClearChanges}
                disabled={updateUsersList.length === 0}
                className='publisher-panel-action-button'
              >
                Cancel Changes
              </button>
            </div>
            <div className='action-save-changes'>
              <button
                onClick={handleInviteUninviteUsers}
                disabled={updateUsersList.length === 0}
                className='publisher-panel-action-button'
              >
                Apply Changes
              </button>
            </div>
          </div>
        </nav>
      </div>
    );
  };

  return <> {Algolia(PublisherPanel, { config })} </>;
};

export default memo(PublisherPanelHOC);
