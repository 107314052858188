import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { Table } from 'antd';
import { Button, Modal} from 'semantic-ui-react';
import backend from '../../Service/Backend'
import { Layout } from "../PageLayout/Layout";
import api from "../../Service/Api";
import { PAGES } from "../../Utils/constants";
import ProgressLoader from "../Common/ProgressLoader";
//import { get } from "../../Utils/helpers";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';


interface TrustedDevice {
    key: string,
    fingerprint: string,
    browser: string,
    os: string,
    status: string,
    expires: string,
}
const TrustedDevicesList = () => {
    const [loading, setLoading] = useState(false);
    const currentUser = get(useContext(AuthContext), "currentUser", {});
    const [deviceList, setDeviceList] =useState([]);
    const [selectedDevice, setSelectedDevice] = useState<React.Key[]>([]);
    const [modalOpen, setModalOpen] = useState(false);


    useEffect(() => {
       fetchTrustedDevice()
    }, [])

    const fetchTrustedDevice = async () => {
        try {
        const deviceAll = {
          api: api.trustedDevice.deviceList,
        }
          setLoading(true);
          const devices = await backend.fetch(deviceAll, get(currentUser, "user.accessToken", ""));
          //const deviceDropdown = devices.map((d:any) => ({'text': d.id,'value': d.id}));
            //setDeviceData(device)
          setDeviceList(devices.map((device:any) => ({...device, key: device.fingerprint})));
          console.log("device######",devices)
          setLoading(false);
          //setDeviceList(deviceDropdown)
        } catch (err: any) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      };

      const deactivateTrustedDevice = async () => {
        try {
        const device = {
          api: api.trustedDevice.deactivateDevices,
          payLoad: {
                   fingerprints: selectedDevice
                   } 
         }
          setLoading(true);
          const devices = await backend.remove(device, get(currentUser, "user.accessToken", ""));
          //const deviceDropdown = devices.map((d:any) => ({'text': d.id,'value': d.id}));
            //setDeviceData(device)
          await fetchTrustedDevice();
          setLoading(false);
          //setDeviceList(deviceDropdown)
        } catch (err: any) {
          console.error(err);
        } finally {
          setLoading(false);
          setSelectedDevice([] as any);
          setModalOpen(false);
        }
      };


      const columns: ColumnsType<TrustedDevice> = [
        {
            title: "FINGERPRINT ID",
            dataIndex: "fingerprint",
        },
        {
          title: "BROWSER",
          dataIndex: "metaData",
          render: (metaData: any) => <span>{metaData.browser}</span>,
        },
        {
          title: "OS",
          dataIndex: "metaData",
          render: (metaData: any) => <span>{metaData.os}</span>,
        },
        {
            title: "STATUS",
            dataIndex: "status",
          },
        {
            title: "EXPIRES",
            dataIndex: "expires_in",
            render: (date) => moment(date).format('lll'),
        }
      ];

      const rowSelection = {
        selectedRowKeys: selectedDevice,
        onChange: (selectedRowKeys: React.Key[], selectedRows: TrustedDevice[]) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedDevice(selectedRowKeys)
        },

      };

    return (
        <Layout page={PAGES.TRUSTED_DEVICE_LIST}>
          <ProgressLoader loading={loading} />
        <div className="trusted-device-content">
          <div className="trusted-device-list-heading">
            {/* <p className="trusted-device-list-heading"> */}
              <span className="grey-text"> Trusted Devices List </span>
            {/* </p> */}
            <button className="btn-deregister" disabled={!selectedDevice.length} onClick={() => setModalOpen(true)}>Unregister</button>
            </div>
            <div className="trusted-device-list">
            <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={deviceList}
      />
      </div>

          {/* <button className="btn-deregister" disabled={!selectedDevice} onClick={() => setModalOpen(true)}>Deregister</button> */}

            { modalOpen === true && (
                <Modal
                    centered
                    open={modalOpen}
                    size={"tiny"}
                    onClose={() => setModalOpen(false)}
                    onOpen={() => setModalOpen(true)}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                  >
                    <Modal.Content>Are you sure, you want to delete the selected trusted device</Modal.Content>
                    <Modal.Actions>
                    <div className="deregister-trust-device">
                    <Button
                        className="purple-button"
                        onClick={deactivateTrustedDevice}
                      >
                        YES
                      </Button>
                      <Button
                        className="purple-button"
                        onClick={() => {setSelectedDevice([]);setModalOpen(false)}}
                      >
                        CLOSE
                      </Button>
                    </div>
                    </Modal.Actions>
                  </Modal>
              )}
              </div>
              </Layout>
      );
}
export default TrustedDevicesList;