import { useContext, useState } from 'react';
import useGuestViewerCommands from './useGuestViewerCommands';
import { Participant } from '@zoom/videosdk';
import { get } from '../../../Utils/helpers';
import useToggle from '../../../Utils/useToggle';
import ConfirmationDialog from '../../ConfirmationDialog/Index';
import useGetGuestUsers from './useGetGuestUsers';
import ZoomContext from '../context/zoom-context';

const GuestUsersList = (props: any) => {
  const { guestInviteCount } = props;
  const [removeConfirmationOpen, showRemoveConfirmation, hideRemoveConfirmation, confirmationData] =
    useToggle<{ displayName: string; identity: string }>();
  const guests = useGetGuestUsers();
  const [updated, setUpdated] = useState(false);
  const zmClient = useContext(ZoomContext);
  const { allow, remove } = useGuestViewerCommands(updated, setUpdated,zmClient);

  return (
    <>
      <>
        <div className='guest-viewer-url-container'>
          <div className='guest-viewer-url-container-item'>
            <div className='guest-users-list-title'>Guests:</div>
          </div>
          <div className='guest-viewer-url-container-item'>
            ({guests.length} / {guestInviteCount})
          </div>
        </div>

        {guests.map(
          //({ allowed, displayName, identity }) => {
          (guest: Participant) => {
            let allowedGuest: any = sessionStorage.getItem('allowedGuest') || {};
            allowedGuest =
              typeof allowedGuest === 'string' ? JSON.parse(allowedGuest) : allowedGuest;
            const identity = get(guest, 'userIdentity', '');
            const allowed = allowedGuest[identity]?.status === 'allowed';
            const status = allowed ? 'Active' : 'Waiting';
            const displayName = get(guest, 'displayName', '');
            return (
              <div className='guest-viewer-url-container' key={identity}>
                <div className='guest-viewer-url-container-item'>
                  <div>
                    <div>{displayName}</div>
                    <div>
                      Status:&nbsp;<i>{status}</i>
                    </div>
                  </div>
                </div>
                <div className='guest-viewer-url-container-item'>
                  <div className='guest-user-remove-btn-wrapper'>
                    {Boolean(allowed) && (
                      <button
                        key={identity}
                        className='guest-user-list-remove-btn'
                        onClick={() =>
                          showRemoveConfirmation.withData({
                            displayName,
                            identity,
                          })
                        }
                      >
                        Remove
                      </button>
                    )}
                    {!allowed && (
                      <button
                        key={identity}
                        className='guest-user-list-remove-btn'
                        onClick={() => allow(identity)}
                      >
                        Allow
                      </button>
                    )}
                  </div>

                  <div className='guest-user-status-circle'>
                    <div
                      className={!allowed ? 'guest-user-status-waiting' : 'guest-url-status-active'}
                    ></div>
                  </div>
                </div>
              </div>
            );
          },
        )}

        <ConfirmationDialog
          open={removeConfirmationOpen}
          onClose={hideRemoveConfirmation}
          onConfirm={() => {
            confirmationData?.identity && remove(confirmationData?.identity);
            hideRemoveConfirmation();
          }}
          onCancel={hideRemoveConfirmation}
          title={`Remove ${confirmationData?.displayName}`}
          message='Are you sure you want to remove this guest?'
        />
      </>
    </>
  );
};
export default GuestUsersList;
