import React from "react";
import { Message } from "semantic-ui-react";

interface IMessageProps {
  successMsg?: string;
  errorMsg?: string;
}

const UserMessage = (props: IMessageProps) => {
  const renderMessage = () => {

    if (props.successMsg || props.errorMsg) {
      if (props.successMsg) {
        return (
          <Message positive style={{ marginBottom: "15px"}}>
              <p>{props.successMsg}</p>
          </Message>
        );
      } else if (props.errorMsg) {
        
        return (
          <Message negative style={{ marginBottom: "15px"}}>
            <p>{props.errorMsg}</p>
          </Message>
        );
      }  
    } else {
      return <div></div>
    }
  };

  return <div>{renderMessage()}</div>;
};

export default UserMessage;
