import { InfoCircleOutlined } from '@ant-design/icons';
import { List, Table, Tooltip, Button } from 'antd';
import { useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import type { ColumnsType } from 'antd/es/table';
import { get } from '../../Utils/helpers';
import {
  AddDeviceTableDataType,
  DeviceDataComponentState,
  StatusDataType,
  DeviceDisabledButtonDataType,
} from './structs';
import useDeviceActions from './useDeviceActions';
import DeviceStatusModal from './DeviceStatusModal';

interface DeviceListProps {
  deviceData: never[];
  shows: never[];
  refreshList: boolean;
  setRefreshList: (value: boolean) => void;
  setLoading: (value: boolean) => void;
}
const DeviceList = (props: DeviceListProps) => {
  const [searchResults, setSearchResults] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deviceAction, setDeviceAction] = useState('');

  const [actionLoader, setActionLoader] = useState(false);

  const [disableButton, setDisableButton] = useState<DeviceDisabledButtonDataType>({
    device_id: '',
    disabled_state: false,
  });
  const [deviceStatusData, setDeviceStatusData] = useState<StatusDataType>({
    audioBitrate: '',
    audioCodec: '',
    colorFormat: '',
    currentVideoBitrate: '',
    fps: '',
    hdmiStatus: '',
    sdiStatus: '',
    videoBitrate: '',
    videoCodec: '',
    videoInput: '',
    videoResolution: '',
  });
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const [deviceValues, setDeviceValues] = useState<DeviceDataComponentState>({
    deviceId: '',
    deviceName: '',
    macId: '',
    provision: false,
    show_id: '',
    stream_id: '',
    object_id: '',
  });

  const { reboot, getStatus, provisionDevice, unProvisionDevice } = useDeviceActions(
    props.setRefreshList,
    props.refreshList,
    setActionLoader,
    setDeviceStatusData,
    setStatusModalOpen,
    setDisableButton,
    searchResults,
    setSearchResults,
  );

  const search = (event: any) => {
    //Check if the entered value matches with device data.
    let filteredOutput = props.deviceData.filter(
      (device: any) =>
        get(device, 'name', '').toLowerCase().includes(event.target.value.toLowerCase()) ||
        get(device, 'show_name', '').toLowerCase().includes(event.target.value.toLowerCase()) ||
        get(device, 'stream_name', '').toLowerCase().includes(event.target.value.toLowerCase()),
    );

    if (filteredOutput) setSearchResults(filteredOutput);
    else setSearchResults([]);
  };

  const renderToolTip = (text: any) => {
    const requiredData = props.deviceData.filter((device: any) => device.name === text);
    const toolTipData = [
      { Mac_id: `${get(get(requiredData, '0', ''), 'mac_id', '')}` },
      { device_id: `${get(get(requiredData, '0', ''), 'name', '')}` },
      { show_id: `${get(get(requiredData, '0', ''), 'show_id', '')}` },
      { stream_id: `${get(get(requiredData, '0', ''), 'stream_id', '')}` },
      { streaming: `${get(get(requiredData, '0', ''), 'streaming', '')}` },
    ];
    const streaming: boolean = get(get(requiredData, '0', ''), 'streaming', '') === true;
    return (
      <div className='device-name-field'>
        <div className={!streaming ? 'guest-url-status-expired' : 'guest-url-status-active'}></div>
        <span>{text}</span>{' '}
        <Tooltip
          title={
            <List
              dataSource={toolTipData}
              className='device-tooltip-list'
              renderItem={(item: any) => {
                return (
                  <List.Item>
                    <div className='device-tooltip-list-item'>
                      <span className='bold-font'>{Object.keys(item)}:</span>
                      <span>{Object.values(item)}</span>
                    </div>
                  </List.Item>
                );
              }}
            />
          }
          placement='right'
          color='#808080'
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    );
  };

  const closeModal = () => {
    setModalOpen(false);
    setDeviceAction('');
    setDeviceValues((prevState) => ({
      ...prevState,
      deviceId: '',
      deviceName: '',
      macId: '',
      provision: false,
      show_id: '',
      stream_id: '',
      object_id: '',
    }));
  };

  const openModal = (action: string, deviceId: string) => {
    setModalOpen(true);
    const requiredData = props.deviceData.filter((device: any) => device.device_id === deviceId);
    setDeviceValues((prevState) => ({
      ...prevState,
      deviceId: deviceId,
      deviceName: get(get(requiredData, '0', ''), 'name', ''),
      macId: get(get(requiredData, '0', ''), 'mac_id', ''),
      show_id: get(get(requiredData, '0', ''), 'show_id', ''),
      stream_id: get(get(requiredData, '0', ''), 'stream_id', ''),
      object_id: get(get(requiredData, '0', ''), 'id', ''),
    }));
    if (action) {
      setDeviceAction(action);
    }
  };

  const handleModalConfirm = async () => {
    setModalOpen(false);
    switch (deviceAction) {
      case 'REBOOT':
        reboot(deviceValues.deviceId);
        break;
      case 'PROVISION':
        provisionDevice(deviceValues.object_id, deviceValues.deviceId);
        break;
      case 'UN-PROVISION':
        unProvisionDevice(deviceValues.object_id, deviceValues.deviceId);
        break;
      default:
        break;
    }
  };

  const getDeviceStatus = (deviceId: string) => {
    const requiredData = props.deviceData.filter((device: any) => device.device_id === deviceId);
    const object_id = get(get(requiredData, '0', ''), 'id', '');
    const device_id = get(get(requiredData, '0', ''), 'device_id', '');
    getStatus(object_id, device_id);
  };

  const renderActionButtons = (deviceId: any) => {
    const requiredData = props.deviceData.filter((device: any) => device.device_id === deviceId);
    const provisionFlag = get(get(requiredData, '0', ''), 'provisioned', false);
    const streaming: boolean = get(get(requiredData, '0', ''), 'streaming', false) === true;
    return (
      <div className='device-name-field' key={deviceId}>
        <div>
          {provisionFlag ? (
            <Button
              onClick={() => openModal('UN-PROVISION', deviceId)}
              className='device-action-btn'
              disabled={
                (disableButton.device_id === deviceId && disableButton.disabled_state) || streaming
              }
              // loading={actionLoader}
            >
              UNPROVISION
            </Button>
          ) : (
            <Button
              onClick={() => openModal('PROVISION', deviceId)}
              className='device-action-btn'
              disabled={
                (disableButton.device_id === deviceId && disableButton.disabled_state) || streaming
              }
              // loading={actionLoader}
            >
              PROVISION
            </Button>
          )}
        </div>
        <Button
          onClick={() => getDeviceStatus(deviceId)}
          className='device-action-btn'
          disabled={
            (disableButton.device_id === deviceId && disableButton.disabled_state) || streaming
          }
          // loading={actionLoader}
        >
          STATUS
        </Button>

        <Button
          onClick={() => {
            // rebootDevice(deviceId);
            openModal('REBOOT', deviceId);
          }}
          disabled={
            (disableButton.device_id === deviceId && disableButton.disabled_state) || streaming
          }
          className='device-action-btn'
          loading={
            actionLoader && disableButton.device_id === deviceId && disableButton.disabled_state
          }
        >
          REBOOT
        </Button>
      </div>
    );
  };

  const columns: ColumnsType<AddDeviceTableDataType> = [
    {
      title: 'Device Name',
      dataIndex: 'name',
      key: 'name',
      render: renderToolTip,
      sorter: (item1: any, item2: any) => {
        const x = get(item1, 'name', '').toLowerCase();
        const y = get(item2, 'name', '').toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Show Name',
      dataIndex: 'show_name',
      key: 'show_name',
      render: (show_name: string) => (
        <div className='empty-device-name-field'>
          {show_name ? <span>{show_name}</span> : <span className='bold-font'>--</span>}
        </div>
      ),
      sorter: (item1: any, item2: any) => {
        const x = get(item1, 'show_name', '').toLowerCase();
        const y = get(item2, 'show_name', '').toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Stream Name',
      dataIndex: 'stream_name',
      key: 'stream_name',
      render: (stream_name: string) => (
        <div className='empty-device-name-field'>
          {stream_name ? <span>{stream_name}</span> : <span className='bold-font'>--</span>}
        </div>
      ),
      sorter: (item1: any, item2: any) => {
        const x = get(item1, 'stream_name', '').toLowerCase();
        const y = get(item2, 'stream_name', '').toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'device_id',
      key: 'device_id',
      render: renderActionButtons,
    },
  ];

  return (
    <div className='device-list-container'>
      <div className='device-list-searchbar-container'>
        <input
          className='device-list-searchbar'
          name='search'
          type='text'
          placeholder={`Search`}
          // value={searchValue}
          onChange={search}
        ></input>
      </div>
      <div className='ppl-device-list'>
        <Table
          columns={columns}
          dataSource={searchResults.length ? searchResults : props.deviceData}
        />
      </div>
      <Confirm
        open={modalOpen}
        content={`Are you sure you want to ${deviceAction} the device ?`}
        cancelButton='Cancel'
        confirmButton='Yes'
        onConfirm={handleModalConfirm}
        onCancel={closeModal}
      />
      <DeviceStatusModal
        deviceStatusData={deviceStatusData}
        setStatusModalOpen={setStatusModalOpen}
        statusModalOpen={statusModalOpen}
      />
    </div>
  );
};
export default DeviceList;
