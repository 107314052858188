import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Dispatch, SetStateAction } from 'react';

export type SetValue<T> = Dispatch<SetStateAction<T>>;

export function encrypt(key: string, strValue: string) {
  return CryptoAES.encrypt(strValue, key).toString();
}

export function decrypt(key: string, encryptedValue: string) {
  return CryptoAES.decrypt(encryptedValue, key).toString(CryptoENC);
}

// A wrapper for "JSON.parse()"" to support "undefined" value
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}