import { useContext } from "react";
import cameraOn from "../../../Images/cameraOn.svg";
import cameraOff from "../../../Images/cameraOff.svg";
import ZoomContext from '../context/zoom-context';
import ZoomMediaContext from '../context/media-context';
import { useVideo } from "../hooks/useVideo";
import { useWhiteboardingStore } from "../../../store";
import { Grid } from "antd";
import { get } from "../../../Utils/helpers";

const VideoToggle = (props: any) => {
    const { showDominantSpeaker, resetDrawingButtonState } = props;
    const zmClient = useContext(ZoomContext);
    const { mediaStream } = useContext(ZoomMediaContext);
    const { resetAllTools } = useWhiteboardingStore();
    const { isVideoOn, onCamClick } = useVideo(zmClient, mediaStream);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    return (
        <div className={get(screens, 'xs', false) ? 'miniheader-btn' :`top-menu-button-item ${showDominantSpeaker ? "disableddiv" : ""}`} onClick={() => {
            onCamClick();
            resetDrawingButtonState();
            resetAllTools();
        }}>
            {isVideoOn ? (
                <>
                    <img src={cameraOn} alt="" />
                </>
            ) : (
                <>
                    <img
                        src={cameraOff}
                        alt=""
                        style={{ filter: "brightness(1)" }}
                    />
                </>
            )}
            {!get(screens, 'xs', false) &&<span>Toggle Video</span>}
        </div>
    )
};

export default VideoToggle;
