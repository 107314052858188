import { useRef } from 'react';
import { AudioMutedOutlined } from '@ant-design/icons';
import type { NetworkQuality } from '@zoom/videosdk';
import classNames from 'classnames';
import './avatar.scss';
import { ParticipantType } from '../types/index-types';

interface AvatarProps {
  participant: ParticipantType;
  style?: { [key: string]: string };
  isActive: boolean;
  className?: string;
  networkQuality?: NetworkQuality;
  videoOn: boolean
}
const networkQualityIcons = ['bad', 'bad', 'normal', 'good', 'good', 'good'];

const ZoomAvatar = (props: AvatarProps) => {
  const { participant, style, isActive, className, networkQuality, videoOn } = props;
  const { displayName, audio, muted, userId, isInFailover } = participant;
  const avatarRef = useRef(null);

  return (
    <div
      className={classNames('zoom-avatar', { 'avatar-active': isActive }, className)}
      style={{ ...style, background: videoOn ? 'transparent' : 'rgb(26,26,26)', display: videoOn ? 'none' : 'block' }}
      ref={avatarRef}
    >
      {!videoOn && <p className="center-name">{displayName}</p>}
    </div>
  );
};

export default ZoomAvatar;
