import { useState, useRef } from 'react';
import {
  connect,
  createLocalTracks,
  NoiseCancellationOptions,
  LocalTrack,
} from 'twilio-video';
import { trace } from "firebase/performance";
import { performance } from "../../../Firebase/firebase";
import { get } from '../../../Utils/helpers';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { isGuestAuthenticated } from '../../../Utils/guestHelpers';
import { isViewInScreen } from '../../../Utils/utils';
import useGuestSessions from '../hooks/useGuestSessions';
import { auth } from '../../../Firebase';
import useNavigateToPage from '../../../hooks/useNavigateToPage';
import * as Sentry from '@sentry/react';

const noiseCancellationOptions: NoiseCancellationOptions = {
  sdkAssetsPath: `${process.env.PUBLIC_URL || window.location.origin}/${
    process.env.NOISE_CANCELLATION_PLUGIN_PATH
  }`,
  vendor: 'krisp',
};

type Coordinate = {
  x: number;
  y: number;
};

const useTwilioVC = (
 dataTrack: any,
 dataTrackRef:any,
  setOpen: any,
  setAnnotateMessages: any,
  setMessages: any,
  setUsersUpdated: any,
  setWatermarkDetails: any,
  invitedViewersRef: any,
  user_chime_preferance: any,
  disconnectedGuests: any,
  showProfileImage: any,
  hideProfileImage: any,
  updateGuestSession: any,
  signOutGl: any,
  changeMicrophoneTrack: any,
  setLocalParticipantBGModalOpen: any,
  drawLine:any,
  drawCircle:any,
  setStream:any,
  setStreamLabel:any,
  cleanupFunction:any
  //vtrayRef: any
) => {
  /***************** State (Start) ***************/

  // Room
  const [troom, setTRoom] = useState({} as any);
  const troomRef = useRef<any>();
  troomRef.current = troom;
  const signOutGuest = useRef(()=>{});
  const updateGuestSessioninDB = useRef((sessions:{})=>{});
  const isAudioVideoAllowedForGuestRef = useRef(()=>{});
  const showProfileImageRef = useRef((activeViewers:[],sid:String,identity:String)=>{});
  const hideProfileImageRef = useRef((activeViewers:[],sid:String,identity:String)=>{});
  const cleanupRef = useRef(()=>{});
  if(typeof signOutGl === 'function') signOutGuest.current =  signOutGl;
  if(typeof updateGuestSession === 'function') updateGuestSessioninDB.current =  updateGuestSession;
  if(typeof showProfileImage === 'function') showProfileImageRef.current =  showProfileImage;
  if(typeof hideProfileImage === 'function') hideProfileImageRef.current =  hideProfileImage;
  if(typeof cleanupFunction === 'function') cleanupRef.current =  cleanupFunction;

  // Local Participant
  const [localParticipant, setLocalParticipant] = useState({} as any);
  const [lParticipant, _setLLocalParticipant] = useState({} as any);
  const lParticipantRef = useRef(lParticipant);
  const setLLocalParticipant = (data: any) => {
    lParticipantRef.current = data;
    _setLLocalParticipant(data);
  };

  // Room Participants
  const [roomParticipantsList, _setRoomParticipantsList] = useState([] as any);
  const roomParticipantsListRef = useRef(roomParticipantsList);
  const [roomParticipantsWithTracks, setRoomParticipantsWithTracks] = useState([] as any);

  // Room participnat Connection status
  const [roomParticipantsConnectionStatus, _setRoomParticipantsConnectionStatus] = useState(
    [] as any,
  );
  const roomParticipantsConnectionStatusRef = useRef(roomParticipantsConnectionStatus);

  //Local Participant track
  const [localParticipantTrack, _setLocalParticipantTrack] = useState({} as any);
  const localParticipantTrackRef = useRef(localParticipantTrack);
  const setLocalParticipantTrack = (data: any) => {
    localParticipantTrackRef.current = data;
    _setLocalParticipantTrack(data);
  };

  // local Audio and Video
  const [localAudioMute, _setLocalAudioMute] = useState(true);
  const localAudioMuteRef = useRef(localAudioMute);
  const setLocalAudioMute = (data: boolean) => {
    localAudioMuteRef.current = data;
    _setLocalAudioMute(data);
  };
  const [localVideoMute, _setLocalVideoMute] = useState(true);
  const localVideoMuteRef = useRef(localVideoMute);
  const setLocalVideoMute = (data: boolean) => {
    localVideoMuteRef.current = data;
    _setLocalVideoMute(data);
  };

  // Mute
  const [localMute, setlocalMute] = useState(true);
  const [volumeMute, setVolumeMute] = useState(false);
  let isStreamUnmuted = false;

  // Dominant Speaker
  const [showDominantSpeaker, setShowDominantSpeaker] = useState(false);
  const [dominantSpeakerID, _setDominantSpeakerID] = useState('');
  const dominantSpeakerIDRef = useRef(dominantSpeakerID);
  const setDominantSpeakerID = (data: any) => {
    dominantSpeakerIDRef.current = data;
    _setDominantSpeakerID(data);
  };

  //Dominant Speaker track
  const [dominantSpeakerTrack, _setDominantSpeakerTrack] = useState({} as any);
  const dominantSpeakerTrackRef = useRef(dominantSpeakerTrack);
  const setDominantSpeakerTrack = (data: any) => {
    dominantSpeakerTrackRef.current = data;
    _setDominantSpeakerTrack(data);
  };

  /* VC Tools */
  
  // Camera and Mic
  const [cameraId, setCameraId] = useState('');
  const [micId, setMicId] = useState('');
  const micIdRef = useRef<string>();
  const cameraIdRef = useRef<string>();
  cameraIdRef.current = cameraId;
  micIdRef.current = micId;
  
  // Background
  const [tempActiveBG, setTempActiveBG] = useState('');
  const [activeBG, _setActiveBG] = useState('none');
  const activeBGRef = useRef(activeBG);
  const setActiveBG = (data: any) => {
    activeBGRef.current = data;
    _setActiveBG(data);
  };

  // ** Not Related **

  // Guests
  const guestTracks: any = [];
  const isGuest = isGuestAuthenticated();
  const { createGuestSessions } = useGuestSessions()

  // White Boarding
  // const { drawLine, drawCircle } = useWhiteBoarding(dataTrack);

  // Show Stream
  const [showStreamUpdate, _setShowStreamUpdate] = useState(1);
  const showStreamUpdateRef = useRef(showStreamUpdate);
  const setShowStreamUpdate = (data: any) => {
    showStreamUpdateRef.current = data;
    _setShowStreamUpdate(data);
  };

  /***************** State (End) *****************/

  const navigateToPage = useNavigateToPage();

  // Hide the remote video and show avatar
  const handleRemoteTrack = (remotetrack: any, remoteparticipant: any) => {
    remotetrack.on('disabled', () => {
      // Audio Disabled
      if (remotetrack.kind === 'audio' && !remotetrack.isEnabled) {
        const audioButtonElement = document.getElementById(remoteparticipant.sid + '_audio');
        if (audioButtonElement) {
          audioButtonElement.className = 'icon overlay-audio-off';
        }
      }

      // Video Disabled
      if (remotetrack.kind === 'video' && !remotetrack.isEnabled) {
        const videoButtonElement = document.getElementById(remoteparticipant.sid + '_video');
        if (videoButtonElement) {
          videoButtonElement.className = 'icon overlay-video-off-tray';

          const remoteparticipantDiv = document.getElementById(remoteparticipant.sid);

          const vtray = document.getElementById('vtray');
          if (remoteparticipantDiv) {
            vtray?.removeChild(remoteparticipantDiv);
            vtray?.appendChild(remoteparticipantDiv);
          }
        }
        const fullscreenButtonElement = document.getElementById(
          remoteparticipant.sid + '_fullscreen',
        );
        if (fullscreenButtonElement) {
          fullscreenButtonElement.className = 'icon overlay-fullscreen-off-tray';
        }

        const activeViewers = roomParticipantsListRef.current;
        //remotetrack.removeProcessor(remotetrack.processor);
        showProfileImageRef.current(activeViewers, remoteparticipant.sid, remoteparticipant.identity);
      }

      if (remotetrack.kind === 'video' && dominantSpeakerIDRef.current === remoteparticipant.sid) {
        setDominantSpeakerTrack(remotetrack);
        setDominantSpeakerID(dominantSpeakerIDRef.current);
        setShowStreamUpdate(showStreamUpdateRef.current + 1);
      }
    });

    remotetrack.on('enabled', () => {
      let audioTrackEnabled = false,
        videoTrackEnabled = false;
      // Audio Enabled
      if (remotetrack.kind === 'audio' && remotetrack.isEnabled) {
        audioTrackEnabled = true;
        const audioButtonElement = document.getElementById(remoteparticipant.sid + '_audio');
        if (audioButtonElement) {
          audioButtonElement.className = 'icon overlay-audio-on';
        }
      }

      // Video Enabled
      if (remotetrack.kind === 'video' && remotetrack.isEnabled) {
        videoTrackEnabled = true;
        const videoButtonElement = document.getElementById(remoteparticipant.sid + '_video');
        const fullscreenButtonElement = document.getElementById(
          remoteparticipant.sid + '_fullscreen',
        );
        if (videoButtonElement) {
          const remoteparticipantDiv = document.getElementById(remoteparticipant.sid);

          const vtray = document.getElementById('vtray');

          if (vtray?.children[2]) {
            if (remoteparticipantDiv) {
              // list of all un muted video trays
              var audEle = document.querySelectorAll('.overlay-video-on-tray');
              if (audEle.length > 0) {
                var audId = audEle[audEle.length - 1].id;
                audId = audId.replace('_video', '');

                //last un muted video tray
                var lastVideoOnPerson = document.getElementById(audId);
                var isnextDiv = lastVideoOnPerson?.nextSibling;
                if (isnextDiv != null) {
                  vtray?.insertBefore(remoteparticipantDiv, isnextDiv);
                  videoButtonElement.className = 'icon overlay-video-on-tray';
                  if (fullscreenButtonElement) {
                    fullscreenButtonElement.className = 'icon overlay-fullscreen-on-tray';
                  }
                } else {
                  vtray?.removeChild(remoteparticipantDiv);
                  vtray?.appendChild(remoteparticipantDiv);
                  videoButtonElement.className = 'icon overlay-video-on-tray';
                  if (fullscreenButtonElement) {
                    fullscreenButtonElement.className = 'icon overlay-fullscreen-on-tray';
                  }
                }
              } else {
                //if everyone is in mute then we need to move 2nd tray
                const localparticipant = vtray.children[2];
                localparticipant.parentNode?.insertBefore(
                  remoteparticipantDiv,
                  localparticipant.nextSibling,
                );
                videoButtonElement.className = 'icon overlay-video-on-tray';
                if (fullscreenButtonElement) {
                  fullscreenButtonElement.className = 'icon overlay-fullscreen-on-tray';
                }
              }
            }
          } else {
            videoButtonElement.className = 'icon overlay-video-on-tray';
            if (fullscreenButtonElement) {
              fullscreenButtonElement.className = 'icon overlay-fullscreen-on-tray';
            }
          }
        }

        // Remove profile image when remote participant video unmuted
        const activeViewers = roomParticipantsListRef.current;
        hideProfileImageRef.current(activeViewers, remoteparticipant.sid, remoteparticipant.identity);
      }

      if (audioTrackEnabled && videoTrackEnabled) {
        const nameTextElement = document.getElementById(remoteparticipant.sid + '_name');
        if (nameTextElement) {
          nameTextElement.className = 'overlay-text prevent-textcopy';
        }
      }

      if (remotetrack.kind === 'video' && dominantSpeakerIDRef.current === remoteparticipant.sid) {
        setDominantSpeakerTrack(remotetrack);
        setDominantSpeakerID(dominantSpeakerIDRef.current);
        setShowStreamUpdate(showStreamUpdateRef.current + 1);
      }
    });
  };

  const dominantSpeakerChanged = (participant: any) => {
    let track: any = {};

    if (!participant) {
      setDominantSpeakerID('');
      setDominantSpeakerTrack({});
      return;
    }

    participant.tracks.forEach((publication: any) => {
      if (publication.kind === 'video') {
        track = publication.track;
      }
    });

    setDominantSpeakerTrack(track);

    setDominantSpeakerID(participant.sid);
    // Get speaker div
    const dominatSpeakerDiv = document.getElementById(participant.sid);

    const vtray = document.getElementById('vtray');
    if (dominatSpeakerDiv && vtray) {
      // Switch only if dominant speaker not in screen
      if (!isViewInScreen(dominatSpeakerDiv)) {
        vtray.removeChild(dominatSpeakerDiv);

        // Switch to second place.
        const localparticipant = vtray.children[2];
        localparticipant.parentNode?.insertBefore(dominatSpeakerDiv, localparticipant.nextSibling);
      } else {
        console.log('In the screen, no switch');
      }
    }
  };

  const participantDisconnected = (participant: any) => {
    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({
      sid: participant.sid,
      identity: participant.identity,
      state: participant.state,
    });

    if (get(participant.identity.split('#'), '0', '').toLowerCase() === 'guestviewer') {
      disconnectedGuests.push(get(participant, 'sid', ''));
    }

    setRoomParticipantsList(roomParticipantsList);

    // Play chime
    if (user_chime_preferance) {
      const audioEl: HTMLAudioElement = document.getElementsByClassName(
        'audio-element',
      )[0] as HTMLAudioElement;
      audioEl.play();
    }

    const participantSidElem = document.getElementById(participant.sid);
    if (participantSidElem !== null) {
      participantSidElem.remove();
    }
    setUsersUpdated(true);
  };

  const participantConnected = (participant: any) => {
    const checkAutoAllow = sessionStorage.getItem('auto_allow');

    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({
      sid: participant.sid,
      identity: participant.identity,
      state: participant.state,
    });
    setRoomParticipantsList(roomParticipantsList);

    const roomParticipants = [...roomParticipantsWithTracks];
    roomParticipants.push(participant);
    setRoomParticipantsWithTracks(roomParticipants);

    // Play chime
    if (user_chime_preferance) {
      const audioEl: HTMLAudioElement = document.getElementsByClassName(
        'audio-element',
      )[0] as HTMLAudioElement;
      audioEl.play();
    }

    const div = document.createElement('div');
    div.id = participant.sid;
    div.style.width = '200px';
    div.style.minWidth = '200px';

    let allowedGuest: any = sessionStorage.getItem('allowedGuest') || {};
    allowedGuest = typeof allowedGuest === 'string' ? JSON.parse(allowedGuest) : allowedGuest;

    if (
      checkAutoAllow === 'false' &&
      get(participant.identity.split('#'), '0', '').toLowerCase() === 'guestviewer' &&
      (!allowedGuest[participant.identity] ||
        (!!allowedGuest[participant.identity] &&
          allowedGuest[participant.identity].status !== 'allowed'))
    ) {
      div.style.display = 'none';
    }

    if (
      checkAutoAllow === 'true' &&
      get(participant.identity.split('#'), '0', '').toLowerCase() === 'guestviewer'
    ) {
      // console.log("this guest joined",participant.identity)
      let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
      allowedGuestSession =
        typeof allowedGuestSession === 'string'
          ? JSON.parse(allowedGuestSession)
          : allowedGuestSession;
      // Add the guest user into session
      allowedGuestSession[participant.identity] = { sid: participant.sid, status: 'allowed' };
      sessionStorage.setItem(
        'allowedGuest',
        typeof allowedGuestSession === 'string'
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession),
      );
    }
    // Share the guest sessions to all the users if the user as a publisher
    const isPublisher = sessionStorage.getItem('publisher') || null;

    div.setAttribute('class', 'videoparticipant');

    //vtray-overlay
    const childDiv = document.createElement('div');
    childDiv.id = participant.sid + '_overlay';
    childDiv.className = 'overlay-vtray';

    let identity = participant.identity.split('_');
    const nameTextElement = document.createElement('p');
    nameTextElement.id = participant.sid + '_name';
    nameTextElement.className = 'overlay-text-red prevent-textcopy';

    //Show maximum 15 charecters
    let string = identity[1];
    let length = 11;
    let trimmedString = string;
    if (string.length > 11) {
      trimmedString = string.substring(0, length).concat('...');
      nameTextElement.textContent = trimmedString;
    } else {
      nameTextElement.textContent = trimmedString;
    }

    // Audio Button
    const audioButtonElement = document.createElement('i');
    audioButtonElement.id = participant.sid + '_audio';

    // Video Button (Default Muted)
    const videoButtonElement = document.createElement('i');
    videoButtonElement.id = participant.sid + '_video';

    // Fullscreen Button
    const fullscreenButtonElement = document.createElement('i');
    fullscreenButtonElement.id = participant.sid + '_fullscreen';

    let audioEnabled = false,
      videoEnabled = false;

    participant.tracks.forEach((publication: any) => {
      if (publication.kind === 'audio') {
        if (publication.isTrackEnabled) {
          audioButtonElement.className = 'icon overlay-audio-on';
          audioEnabled = true;
        } else {
          audioButtonElement.className = 'icon overlay-audio-off';
        }
      }
      if (publication.kind === 'video') {
        if (publication.isTrackEnabled) {
          videoButtonElement.className = 'icon overlay-video-on-tray';
          fullscreenButtonElement.className = 'icon overlay-fullscreen-on-tray';
          videoEnabled = true;
        } else {
          //set default image profiles
          videoButtonElement.className = 'icon overlay-video-off-tray';
          fullscreenButtonElement.className = 'icon overlay-fullscreen-off-tray';
          const activeViewers = roomParticipantsListRef.current;
          setTimeout(function () {
            showProfileImageRef.current(activeViewers, participant.sid, participant.identity);
          }, 250);
        }
      }
    });

    childDiv.appendChild(nameTextElement);
    childDiv.appendChild(audioButtonElement);
    childDiv.appendChild(videoButtonElement);
    childDiv.appendChild(fullscreenButtonElement);

    fullscreenButtonElement.onclick = () => {
      // Take participant's video to fullscreen mode
      const elem: any = childDiv.parentElement?.getElementsByTagName('video')[0];

      switchToFullScreen(elem, participant.sid);
    };

    participant.on('trackSubscribed', (track: any) =>
      trackSubscribed(div, track),
    );

    // Handle RemoteTracks published after connecting to the Room.
    participant.on('trackPublished', (track: any) => {
      if (track && track.trackName) {
        participant.tracks.forEach((publication: any) => {
          if (track.trackName === publication.trackName && publication.kind === 'video') {
            if (publication.isSubscribed) {
              handleRemoteTrack(publication.track, participant);
            }
            publication.on('subscribed', (track: any) => handleRemoteTrack(track, participant));
          }
        });
      }
    });

    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        handleRemoteTrack(publication.track, participant);
      }
      publication.on('subscribed', (track: any) => handleRemoteTrack(track, participant));
    });

    div.appendChild(childDiv); //appending vtray-overlay
    const vtray = document.getElementById('vtray');
    vtray?.appendChild(div);
    setUsersUpdated(true);

    if (isPublisher === 'true' && dataTrackRef.current) {
      let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
      allowedGuestSession =
        typeof allowedGuestSession === 'string'
          ? JSON.parse(allowedGuestSession)
          : allowedGuestSession;
      if (
        !!participant.identity &&
        get(participant.identity.split('#'), '0', '').toLowerCase() === 'guestviewer'
      ) {
        allowedGuestSession[participant.identity] = {
          sid: participant.sid,
          status:
            checkAutoAllow?.toLowerCase() === 'true' ||
            (!!allowedGuestSession[participant.identity] &&
              !!allowedGuestSession[participant.identity].status &&
              allowedGuestSession[participant.identity].status === 'allowed')
              ? 'allowed'
              : 'waiting',
        };
        sessionStorage.setItem('allowedGuest', JSON.stringify(allowedGuestSession));
      }
    
      if (
        !!participant.identity &&
        get(participant.identity.split('#'), '0', '').toLowerCase() === 'guestviewer'
      ) {
        setTimeout(() => {
          dataTrackRef.current.send(
            JSON.stringify({
              allowedGuestSession: allowedGuestSession,
            }),
          );
        }, 2000);
      } else {
        setTimeout(() => {
          dataTrackRef.current.send(
            JSON.stringify({
              allowedGuestSession: allowedGuestSession,
            }),
          );
        }, 5000);
      }
    }
  };

  const unmuteStream = () => {
    const elem: any = document.getElementsByTagName('video')[0];
    if (elem) {
      elem.muted = false;
    }
  };

  const trackSubscribed = (div: any, track: any)  => {
    console.log('**** trackSubscribed')
    if (track.kind === 'audio' || track.kind === 'video') {
    const audioVideoAllowed = isAudioVideoAllowedFrGuest() ;
    if (audioVideoAllowed) {
        console.log("***** Append Track")
        div.appendChild(track.attach());
      } else {
        guestTracks.push({
          [track.kind]: track,
          div,
        });
      }

      const preview = div.getElementsByTagName('video');

      //TO DO: I know this loop is junck code, Need to change
      for (let i = 0; i < preview.length; i++) {
        if (i > 0) {
          div.removeChild(preview[i]);
        }
      }
    }

    if (track.kind === 'data') {
      const canvas: any = document.getElementById('canvas');
      track.on('message', (data: any) => {
        const {
          isPainting,
          color,
          mousePosition,
          newMousePosition,
          incoming_canvas,
          erase,
          clear,
          globalMute,
          nameTextId,
          className,
          clearAnnotationChat,
          clearGroupChat,
          streamId,
          streamName,
          isCircle,
          allowedGuestSession,
          auto_allow,
          watermark,
          endAll
        } = JSON.parse(data);

        if (!!allowedGuestSession) {
          sessionStorage.setItem(
            'allowedGuest',
            typeof allowedGuestSession === 'string'
              ? allowedGuestSession
              : JSON.stringify(allowedGuestSession),
          );

          const allowedGuestSessionType: any =
            typeof allowedGuestSession === 'string'
              ? JSON.stringify(allowedGuestSession)
              : allowedGuestSession;
          if (lParticipantRef.current.identity.split('#')[0] === 'guestviewer') {
            updateGuestSessioninDB.current(allowedGuestSession);
          }

          Object.keys(allowedGuestSessionType).forEach((guestIdentity: string) => {
            const guestDiv: any = document.getElementById(
              allowedGuestSessionType[guestIdentity].sid,
            );
            if (guestDiv && allowedGuestSessionType[guestIdentity].status === 'allowed') {
              guestDiv.style.display = 'block';
            }
          });

          if (lParticipantRef.current.identity.split('#')[0] !== 'guestviewer') {
            setOpen(false);
          } else if (!allowedGuestSessionType[lParticipantRef.current.identity]) {
            signOutGuest.current();
          } else {
            //TODO: Need to fix the guestAttached session
            const guestAttached = sessionStorage.getItem('guestAttached');
            const openFlag =
              String(allowedGuestSessionType[lParticipantRef.current.identity].status) !==
                'allowed' && String(guestAttached) !== 'true';
            //console.log("openFlag", openFlag);
            if (openFlag === true) {
              setVolumeMute(openFlag);
            }
            
            if(allowedGuestSessionType[lParticipantRef.current.identity].status === 'allowed' && !isStreamUnmuted){
              setVolumeMute(false);
              unmuteStream();
              isStreamUnmuted = true;
            }
            setOpen(openFlag);

            if (!openFlag && isGuest && guestAttached !== 'true') {
              //console.log("guestTracks", guestTracks);
              guestTracks.forEach((track: any) => {
                //console.log("track", track);
                const getDiv = document.contains(track.div);
                if (track.video && getDiv) {
                  track.div.appendChild(track?.video?.attach());
                }
                if (track.audio && getDiv) {
                  track.div.appendChild(track?.audio?.attach());
                }
              });
              sessionStorage.setItem('guestAttached', 'true');
            }
          }
        }

        if(endAll && !isGuest){
          cleanupRef.current()
          sessionStorage.removeItem('allowedGuest');
          setStreamLabel('');
          setStream({});
          const user = auth.getUser();
          const uid = get(user, 'uid', '');
          navigateToPage('/showselection', { state: { userId: uid } },true);
        }

        if (!!auto_allow) {
          sessionStorage.setItem('auto_allow', auto_allow);
        }

        if (streamId && streamName) {
          const curr_stream_label_span: any = document.getElementById(streamId);
          curr_stream_label_span.innerText = streamName;
        }

        if (watermark) {
          setWatermarkDetails(watermark);
        }

        if (isPainting && canvas) {
          const curr_canvas: any = document.getElementById('canvas');
          let bb = curr_canvas?.getBoundingClientRect();
          const bbWidth = get(bb, 'width', 0);
          const bbHeight = get(bb, 'height', 0);
          const curr_canvas_width = bbWidth;
          const curr_canvas_height = bbHeight;

          let oldMousePos: Coordinate, newMousePos: Coordinate;

          if (curr_canvas) {
            oldMousePos = {
              x: (mousePosition.x / incoming_canvas.width) * curr_canvas_width,
              y: (mousePosition.y / incoming_canvas.height) * curr_canvas_height,
            };
            newMousePos = {
              x: (newMousePosition.x / incoming_canvas.width) * curr_canvas_width,
              y: (newMousePosition.y / incoming_canvas.height) * curr_canvas_height,
            };
            if (isCircle) {
              drawCircle(color, oldMousePos, newMousePos);
            } else {
              drawLine(color, oldMousePos, newMousePos, erase);
            }
            canvas.className = 'canvas-pencil-on';
          }
        }

        if (clear) {
          const canvas: any = document.getElementById('canvas');
          let context = canvas.getContext('2d');
          const canvasWidth = get(canvas, 'width', 0);
          const canvasHeight = get(canvas, 'height', 0);
          context.clearRect(0, 0, canvasWidth, canvasHeight);
        }

        if (globalMute) {
          setlocalMute(true);
          const locParticipant = lParticipantRef.current;
          const audioButton = document.getElementById(locParticipant.sid + '_audio');
          // Name
          const nameTextElement = document.createElement('p');
          if (audioButton) {
            audioButton.className = 'icon overlay-audio-off';
            nameTextElement.className = 'overlay-text-red prevent-textcopy';
          }

          locParticipant.tracks.forEach((publication: any) => {
            if (publication.kind === 'audio') {
              if (publication.isTrackEnabled) {
                publication.track.disable();
              }
            }
          });
        }

        // unmute other participants
        if (globalMute === false) {
          setlocalMute(false);
          const locParticipant = lParticipantRef.current;
          const audioButton = document.getElementById(locParticipant.sid + '_audio');
          // Name
          const nameTextElement = document.createElement('p');
          if (audioButton) {
            audioButton.className = 'icon overlay-audio-on';
            nameTextElement.className = 'overlay-text prevent-textcopy';
          }

          locParticipant.tracks.forEach((publication: any) => {
            if (publication.kind === 'audio') {
              if (!publication.isTrackEnabled) {
                publication.track.enable();
              }
            }
          });
        }

        if (nameTextId && className) {
          const nameTextElement = document.getElementById(nameTextId);
          if (nameTextElement) {
            nameTextElement.className = className;
          }
        }

        if (clearAnnotationChat) {
          setAnnotateMessages([]);
        }

        if (clearGroupChat) {
          setMessages([]);
        }
      });
    }
  };

  const trackUnsubscribed = (track: any) => {
    if (track.kind === 'audio' || track.kind === 'video') {
      track.detach().forEach((element: any) => element.remove());
    }
  };

  const openBGSelectionModal = () => {
    setTempActiveBG(activeBGRef.current);
    setLocalParticipantBGModalOpen(true);
  };

  const toggleAudioMute = (
    localParticipant: any,
    audioButtonElement: any,
    nameTextElement: any,
  ) => {
    localParticipant.tracks.forEach((publication: any) => {
      if (publication.kind === 'audio') {
        if (publication.isTrackEnabled) {
          publication.track.disable();
          audioButtonElement.className = 'icon overlay-audio-off';
          setLocalAudioMute(true);
          setlocalMute(true);
        } else {
          if (micIdRef.current != '') {
            publication.track.enable();
            if (micIdRef.current) {
              changeMicrophoneTrack(micIdRef.current);
            }
            setMicId('');
          } else {
            publication.track.enable();
          }
          audioButtonElement.className = 'icon overlay-audio-on';
          setLocalAudioMute(false);
          setlocalMute(false);
        }

        if (localAudioMuteRef.current || localVideoMuteRef.current) {
          nameTextElement.className = 'overlay-text-red prevent-textcopy';
          dataTrackRef.current.send(
            JSON.stringify({
              nameTextId: nameTextElement.id,
              className: nameTextElement.className,
            }),
          );
        } else {
          nameTextElement.className = 'overlay-text prevent-textcopy';
          dataTrackRef.current.send(
            JSON.stringify({
              nameTextId: nameTextElement.id,
              className: nameTextElement.className,
            }),
          );
        }
      }
    });
  };

  const switchToFullScreen = (elem: any, sid: any) => {
    if (!elem) {
      return;
    }

    // This looks like the easiest way to determine whether the video we're trying
    // to switch to full screen is currently muted. I said "easy", not "good."
    const mutedImage = document.getElementById(sid + '_image');

    if (mutedImage) {
      return;
    }

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const setRoomParticipantsList = (data: any) => {
    roomParticipantsListRef.current = data;
    let connectionStatusArr: any = [],
      connectionStatusObjArr: any = [];
    data.forEach((item: any) => {
      let member = item.identity.substring(
        item?.identity.lastIndexOf('#') + 1,
        item?.identity.lastIndexOf('_'),
      );
      let statusObj: any = { user: member, status: item.state };
      let index = connectionStatusArr.indexOf(member);
      if (index === -1) {
        connectionStatusArr.push(member);
        connectionStatusObjArr.push(statusObj);
      } else {
        connectionStatusObjArr[index] = statusObj;
      }
    });
    roomParticipantsConnectionStatusRef.current = connectionStatusObjArr;
    _setRoomParticipantsConnectionStatus(connectionStatusObjArr);
    _setRoomParticipantsList(data);
  };

  const createLocalParticipant = (
    localParticipant: any,
    showOverlay: any,
  ) => {
    const roomParticipantsList = [...roomParticipantsListRef.current];

    roomParticipantsList.push({
      sid: localParticipant.sid,
      identity: localParticipant.identity,
      state: localParticipant.state,
    });

    setRoomParticipantsList(roomParticipantsList);

    const div = document.createElement('div');
    div.id = localParticipant.sid;
    div.style.width = '200px';
    div.style.minWidth = '200px';
    div.setAttribute('class', 'videoparticipant');
    console.log(div)

    //Local participant profile image when video mute intially
    const user_profile_img = document.getElementById(localParticipant.sid + '_image');
    if (!user_profile_img) {
      const backupName = document.createElement('div');
      backupName.textContent = localParticipant.identity.split('_')[1];
      backupName.className = 'backup-name';
      backupName.id = localParticipant.sid + '_backupName';
      div.insertBefore(backupName, div.childNodes[0]);
    }

    //vtray-overlay
    const childDiv = document.createElement('div');
    childDiv.id = localParticipant.sid + '_overlay';
    childDiv.className = 'overlay-vtray';
  

    // Name
    let identity = localParticipant.identity.split('_');
    const nameTextElement = document.createElement('p');
    nameTextElement.id = localParticipant.sid + '_name';
    nameTextElement.className = 'overlay-text-red prevent-textcopy';

    //Show max 15 charecters
    let string = identity[1];
    let length = 11;
    let trimmedString = string;
    if (string.length > 11) {
      trimmedString = string.substring(0, length).concat('...');
      nameTextElement.textContent = trimmedString;
    } else {
      nameTextElement.textContent = trimmedString;
    }

    // Audio Button
    const audioButtonElement = document.createElement('i');
    audioButtonElement.id = localParticipant.sid + '_audio';
    audioButtonElement.className = 'icon overlay-audio-off';

    // Video Button
    const videoButtonElement = document.createElement('i');
    videoButtonElement.id = localParticipant.sid + '_video';
    videoButtonElement.className = 'icon overlay-video-off-tray';

    // Fullscreen Button
    const fullscreenButtonElement = document.createElement('i');
    fullscreenButtonElement.id = localParticipant.sid + '_fullscreen';
    fullscreenButtonElement.className = 'icon overlay-fullscreen-off-tray';

    // Signal Button
    const signalButtonElement = document.createElement('i');
    signalButtonElement.id = localParticipant.sid + '_signal';
    signalButtonElement.className = 'icon overlay-signal overlay-high-signal';

    //More Option
    const moreButtonElement = document.createElement('i');
    moreButtonElement.id = localParticipant.sid + '_background';
    moreButtonElement.className = 'icon menu-vertical';
    moreButtonElement.title = 'Change Video Background';
    moreButtonElement.style.display = 'none';

    childDiv.appendChild(nameTextElement);
    // div.appendChild(signalButtonElement);
    childDiv.appendChild(audioButtonElement);
    childDiv.appendChild(videoButtonElement);
    childDiv.appendChild(fullscreenButtonElement);
    childDiv.appendChild(moreButtonElement);
    console.log(childDiv)

    fullscreenButtonElement.onclick = () => {
      // Take local participant's video to fullscreen mode
      const elem: any = childDiv.parentElement?.getElementsByTagName('video')[0];

      switchToFullScreen(elem, localParticipant.sid);
    };

    //Delay backslash click
    let delayBackslasClick = (() => {
      let timer: any;
      return function (callback: any, ms: number) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();

    // Listen to audio mute key
    document.addEventListener('keyup', (e) => {
      let update = true;
      const elem: any = e;
      if (!!elem.path) {
        if (
          !!elem.path[2] &&
          !!elem.path[2].classList &&
          elem.path[2].classList.contains('chat-msg-input')
        ) {
          update = false;
        }
        if (
          !!elem.path[0] &&
          !!elem.path[0].classList &&
          elem.path[0].classList.contains('watermarkInput-field')
        ) {
          update = false;
        }
      }
      if (e.code === 'BracketRight' && update) {
        const elem: any = document.getElementsByTagName('video')[0];
        elem.muted = !elem.muted;
        setVolumeMute(elem.muted);
      }
      if (e.code === 'Backquote' && update) {
        toggleAudioMute(localParticipant, audioButtonElement, nameTextElement);
      }
      if (e.code === 'Backslash' && update) {
        delayBackslasClick(function () {
          videoButtonClick();
        }, 500);
      }
    });

    // Listening to Audio and Video Button Click
    audioButtonElement.onclick = () => {
      toggleAudioMute(localParticipant, audioButtonElement, nameTextElement);
    };

    moreButtonElement.onclick = () => {
      openBGSelectionModal();
    };

    function videoButtonClick() {
      console.log("debug => videoButtonClick")
      console.log(localParticipant)
      localParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === 'video') {
          setLocalParticipantTrack(publication.track);
          if (publication.isTrackEnabled) {
            console.log(publication)
            //adding profile image when video muted
            // const image = document.createElement("img");
            const backupName = document.createElement('div');
            backupName.textContent = localParticipant.identity.split('_')[1];
            backupName.className = 'backup-name';
            backupName.id = localParticipant.sid + '_backupName';
            backupName.style.display = 'none';

            setTimeout(function () {
              backupName.style.display = 'flex';
              div.insertBefore(backupName, div.childNodes[0]);
            }, 250);

            publication.track.disable();
            videoButtonElement.className = 'icon overlay-video-off-tray';
            setLocalVideoMute(true);
            fullscreenButtonElement.className = 'icon overlay-fullscreen-off-tray';

            //Hiding virtual background button when video muted
            const more_button_ele: any = document.getElementById(
              localParticipant.sid + '_background',
            );
            if (more_button_ele) {
              more_button_ele.style.display = 'none';
            }
          } else {
            if (localParticipant.networkQualityLevel >= 2) {
              //delete profile image when video unmute
              const hideBackupName = document.getElementById(localParticipant.sid + '_backupName');
              if (hideBackupName) {
                div.removeChild(hideBackupName);
              }
              // changeCameraTrack(cameraIdRef.current);
              setTimeout(function () {
                publication.track.enable();
              }, 250);
              videoButtonElement.className = 'icon overlay-video-on-tray';
              setLocalVideoMute(false);
              fullscreenButtonElement.className = 'icon overlay-fullscreen-on-tray';

              //Enable virtual background button when video unmuted
              const more_button_ele: any = document.getElementById(
                localParticipant.sid + '_background',
              );
              if (more_button_ele) {
                more_button_ele.style.display = 'block';
              }
            } else {
              const msg =
                'Your network quality is less than optimal, so we are restricting you from enabling your video!';
              if (showOverlay) {
                showOverlay(msg);
              }
            }
          }

          if (localAudioMuteRef.current || localVideoMuteRef.current) {
            nameTextElement.className = 'overlay-text-red prevent-textcopy';
            dataTrackRef.current.send(
              JSON.stringify({
                nameTextId: nameTextElement.id,
                className: nameTextElement.className,
              }),
            );
          } else {
            nameTextElement.className = 'overlay-text prevent-textcopy';
            dataTrackRef.current.send(
              JSON.stringify({
                nameTextId: nameTextElement.id,
                className: nameTextElement.className,
              }),
            );
          }
        }
      });
    }

    videoButtonElement.onclick = () => {
      videoButtonElement.classList.add('disabled-button');
      setTimeout(function () {
        videoButtonClick();
        videoButtonElement.classList.remove('disabled-button');
      }, 1000);
    };

    localParticipant.tracks.forEach((publication: any) => {
      trackSubscribed(div, publication.track);
    });

    localParticipant.on('trackUnsubscribed', trackUnsubscribed);
    div.appendChild(childDiv); //appending the background-overlay-vedio-tray
    const vtray = document.getElementById('vtray');
    vtray?.appendChild(div);
  };

  const isAudioVideoAllowedFrGuest = () => {
    try {
      const localIdentity = get(lParticipantRef.current,'identity','');
      const checkAutoAllow = sessionStorage.getItem('auto_allow');
      
      // const isGuest: boolean = isGuestAuthenticated();
    
      const sessionData: any = sessionStorage.getItem('allowedGuest') || '';
  
      const parseResult: any = !!sessionData ? Object.keys(JSON.parse(sessionData)) : [];
  
      //TODO: Change the code with if cond
      const guestIsAllowed =
        isGuest && !!parseResult
          ? parseResult.includes(localIdentity) &&
            JSON.parse(sessionData)[localIdentity] &&
            JSON.parse(sessionData)[localIdentity].status === 'allowed'
          : false;
      
          const audioVideoAllowed = isGuest&& !guestIsAllowed ? false : true;

      return audioVideoAllowed;
    } catch(err: any) {
      throw err
    }
  }
  

  const manageRoom = (
    room: any,
    vcParticipants: any,
    cleanUpWhenUserLeftRoom: any,
    showOverlay: any,
  ) => {
    try {
      // LocalParticipant in the Room
      const localParticipant = room.localParticipant;

       //Create session for the local guest participant & update them in db
       if (isGuest) {
        const sessionsCreated =  createGuestSessions(localParticipant);
        const allowedGuestSession = sessionStorage.getItem('allowedGuest');
        if (sessionsCreated){
           updateGuestSessioninDB.current(
            typeof allowedGuestSession === 'string'
              ? JSON.parse(allowedGuestSession)
              : allowedGuestSession,
          );
        }
      }

      /* CONNECT */
      setLocalParticipant(localParticipant);
      setLLocalParticipant(localParticipant);

      createLocalParticipant(localParticipant, showOverlay);

      room.localParticipant.on('networkQualityLevelChanged', (level: number) => {
        const signalButtonElement = document.getElementById(room.localParticipant.sid + '_signal');

        if (signalButtonElement) {
          if (localParticipant.networkQualityLevel <= 2) {
            signalButtonElement.className = 'icon overlay-signal overlay-low-signal';
          } else if (
            localParticipant.networkQualityLevel > 2 &&
            localParticipant.networkQualityLevel <= 4
          ) {
            signalButtonElement.className = 'icon overlay-signal overlay-medium-signal';
          } else if (localParticipant.networkQualityLevel > 4) {
            signalButtonElement.className = 'icon overlay-signal overlay-high-signal';
          }
        }
      });

      // Any Participants already connected to the Room
      room.participants.forEach(participantConnected);

      // New participant as they connect to the room (Connection Events)
      room.on('participantConnected', (participant: any) => {
        participantConnected(participant);

        if (!!participant && vcParticipants) {
          //show the participant joined pubnub
          vcParticipants(participant, 'joined');
        }
      });

      /* DISCONNECT */
      room.on('participantDisconnected', (participant: any) => {
        participantDisconnected(participant);
        if (!!participant) {
          //show the participant joined pubnub
          vcParticipants(participant, 'left');
        }
      });

      room.on('dominantSpeakerChanged', (participant: any) => {
        dominantSpeakerChanged(participant);
      });

      // You can disconnect from a Room, Other will receive "participantDisconnected" event
      room.on('disconnected', (room: any) => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === 'audio' || publication.kind === 'video') {
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element: any) => element.remove());
          }
        });

        // Remove local view
        const localParticipantDiv = document.getElementById(localParticipant.sid);
        localParticipantDiv?.parentNode?.removeChild(localParticipantDiv);

        // Remove others from vtray
        room.participants.forEach((participant: any) => {
          const participantSidElem = document.getElementById(participant.sid);
          if (participantSidElem !== null) {
            participantSidElem.remove();
          }
        });

        // Stop camera and mic
        room.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === 'audio' || publication.kind === 'video') {
            publication.track.stop();
            publication.unpublish();
          }
        });

        // Cleanup
        if (cleanUpWhenUserLeftRoom) {
          cleanUpWhenUserLeftRoom();
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      //setVtrayRendering(true)
    }
  };

  const setupLocalTracks = async (user_id:string, vc_id:string) => {
    try {
      let localTracks: LocalTrack[];

      try {
        localTracks = await createLocalTracks({
          audio: { noiseCancellationOptions,  autoGainControl: false },
          video: true,
        });
      } catch (err: any) {
        try {
          localTracks = await createLocalTracks({
            audio: {autoGainControl: false},
            video: true,
          });
        } catch (err: any) {
          try {
            localTracks = await createLocalTracks({
              audio: {autoGainControl: false},
              video: false,
            });
            Sentry.captureMessage(`Video initialization failed for user :${user_id} for room: ${vc_id}`)
          } catch (err: any) {
            localTracks = await createLocalTracks({
              audio: false,
              video: false,
            });
            Sentry.captureMessage(`Video & audio initialization failed for user :${user_id} for room: ${vc_id}`)
          }
        }
      }
      console.log(localTracks)
      return localTracks;
    } catch (err: any) {
      throw err;
    }
  };

  const getTwilioVGT = async (
    twilio_room_name: string,
    show_id: string,
    identity: string,
    accessToken: string,
  ) => {
    try {
      const data = {
        api: api.vc.getTwilioVGT,
        queryParam: {
          show_id,
          identity: identity,
          roomName: twilio_room_name,
        },
      };
      const token: string = await backend.fetch(data, accessToken);
      return token;
    } catch (err: any) {
      throw err;
    }
  };

  const joinTwilioRoom = async (
    stream_vc_room: any,
    identity: string,
    accessToken: string,
    show_id: string,
    gotDevices: any,
    vcParticipants: any,
    cleanUpWhenUserLeftRoom: any,
    showOverlay: any,
  ) => {
    const traceTrack = trace(performance, "VideoTilesLoader");
    try {
      traceTrack.start()
      if (!stream_vc_room) {
        return;
      }
      console.log('***** Join twilio room****');

      const twilio_room_name: string = stream_vc_room.show_vc_room_id;
    
      // 1. Get VGT
      const token: string = await getTwilioVGT(twilio_room_name, show_id, identity, accessToken);

      // 2. Create Local Tracks at the time of room creation
      const localTracks: LocalTrack[] = await setupLocalTracks(identity,twilio_room_name);

      // 3. Merge all tracks
      const tracks = localTracks.concat(dataTrackRef.current);
      // 4. Query local media
      navigator.mediaDevices.enumerateDevices().then(gotDevices);

      // 5. Create Room
      const room: any = await connect(token, {
        name: twilio_room_name,
        tracks: tracks,
        video: { height: 720, frameRate: 24, width: 1280 },
        bandwidthProfile: {
          video: {
            mode: 'collaboration',
            dominantSpeakerPriority: 'standard',
            contentPreferencesMode: 'auto',
            clientTrackSwitchOffControl: 'auto'
          },
        },
        dominantSpeaker: true,
        maxAudioBitrate: 16000, //For music remove this line
        preferredVideoCodecs: 'auto',
        networkQuality: {
          local: 1,
          remote: 1,
        },
      });

      if (room) {
        console.log("debug => Room")
        console.log(room)

        setTRoom(room);

        // 6. Disable audio and video by default
        localTracks.forEach((track: any) => {
          if (track.isEnabled) {
            track.disable();
          }
        });

        // Manage Room
        manageRoom(
          room,
          vcParticipants,
          cleanUpWhenUserLeftRoom,
          showOverlay,
        );
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      traceTrack.stop()
    }
  };

  return {
    troom,
    troomRef,
    setTRoom,
    localMute,
    setlocalMute,
    localAudioMute,
    localAudioMuteRef,
    localVideoMute,
    localVideoMuteRef,
    setLocalVideoMute,
    volumeMute,
    setVolumeMute,
    lParticipantRef,
    localParticipantTrackRef,
    setLocalParticipantTrack,
    roomParticipantsList,
    roomParticipantsListRef,
    roomParticipantsConnectionStatus,
    roomParticipantsConnectionStatusRef,
    joinTwilioRoom,
    participantConnected,
    trackSubscribed,
    trackUnsubscribed,
    showDominantSpeaker,
    dominantSpeakerIDRef,
    dominantSpeakerTrackRef,
    showStreamUpdateRef,
    setShowDominantSpeaker,
    setCameraId,
    setMicId,
    cameraIdRef,
    setTempActiveBG,
    setActiveBG,
    activeBGRef,
    tempActiveBG
  };
};
export default useTwilioVC;
