import { isGuestAuthenticated } from '../../../Utils/guestHelpers';
import { Room } from 'twilio-video';
import { get } from '../../../Utils/helpers';

export default function useGuestSessions() {
  const createGuestSessions = (localParticipantRef: {}, showVCroomId?: string) => {
    const checkAutoAllow = sessionStorage.getItem('auto_allow');
    const localIdentity = get(localParticipantRef, 'identity', '');
    const localParticipantSid = get(localParticipantRef, 'sid', '');
    const isGuest: boolean = isGuestAuthenticated();
    if (isGuest && checkAutoAllow === 'true') {
      sessionStorage.setItem('localIdentity', localIdentity);

      let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};

      allowedGuestSession =
        typeof allowedGuestSession === 'string'
          ? JSON.parse(allowedGuestSession)
          : allowedGuestSession;

      if (!!localIdentity) {
        allowedGuestSession[localIdentity] = {
          sid: localParticipantSid,
          status: 'allowed',
        };
        sessionStorage.setItem(
          'allowedGuest',
          typeof allowedGuestSession === 'string'
            ? allowedGuestSession
            : JSON.stringify(allowedGuestSession),
        );
      }
      return true;
    }

    if (isGuest && checkAutoAllow === 'false') {
      sessionStorage.setItem('localIdentity', localIdentity);
      let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
      allowedGuestSession =
        !!allowedGuestSession && typeof allowedGuestSession === 'string'
          ? JSON.parse(allowedGuestSession)
          : allowedGuestSession;
      if (!!localIdentity && !allowedGuestSession[localIdentity]) {
        allowedGuestSession[localIdentity] = {
          sid: localParticipantSid,
          status: 'waiting',
        };
        sessionStorage.setItem(
          'allowedGuest',
          typeof allowedGuestSession === 'string'
            ? allowedGuestSession
            : JSON.stringify(allowedGuestSession),
        );
      }
      return true;
    }
    return false;
  };
  return { createGuestSessions };
}
