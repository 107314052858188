import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

import { Button, Modal, Dropdown } from "semantic-ui-react";
import {
  getSubscriptionList,
  getActivePublishers,
  cancelSubscription,
  retrieveSubscription,
  getCustomerCard,
} from "./DataHandler";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';

interface IProps {
  handleAddNewSubscription: any;
  customerID: string;
  showAdminHeaderMenuRef: any;
}

const SubscriptionDetails = (props: IProps) => {
  const { handleAddNewSubscription, customerID, showAdminHeaderMenuRef } =
    props;
  
   const currentUser: any = useContext(AuthContext);

  const [subList, setSubList] = useState([]);
  const [activePublisherList, setActivePublisherList] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState("");
  const [selectedPublisherName, setSelectedPublisherName] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLicence, setSelectedLicence] = useState({} as any);
  const [customerCard, setCustomerCard] = useState([] as any);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    type: "",
    action: null as any,
  });

  const getSubscription = async () => {
    try {
      setLoading(true);
      const sub = await getSubscriptionList(customerID, "all", get(currentUser, "user.accessToken", ""));
      const card = await getCustomerCard(customerID, get(currentUser, "user.accessToken", ""));
      setCustomerCard(card);
      if (sub.length === 0) {
        handleAddNewSubscription(false);
      } else {
        setSubList(sub);
        const activeSubscription = sub.filter(
          (val: any) => val.status === "active"
        );
        showAdminHeaderMenuRef.current.map(
          (ref: any) =>
            (ref.ele.style.display =
              activeSubscription.length === 0 ? "none" : "block")
        );
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (subList.length === 0) {
      getSubscription();
    }
  });

  const getSubscriptionStatusColumn = (data: any) => (
    <td
      style={
        data.status === "canceled"
          ? { color: "red", textTransform: "capitalize" }
          : data.status === "active" && !!data.cancel_at_period_end
          ? {
              color: "red",
              textTransform: "capitalize",
            }
          : { color: "green", textTransform: "capitalize" }
      }
    >
      {!!data.cancel_at_period_end ? "To be Canceled" : data.status}
    </td>
  );

  const getSubscriptionAction = (data: any) => {
    if (data.status === "active" && !data.cancel_at_period_end) {
      return (
        <Button
          color="violet"
          onClick={() => {
            setSelectedLicence(data)
            setConfirmationModal({
              open: true,
              type: "Cancel",
              action: () => cancelSub(data),
            });
          }}
        >
          Cancel
        </Button>
      );
    } else if (data.status === "active" && !!data.cancel_at_period_end) {
      return (
        <Button
          color="red"
          onClick={() => {
            console.log("data", data);
            setSelectedLicence(data);
            setConfirmationModal({
              open: true,
              type: "Un-Cancel",
              action: () => retrieveSub(data),
            });
          }}
        >
          Un-Cancel
        </Button>
      );
    }
  };

  const renderRow = () => {
    return subList.map((data: any, index: number) => (
      <tr key={index}>
        <td>{data.metadata.custom_plan_name}</td>
        <td>{moment.unix(data.current_period_start).format("L")}</td>
        <td>{moment.unix(data.current_period_end).format("L")}</td>
        {getSubscriptionStatusColumn(data)}
        <td>
          {data.collection_method === "charge_automatically"
            ? `CC - ${customerCard[0].card.last4}`
            : data.metadata.poCode}
        </td>
        <td>
          {!!data.metadata.publisher_name &&
          (!!data.cancel_at_period_end || data.status !== "active")
            ? data.metadata.publisher_name
            : ""}
        </td>
        <td style={{ width: "150px" }}>{getSubscriptionAction(data)}</td>
      </tr>
    ));
  };

  const retrieveSub = async (data: any) => {
    const { id, metadata } = data;
    const { show_id, show_code } = metadata;
    setConfirmationModal({
      open: false,
      type: "",
      action: null as any,
    });
    try {
      setLoading(true);
      const retrieveResponse = await retrieveSubscription(
        show_id,
        show_code,
        id,
        get(currentUser, "user.accessToken", "")
      );
      console.log("retrieveResponse", retrieveResponse);
      getSubscription();
    } catch (err: any) {
      setLoading(false);
      console.log("err", err);
    }
  };

  const cancelSub = async (data: any) => {
    const { id, metadata } = data;
    const { show_id, show_code } = metadata;
    setConfirmationModal({
      open: false,
      type: "",
      action: null as any,
    });
    try {
      setLoading(true);
      const publisherList = await getActivePublishers(show_id, get(currentUser, "user.accessToken", ""));
      setActivePublisherList(publisherList);
      if(publisherList.length === subList.length) {
        setModalOpen(true);
      } else {
        await cancelSubscriptionAction(data);
      }
      setLoading(false);
    } catch (err: any) {
      console.log("err", err);
      setLoading(true);
      await cancelSubscriptionAction(data)
    }
  };
  const cancelSubscriptionAction = async(data: any) => {
    const { id, metadata } = data;
    const { show_id, show_code } = metadata;
    const cancelResponse = await cancelSubscription(
      show_id,
      show_code,
      "",
      "",
      id,
      get(currentUser, "user.accessToken", "")
    );
    getSubscription();
    return cancelResponse
  }
  const handleOnChange = (event: any, data: any) => {
    //console.log("handleOnChange", data.options.filter((val:any) => val.key === data.value)[0].text);
    setSelectedPublisher(data.value);
    setSelectedPublisherName(
      data.options.filter((val: any) => val.key === data.value)[0].name
    );
  };

  const getActivePublisherList = () => {
    const dropDownOptions = activePublisherList.map((value: any) => ({
      key: value.user_id,
      value: value.user_id,
      name: `${value.user_name?.user_name_first} ${value.user_name?.user_name_last}`,
      text: `${value.user_name?.user_name_first} ${value.user_name?.user_name_last}(${value.user_email})`,
    }));
    return (
      <Dropdown
        placeholder="Select"
        className="form-input"
        fluid
        search
        selection
        options={dropDownOptions}
        value={selectedPublisher}
        onChange={handleOnChange}
      />
    );
  };
  const handleCancelSubscription = async () => {
    setModalOpen(false);
    setLoading(true);
    const { id, metadata } = selectedLicence;
    const { show_id, show_code } = metadata;
    const cancelResponse = await cancelSubscription(
      show_id,
      show_code,
      selectedPublisher,
      selectedPublisherName,
      id,
      get(currentUser, "user.accessToken", "")
    );
    console.log("cancelResponse", cancelResponse);
    setModalOpen(false);
    getSubscription();
  };

  const getModal = () => {
    return (
      <Modal
        open={modalOpen}
        size={"tiny"}
        onClick={() => setModalOpen(false)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          Currently you have {activePublisherList.length} active publisher
        </Modal.Header>
        <Modal.Content>
        {/* <b>Please remove a publisher role to cancel the subscription</b> */}
          <b>To cancel a subscription you must remove a publisher role</b>
          {/* <b>Please designate which publisher to cancel</b> */}
          {/* {getActivePublisherList()} */}
        </Modal.Content>
        <Modal.Actions>
          <div style={{ textAlign: "center" }}>
            {/* <Button color="red" onClick={handleCancelSubscription}>
              Remove on End Date
            </Button> */}
            <Button color="green" onClick={() => setModalOpen(false)}>
              Close
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  };
  const getConfirmationModal = () => {
    const { open, type, action } = confirmationModal;
    return (
      <Modal
        open={open}
        size={"tiny"}
        onClick={() =>
          setConfirmationModal({
            open: false,
            type: "",
            action: null,
          })
        }
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Content>
          <b>Are you sure do you want to {type} the licence</b>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ textAlign: "center" }}>
            <Button color="green" onClick={action}>
              YES
            </Button>
            <Button color="red" onClick={() => setModalOpen(false)}>
              CANCEL
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  };
  return (
    <>
      <ProgressLoader loading={loading} />
      <div className="billing-info-wrapper  billing-info-details" style={{ width: "100%" }}>
        <table className="ui inverted table list-streams-table-view">
          <thead className="">
            <tr className="">
              <th className="custom-border">License Type</th>
              <th className="custom-border">Start Date</th>
              <th className="custom-border">Renewal Date</th>
              <th className="custom-border">Status</th>
              <th className="custom-border">Payment Method</th>
              <th className="custom-border">Canceled Publisher</th>
              <th className="custom-border">
                <Button
                  color="green"
                  onClick={() => handleAddNewSubscription(false)}
                >
                  Add
                </Button>
              </th>
            </tr>
          </thead>
          <tbody className="">
            {subList.length > 0 ? (
              renderRow()
            ) : (
              <tr>
                <td colSpan={7}>No Records Found</td>
              </tr>
            )}
          </tbody>
        </table>
        {getModal()}
        {getConfirmationModal()}
      </div>
    </>
  );
};
export default SubscriptionDetails;
