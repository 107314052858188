import { useEffect, useState, useRef } from 'react';
import {
  LocalDataTrack,
} from 'twilio-video';

const useLocalDataTrack = () => {  
  const [dataTrack, _setDataTrack] = useState<any>();
  const dataTrackRef = useRef(dataTrack);
  const [isDataTrackCreated, setIsDataTrackCreated] = useState(false);

  const setDataTrack = (data: any) => {
    dataTrackRef.current = data;
    _setDataTrack(data);
  };

  useEffect(() => {
    console.log('useEffect useLocalDataTrack')
    if (!isDataTrackCreated) {
      const initializeLocalDataTrack = async () => {
        try {
          const dataTrack = new LocalDataTrack();
          console.log(dataTrack)
          setDataTrack(dataTrack);
          console.log('Local data track created')
          setIsDataTrackCreated(true);
        } catch (error) {
          console.error('Error initializing local data track:', error);
        }
      };
      console.log('Data track has not been created')
      initializeLocalDataTrack();
    }
  }, [isDataTrackCreated]);
  return [dataTrack, dataTrackRef];
};

export default useLocalDataTrack;