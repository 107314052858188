import { useEffect, useContext } from 'react';
import CounterMsg from './CounterMsg';
import { EncoderContext, EncoderProvider } from '../hooks/useEncoderContext';
import EncoderSettings from './EncoderSettings';
import EncoderActions from './EncoderActions';
import './Encoder.css';

const EncoderHeader = () => {
  return (
    <div className='chat-h1'>
      <span className='chat-text'>Encoder options</span>
    </div>
  );
};

const EncoderWrapper = (props: any) => {
  const { show_id } = props;
  const encoderValues = useContext(EncoderContext);
  const { setShowId, rebootLoading, deviceList } = encoderValues;
  useEffect(() => {
    setShowId(show_id);
  }, []);
  return (
    <div
      className='encoder-panel-wrapper'
      style={{ pointerEvents: Object.keys(deviceList).length > 0 ? 'auto' : 'none' }}
    >
      <div className='main-menu-right-chat'>
        {rebootLoading ? (
          <CounterMsg
            message={`Device is offline. Please be patient as the system restarts`}
            type={`warning`}
            counter={70}
          />
        ) : (
          <>
            <EncoderHeader />
            <EncoderActions />
            <EncoderSettings />
          </>
        )}
      </div>
    </div>
  );
};

export default function Encoder({ show_id }: any) {
  return (
    <EncoderProvider>
      <EncoderWrapper show_id={show_id} />
    </EncoderProvider>
  );
}
