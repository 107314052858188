
declare global {
    interface Window {
      DataZoom: any;
    }
  }
  
  export const initDataZoom = (configId: any) => {
    const script = document.createElement('script');
    script.src = `https://platform.datazoom.io/beacon/v1/config?configuration_id=${configId}`;
    document.head.appendChild(script);
  };