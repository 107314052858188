import React from "react";
import { useEffect} from 'react';
import { Modal, ModalProps, Header } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import hiSrc from "../../Images/hi-icon.svg";
import "../../App.css";
import styles from "./GuestWaitingModal.module.css";

interface GuestWaitingModalProps extends Pick<ModalProps, "open" | "onClose"> {
  streamName: string;
  publisherName: string;
  onLeave: () => void;
}

//document.addEventListener("contextmenu", (event) => event.preventDefault());

const changeModalBackGround = ()=>{
  let waitingModalDiv = document.getElementById('guest-waiting-modal');
  if(waitingModalDiv){
    let dimmerDiv =  waitingModalDiv.parentElement || {style:{backgroundColor:'#7F7F7F'}}
    dimmerDiv.style.backgroundColor = '#7F7F7F'
  }
}
  
const GuestWaitingModal = ({
  publisherName,
  streamName,
  onClose,
  open,
  onLeave,
}: GuestWaitingModalProps) => {
  useEffect(()=>{
    changeModalBackGround()
  },[open])
  
  return open ? (
    <div className={styles.waitingmodal} onContextMenu={(event) => event.preventDefault()} >
      <Modal open={open} dimmer={"blurring"} id={'guest-waiting-modal'}>
        <Modal.Content className={styles.content}>
          <div className={styles.modalHeader}>
            <div className={styles.logo}></div>
            {/* <CloseButton onConfirm={onLeave} /> */}
          </div>
          <Header as="h2">Welcome to PacPost</Header>

          <img src={hiSrc} alt="Hi" className={styles.hi} />

          <Header>
            The host will accept your request in a moment, please wait...
          </Header>
          <p>
            Joining <b>{streamName}</b> hosted by <b>{publisherName}</b>
          </p>
        </Modal.Content>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export default GuestWaitingModal;
