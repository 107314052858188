import * as Sentry from "@sentry/react";
import {
  LocalDataTrack, connect, createLocalTracks, createLocalVideoTrack,
  createLocalAudioTrack, LocalParticipant, LocalVideoTrackPublication,
  LocalVideoTrack, LocalAudioTrackPublication, LocalAudioTrack
} from "twilio-video";
import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
  isSupported,
} from '@twilio/video-processors';
import ColorHash from "color-hash";
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
  useReducer,
  useContext
} from "react";
import { useNavigationType } from "react-router-dom";
import {
  Card,
  Icon,
  Input,
  Button,
  Dropdown,
  Popup,
  Grid,
  Modal,
  TextArea,
  Tab
} from "semantic-ui-react";

import axios from "axios";
import PubNub from "pubnub";
import { Parser } from "json2csv";

//import { Picker } from "emoji-mart";
//import "emoji-mart/css/emoji-mart.css";
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { turnApiUrl, CHIME_VALUES } from "../../Utils/constants";
import { get, tick } from "../../Utils/helpers";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ShowStream from "./ShowStream";
import GiphyModal from "./GiphyModal";
import ProgressLoader from "../Common/ProgressLoader";
import { auth } from "../../Firebase";
import { PAGES, FEATURE_NAME } from "../../Utils/constants";
import { isFeatureIncluded } from "../../Utils/featureFlags";
import { firstBy } from "thenby";
import { Mixpanel } from "../../Service/Mixpanel";

import RightMenuWithChatWindow from "./RightMenuChat";
import { isViewInScreen, convertDateTimeFromUTCEpoc } from "../../Utils/utils";
import "./showviewer.css";

//Header VC control images
import pacpost_img from "../..//Images/logo_PP_white_header.png";
import dndActiveImg from "../../Images/connection-signal--off.svg"
import dndInactiveImg from "../../Images/connection-signal.svg"
import phoneFilled from "../../Images/phone_outline.svg";
import settingsImg from "../../Images/settings.svg";
import chatImg from "../../Images/chat.svg";
import closeImg from "../../Images/close.svg";
import download_chat from "../../Images/download_arrow.png";
import fitToScreen from "../../Images/fit-to-screen.svg";
import tc_Img from "../../Images/room-finder1.png";
import golbalAudioMute from "../../Images/Global_Microphone.svg";
import golbalAudioUnMute from "../../Images/Global_Microphone_unmute.svg";
import overflowMenuHorizontal from "../../Images/overflow-menu-horizontal.svg";
import misuseAlt from "../../Images/misuse-alt.svg";
import pencil from "../../Images/pencil.png";
import erase from "../../Images/erase.png";
import camera from "../../Images/camera.png";
import clear from "../../Images/clear.png";
import documentExport from "../../Images/documentExport.svg";
// import playPause from "../../Images/pause-play.png";
import volume from "../../Images/volume.svg";
import volumeMuteIcon from "../../Images/volume_mute.svg";
import basic_profile_img from "../../Images/user.png";
import refreshIcon from "../../Images/refreshicon.png";
import greeTick from "../../Images/green-tick.png";
import crossTick from "../../Images/red-cancel.png";
import editPencil from "../../Images/pencil.png";
import streamEditIcon from "../../Images/stream_edit.svg"
//left sidebar Images
import menuIcon from "../../Images/hamburger-menu.svg";
import closeIcon from "../../Images/close1.svg";
import homeIcon from "../../Images/homeIcon.svg";
import streamIcon from "../../Images/stream.svg";
import dashboard from "../../Images/chevron-left.svg";
import playIcon from "../../Images/play-outline.svg";
import group from "../../Images/People.svg";
import guest from "../../Images/guest_whitebg.svg";
import PublisherPanel from "./PublisherPanel";
import GuestInvite from "./GuestInvite";

import pauseIcon from "../../Images/pause-outline.svg";
import mediaHubIcon from "../../Images/folder-details.svg";
import playListIcon from "../../Images/list_new.svg";
import uploadMediaIcon from "../../Images/cloud-upload.svg";
import videoConfIcon from "../../Images/events-alt.svg";
import emoji from "../../Images/emoji.svg";

import dndActiveIcon from "../../Images/dnd-active-icon.svg";
import dndInactiveIcon from "../../Images/dnd-inactive-icon.svg";

import diagnosticsIcon from "../../Images/diagnostics-check.svg";

import groupPresentation from "../../Images/group_presentation.svg";
import showStreamIcon from "../../Images/list_shows.svg";

import showDominantSpeakerIcon from "../../Images/show-dom-speaker.svg"
import hideDominantSpeakerIcon from "../../Images/hide-dom-speaker.svg"

import virtualBGImg from "../../Images/VirtualBackGround.jpg"

import { analytics } from "../../Firebase";

import Diagnostics from "./Diagnostics";
import Abstract_thumb from "../../Images/Abstract_thumb.jpg";
import Bookshelf_thumb from "../../Images/Bookshelf_thumb.jpg";
import CoffeeShop_thumb from "../../Images/CoffeeShop_thumb.jpg";
import SanFrancisco_thumb from "../../Images/SanFrancisco_thumb.jpg";
import Abstract from "../../Images/Abstract.jpg";
import Bookshelf from "../../Images/Bookshelf.jpg";
import CoffeeShop from "../../Images/CoffeeShop.jpg";
import SanFrancisco from "../../Images/SanFrancisco.jpg";
import Blur_thumb from "../../Images/Blur_thumb.svg";
import None_thumb from "../../Images/None_thumb.svg";
import { AuthContext } from '../../Context/authContext';
import ImageCanvas from "../UserSettings/ImageCanvas";
import { useLocationStore } from "../../store/useLocationStore";
import useNavigateToPage from "../../hooks/useNavigateToPage";

const virtualBackgroundAssets = '/virtualbackground';
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

const backgrounds = [
  { value: "none", image: '', thumbImage: None_thumb},
  { value: "blur", image: '', thumbImage: Blur_thumb},
  { value: "abstract", image: Abstract, thumbImage: Abstract_thumb},
  { value: "bookshelf", image: Bookshelf, thumbImage: Bookshelf_thumb},
  { value: "coffeeshop", image: CoffeeShop, thumbImage: CoffeeShop_thumb},
  { value: "sanfrancisco", image: SanFrancisco, thumbImage: SanFrancisco_thumb}
]

//chime_audio
const chimeAudioUrl = require("../../Assets/sounds/chime.mp3");
let colorHash = new ColorHash();

const annotationColorOptions = [
  { text: "red", value: "red" },
  { text: "green", value: "green" },
  { text: "blue", value: "blue" },
  { text: "cyan", value: "cyan" },
  { text: "magenta", value: "magenta" },
  { text: "yellow", value: "yellow" },
  { text: "black", value: "black" },
  { text: "white", value: "white" }
];

let localUserImage = "";

const ShowViewerDashboard = () => {
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const navigationType = useNavigationType();
  
  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  const show_id = get(locationState,'state.show_id','');
  const team_admin = get(locationState,'state.team_admin',false);
  const stream_id = get(locationState,'state.stream_id','');

  const [updatedStreamLabel, setUpdatedStreamLabel] = useState("");
  const [updatedStreamId, setUpdatedStreamId] = useState("");
  const [updatedStreamLabelToAll, setUpdatedStreamLabelToAll] = useState(false);
  const [streamLabelUpdateMsg, setStreamLabelUpdateMsg] = useState("");

  const [timeCodePopup, setTimeCodePopup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [annotateLoading, setAnnotateLoading] = useState(false);
  const [chatClearLoading, setChatClearLoading] = useState(false);
  const [annotateChatClearLoading, setAnnotateChatClearLoading] = useState(
    false
  );
  const [chatExportLoading, setChatExportLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [showViewer, setShowViewer] = useState(false);

  const [streamLabel, setStreamLabel] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [showId, setShowId] = useState("");
  const [selectedStream, setStreamId] = useState("");
  const [activeStreams, setActiveStreams] = useState([] as any);

  const [stream, setStream] = useState({} as any);
  const [streamKey, setStreamKey] = useState(Math.random());
  const [iceServers, setIceServers] = useState([] as any);

  const [activeStreamVCRoom, setActiveStreamVCRoom] = useState({} as any);
  const [isVCHost, setIsVCHost] = useState(false);
  const [troom, setTRoom] = useState({} as any);
  const troomRef = useRef<any>();
  troomRef.current = troom;
  const [localParticipant, setLocalParticipant] = useState({} as any);
  const [cameraId, setCameraId] = useState("");
  const [cameraName, setCameraName] = useState("Select Camera");
  const cameraIdRef = useRef<string>();
  const [micId, setMicId] = useState("");
  const [micName, setMicName] = useState("Select Microphone");
  const micIdRef = useRef<string>();
  cameraIdRef.current = cameraId;
  micIdRef.current = micId;
  const [muteAll, setMuteAll] = useState(false);

  //Config Stream
  const [showConfig, setShowConfig] = useState([]);
  //const [activeStreamCount, setActiveStreamCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [clearChatModalOpen, setClearChatModalOpen] = useState(false);
  const [exportChartConfirmationOpen, setExportChartConfirmationOpen] = useState(false)

  //left sidebar
  const [showStreamMenu, setShowStreamMenu] = useState(false);
  const [streamMenuActive, setStreamMenuActive] = useState(true);
  const [showUser, setUsers] = useState(false);
  const [showCloseUser, setCloseUsers] = useState(false);
  const [showOpenUser, setOpenUsers] = useState(true);
  const [showChat, setChatWindow] = useState(false);
  const [showAnnotationChat, setAnnotationChatWindow] = useState(false);
  const [isModalOpen, setModalStatus] = useState(false);
  const [showCloseDisable, setCloseDisable] = useState(false);
  const [showAnnotationCloseDisable, setAnnotationCloseDisable] = useState(
    false
  );
  const [showChatDisable, setChatDisable] = useState(true);
  const [showAnnotationChatDisable, setAnnotationChatDisable] = useState(true);
  const leftBarRef = useRef<HTMLDivElement>(null);

  //Pubnub
  const [pubnub, setPubNub] = useState({} as any);
  const [channels, setChannels] = useState([] as any[]);
  let [allMessages, setAllMessages] = useState([] as any[]);
  let [allAnnotateMessages, setAllAnnotateMessages] = useState([] as any[]);
  const [input, setInput] = useState("");
  const [annotateInput, setAnnotateInput] = useState("");
  const [typingInd, setTypingInd] = useState("");
  const [editInput, setEditInput] = useState("");
  const [editAnnotateInput, setAnnotateEditInput] = useState("");
  const [editMessage, setEditMessage] = useState(false);
  
  const [editAnnotateMessage, setEditAnnotateMessage] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState("");
  const [editingAnnotateMessageId, setEditingAnnotateMessageId] = useState("");

  // let photoURL: any
  // if (auth && auth.getUser() && auth.getUser().photoURL) {
  //   //photoURL = auth.getUser().photoURL
  // }
  const [user_imageurl, setProfileImageUrl] = useState("");
  const [user_chime_preferance, setUserChimePreferance] = useState(false);

  const [signalStrengthOpen, setsignalStrengthOpen] = useState(false);
  const [signalStrengthClose, setsignalStrengthClose] = useState(false);
  const [overlay, setOverlay] = useState({ style: { display: "none" } });
  const [overlayConnected, setOverlayConnected] = useState({ style: { display: "none" } });
  const [overlayDisconnected, setOverlayDisconnected] = useState({ style: { display: "none" } });
  const [overlayMsg, setOverlaymsg] = useState("");
  const [overlayMsgConnected, setOverlayMsgConnected] = useState("");
  const [overlayMsgDisconnected, setOverlayMsgDisconnected] = useState("");
  const [user_invited, setUserInvited] = useState(true);

  //Uploadfile
  const [uploadFiles, setUploadFiles] = useState([] as any);
  const [fileName, setFileName] = useState("");
  // const [browseInput, setbrowseInput] = useState(false);
  const [uploadAlert, setUploadAlert] = useState(false);

  //popup
  const [showPopup, setShowPopup] = useState(false);
  //Drawing option
  const [showDrawingOption, setDrawingOption] = useState(false);

  //Volume show and hide
  const [showVolume, setShowVolume] = useState(false);
  const [playIconStatus, setPlayIconStatus] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(100);
  const [volumeMute, setVolumeMute] = useState(false);

  //emoji
  const [showEmoji, setShowEmoji] = useState(false);
  //full screen
  const fullScreenHandler = useFullScreenHandle();

  const [selectedAnnotateColor, setSelectedAnnotateColor] = useState("");
  const [VFXcolor, setVFXcolor] = useState("red");
  const [SFXcolor, setSFXcolor] = useState("red");
  const [MXcolor, setMXcolor] = useState("red");
  const [PIXcolor, setPIXcolor] = useState("red");
  const [modalAnnotateColorOpen, setModalAnnotateColorOpen] = useState(false);
  // const [messages, setMessages] = useState([] as any[]);
  // const [annotateMessages, setAnnotateMessages] = useState([] as any[]);

  // Camera and microphone selection
  const [cameraOptions, _setCameraOptions] = useState(
    []
  );

  const setCameraOptions = (data: any) => {
    _setCameraOptions(data);
  };

  const [micOptions, _setMicOptions] = useState(
    []
  );

  const setMicOptions = (data: any) => {
    _setMicOptions(data);
  };

  const [messages, _setMessages] = useState([] as any[]);
  const messagesRef = useRef(messages);
  const setMessages = (data: any[]) => {
    messagesRef.current = data;
    _setMessages(data)
  }

  const [annotateMessages, _setAnnotateMessages] = useState([] as any[]);
  const annotateMessagesRef = useRef(annotateMessages);
  const setAnnotateMessages = (data: any[]) => {
    annotateMessagesRef.current = data;
    _setAnnotateMessages(data)
  }

  const [invitedViewers, _setInvitedViewers] = useState([] as any);
  const invitedViewersRef = useRef(invitedViewers);
  const setInvitedViewers = (data: any) => {
    invitedViewersRef.current = data;
    _setInvitedViewers(data);
  };

  const [lParticipant, _setLLocalParticipant] = useState({} as any);
  const lParticipantRef = useRef(lParticipant);
  const setLLocalParticipant = (data: any) => {
    lParticipantRef.current = data;
    _setLLocalParticipant(data);
  };

  // White boarding
  const inMemCanvas: HTMLCanvasElement = document.createElement("canvas");

  const [isEraser, _setMyEraser] = useState(false);
  const myEraserRef = useRef(isEraser);
  const setMyEraser = (data: boolean) => {
    myEraserRef.current = data;
    _setMyEraser(data);
  };
  
  const [isPencilCircle, _setMyPencilCircle] = useState(false);
  const myPencilCircleRef = useRef(isPencilCircle);
  const setMyPencilCircle = (data: boolean) => {
    myPencilCircleRef.current = data;
    _setMyPencilCircle(data);
  };

  const [isPencilLine, _setMyPencilLine] = useState(false);
  const myPencilLineRef = useRef(isPencilLine);
  const setMyPencilLine = (data: boolean) => {
    myPencilLineRef.current = data;
    _setMyPencilLine(data);
  };

  const [vratio, _setVRatio] = useState(1.6);
  const vratioRef = useRef(vratio);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(
    undefined
  );

  const [localAudioMute, _setLocalAudioMute] = useState(true);
  const localAudioMuteRef = useRef(localAudioMute);
  const setLocalAudioMute = (data: boolean) => {
    localAudioMuteRef.current = data;
    _setLocalAudioMute(data);
  };

  const [localVideoMute, _setLocalVideoMute] = useState(true);
  const localVideoMuteRef = useRef(localVideoMute);
  const setLocalVideoMute = (data: boolean) => {
    localVideoMuteRef.current = data;
    _setLocalVideoMute(data);
  };

  const [dataTrack, _setDataTrack] = useState<any>();
  const dataTrackRef = useRef(dataTrack);
  const setDataTrack = (data: any) => {
    dataTrackRef.current = data;
    _setDataTrack(data);
  };


  const [roomParticipantsList, _setRoomParticipantsList] = useState([] as any);
  const [roomParticipantsConnectionStatus, _setRoomParticipantsConnectionStatus] = useState([] as any);
  const roomParticipantsListRef = useRef(roomParticipantsList);
  const roomParticipantsConnectionStatusRef = useRef(roomParticipantsConnectionStatus);
  const setRoomParticipantsList = (data: any) => {
    roomParticipantsListRef.current = data;
    let connectionStatusArr:any = [], connectionStatusObjArr:any = [];
    data.forEach((item: any)=> {
      let member = item.identity.substring(
      item?.identity.lastIndexOf("#") + 1,
      item?.identity.lastIndexOf("_"));
      let statusObj:any = {user: member, status: item.state};
      let index = connectionStatusArr.indexOf(member);
      if(index === -1) {
        connectionStatusArr.push(member);
        connectionStatusObjArr.push(statusObj);
      } else {
        connectionStatusObjArr[index] = statusObj;
      }
    })
    roomParticipantsConnectionStatusRef.current = connectionStatusObjArr;
    _setRoomParticipantsConnectionStatus(connectionStatusObjArr);
    _setRoomParticipantsList(data);
  };

  const [isDND, _setMyDND] = useState(false);
  const myDNDRef = useRef(isDND);
  const setMyDND = (data: boolean) => {
    myDNDRef.current = data;
    _setMyDND(data);
  };
  const [roomParticipantsWithTracks, setRoomParticipantsWithTracks] = useState([] as any);

  const [isTimeCodeFeatureIncluded, setTimeCodeFeatureIncluded] = useState(
    false
  );

  const [isGuestInviteIncluded, setGuestInviteIncluded] = useState(
    false
  );

  //publisher panel
  const [showPublisherPanel, setShowPublisherPanel] = useState(false);
  const [showPublisherPanelCloseDisable, setShowPublisherPanelCloseDisable] = useState(
    false
  );

  const [isPublisher, setIsPublisher] = useState(false);
  const [isGuestViewer, setIsGuestViewer] = useState(false);
  const [listUsers, setListUsers] = useState([]);

  //Guest Invite
  const [showGuestInvite, setShowGuestInvite] = useState(false);
  const [showGuestInviteCloseDisable, setShowGuestInviteCloseDisable] = useState(
    false
  );

  const sendTextMessageInput = useRef<HTMLInputElement>(null);

  const [cameraDropdownOpen, setCameraDropdownOpen] = useState(false);

  const [guestUsersList, setGuestUsersList] = useState([] as any[]);

  const [usersUpdated, setUsersUpdated] = useState(false);

  // Dominant Speaker
  const [showDominantSpeaker, setShowDominantSpeaker] = useState(false);
  const [dominantSpeakerID, _setDominantSpeakerID] = useState("");
  const dominantSpeakerIDRef = useRef(dominantSpeakerID);
  const setDominantSpeakerID = (data: any) => {
    dominantSpeakerIDRef.current = data;
    _setDominantSpeakerID(data);
  };

  //Dominant Speaker track
  const [dominantSpeakerTrack, _setDominantSpeakerTrack] = useState({} as any);
  const dominantSpeakerTrackRef = useRef(dominantSpeakerTrack);
  const setDominantSpeakerTrack = (data: any) => {
    dominantSpeakerTrackRef.current = data;
    _setDominantSpeakerTrack(data);
  };

  //Local Participant track
  const [localParticipantTrack, _setLocalParticipantTrack] = useState({} as any);
  const localParticipantTrackRef = useRef(localParticipantTrack);
  const setLocalParticipantTrack = (data: any) => {
    localParticipantTrackRef.current = data;
    _setLocalParticipantTrack(data);
  };

  const [showStreamUpdate, _setShowStreamUpdate] = useState(1);
  const showStreamUpdateRef = useRef(showStreamUpdate);
  const setShowStreamUpdate = (data: any) => {
    showStreamUpdateRef.current = data;
    _setShowStreamUpdate(data);
  };

  const [showDiagnostics, setShowDiagnostics] = useState(false);
  //Virtual Background
  const [localParticipantBGModalOpen, setLocalParticipantBGModalOpen] = useState(false);
  const [tempActiveBG, setTempActiveBG] = useState("");
  const [activeBG, _setActiveBG] = useState("none");
  const activeBGRef = useRef(activeBG);
  const setActiveBG = (data: any) => {
    activeBGRef.current = data;
    _setActiveBG(data);
  };

  //Back Button
  useEffect(() => {
    return () => {
      if (navigationType === "POP") {
        if (troom && !isEmpty(troom)) {
          troom.disconnect();
        }
        clearWhiteBoard();
      }
    }
  }, [navigationType, troom]);

  //Tab closing
  useEffect(() => {
    window.addEventListener('beforeunload', disconnectOnTabClose)

    const user = auth.getUser();
    const uid = get(user, "uid", "");

    analytics.showViewerVisit(uid, show_id);

    return () => {
      window.removeEventListener('beforeunload', disconnectOnTabClose)
    }
  }, []);

  const disconnectOnTabClose = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
    if (troom && !isEmpty(troom)) {
      troom.disconnect();
    }
    clearWhiteBoard();
  }

  const getPublisherStatus = async () => {
    const isShowPublisher = await backend.fetch({
      api: api.users.isShowPublisher,
      queryParam: { show_id },
    }, get(currentUser, "user.accessToken", ""));
    setIsPublisher(isShowPublisher);
  }

  const getGuestInviteStatus = async () => {
    const getActiveShowUsers = {
      api: api.users.listActiveUsersInShow,
      urlParam: show_id
    }

    const listActiveUsersInShow = await backend.fetch(getActiveShowUsers, get(currentUser, "user.accessToken", ""));
    const user = auth.getUser();
    const uid = get(user, "uid", "");
    const userData = listActiveUsersInShow.find((item: any) => item.user_id === uid);
    if (userData?.guest_invite && isFeatureIncluded(FEATURE_NAME.ALLOW_GUEST_INVITE, show_id)) {
      setGuestInviteIncluded(true);
    } else {
      setGuestInviteIncluded(false);
    }
  }

  // Feature inclusion
  useEffect(() => {
    if (isFeatureIncluded(FEATURE_NAME.TIME_CODE, show_id)) {
      setTimeCodeFeatureIncluded(true);
    }

    if (show_id) {
      getPublisherStatus();
      getGuestInviteStatus();
    }
  }, []);

  useEffect(() => {

    if (!!dataTrackRef && !!dataTrackRef.current) {
      dataTrackRef.current.send(JSON.stringify({ streamId: updatedStreamId, streamName: updatedStreamLabel }));
    }

  }, [updatedStreamLabelToAll])

  // Access Check
  useEffect(() => {
    // Mixpanel
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SHOW_VIEWER_PAGE,
    });

    // If profile image changed as the header is specific to viewer
    const refreshProfile = async () => {
      // Pull user data to show new profile image if it is changed
      const data = {
        api: api.users.get,
      };
      setLoading(true);
      const profiledata = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      console.log("profiledata:::",profiledata)
      setLoading(false);
      if (profiledata) {
        const { imageUrlPath, user_chime_preferance } = profiledata;
        if (imageUrlPath) {
          
          localUserImage = imageUrlPath;

          setProfileImageUrl(imageUrlPath);
        }
        if (user_chime_preferance) {
          setUserChimePreferance(true)
        }

      }
    };

    // Check access
    const checkAccess = async () => {
      setLoading(true);
      const isShowViewer = await backend.fetch({
        api: api.users.isShowViewer,
        queryParam: { show_id },
      }, get(currentUser, "user.accessToken", ""));

      const isGuestViewer = await backend.fetch({
        api: api.users.isGuestViewer,
        queryParam: { show_id },
      }, get(currentUser, "user.accessToken", ""));

      setIsGuestViewer(isGuestViewer)

      setLoading(false);
      if (!isShowViewer && !isGuestViewer) {
        navigateToPage("/unauthorised");
      }
      if (isShowViewer || isGuestViewer) {
        setShowViewer(true);
      }
    };
    if (show_id) {
      setShowId(show_id);
      refreshProfile();
      checkAccess();
    } else {
      //history.push("/unauthorised");
      navigateToPage("/login");
    }
  }, [show_id]);

  useEffect(() => {
    const stream_id = sessionStorage.getItem("stream_id");
    const stream_label: any = sessionStorage.getItem("stream_label");

    const fetchStreamData = async () => {
      Mixpanel.track("Viewer Click Stream", {
        Platform: "Web",
        "Stream Label": stream_label,
      });

      if (stream_id) {
        let stream = { stream_id, stream_label };
        setStream(stream);

        setStreamKey(Math.random());

        await fetchAllActiveStreamUsers(stream_id);

        // Pull VC Room Details and start/join VC
        await fetchVCRoomAndStartOrJoin(stream_id, stream_label);
      }
    };
    if (stream_id && stream_label) {
      setShowStreamMenu(true);
      setStreamMenuActive(false);
      setStreamId(stream_id);
      setStreamLabel(stream_label);
      fetchStreamData();
    }

    return () => {
      sessionStorage.removeItem("stream_id");
      sessionStorage.removeItem("stream_label");
    };
  }, []);

  useEffect(() => {
    const fetchIceServers = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(turnApiUrl, {
          method: "PUT",
        });

        if (response.status >= 300) {
          throw new Error(
            "TURN API return unexpected status " + response.status
          );
        }

        const result = await response.json(); 
        const { iceServers = [] } = result.v;
        setIsLoading(false);

        setIceServers(iceServers);
      } catch (err: any) {
        const msg = "Error while pulling ICE Servers !";
        console.error(msg);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchStreamData = async (show_id: string) => {
      try {
        setSuccessMessage("");
        setErrorMessage("");

        // 1. Pull active stream
        const data = {
          api: api.streams.listViewerStreams,
          queryParam: { show_id: show_id },
        };

        const getShowsConfig = {
          api: api.configs.listShowConfigsByShowId,
          urlParam: show_id,
          queryParam: { config_name: "number_of_simultaneous_streams" },
        };

        setIsLoading(true);

        const showConfigDetails = await backend.fetch(getShowsConfig, get(currentUser, "user.accessToken", ""));
        setShowConfig(showConfigDetails);

        const streams: any[] = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        setIsLoading(false);

        if (streams && streams.length) {
          const cleanStream = streams.filter((arr: any, index: any, self: any) =>
            index === self.findIndex((temp: any) => (temp.stream_id === arr.stream_id)))
          cleanStream.sort(
            firstBy(function (v: any) {
              return v.stream_label.toLowerCase();
            })
          );

          setActiveStreams(cleanStream);
        }

        // If navigated from Publisher invite screen
        if (stream_id && streams) {
          //Show Config Validation
          const configResult = await showConfigValidation(show_id, stream_id, showConfigDetails);
          if (!configResult) {
            return false;
          }

          // Check if stream_id present in active streams
          const result: any[] = streams.filter(
            (stream) => stream.stream_id === stream_id
          );
          if (result && result[0]) {
            const pub_owned_stream = result[0] || null;
            if (pub_owned_stream) {

              const { stream_label } = pub_owned_stream;

              setShowStreamMenu(true);

              if (stream_label) {
                setStreamLabel(stream_label);
              }

              if (stream_id) {
                let stream = { stream_id, stream_label };
                setStream(stream);

                setStreamKey(Math.random());

                await fetchAllActiveStreamUsers(stream_id);

                // Pull VC Room Details and start/join VC
                await fetchVCRoomAndStartOrJoin(stream_id, stream_label);
              }
            }
          }
        }
      } catch (err: any) {
        const msg = "Error while pulling data";
        if (err.errCode === 30314) {
          const user = auth.getUser();
          const uid: any = get(user, "uid", "");
          Sentry.configureScope(
            scope => scope
              .setUser({ "id": uid })
              .setTag("show_id", show_id)
          );
          Sentry.captureMessage(err.errMessage)
        }
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (show_id) {
      setShowId(show_id);

      // Fetch Streams
      fetchStreamData(show_id);

      // Fetch Ice Servers
      fetchIceServers();
    }
  }, [show_id]);

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showOverlay = async () => {
    let sstyle: any = { style: { display: "block" } };
    setOverlay(sstyle);

    await sleep(3000);

    let hstyle: any = { style: { display: "none" } };
    setOverlay(hstyle);
  };

  const showConnectedOverlay = async () => {
    let sstyle: any = { style: { display: "block" } };
    setOverlayConnected(sstyle);

    await sleep(1000);

    let hstyle: any = { style: { display: "none" } };
    setOverlayConnected(hstyle);
  };
  
  const showDisconnectedOverlay = async () => {
    let sstyle: any = { style: { display: "block" } };
    setOverlayDisconnected(sstyle);

    await sleep(1000);

    let hstyle: any = { style: { display: "none" } };
    setOverlayDisconnected(hstyle);
  };

  /*
   * White boarding
   */
  type Coordinate = {
    x: number;
    y: number;
  };

  const drawLine = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate,
    erase: Boolean
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext("2d");
    if (context) {
      context.lineJoin = context.lineCap = "round";

      context.beginPath();

      if (!erase) {
        context.globalCompositeOperation = "source-over";
        context.strokeStyle = color;
        context.lineWidth = 5;
      } else {
        context.globalCompositeOperation = "destination-out";
        context.lineWidth = 20;
      }

      context.moveTo(originalMousePosition.x, originalMousePosition.y);
      context.lineTo(newMousePosition.x, newMousePosition.y);
      context.closePath();

      context.stroke();
    }
  };

  const drawCircle = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext("2d");
    if (context) {
      context.lineJoin = context.lineCap = "round";

      context.beginPath();
      context.globalCompositeOperation = "source-over";
      context.strokeStyle = color;
      context.lineWidth = 3;

      // calculating the midX and midY
      var midY=originalMousePosition.y+(newMousePosition.y-originalMousePosition.y)*0.50;
      var midX=originalMousePosition.x+(newMousePosition.x-originalMousePosition.x)*0.50;
      var radius= Math.hypot(newMousePosition.x-originalMousePosition.x, newMousePosition.y-originalMousePosition.y)/2;

      context.arc(midX, midY, radius, 0, 2 * Math.PI);

      context.stroke();
    }
  };

  const startPaint = (coordinates: Coordinate) => {
    if (coordinates) {
      setMousePosition(coordinates);
      setIsPainting(true);

      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;

      if (myPencilCircleRef.current || myPencilLineRef.current) {
        canvas.className = "canvas-pencil-on";
      }

      if (myEraserRef.current) {
        canvas.className = "canvas-eraser-on";
      }
    }
  }

  const startPaintMouse = useCallback((event: MouseEvent) => {
    const coordinates = getCoordinates(event);
    if (coordinates) {
      startPaint(coordinates);
    }
  }, []);

  const startPaintTouch = useCallback((event: TouchEvent) => {
    const coordinates = getTouchCoordinates(event);
    if (coordinates) {
      startPaint(coordinates);
    }
  }, []);

  const paint = (newMousePosition: Coordinate, isPainting: boolean, mousePosition: Coordinate | undefined, isCircle: boolean) => {
    let erase: Boolean = false;

    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    if (isPainting && (myPencilCircleRef.current || myPencilLineRef.current)) {

      if (mousePosition && newMousePosition) {
        const color = colorHash.hex(dataTrackRef.current.id);
        if (isCircle) {
          drawCircle(color, mousePosition, newMousePosition);
        } else {
          drawLine(color, mousePosition, newMousePosition, false);
        }
        canvas.className = "canvas-pencil-on";

        const icanvas: any = document.getElementById("canvas");
        const canvasWidth = get(icanvas, "width", 0);
        const canvasHeight = get(icanvas, "height", 0);
        const incoming_canvas = {
          width: canvasWidth,
          height: canvasHeight,
        };

        dataTrackRef.current.send(
          JSON.stringify({
            isPainting,
            color,
            mousePosition,
            newMousePosition,
            incoming_canvas,
            erase,
            isCircle
          })
        );
        setMousePosition(newMousePosition);
      }
    } else if (isPainting && myEraserRef.current) {
      erase = true;
      if (mousePosition && newMousePosition) {
        const color = colorHash.hex(dataTrackRef.current.id);
        drawLine(color, mousePosition, newMousePosition, true);
        canvas.className = "canvas-eraser-on";
        const icanvas: any = document.getElementById("canvas");
        const incoming_canvas = {
          width: icanvas.width,
          height: icanvas.height,
        };
        dataTrackRef.current.send(
          JSON.stringify({
            isPainting,
            color,
            mousePosition,
            newMousePosition,
            incoming_canvas,
            erase,
            isCircle
          })
        );
        setMousePosition(newMousePosition);
      }
    }
  }

  const paintMouse = useCallback(
    (event: MouseEvent) => {
      if (isPencilLine || isEraser) {
        const newMousePosition = getCoordinates(event);
        if (newMousePosition) {
          paint(newMousePosition, isPainting, mousePosition, false);
        }
      }
    },
    [isPainting, mousePosition]
  );

  const paintTouch = useCallback(
    (event: TouchEvent) => {
      const newMousePosition = getTouchCoordinates(event);
      if (newMousePosition) {
        paint(newMousePosition, isPainting, mousePosition, false);  
      }
    },
    [isPainting, mousePosition]
  );

  const exitPaint = useCallback((event: TouchEvent) => {
    setIsPainting(false);
    setMousePosition(undefined);
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.className = "canvas-cursor-auto";
  }, []);

  const mouseExitPaint = useCallback((event: MouseEvent) => {
    if (isPencilCircle && event.type === 'mouseup') {
      const newMousePosition = getCoordinates(event);
      if (newMousePosition) {
        paint(newMousePosition, isPainting, mousePosition, true);
      }
    }

    if (isPencilLine) {
      setIsPainting(false);
      setMousePosition(undefined);
    }
    
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.className = "canvas-cursor-auto";
  },
  [isPainting, mousePosition]);

  const preventDragging = useCallback((e: TouchEvent) => { e.preventDefault(); }, []);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    canvas.addEventListener("mousedown", startPaintMouse);
    canvas.addEventListener("mousemove", paintMouse);
    canvas.addEventListener("mouseup", mouseExitPaint);
    canvas.addEventListener("mouseleave", mouseExitPaint);

    canvas.addEventListener("touchstart", startPaintTouch);
    canvas.addEventListener("touchmove", paintTouch);
    canvas.addEventListener("touchend", exitPaint);

    window.addEventListener("touchmove", preventDragging, { passive: false });

    return () => {
      canvas.removeEventListener("mousedown", startPaintMouse);
      canvas.removeEventListener("mousemove", paintMouse);
      canvas.removeEventListener("mouseup", mouseExitPaint);
      canvas.removeEventListener("mouseleave", mouseExitPaint);

      canvas.removeEventListener("touchstart", startPaintTouch);
      canvas.removeEventListener("touchmove", paintTouch);
      canvas.removeEventListener("touchend", exitPaint);

      window.removeEventListener("touchmove", preventDragging);
    };
  }, [startPaint, paint, exitPaint]);

  const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const BB = canvas?.getBoundingClientRect();

    return {
      x: event.clientX - BB.left,
      y: event.clientY - BB.top
    };
  };

  // Get the position of a touch relative to the canvas
  const getTouchCoordinates = (touchEvent: TouchEvent): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const BB = canvas?.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - BB.left,
      y: touchEvent.touches[0].clientY - BB.top
    };
  }


  const setupLocalDataTrack = () => {
    const dataTrack = new LocalDataTrack();
    return dataTrack;
  };

  // pencil toggle function
  const pencilToggled = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(!isPencilLine);
    setDrawingOption(!showDrawingOption);
  };

  //circle toggled function
  const circleToggled = async () => {
    setMyEraser(false);
    setMyPencilCircle(!isPencilCircle);
    setMyPencilLine(false);
    setDrawingOption(!showDrawingOption);
  }

  // eraser toggle function
  const eraserToggled = async () => {
    setMyEraser(!isEraser);
    setMyPencilCircle(false);
    setMyPencilLine(false);
  };

  // Clear WhiteBoard for host
  const clearWhiteBoard = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(false);
    const canvas: any = document.getElementById("canvas");
    if (canvas) {
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }
  };

  // Clear WhiteBoard for all
  const clearWhiteBoardAll = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(false);
    const canvas: any = document.getElementById("canvas");
    if (canvas) {
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }

    // also send the clear function to others in the same twilio room
    const clear = true;
    dataTrackRef.current.send(
      JSON.stringify({
        clear,
      })
    );
  };

  const toggleAudioVideo = async () => {
    setCameraDropdownOpen(!cameraDropdownOpen);
  };

  //Full screen video

  const fullScreenVideo = async () => {
    // if (!!videoObj) {
    //   videoObj.enter()
    // }
    const elem: any = document.getElementsByTagName("video")[0];
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const handleDiagnostics = () => {
    setShowDiagnostics(true);
  }

  const handleDND = async () => {
    // turn off my audio
    const locParticipant = lParticipantRef.current;

    const audioButtonElement: any = document.getElementById(locParticipant.sid + "_audio");
    const videoButtonElement: any = document.getElementById(locParticipant.sid + "_video");
    if (!isDND) {
      if (!localAudioMuteRef.current) { audioButtonElement.click(); }
      if (!localVideoMuteRef.current) { videoButtonElement.click(); }
    } else {
      if (localAudioMuteRef.current) { audioButtonElement.click(); }
      if (localVideoMuteRef.current) { videoButtonElement.click(); }
    }

    console.log('troom.participants :>> ', troom.participants);
    await troom.participants.forEach(dndParticipant)

    // toggling the current dnd variable
    console.log(`isDND : ${isDND}`)
    setMyDND(!isDND)
  }

  const dndParticipant = (participant: any, index: any) => {
    participant.tracks.forEach((publication: any) => {
      if (publication.kind === 'audio') {
        const audioButtonElement = document.getElementById(participant.sid + "_audio");

        if (!isDND) {
          trackUnsubscribed(publication.track)
          if (audioButtonElement) {
            audioButtonElement.className = "icon overlay-audio-off";
          }
        } else {
          const div = document.getElementById(participant.sid);
          trackSubscribed(div, publication.track);

          if (audioButtonElement && publication.track.kind === "audio" && publication.track.isEnabled) {
            audioButtonElement.className = "icon overlay-audio-on";
          }
        }
      }
      if (publication.kind === 'video') {
        const videoButtonElement = document.getElementById(participant.sid + "_video");

        if (!isDND) {
          trackUnsubscribed(publication.track)
          if (videoButtonElement) {
            videoButtonElement.className = "icon overlay-video-off-tray";
          }
          const activeViewers = invitedViewersRef.current;
          showProfileImage(
            activeViewers,
            participant.sid,
            participant.identity
          );
        } else {
          const div = document.getElementById(participant.sid);
          trackSubscribed(div, publication.track);

          if (videoButtonElement && publication.track.kind === "video" && publication.track.isEnabled) {
            videoButtonElement.className = "icon overlay-video-on-tray";
          }
          // Remove profile image when remote participant video unmuted
          const activeViewers = invitedViewersRef.current;
          hideProfileImage(
            activeViewers,
            participant.sid,
            participant.identity
          );
        }
      }
    })
    
  }

  const playPauseVideo = async () => {
    const elem: any = document.getElementsByTagName("video")[0];
    if (elem) {
      if (elem.paused) {
        elem.play();
      } else {
        elem.pause();
      }
      setPlayIconStatus(elem.paused)
    }
  }

  const volumeUpDown = async (event: any) => {
    const elem: any = document.getElementsByTagName("video")[0];
    if (elem) {
      const value = event.currentTarget.value;
      setVolumeLevel(value)
      elem.volume = value / 100;
      if (elem.volume === 0) {
        setVolumeMute(true);
      } else {
        setVolumeMute(false);
      }
    }
  }

  /*
   * PubNub
   */

  const leaveChat = () => {
    // Disconnect from chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: [channels[0]],
      });

      const msgs = messagesRef.current;
      msgs.length = 0;
      setMessages(msgs);

      //Close chat window
      setChatWindow(false);
      setCloseDisable(false);
      setChatDisable(true);
    }
  };

  const leaveAnnotateChat = () => {
    // Disconnect from annotate chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: [channels[1]],
      });

      const msgs = annotateMessagesRef.current;
      msgs.length = 0;
      setAnnotateMessages(msgs);

      //Close chat window
      setAnnotationChatWindow(false);
      setAnnotationCloseDisable(false);
      setAnnotationChatDisable(true);
    }
  };

  const leaveGuestInvite = () => {
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);
  }

  const leavePublisherPanel = () => {
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
  }

  //Participant while joind the vc show the status in pubnub chat
  const vcParticipants = async(participant:any, status:any)  => {
    const user = auth.getUser();
    const uid = get(user, "uid", "");
    let identity = participant.identity.split("_");
    const displayName = identity[1]
    const currTime = new Date().getTime();
      // Update local state with new msg    
      const new_msg: any = {
        message_id: uid + String(currTime),
        userDisplay: "Pacpost",
        description: `${displayName} has ${status} the room`,
        userid: uid,
        imageurl: pacpost_img,
        status:status,
        timetoken:currTime
      };  
      const msgs = [...messagesRef.current]; 
      msgs.push(new_msg);
      setMessages(msgs);
    
  }
  // Group Chat
  const sendMessage = useCallback(
    async (message: any) => {
      if (uploadFiles.length > 0) {
        setFileUploadLoading(true);
        const user = auth.getUser();
        const uid = get(user, "uid", "");
        const displayName = get(user, "displayName", "");
        const currTime = new Date().getTime();
        const result = await pubnub.sendFile({
          channel: channels[0],
          file: uploadFiles[0],
          message: {
            userDisplay: displayName,
            imageurl: user_imageurl,
            message_id: uid + currTime,
            description: "",
            userid: uid, // Needed for old msgs as they dont have publisher id
            publisher: auth.getUserId()
          },
        })

      } else if (message.length > 0) {
        const user = auth.getUser();
        const currTime = new Date().getTime();
        const uid = get(user, "uid", "");
        const displayName = get(user, "displayName", "");
        await pubnub.publish({
          channel: channels[0],
          message: {
            message_id: uid + currTime,
            userDisplay: displayName,
            description: message,
            userid: uid, // Needed for old msgs as they dont have publisher id
            imageurl: user_imageurl,
          },
        });

        await pubnub.signal({
          channel: channels[0],
          message: `typing_off:${displayName}`,
        });
      }

      setUploadFiles([]);
      setInput("");
      setShowEmoji(false);
    },
    [pubnub, channels, uploadFiles, user_imageurl]
  );

  const updateMessage = useCallback(
    async (input, message, messageIndex) => {
      if (input.length > 0) {
        const user = auth.getUser();
        let hideEdit = document.getElementById(
          "edit_button_" + message.timetoken
        );
        hideEdit?.removeAttribute("style");
        const uid = get(user, "uid", "");
        const displayName = get(user, "displayName", "");
        await pubnub.publish({
          channel: channels[0],
          message: {
            message_id: message.message_id,
            userDisplay: displayName,
            description: input,
            userid: uid, // Needed for old msgs as they dont have publisher id
            imageurl: user_imageurl,
            usecase: "update",
            deleted: false,
            is_update: true,
          },
        });

        await pubnub.signal({
          channel: channels[0],
          message: `typing_off:${displayName}`,
        });
      }

      setEditInput("");
      renderMessage(input, messageIndex);
    },
    [pubnub, channels, setEditInput]
  );

  // Annotation Chat
  const sendAnnotationMessage = async (
    message: string,
    annotateNote: string,
    annotateColor: string
  ) => {
    if (annotateNote === "" && message.length < 1) {
      return;
    }

    setAnnotateInput("");

    const user: any = auth.getUser();
    let currTime = new Date().getTime();
    const uid = get(user, "uid", "");
    const displayName = get(user, "displayName", "");
    // Update local state with new msg
    const new_msg: any = {
      publisher: uid,
      message_id: uid + currTime,
      annotationTitle: "Loading...",
      userDisplay: displayName,
      description: message,
      userid: uid,
      imageurl: user_imageurl,
      annotateNote: annotateNote,
      annotateColor: annotateColor,
      timecode: "Loading..."
    };

    const msgs = [...annotateMessagesRef.current];
    msgs.push(new_msg);
    setAnnotateMessages(msgs);

    let dataURI: string = "";
    let timeCodeResponse: any = {};
    dataURI = getTimecodeImage();

    if (dataURI) {
      let result: any = await getTimeCode(dataURI);

      if (result) {
        // Delete the local message
        let filteredMsgs = msgs.filter(
          (msg: any) => msg.message_id !== new_msg.message_id
        );

        setAnnotateMessages([...filteredMsgs]);

        timeCodeResponse = result;

        currTime = new Date().getTime();
        let title = "Annotation Title";
        if (timeCodeResponse && timeCodeResponse.title) {
          title = timeCodeResponse.title;
        }
        const uid = get(user, "uid", "");
        const displayName = get(user, "displayName", "");
        const pubnubResponse = await pubnub.publish({
          channel: channels[1],
          message: {
            message_id: uid + currTime,
            annotationTitle: title,
            userDisplay: displayName,
            description: message,
            userid: uid,
            imageurl: user_imageurl,
            annotateNote: annotateNote,
            annotateColor: annotateColor,
            timecode: timeCodeResponse.timecode
          },
        });

        if (message === "") {
          handleAnnotateEditMessageClick(pubnubResponse.timetoken, message);
        }
      }
    }

    await pubnub.signal({
      channel: channels[1],
      message: `typing_off:${displayName}`,
    });
  };

  const updateAnnotateMessage =
    async (input: any, message: any, messageIndex: any) => {
      const user = auth.getUser();

      let hideEdit = document.getElementById("edit_button" + message.timetoken);
      hideEdit?.removeAttribute("style");
      const uid = get(user, "uid", "");
      const displayName = get(user, "displayName", "");
      const result1 = await pubnub.publish({
        channel: channels[1],
        message: {
          message_id: message.message_id,
          annotationTitle: message.annotationTitle,
          userDisplay: displayName,
          description: input,
          userid: uid, // Needed for old msgs as they dont have publisher id
          imageurl: user_imageurl,
          annotateNote: message.annotateNote,
          annotateColor: message.annotateColor,
          timecode: message.timecode,
          usecase: "update",
          deleted: false,
          is_update: true
        },
      });

      await pubnub.signal({
        channel: channels[1],
        message: `typing_off:${displayName}`,
      });

      setAnnotateEditInput("");
      renderAnnotateMessage(input, messageIndex);
    }

  const clearChat = useCallback(async () => {
    setClearChatModalOpen(false);
    setChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[0]}`;

    const deleteResponse = await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setMessages([]);

    const clearGroupChat = true;
    dataTrackRef.current.send(JSON.stringify({ clearGroupChat }));

    setChatClearLoading(false);

    const user = auth.getUser();
    const currTime = new Date().getTime();
    const uid = get(user, "uid", "");
    const displayName = get(user, "displayName", "");
    await pubnub.publish({
      channel: channels[0],
      message: {
        message_id: uid + currTime,
        userDisplay: displayName,
        description: `${displayName} has just cleared all chat.`,
        userid: uid, // Needed for old msgs as they dont have publisher id
        imageurl: user_imageurl,
      },
    });

    await pubnub.signal({
      channel: channels[0],
      message: `typing_off:${displayName}`,
    });
  }, [channels]);

  const clearAnnotationChat = useCallback(async () => {
    setAnnotateChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[1]}`;

    await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setAnnotateMessages([]);

    const clearAnnotationChat = true;
    dataTrackRef.current.send(JSON.stringify({ clearAnnotationChat }));

    setAnnotateChatClearLoading(false);
  }, [channels]);
 
  const handleUploadCancel =() =>{
    setUploadAlert(false);
    setInput("");
    setUploadFiles([]);
  };

  const handleUploadConfirm = async (event: any) =>{
    setUploadAlert(false);
 }

  const onChangeMessage = async (event: any, input: any) => {
    if (event.target.files) {
      setUploadAlert(true);
      let files: any = event.target.files;
      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();
        let file = files[i];
        setFileName(file.name)
        reader.onloadend = () => {
          setUploadFiles([file]);
        }
        reader.readAsDataURL(file);
      }

    }
    //setbrowseInput(false);
    setInput(input);
    const user = auth.getUser();
    const displayName = get(user, "displayName", "");
    await pubnub.signal({
      channel: channels[0],
      message: `typing_on:${displayName}`,
    });
  };

  const onChangeEditMessage = async (input: string) => {
    setEditInput(input);
    const user = auth.getUser();
    const displayName = get(user, "displayName", "");
    await pubnub.signal({
      channel: channels[0],
      message: `typing_on:${displayName}`,
    });
  };

  const onChangeAnnotateMessage = async (input: string) => {
    setAnnotateInput(input);
    const user = auth.getUser();
    const displayName = get(user, "displayName", "");
    await pubnub.signal({
      channel: channels[1],
      message: `typing_on:${displayName}`,
    });
  };

  const onChangeAnnotateEditMessage = async (input: string) => {
    setAnnotateEditInput(input);
    const user = auth.getUser();
    const displayName = get(user, "displayName", "");
    await pubnub.signal({
      channel: channels[1],
      message: `typing_on:${displayName}`,
    });
  };

  const getAllMessages = async (
    timetoken: string,
    pubnubObj?: any,
    channelsArray?: any
  ) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[0],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allMessages;
        allMsgs.push(...historyResponse.messages);
        setAllMessages(allMsgs);
        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const getAllAnnotateMessages = async (
    timetoken: string,
    pubnubObj?: any,
    channelsArray?: any
  ) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[1],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allAnnotateMessages;
        allMsgs.push(...historyResponse.messages);
        setAllAnnotateMessages(allMsgs);

        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllAnnotateMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const addEmoji = (emoji: any) => {
    setInput(input + emoji.native);
    sendTextMessageInput?.current?.focus();
  }

  const downloadMessages = async () => {
    setChatExportLoading(true);

    await getAllMessages("");

    const newLine = '\n';
    const newTab = '\t';

    const allMessagesList = allMessages.map((message: any) => {
      return {
        userDisplay: `${newLine}${message.entry.userDisplay}${newTab}`,
        description: message.entry.description,
        timetoken: `${newLine}${convertDateTimeFromUTCEpoc(Math.ceil(parseInt(message.timetoken) / 10000))}`
      }
    })

    const fields = [
      'userDisplay',
      'description',
      'timetoken'
    ]

    const json2csvParser = new Parser({
      delimiter: '',
      fields: fields,
      quote: ''
    });

    const finalCSV = json2csvParser.parse(allMessagesList);

    const fileBlob = new Blob([finalCSV.replaceAll(",", " ")], {
      type: "application/octet-binary",
    });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = "_blank";
    hiddenElement.download = `${streamLabel}.txt`;
    hiddenElement.click();

    const user = auth.getUser();
    const displayName = get(user, "displayName", "");
    sendMessage(`${displayName} exported chat`);

    setChatExportLoading(false);
  }

  const downloadAnnotateMessages = async () => {
    setAnnotateLoading(true);

    await getAllAnnotateMessages("");
    // Now we need to filter only annotate messages which are not deleted and replace the updated messages
    allAnnotateMessages = allAnnotateMessages
      .map((annotateMsg) => annotateMsg.entry)
      .filter((msg) => msg.annotateNote && msg.annotateNote !== "");

    const msgs: any[] = [];
    for (let msg of allAnnotateMessages) {
      const old_msg: any = msg;
      old_msg.version = "v1";
      old_msg.number = 1;
      let outerIndex = -1;
      msgs.forEach((iMsg, index) => {
        if (
          msg.message_id &&
          iMsg.message_id &&
          iMsg.message_id == msg.message_id
        ) {
          outerIndex = index;
          return index;
        }
      });

      if (outerIndex < 0) {
        msgs.push(old_msg);
      } else {
        if (msg.deleted) {
          msgs.splice(outerIndex, 1);
        } else {
          msgs[outerIndex] = old_msg;
        }
      }
    }

    setAllAnnotateMessages([]);

    const fields = [
      'userDisplay',
      'annotationTitle',
      'version',
      'annotateColor',
      'description',
      'number',
    ]

    const json2csvParser = new Parser({
      delimiter: '\t',
      fields: fields,
      quote: ''
    });


    const finalCSV = json2csvParser.parse(msgs);

    var fileBlob = new Blob([finalCSV.replaceAll(",", " ")], {
      type: "application/octet-binary",
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = "_blank";
    hiddenElement.download = `Annotation Title.txt`;
    hiddenElement.click();

    setVFXcolor("red");
    setSFXcolor("red");
    setMXcolor("red");
    setPIXcolor("red");
    setAnnotateLoading(false);
  };

  // JOIN PUBNUB
  const joinPubNubChat = async (user: any, roomName: string) => {
    let new_pubnub;
    const uid = get(user, "uid", "");
    new_pubnub = new PubNub({
      publishKey: process.env.REACT_APP_PN_PUBLISH || "",
      subscribeKey: process.env.REACT_APP_PN_SUBSCRIBE || "",
      uuid: uid,
      ssl: true,
    });

    if (!new_pubnub) {
      return;
    }
    setPubNub(new_pubnub);

    const mainChannelName = roomName;
    const annotateChannelName = `${roomName}_tc`;

    let new_channels = [mainChannelName];
    if (isFeatureIncluded(FEATURE_NAME.TIME_CODE, show_id)) {
      new_channels.push(annotateChannelName);
    }
    setChannels(new_channels);

    // Subscribe to channel
    new_pubnub.subscribe({ channels: new_channels, withPresence: true });

    // Fetch if you have any recent messages (Max 25 msg per channel)
    const recentmsg_responses: any = await new_pubnub.fetchMessages({
      channels: new_channels,
      count: 100,
    });

    if (recentmsg_responses && recentmsg_responses.channels) {
      const channel_msgs = recentmsg_responses.channels;

      if (channel_msgs && channel_msgs[mainChannelName]) {
        const recet_msgs: any[] = channel_msgs[mainChannelName] || [];

        const msgs = messagesRef.current;
        for (let msg of recet_msgs) {
          const old_msg: any = msg.message;

          // Recent msgs doesn't have publisher id, so need to pass userid in message
          old_msg.publisher = old_msg.userid;
          old_msg.timetoken = msg.timetoken;

          if (msg.channel === mainChannelName) {
            let outerIndex = -1;
            msgs.forEach((iMsg, index) => {
              if (
                msg.message.message_id &&
                iMsg.message_id &&
                iMsg.message_id === msg.message.message_id
              ) {
                outerIndex = index;
                return index;
              }
            });

            if (outerIndex < 0) {
              if (!old_msg.deleted) {
                msgs.push(old_msg);
              }
            } else {
              if (old_msg.deleted) {
                msgs.splice(outerIndex, 1);
              } else {
                msgs[outerIndex] = old_msg;
              }
            }
          }
        }

        setMessages([...msgs]);
      }

      if (channel_msgs && channel_msgs[annotateChannelName]) {
        const recet_msgs: any[] = channel_msgs[annotateChannelName] || [];

        const msgs = annotateMessagesRef.current;
        for (let msg of recet_msgs) {
          const old_msg: any = msg.message;

          // Recent msgs doesn't have publisher id, so need to pass userid in message
          old_msg.publisher = old_msg.userid;
          old_msg.timetoken = msg.timetoken;

          if (msg.channel === annotateChannelName) {
            let outerIndex = -1;
            msgs.forEach((iMsg, index) => {
              if (
                msg.message.message_id &&
                iMsg.message_id &&
                iMsg.message_id === msg.message.message_id
              ) {
                outerIndex = index;
                return index;
              }
            });

            if (outerIndex < 0) {
              if (!old_msg.deleted) {
                msgs.push(old_msg);
              }
            } else {
              if (old_msg.deleted) {
                msgs.splice(outerIndex, 1);
              } else {
                msgs[outerIndex] = old_msg;
              }
            }
          }
        }

        setAnnotateMessages([...msgs]);
      }
    }

    // Listen to messages
    new_pubnub.addListener({
      message: (messageEvent: any) => {
        const new_msg: any = messageEvent.message;
        new_msg.publisher = messageEvent.publisher;
        new_msg.timetoken = messageEvent.timetoken;

        if (messageEvent.channel === mainChannelName) {
          const msgs = messagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg, index) => {
            if (
              new_msg.message_id &&
              iMsg.message_id &&
              iMsg.message_id === new_msg.message_id
            ) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setMessages([...msgs]);
        }
        if (messageEvent.channel === annotateChannelName) {
          const msgs = annotateMessagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg, index) => {
            if (
              new_msg.message_id &&
              iMsg.message_id &&
              iMsg.message_id === new_msg.message_id
            ) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setAnnotateMessages([...msgs]);
        }
      },
      signal: (signal: any) => {
        var message = signal.message; // The Payload
        var publisher = signal.publisher; //The Publisher

        if (publisher !== auth.getUserId()) {
          var signalName = message.split(":")[0];

          if (signalName === "typing_on") {
            var senderName = message.split(":")[1];
            if (senderName) {
              setTypingInd(senderName + " typing ...");
            }
          } else if (signalName === "typing_off") {
            setTypingInd("");
          }
        }
      },
      // @ts-ignore
      file: (event: any) => {
        const all_msgs = [...messagesRef.current]
        all_msgs.push(event)
        setMessages([...all_msgs])
        setFileUploadLoading(false);
      }
    });
    return new_pubnub;
  };

  /*
   * Timecode Annotation Supported Actions
   */

  const getTimeCode = async (dataURI: string) => {
    if (!dataURI) {
      return;
    }
    try {
      const data = {
        api: api.streams.timeCode,
        payLoad: JSON.stringify({ show_id: showId, file: dataURI }),
      };

      const result: any = await backend.save(data, get(currentUser, "user.accessToken", ""));
      return result;
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTimecodeImage = () => {
    const video_player = document.getElementById("player") as HTMLVideoElement;

    const canvas = document.getElementById("canvas") as HTMLCanvasElement;

    const v_canvas = document.createElement("canvas") as HTMLCanvasElement;
    const canvasWidth = get(canvas, "width", 0);
    const canvasHeight = get(canvas, "height", 0);
    if (!!v_canvas) {
      v_canvas.width = canvasWidth;
      v_canvas.height = canvasHeight;
    }

    let dataURI: any = "";
    if (video_player && v_canvas) {
      // get complete image
      const v_canvasWidth = get(v_canvas, "width", "");
      const v_canvasHeight = get(v_canvas, "height", 0);
      const ctx = v_canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(video_player, 0, 0, v_canvasWidth, v_canvasHeight);
      var imageData = ctx.getImageData(0, 0, v_canvasWidth, 30);

      // destination canvas
      const img_capture_canvas = document.createElement(
        "canvas"
      ) as HTMLCanvasElement;
      if (!!img_capture_canvas) {
        img_capture_canvas.width = v_canvasWidth;
        img_capture_canvas.height = 30;
      }
      const ctx1 = img_capture_canvas.getContext(
        "2d"
      ) as CanvasRenderingContext2D;
      ctx1.putImageData(imageData, 0, 0);

      // Convert to desired file format
      dataURI = img_capture_canvas.toDataURL("image/jpg");

      img_capture_canvas.parentNode?.removeChild(img_capture_canvas);

      v_canvas.parentNode?.removeChild(v_canvas);

      // Clear the captured image at the end
      ctx.clearRect(0, 0, v_canvasWidth, v_canvasHeight);
    }

    return dataURI;
  };

  /*
   ****************************
   * VC Supported Actions
   ****************************
   */

  const getPlayerBox = (rect: DOMRect, ratio: number): DOMRect => {
    let r = new DOMRect();
    const rectWidth = get(rect, "width", 0);
    const rectHeight = get(rect, "height", 0);
    let ar = rectWidth / rectHeight;

    if (ar < ratio) {
      let vidh = rectWidth / ratio;
      r.width = rectWidth; // Width is OK
      r.height = Math.floor(vidh); // We know the aspect ratio so we can calculate the height
      r.x = 0; // The width fits
      r.y = Math.floor((rectHeight - vidh) / 2); // But there's a vertical gap
    } else {
      let vidw = rectHeight * ratio;
      r.width = Math.floor(vidw); // We know the aspect ratio so we can calculate the width
      r.height = rectHeight; // Height is OK
      r.x = Math.floor((rectWidth - vidw) / 2); // Horizontal gap
      r.y = 0; // No vertical gap
    }

    return r;
  }

  const playbackStarted = () => {
    const video_player: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;

    if (!!video_player && !!video_player.videoHeight && video_player.videoHeight !== 0) {
      let new_ratio = video_player.videoWidth / video_player.videoHeight;
      vratioRef.current = new_ratio;
      const canvas: any = document.getElementById("canvas");
      renderCanvas(canvas, new_ratio);
    }
  }

  const renderCanvas = (canvas: any, ratio: number) => {
    const video_player: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;

    if (video_player && !!canvas) {
      // The width and height of the video element
      let bb = video_player?.getBoundingClientRect();
      let player_box = getPlayerBox(bb, ratio);
      const player_box_width = get(player_box, "width", 0);
      const player_box_height = get(player_box, "height", 0);
      canvas.width = player_box_width;
      canvas.height = player_box_height;

      canvas.style.width = `${player_box_width}px`;
      canvas.style.height = `${player_box_height}px`;
      canvas.style.marginTop = `${player_box.y}px`;
      canvas.style.marginLeft = `${player_box.x}px`;
      return { w: player_box_width, h: player_box_height };
    }
    return { w: 0, h: 0 };
  };

  const setupCanvas = () => {
    const canvas: any = document.getElementById("canvas");

    if (canvas) {

      const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

      window.addEventListener("resize", () => {

        inMemCanvas.width = canvas.width;
        inMemCanvas.height = canvas.height;
        const memCtx = inMemCanvas.getContext("2d");
        console.log("memCtx", memCtx, canvas);
        if (memCtx) {
          memCtx.drawImage(canvas, 0, 0);
        }

        const size = renderCanvas(canvas, vratioRef.current); // The new size
        if (memCtx) {
          let ctx2: CanvasRenderingContext2D = canvas.getContext("2d");
          ctx2.drawImage(inMemCanvas, 0, 0, size.w, size.h);
        }

      });
    }
  }

  const videoReady = () => {
    const video_player: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;

    video_player.addEventListener('loadedmetadata', playbackStarted);
    setupCanvas();
  }

  const gotDevices = (mediaDevices: any) => {
    let cameras: any = [];
    let microphones: any = [];
    let count = 1;
    mediaDevices.forEach((mediaDevice: any) => {
      if (mediaDevice.kind === 'videoinput') {
        if (mediaDevice.deviceId === 'default') {
          setCameraName(mediaDevice.label);
        }
        cameras.push({
          value: mediaDevice.deviceId,
          text: mediaDevice.label,
          key: mediaDevice.label
        });
      } else if (mediaDevice.kind === 'audioinput') {
        if (mediaDevice.deviceId == "default") {
          setMicName(mediaDevice.label);
        }
        microphones.push({
          value: mediaDevice.deviceId,
          text: mediaDevice.label,
          key: mediaDevice.label
        });
      }
    });
    cameras.push({
      value: "DESKTOP",
      text: "Share Your Desktop",
      key: "desktop"
    });

    _setCameraOptions(cameras);
    _setMicOptions(microphones);
  }

  const changeLocalView = (pid: string, track: LocalVideoTrack) => {
    const div = document.getElementById(pid);
    // Look for the video element for the local preview div:
    if (div) {
      const preview = div.getElementsByTagName('video');

      const vid_elem = track.attach();
      div.removeChild(preview[0]);
      div.appendChild(vid_elem);
    }
  }

  const changeLocalAudio = (pid: string, track: LocalAudioTrack) => {
    const div = document.getElementById(pid);
    // Look for the audio element for the local preview div:
    if (div) {
      const preview = div.getElementsByTagName('audio');
      div.removeChild(preview[0]);
      div.appendChild(track.attach());
    }
  }

  const getImage = (): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      const bgImg = backgrounds.filter((item: any) => (item.value === activeBGRef.current));
      img.src = bgImg[0]?.image
    });
  };

  const changeCameraTrack = async(cameraId: string | undefined) => {
    const localParticipant = troomRef.current.localParticipant;
    
    if (cameraId === "DESKTOP") {
      // @ts-ignore
      navigator.mediaDevices.getDisplayMedia({ video: { height: 720, frameRate: 24, width: 1280 }, audio: true }).then((stream: any) => {
        const localVideoTrack = new LocalVideoTrack(stream.getTracks()[0]);
        const tracks: Array<LocalVideoTrackPublication> = Array.from(localParticipant.videoTracks.values());
        localParticipant.unpublishTrack(tracks[0].track);
        localParticipant.publishTrack(localVideoTrack).then((publication: LocalVideoTrackPublication) => {
          changeLocalView(localParticipant.sid, localVideoTrack);
        });
      });
    } else {
      if(!localVideoMuteRef.current){
      let localVideoTrack: LocalVideoTrack;
      if (!!cameraId) {
        localVideoTrack = await createLocalVideoTrack({
          deviceId: { exact: cameraId }
        });
      } else {
        localVideoTrack = await createLocalVideoTrack();
        setCameraId("");
      }
      const tracks: Array<LocalVideoTrackPublication> = Array.from(localParticipant.videoTracks.values());
      const bgVideoTrack = await applyBGFilter(localVideoTrack, tracks);
      setLocalParticipantTrack(bgVideoTrack);
      localParticipant.unpublishTrack(tracks[0].track);
      localParticipant.publishTrack(bgVideoTrack).then((publication: LocalVideoTrackPublication) => {
        changeLocalView(localParticipant.sid, bgVideoTrack);
      });
    }
    }
  }

  const applyBGFilter = async (localVideoTrack: LocalVideoTrack, tracks: any) => {
    if (activeBGRef.current === 'none') {
      // remove processor -- not needed to do anything
      //localVideoTrack.removeProcessor(tracks[0].track.processor);
    } else if (activeBGRef.current === 'blur') {
      blurProcessor = new GaussianBlurBackgroundProcessor({
        assetsPath: virtualBackgroundAssets,
      });
      await blurProcessor.loadModel();
      localVideoTrack.addProcessor(blurProcessor);
    } else {
      virtualBackgroundProcessor = new VirtualBackgroundProcessor({
        assetsPath: virtualBackgroundAssets,
        backgroundImage: await getImage(),
        fitType: ImageFit.Fill,
      });
      await virtualBackgroundProcessor.loadModel();
      localVideoTrack.addProcessor(virtualBackgroundProcessor);
    }

    return localVideoTrack;
  }

  const changeCamera = (e: any, v: any) => {
    setCameraName(v.name);
    setCameraDropdownOpen(!cameraDropdownOpen);
    
    if (localVideoMute) {
      setCameraId(v.value);
      return;
    }

    // Video isn't muted - go ahead and do the switcheroo
    changeCameraTrack(v.value);
  }

  const changeMicrophoneTrack = (microphoneId: string) => {
    const localParticipant = troomRef.current.localParticipant;
    createLocalAudioTrack({
      deviceId: { exact: microphoneId }
    }).then(function (localAudioTrack) {

      const tracks: Array<LocalAudioTrackPublication> = Array.from(localParticipant.audioTracks.values());
      localParticipant.unpublishTrack(tracks[0].track);
      localParticipant.publishTrack(localAudioTrack);
      const previewContainer = document.getElementById('local-media');
      changeLocalAudio(localParticipant.sid, localAudioTrack);
    });
  }

  const changeMicrophone = (e: any, v: any) => {
    setMicName(v.name);
    setCameraDropdownOpen(!cameraDropdownOpen);
    
    if (localAudioMute) {
      setMicId(v.value);
      return;
    }
    changeMicrophoneTrack(v.value);
  }


  const joinTwilioRoom = async (
    stream_vc_room: any,
    participant_type: string
  ) => {
    try {
      if (!stream_vc_room) {
        return;
      }

      setLoading(true);

      // 1. Get VGT
      const twilio_room_name: string = stream_vc_room.show_vc_room_id;

      const user = auth.getUser();
      const uid = get(user, "uid", "");
      const displayName = get(user, "displayName", "");
      const identity = `${participant_type}#${uid}_${displayName}`;
      const data = {
        api: api.vc.getTwilioVGT,
        queryParam: {
          show_id,
          identity: identity,
          roomName: twilio_room_name,
        },
      };
      const token: string = await backend.fetch(data, get(currentUser, "user.accessToken", ""));

      // 2. Create Local Tracks at the time of room creation
      let localTracks: any;
      try {
        localTracks = await createLocalTracks({
          audio: true,
          video: true,
        });
      } catch (err: any) {
        try {
          localTracks = await createLocalTracks({
            audio: true,
            video: false,
          });
        } catch (err: any) {
          console.log(err);
          localTracks = await createLocalTracks({
            audio: false,
            video: false,
          });
        }
      }

      // 3. Setup Local data track
      const dataTrack = setupLocalDataTrack();
      setDataTrack(dataTrack);

      // 4. Merge all tracks
      const tracks = localTracks.concat(dataTrackRef.current);

      // Query local media
      navigator.mediaDevices.enumerateDevices().then(gotDevices);
      console.log("troom.participants", roomParticipantsList)
      // 5. Create Room
      const new_room: any = await connect(token, {
        name: twilio_room_name,
        tracks: tracks,
        video: { height: 720, frameRate: 24, width: 1280 },
        bandwidthProfile: {
          video: {
            mode: 'collaboration',
            dominantSpeakerPriority: 'standard'
          }
        },
        dominantSpeaker: true,
        maxAudioBitrate: 16000, //For music remove this line
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: {
          local: 1,
          remote: 1
        },
      });
      //let resultPubnub = null;
      if (new_room) {
        setTRoom(new_room);

        // 6. Disable audio and video by default
        localTracks.forEach((track: any) => {
          if (track.isEnabled) {
            track.disable();
          }
        });

        // Pubnub
        joinPubNubChat(user, twilio_room_name);
      }

      setLoading(false);

      return new_room;
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const toggleAudioMute = (localParticipant: any, audioButtonElement: any, nameTextElement: any) => {
    localParticipant.tracks.forEach((publication: any) => {
      if (publication.kind === "audio") {
        if (publication.isTrackEnabled) {
          publication.track.disable();
          audioButtonElement.className = "icon overlay-audio-off";
          setLocalAudioMute(true);

          Mixpanel.track("Viewer VCControl Action", {
            Platform: "Web",
            "VC Control Type": "Audio",
            "VC Control Action": "Mute",
            "VC Type": "Stream",
            "Stream Label": streamLabel,
          });
        } else {
          if (micIdRef.current != "") {
            publication.track.enable();
            if (micIdRef.current) {
              changeMicrophoneTrack(micIdRef.current);
            }
            setMicId("");
          } else {
            publication.track.enable();
          }
          audioButtonElement.className = "icon overlay-audio-on";
          setLocalAudioMute(false);

          Mixpanel.track("Viewer VCControl Action", {
            Platform: "Web",
            "VC Control Type": "Audio",
            "VC Control Action": "UnMute",
            "VC Type": "Stream",
            "Stream Label": streamLabel,
          });
        }

        if (localAudioMuteRef.current || localVideoMuteRef.current) {
          nameTextElement.className = "overlay-text-red prevent-textcopy";
          dataTrackRef.current.send(
            JSON.stringify({
              nameTextId: nameTextElement.id,
              className: nameTextElement.className,
            })
          );
        } else {
          nameTextElement.className = "overlay-text prevent-textcopy";
          dataTrackRef.current.send(
            JSON.stringify({
              nameTextId: nameTextElement.id,
              className: nameTextElement.className,
            })
          );
        }
      }
    });
  }

  const handelVideoConfStart = async (
    stream_vc_room: any,
    stream_id: string,
    participant_type: string
  ) => {
    try {
        setLoading(true);
        const new_room = await joinTwilioRoom(stream_vc_room, participant_type);
  
        // Update room name in database so other viewer can auto join
        await updateTwilioRoom(
          stream_vc_room,
          stream_vc_room.show_vc_room_id,
          stream_id
        );
  
        // Pull details to create state
        await fetchVCRoomDetails(stream_id);
  
        // Manage Room
        if (new_room) {
          manageRoom(new_room);
        }
  

      setLoading(false);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const startOrJoinVC = async (
    stream_vc_room: any,
    stream_id: string,
    stream_label: string,
    participant_type: string
  ) => {
    try {

      if (
        troom &&
        !isEmpty(troom) &&
        troom.name === stream_vc_room.twilio_room_name
      ) {
        const canvas: any = document.getElementById("canvas");
        renderCanvas(canvas, vratioRef.current);
        return;
      }

      if (stream_vc_room && stream_vc_room.twilio_room_name) {

        // Join VC
        const room = await joinTwilioRoom(stream_vc_room, participant_type);       
        if (room) {
          manageRoom(room);

          Mixpanel.track("Viewer Join VC", {
            Platform: "Web",
            "VC Start Action": "Join Call",
            "VC Type": "Stream",
            "Stream Label": stream_label,
          });
        }
      } else {
        // Start VC (Join vc and update twilio room link in backend)
        await handelVideoConfStart(stream_vc_room, stream_id, participant_type);

        Mixpanel.track("Viewer Join VC", {
          Platform: "Web",
          "VC Start Action": "Start Call",
          "VC Type": "Stream",
          "Stream Label": stream_label,
        });
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchVCRoomDetails = async (stream_id: string) => {
    try {
      const data = {
        api: api.vc.activeStreamVCRoom,
        queryParam: { show_id, showStream_id: stream_id },
      };

      setLoading(true);
      const stream_vc_room: any = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

      if (stream_vc_room) {
        setActiveStreamVCRoom(stream_vc_room);
      }

      return stream_vc_room;
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const isUserInvited = async (stream_vc_room: any) => {
    try {
      setLoading(true);
      const data = {
        api: api.vc.getVCRoomParticipant,
        queryParam: {
          show_id,
          show_vc_room_id: stream_vc_room.show_vc_room_id,
        },
      };

      const participant: any = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      if (participant) {
        setLoading(false);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    } catch (err: any) {
      setLoading(false);
      throw err;
    }
  };

  const fetchAllActiveStreamUsers = async (stream_id: any) => {
    try {
      const data = {
        api: api.streams.listAllActiveViewersOfStream,
        queryParam: {
          show_id: show_id,
          stream_id: stream_id,
        },
      };
      setLoading(true);
      const invitedViewersData = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      if (invitedViewersData) {
        setInvitedViewers(invitedViewersData);
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchVCRoomAndStartOrJoin = async (
    stream_id: string,
    stream_label: string
  ) => {
    try {
      setLoading(true);
      let stream_vc_room: any = await fetchVCRoomDetails(stream_id);

      if (stream_vc_room) {
        setActiveStreamVCRoom(stream_vc_room);

        // Switching Stream
        if (troom && !isEmpty(troom)) {
          if (troom.name !== stream_vc_room.twilio_room_name) {
            // Disconnect from old one
            await troom.disconnect();

            // Leave existing chat channels
            leaveChat();
            leaveAnnotateChat();
            leaveGuestInvite();
            leavePublisherPanel();
          }
        }

        // Are you part of the stream ?
        const invited = await isUserInvited(stream_vc_room);
        if (!invited) {
          setUserInvited(false);
          alert("You are not invited to the stream");
          throw new Error("You are not invited to the stream");
        }
        setUserInvited(true);

        const owner_user_id: string = stream_vc_room.owner_user_id;
        if (owner_user_id === auth.getUserId()) {
          setIsVCHost(true);

          await startOrJoinVC(stream_vc_room, stream_id, stream_label, "admin");
        } else {
          setIsVCHost(false);

          await startOrJoinVC(
            stream_vc_room,
            stream_id,
            stream_label,
            "member"
          );
        }

        // Open chat window
        setChatWindow(true);
        setCloseDisable(true);
        setChatDisable(false);
      } else {
        console.log("No room created at the time of stream creation");
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const dominantSpeakerChanged = (participant: any) => {
    let track: any = {};


    if (!participant) {
      setDominantSpeakerID("");
      setDominantSpeakerTrack({});
      return;
    }

    participant.tracks.forEach((publication: any) => {
      if (publication.kind === 'video') {
        track = publication.track;
      }
    })

    setDominantSpeakerTrack(track);

    setDominantSpeakerID(participant.sid);
    // Get speaker div
    const dominatSpeakerDiv = document.getElementById(participant.sid);

    const vtray = document.getElementById("vtray");
    if (dominatSpeakerDiv && vtray) {
      // Switch only if dominant speaker not in screen
      if (!isViewInScreen(dominatSpeakerDiv)) {
        vtray.removeChild(dominatSpeakerDiv);

        // Switch to second place.
        const localparticipant = vtray.children[2];
        localparticipant.parentNode?.insertBefore(
          dominatSpeakerDiv,
          localparticipant.nextSibling
        );
      } else {
        console.log("In the screen, no switch");
      }
    }
  };

  //Show profile image when remote participant mutes video
  const showProfileImage = async (activeViewers: any, sid: any, uid: any) => {
    if (uid) {
      uid = /\#(.*?)\_/.exec(uid);
      uid = uid[1];
    }
    const remoteparticipantDiv = document.getElementById(sid);
    if (remoteparticipantDiv) {
      var disableVideoEle = remoteparticipantDiv.getElementsByTagName("video");
      if (disableVideoEle && disableVideoEle[0]) {
        disableVideoEle[0].classList.add("hide-profile");
      }
    }
    if (activeViewers) {
      activeViewers.forEach((viewer: any) => {
        if (viewer && viewer.user_id === uid) {
          //const user_profile_img = document.getElementById(sid + "_image");
          if (!!viewer.user_image_url_path) {
              const image = document.createElement("img");
              image.src = viewer.user_image_url_path;
              image.id = sid + "_image";
              image.alt = "Profile image shown here";
              image.style.minWidth = "239px";
              image.setAttribute('class', 'profile_img11');
              if (remoteparticipantDiv) {
                remoteparticipantDiv.insertBefore(
                  image,
                  remoteparticipantDiv.childNodes[0]
                );
              }
            } else {
              let length = 11;
              let trimmedString = viewer?.user_name ? viewer.user_name.user_name_first +" "+ viewer.user_name.user_name_last : 'User';
              if (trimmedString.length > 11) {
                trimmedString = trimmedString.substring(0, length).concat("...");
              }
              const image = document.createElement("div");
              image.id = sid + "_image";
              image.style.minWidth = "244px";
              image.title= viewer?.user_name ? viewer.user_name.user_name_first +" "+ viewer.user_name.user_name_last : 'User';
              image.innerHTML = trimmedString;
              image.setAttribute('class', 'profile_name');
              if (remoteparticipantDiv) {
                remoteparticipantDiv.insertBefore(
                  image,
                  remoteparticipantDiv.childNodes[0]
                );
              }
          }
        }
      });
    }
  };

  //hide profile image when remote participant unmutes video
  const hideProfileImage = async (activeViewers: any, sid: any, uid: any) => {
    if (uid) {
      uid = /\#(.*?)\_/.exec(uid);
      uid = uid[1];
    }
    const remoteparticipantDiv = document.getElementById(sid);
    if (remoteparticipantDiv) {
      var disableVideoEle = remoteparticipantDiv.getElementsByTagName("video");
      disableVideoEle[0]?.classList.remove("hide-profile");
    }
    activeViewers.map((viewer: any) => {
      if (viewer.user_id == uid) {
        const del_prof_img = document.getElementById(sid + "_image");
        if (del_prof_img) {
          if (remoteparticipantDiv) {
            remoteparticipantDiv.removeChild(del_prof_img);
          }
        }
      }
    });
  };

  // Hide the remote video and show avatar
  const handleRemoteTrack = (remotetrack: any, remoteparticipant: any) => {
    remotetrack.on("disabled", () => {
      // Audio Disabled
      if (remotetrack.kind === "audio" && !remotetrack.isEnabled) {
        const audioButtonElement = document.getElementById(
          remoteparticipant.sid + "_audio"
        );
        if (audioButtonElement) {
          audioButtonElement.className = "icon overlay-audio-off";
        }

        // If admin is mute then we are not sure whether it is global mute, can not use identity tag approach here
      }

      // Video Disabled
      if (remotetrack.kind === "video" && !remotetrack.isEnabled) {
        const videoButtonElement = document.getElementById(
          remoteparticipant.sid + "_video"
        );
        if (videoButtonElement) {
          videoButtonElement.className = "icon overlay-video-off-tray";

          const remoteparticipantDiv = document.getElementById(
            remoteparticipant.sid
          );

          const vtray = document.getElementById("vtray");
          if (remoteparticipantDiv) {
            vtray?.removeChild(remoteparticipantDiv);
            vtray?.appendChild(remoteparticipantDiv);
          }
        }
        const fullscreenButtonElement = document.getElementById(
          remoteparticipant.sid + "_fullscreen"
        );
        if (fullscreenButtonElement) {
          fullscreenButtonElement.className = "icon overlay-fullscreen-off-tray";
        }

        const activeViewers = invitedViewersRef.current;
        //remotetrack.removeProcessor(remotetrack.processor);
        showProfileImage(
          activeViewers,
          remoteparticipant.sid,
          remoteparticipant.identity
        );
      }

      if (remotetrack.kind === "video" && dominantSpeakerIDRef.current === remoteparticipant.sid) {
        setDominantSpeakerTrack(remotetrack);
        setDominantSpeakerID(dominantSpeakerIDRef.current);
        setShowStreamUpdate(showStreamUpdateRef.current + 1);
      }

    });

    remotetrack.on("enabled", () => {
      let audioTrackEnabled = false, videoTrackEnabled = false;
      // Audio Enabled
      if (remotetrack.kind === "audio" && remotetrack.isEnabled) {
        audioTrackEnabled = true;
        const audioButtonElement = document.getElementById(
          remoteparticipant.sid + "_audio"
        );
        if (audioButtonElement) {
          audioButtonElement.className = "icon overlay-audio-on";
        }
      }

      // Video Enabled
      if (remotetrack.kind === "video" && remotetrack.isEnabled) {
        videoTrackEnabled = true;
        const videoButtonElement = document.getElementById(
          remoteparticipant.sid + "_video"
        );
        const fullscreenButtonElement = document.getElementById(
          remoteparticipant.sid + "_fullscreen"
        );
        if (videoButtonElement) {
          const remoteparticipantDiv = document.getElementById(
            remoteparticipant.sid
          );

          const vtray = document.getElementById("vtray");

          if (vtray?.children[2]) {
            if (remoteparticipantDiv) {
              // list of all un muted video trays
              var audEle = document.querySelectorAll(".overlay-video-on-tray");
              if (audEle.length > 0) {
                var audId = audEle[audEle.length - 1].id;
                audId = audId.replace("_video", "");

                //last un muted video tray
                var lastVideoOnPerson = document.getElementById(audId);
                var isnextDiv = lastVideoOnPerson?.nextSibling;
                if (isnextDiv != null) {
                  vtray?.insertBefore(remoteparticipantDiv, isnextDiv);
                  videoButtonElement.className = "icon overlay-video-on-tray";
                  if (fullscreenButtonElement) {
                    fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";
                  }
                } else {
                  vtray?.removeChild(remoteparticipantDiv);
                  vtray?.appendChild(remoteparticipantDiv);
                  videoButtonElement.className = "icon overlay-video-on-tray";
                  if (fullscreenButtonElement) {
                    fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";
                  }
                }
              } else {
                //if everyone is in mute then we need to move 2nd tray
                const localparticipant = vtray.children[2];
                localparticipant.parentNode?.insertBefore(
                  remoteparticipantDiv,
                  localparticipant.nextSibling
                );
                videoButtonElement.className = "icon overlay-video-on-tray";
                if (fullscreenButtonElement) {
                  fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";
                }
              }
            }
          } else {
            videoButtonElement.className = "icon overlay-video-on-tray";
            if (fullscreenButtonElement) {
              fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";
            }
          }
        }

        // Remove profile image when remote participant video unmuted
        const activeViewers = invitedViewersRef.current;
        hideProfileImage(
          activeViewers,
          remoteparticipant.sid,
          remoteparticipant.identity
        );
      }

      if(audioTrackEnabled && videoTrackEnabled) {
        const nameTextElement = document.getElementById(
          remoteparticipant.sid + "_name"
        );
        if (nameTextElement) {
          nameTextElement.className = "overlay-text prevent-textcopy";
        }
      }

      if (remotetrack.kind === "video" && dominantSpeakerIDRef.current === remoteparticipant.sid) {
        setDominantSpeakerTrack(remotetrack);
        setDominantSpeakerID(dominantSpeakerIDRef.current);
        setShowStreamUpdate(showStreamUpdateRef.current + 1);
      }
    });
  };

  const switchToFullScreen = (elem: any, sid: any) => {
    if (!elem) {
      return;
    }

    // This looks like the easiest way to determine whether the video we're trying
    // to switch to full screen is currently muted. I said "easy", not "good."
    const mutedImage = document.getElementById(sid + "_image");

    if (mutedImage) {
      return;
    }

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  const participantConnected = (participant: any) => {

    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({ sid: participant.sid, identity: participant.identity, state: participant.state });
    setRoomParticipantsList(roomParticipantsList);

    const roomParticipants = [...roomParticipantsWithTracks];
    roomParticipants.push(participant);
    setRoomParticipantsWithTracks(roomParticipants);
    // Play chime
    if (user_chime_preferance) {
      const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0] as HTMLAudioElement
      audioEl.play()
    }


    const div = document.createElement("div");
    div.id = participant.sid;
    div.style.width = "244px";
    div.style.minWidth = "244px";
    div.setAttribute('class', 'videoparticipant');

    //vtray-overlay
    const childDiv = document.createElement("div");
    childDiv.id = participant.sid + "_overlay";
    childDiv.className = "overlay-vtray";

    let identity = participant.identity.split("_");
    const nameTextElement = document.createElement("p");
    nameTextElement.id = participant.sid + "_name";
    nameTextElement.className = "overlay-text-red prevent-textcopy";

    //Show maximum 15 charecters
    let string = identity[1];
    let length = 11;
    let trimmedString = string;
    if (string.length > 11) {
      trimmedString = string.substring(0, length).concat("...");
      nameTextElement.textContent = trimmedString;
    } else {
      nameTextElement.textContent = trimmedString;
    }

    // Audio Button
    const audioButtonElement = document.createElement("i");
    audioButtonElement.id = participant.sid + "_audio";

    // Video Button (Default Muted) 
    const videoButtonElement = document.createElement("i");
    videoButtonElement.id = participant.sid + "_video";

    // Fullscreen Button 
    const fullscreenButtonElement = document.createElement("i");
    fullscreenButtonElement.id = participant.sid + "_fullscreen";

    let audioEnabled = false, videoEnabled = false;
    participant.tracks.forEach((publication: any) => {
      if (publication.kind === "audio") {
        if (publication.isTrackEnabled) {
          audioButtonElement.className = "icon overlay-audio-on";
          audioEnabled = true;
        } else {
          audioButtonElement.className = "icon overlay-audio-off";
        }
      }
      if (publication.kind === "video") {
        if (publication.isTrackEnabled) {
          videoButtonElement.className = "icon overlay-video-on-tray";
          fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";
          videoEnabled = true;
        } else {
          //set default image profiles
          videoButtonElement.className = "icon overlay-video-off-tray";
          fullscreenButtonElement.className = "icon overlay-fullscreen-off-tray";
          const activeViewers = invitedViewersRef.current;
          setTimeout(function () {
            showProfileImage(
              activeViewers,
              participant.sid,
              participant.identity
            );
          }, 250);
        }
      }
    });

    childDiv.appendChild(nameTextElement);
    childDiv.appendChild(audioButtonElement);
    childDiv.appendChild(videoButtonElement);
    childDiv.appendChild(fullscreenButtonElement);

    fullscreenButtonElement.onclick = () => {
      // Take participant's video to fullscreen mode
      const elem: any = childDiv.parentElement?.getElementsByTagName("video")[0];

      switchToFullScreen(elem, participant.sid);
    }

    participant.on("trackSubscribed", (track: any) =>
      trackSubscribed(div, track)
    );

    // Handle RemoteTracks published after connecting to the Room.
    participant.on('trackPublished', (track: any) => {
      if (track && track.trackName) {
        participant.tracks.forEach((publication: any) => {
          if (track.trackName === publication.trackName && 
            publication.kind === "video") { 
              if (publication.isSubscribed) {
                handleRemoteTrack(publication.track, participant);
              }
              publication.on("subscribed", (track: any) =>
                handleRemoteTrack(track, participant)
              );
          }
        });
      }
    });
    
    participant.on("trackUnsubscribed", trackUnsubscribed);

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });

    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        handleRemoteTrack(publication.track, participant);
      }
      publication.on("subscribed", (track: any) =>
        handleRemoteTrack(track, participant)
      );
    });

    div.appendChild(childDiv); //appending vtray-overlay
    const vtray = document.getElementById("vtray");
    vtray?.appendChild(div);
    setUsersUpdated(true)
  };

  const participantDisconnected = (participant: any) => {

    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({ sid: participant.sid, identity: participant.identity, state: participant.state });

    setRoomParticipantsList(roomParticipantsList);

    // Play chime
    if (user_chime_preferance) {
      const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0] as HTMLAudioElement
      audioEl.play()
    }

    const participantSidElem = document.getElementById(participant.sid);
    if (participantSidElem !== null) {
      participantSidElem.remove();
    }
    setUsersUpdated(true)
  };

  const trackSubscribed = (div: any, track: any) => {

    // Audio and Video
    if (track.kind === "audio" || track.kind === "video") {
      const preview = div.getElementsByTagName('video');
      div.appendChild(track.attach());
      //TO DO: I know this loop is junck code, Need to change 
      for (let i = 0; i < preview.length; i++) {
        if (i > 0) {
          div.removeChild(preview[i]);
        }
      }
    }

    // White borading
    if (track.kind === "data") {
      const canvas: any = document.getElementById("canvas");
      track.on("message", (data: any) => {
        const {
          isPainting,
          color,
          mousePosition,
          newMousePosition,
          incoming_canvas,
          erase,
          clear,
          globalMute,
          nameTextId,
          className,
          clearAnnotationChat,
          clearGroupChat,
          streamId,
          streamName,
          isCircle
        } = JSON.parse(data);
        if (streamId && streamName) {
          const curr_stream_label_span: any = document.getElementById(streamId);
          curr_stream_label_span.innerText = streamName;
        }
        if (isPainting && canvas) {
          const curr_canvas: any = document.getElementById("canvas");
          let bb = curr_canvas?.getBoundingClientRect();
          const bbWidth = get(bb, "width", 0);
          const bbHeight = get(bb, "height", 0);
          const curr_canvas_width = bbWidth;
          const curr_canvas_height = bbHeight;

          let oldMousePos: Coordinate, newMousePos: Coordinate;

          if (curr_canvas) {
            oldMousePos = {
              x: (mousePosition.x / incoming_canvas.width) * curr_canvas_width,
              y: (mousePosition.y / incoming_canvas.height) * curr_canvas_height,
            };
            newMousePos = {
              x:
                (newMousePosition.x / incoming_canvas.width) *
                curr_canvas_width,
              y:
                (newMousePosition.y / incoming_canvas.height) *
                curr_canvas_height,
            };
            if(isCircle) {
              drawCircle(color, oldMousePos, newMousePos)
            } else {
              drawLine(color, oldMousePos, newMousePos, erase);
            }
            canvas.className = "canvas-pencil-on";
          }
        }

        if (clear) {
          const canvas: any = document.getElementById("canvas");
          let context = canvas.getContext("2d");
          const canvasWidth = get(canvas, "width", 0);
          const canvasHeight = get(canvas, "height", 0);
          context.clearRect(0, 0, canvasWidth, canvasHeight);
        }

        if (globalMute) {

          const locParticipant = lParticipantRef.current;
          const audioButton = document.getElementById(
            locParticipant.sid + "_audio"
          );
          // Name
          const nameTextElement = document.createElement("p");
          if (audioButton) {
            audioButton.className = "icon overlay-audio-off";
            nameTextElement.className = "overlay-text-red prevent-textcopy";
          }

          locParticipant.tracks.forEach((publication: any) => {
            if (publication.kind === "audio") {
              if (publication.isTrackEnabled) {
                publication.track.disable();
              }
            }
          });
        }

        // unmute other participants
        if (globalMute === false) {

          const locParticipant = lParticipantRef.current;
          const audioButton = document.getElementById(
            locParticipant.sid + "_audio"
          );
          // Name
          const nameTextElement = document.createElement("p");
          if (audioButton) {
            audioButton.className = "icon overlay-audio-on";
            nameTextElement.className = "overlay-text prevent-textcopy";
          }

          locParticipant.tracks.forEach((publication: any) => {
            if (publication.kind === "audio") {
              if (!publication.isTrackEnabled) {
                publication.track.enable();
              }
            }
          });
        }

        if (nameTextId && className) {
          const nameTextElement = document.getElementById(nameTextId);
          if (nameTextElement) {
            nameTextElement.className = className;
          }
        }

        if (clearAnnotationChat) {
          setAnnotateMessages([]);
        }

        if (clearGroupChat) {
          setMessages([]);
        }
      });
    }
  };

  const trackUnsubscribed = (track: any) => {
    if (track.kind === "audio" || track.kind === "video") {
      track.detach().forEach((element: any) => element.remove());
    }
  };

  const createLocalParticipant = (localParticipant: any) => {

    const roomParticipantsList = [...roomParticipantsListRef.current];
    roomParticipantsList.push({ sid: localParticipant.sid, identity: localParticipant.identity, state: localParticipant.state });

    setRoomParticipantsList(roomParticipantsList);

    // Participant Window
    const div = document.createElement("div");
    div.id = localParticipant.sid;
    div.style.width = "244px";
    div.style.minWidth = "244px";
    div.setAttribute('class', 'videoparticipant');

    //vtray-overlay
    const childDiv = document.createElement("div");
    childDiv.id = localParticipant.sid + "_overlay";
    childDiv.className = "overlay-vtray";

    // Name
    let identity = localParticipant.identity.split("_");
    const nameTextElement = document.createElement("p");
    nameTextElement.id = localParticipant.sid + "_name";
    nameTextElement.className = "overlay-text-red prevent-textcopy";

    //Show max 15 charecters
    let string = identity[1];
    let length = 11;
    let trimmedString = string;
    if (string.length > 11) {
      trimmedString = string.substring(0, length).concat("...");
      nameTextElement.textContent = trimmedString;
    } else {
      nameTextElement.textContent = trimmedString;
    }

    //Local participant profile image when video mute intially
    // const user_profile_img = document.getElementById(
    //   localParticipant.sid + "_image"
    // );
    if (!!localUserImage && !!user_imageurl) {
        const image = document.createElement("img");
        image.src = localUserImage;
        image.id = localParticipant.sid + "_image";
        image.alt = "Profile image shown here";
        image.style.minWidth = "244px";
        image.setAttribute('class', 'profile_img11');
        div.insertBefore(image, div.childNodes[0]);
      } else {
        const image = document.createElement("div");
        image.id = localParticipant.sid + "_image";
        image.style.minWidth = "244px";
        image.innerHTML = trimmedString;
        image.setAttribute('class', 'profile_name');
        div.insertBefore(image, div.childNodes[0]);
      }

    // Audio Button
    const audioButtonElement = document.createElement("i");
    audioButtonElement.id = localParticipant.sid + "_audio";
    audioButtonElement.className = "icon overlay-audio-off";

    // Video Button
    const videoButtonElement = document.createElement("i");
    videoButtonElement.id = localParticipant.sid + "_video";
    videoButtonElement.className = "icon overlay-video-off-tray";

    // Fullscreen Button
    const fullscreenButtonElement = document.createElement("i");
    fullscreenButtonElement.id = localParticipant.sid + "_fullscreen";
    fullscreenButtonElement.className = "icon overlay-fullscreen-off-tray";

    // Signal Button
    const signalButtonElement = document.createElement("i");
    signalButtonElement.id = localParticipant.sid + "_signal";
    signalButtonElement.className = "icon overlay-high-signal";

    //More Option
    const moreButtonElement = document.createElement("i");
    moreButtonElement.id = localParticipant.sid + "_background";
    moreButtonElement.className = "icon menu-vertical";
    moreButtonElement.title = "Change Video Background"
    moreButtonElement.style.display = "none";

    childDiv.appendChild(nameTextElement);
    childDiv.appendChild(signalButtonElement);
    childDiv.appendChild(audioButtonElement);
    childDiv.appendChild(videoButtonElement);
    childDiv.appendChild(fullscreenButtonElement);
    childDiv.appendChild(moreButtonElement);

    fullscreenButtonElement.onclick = () => {
      // Take local participant's video to fullscreen mode
      const elem: any = childDiv.parentElement?.getElementsByTagName("video")[0];

      switchToFullScreen(elem, localParticipant.sid);
    }
    
    //Delay backslash click
    let delayBackslasClick = (()=>{
      let timer:any;
      return function(callback:any, ms:number){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);        
      }; 
    })();

    // Listen to audio mute key
    document.addEventListener('keyup', (e) => {
      if (e.code === "BracketRight") {
        const elem: any = document.getElementsByTagName("video")[0];
        elem.muted = !elem.muted;
        setVolumeMute(elem.muted);
      }
      if (e.code === "Backquote") {
        toggleAudioMute(localParticipant, audioButtonElement, nameTextElement);
      }
      if (e.code === "Backslash") { 
        delayBackslasClick(function(){
          videoButtonClick()
        }, 500 ); 
      }
    });

    // Listening to Audio and Video Button Click
    audioButtonElement.onclick = () => {
      toggleAudioMute(localParticipant, audioButtonElement, nameTextElement);
    };

    moreButtonElement.onclick = () => {
      openBGSelectionModal();
    }

    function videoButtonClick(){
      localParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === "video") {
          setLocalParticipantTrack(publication.track);
          if (publication.isTrackEnabled) {
            //adding profile image when video muted
            if (!!user_imageurl) {
              const image = document.createElement("img");
              image.src = user_imageurl;
              image.className = "profile_img11";
              image.id = localParticipant.sid + "_image";
              div.insertBefore(image, div.childNodes[0]);
             
            } else {
              const image = document.createElement("div");
              image.id = localParticipant.sid + "_image";
              image.style.minWidth = "244px";
              let length = 11;
              let trimmedString = localParticipant.identity.split('_')[1]
              if (trimmedString.length > 11) {
                trimmedString = trimmedString.substring(0, length).concat("...");
              }
              image.innerHTML = trimmedString;
              image.className = 'profile_name';
              div.insertBefore(image, div.childNodes[0]);
              
            }

            publication.track.disable();
            videoButtonElement.className = "icon overlay-video-off-tray";
            setLocalVideoMute(true);
            fullscreenButtonElement.className = "icon overlay-fullscreen-off-tray";

            //Hiding virtual background button when video muted
            const more_button_ele: any = document.getElementById(
              localParticipant.sid + "_background"
            );
            if (more_button_ele) { more_button_ele.style.display = "none"; }

            Mixpanel.track("Viewer VCControl Action", {
              Platform: "Web",
              "VC Control Type": "Video",
              "VC Control Action": "Mute",
              "VC Type": "Stream",
              "Stream Label": streamLabel,
            });
          } else {
            if (localParticipant.networkQualityLevel >= 2) {
              setsignalStrengthOpen(false);

              //delete profile image when video unmute
              const del_prof_img = document.getElementById(
                localParticipant.sid + "_image"
              );
              if (del_prof_img) {
                div.removeChild(del_prof_img);
              }
              // changeCameraTrack(cameraIdRef.current);
              setTimeout(function () {
                publication.track.enable();
              }, 250);
              videoButtonElement.className = "icon overlay-video-on-tray";
              setLocalVideoMute(false);
              fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";

              //Enable virtual background button when video unmuted
              const more_button_ele: any = document.getElementById(
                localParticipant.sid + "_background"
              );
              if (more_button_ele) { more_button_ele.style.display = "block"; }

              Mixpanel.track("Viewer VCControl Action", {
                Platform: "Web",
                "VC Control Type": "Video",
                "VC Control Action": "UnMute",
                "VC Type": "Stream",
                "Stream Label": streamLabel,
              });
            } else {
              setOverlaymsg(
                "Your network quality is less than optimal, so we are restricting you from enabling your video!"
              );
              showOverlay();

              Mixpanel.track("Viewer Network Quality", {
                Platform: "Web",
                "Network Quality": localParticipant.networkQualityLevel,
                "Network Quality Type": "Low",
                "Stream Label": streamLabel,
              });
            }
          }

          if (localAudioMuteRef.current || localVideoMuteRef.current) {
            nameTextElement.className = "overlay-text-red prevent-textcopy";
            dataTrackRef.current.send(
              JSON.stringify({
                nameTextId: nameTextElement.id,
                className: nameTextElement.className,
              })
            );
          } else {
            nameTextElement.className = "overlay-text prevent-textcopy";
            dataTrackRef.current.send(
              JSON.stringify({
                nameTextId: nameTextElement.id,
                className: nameTextElement.className,
              })
            );
          }
        }
      });
    }
    videoButtonElement.onclick = () => {
      videoButtonElement.classList.add("disabled-button");
      setTimeout(function () {
        videoButtonClick()      
        videoButtonElement.classList.remove("disabled-button");
      }, 1000);
    };
    localParticipant.tracks.forEach((publication: any) => {
      trackSubscribed(div, publication.track);
    });

    localParticipant.on("trackUnsubscribed", trackUnsubscribed);
    div.appendChild(childDiv); //appending the background-overlay-vedio-tray
    const vtray = document.getElementById("vtray");
    vtray?.appendChild(div);
  };

  const manageRoom = (room: any) => {
    try {
      /* CONNECT */

      // LocalParticipant in the Room
      const localParticipant = room.localParticipant;
      setLocalParticipant(localParticipant);
      setLLocalParticipant(localParticipant);

      createLocalParticipant(localParticipant);

      room.localParticipant.on(
        "networkQualityLevelChanged",
        (level: number) => {
          const signalButtonElement = document.getElementById(
            room.localParticipant.sid + "_signal"
          );

          if (signalButtonElement) {
            if (localParticipant.networkQualityLevel <= 2) {
              signalButtonElement.className = "icon overlay-low-signal";
            } else if (
              localParticipant.networkQualityLevel > 2 &&
              localParticipant.networkQualityLevel <= 4
            ) {
              signalButtonElement.className = "icon overlay-medium-signal";
            } else if (localParticipant.networkQualityLevel > 4) {
              signalButtonElement.className = "icon overlay-high-signal";
            }
          }
        }
      );

      // Log Participants as they connect to the Room
      room.once("participantConnected", (participant: any) => {
        // console.log(
        //   `Participant "${participant.identity}" has connected to the Room, ${participant}`
        // );
      });

      // Any Participants already connected to the Room
      room.participants.forEach(participantConnected);
      // New participant as they connect to the room (Connection Events)
      // Other will receive this (Connection Events)
      room.on("participantConnected", (participant: any) => {
        participantConnected(participant);
        if(!!participant){
          //show the participant joined pubnub
          vcParticipants(participant, "joined")
        }
      });
      /* DISCONNECT */

      // Log Participants as they disconnect from the Room
      room.once("participantDisconnected", (participant: any) => {
        // console.log(
        //   `Participant "${participant.identity}" has disconnected from the Room`
        // );
      });

      // Other will receive this (Connection Events)
      room.on("participantDisconnected", (participant: any) => {
        participantDisconnected(participant);
        if(!!participant){
          //show the participant joined pubnub
          vcParticipants(participant, "left")
        }
      });

      room.on("dominantSpeakerChanged", (participant: any) => {
        dominantSpeakerChanged(participant);
      });

      // You can disconnect from a Room, Other will receive "participantDisconnected" event
      room.on("disconnected", (room: any) => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === "audio" || publication.kind === "video") {
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element: any) => element.remove());
          }
        });

        // Remove local view
        const localParticipantDiv = document.getElementById(
          localParticipant.sid
        );
        localParticipantDiv?.parentNode?.removeChild(localParticipantDiv);

        // Remove others from vtray
        room.participants.forEach((participant: any) => {
          const participantSidElem = document.getElementById(participant.sid);
          if (participantSidElem !== null) {
            participantSidElem.remove();
          }
        });

        // Stop camera and mic
        room.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === "audio" || publication.kind === "video") {
            publication.track.stop();
            publication.unpublish();
          }
        });

        setTRoom({});
        setActiveStreamVCRoom({});
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateTwilioRoom = async (
    stream_vc_room: any,
    twilio_room_name: string,
    stream_id: string
  ) => {
    try {
      const data = {
        api: api.vc.updateStreamVCRoom,
        urlParam: stream_vc_room.show_vc_room_id,
        payLoad: {
          show_id,
          showStream_id: stream_id,
          twilio_room_name,
        },
      };
      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

    } catch (err: any) {
      console.error("Not able to update twilio room in db");
    } finally {
      setLoading(false);
    }
  };

  const removeTwilioLink = async (stream_vc_room: any, stream_id: string) => {
    try {
      // Remove twilio link from db
      const data = {
        api: api.vc.removeTwilioLinkFrmStreamVCRoom,
        urlParam: stream_vc_room.show_vc_room_id,
        payLoad: {
          show_id,
          showStream_id: stream_id,
        },
      };
      setLoading(true);
      await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const openBGSelectionModal = () => {
    setTempActiveBG(activeBGRef.current);
    setLocalParticipantBGModalOpen(true);
  }

  /*
   ****************************
   * VC Main Action Controls
   ****************************
   */

  const handleGlobalMute = () => {
    let muteFlag = !muteAll;
    setMuteAll(muteFlag);

    // here mute my self first
    const locParticipant = lParticipantRef.current;
    const audioButton = document.getElementById(locParticipant.sid + "_audio");

    if (muteFlag) {
      if (audioButton) {
        audioButton.className = "icon overlay-audio-off";
      }
      locParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === "audio") {
          if (publication.isTrackEnabled) {
            publication.track.disable();
          }
        }
      });
    } else {
      if (audioButton) {
        audioButton.className = "icon overlay-audio-on";
      }
      locParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === "audio") {
          if (!publication.isTrackEnabled) {
            publication.track.enable();
          }
        }
      });
    }

    // here send data track with global mute variable and everybody will receive and close audio track respectively
    dataTrackRef.current.send(JSON.stringify({ globalMute: muteFlag }));
  };

  const handelAudio = async () => {
    const audioButtonElement = document.getElementById(
      localParticipant.sid + "_audio"
    );

    localParticipant.tracks.forEach((publication: any) => {
      if (publication.kind === "audio") {
        if (publication.isTrackEnabled) {
          publication.track.disable();

          // Update Participant Tray Audio Icon
          if (audioButtonElement) {
            audioButtonElement.className = "icon overlay-audio-off";
          }
        } else {
          publication.track.enable();

          // Update Participant Tray Audio Icon
          if (audioButtonElement) {
            audioButtonElement.className = "icon overlay-audio-on";
          }
        }
      }
    });
  };


  const handelVideo = async () => {
    const videoButtonElement = document.getElementById(
      localParticipant.sid + "_video"
    );
    const fullscreenButtonElement = document.getElementById(
      localParticipant.sid + "_fullscreen"
    );
    localParticipant.tracks.forEach((publication: any) => {
      if (publication.kind === "video") {
        if (publication.isTrackEnabled) {
          publication.track.disable();

          // Update Participant Tray Video Icon
          if (videoButtonElement) {
            videoButtonElement.className = "icon overlay-video-off-tray";
          }
          if (fullscreenButtonElement) {
            fullscreenButtonElement.className = "icon overlay-fullscreen-off-tray";
          }
        } else {
          publication.track.enable();

          // Update Participant Tray Video Icon
          if (videoButtonElement) {
            videoButtonElement.className = "icon overlay-video-on-tray";
          }
          if (fullscreenButtonElement) {
            fullscreenButtonElement.className = "icon overlay-fullscreen-on-tray";
          }
        }
      }
    });
  };

  const getTwilioRoomDetails = async () => {
    try {
      const data = {
        api: api.vc.getTwilioRoom,
        urlParam: troom.sid,
        queryParam: {
          show_id: showId,
        },
      };
      setLoading(true);
      const roomDetails = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      setLoading(false)
      return roomDetails;
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getTwilioRoomsParticipants = async () => {
    try {
      const data = {
        api: api.vc.twilioRoomParticipantsList,
        urlParam: troom.sid,
        queryParam: {
          show_id: showId,
        },
      };
      setLoading(true);
      const roomParticipants = await backend.fetch(data, get(currentUser, "user.accessToken", ""));

      return roomParticipants;
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const completeTwilioRoom = async () => {
    try {
      // Complete Room
      const data = {
        api: api.vc.completeTwilioRoom,
        payLoad: {
          show_id: showId,
          stream_id: stream.stream_id,
          room_sid: troom.sid,
        },
      };

      setLoading(true);
      const roomdetails = await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

      if (roomdetails && roomdetails.status === "completed") {
        return true;
      } else {
        return false;
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const handelVideoConfEnd = async () => {
    try {
      // check if room present
      if (troom && !isEmpty(troom)) {
        troom.disconnect();

        // Check Participants in room
        const connectedRoomParticipants = await getTwilioRoomsParticipants();

        if (connectedRoomParticipants.length === 0) {

          await completeTwilioRoom();
          await removeTwilioLink(activeStreamVCRoom, stream.stream_id);

          Mixpanel.track("Viewer End VC", {
            Platform: "Web",
            "VC End Action": "End Call",
            "VC Type": "Stream",
            "Stream Label": streamLabel,
          });
        } else {
          Mixpanel.track("Viewer End VC", {
            Platform: "Web",
            "VC End Action": "Leave Call",
            "VC Type": "Stream",
            "Stream Label": streamLabel,
          });
        }

        sessionStorage.removeItem("stream_id");
        sessionStorage.removeItem("stream_label");

        setTRoom({});
        setActiveStreamVCRoom({});
        setShowPopup(false);
      } else {
        console.log("Error: VC Room not present");
      }

      setStream({});
      clearWhiteBoard();
      leaveChat();
      leaveAnnotateChat();
      leaveGuestInvite();
      leavePublisherPanel();
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handelVideoConfEndAll = async () => {
    try {
      if (!isVCHost) {
        return;
      }

      const isTwilioRoomCompleted = await completeTwilioRoom();
      if (isTwilioRoomCompleted) {
        await removeTwilioLink(activeStreamVCRoom, stream.stream_id);

        setTRoom({});
        setActiveStreamVCRoom({});
      }

      if (guestUsersList.length > 0) {
        guestUsersList.forEach(async (item: any) => {
          const data = {
            api: api.guestUsers.unInviteGuestUser,
            urlParam: item.user_id,
            queryParam: { show_id }
          };
          const result: any = await backend.remove(data, get(currentUser, "user.accessToken", ""));
        })
      }

      setStream({});
      clearWhiteBoardAll();
      leaveChat();
      leaveAnnotateChat();
      leaveGuestInvite();
      leavePublisherPanel();

      sessionStorage.removeItem("stream_id");
      sessionStorage.removeItem("stream_label");

      Mixpanel.track("Viewer End VC", {
        Platform: "Web",
        "VC End Action": "End Call",
        "VC Type": "Stream",
        "Stream Label": streamLabel,
      });
      setShowPopup(false);
    } catch (err: any) {
      console.error(err);
    }
  };
  const reloadPage = async () => {
    // wait a tick for any state updates to complete before reloading,
    // like any open menus to close.
    // await tick();
    // window.location.reload();
    setStreamKey(Math.random());
  };
  /*
   ****************************
   * Left Stream Actions
   ****************************
   */

  const showConfigValidation = async (id: string, stream_id: string, showConfigValidation: any) => {

    const data = {
      api: api.configs.listConfigsByShowType,
    };

    const totalInprogressRooms = {
      api: api.vc.getInprogressVCRoomsByShow,
      urlParam: id,
    };

    setLoading(true);
    const config = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
    const inprogressVCs: any[] = await backend.fetch(totalInprogressRooms, get(currentUser, "user.accessToken", ""));
    setLoading(false);

    const filterConfig = config.filter((config: any) => config["config_name"] === "number_of_simultaneous_streams");
    const configEnabled = filterConfig.length > 0 && !!filterConfig[0]["config_enable"];

    const openStreams = inprogressVCs.filter((item: any) => item.showStream_id === stream_id && item.twilio_room_name.length > 0)

    if (openStreams.length > 0) {
      return true
    }

    const showConfigLength =
      !!showConfigValidation && showConfigValidation.length > 0 ? showConfigValidation[0]["config_value"] : 0;
    const total_inprogress_Confs = !!inprogressVCs ? inprogressVCs.length : 0;


    if (!!configEnabled && !!showConfigLength && showConfigLength > 0 &&
      total_inprogress_Confs >= showConfigLength &&
      !!showConfigValidation &&
      showConfigValidation.length > 0
    ) {
      setModalOpen(true);
      return false;
    }
    return true;
  };

  const handleStreamLabelClick = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();
      const stream_id = event.currentTarget.dataset.stream_id || "";
      const stream_label = event.currentTarget.dataset.stream_label || "";

      //Show Config Validation
      const configResult = await showConfigValidation(showId, stream_id, showConfig);

      if (!configResult) {
        return false;
      }

      sessionStorage.setItem("stream_id", stream_id);
      sessionStorage.setItem("stream_label", stream_label);

      if (stream_label) {
        setStreamLabel(stream_label);
      }

      Mixpanel.track("Viewer Click Stream", {
        Platform: "Web",
        "Stream Label": stream_label,
      });

      if (stream_id) {
        let stream = { stream_id, stream_label };

        setStream(stream);

        setStreamKey(Math.random());

        await fetchAllActiveStreamUsers(stream_id);

        // Pull VC Room Details and start/join VC
        await fetchVCRoomAndStartOrJoin(stream_id, stream_label);
      }
    } catch (err: any) {
      console.error(err);
    }
    setShowStreamMenu(true);
    setStreamMenuActive(false);
  };
  /*
   ****************************
   * Left Main Nav Actions
   ****************************
   */

  const handleStreamClick = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      event.preventDefault();
      setShowStreamMenu(true);
      setStreamMenuActive(true);
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleClickBack = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      event.preventDefault();
      sessionStorage.removeItem("stream_id");
      sessionStorage.removeItem("stream_label");

      setShowStreamMenu(false);
      setStreamMenuActive(true);
    } catch (err: any) {
      console.error(err);
    }
  };

  const downloadFiles = async (id: any, name: any, channel: any) => {
    const file = await pubnub.downloadFile({
      channel: channel,
      id: id,
      name: name
    });

    let fileContent = await file.toBlob();

    var hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = name;
    hiddenElement.click();
  }

  /*
   ****************************
   * Main Body Content
   ****************************
   */

  const handleUpdateStreamLabel = async () => {

    leftBarRef.current?.classList.add("left-bar-temp-width");

    if (!updatedStreamLabel) return
    updatedStreamLabel.trim()
    if (updatedStreamLabel === "") return

    try {
      const data = {
        api: api.streams.update,
        urlParam: updatedStreamId,
        payLoad: {
          update_data: { stream_label: updatedStreamLabel },
        },
      };

      setLoading(true);
      await backend.save(data, get(currentUser, "user.accessToken", ""));
      setUpdatedStreamId("");
      setStreamLabelUpdateMsg("");
      setUpdatedStreamLabelToAll(!updatedStreamLabelToAll);
      const updateStreams = activeStreams.map((stream: any) => {
        if (stream.stream_id === updatedStreamId) {
          stream.stream_label = updatedStreamLabel
        }
        return stream;
      })
      setActiveStreams(updateStreams);
      leftBarRef.current?.classList.remove("left-bar-temp-width");
    } catch (err: any) {
      const msg = "Error while updating stream  labe!";
      console.log(err)
      if (err.errMessage) {
        setStreamLabelUpdateMsg('ERROR: ' + err.errMessage)
      } else {
        setStreamLabelUpdateMsg(msg)
      }
    } finally {
      setLoading(false);
    }
  }

  const handleCancelUpdateStreamLabel = async () => {
    for(const stream of activeStreams) {
      const stream_id = stream.stream_id;
      const hideEdit = document.getElementById("edit_stream_" + stream_id);
      hideEdit?.setAttribute("style", "display: none");
    }
  }

  const handleStreamName = async (stream: any) => {
    const { stream_id = "", stream_label = "" } = stream;
    setUpdatedStreamId(stream_id);
    setUpdatedStreamLabel(stream_label);
  }

  const onStreamSubmit = (e: any) => {
    e.preventDefault();
    return false
  }

  const renderLeftMenuStreamLabels = () => {
    return activeStreams.map((stream: any, index: number) => {
      let streamId = stream.stream_id;
      let created_user_email = stream.created_user_email;
      const authUserEmail = auth.getUserEmail();
      return (
        <li
          key={index}
          data-stream_id={streamId}
          data-stream_label={stream.stream_label}
          onClick={handleStreamLabelClick}
          className={`old-viewer-selector ${streamLabel === stream.stream_label ? "active" : ""}`}
        >
          <a>
            <i>
              <img src={playIcon} alt="streamIcon" />
            </i>
            {updatedStreamId !== streamId && <span id={streamId}
              style={{ wordBreak: "break-all", width: "155px" }}>
              {stream.stream_label}
            </span>}
            {
              updatedStreamId === streamId &&
              <form onSubmit={onStreamSubmit} style={{ width: "155px" }}>

                <span className="stream-label-edit-parent" onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                  <div>
                    <input
                      type="texbox"
                      className="stream-label-edit-box"
                      value={updatedStreamLabel}
                      maxLength={20}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const streamNameRegex = /^[A-Za-z0-9\_]+$/;
                        e.preventDefault();
                        e.stopPropagation();
                        if (streamNameRegex.test(value)) {
                          setUpdatedStreamLabel(value);
                        } else if (value === "") {
                          setUpdatedStreamLabel("");
                        }
                        setStreamLabelUpdateMsg("");
                      }}
                    />
                  </div>
                  <div>
                    <img src={crossTick} className="cancelTick-img cursor-pointer"
                      alt="cancel" onClick={() => {
                        setUpdatedStreamId("")
                        setStreamLabelUpdateMsg("")
                      }} />
                  </div>
                  <div>
                    <button onClick={handleUpdateStreamLabel}
                      className="stream-label-submit cursor-pointer"
                      disabled={!(/^[A-Za-z0-9\_]+$/).test(updatedStreamLabel)}>
                      <img src={greeTick} className="greenTick-img"
                        alt="Save" />
                    </button>

                  </div>
                </span>
                {!streamLabelUpdateMsg && <span className="stream-label-helper-text">Note: Accept only letters, numbers and underscore </span>}
                {streamLabelUpdateMsg && <span className="stream-label-error-text">{streamLabelUpdateMsg}</span>}
              </form>
            }
            {created_user_email === authUserEmail && updatedStreamId !== streamId && <span className="editPencil-padding">
              <img
                src={streamEditIcon}
                className="editPencil-img float-right"
                style={{ width: "20px", height: "20px" }}
                alt="Edit"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleStreamName(stream);
                }}
              />
            </span>}
          </a>
        </li>
      );
    });
  };

  const renderLeftMenu = () => {
    return (
      <nav
        ref={leftBarRef}
        className={`old-viewer-selector ${streamMenuActive === true ? "leftBar" : "leftBar"}`}
      >
        {!isGuestViewer &&
          <ul className="menuSlide">
            <li>
              <a>
                <i>
                  <img src={menuIcon} alt="" style={{ display: "inline" }} />
                  <img src={closeIcon} alt="" style={{ display: "none" }} />
                </i>
                <span className="nav-text"></span>
              </a>
            </li>
            {showStreamMenu === false && (
              <span>
                <li className="active">
                  <a>
                    <i>
                      <img src={homeIcon} alt="" />
                    </i>
                    <span>Home</span>
                  </a>
                </li>
                <hr className="menuBorder" />
                <li onClick={handleStreamClick}>
                  <a>
                    <i>
                      <img src={streamIcon} alt="stream" />
                    </i>
                    <span>Streams</span>
                  </a>
                </li>
              </span>
            )}
            {showStreamMenu === true && (
              <div>
                <li onClick={handleClickBack}>
                  <a href="#">
                    <i>
                      <img src={dashboard} alt="" />
                    </i>
                    <span>Dashboard</span>
                  </a>
                </li>
                <hr className="menuBorder" />
                <span className="areaTitle">Streams</span>
              </div>
            )}
            <div className="stream-name-labels">{renderLeftMenuStreamLabels()}</div>
          </ul>
        }
        {/* <ul className="menu-bottom">{renderHeaderVideoControls()}</ul> */}
        <ul className="menu-bottom">
        </ul>
      </nav>
    );
  };

  // This is show stream component
  const renderStreamContent = () => {
    if (!stream || (stream && isEmpty(stream))) {
      return;
    }
    if (!user_invited) {
      return;
    }
    return (
      <div className="old-viewer-selector">
        <ShowStream
          show_id={showId}
          canvasRef={canvasRef}
          stream={stream}
          iceServers={iceServers}
          refreshKey={streamKey}
          setLoading={setLoading}
          isReady={videoReady}
          showDominantSpeaker={showDominantSpeaker}
          dominantSpeakerID={dominantSpeakerIDRef.current}
          localAudioMuteValue={localAudioMuteRef.current}
          localParticipantID={lParticipantRef.current.sid}
          localParticpantTrack={localParticipantTrackRef.current}
          dominantSpeakerTrack={dominantSpeakerTrackRef.current}
          showStreamUpdate={showStreamUpdateRef.current}
          volumeMute={volumeMute}
        />
      </div>
    );
  };

  const renderRightMenu = () => {
    return <RightMenuWithChatWindow></RightMenuWithChatWindow>;
  };

  const moveLeft = async (event: React.MouseEvent<HTMLElement>) => {
    document.getElementById("vtray")?.scrollBy(-250, 0);
  };
  const moveRight = async (event: React.MouseEvent<HTMLElement>) => {
    document.getElementById("vtray")?.scrollBy(250, 0);
  };

  const renderLeftAndRightScrollArrow = () => {
    if (!stream || (stream && isEmpty(stream))) {
      return;
    }
    if (!troom || (troom && isEmpty(troom))) {
      return;
    }
    return (
      <Fragment > 
        <button className="old-viewer-selector flex-prev tray-btns" onClick={moveLeft}></button>
        <button className=".old-viewer-selector flex-next tray-btns" onClick={moveRight}></button>
      </Fragment>
    );
  };
  const renderVideoTray = () => {
    return (
      <div className="old-viewer-selector">
        <div className="videotray" id="vtray">
          {renderLeftAndRightScrollArrow()}
        </div>
        {/* <Modal
      centered={true}
      open={signalStrengthOpen == true ? signalStrengthOpen : signalStrengthClose}
      onClose={() => setsignalStrengthOpen(false)}
      onOpen={() => setsignalStrengthOpen(true)}
    >
      <Modal.Content>
        <Modal.Description>
        Your network quality is less than optimal, so we are restricting you from enabling your video!
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setsignalStrengthOpen(false)}>OK</Button>
      </Modal.Actions>
    </Modal> */}

        <div className="overlay-vtry" style={overlay.style}>
          <span className="overlay-vtry-text">{overlayMsg}</span>
        </div>

        <div className="overlay-participant-connected" style={overlayConnected.style}>
          <span className="overlay-vtry-text">{overlayMsgConnected}</span>
        </div>

        <div className="overlay-participant-disconnected" style={overlayDisconnected.style}>
          <span className="overlay-vtry-text">{overlayMsgDisconnected}</span>
        </div>
      </div>
    );
  };

  const renderBodyContent = () => {
    if (!showViewer) {
      return <div> </div>;
    }

    return (
      <div className="show-old-viewer old-viewer-selector">
        <div className="show-viewer-leftmenu">{renderLeftMenu()}</div>
        <div className="show-viewer-background" style={{ width: "100%" }}>
          <div className="show-viewer-maincontent">
            {renderStreamContent()}
          </div>
          {renderVideoTray()}
        </div>
        <div className="show-viewer-rightmenu">{renderRightMenu()}</div>

        <audio className="audio-element">
          <source src={chimeAudioUrl}></source>
        </audio>
      </div>
    );
  };

  /*
   **************************
   * Header Right Nav Actions
   **************************
   */

  const handleChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setChatWindow(true);
    setCloseDisable(true);
    setChatDisable(false);

    setUsers(false);
    setCloseUsers(false);
    setOpenUsers(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);
  };

  const handleCloseChatWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    // setAnnotationChatWindow(true);
    // setAnnotationCloseDisable(true);
    // setAnnotationChatDisable(false);
  };

  const onTimecodePopupConfirm = async () => {
    setTimeCodePopup(false);
  };

  const handleAnnotationChatWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    let showTimeCodePopup = sessionStorage.getItem("showTimeCodePopup");
    if (!showTimeCodePopup) {
      sessionStorage.setItem("showTimeCodePopup", 'yes');
      setTimeCodePopup(true);
    }
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(true);
    setAnnotationCloseDisable(true);
    setAnnotationChatDisable(false);
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);
  };

  const handlePublisherPanelWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);
    setShowPublisherPanel(true);
    setShowPublisherPanelCloseDisable(true);
    setShowGuestInvite(false);
    setShowGuestInviteCloseDisable(false);
  }
  const handleClosePublisherPanelWindow = async(
    event: React.MouseEvent<HTMLElement>
    ) => {
      setChatWindow(false);
      setCloseDisable(false);
      setChatDisable(true);
      setShowPublisherPanel(false);
      setShowPublisherPanelCloseDisable(false);
    }
  const handleGuestInviteWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);
    setShowPublisherPanel(false);
    setShowPublisherPanelCloseDisable(false);
    setShowGuestInvite(true);
    setShowGuestInviteCloseDisable(true);
  }

  const handleAnnotationCloseChatWindow = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
    setAnnotationChatWindow(false);
    setAnnotationCloseDisable(false);
    setAnnotationChatDisable(true);
  };

  const handleShowUsers = async (event: React.MouseEvent<HTMLElement>) => {
    setUsers(true);
    setCloseUsers(true);
    setOpenUsers(false);

    setChatWindow(false);
    setCloseDisable(false);
    setChatDisable(true);
  };

  const handleCloseUsers = async (event: React.MouseEvent<HTMLElement>) => {
    setUsers(false);
    setCloseUsers(false);
    setOpenUsers(true);
  };

  /*
   **************************
   * Giphy Modal
   **************************
   */

  // const handleGiphyModal = async (event: React.MouseEvent<HTMLElement>) => {
  //   setGiphyModal(true);
  // };
  /*
   **************************
   * Header Actions
   **************************
   */

  const handelLogoClick = async () => {
    setLoading(true);
    await handelVideoConfEnd();
    setLoading(false);
    const user = auth.getUser();
    const uid = get(user, "uid", "");
    navigateToPage("/showselection", { state: { userId: uid } });
  };

  const gotoPublisher = async () => {
    const stream_id = sessionStorage.getItem("stream_id");
    const stream_label = sessionStorage.getItem("stream_label");
    navigateToPage("/showpublisher", { state: { show_id, stream_id, stream_label } });
  };

  const gotoTeamAdmin = async () => {
    navigateToPage("/teamadmin", { state: { show_id, team_admin } });
  };

  const profile = async () => {
    const user = auth.getUser();
    const uid = get(user, "uid", null);
    navigateToPage("/profile", { state: { userId: uid } });
  };

  const resetPassword = async () => {
    const user = auth.getUser();
    const uid = get(user, "uid", null);
    navigateToPage("/resetpassword", { state: { userId: uid } });
  };

  const signOut = async () => {
    try {
      setLoading(true);
      await handelVideoConfEnd();
      if (pubnub && !isEmpty(pubnub)) {
        pubnub.unsubscribeAll();
      }
      await auth.doSignOut();
      Mixpanel.track("Logout", { Platform: "Web" });
    } catch (err: any) {
      console.log(err);
    } finally {
      navigateToPage('/logoutredirect', {replace : true});
      setLoading(false);
    }
  };

  /*
   ****************************
   * Header Content
   ****************************
   */

  const renderVCInvitedUsersFrmHeader = () => {
    if (!showUser) {
      return;
    }

    return (
      <nav className="main-menu-right-chat">
        <div className="guestlist">
          <div className="invitePeopleHeadingLarge">Meeting Admin</div>
          <span className="subHeading">Steve Gibson</span>
          <div id="scrollbar3">
            <div className="invitePeopleHeadingLarge">Guests</div>
            <div className="scrollbar">
              <div className="track">
                <div className="thumb">
                  <div className="end"></div>
                </div>
              </div>
            </div>
            <div className="viewport">
              <div className="overview">
                <ul className="listPeoples">
                  <li>
                    <div className="namePeople">Emily Watson</div>
                    <button className="posAdmin">
                      <img src={overflowMenuHorizontal} alt="" />
                    </button>

                    <div className="popDesign">
                      <div className="closeBtn">
                        <img src={misuseAlt} alt="" />
                      </div>
                      <ul>
                        <li>
                          <a href="">Make Administrator</a>
                        </li>
                        <li>
                          <a href="">Ask to turn camera on</a>
                        </li>
                        <li>
                          <a href="">Remove from Meeting</a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div className="namePeople">Emily Watson</div>
                    <button className="posAdmin">
                      <img src={overflowMenuHorizontal} alt="" />
                    </button>

                    <div className="popDesign">
                      <div className="closeBtn">
                        <img src={misuseAlt} alt="" />
                      </div>
                      <ul>
                        <li>
                          <a href="">Make Administrator</a>
                        </li>
                        <li>
                          <a href="">Ask to turn camera on</a>
                        </li>
                        <li>
                          <a href="">Remove from Meeting</a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div className="namePeople">Emily Watson</div>
                    <button className="posAdmin">
                      <img src={overflowMenuHorizontal} alt="" />
                    </button>

                    <div className="popDesign">
                      <div className="closeBtn">
                        <img src={misuseAlt} alt="" />
                      </div>
                      <ul>
                        <li>
                          <a href="">Make Administrator</a>
                        </li>
                        <li>
                          <a href="">Ask to turn camera on</a>
                        </li>
                        <li>
                          <a href="">Remove from Meeting</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="namePeople">Emily Watson</div>
                    <button className="posAdmin">
                      <img src={overflowMenuHorizontal} alt="" />
                    </button>

                    <div className="popDesign">
                      <div className="closeBtn">
                        <img src={misuseAlt} alt="" />
                      </div>
                      <ul>
                        <li>
                          <a href="">Make Administrator</a>
                        </li>
                        <li>
                          <a href="">Ask to turn camera on</a>
                        </li>
                        <li>
                          <a href="">Remove from Meeting</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="namePeople">Emily Watson</div>
                    <button className="posAdmin">
                      <img src={overflowMenuHorizontal} alt="" />
                    </button>

                    <div className="popDesign">
                      <div className="closeBtn">
                        <img src={misuseAlt} alt="" />
                      </div>
                      <ul>
                        <li>
                          <a href="">Make Administrator</a>
                        </li>
                        <li>
                          <a href="">Ask to turn camera on</a>
                        </li>
                        <li>
                          <a href="">Remove from Meeting</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  };

  const renderMessage = (messageDesc: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let div = messageDesc?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return div;
  };

  const renderAnnotateMessage = (messageDesc: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let div = messageDesc?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return div;
  };

  const renderMessagewithFile = (message: any) => {
    return (
      <Card>
        <Card.Content>
          <Card.Meta>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="file-name">{message?.file?.name}</div>
              <div>
                <img
                  alt={message?.file?.name}
                  src={require("../../Images/download-icon.png")}
                  onClick={() => downloadFiles(message?.file?.id, message?.file?.name, channels[0])}
                  className="download-file-icon"
                />
              </div>
            </div>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  }

  const renderMessageWithEditButton = (message: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

    let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
    let div = message.description?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return (
      <div>
        <div id={message.timetoken} className="annote-chat-message-grey-bg">
          {div}
        </div>
        <div className="chat-time">{formatted_time}</div>
      </div>
    );
  };

  const renderAnnotateMessageWithEditButton = (
    message: any,
    messageIndex: number
  ) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

    let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
    let div = message.description.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return (
      <div>
        <div id={message.timetoken} className="annote-chat-message-grey-bg">
          {div}
        </div>
        <div className="chat-time">
          <span>{message.timecode ? message.timecode : formatted_time}</span>
          {message.annotateNote && (
            <span
              className="chat-annote"
              style={{ backgroundColor: message.annotateColor }}
            >
              {message.annotateNote}
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleEditMessageClick = (messageId: string, messageDesc: string) => {
    setEditMessage(true);
    setEditingMessageId(messageId);
    setEditInput(messageDesc);
    let hideEdit = document.getElementById("edit_button_" + messageId);
    hideEdit?.setAttribute("style", "display: none");
  };

  const handleAnnotateEditMessageClick = (
    messageId: string,
    messageDesc: string
  ) => {
    setEditAnnotateMessage(true);
    setEditingAnnotateMessageId(messageId);
    setAnnotateEditInput(messageDesc);
    let hideEdit = document.getElementById("edit_button" + messageId);
    hideEdit?.setAttribute("style", "display: none");
  };

  const handleChatCopy = (value: any) => {
    const { userDisplay, description, timetoken } = value;
    const newTimeStamp = Math.ceil(parseInt(timetoken) / 10000)
    const formattedTimeStamp = convertDateTimeFromUTCEpoc(newTimeStamp);
    const copyElement = document.createElement("textarea");
    document.body.appendChild(copyElement);
    copyElement.value = `${userDisplay} - ${description} - ${formattedTimeStamp}`;
    copyElement.select();
    document.execCommand("copy");
    document.body.removeChild(copyElement);
  }

  const renderEditableMessage = (message: any, messageIndex: number) => {
    if (editingMessageId === message.timetoken) {
      let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

      let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
      return (
        <div>
          <TextArea
            className="editTxtArea"
            autoFocus
            placeholder="Send a message here"
            rows="2"
            size="small"
            spellCheck="true"
            value={editInput}
            onChange={(event, data) =>
              onChangeEditMessage(data.value as string)
            }
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                event.preventDefault();
                updateMessage(editInput, message, messageIndex);
              } else if (event.key === "Esc") {
                event.preventDefault();
                renderMessage(message.description, messageIndex);
                let hideEdit = document.getElementById(
                  "edit_button_" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
              }
            }}
          />
          <span className="chat-time">{formatted_time}</span>
          <div className="float-right">
            <a
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setEditMessage(false);
                setEditingMessageId("");
                let hideEdit = document.getElementById(
                  "edit_button_" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
                renderMessageWithEditButton(message, messageIndex);
              }}
            >
              <img src={crossTick} className="cancelTick-img" alt="cancel" />
            </a>
            <a
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                updateMessage(editInput, message, messageIndex);
              }}
            >
              <img src={greeTick} className="greenTick-img" alt="cancel" />
            </a>
          </div>
        </div>
      );
    } else {
      // Render this normal method message here.
      return renderMessageWithEditButton(message, messageIndex);
    }
  };

  const renderEditableAnnotateMessage = (
    message: any,
    messageIndex: number
  ) => {
    if (editingAnnotateMessageId === message.timetoken) {
      let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

      let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
      return (
        <div>
          <TextArea
            className="editTxtArea"
            autoFocus
            placeholder="Send a message here"
            rows="2"
            size="small"
            spellCheck="true"
            value={editAnnotateInput}
            onChange={(event, data) =>
              onChangeAnnotateEditMessage(data.value as string)
            }
            // onBlur={(event: any) => {
            //   event.preventDefault();
            //   setEditAnnotateMessage(false);
            //   let hideEdit = document.getElementById(
            //     "edit_button" + message.timetoken
            //   );
            //   hideEdit?.removeAttribute("style");
            //   setEditingAnnotateMessageId("");
            //   updateAnnotateMessage(editAnnotateInput, message, messageIndex);
            // }}
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                event.preventDefault();
                updateAnnotateMessage(editAnnotateInput, message, messageIndex);
                let hideEdit = document.getElementById(
                  "edit_button" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
              } else if (event.key === "Esc") {
                event.preventDefault();
                let hideEdit = document.getElementById(
                  "edit_button" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
                renderAnnotateMessage(message.description, messageIndex);
              }
            }}
          />
          <div className="chat-time">
            <span>{message.timecode ? message.timecode : formatted_time}</span>
            <span className="float-right">
              <a
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setEditAnnotateMessage(false);
                  let hideEdit = document.getElementById(
                    "edit_button" + message.timetoken
                  );
                  hideEdit?.removeAttribute("style");
                  setEditingAnnotateMessageId("");
                  renderAnnotateMessageWithEditButton(message, messageIndex);
                }}
              >
                <img src={crossTick} className="cancelTick-img" alt="cancel" />
              </a>
              <a
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  let hideEdit = document.getElementById(
                    "edit_button" + message.timetoken
                  );
                  hideEdit?.removeAttribute("style");
                  updateAnnotateMessage(
                    editAnnotateInput,
                    message,
                    messageIndex
                  );
                }}
              >
                <img src={greeTick} className="greenTick-img" alt="cancel" />
              </a>
            </span>
            {message.annotateNote && (

              <span
                className="chat-annote"
                style={{
                  backgroundColor: message.annotateColor,
                  marginRight: "20px",
                }}
              >
                {message.annotateNote}
              </span>
            )}
          </div>
        </div>
      );
    } else {
      return renderAnnotateMessageWithEditButton(message, messageIndex);
    }
  };

  const renderChatWindowFrmHeader = () => {
    /* Don't comment this please */
    if (!showChat) {
      return;
    }
    if (!troom || (troom && isEmpty(troom))) {
      return;
    }
    /* We don't want to show chat window
    without conf running */

    return (
      <nav className="main-menu-right-chat" id="chat-tray">
        <div className="chat-h1">
          <span className="chat-text">Group Chat</span>
          <img alt="" className="clear-image clear-chat-button" src={clear} onClick={() => { setClearChatModalOpen(true) }} />
          <Popup content='Export Chat' position="left center" trigger={
            <img alt="" className="clear-image clear-chat-button export-chat-img" src={documentExport} onClick={(e) => {
              e.preventDefault();
              setExportChartConfirmationOpen(true);
            }} />
          } />
        </div>
        {
          fileUploadLoading || chatClearLoading ?
            <div>
              <ProgressLoader loading={fileUploadLoading || chatClearLoading} size="small" />
            </div>
            :
            (
              <>
                <div className="chat-content">
                  {messagesRef.current.map((message: any, messageIndex: number) => {
                    let editMessageIndex = messagesRef.current.findIndex(
                      (item) => item.timetoken === editingMessageId
                    );

                    if (editMessageIndex > -1) {
                      setTimeout(function () {
                        var chatTray = document.getElementById("chat-tray");
                        var currScrollHeight = chatTray?.scrollHeight || 0;
                        var scrollHeight: number =
                          currScrollHeight * (editMessageIndex / messagesRef.current.length);
                        chatTray?.scrollTo(0, scrollHeight - 20);
                      }, 250);
                    } else {
                      setTimeout(function () {
                        var chatTray = document.getElementById("chat-tray");
                        var currScrollHeight = chatTray?.scrollHeight || 0;
                        chatTray?.scrollTo(0, currScrollHeight);
                      }, 250);
                    }
                    
                    let message_timeToken:any = String(message.timetoken).length === 17 ? message.timetoken : String(message.timetoken) + "0000";
                    let msg_unix_timestamp = Math.ceil(
                      parseInt(message_timeToken) / 10000
                    );
                    let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
                    let publisher = message?.file ? message?.message?.publisher : message.publisher;

                    if (publisher === auth.getUserId()) {
                      // Loggedin user
                      let style = {};
                      if (user_imageurl) {
                        style = {
                          background:
                            'transparent url("' +
                            user_imageurl +
                            '") 0% 0% no-repeat padding-box',
                        };
                      }
                      return (
                        <div className="chat-list-item" key={`message-${messageIndex}`}>
                          <div className="chat-image" style={style}></div>
                          <div className="chat-message-body">
                            <div className="chat-heading">
                              {message?.file ? message?.message?.userDisplay : message?.userDisplay}
                              {message?.file ? null :
                                <div className="float-right">
                                  <Popup content='Copy To Clipboard' position="top right" trigger={<span className='duplicate-icon' onClick={() => handleChatCopy(message)} />} />
                                  <a
                                    id={"edit_button_" + message.timetoken}
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleEditMessageClick(
                                        message.timetoken,
                                        message.description
                                      )
                                    }
                                  >
                                    <img
                                      src={editPencil}
                                      className="editPencil-img"
                                      alt="Edit"
                                    />
                                  </a>
                                </div>
                              }
                            </div>
                            {message?.file ?
                              <div className="file-message-grey-bg">
                                {renderMessagewithFile(message)}
                              </div> :
                              !editMessage ?
                                renderMessageWithEditButton(message, messageIndex) :
                                renderEditableMessage(message, messageIndex)
                            }
                            {message?.file ?
                              <div className="chat-time">{formatted_time}</div> : null
                            }
                          </div>
                        </div>
                      );
                    } else {
                      // Other users
                      let style = {};
                      let profile_Image_url = message?.file ? message?.message?.imageurl : message?.imageurl;
                      if (profile_Image_url) {
                        style = {
                          background:
                            'transparent url("' +
                            profile_Image_url +
                            '") 0% 0% no-repeat padding-box',
                        };
                      }
                      // else if()
                      return (
                        <div className="chat-list-item" key={`message-${messageIndex}`}>
                          <div className="chat-image" style={style}></div>
                          <div className="chat-message-body">
                            <div className="chat-heading">
                              {message?.file ? message?.message?.userDisplay : message.userDisplay}
                              {message?.file ? null :
                               (message.status) ? null:
                                <div className="float-right">
                                  <Popup content='Copy To Clipboard' position="top right" trigger={<span className='duplicate-icon' onClick={() => handleChatCopy(message)} />} />
                                </div>
                              }
                            </div>
                            {message?.file ?
                              <div className="file-message-white-bg">
                                {renderMessagewithFile(message)}
                              </div> : 
                              (message.status === "joined")?
                              <div className='chat-message-green-bg'>
                                {renderMessage(message.description, messageIndex)}
                              </div>
                              : 
                              (message.status === "left")?
                              <div className='chat-message-red-bg'>
                                {renderMessage(message.description, messageIndex)}
                              </div>
                              :
                              <div className='chat-message-white-bg'>
                                {renderMessage(message.description, messageIndex)}
                              </div>
                            }
                            <div className="chat-time">{formatted_time}</div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                {/* <div className="emoji-picker">
                  {showEmoji && <Picker onSelect={addEmoji} theme='dark' title='' emoji='' showSkinTones={true} />}
                </div> */}

                <div className="chat-msg-input">
                  <Input
                    className="sendTxt"
                    placeholder="Send a message here"
                    size="small"
                    spellCheck="true"
                    onChange={(event, data) => {
                      let input: any = data.value.replace("C:\\fakepath\\", "") as string;
                      onChangeMessage(event, input)
                    }}
                    onKeyPress={(event: any) => {
                      if (event.key === "Enter") {
                        const inputText = input;
                        setInput("");
                        event.preventDefault();
                        if (inputText.length > 0) sendMessage(inputText);
                      }
                    }}
                  >
                    {/* <button>Show Modal</button> */}
                    {/* <Icon size="big" className="giphy-btn" onClick={showGiphyModal} /> */}
                    <img
                      src={emoji}
                      className="emoji-img"
                      alt="Emoji"
                      onClick={() => {
                        setShowEmoji(!showEmoji);
                      }}
                    />
                    <input value={input} ref={sendTextMessageInput} />
                    {
                      // browseInput === true &&
                      <input type="file" id="file-input" style={{ display: 'none' }} />
                    }
                    <span className="upload-btn" onClick={onBrowseClick}></span>
                    <Button
                      color="violet"
                      floated="right"
                      className="send-chat-btn"
                      size="medium"
                      onClick={(e) => {
                        const inputText = input;
                        setInput("");
                        e.preventDefault();
                        if (inputText.length > 0) sendMessage(inputText);
                      }}
                    ></Button>
                  </Input>

                </div>
                <div className="chat-typing-indicator">{typingInd}</div>

                {isModalOpen && (
                  <div>
                    <GiphyModal hideGiphyModal={hideGiphyModal}></GiphyModal>
                  </div>
                )}
              </>
            )
        }


      </nav>
    );
  };

  const onBrowseClick = () => {
    const browse = document.getElementById('file-input');
    if (browse) {
      browse.click();
    }
  };
  const renderAnnotationChatSettings = () => {
    return (
      <ul className={isVCHost ? "" : "hidden"}>
        <li>
          <Dropdown
            className="chat-settings"
            pointing="top right"
            icon={<img src={settingsImg} />}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text="Clear Locators"
                icon={<img className="clear-image" src={clear} />}
                onClick={clearAnnotationChat}
              />
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    );
  };

  const renderAnnotationChatWindowFrmHeader = () => {
    if (!showAnnotationChat) {
      return;
    }
    if (!troom || (troom && isEmpty(troom))) {
      return;
    }

    return (
      <nav className="main-menu-right-chat" id="annotate-chat-tray">
        <ProgressLoader loading={annotateChatClearLoading} size="small" />
        <div className="chat-h1">
          <span className="chat-text">Locators for Avid</span>
          {renderAnnotationChatSettings()}
        </div>

        <div className="chat-content">
          {annotateMessagesRef.current.map((message: any, messageIndex: number) => {
            let editAnnotateMessageIndex = annotateMessagesRef.current.findIndex(
              (item) => item.timetoken === editingAnnotateMessageId
            )

            if (editAnnotateMessageIndex > -1) {
              setTimeout(function () {
                var chatTray = document.getElementById("annotate-chat-tray");
                var currScrollHeight = chatTray?.scrollHeight || 0;
                var scrollHeight: number =
                  currScrollHeight *
                  (editAnnotateMessageIndex / annotateMessagesRef.current.length);
                chatTray?.scrollTo(0, scrollHeight - 50);
              }, 250);
            } else {
              setTimeout(function () {
                var chatTray = document.getElementById("annotate-chat-tray");
                var currScrollHeight = chatTray?.scrollHeight || 0;
                chatTray?.scrollTo(0, currScrollHeight);
              }, 250);
            }

            let msg_unix_timestamp = Math.ceil(
              parseInt(message.timetoken) / 10000
            );

            let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);

            if (message.publisher === auth.getUserId()) {
              // Loggedin user
              let style = {};
              if (user_imageurl) {
                style = {
                  background:
                    'transparent url("' +
                    user_imageurl +
                    '") 0% 0% no-repeat padding-box',
                };
              }
              return (
                <div className="chat-list-item" key={`message-${messageIndex}`}>
                  <div className="chat-image" style={style}></div>
                  <div className="chat-message-body">
                    <div className="chat-heading">
                      {message.annotationTitle}
                      <div className="float-right">
                        <Popup content='Copy To Clipboard' trigger={<span className='duplicate-icon' onClick={() => handleChatCopy(message)} />} />
                        <a
                          id={"edit_button" + message.timetoken}
                          className="cursor-pointer"
                          onClick={() =>
                            handleAnnotateEditMessageClick(
                              message.timetoken,
                              message.description
                            )
                          }
                        >
                          <img
                            src={editPencil}
                            className="editPencil-img"
                            alt="Edit"
                          />
                        </a>
                      </div>
                    </div>
                    {!editAnnotateMessage &&
                      renderAnnotateMessageWithEditButton(
                        message,
                        messageIndex
                      )}
                    {editAnnotateMessage &&
                      renderEditableAnnotateMessage(message, messageIndex)}
                  </div>
                </div>
              );
            } else {
              // Other users
              let style = {};
              if (message.imageurl) {
                const profile_Image_url = message.imageurl;
                style = {
                  background:
                    'transparent url("' +
                    profile_Image_url +
                    '") 0% 0% no-repeat padding-box',
                };
              }
              return (
                <div className="chat-list-item" key={`message-${messageIndex}`}>
                  <div className="chat-image" style={style}></div>
                  <div className="chat-message-body">
                    <div className="chat-heading">
                      {message.annotationTitle}
                      {message?.file ? null :
                        <div className="float-right">
                          <Popup content='Copy To Clipboard' position="top right" trigger={<span className='duplicate-icon' onClick={() => handleChatCopy(message)} />} />
                        </div>
                      }
                    </div>
                    <div className="chat-message-white-bg">
                      {renderAnnotateMessage(message.description, messageIndex)}
                    </div>
                    <div className="chat-time">
                      <span>
                        {message.timecode ? message.timecode : formatted_time}
                      </span>
                      {message.annotateNote && (
                        <span
                          className="chat-annote"
                          style={{ backgroundColor: message.annotateColor }}
                        >
                          {message.annotateNote}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="annotation-chat-footer">
          <div className="annotation-chat-msg-input">
            <Input
              className="sendTxt"
              // icon={<Icon name="smile outline" link />}
              placeholder="Type annotation here..."
              size="small"
              spellCheck="true"
              value={annotateInput}
              onChange={(e) => onChangeAnnotateMessage(e.target.value)}
              onKeyPress={(event: any) => {
                if (event.key === "Enter") {
                  const annotateInputText = annotateInput;
                  setAnnotateInput("");
                  event.preventDefault();
                  if (annotateInputText.length > 0) sendAnnotationMessage(annotateInputText, "", "");
                }
              }}
            >
              <input />

              <Button
                color="violet"
                floated="right"
                className="send-chat-btn"
                size="medium"
                onClick={(e) => {
                  e.preventDefault();
                  sendAnnotationMessage(annotateInput, "", "");
                }}
              ></Button>
            </Input>
          </div>
          <div className="annotation-chat-btn">
            <ProgressLoader loading={annotateLoading} size="small" />
            <button
              className="annotation-chat-color-btn"
              style={{ backgroundColor: VFXcolor }}
              onClick={(e) => {
                e.preventDefault();
                sendAnnotationMessage(annotateInput, "VFX Note", VFXcolor);
              }}
            >
              VFX
            </button>
            <button
              className="annotation-chat-color-btn"
              style={{ backgroundColor: SFXcolor }}
              onClick={(e) => {
                e.preventDefault();
                sendAnnotationMessage(annotateInput, "SFX Note", SFXcolor);
              }}
            >
              SFX
            </button>
            <button
              className="annotation-chat-color-btn"
              style={{ backgroundColor: MXcolor }}
              onClick={(e) => {
                e.preventDefault();
                sendAnnotationMessage(annotateInput, "MX Note", MXcolor);
              }}
            >
              MX
            </button>
            <button
              className="annotation-chat-color-btn"
              style={{ backgroundColor: PIXcolor }}
              onClick={(e) => {
                e.preventDefault();
                sendAnnotationMessage(annotateInput, "PIX Note", PIXcolor);
              }}
            >
              PIX
            </button>

            <Button
              circular
              style={{ marginLeft: "10px" }}
              color="grey"
              onClick={(e) => {
                e.preventDefault();
                setModalAnnotateColorOpen(true)
              }}
            >
              Color
            </Button>

            <a
              className="cursor-pointer download_tc"
              onClick={(e) => {
                e.preventDefault();
                downloadAnnotateMessages();
              }}
            >
              <img className="" src={download_chat} alt="Download" />
            </a>
          </div>
          <div className="chat-typing-indicator">{typingInd}</div>
        </div>
        {isModalOpen && (
          <div>
            <GiphyModal hideGiphyModal={hideGiphyModal}></GiphyModal>
          </div>
        )}
      </nav>
    );
  };

  const showGiphyModal = function () {
    setModalStatus(true);
  };
  const hideGiphyModal = function () {
    setModalStatus(false);
  };

  const updateUserChimePref = async () => {
    try {
      const data: any = { api: "", payLoad: "" }
      data.api = api.users.edit

      if (user_chime_preferance) { //User Chime is ON, make it OFF
        data.payLoad = JSON.stringify({ user_chime_preferance: CHIME_VALUES.CHIME_OFF })
        setUserChimePreferance(false)
      } else { //User Chime is OFF, make it ON
        data.payLoad = JSON.stringify({ user_chime_preferance: CHIME_VALUES.CHIME_ON })
        setUserChimePreferance(true)
      }
      await backend.save(data, get(currentUser, "user.accessToken", ""));
    } catch (err: any) {
      console.error(err);
    }
  }
  const renderHeaderAccountSettings = () => {
    let style = null;
    return (
      <li className="account-settings">
        <Dropdown
          className="right-header-image"
          style={style}
          pointing="top right"
          icon={(<ImageCanvas imageUrl={user_imageurl}  className={'profile-image-property'}/>)}
        >
          <Dropdown.Menu>
            {
              team_admin ?
                <Dropdown.Item
                  text="Go to Team Admin"
                  icon="user circle outline"
                  onClick={gotoTeamAdmin}
                /> : isVCHost ?
                  <Dropdown.Item
                    text="Go to Publisher"
                    icon="user circle outline"
                    onClick={gotoPublisher}
                  /> : ""
            }
            <Dropdown.Item
              text="Chime Sound"
              icon={user_chime_preferance ? "microphone" : "microphone slash"}
              onClick={updateUserChimePref}
            />
            <Dropdown.Item onClick={handleDND}>
              <img className="dnd-icon" src={isDND ? dndInactiveIcon : dndActiveIcon} alt="" />
              {isDND ? "Deactivate DND" : "Do Not Disturb"}
            </Dropdown.Item>
            {/* {!!dataTrack && <Dropdown.Item onClick={handleDiagnostics}>
              <img className="dnd-icon" src={diagnosticsIcon} alt="" />
              Diagnostics Test
            </Dropdown.Item>} */}
            {
              showDominantSpeaker ?
                <Dropdown.Item
                  onClick={showStreamContent}
                >
                  <img className="dominant-speaker-icon" src={hideDominantSpeakerIcon} alt="" />
                  Hide Speaker Viewer
                </Dropdown.Item> :
                <Dropdown.Item
                  onClick={showDominantSpeakerView}
                >
                  <img className="dominant-speaker-icon" src={showDominantSpeakerIcon} alt="" />
                  Show Speaker Viewer
                </Dropdown.Item>
            }
            <Dropdown.Item
              text="Account Settings"
              icon="user circle outline"
              onClick={profile}
            />
            {/* <Dropdown.Item text="Help" icon="help circle" /> */}
            <Dropdown.Item
              text="Reset Password"
              icon="key"
              onClick={resetPassword}
            />
            <Dropdown.Item text="Log Out" icon="power" onClick={signOut} />
          </Dropdown.Menu>
        </Dropdown>
      </li>
    );
  };

  const renderRightNavVCControls = () => {
    if (!troom || (troom && isEmpty(troom))) {
      return;
    }
    return (
      <Fragment>
        {showAnnotationChatDisable && isTimeCodeFeatureIncluded && (
          <li>
            <a id="annotationChatArea" onClick={handleAnnotationChatWindow}>
              <img className="tc_image" src={tc_Img} alt="TC" />
            </a>
          </li>
        )}
        <ConfirmationalPopup
          open={timeCodePopup}
          data={"This is a BETA feature, are you sure you want to enable timecode capture ?"}
          onCancel={()=>{setTimeCodePopup(false)}}
          onOk={onTimecodePopupConfirm}
          
        />
        {showAnnotationCloseDisable && (
          <li>
           <a id="annotationChatArea"  className="active" onClick={handleAnnotationCloseChatWindow}>
           <img className="closeinvite" src={closeImg} alt="" />
           </a>
          </li>
           
        )}
        {showChatDisable && (
          <li>
            <a id="chatArea" onClick={handleChatWindow}>
            <img className="tc_image" src={chatImg} alt="" />
            </a>
          </li>
        )}
        {showCloseDisable && (
          <li>
            <a id="chatArea" className="active" onClick={handleCloseChatWindow}>
              <img className="closeinvite" src={closeImg } alt="" />
            </a>
          </li>
        )}
        {
          (isVCHost && isPublisher && isGuestInviteIncluded) &&
          <li>
            <a id="guestInviteArea" className={showGuestInviteCloseDisable ? "active" : ""} onClick={handleGuestInviteWindow}>
              <img className="tc_image" src={guest} alt="" />
            </a>
          </li>
        }
        {
          isVCHost && isPublisher &&
          <li>
            <a id="publisherPanelArea" className={showPublisherPanelCloseDisable ? "active" : ""} onClick={showPublisherPanelCloseDisable ? handleClosePublisherPanelWindow : handlePublisherPanelWindow}>
              <img className={showPublisherPanelCloseDisable ? "closeinvite" : "tc_image"} src={showPublisherPanelCloseDisable ? closeImg : group} alt="" />
            </a>
          </li>}
        {/* <li>
          <a id="invitePeoples">
            <img src={addAltImg} alt="" />
            <img className="closeinvite" src={closeImg} alt="" />
          </a>
        </li>
        {showOpenUser && (
          <li>
            <a id="roomCreatedcount" onClick={handleShowUsers}>
              <img src={eventsImg} alt="" />
              <span className="memberCount">18</span>
            </a>
          </li>
        )}
        {showCloseUser && (
          <li>
            <a
              id="roomCreatedcount"
              className="active"
              onClick={handleCloseUsers}
            >
              <img className="closeinvite" src={closeImg} alt="" />
            </a>
          </li>
        )} */}
      </Fragment>
    );
  };

  const renderHeaderRightNavControls = () => {
    return (
      <ul>
        {/* Header Right Nav VC control */}
        {showViewer ? renderRightNavVCControls() : null}

        {/* Header Right Account Settings control */}
        {renderHeaderAccountSettings()}
      </ul>
    );
  };

  const renderCameraAndMicrophoneSelection = () => {
    return (
      <li onClick={toggleAudioVideo} className={showDominantSpeaker ? 'disableddiv' : ""}>
        <img src={camera} alt="" style={{ marginLeft: "30px" }} />
        <i aria-hidden="true" className="dropdown icon" style={{ marginTop: "3px", marginLeft: "5px" }}></i>
        <Dropdown
          icon=""
          open={cameraDropdownOpen}
          onClose={toggleAudioVideo}
        >
          <Dropdown.Menu className="cameraMenu">
            <Dropdown.Item>
              <Dropdown
                className="cameraDropdown"
                id="cameraDropdown"
                text={cameraName}
                pointing="left"
                multiple={false}
                fluid
                selection
                placeholder="camera"
                onChange={changeCamera}
                options={cameraOptions} />
            </Dropdown.Item>
            <Dropdown.Item>
              <Dropdown
                text={micName}
                id="cameraDropdown"
                placeholder="microphone"
                multiple={false}
                fluid
                selection
                pointing="left"
                onChange={changeMicrophone}
                options={micOptions} />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span>Select Camera</span>
      </li>
    )
  }


  const handleVolumeControlMouseOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!showVolume) {
      setShowVolume(true);
    }
  }

  const handleVolumeMouseOut = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!showVolume) {
      setShowVolume(false);
    }
  }

  const handleVolumeDoubleClick = () => {
    setShowVolume(false);
    const elem: any = document.getElementsByTagName("video")[0];
    elem.muted = !elem.muted;
    setVolumeMute(elem.muted);
    // if (!elem.muted) {
    //   setShowVolume(true);
    // }
  }

  const showStreamContent = () => {
    setShowDominantSpeaker(false);
  }

  const showDominantSpeakerView = () => {
    setShowDominantSpeaker(true);
  }

  const getDominantSpeakerStatus = () => {
    if (showDominantSpeaker) {
      if (!localAudioMuteRef.current) {
        return true;
      } else if (!!dominantSpeakerIDRef.current) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const renderConferenceSelection = (member?: string) => {
    const toggleTools = () => {
      setShowPopup(!showPopup);
    }

    return (
      <li onClick={toggleTools}>
        <img src={phoneFilled} alt="" />
        <i aria-hidden="true" className="dropdown icon" style={{ marginTop: "3px", marginLeft: "5px" }}></i>
        <Dropdown
          icon=""
          open={showPopup}
          onClose={toggleTools}
        >
          <Dropdown.Menu className="cameraMenu">
            {
              !member && (
                <Dropdown.Item>
                  <Button
                    className="ui button"
                    onClick={handelVideoConfEndAll}
                    style={{ backgroundColor: 'white' }}
                  >
                    End Conference for All
                  </Button>
                </Dropdown.Item>
              )
            }
            <Dropdown.Item>
              <Button
                className="ui button"
                onClick={handelVideoConfEnd}
                style={{ backgroundColor: 'white', width: '183px'}}
              >
                Leave Conference
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span>Tools</span>
      </li>
    )
  }

  const onDrawingClick = () => {
    setDrawingOption(!showDrawingOption);
  }

  const renderDrawingOptions = () => {
    return (
      <li onClick={onDrawingClick}>
        <img src={pencil} alt="" style={{ marginLeft: "30px" }} />
        <i aria-hidden="true" className="dropdown icon" style={{ marginTop: "3px", marginLeft: "5px" }}></i>
        <Dropdown
          icon=""
          open={showDrawingOption}
          onClose={onDrawingClick}
        >
          <Dropdown.Menu className="cameraMenu">
            <Dropdown.Item>
              <div className={showDominantSpeaker ? 'disableddiv' : ""}>
                <Button
                  className="ui button"
                  onClick={pencilToggled}
                  style={{ backgroundColor: isPencilLine ? 'grey' : 'white', paddingLeft: '70px', paddingRight: '70px'}}
                >
                  Pencil
                </Button>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div className={showDominantSpeaker ? 'disableddiv' : ""}>
                <Button
                  className="ui button"
                  onClick={circleToggled}
                  style={{ backgroundColor: isPencilCircle ? 'grey' : 'white', paddingLeft: '70px', paddingRight: '70px'}}
                >
                  Circle
                </Button>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span>Tools</span>
      </li>
    )
  }

  const renderHeaderTopVCControls = () => {
    const showDomSpeaker = showDominantSpeaker;
    // Member
    if (!isVCHost) {
      // Show end button if conf is running and user participated
      if (troom && !isEmpty(troom)) {
        return (
          <Fragment>
            {renderConferenceSelection('member')}
            <span className="dividervertical">&nbsp;</span>

            <li onClick={reloadPage} className={showDomSpeaker ? 'disableddiv' : ""}>
              <img src={refreshIcon} alt="" />
              <span>Reload Stream</span>
            </li>
            <span className="dividervertical">&nbsp;</span>

            {renderDrawingOptions()}

            <span className="dividervertical">&nbsp;</span>

            <li className={showDomSpeaker ? 'disableddiv' : isEraser ? "eraser-on" : ""} onClick={eraserToggled}>
              <img src={erase} alt="" />
              <span>Erase</span>
            </li>

            <span className="dividervertical">&nbsp;</span>

            <li onClick={clearWhiteBoardAll} className={showDomSpeaker ? 'disableddiv' : ""}>
              <img src={clear} alt="" />
              <span>Clear</span>
            </li>
            <span className="dividervertical">&nbsp;</span>
            <Fragment>{renderCameraAndMicrophoneSelection()}</Fragment>

            <span className="dividervertical">&nbsp;</span>

            <li onClick={fullScreenVideo} className={showDomSpeaker ? 'disableddiv' : ""}>
              <img className="fullscreen-img" src={fitToScreen} alt="" />
              <span>FullScreen Video</span>
            </li>

            <span className="dividervertical">&nbsp;</span>
            <li data-tooltip="Volume" className={showDomSpeaker ? 'ui disableddiv' : "ui"} data-position="bottom center" >
              <div style={{ position: "absolute", width: !!showVolume ? "160px" : "30px", left: "20%" }}
                onMouseLeave={handleVolumeMouseOut}
                onMouseEnter={handleVolumeControlMouseOver} >
                <img onClick={handleVolumeDoubleClick} className="fullscreen-img" src={!!volumeMute ? volumeMuteIcon : volume} alt="" />
                {!!showVolume ? <div className="volume-control">
                  <input type="range" min="0" max="100" step="1" value={volumeLevel}
                    style={{ background: `linear-gradient(to right, #0477cc 0%, #0477cc ${volumeLevel}%, #fff  ${volumeLevel}%, white 100%)` }}
                    onInput={(event) => volumeUpDown(event)}
                    onChange={(event) => volumeUpDown(event)} />
                </div> : ''
                }
              </div>
            </li>
          </Fragment>
        );
      }
    }

    // Admin
    if (isVCHost) {
      // Show end button if conf is running
      if (troom && !isEmpty(troom)) {
        // const sId = {localParticipant.sid}+"_audio";
        return (
          <Fragment>
            {renderConferenceSelection()}
            <span className="dividervertical">&nbsp;</span>

            <li onClick={reloadPage} className={showDomSpeaker ? 'disableddiv' : ""}>
              <img src={refreshIcon} alt="" />
              <span>Reload Stream</span>
            </li>
            <span className="dividervertical">&nbsp;</span>

            <li onClick={handleGlobalMute} className={showDomSpeaker ? 'disableddiv' : ""}>
              {
                muteAll ?
                  <>
                    <img src={golbalAudioUnMute} alt="" />
                    <span>Unmute All</span>
                  </>
                  :
                  <>
                    <img src={golbalAudioMute} alt="" />
                    <span>Mute All</span>
                  </>
              }
            </li>
            <span className="dividervertical">&nbsp;</span>

            {renderDrawingOptions()}

            <span className="dividervertical">&nbsp;</span>

            <li className={showDomSpeaker ? 'disableddiv' : isEraser ? "eraser-on" : ""} onClick={eraserToggled}>
              <img src={erase} alt="" />
              <span>Erase</span>
            </li>

            <span className="dividervertical">&nbsp;</span>

            <li onClick={clearWhiteBoardAll} className={showDomSpeaker ? 'disableddiv' : ""}>
              <img src={clear} alt="" />
              <span>Clear</span>
            </li>

            <span className="dividervertical">&nbsp;</span>

            <Fragment>{renderCameraAndMicrophoneSelection()}</Fragment>

            <span className="dividervertical">&nbsp;</span>

            <li onClick={fullScreenVideo} className={showDomSpeaker ? 'disableddiv' : ""}>
              <img className="fullscreen-img" src={fitToScreen} alt="" />
              <span>FullScreen Video</span>
            </li>

            <span className="dividervertical">&nbsp;</span>

            <li data-tooltip="Volume" className={showDomSpeaker ? 'ui disableddiv' : "ui"} data-position="bottom center">
              <div style={{ position: "absolute", width: !!showVolume ? "160px" : "30px", left: "20%" }}
                onMouseLeave={handleVolumeMouseOut}
                onMouseEnter={handleVolumeControlMouseOver} >
                <img onClick={handleVolumeDoubleClick} className="fullscreen-img" src={!!volumeMute ? volumeMuteIcon : volume} alt="" />
                {!!showVolume ? <div className="volume-control">
                  <input type="range" min="0" max="100" step="1" value={volumeLevel}
                    style={{ background: `linear-gradient(to right, #0477cc 0%, #0477cc ${volumeLevel}%, #fff  ${volumeLevel}%, white 100%)` }}
                    onInput={(event) => volumeUpDown(event)}
                    onChange={(event) => volumeUpDown(event)} />
                </div> : ''
                }
              </div>
            </li>

            {/* <li onClick={() => volumeUpDown("down") }>
              <img className="fullscreen-img" src={volumeDown} alt="" />
              <span>Volume Down</span>
            </li> */}

            {/* <span className="dividervertical">&nbsp;</span>
            <li className="settingBtn">
              <img src={settingsImg} alt="" />
              <span>Setting</span>
            </li>
            <li>
              <img src={golbalAudioUnmute} alt="" />
              <span></span>
            </li>
            <li>
              <img src={golbalVideoMute} alt="" />
              <span>Mute All</span>
            </li>
            <span className="dividervertical">&nbsp;</span> */}

          </Fragment>
        );
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="old-viewer-selector header-viewer">
        {/* Left  Logo*/}
        <div className="logo-viewer" onClick={handelLogoClick}></div>

        {/* Top VC Controls*/}
        <div className="centerMenuTop">
          <ul>{renderHeaderTopVCControls()}</ul>
        </div>

        {/* Right Nav Controls with Settings */}
        <div className="menuTopRight">{renderHeaderRightNavControls()}</div>

        {/*  Chat window */}
        {renderChatWindowFrmHeader()}

        {renderAnnotationChatWindowFrmHeader()}

        {
          showPublisherPanel &&
          <PublisherPanel
            troom={troom}
            usersUpdated={usersUpdated}
            setUsersUpdated={setUsersUpdated}
            roomParticipants={roomParticipantsListRef.current}
            roomParticipantsConnectionStatus={roomParticipantsConnectionStatusRef.current}
          />
        }

        {
          showGuestInvite &&
          <GuestInvite troom={troom} setGuestUsersList={setGuestUsersList}
            usersUpdated={usersUpdated}
            setUsersUpdated={setUsersUpdated} />
        }

        {/*  Invited Users for Non Stream VC (Case-2)*/}
        {/* {renderVCInvitedUsersFrmHeader()} */}
      </div>
    );
  };
  const showConfigLength =
    !!showConfig && showConfig.length > 0 ? showConfig[0]["config_value"] : 0;

  const FallbackComponent = () => {
    return <div className="not-found">Sorry there was a problem !</div>;
  };
  const myFallback = <FallbackComponent />;
  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      {!!dataTrack && !!showDiagnostics && <Diagnostics iceServers={iceServers} setShowDiagnostics={setShowDiagnostics} /> }
      <FullScreen handle={fullScreenHandler}>
        <div className="show-viewer-main" id="show-viewer-main">
          <ProgressLoader loading={loading || isLoading} />

          {/* Header */}
          {renderHeader()}

          {/* Body */}
          {renderBodyContent()}

          <Modal
            open={modalOpen}
            size={"tiny"}
            onClick={() => setModalOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Stream Limit Reached</Modal.Header>
            <Modal.Content>
              You can not start more than {showConfigLength} number of Streams.
            </Modal.Content>
            <Modal.Actions>
              <Button color="green" onClick={() => setModalOpen(false)}>
                CLOSE
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={clearChatModalOpen}
            size={"tiny"}
            onClick={() => setClearChatModalOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Clear Chat</Modal.Header>
            <Modal.Content>
              You are about to clear the chat messages
            </Modal.Content>
            <Modal.Actions>
              <Button color="green" onClick={() => clearChat()}>
                OK
              </Button>
              <Button color="grey" onClick={() => setModalOpen(false)}>
                CANCEL
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={exportChartConfirmationOpen}
            size={"tiny"}
            onClick={() => setExportChartConfirmationOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Export Chat</Modal.Header>
            <Modal.Content>
              Are you sure you want to export the chat log
            </Modal.Content>
            <Modal.Actions>
              <Button color="green" onClick={downloadMessages}>
                YES
              </Button>
              <Button color="grey" onClick={() => setExportChartConfirmationOpen(false)}>
                CANCEL
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={modalAnnotateColorOpen}
            size={"tiny"}
            onClick={() => setModalAnnotateColorOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Choose Annotate Color</Modal.Header>
            <Modal.Content>
              <div>
                <div>
                  <label className="form-input-label">Choose VFX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={VFXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setVFXcolor(value);
                    }}
                  />
                </div>
                <div>
                  <label className="form-input-label">Choose SFX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={SFXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setSFXcolor(value);
                    }}
                  />
                </div>
                <div>
                  <label className="form-input-label">Choose MX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={MXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setMXcolor(value);
                    }}
                  />
                </div>
                <div>
                  <label className="form-input-label">Choose PIX Color</label>
                  <Dropdown
                    placeholder="Select VFX Color"
                    className="form-input"
                    fluid
                    selection
                    options={annotationColorOptions}
                    value={PIXcolor}
                    onChange={(e: any, data: any) => {
                      const value: string = data?.value || "";
                      setPIXcolor(value);
                    }}
                  />
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button color="grey" onClick={() => setModalAnnotateColorOpen(false)}>
                CLOSE
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={localParticipantBGModalOpen}
            size={"tiny"}
            // onClick={() => setLocalParticipantBGModalOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Choose Virtual Background</Modal.Header>
            <Modal.Content>
                <div className="background-selector-wrapper">
                  {
                    backgrounds.map((item: any) => {
                      return (
                        <div className={`background-selector ${tempActiveBG===item.value ? "active" : ""}`} onClick={(event)=>{
                          event.preventDefault();
                          event.stopPropagation();
                          setTempActiveBG(item.value)
                          }}>
                          <img src={item.thumbImage} alt={item.value} />
                        </div> 
                      )
                    })
                  }

                </div>
            </Modal.Content>
            <Modal.Actions>
              <Button color="green" onClick={() => {
                const tempActiveBGimg = tempActiveBG;
                setActiveBG(tempActiveBGimg)
                setLocalParticipantBGModalOpen(false)
                changeCameraTrack(cameraIdRef.current);
                }}>
                APPLY
              </Button>
              <Button color="grey" onClick={() => {
                setLocalParticipantBGModalOpen(false)
                }}>
                CANCEL
              </Button>
            </Modal.Actions>
          </Modal>
          <ConfirmationalPopup
              open={uploadAlert}
              data={`Are you sure you want to upload ${fileName} ?`}
              onCancel={handleUploadCancel}
              onOk={handleUploadConfirm}
            />
        </div>
      </FullScreen>
    </Sentry.ErrorBoundary>
  );
};

export default ShowViewerDashboard;
