import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from '../../Firebase';
import { NON_AUTH_ROUTES } from './AllRoutes';
import { isGuestAuthenticated } from '../../Utils/guestHelpers';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import ProgressLoader from '../Common/ProgressLoader';

interface IProps {
  exact?: boolean;
  path: string;
  component: any;
}

const RouteComponent = ({ component: Component }: any) => {
  const location = useLocation();
  const currentUser: any = get(useContext(AuthContext), 'currentUser', {});
  return isGuestAuthenticated() ||
    (currentUser.user && currentUser.is2FASuccess) ? (
    <Component {...location} />
  ) : (
    <Navigate to={NON_AUTH_ROUTES.login} state={{ from: location }} />
  );
};

export const PrivateRoute = ({ component: Component, ...rest }: IProps) => {
  const loading = get(useContext(AuthContext), 'currentUser.loading', true);
  console.log("...rest", rest);
  return !loading ? <RouteComponent component={Component} /> : <ProgressLoader loading={true}/>;
};

export const OTPRoute = ({ component: Component, ...rest }: IProps) => {
  const location = useLocation();
  const user: any = get(useContext(AuthContext), 'currentUser.user', {});
  return !!user ? (
    <Component />
  ) : (
    <Navigate to={NON_AUTH_ROUTES.login} state={{ from: location }} />
  );
};
