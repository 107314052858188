import React, { useState, useEffect, MouseEvent, Fragment } from "react";
import { Icon, Input, Button } from "semantic-ui-react";

import "./showviewer.css";

const RightMenuWithChatWindow = () => {

    const [showUser, setUsers] = useState(false);
    const [showChat, setChatWindow] = useState("main-menu-right-close");
    const [showCloseDisable, setCloseDisable] = useState("main-menu-right-close");
    const [showChatDisable1, setChatDisable1] = useState("main-menu-right-show");


    const handleChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
        setChatWindow("main-menu-right-chat");
        setCloseDisable("main-menu-right-show");
        setChatDisable1("main-menu-right-close");
    }
    const handleCloseChatWindow1 = async (event: React.MouseEvent<HTMLElement>) => {
        setCloseDisable("main-menu-right-close");
        setChatDisable1("main-menu-right-show");
        setChatWindow("main-menu-right-close");
    }

    const moveRight = async (event: React.MouseEvent<HTMLElement>) => {
        document.getElementById('vtray')?.scrollBy(300, 0);
    }

    return (

        <div>
            <nav className="main-menu-right">

                <ul>
                    {/* <li>
            <a href="">
                <i className="fa fa-2x">
                    <Icon name='paint brush'/>
                </i>
            </a>
        </li>
        <li className="">
            <a href="">
                <i className="fa fa-2x">
                    <Icon name="users" />
                </i>
            </a>
        </li>                */}
                    {/* <li className="main-bar">
              <a onClick={handleChatWindow} className={showChatDisable1}>
                <i className="fa fa-2x chat-icon">
                </i>
              </a>
            </li> */}

                    {/* <li className="main-bar" >
              <a className={showCloseDisable} onClick={handleCloseChatWindow1}>
                <i className="fa fa-2x cross-icon"></i>
              </a>
            </li> */}
                </ul>
                <ul className="menu-bottom">
                    {/* <li className="main-bar">
                        <a href="">
                            <i className="fa fa-2x">
                                <Icon name="help circle" />
                            </i>
                        </a>
                    </li> */}
                    {/* <li className="main-bar">
                        <a className="flex-next" onClick={moveRight}>
                            <i className="fa fa-2x">
                            </i>
                        </a>
                    </li> */}
                </ul>
            </nav>

            <nav className={showChat}>
                <div className="chat-h1">
                    <span className="chat-text">Chat</span>
                    <a className="user-icon-text" onClick={() => setUsers(!showUser)}>
                        <i className="fa fa-2x">
                            <Icon name="users" className="user-icon-text" />
                        </i>
                    </a>
                </div>
                <div>
                    <div>
                        {showUser &&
                            <div className="main-menu-right-chat-users">
                                <div>
                                    <a className="user-icon-text" onClick={() => setUsers(!showUser)}>
                                        <i className="fa fa-2x cross-icon"></i>
                                    </a>
                                    <span className="chat-heading-user">
                                        Attendees in Chat
                  </span>
                                </div>

                                <div style={{ 'borderTop': '1px solid darkgrey' }}>
                                    <div className="chat-heading-user"> Linda Wlson</div>
                                    <div className="chat-message-user">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                </div>
                                <div>
                                    <div className="chat-heading-user"> Linda Wlson</div>
                                    <div className="chat-message-user">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                </div>
                                <div>
                                    <div className="chat-heading-user"> Linda Wlson</div>
                                    <div className="chat-message-user">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                </div>
                            </div>
                        }
                    </div>
                    <ul className="chat-content">
                        <li>
                            <div className="chat-image">
                                <div className="chat-heading"> Linda Wlson</div>
                                <div className="chat-message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                            </div>
                        </li>
                        <li>
                            <div className="chat-image">
                                <div className="chat-heading"> Linda Wlson</div>
                                <div className="chat-message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                            </div>
                        </li>
                        <li>
                            <div className="chat-image">
                                <div className="chat-heading"> Linda Wlson</div>
                                <div className="chat-message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                            </div>
                        </li>

                    </ul>

                </div>

                <div className="form-field stickyFields">
                    <Input
                        icon={<Icon name='smile outline' link />}
                        placeholder='Send a message here' size="big"
                    />
                    <div style={{ 'marginTop': '6px' }}>
                        <Button color="violet" floated="right" size="medium">CHAT</Button>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default RightMenuWithChatWindow;