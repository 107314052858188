import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, Modal,Dropdown } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import * as Sentry from '@sentry/react';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import { PAGES, SHOWPUBLISHER_PAGES } from '../../Utils/constants';
import { Mixpanel } from '../../Service/Mixpanel';
import { fetchShowById } from '../../Service/show';
import * as constants from '../../Utils/constants';
import OverLay from '../../Components/Common/OverLay';
import { auth } from '../../Firebase';
import ReactSelect from '../../Components/Common/ReactSelect';
import deleteIcon from '../../Images/delete.svg';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";

interface IStreamProps {
  showId: string;
  setStreamUpdated: any;
  teamAdmin: boolean;
  usersGroupsFlag?: boolean;
}
export interface DeviceState {
  id: string;
  deviceId: string;
}

const streamLimitEmail: string = process.env.REACT_APP_STREAM_LIMIT_REACHED_CONTACT_MAIL || '';
const CreateStream = (props: IStreamProps) => {
  const { REACT_APP_ENABLE_LOGGER = false } = process.env;
  const currentUser = get(useContext(AuthContext), 'currentUser', {});
  const [, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [overlayMsg, setOverlaymsg] = useState('');

  const [configValue, setConfigValue] = useState(0);

  const [encoderValue, setEncoderValue] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceValues, setDeviceValues] = useState<DeviceState>({
    id:'',
    deviceId:''
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [showAdminList, setShowAdminList] = useState([]);

  const [show_id, setShowId] = useState('');
  const [parent_show_id, setParentShowId] = useState('');
  const [stream_label, setStreamLabel] = useState('');
  const [stream_end_ts, setStreamEndTS] = useState(null);
  const [stream_comments, setStreamComments] = useState('');

  const [token, setToken] = useState('');
  const [vendor_stream_name, setVendorStreamName] = useState('');

  const [streamNameValid, setStreamNameValid] = useState(true);
  const [errorStreamName, seterrorStreamName] = useState('');

  const [activeStreams, setTotalActiveStream] = useState([] as any[]);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteStreamId, setDeleteStreamId] = useState('');

  //StreamConfigList
  const [streamConfigList, setStreamConfigList] = useState([]);

  const [assignedPublisherUID, setAssignedPublisherUID] = useState('');

  const [activePublisherCount, setActivePublisherCount] = useState([]);

  const [existStreamLabel, setExistStreamLabel] = useState([] as any[]);

  const [tableData, setTableData] = useState([] as any);

  const getStreamUserCountList = useCallback(async () => {
    const { showId } = props;
    try {
      const activeStreamData = {
        api: api.streams.pubActiveStreamCount,
        queryParam: { show_id: showId },
      };
      setLoading(true);
      const streamsData = await backend.fetch(
        activeStreamData,
        get(currentUser, 'user.accessToken', ''),
      );
      setLoading(false);
      const streamUser =
        streamsData.length > 0 && streamsData[0].stream
          ? streamsData[0].stream.map((item: any) => item.created_user_email)
          : [];
      const streamLabels =
        streamsData.length > 0 && streamsData[0].stream
          ? streamsData[0].stream.map((item: any) => item.user_entered_stream_label)
          : [];

      setExistStreamLabel(streamLabels);

      const getCount = (email: any) => {
        return streamUser.filter((user: any) => user === email).length;
      };

      const publisherListOption = streamsData[0].user.map((item: any) => {
        const count = getCount(item.user_email);
        return {
          label: `${get(item, 'user_name.user_name_first', '')} ${get(
            item,
            'user_name.user_name_last',
            '',
          )} 
              (${item.user_email}) - Stream Count: ${count} ${
            !!item.team_admin ? '(Team Admin)' : ''
          }`,
          value: item.user_id,
          email: item.user_email,
          name: `${get(item, 'user_name.user_name_first', '')} ${get(
            item,
            'user_name.user_name_last',
            '',
          )}`,
          isDisabled: !!item.team_admin ? false : count > 0,
        };
      });
      publisherListOption.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setActivePublisherCount(publisherListOption);
    } catch (err: any) {
      if (err.errMessage) {
        console.error(err.errMessage);
      }
    }
  }, [activePublisherCount]);

  useEffect(() => {
    // Mixpanel
    Mixpanel.track('Visit', {
      Platform: 'Web',
      'Page Name': PAGES.SHOW_PUBLISHER_PAGE + '_' + SHOWPUBLISHER_PAGES.CREATE_STREAM,
    });

    //Get Config Details
    const getStreamConfig = async () => {
      try {
        const data = {
          api: api.configs.listConfigsByStreamType,
        };
        setLoading(true);
        const response = await backend.fetch(data, get(currentUser, 'user.accessToken', ''));
        setStreamConfigList(response);
        setLoading(false);
      } catch (err: any) {
        const msg = 'Error getting config info';
        console.error(msg);
        if (err.errMessage) {
          console.error(err.errMessage);
        }
      } finally {
        setLoading(false);
      }
    };

    //Get Config Details
    const getConfig = async () => {
      try {
        setLoading(true);
        const flagResult: any = await fetchShowById(props.showId);
        const { hits } = flagResult;
        if (hits.length > 0) {
          setParentShowId(get(hits, '0.parent_show_id', ''));
          const configList = get(hits, '0.configs', {});
          const flagList = get(hits, '0.feature_flags', {});
          const streamCountValue = Object.keys(configList).filter((config: any) => {
            return configList[config].config_name === 'stream_count';
          });
          setConfigValue(get(configList, `${streamCountValue[0]}.config_value`, ''));
          setEncoderValue(get(flagList, 'ALLOW_ENCODER', false));
          if (get(flagList, 'ALLOW_ENCODER', false)) {
            const deviceResult = await backend.fetch(
              {
                api: api.encoder.showDevice,
                urlParam: props.showId,
              },
              get(currentUser, 'user.accessToken', ''),
            );

            const deviceList = deviceResult.map((device: any) => ({
              key: device.id,
              value: device.id,
              deviceId: device.device_id,
              text: `${device.name}-${device.mac_id}`,
            }));
            setDeviceList(deviceList);
          }
        }
      } catch (err: any) {
        if (err.errMessage) {
          console.error(err.errMessage);
        }
      } finally {
        setLoading(false);
      }
    };

    if (props.showId) {
      getConfig();
      getStreamUserCountList();
      getStreamConfig();
      setShowId(props.showId);
      getActiveShowAdminList();
    }
  }, [props.showId]);

  const clearFormFields = () => {
    setStreamComments('');
    setStreamLabel('');
    setStreamEndTS(null);
    setAssignedPublisherUID('');
    getStreamUserCountList();
    setDeviceValues((prevState)=>({
      ...prevState,
      id: '',
      deviceId: ''
    }))
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const showOverlayForThreeSeconds = async () => {
    setOverlayDisplay(true);
    await sleep(3000);
    setOverlayDisplay(false);
  };

  const getConfigStream = async () => {
    try {
      const data = {
        api: api.configs.listConfigsByShowType,
      };
      const result = await backend.fetch(data, get(currentUser, 'user.accessToken', ''));
      const filterStreamConfig = result.filter(
        (config: any) => config['config_name'] === 'stream_count',
      );
      return filterStreamConfig;
    } catch (err: any) {
      console.log('err', err);
      return [];
    }
  };

  const getTotalStream = async () => {
    try {
      const getActiveStreamList = {
        api: !!parent_show_id ? api.streams.listAllActiveChildStream : api.streams.listAllActive,
        urlParam: !!parent_show_id ? parent_show_id : props.showId,
      };
      console.log('getActiveStreamList', getActiveStreamList);
      return await backend.fetch(getActiveStreamList, get(currentUser, 'user.accessToken', ''));
    } catch (err: any) {
      return [];
    }
  };

  const getActiveShowAdminList = async () => {
    try {
      const getActiveShowAdminList = {
        api: api.users.listActiveShowAdminInShow,
        urlParam: props.showId,
      };
      const result = await backend.fetch(
        getActiveShowAdminList,
        get(currentUser, 'user.accessToken', ''),
      );
      const showAdminList =
        result.length > 0 ? result.map((showAdmin: any) => showAdmin.user_email) : [];
      setShowAdminList(showAdminList);
    } catch (err: any) {
      console.log('err', err);
    }
  };

  const sendStreamReachedEmail = async (show_name: string) => {
    try {
      const input = {
        show_name,
        show_admin_list: showAdminList.join(),
      };
      const sendEmail = {
        api: api.notification.streamLimitReachedEmail,
        payLoad: JSON.stringify(input),
      };
      const result = await backend.save(sendEmail, get(currentUser, 'user.accessToken', ''));
      console.log('result', result);
    } catch (err: any) {
      console.log('err', err);
      return [];
    }
  };

  const create = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage('');
      setErrorMessage('');

      setLoading(true);
      const totalStreamCount: any = await getTotalStream();
      const configStream = await getConfigStream();
      const configEnabled =
        configStream.length > 0 && !!configStream[0]["config_enable"];
      if (
        totalStreamCount.filter((stream: any) => stream.created_user_email === auth.getUserEmail())
          .length > 0
      ) {
        setErrorMessage(
          'You have already created a stream. Please delete the old one to create new'
        );
        return false;
      }

      if (!!configEnabled && configValue > 0 && configValue <= totalStreamCount.length) {
        setTotalActiveStream(totalStreamCount);
        setModalOpen(true);
        setLoading(false);
        sendStreamReachedEmail(totalStreamCount[0].show_name);
        return false;
      }

      // Call Backend to Create Stream
      let comment = stream_comments;

      if (comment && comment.length > 255) {
        comment = stream_comments.substring(0, 255);
      }

      let config_details: any = {};

      streamConfigList.forEach((item: any) => {
        config_details[item?.id] = 2;
      });

      const device =  (deviceValues.deviceId !== '' || deviceValues.id !== '') ? deviceValues : null;

      const input: any = {
        show_id,
        stream_label,
        stream_end_ts,
        stream_comments: comment,
        config_details,
        device,
      };

      // Team Admin passing assigned publisher user id.
      if (props.teamAdmin && assignedPublisherUID) {
        if (assignedPublisherUID !== auth.getUserId()) {
          // If team admin chooses himself then dont pass it.
          input.assign_pub_id = assignedPublisherUID;
        }
      }

      const data = {
        api: api.streams.create,
        payLoad: JSON.stringify(input),
      };

      console.log(data);

      const callStartTime = new Date();

      const response = await backend.save(data, get(currentUser, 'user.accessToken', ''));
      setLoading(false);

      const callEndTime = new Date();
      const timeTaken: any = callEndTime.getTime() - callStartTime.getTime();

      const logData = {
        api: api.log.log,
        payLoad: JSON.stringify({
          logFrom: constants.PAGES.SHOW_PUBLISHER_PAGE,
          data: {
            startTime: callStartTime,
            endTime: callEndTime,
            timeTaken: timeTaken,
            api: api.streams.create.endPoint,
            input: input,
          },
        }),
      };

      if (REACT_APP_ENABLE_LOGGER) {
        backend.save(logData, get(currentUser, 'user.accessToken', ''));
      }
      console.log('response', response);

      if (props.teamAdmin) {
        tableData.push({
          stream_label,
          stream_publisher: activePublisherCount.filter(
            (publisher: any) => publisher.value === assignedPublisherUID,
          )[0],
          stream_doc_id: response.stream_doc_id,
        });
        setTableData(tableData);
      }

      const stream_doc_id = response.stream_doc_id;
      const token = response.vendorStreamData.token;
      setToken(token);

      const vendor_stream_name = response.vendorStreamData.streamName;
      setVendorStreamName(vendor_stream_name);

      setSuccessMessage(`Created stream with 
        id : ${stream_doc_id} 
        stream name : ${vendor_stream_name}
        token : ${token}`);

      Mixpanel.track('Publisher Stream Action', {
        Platform: 'Web',
        'Stream Label': stream_label,
        'Action Type': 'CreateStream',
      });

      setOverlaymsg(`${stream_label} has been created`);

      clearFormFields();

      showOverlayForThreeSeconds();
    } catch (err: any) {
      const msg = 'Unable to process your request now, please try after some time !';

      const user = auth.getUser();
      const uid: any = get(user, 'uid', '');
      Sentry.configureScope((scope) => scope.setUser({ id: uid }).setTag('show_id', show_id));
      Sentry.captureMessage(msg);

      console.error(msg);
      if (err.errCode === 30102) {
        setModalOpen(true);
      } else if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      props.setStreamUpdated(true);
      setLoading(false);
    }
  };

  const validateStreamName = (event: any) => {
    setStreamLabel(event.target.value);
    const validateStream: string = event.target.value.toLowerCase(); 
    const existingStreamNamesLower = existStreamLabel.map(existingStream => existingStream.toLowerCase());
    if (!validateStream) {
      setStreamNameValid(false);
      seterrorStreamName('Please enter Stream Name');
    } else if (existingStreamNamesLower.includes(validateStream)) {
      setStreamNameValid(false);
      seterrorStreamName('The Stream Name already exists.');
    } else {
      const regex = new RegExp(/^[A-Za-z0-9\_]+$/);
      if (!regex.test(validateStream)) {
        setStreamNameValid(false);
        seterrorStreamName(
          'Please enter letters and numbers as stream name. No special characters and spaces are allowed',
        );
      } else {
        setStreamNameValid(true);
        seterrorStreamName('');
      }
    }
  };

  const getPublisherNames = () => {
    return activeStreams.map((stream: any, index) => {
      let name = '';
      if (stream && stream.user_name && stream.user_name.user_name_first) {
        name = name + stream.user_name.user_name_first;
      }
      if (stream && stream.user_name && stream.user_name.user_name_last) {
        name = name + ' ' + stream.user_name.user_name_last;
      }
      return (
        <ul key={index}>
          <li>{name || stream.created_user_email}</li>
        </ul>
      );
    });
  };

  const handleAssignPublisher = (value: any) => {
    setAssignedPublisherUID(value.value);
  };

  const renderAssignStreamToPublisher = () => {
    if (!props.teamAdmin) {
      return;
    }
    return (
      <div className='field-and-icon'>
        <div className='field-icon-tv left-icon'></div>
        <div className='form-field  icon-input-field'>
          <label className='form-input-label mandatory'>Assign Stream To Publisher</label>

          {/* Change to a drop down and populate publisher names. Should be a real time search
          Dont show everything in the drop down */}
          <ReactSelect
            name='publisher-select'
            value={[assignedPublisherUID]}
            options={activePublisherCount}
            onChange={handleAssignPublisher}
          />
        </div>
      </div>
    );
  };

  const handleDelete = async (streamId: string) => {
    try {
      const data = {
        api: api.streams.update,
        urlParam: streamId,
        payLoad: {
          update_data: { stream_status: constants.STATUS.DELETED },
        },
      };

      setLoading(true);
      await backend.save(data, get(currentUser, 'user.accessToken', ''));
      getStreamUserCountList();
      const filterTableData = tableData.filter((data: any) => data.stream_doc_id !== streamId);
      setTableData(filterTableData);
      setLoading(false);
    } catch (err: any) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };

  const isValid = () => {
    if (props.teamAdmin) {
      return streamNameValid === false || stream_label === '' || assignedPublisherUID === '';
    }
    return streamNameValid === false || stream_label === '';
  };

  const openConfirm = (id: string) => {
    setDeleteStreamId(id);
    setDeleteAlertOpen(true);
  };

  const handleDeleteConfirm = () => {
    handleDelete(deleteStreamId);
    setDeleteAlertOpen(false);
  };

  const handleDeviceChange = (e: any, data: any) => {
    const selectedDevice = deviceList.filter((device:any)=> device.value === data.value )
    setDeviceValues((prevState)=>({
      ...prevState,
      id: data.value,
      deviceId: get(get(selectedDevice,'0',''),'deviceId','')
    }))
  };

  return (
    <div className='createstream'>
      <ProgressLoader loading={loading} />

      <div className='heading margin-0 '>
        <p className='heading-text-center'>
          Publisher :<span className='grey-text'> Create Stream</span>
        </p>
        <hr className='top' />
      </div>

      <div className='form-content margin-left-0'>
        <form className='ui form'>
          {/* Semantic UI form */}
          <div className='field-and-icon'>
            <div className='field-icon-tv left-icon'></div>
            <div className='form-field  icon-input-field'>
              <label className='form-input-label mandatory'>Stream Name</label>
              <input
                className={streamNameValid === false ? 'form-input error-border' : 'form-input'}
                type='text'
                maxLength={20}
                value={stream_label}
                //onChange={(e) => setStreamLabel(e.target.value)}
                onChange={validateStreamName}
              />
              {streamNameValid === false && (
                <span className='error-message'>{errorStreamName}</span>
              )}
            </div>
          </div>
          {renderAssignStreamToPublisher()}
          <div className='field-and-icon'>
            <div className='field-icon-calender left-icon'></div>
            <div className='form-field icon-input-field semantic-date-input'>
              <label className='form-input-label'>Stream End Time</label>
              <SemanticDatepicker
                filterDate={(date) => {
                  const now = new Date();
                  return date >= now;
                }}
                placeholder='Stream End Time'
                format='YYYY-MM-DD'
                showToday={false}
                name='survey_start_datetime'
                value={stream_end_ts}
                onChange={(event, { value }: any) => setStreamEndTS(value)}
              />
            </div>
          </div>
          {encoderValue && (
            <div className='field-and-icon'>
              <div className='field-icon-tv left-icon'></div>
              <div className='form-field  icon-input-field'>
                <label className='form-input-label'>Device</label>
                <Dropdown
                  placeholder='Select'
                  className='form-input'
                  fluid
                  search
                  selection
                  options={deviceList}
                  name='auto_allow'
                  value={deviceValues.id}
                  onChange={handleDeviceChange}
                ></Dropdown>
              </div>
            </div>
          )}

          <div className='field-and-icon'>
            <div className='field-icon-note left-icon'></div>
            <div className='form-field icon-input-field'>
              <label className='form-input-label'>Notes</label>
              <textarea
                className='form-textarea'
                placeholder='Notes goes here'
                rows={5}
                value={stream_comments}
                onChange={(e) => setStreamComments(e.target.value)}
              />
            </div>
          </div>
          <div className='center-button'>
            <Button
              className='violet-button  margin-top-50'
              color='violet'
              loading={loading}
              onClick={create}
              disabled={isValid()}
            >
              Create
            </Button>
          </div>
          {props.teamAdmin && tableData.length > 0 && (
            <table style={{ width: '100%', marginTop: '25px' }}>
              <thead>
                <tr>
                  <th>Stream</th>
                  <th>Publisher</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item: any) => {
                  return (
                    <tr>
                      <td style={{ textAlign: 'center' }}>{item.stream_label}</td>
                      <td style={{ textAlign: 'center' }}>{item.stream_publisher.name}</td>
                      <td style={{ textAlign: 'center' }}>
                        <img
                          src={deleteIcon}
                          alt='delete'
                          style={{ cursor: 'pointer', width: '15px' }}
                          onClick={() => openConfirm(item.stream_doc_id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {errorMessage && (
            <>
              <div className='flex'>
                <span className='alert-icon'></span>
                <span className='error-message alert-text-message'>{errorMessage}</span>
              </div>
            </>
          )}
        </form>
      </div>
      <Modal
        open={modalOpen}
        size={'tiny'}
        onClick={() => setModalOpen(false)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Active Stream Limit Reached</Modal.Header>
        <Modal.Content>
          You have exceeded the number of licensed streams.
          <br />
          please contact ({streamLimitEmail}).
          <br />
          The other users who have active streams are:
          {getPublisherNames()}
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => setModalOpen(false)}>
            CLOSE
          </Button>
        </Modal.Actions>
      </Modal>
      <ConfirmationalPopup
        open={deleteAlertOpen}
        data={"Are you sure you want to delete the stream ?"}
         onCancel={() => setDeleteAlertOpen(false)}
         onOk={handleDeleteConfirm}
           
      />
      <OverLay overlayMsg={overlayMsg} display={overlayDisplay} />
    </div>
  );
};

export default CreateStream;
