import React, { useEffect, useState } from "react";

interface IProps {
  overlayMsg: string;
  display: boolean;
}

const OverLay = (props: IProps) => {
  const [overlayStyle, setOverlayStyle] = useState({ style: { display: "none" } });

  useEffect(() => {
    if (props.display) {
      setOverlayStyle({ style: { display: "block" } });
    } else {
      setOverlayStyle({ style: { display: "none" } });
    }
  }, [props.display]);

  return (
    <div className="overlay" style={overlayStyle.style}>
      <div className="overlay-image">
        <img
          alt="SUCCESS"
          src={require("../../Images/icon_overlay.svg").default}
          height="80px"
          width="80px"
        />
        <br />
        <span className="overlay-text">{props.overlayMsg}</span>
      </div>
    </div>
  );
};

export default OverLay;
