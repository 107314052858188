import { useContext, useEffect, useState } from 'react';
import { Participant } from '@zoom/videosdk';
import zoomContext from '../context/zoom-context';

export default function useParticipants() {
  const zmClient = useContext(zoomContext);
  const [participants, setParticipants] = useState<Participant[]>(zmClient?.getAllUser());
  const updateParticipants = () => {
    setParticipants(zmClient?.getAllUser());
  };
  useEffect(() => {
    zmClient.on('user-added', updateParticipants);
    zmClient.on('user-removed', updateParticipants);
    zmClient.on('user-updated', updateParticipants);

    return () => {
      zmClient.off('user-added', updateParticipants);
      zmClient.off('user-removed', updateParticipants);
      zmClient.off('user-updated', updateParticipants);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zmClient]);
  return participants;
}
