import React, { useState, useEffect } from "react";

interface IProps {
  searchText: string
  searchSubmit(term: any): void;
}

const SearchBox = (props: IProps) => {
  const {searchText="", searchSubmit} = props;

  return (
    <>
    <div className="ui search">
      <div className="ui form">
        <div className="field">
          <div className="ui icon input">
            <input
              className="prompt"
              type="text"
              value={searchText}
              placeholder="Search"
              onChange={(e) => {
                searchSubmit(e.target.value);
              }}
            />
            { searchText !== "" &&
                <i className="times icon" onClick={()=>{
                    searchSubmit("");
                }}></i>
            }
          </div>
        </div>
      </div>
    </div>
    <div className="search-icon-wrapper">
      <i className="search icon"></i>
    </div>
    </>
  );
};
export default SearchBox;
