import { Button, notification } from 'antd';
import { Modal } from 'semantic-ui-react';
import { ErrorComponentState, EditDataComponentState, FieldValidComponentState } from './structs';
import { AuthContext } from '../../Context/authContext';
import backend from '../../Service/Backend';
import api from '../../Service/Api';
import { useContext } from 'react';
import { get } from '../../Utils/helpers';

interface AddorEditModalProps {
  modalOpen: boolean;
  refreshList: boolean;
  cleanupFunction: () => void;
  formValueState: EditDataComponentState;
  fieldValidationState: FieldValidComponentState;
  errorState: ErrorComponentState;
  setErrorState: (value: React.SetStateAction<ErrorComponentState>) => void;
  validateDeviceName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateMacId: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderShowName: () => JSX.Element;
  renderCheckBox: () => JSX.Element;
  setFormValueState: (value: React.SetStateAction<EditDataComponentState>) => void;
  setRefreshList: (value: boolean) => void;
  setLoading: (value: boolean) => void;
}

const AddorEditModal = (props: AddorEditModalProps) => {
  const {
    modalOpen,
    cleanupFunction,
    formValueState,
    errorState,
    setErrorState,
    setFormValueState,
    validateDeviceName,
    validateMacId,
    renderShowName,
    renderCheckBox,
    fieldValidationState,
    setRefreshList,
    refreshList,
    setLoading,
  } = props;

  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  const addDevice = async () => {
    try {
      setLoading(true);
      const payload = {
        name: formValueState.deviceName,
        mac_id: formValueState.macId,
        provisioned: formValueState.provision,
        show_id: formValueState.show_id,
      };

      const data = {
        api: api.encoder.add,
        payLoad: JSON.stringify(payload),
      };
      const result = await backend.save(data, get(authValue, 'accessToken', {}));
      if (result) {
        cleanupFunction();
        notification.success({
          message: 'Device added successfully',
        });
        setRefreshList(!refreshList);
      }
    } catch (err: any) {
      console.log(err);
      setErrorState((prevState) => ({
        ...prevState,
        errorMessage: 'Error while adding device',
        macIdError: '',
        deviceIdError: '',
        deviceNameError: '',
      }));
    } finally {
      setLoading(false);
    }
  };

  const resetFormValue = () => {
    setFormValueState((prevState) => ({
      ...prevState,
      deviceId: '',
      deviceName: '',
      macId: '',
      provision: false,
      show_id: '',
      stream_id: '',
    }));
    setErrorState((prevState) => ({
      ...prevState,
      errorMessage: '',
      macIdError: '',
      deviceIdError: '',
      deviceNameError: '',
    }));
  };

  const renderModal = () => {
    return (
      <>
        <div className='add-device-modal-close'>
          <button className='close-modal-btn' onClick={cleanupFunction}>
            X
          </button>
        </div>
        <Modal.Content>
          <div className='heading margin-0'>
            <p className='heading-text-center'>
              Super Admin :<span className='grey-text'>Add Device</span>
            </p>
            <hr className='top' />
          </div>
          <div className='form-content margin-left-0'>
            <form className='ui form'>
              <div className='field-and-icon'>
                <div className='field-icon-dot left-icon'></div>
                <div className='form-field  icon-input-field'>
                  <label className='form-input-label mandatory'>Device Name</label>
                  <input
                    className='form-input'
                    type='text'
                    value={formValueState.deviceName}
                    onChange={validateDeviceName}
                  />
                  <span className='error-message'>{errorState.deviceNameError}</span>
                </div>
              </div>
              <div className='field-and-icon'>
                <div className='field-icon-dot left-icon'></div>
                <div className='form-field  icon-input-field'>
                  <label className='form-input-label mandatory'>MAC Id</label>
                  <input
                    className='form-input'
                    type='text'
                    value={formValueState.macId}
                    onChange={validateMacId}
                  />
                  <span className='error-message'>{errorState.macIdError}</span>
                </div>
              </div>
              {renderShowName()}
              {renderCheckBox()}
            </form>
          </div>
          <Modal.Actions>
            <div className='add-device-btn-container'>
              <Button
                className='add-device-button'
                onClick={addDevice}
                disabled={
                  !fieldValidationState.macIdValid ||
                  !fieldValidationState.deviceNameVaild ||
                  formValueState.show_id === ''
                }
              >
                CREATE
              </Button>
              <Button className='violet-button' color='violet' onClick={resetFormValue}>
                RESET
              </Button>
            </div>
          </Modal.Actions>
        </Modal.Content>
      </>
    );
  };

  return (
    <Modal
      open={modalOpen}
      className='update-show-modal'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      basic
      size={'small'}
    >
      {renderModal()}
    </Modal>
  );
};
export default AddorEditModal;
