import React, { useEffect, useState, useContext } from "react";
import "./ManageLicense.css";
import {
  Button,
  Icon,
  Modal,
  Popup,
  Checkbox,
  CheckboxProps,
} from "semantic-ui-react";
import { ROLES , FEATURE_NAME} from "../../Utils/constants";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import { searchClient , isFeatureIncluded } from "../../Service/show";

interface IProps {
  showId: any;
  show_name: any;
  show_admin_id: any;
  show_admin: any;
  publishers: any;
  activePublist: any;
  inactivePublist: any;
  viewers: any;
  licenceApproved: any;
  assignUserUpdate: any;
  deleteUnassign: any;
  deletePubRole: any;
  showConfig: any;
  configValue: any;
  requestLicenseFlag: any;
  pageName: any;
  fetchShowViewers: any;
  setParentLoading: any;
  loading: any;
}

const PublisherLicences = (props: IProps) => {
  const {
    showId,
    show_name,
    show_admin_id,
    show_admin,
    publishers,
    activePublist,
    inactivePublist,
    viewers,
    licenceApproved,
    assignUserUpdate,
    deleteUnassign,
    deletePubRole,
    showConfig,
    configValue,
    requestLicenseFlag,
    //fetchShowViewers,
    //setParentLoading,
    loading,
  } = props;

  const currentUser = get(useContext(AuthContext), "currentUser", {} );

  const [openAssignUserModal, setOpenAssignUserModal] = useState(false);
  const [openOnlineStreamModal, setOpenOnlineStreamModal] = useState(false);
  const [openOfflineStreamModal, setOpenOfflineStreamModal] = useState(false);

  const [openLmPrice, setOpenLmPrice] = useState(false);
  const [viewerId, setViewerId] = useState("");
  const [selectedViewer, setSelectedViewer] = useState({} as any);
  const [deletePublisher, setDeletePublisher] = useState({} as any);
  const [isDeleteActiveStreamPub, setDeleteActiveStreamPub] = useState(false);
  const [matchedPublisher, setMatchedPublisher] = useState(false);

  useEffect(() => {
    deleteActiveStreamFlag();
  }, [activePublist, inactivePublist]);

  const deleteActiveStreamFlag = async () => {
    const result = await isFeatureIncluded(FEATURE_NAME.ALLOW_DELETE_ACTIVE_STREAM, props.showId);
    await searchClient.clearCache();
    if (result) {
      setDeleteActiveStreamPub(true);
    }
  };

  const removeLicenseMail = async (remove_name: any) => {
    try {
      const input = {
        show_id: showId,
        remove_name,
        show_name,
        show_admin,
        license_count: 1,
      };
      console.log("input", input);
      const sendEmail = {
        api: api.users.removeLicense,
        payLoad: JSON.stringify(input),
      };
      await backend.save(sendEmail, get(currentUser, "user.accessToken", ""));
    } catch (err) {
      console.log("err", err);
    } 
  };

  const confirmAssignUser = async (
    e: React.MouseEvent<HTMLButtonElement>,
    selectedViewer: any
  ) => {
    try {
      assignUserUpdate(true);
      const user_id = get(selectedViewer, 'user_id', '');
      e.preventDefault();
      e.stopPropagation();
      setOpenAssignUserModal(false);
      if (!!selectedViewer) {
        const input = {
          user_title: get(selectedViewer, 'user_title', ''),
          user_name_first: get(selectedViewer, 'user_name.user_name_first', ''),
          user_name_last: get(selectedViewer, 'user_name.user_name_last', ''),
          user_email: get(selectedViewer, 'user_email', ''),
          user_phone: get(selectedViewer, 'user_phone.user_phone', ''),
          country_code: get(selectedViewer, 'user_phone.country_code', ''),
          show_id: showId,
          roles: {
            ...selectedViewer.roles,
            [ROLES.SHOW_PUBLISHER]: true,
          },
          comments: get(selectedViewer, 'comments', ''),
        };
        const data = {
          api: api.users.adminuseredit,
          urlParam: user_id,
          payLoad: JSON.stringify(input),
        };

        await backend.save(data, get(currentUser, 'user.accessToken', ''));

        if (get(deletePublisher, 'user_name.user_name_first', null)) {
          await removePubRole(e, deletePublisher);
          const input = {
            old_pubName: `${get(deletePublisher, 'user_name.user_name_first', '')} ${get(
              deletePublisher,
              'user_name.user_name_last',
              '',
            )}`,
            new_pubName: `${get(selectedViewer, 'user_name.user_name_first', '')} ${get(
              selectedViewer,
              'user_name.user_name_last',
              '',
            )}`,
            show_name,
            show_id: showId,
            show_admin,
          };
          const sendEmail = {
            api: api.users.reassignLicense,
            payLoad: JSON.stringify(input),
          };
          const result = await backend.save(sendEmail, get(currentUser, 'user.accessToken', ''));
          console.log('result', result);
          setDeletePublisher({});

          const historyPayload = {
            show_name,
            show_id: showId,
            old_user_id: get(deletePublisher, 'user_id', ''),
            user_id: show_admin_id,
            action: 'REASSIGN_PUBLISHER_LICENSE',
            action_userid: user_id,
            action_time: new Date(),
          };
          const historyData = {
            api: api.licenseHistory.reAssignPublisherLicense,
            // urlParam: user_id,
            payLoad: JSON.stringify(historyPayload),
          };
          await backend.save(historyData, get(currentUser, 'user.accessToken', ''));
          assignUserUpdate(false);
        } else {
          const historyPayload = {
            show_name,
            show_id: showId,
            user_id: show_admin_id,
            action: 'ADD_PUBLISHER_LICENSE',
            action_userid: user_id,
            action_time: new Date(),
          };
          const historyData = {
            api: api.licenseHistory.addPublisherLicense,
            // urlParam: user_id,
            payLoad: JSON.stringify(historyPayload),
          };
          await backend.save(historyData, get(currentUser, 'user.accessToken', ''));
          const lmInput = {
            pubName: `${get(selectedViewer, 'user_name.user_name_first', '')} ${get(
              selectedViewer,
              'user_name.user_name_last',
              '',
            )}`,
            show_name,
            show_id: showId,
            show_admin,
          };
          const sendEmail = {
            api: api.users.assignLicense,
            payLoad: JSON.stringify(lmInput),
          };
          const result = await backend.save(sendEmail, get(currentUser, 'user.accessToken', ''));
          console.log('result', result);
          assignUserUpdate(false);
        }
      }
      setViewerId('');
      setSelectedViewer({});
    } catch (err: any) {
      console.log('err', err);
    } 
  };

  const cancelAssignUser = (e: any, Viewer: any) => {
    setViewerId("");
    if (Viewer) {
      setSelectedViewer({});
    }
    setOpenAssignUserModal(false);
  };
  
  const handleOnlineStream = () => {
    setOpenOnlineStreamModal(true);
  };
  
  const handleOfflineStream = (publisher: any) => {
    const matchedPublisher = activePublist.find((pub: any) => pub.user_email === publisher.user_email);
    if (matchedPublisher) {
      setMatchedPublisher(true);
    } else {
      setMatchedPublisher(false);
    }
    setOpenOfflineStreamModal(true);
    if (publisher === "UnassignedLicense") {
      setDeletePublisher("UnassignedLicense");
    } else {
      setDeletePublisher(publisher);
    }
  };
  
  const addUserAsPublisher = async (
    viewer: any,
  ) => {
    setSelectedViewer(viewer);
  };

  const reducePubCount = async () => {
    try {
      const newLicenceApproved = showConfig.config_value - 1;
      const configActualValue = publishers.length;
      const configInput = {
        show_id: showId,
        show_config_id: showConfig.id,
        config_value: newLicenceApproved,
        config_actual_value: configActualValue - 1,
      };
      console.log("configInput::", configInput);
      const config = {
        api: api.configs.updateShowConfigs,
        payLoad: JSON.stringify(configInput),
      };
      console.log("config::", config);
      await backend.save(config, get(currentUser, "user.accessToken", ""));
    } catch (err: any) {
      console.log("err", err);
    }
  };
  
  const removePubHistory = async (deletePublisher: any) => {
    try {
      const historyPayload = {
        show_name,
        show_id: showId,
        user_id: show_admin_id,
        action: "REMOVE_PUBLISHER_LICENSE",
        action_userid: deletePublisher.user_id,
        action_time: new Date(),
      };
      const historyData = {
        api: api.licenseHistory.removePublisherLicense,
        // urlParam: deletePublisher.user_id,
        payLoad: JSON.stringify(historyPayload),
      };
      await backend.save(historyData, get(currentUser, "user.accessToken", ""));
    } catch (err: any) {
      console.log("err", err);
    } 
  };

  const removePubRole = async (
    e: React.MouseEvent<HTMLButtonElement>,
    deletePublisher: any
  ) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (deletePublisher !== "UnassignedLicense") {
        const input = {
          user_title: get(deletePublisher, "user_title", ""),
          user_name_first: get(
            deletePublisher,
            "user_name.user_name_first",
            ""
          ),
          user_name_last: get(deletePublisher, "user_name.user_name_last", ""),
          user_email: get(deletePublisher, "user_email", ""),
          user_phone: get(deletePublisher, "user_phone.user_phone", ""),
          country_code: get(deletePublisher, "user_phone.country_code", ""),
          show_id: showId,
          roles: {
            [ROLES.SHOW_VIEWER]: true,
            [ROLES.SHOW_PUBLISHER]: false,
          },
        };
        console.log("input for update : ", input);
        const data = {
          api: api.users.adminuseredit,
          urlParam: deletePublisher.user_id,
          payLoad: JSON.stringify(input),
        };
        await backend.save(data, get(currentUser, "user.accessToken", ""));
      }
    } catch (err: any) {
      console.log("err", err);
    }
  };

  const deleteAssignedLicense = async (
    e: React.MouseEvent<HTMLButtonElement>,
    deletePublisher: any
  ) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setOpenOfflineStreamModal(false);
      if (deletePublisher !== "UnassignedLicense") {
        const user_name = `${get(
          deletePublisher,
          "user_name.user_name_first",
          ""
        )} ${get(deletePublisher, "user_name.user_name_last", "")}`;

        deletePubRole(true);
        await removePubRole(e, deletePublisher);
        await removePubHistory(deletePublisher);
        await removeLicenseMail(user_name);
        await reducePubCount();
        deletePubRole(false);
      } else {
        deleteUnassign(true);
        await removeLicenseMail("an Unassigned");
        await reducePubCount();
        const historyPayload = {
          show_name,
          show_id: showId,
          user_id: show_admin_id,
          action: "REMOVE_LICENSE",
          action_time: new Date(),
        };
        const historyData = {
          api: api.licenseHistory.removeLicense,
          payLoad: JSON.stringify(historyPayload),
        };
        await backend.save(historyData, get(currentUser, "user.accessToken", ""));
        deleteUnassign(false);
      }
      setDeletePublisher({});
    } catch (err: any) {
      console.log("err", err);
    }
  };
  
  const reAssignLicense = async (
    e: React.MouseEvent<HTMLButtonElement>,
    publisher: any
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setDeletePublisher(publisher);
      setOpenAssignUserModal(true);
    } catch (err: any) {
      console.log("err", err);
    } 
  };
  
  const requestLicense = () => {
    requestLicenseFlag(true);
  };
  
  const renderUnassinedUser = () => {
    //const rowLimit = 3;
    return Array.from({ length: licenceApproved }, (_, k) => (
      <div className="mlv-card" key={k}>
        <div className="mlv-card-left">
          <div>Unassigned Licences</div>
          <div
            className="purple_link"
            onClick={() => setOpenAssignUserModal(true)}
          >
            Click to assign a user
          </div>
        </div>

        <Popup
          content="Cancel Subscription"
          trigger={
            <Icon
              className="close close-icon"
              onClick={() => handleOfflineStream("UnassignedLicense")}
            />
          }
          position="top right"
        ></Popup>
      </div>
    ));
  };

  const renderReAssignUser = (publisher: any) => {
    return (
      <div
        className="purple_link"
        onClick={(e: any) => reAssignLicense(e, publisher)}
      >
        Click to reassign new user
      </div>
    );
  };

  const renderZeroPubSreen = () => {
    return (
      <div className="no-license-content">
        <div className="no-license-text">
          There aren’t any publisher licences right now
        </div>
        <div className="purple_link" onClick={requestLicense}>
          Try requesting one
        </div>
      </div>
    );
  };

  const renderActivePub = () => {
    //console.log("activePublisher:::",activePublist)
    return activePublist.map((publisher: any) => {
      const username = `${get(publisher, "user_name.user_name_first", "")} ${get(publisher, "user_name.user_name_last", "")}`;
      return (
        <div className="mlv-card" key={publisher.user_id}>
          <div className="mlv-card-left">
            <div>
              <Popup
                content={publisher.user_email}
                trigger={<span>{username}</span>}
              ></Popup>
            </div>
            <div className="purple_text"> Active Stream</div>
          </div>
          {
            isDeleteActiveStreamPub ? (
              <Popup
                content="Cancel Subscription"
                trigger={
                  <Icon
                    className="close close-icon"
                    onClick={() => handleOfflineStream(publisher)}
                  />
                }
                position="top right"
              ></Popup>
            ) : (
              <span>
                <Icon className="close close-icon close-icon-inactive" />
                <Icon
                  className="question circle outline qauestion-active"
                  onClick={() => handleOnlineStream()}
                />
              </span>
            )
          }
        </div>
      );
    });
  };

  const renderInactivePub = () => {
    //console.log("InactivePublisher:::",inactivePublist)
    return inactivePublist.map((publisher: any) => {
      const username = `${get(
        publisher,
        "user_name.user_name_first",
        ""
      )} ${get(publisher, "user_name.user_name_last", "")}`;
      return (
        <div className="mlv-card" key={publisher.user_id}>
          <div className="mlv-card-left">
            <div>
              <Popup
                content={publisher.user_email}
                trigger={<span>{username}</span>}
              ></Popup>
            </div>
            <div>{renderReAssignUser(publisher)}</div>
          </div>
          <Popup
            content="Cancel Subscription"
            trigger={
              <Icon
                className="close close-icon"
                onClick={() => handleOfflineStream(publisher)}
              />
            }
            position="top right"
          ></Popup>
        </div>
      );
    });
  };

  const renderPubScreen = () => {
    return (
      <div className="ml-license-lists">
        {renderUnassinedUser()}
        {renderInactivePub()}
        {renderActivePub()}
      </div>
    );
  };

  const renderviewers = () => {
    const delet_pub = deletePublisher ? deletePublisher : "";
    return viewers.map((viewer: any) => {
      return (
        <div
          className="mlv-viewer"
          style={{ margin: "0" }}
          key={viewer.user_id}
        >
          <div className="mlv-card-viewer">
            <Popup
              content={viewer.user_email}
              trigger={
                <span>
                  {`${get(viewer, "user_name.user_name_first", "")} ${get(
                    viewer,
                    "user_name.user_name_last",
                    ""
                  )}`}
                </span>
              }
            ></Popup>
          </div>
          <span className="add-checkbox-header">
            <label className="checkbox-container">
              <Checkbox
                label=""
                value={viewer.user_id}
                checked={viewerId === viewer.user_id}
                onChange={(e, data: CheckboxProps) => {
                  setViewerId(viewer.user_id);
                  addUserAsPublisher(viewer);
                }}
              />
            </label>
          </span>
        </div>
      );
    });
  };

  const goInviteUser = () => {
    setOpenAssignUserModal(false);
    //pageName("CreatePublisherViewer")
  };

  const gotToInviteUser = () => {
    return (
      <div>
        <span>
          As there are no viewer in the list, please add them from the Invite
          User screen
        </span>
      </div>
    );
  };

  const renderViewerButtons = () => {
    return (
      <div>
        <Button
          className="cancel-button"
          disabled={loading}
          onClick={(e) => cancelAssignUser(e, selectedViewer)}
        >
          Cancel
        </Button>
        <Button
          className="confirm-button"
          loading={loading}
          onClick={(e) => confirmAssignUser(e, selectedViewer)}
        >
          Confirm
        </Button>
      </div>
    );
  };

  const renderNoViewerButtons = () => {
    return (
      <Button className="confirm-button" onClick={goInviteUser}>
        Ok
      </Button>
    );
  };

  return (
    <div className="mlv-pub-license-main">
      <div className="typography-h4">
        PUBLISHER LICENSES
        <span className="bold-text">
          ( Total ({configValue ? configValue : 0}) : Assigned (
          {publishers?.length}), Unassigned (
          {licenceApproved ? licenceApproved : 0}) )
        </span>{" "}
      </div>

      <div className="mlv-pub-license-content">
        {configValue > 0 ? renderPubScreen() : renderZeroPubSreen()}

        <div className="ml-footer">
          <div className="ml-footer-content">
            Note: Each PacPost.live license is $699.00/month and is not
            prorated.
            {openLmPrice && (
              <span>
                <br />
                For example if a license starts on 01/19/22, it would be
                invoiced from 01/19/22 - 02/18/22 and the following month it
                would be billed from 02/19/22 - 03/18/22.
              </span>
            )}
          </div>
          <div className="ml-footer-icon">
            <Icon
              className={
                openLmPrice
                  ? "close close-icon pl-10"
                  : "question circle outline question-icon pl-10"
              }
              onClick={() =>
                openLmPrice ? setOpenLmPrice(false) : setOpenLmPrice(true)
              }
            />
          </div>
        </div>
      </div>

      {openAssignUserModal && (
        <Modal
          open={openAssignUserModal}
          size={"tiny"}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="licence-modal"
          closeIcon
          onClose={(e: any) => cancelAssignUser(e, selectedViewer)}
        >
          <Modal.Header className="licence-header">
            ASSIGN A USER TO LICENSE
          </Modal.Header>
          <Modal.Content className="licence-content">
            <div className="mlv-viewer-license-content">
              {viewers.length > 0 ? renderviewers() : gotToInviteUser()}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div></div>
            {viewers.length > 0
              ? renderViewerButtons()
              : renderNoViewerButtons()}
          </Modal.Actions>
        </Modal>
      )}
      {openOnlineStreamModal && (
        <Modal
          open={openOnlineStreamModal}
          size={"tiny"}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="licence-modal"
          closeIcon
          onClose={() => setOpenOnlineStreamModal(false)}
        >
          <Modal.Header className="licence-header">MESSAGE</Modal.Header>
          <Modal.Content className="licence-content">
            <div className="mlv-viewer-license-content license-msg-content">
              Can’t remove this publisher license because the user has an active
              stream. Please have publisher delete the stream before
              re-assigning license.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="confirm-button"
              onClick={() => setOpenOnlineStreamModal(false)}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {openOfflineStreamModal && (
        <Modal
          open={openOfflineStreamModal}
          size={"tiny"}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="licence-modal"
          closeIcon
          onClose={() => {
            setOpenOfflineStreamModal(false);
          }}
        >
          <Modal.Header className="licence-header">CONFIRMATION</Modal.Header>
          <Modal.Content className="licence-content">
            <div className="mlv-viewer-license-content license-msg-content">
              {isDeleteActiveStreamPub && matchedPublisher ? (
                <>
                  <p>Are you sure you want to delete an active stream and cancel the subscription for this publisher license ?</p>
                  <p className="no-license-text">
                    Once the publisher license is eliminated, this cannot be reversed. To purchase a license, please get in touch with sales.
                  </p>
                </>
              ) : (
                <>
                  Are you sure you want to cancel this publisher license
                  subscription ?{/* {deletePublisher === "UnassignedLicense" ? ( */}
                  <p className="no-license-text">
                    Once the publisher license is eliminated, this cannot be
                    reversed. To purchase a license please get in touch with sales.
                  </p>
                </>
              )}
              {/* ) : (
                <p className="no-license-text">
                  Once the publisher license is eliminated, this license can be
                  assigned to another user.
                </p>
              )} */}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="cancel-button"
              disabled={loading}
              onClick={() => {
                setOpenOfflineStreamModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="confirm-button"
              loading={loading}
              onClick={(e: any) => deleteAssignedLicense(e, deletePublisher)}
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};
export default PublisherLicences;
