import * as React from "react";
import * as Sentry from "@sentry/react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { LayoutProps } from "./structs";
//import "./layout.css";
import "../../App.css";
import "../../Assets/css/font-face.css";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

export const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const FallbackComponent = () => {
    return <div className="not-found-text">Sorry there was a problem !</div>;
  };
  const myFallback = <FallbackComponent />;
  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <div className="container layout-wrapper">
        <Header
          page={props.page}
          profile_image_url={props.profile_image_url}

          superAdminCreateShowClick={props.superAdminCreateShowClick}
          superAdminInviteUserClick={props.superAdminInviteUserClick}
          superAdminListShowsClick={props.superAdminListShowsClick}
          superAdminListStreamsClick={props.superAdminListStreamsClick}
          superAdminListUsersClick={props.superAdminListUsersClick}
          superAdminListUsersNewClick={props.superAdminListUsersNewClick}
          superAdminPPLDeviceClick={props.superAdminPPLDeviceClick}
          superAdminVcListsClick={props.superAdminVcListsClick}

          teamAdmin={props.teamAdmin}
          showAdminUserEditView={props.showAdminUserEditView}
          showAdminListClick={props.showAdminListClick}
          showAdminCreateUserClick={props.showAdminCreateUserClick}
          showAdminStreamsListClick={props.showAdminStreamsListClick}
          showInvitedViewerListClick = {props.showInvitedViewerListClick}
          showManageLicenseViewerClick={props.showManageLicenseViewerClick}

          showAdminCreateChildShowClick = {props.showAdminCreateChildShowClick}
          showAdminChildShowAdminClick = {props.showAdminChildShowAdminClick}
          showAdminChildShowClick = {props.showAdminChildShowClick}
          showAdminChildStreamClick = {props.showAdminChildStreamClick}
          showAdminChildUserClick = {props.showAdminChildUserClick}
          showAdminSubscribeClick = {props.showAdminSubscribeClick}
          
          streamId={props.streamId}
          currentPage= {props.currentPage}
          showId={props.showId}
          stripeFlag={props.stripeFlag}
          licenseMangerFlag={props.licenseMangerFlag}
          usersGroupsFlag={props.usersGroupsFlag}
          activeClassUser={props.activeClassUser}
          activeClassStream={props.activeClassStream}
          activeClassListOfStream={props.activeClassListOfStream}
          addMenuToRef= {props.addMenuToRef}
          showPublisherCreateStreamClick={props.showPublisherCreateStreamClick}
          showPublisherMangeUsersClick={props.showPublisherMangeUsersClick}
          showPublisherListStreamsClick={props.showPublisherListStreamsClick}
          stream_label={props.stream_label}

          showFinanceListShowsClick={props.showFinanceListShowsClick}
          showFinanceListStreamsClick={props.showFinanceListStreamsClick}
          showFinanceListUsersClick={props.showFinanceListUsersClick}
          showFinanceInviteUserClick={props.showFinanceInviteUserClick}
        />
        <div className="layout-body-wrapper">
          {props.children}
        </div>
        {!((props.page === "SHOW_ADMIN_PAGE" || props.page === "SHOW_PUBLISHER_PAGE" || props.page === "TEAM_ADMIN_PAGE") && props.usersGroupsFlag) && <Footer />}
      </div>
    </Sentry.ErrorBoundary>
  );
};
