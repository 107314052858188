import { analytics } from './firebase';

// Sign In Success
export const signInSuccess = () => {
  analytics.logEvent(analytics.analyticsData, 'login_success');
};

// Sign In Failure
export const signInFailure = () => {
  analytics.logEvent(analytics.analyticsData, 'login_failure');
};

// Sign In password retries
export const signInPasswordRetries = () => {
  analytics.logEvent(analytics.analyticsData, 'login_failure_password_retries');
};

// Sign In many wrong attempts locked out
export const signInWrongAttempts = () => {
  analytics.logEvent(analytics.analyticsData, 'login_failure_many_wrong_attempts_locked_out');
};

// Sign In no Active Show
export const signInNoActiveShow = () => {
  analytics.logEvent(analytics.analyticsData, 'login_failure_no_active_show');
};

// Sign OTP Success
export const OTPSuccess = () => {
  analytics.logEvent(analytics.analyticsData, 'OTP_success');
};

// Sign OTP Success
export const OTPFailure = () => {
  analytics.logEvent(analytics.analyticsData, 'OTP_failure');
};

// Security Click
export const securityClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_security_click');
};

// Roles Click
export const rolesClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_roles_click');
};

// Speed Click
export const speedClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_speed_click');
};

// Tools Click
export const toolsClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_tools_click');
};

// Collaborate Click
export const colloborateClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_colloborate_click');
};

// Contact Click
export const contactClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_contact_click');
};

// AvidRentals Click
export const avidRentalsClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_avidrentals_click');
};

// Login Click
export const loginClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_login_click');
};

// Book a Demo Click
export const bookDemoClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_bookdemo_click');
};

// Video Click
export const videoClick = () => {
  analytics.logEvent(analytics.analyticsData, 'static_video_click');
};

//ShowViewer Visit
export const showViewerVisit = (userID: string, showID: string) => {
  analytics.logEvent(analytics.analyticsData, 'show_viewer_visit', {
    user_id: userID,
    show_id: showID,
  });
};
