import { create } from 'zustand';

interface RightPanelState {
  rightActivePanel: string;
}

interface RightPanelActions {
  openChatPanel: () => void;
  openGuestPanel: () => void;
  openPublisherPanel: () => void;
  openEncoderPanel: () => void;
  closeRightPanel: () => void;
  openWaterMarkPanel:() => void;
}

interface ViewerState {
  activeStreams: [];
  showDomSpeaker: boolean;
}

interface StreamState {
  showStreamModal: boolean;
  setShowStreamModal: (value: boolean) => void;
  streamKey: number;
  setStreamKey: () => void;
}

interface VCState {
  volumeMute: boolean;
  setVolumeMute: (value: boolean) => void;
}

//   interface ICEServerState {
//     iceServers: any[],
//     isIceServersLoading: boolean;
//     fetchIceServers: () => void;
//   }
//   interface ViewerLoadingState {
//     streamLoading: boolean,
//     setStreamLoading: (value: boolean) => void
//   }

//   interface redIcon {
//     redIcon:boolean,
//     setRedIcon: (value: boolean) => void
//   }

const resizeCanvas = () => {
  setTimeout(() => window.dispatchEvent(new Event('resize')), 25);
};

export const useViewerSlice = create<
  RightPanelState & RightPanelActions & ViewerState & VCState
>((set) => ({
  activeStreams: [],
  rightActivePanel: '', // Initially no panel is open
  openChatPanel: () => {
    set({ rightActivePanel: 'chat' });
    resizeCanvas();
  },
  openGuestPanel: () => {
    set({ rightActivePanel: 'guest' });
    resizeCanvas();
  },
  openWaterMarkPanel: () => {
    set({ rightActivePanel: 'watermark' });
    resizeCanvas();
  },
  openPublisherPanel: () => {
    set({ rightActivePanel: 'publisherpanel'});
    resizeCanvas();
  },
  closeRightPanel: () => {
    set({ rightActivePanel: '' });
    resizeCanvas();
  },
  openEncoderPanel: () => {
    set({ rightActivePanel: 'encoder' });
    resizeCanvas();
  },
  
  showDomSpeaker: false,
  volumeMute: false,
  setVolumeMute: (value: boolean) => set({ volumeMute: value }),
}));
