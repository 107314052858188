import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import { billingInfoJson, stateList } from "./SubscribeJson";
import { getCountryCode } from "./DataHandler";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
interface IProps {
  productList: any;
  priceList: any;
  selectedProduct: any;
  setSelectedProduct: any;
  customerDetails: any;
  setCustomerDetails: any;
  setCustomPlanLabel: any;
  firstNameValid: any;
  setFirstNameValid: any;
  lastNameValid: any;
  setLastNameValid: any;
  phoneValid: any;
  setPhoneValid: any;
  emailValid: any;
  setEmailValid: any;
  errorMessage: any;
  setErrorMessage: any;
}

const BillingInfo = (props: IProps) => {
  const {
    customerDetails,
    setCustomerDetails,
    productList,
    priceList,
    selectedProduct,
    setSelectedProduct,
    setCustomPlanLabel,
    setFirstNameValid,
    setLastNameValid,
    setPhoneValid,
    setEmailValid,
    errorMessage,
    setErrorMessage,
  } = props;

  const [countryCodeList, setCountryCodeList] = useState([]);
  const currentUser: any = useContext(AuthContext);
  useEffect(() => {
    const getCountryCodeList = async () => {
      const countryCodeList = await getCountryCode(get(currentUser, "user.accessToken", ""));
      setCountryCodeList(countryCodeList);
    };
    if (countryCodeList.length === 0) {
      getCountryCodeList();
    }
  });
  const validateCountryCode = (event: any, data: any) => {
    if (!data.value) {
      setErrorMessage({
        ...errorMessage,
        countryCode: "Please select country code",
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        countryCode: "",
      });
      validatePhoneText(data);
    }
  };

  const validatePhone = (value: any) => {
    //setPhone(event.target.value);
    const { countryCode } = customerDetails;
    if (countryCode) {
      const currCountry: any = countryCodeList.find(
        (cc: any) => cc.value === countryCode
      );
      const regexString = "^\\d{" + currCountry.count + "}$";
      const regex = new RegExp(regexString);

      if (
        countryCode === currCountry.value &&
        !currCountry?.ignore_count &&
        !regex.test(value)
      ) {
        setPhoneValid(false);
        setErrorMessage({
          ...errorMessage,
          phone: `Please enter ${currCountry.count} digit phone no`,
        });
      } else {
        setPhoneValid(true);
        setErrorMessage({
          ...errorMessage,
          phone: "",
        });
      }
    } else if (!countryCode && !/^\d{10}$/.test(value)) {
      setPhoneValid(false);
      setErrorMessage({
        ...errorMessage,
        phone: "Please enter 10 digit phone no",
      });
    } else {
      setPhoneValid(true);
      setErrorMessage({
        ...errorMessage,
        phone: "",
      });
    }
  };

  const validatePhoneText = (data: any) => {
    const currCountry: any = countryCodeList.find(
      (cc: any) => cc.value === data.value
    );
    const { phone } = customerDetails;
    if (data && currCountry) {
      if (
        phone &&
        !currCountry?.ignore_count &&
        phone.length !== currCountry.count
      ) {
        setPhoneValid(false);
        setErrorMessage({
          ...errorMessage,
          phone: `Please enter ${currCountry.count} digit phone no`,
        });
      } else {
        setPhoneValid(true);
        setErrorMessage({
          ...errorMessage,
          phone: "",
        });
      }
    }
  };

  const planList = priceList.map((value: any) => ({
    key: value.id,
    value: value.id,
    text: `${
      productList.filter((pro: any) => (pro.id = value.product))[0].name
    } - USD$${
      value.unit_amount / 100
    } / ${value.recurring.interval.toUpperCase()}`,
  }));

  const handlePlanOnChange = (event: any, data: any) => {
    setSelectedProduct(data.value);
    setCustomPlanLabel(
      data.options.filter((val: any) => val.key === data.value)[0].text
    );
  };

  const handleCountryCodeOnChange = (e: any, data: any) => {
    const currCountry: any = countryCodeList.find(
      (cc: any) => cc.value === data.value
    );
    validateCountryCode(e, data);
    setCustomerDetails({
      ...customerDetails,
      countryCode: data.value,
    });
  };

  const handleStateOnChange = (event: any, data: any) => {
    //setSelectedState(data.value);
    setCustomerDetails({
      ...customerDetails,
      state: data.value,
    });
  };

  const dropDownObj: any = {
    planList,
    handlePlanOnChange,
    selectedProduct,
    countryCodeList,
    handleCountryCodeOnChange,
    countryCode: customerDetails.countryCode,
    stateList,
    state: customerDetails.state,
    handleStateOnChange,
  };

  const billingHandleOnChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      const validName = /^[a-z]+$/i.test(value);
      setFirstNameValid(validName);
      setErrorMessage({
        ...errorMessage,
        firstName: !validName ? "Please enter the valid first name format" : "",
      });
    }
    if (name === "lastName") {
      const validName = /^[a-z]+$/i.test(value);
      setLastNameValid(validName);
      setErrorMessage({
        ...errorMessage,
        lastName: !validName ? "Please enter the valid last name format" : "",
      });
    }
    if (name === "phone") {
      validatePhone(value);
    }
    if (name === "email") {
      const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
      setErrorMessage({
        ...errorMessage,
        email: !validEmail ? "Please enter email format" : "",
      });
    }
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  const getTypeofComp = (input: any) => {
    const { type, name, value, options, onChange } = input;
    switch (type) {
      case "text":
        return (
          <input
            className="form-input"
            value={customerDetails[name]}
            name={name}
            type={type}
            onChange={billingHandleOnChange}
          />
        );
      case "dropDown":
        return (
          <Dropdown
            placeholder="Select"
            className="form-input"
            fluid
            search
            selection
            options={dropDownObj[options] || []}
            value={dropDownObj[value] || ""}
            onChange={dropDownObj[onChange]}
          />
        );
      default:
        return (
          <label>
            <b>{` ${customerDetails[name]}`}</b>
          </label>
        );
    }
  };

  const getErrorMessage = (name: any) => (
    <span className="error-message"> {errorMessage[name]} </span>
  );

  const getBillingInfoComp = () => {
    const billingComp = billingInfoJson.map((value: any) => {
      const { input, label, icon, className } = value;
      const { name = "" } = input;
      return (
        <div className={`billing-field ${className || ""}`} key={label}>
          {!!icon && <div className={`${icon} left-icon`}></div>}
          <div className="form-field icon-input-field">
            <label className="form-input-label">{label}</label>
            {getTypeofComp(input)}
            {getErrorMessage(name)}
          </div>
        </div>
      );
    });
    return billingComp;
  };

  return (
    <div className="billing-info-wrapper">
      <form className="ui form">
        <div className="stripe-billing-info">{getBillingInfoComp()}</div>
      </form>
    </div>
  );
};

export default BillingInfo;
