import { useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import WatermarkViewerPanel from "./watermarkViewerPanel";
import WatermarkUIPanel from "./watermarkUIPanel";
import "./Watermark.css";

const Watermark = (props: any) => {
  const [enableAllGuests, setEnableAllGuests] = useState(false);
  const {
    setWatermarkDetails,
    dataTrackRef,
    updateWatermarkHandler,
    watermarkDetails,
    setProgramaticChange,
    showId,
    preview,
    setPreview,
    watermarkChecks,
    setWatermarkChecks,
    isGuestInviteIncluded
  } = props;
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Watermark`,
      children: (
        <div className="publisher-panel-content watermark-panel">
          <WatermarkUIPanel
            setWatermarkDetails={setWatermarkDetails}
            dataTrackRef={dataTrackRef}
            preview={preview}
            updateWatermarkHandler={updateWatermarkHandler}
            watermarkDetails={watermarkDetails}
            setProgramaticChange={setProgramaticChange}
            setPreview={setPreview}
            enableAllGuests={enableAllGuests}
            setEnableAllGuests={setEnableAllGuests}
            watermarkChecks={watermarkChecks}
            setWatermarkChecks={setWatermarkChecks}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: `Viewers List`,
      children: (
        <WatermarkViewerPanel
          showId={showId}
          dataTrackRef={dataTrackRef}
          watermarkDetails={watermarkDetails}
          setWatermarkDetails={setWatermarkDetails}
          updateWatermarkHandler={updateWatermarkHandler}
          enableAllGuests={enableAllGuests}
          setEnableAllGuests={setEnableAllGuests}
          isGuestInviteIncluded={isGuestInviteIncluded}
        />
      ),
    },
  ];

  return (
    <div className="publisher-panel-wrapper">
      <nav className="main-menu-right-chat" id="publisher-panel-tray">
        <Tabs
          className="watermarkTab"
          defaultActiveKey="1"
          items={items}
          centered
        />
      </nav>
    </div>
  );
};

export default Watermark;
