import {Divider, Button} from 'antd';
import './styles.css';
const RegisterMasv = () => {
  // TO DO: Find if api level login is possible
    const openMasv =()=>{
    window.open('https://app.massive.io/en/signup')
  }
  return (
  <div className='masv-create-keys'>
    <div className='section' >
      <Divider className='section-header'>
        <h3>Sign up with Massive</h3>
      </Divider>
      <div  className='right-sign-up'>
        <Button type='primary' onClick={openMasv} className='right-sign-up-btn'>
          <span>SIGN UP </span>
        </Button>
      </div>
    </div>
    </div>
  );
};
export default RegisterMasv;
