import { useEffect, useState } from 'react';
import { Participant } from '@zoom/videosdk';
import { get } from '../../../Utils/helpers';
import useGetGuestUsers from '../GuestInvite/useGetGuestUsers';
import { notification } from 'antd';
import { isGuestAuthenticated } from '../../../Utils/guestHelpers';

const notifiedGuests: any = [];
export function useRedIcon() {
  const [redIcon, setRedIcon] = useState(false);
  const guests = useGetGuestUsers();
  const isGuestUser = isGuestAuthenticated();
  useEffect(() => {
    const checkWaitingGuest = () => {
      setTimeout(() => {
        const guestSessions = sessionStorage.getItem('allowedGuest') || '';
        const guestSessionsObject = !!guestSessions ? JSON.parse(guestSessions) : {};
        const auto_allow = Boolean(sessionStorage.getItem('auto_allow') === 'true');
        const isVcHost = sessionStorage.getItem('publisher') === 'true';
        guests.forEach((guest: Participant) => {
          const isGuestWaiting =
            guestSessionsObject[get(guest, 'userIdentity', '')]?.status === 'waiting';
          if (
            !notifiedGuests.includes(guest.userIdentity) &&
            !auto_allow &&
            !isGuestUser &&
            isVcHost &&
            isGuestWaiting
          ) {
            notification.info({
              message: 'Pacpost',
              description: `${guest.displayName} is in waiting room`,
              placement: 'bottomRight',
            });
            notifiedGuests.push(guest.userIdentity);
          }
        });
        const guestIds = guests.map((g: Participant) => g.userIdentity);
        const guestRemoved = notifiedGuests.filter((guestid: any) => !guestIds.includes(guestid));
        guestRemoved.forEach((guestId: string) => {
          const index = notifiedGuests.indexOf(guestId);
          notifiedGuests.splice(index, 1);
        });

        const isUserWaiting = guests.some(
          (participant: Participant) =>
            !!guestSessionsObject[get(participant, 'userIdentity', '')] &&
            guestSessionsObject[get(participant, 'userIdentity', '')]?.status === 'waiting',
        );
        setRedIcon(isUserWaiting);
      }, 1500);
    };

    checkWaitingGuest();
  }, [guests]);

  return redIcon;
}
