import React, { useState, useEffect } from "react";
import searchIcon from "../../Assets/icons/search-icon.svg"
import closeIcon from "../../Assets/icons/close-sm.svg"

interface IProps {
  searchText: string
  searchSubmit(term: any): void;
}

const Search = (props: IProps) => {
  const {searchText="", searchSubmit} = props;

  return (
    <>
    <div className="search-icon-wrapper">
      <img src={searchIcon}/>
    </div>
    <div className="ui search">
      <div className="ui form">
        <div className="field">
          <div className="ui icon input">
            <input
              className="prompt"
              type="text"
              value={searchText}
              placeholder="Search"
              onChange={(e) => {
                searchSubmit(e.target.value);
              }}
            />
            { searchText !== "" &&
              <img src={closeIcon} style={{cursor: 'pointer'}} onClick={() => {searchSubmit('')}}/>
            }
            
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default Search;
