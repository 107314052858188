import { useState, useCallback, useEffect } from 'react';
import { ZoomClient } from '../types/index-types';

import None_thumb from "../../../Images/None_thumb.svg";
import Abstract from "../../../Images/Abstract.jpg";
import Bookshelf from "../../../Images/Bookshelf.jpg";
import CoffeeShop from "../../../Images/CoffeeShop.jpg";
import SanFrancisco from "../../../Images/SanFrancisco.jpg";
import Blur_thumb from "../../../Images/Blur_thumb.svg";
import Abstract_thumb from "../../../Images/Abstract_thumb.jpg";
import Bookshelf_thumb from "../../../Images/Bookshelf_thumb.jpg";
import CoffeeShop_thumb from "../../../Images/CoffeeShop_thumb.jpg";
import SanFrancisco_thumb from "../../../Images/SanFrancisco_thumb.jpg";

const backgrounds = [
  { value: "none", image: "none", thumbImage: None_thumb },
  { value: "blur", image: "blur", thumbImage: Blur_thumb },
  { value: "abstract", image: Abstract, thumbImage: Abstract_thumb },
  { value: "bookshelf", image: Bookshelf, thumbImage: Bookshelf_thumb },
  { value: "coffeeshop", image: CoffeeShop, thumbImage: CoffeeShop_thumb },
  { value: "sanfrancisco", image: SanFrancisco, thumbImage: SanFrancisco_thumb },
];

export function useVideo(zmClient: ZoomClient, mediaStream: any,videoPlayerListRef?:any) {
  const [isVideoOn, setIsVideoOn] = useState(false);
  const excludedElementTypes = ['input', 'textarea', 'select'];

  const onCamClick = async () => {
    const activeBGValue = sessionStorage.getItem("activeBG")
    const currentUserInfo = zmClient.getCurrentUserInfo();
    const isVideoEnabled = !!currentUserInfo.bVideoOn;
    const stream = zmClient?.getMediaStream()

    if (isVideoEnabled) {
        await stream?.stopVideo();
        if(stream?.getShareStatus() === "sharing"){
          stream.stopShareScreen()
        } 
        setIsVideoOn(false);
      } else {
        let videoOptions: any = {
          captureHeight: 160,
          captureWidth: 192
        }
        if(!!activeBGValue) {
          const bgImg = backgrounds.filter(
            (item: any) => item.value === activeBGValue
          )[0];
          if(bgImg?.image){
            videoOptions.virtualBackground = {
              imageUrl: bgImg?.image === "none" ? undefined : bgImg?.image
            }
          }  
        }
        await stream?.startVideo(videoOptions).catch((e:any)=>{
          console.error("Unable to start the video")
        });
        // console.log("::::cam |O|N")
        setIsVideoOn(true);
      }
  }

  const onCurrentVideoChange = useCallback((payload: any) => {
    const { state } = payload;
    if (state === 'Started') {
        setIsVideoOn(true)
      } else if (state === 'Stopped') {
        setIsVideoOn(false)
      } else if (state === 'Failed') {
        console.log("Video Capturing change failed")
      }
  }, []);

  useEffect(() => {
    zmClient.on('video-capturing-change', onCurrentVideoChange);
    return () => {
      zmClient.off('video-capturing-change', onCurrentVideoChange);
    };
  }, [zmClient, onCurrentVideoChange]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;
      if (excludedElementTypes.includes(target.tagName.toLowerCase())) {
        return;
      }
      if (event.key === '\\') {
        onCamClick();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCamClick]);

  return {
    isVideoOn,
    onCamClick
  };
}
