import { Participant } from '@zoom/videosdk';
import useParticipants from '../hooks/useParticipants';
import { useEffect, useState } from 'react';

// interface RoomParticipants extends Participant {
//   identity: string;
// }
export function useGetRoomParticipants() {
  const zoomParticipants = useParticipants();
  const [roomParticipants, setRoomParticipants] = useState<any>([]);
  useEffect(() => {
    if (zoomParticipants.length !== roomParticipants.length) {
      const newParticipants = zoomParticipants.map((participant: Participant) => {
        return { ...participant, identity: participant.userIdentity };
      });
      setRoomParticipants(newParticipants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomParticipants]);
  return roomParticipants;
}
