import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
interface GuestTimerProps {
  expiryTime: number;
  currentDate: number;
  setLinkExpired: (value: boolean) => void;
}
const GuestTimer = (props: GuestTimerProps) => {
  const { expiryTime, currentDate, setLinkExpired } = props;
  const [timer, setTimer] = useState(expiryTime);
  const timeRef = useRef(expiryTime);

  useEffect(() => {
    if (timeRef.current !== 0) {
      const intervalId = setInterval(() => {
        timeRef.current = timeRef.current - 1000;
        if (timeRef.current < props.currentDate) {
          setLinkExpired(true);
        }
        setTimer(timeRef.current);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  });

  const momentExpTime = moment.duration(timer - currentDate);

  return (
    <div>
      <span>
        Expires in:
        {`
         ${Math.floor(momentExpTime.asHours())}: ${
          String(momentExpTime.minutes()).length === 1
            ? `0${momentExpTime.minutes()}`
            : `${momentExpTime.minutes()}`
        } : ${
          String(momentExpTime.seconds()).length === 1
            ? `0${momentExpTime.seconds()}`
            : momentExpTime.seconds()
        }`}
      </span>
    </div>
  );
};
export default GuestTimer;
