import create from 'zustand';
import PubNub from 'pubnub';

interface ChatStore {
  showEmoji: boolean;
  setShowEmoji: (show: boolean) => void;

  fileUploadLoading: boolean; // New state
  setFileUploadLoading: (loading: boolean) => void;

  chatClearLoading: boolean;
  setChatClearLoading: (show: boolean) => void;

  exportChartConfirmationOpen: boolean;
  setExportChartConfirmationOpen: (show: boolean) => void;

  clearChatModalOpen: boolean;
  setClearChatModalOpen: (show: boolean) => void;

  uploadFiles: any[];
  setUploadFiles: (files: any[]) => void;
  
  input: string;
  setInput: (input: string) => void;

  pubnub: PubNub; 
  setPubNub: (pubnub: PubNub) => void;

  channels: any[];
  setChannels: (channels: any[]) => void;

  messages: any[];
  _setMessages: (messages: any[]) => void;

  allMessages: any[];
  setAllMessages: (allMessages: any[]) => void;

  typingInd: string;
  setTypingInd: (typingInd: string) => void;

  uploadAlert: boolean; 
  setUploadAlert: (uploadAlert: boolean) => void;

  fileName: string;
  setFileName: (fileName: string) => void;

  showChat: boolean;
  setChatWindow: (showChat: boolean) => void;

  showChatDisable: boolean;
  setChatDisable: (showChatDisable: boolean) => void;

  showCloseDisable: boolean;
  setCloseDisable: (showCloseDisable: boolean) => void;


  chatExportLoading: boolean;
  setChatExportLoading: (loading: boolean) => void;

  isModalOpen: boolean;
  setModalStatus: (isOpen: boolean) => void;

  streamLabel: string;
  setStreamLabel: (label: string) => void;

}

export const useChatStore = create<ChatStore>((set) => ({
  showEmoji: false,
  setShowEmoji: (show) => set({ showEmoji: show }),

  fileUploadLoading: false,
  setFileUploadLoading: (loading) => set({ fileUploadLoading: loading }),

  chatClearLoading: false,
  setChatClearLoading: (loading) => set({ chatClearLoading: loading }),

  exportChartConfirmationOpen: false,
  setExportChartConfirmationOpen: (loading) => set({ exportChartConfirmationOpen: loading }),

  clearChatModalOpen: false,
  setClearChatModalOpen: (loading) => set({ clearChatModalOpen: loading }),

  uploadFiles: [],
  setUploadFiles: (files) => set({ uploadFiles: files }),

  input: "",
  setInput: (input) => set({ input: input }),

  pubnub: {} as PubNub,
  setPubNub: (pubnub) => set({ pubnub: pubnub }),

  channels: [],
  setChannels: (channels) => set({ channels: channels }),

  messages: [],
  _setMessages: (messages) => set({ messages: messages }),

  allMessages: [],
  setAllMessages: (allMessages) => set({ allMessages: allMessages }),

  typingInd: "",
  setTypingInd: (typingInd) => set({ typingInd: typingInd }),

  uploadAlert: false,
  setUploadAlert: (uploadAlert) => set({ uploadAlert: uploadAlert }),

  fileName: "",
  setFileName: (fileName) => set({ fileName: fileName }),

  showChat: false,
  setChatWindow: (showChat) => set({ showChat: showChat }),

  showChatDisable: true,
  setChatDisable: (showChatDisable) => set({ showChatDisable: showChatDisable }),

  showCloseDisable: false,
  setCloseDisable: (showCloseDisable) => set({ showCloseDisable: showCloseDisable }),

  
  chatExportLoading: false,
  setChatExportLoading: (loading) => set({ chatExportLoading: loading }),

  isModalOpen: false,
  setModalStatus: (isOpen) => set({ isModalOpen: isOpen }),


  streamLabel: "",
  setStreamLabel: (label) => set({ streamLabel: label }),

}));

interface MessageStore{
  messages: any[];
  _setMessages: (messages: any[]) => void;
  streamId:string;
  setStreamId: (stream_id: string) => void;
}

export const useMessageStore = create<MessageStore>((set)=>({
  messages: [],
  _setMessages: (messages) => set({ messages: messages }),
  streamId:'',
  setStreamId:(stream_id) => set({ streamId: stream_id }),
}))
