import React from "react";
import { ActiveShowUserRole } from "./structs";
import UserShowRole from "./UserShowRole";

interface IProps {
  activeShowUserRoles: any[];
  selectShow: (e: any, showUserRole: ActiveShowUserRole) => void;
}

const UserShows = (props: IProps) => {
  const someNote = props.activeShowUserRoles.some(show => !!show.show_comments);
  const renderUserShowRole = () => {
    return props.activeShowUserRoles.filter(show => (String(show.show_status).toLowerCase() === "active" || show.show_id === -1)).map((showUserRole: ActiveShowUserRole, index) => {
      return (
        <UserShowRole showUserRole={showUserRole} key={index} index={index} 
          selectShow={props.selectShow} someNote={someNote} />
      );
    });
  };

  const rendUserShows = () => {
    return (
      <table className="ui inverted selectable table">
        <thead className="">
          <tr className="">
            <th className="custom-border">Show Name</th>
            <th className="custom-border">Show Code</th>
            <th className="custom-border">Status</th>
            <th className={!!someNote ? "custom-border" : "" }>Role</th>
            {someNote && <th className="">Note</th>}
          </tr>
        </thead>
        <tbody className="">{renderUserShowRole()}</tbody>
      </table>
    );
  };

  return <div>{rendUserShows()}</div>;
};
export default UserShows;
