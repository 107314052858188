/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Sentry from '@sentry/react';
import {
  LocalDataTrack,
  connect,
  createLocalTracks,
  createLocalVideoTrack,
  createLocalAudioTrack,
  LocalVideoTrackPublication,
  LocalVideoTrack,
  LocalAudioTrackPublication,
  LocalAudioTrack,
  NoiseCancellationOptions,
} from 'twilio-video';
import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
} from '@twilio/video-processors';

import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext
} from 'react';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { trace } from "firebase/performance";
import { performance } from "../../Firebase/firebase";
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { turnApiUrl, CHIME_VALUES, MULTI_STREAM_VIEWER } from '../../Utils/constants';
import { get, isEmpty, sleep } from '../../Utils/helpers';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import ShowStream from './ShowStream';
import ProgressLoader from '../Common/ProgressLoader';
import { auth } from '../../Firebase';
import { FEATURE_NAME } from '../../Utils/constants';
import { fetchFeatures, showActiveUsers } from '../../Service/show';
import { isGuestAuthenticated } from '../../Utils/guestHelpers';
import { logOutGuestUser } from '../../Utils/guestHelpers';
import { firstBy } from 'thenby';
import './newshowviewer.css';

//Header VC control images
import pacpost_img from '../..//Images/logo_PP_white_header.png';
import phoneFilled from '../../Images/newPhone_outline.svg';
import chatImg from '../../Images/chat.svg';
import closeImg from '../../Images/close.svg';
import fitToScreen from '../../Images/fullScreen.svg';
import transmission from '../../Images/transmission.svg';
import tc_Img from '../../Images/room-finder1.png';
import golbalAudioMute from '../../Images/muteAll.svg';
import golbalAudioUnMute from '../../Images/unmuteAll.svg';
import noiseCancellation from '../../Images/noise-cancellation.svg';
import pencil from '../../Images/pencil.svg';
import erase from '../../Images/eraser.svg';

import trash from '../../Images/trash.svg';
import microphoneOn from '../../Images/micOn.svg';
import microphoneOff from '../../Images/micOff.svg';
import cameraOn from '../../Images/cameraOn.svg';
import cameraOff from '../../Images/cameraOff.svg';
import ham from '../../Images/hamburger-menu.svg';
import changeView from '../../Images/changeView.svg';
import volume from '../../Images/newVolume.svg';
import volumeMuteIcon from '../../Images/volume_mute.svg';
import editPencil from '../../Images/pencil.png';
//left sidebar Images
import group from '../../Images/People.svg';
import guestWhite from '../../Images/guest_whitebg.svg';
import watermarkIcon from '../../Images/watermark_icon.svg';
import PublisherPanel from './PublisherPanel';
import GuestInvite from './GuestInvite';
import Watermark from './Watermark';
import configSource from '../../Images/config-source.svg';

import dndActiveIcon from '../../Images/dnd-active-icon.svg';
import dndInactiveIcon from '../../Images/dnd-inactive-icon.svg';

import showDominantSpeakerIcon from '../../Images/show-dom-speaker.svg';
import hideDominantSpeakerIcon from '../../Images/hide-dom-speaker.svg';
import { analytics } from '../../Firebase';

import Diagnostics from './Diagnostics';
import Abstract_thumb from '../../Images/Abstract_thumb.jpg';
import Bookshelf_thumb from '../../Images/Bookshelf_thumb.jpg';
import CoffeeShop_thumb from '../../Images/CoffeeShop_thumb.jpg';
import SanFrancisco_thumb from '../../Images/SanFrancisco_thumb.jpg';
import Abstract from '../../Images/Abstract.jpg';
import Bookshelf from '../../Images/Bookshelf.jpg';
import CoffeeShop from '../../Images/CoffeeShop.jpg';
import SanFrancisco from '../../Images/SanFrancisco.jpg';
import Blur_thumb from '../../Images/Blur_thumb.svg';
import None_thumb from '../../Images/None_thumb.svg';
import Sidemenu from './Sidemenu';
import VideoTiles from './VideoTiles';
import HeaderView from './HeaderView';
import ChatMenu from './ChatMenu';
import Encoder from './Encoder';
import AnnotationChat from './ChatMenu/AnnotationChat';
import AnnotationChatModal from './ChatMenu/AnnotationChatModal';
import HudStats from './HudStats';
import useNoiseCancellation from './useNoiseCancellation';
import GuestWaitingModal from "../GuestWaitingModal";
import { Col, Grid, Row, Spin, notification, Modal as AntdModal } from "antd";
import { LoadingOutlined, RightOutlined } from '@ant-design/icons';
import useGuestUsers from "./hooks/useGuestUsers";
import useLockedGuestsList from "./hooks/useLockedGuestsList";
import type { NotificationPlacement } from 'antd/es/notification/interface';
import useDbTrigger from "../../hooks/useDbTrigger";
import { AuthContext } from '../../Context/authContext';
import useWhiteBoarding from './WhiteBoarding/useWhiteBoarding';
import useLocalDataTrack from './hooks/useLocalDataTrack';
import useTwilioVC from './TwilioVC/useTwilioVC';
import useGuestSessions from './hooks/useGuestSessions';
import useChatFunctions from './ChatMenu/useChat';
import useCanvas from './hooks/useCanvas';
import ConfirmationalPopup from '../../Utils/ConfirmationalPopup';
import ImageCanvas from '../UserSettings/ImageCanvas';
import ShowStreamMultiView from './ShowStreamMultiView';
import Reload from './Reload';
import useNavigateToPage from '../../hooks/useNavigateToPage';
import { useLocationStore } from '../../store/useLocationStore';

const notifiedGuests: any = [];
const disconnectedGuests: any = [];

const noiseCancellationOptions: NoiseCancellationOptions = {
  sdkAssetsPath: `${process.env.PUBLIC_URL || window.location.origin}/${
    process.env.NOISE_CANCELLATION_PLUGIN_PATH
  }`,
  vendor: 'krisp',
};


const virtualBackgroundAssets = '/virtualbackground';
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

const backgrounds = [
  { value: 'none', image: '', thumbImage: None_thumb },
  { value: 'blur', image: '', thumbImage: Blur_thumb },
  { value: 'abstract', image: Abstract, thumbImage: Abstract_thumb },
  { value: 'bookshelf', image: Bookshelf, thumbImage: Bookshelf_thumb },
  { value: 'coffeeshop', image: CoffeeShop, thumbImage: CoffeeShop_thumb },
  { value: 'sanfrancisco', image: SanFrancisco, thumbImage: SanFrancisco_thumb },
];

//chime_audio
const chimeAudioUrl = require('../../Assets/sounds/chime.mp3');

const NewShowViewerDashboard = () => {
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const navigationType = useNavigationType();

  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  const show_id = get(locationState,'state.show_id','');
  const show_name = get(locationState,'state.show_name','');
  const show_code = get(locationState,'state.show_code','');
  const team_admin = get(locationState,'state.team_admin',false);
  const stream_id = get(locationState,'state.stream_id',false);
  const guestLoader = get(locationState,'state.guestLoader',false);
  const sessionStreamId = sessionStorage.getItem('stream_id') ?? '';
    
  const guestUid = get(locationState, 'state.guestUid', '');
  const guestDisplayName = get(locationState, 'state.guestDisplayName', '');
  const isGuestViewer = get(locationState, 'state.isGuestViewer', false);

  const [updatedStreamLabel, setUpdatedStreamLabel] = useState('');
  const [updatedStreamId, setUpdatedStreamId] = useState('');
  const [updatedStreamLabelToAll, setUpdatedStreamLabelToAll] = useState(false);
  const [streamLabelUpdateMsg, setStreamLabelUpdateMsg] = useState('');
  const [isStreamSelected, setIsStreamSelected] = useState(sessionStreamId !== '');

  const [timeCodePopup, setTimeCodePopup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [endorLeaveConferenceLoading,setEndorLeaveConferenceLoading] = useState(false);
  const [fetchStreamListLoader,setFetchStreamListLoader] = useState(false);
  const [updateStreamLabelLoader,setUpdateStreamLabelLoader] = useState(false);
  
  // const [showViewer, setShowViewer] = useState(false);
  const [streamLabel, setStreamLabel] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [showId, setShowId] = useState('');
  const [selectedStream, setStreamId] = useState('');
  const [activeStreams, setActiveStreams] = useState([] as any);

  const [stream, setStream] = useState({} as any);
  const [streamKey, setStreamKey] = useState(Math.random());
  const [subStreamKey, setSubStreamKey] = useState(Math.random());
  const [iceServers, setIceServers] = useState([] as any);

  const [activeStreamVCRoom, setActiveStreamVCRoom] = useState({} as any);
  const [isVCHost, setIsVCHost] = useState(false);

  const [open,setOpen] = useState(false);
  

  const [cameraName, setCameraName] = useState('Select Camera');  
  const [micName, setMicName] = useState('Select Microphone');
  
  const [muteAll, setMuteAll] = useState(false);

  //Update Stream Label Modal
  const [updateStreamLabelErrorModal,setUpdateStreamLabelErrorModal] = useState(false)

  //Config Stream
  const [clearChatModalOpen, setClearChatModalOpen] = useState(false);
  const [exportChartConfirmationOpen, setExportChartConfirmationOpen] = useState(false);

  //Millicast stream view
  const [millicastView, setMillicastView] = useState(null as any);

  // Chat Input Field Ref
  const chatInputFieldRef = useRef<HTMLInputElement>(null);

  //left sidebar
  const [showStreamMenu, setShowStreamMenu] = useState(false);
  const [streamMenuActive, setStreamMenuActive] = useState(true);
  const [isModalOpen, setModalStatus] = useState(false);
  const leftBarRef = useRef<HTMLDivElement>(null);

  const [streamList, setStreamList] = useState<any[]>();
  const [ipAddress, setIpAddress] = useState('');


  const [headerIconsModalOpen, setHeaderIconsModalOpen] = useState(false); 
  const [rightTopOptionsOpen, setRightTopOptionsOpen] = useState(false); 

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  interface ComponentState {
    chatWindow: boolean;
    closeDisable: boolean;
    chatDisable: boolean;
    users: boolean;
    closeUsers: boolean;
    openUsers: boolean;
    annotationChatWindow: boolean;
    annotationCloseDisable: boolean;
    annotationChatDisable: boolean;
    showPublisherPanel: boolean;
    showPublisherPanelCloseDisable: boolean;
    showGuestInvite: boolean;
    showGuestInviteCloseDisable: boolean;
    watermarkPanel: boolean;
    watermarkDetails: any;
    hudStatsDisplay: boolean;
    encoder: boolean;
  }

  const [rpstate, setRPState] = useState<ComponentState>({
    chatWindow: false,
    closeDisable: false,
    chatDisable: true,
    users: false,
    closeUsers: false,
    openUsers: true,
    annotationChatWindow: false,
    annotationCloseDisable: false,
    annotationChatDisable: true,
    showPublisherPanel: false,
    showPublisherPanelCloseDisable: false,
    showGuestInvite: false,
    showGuestInviteCloseDisable: false,
    watermarkPanel: false,
    watermarkDetails: { /* Initial watermark details */ },
    hudStatsDisplay: false,
    encoder: false,
  });
  const guestStreams:any=[]
  
  // Local Data Track
  const [dataTrack, dataTrackRef] = useLocalDataTrack()

  // chat messages
  const [annotateMessages, _setAnnotateMessages] = useState([] as any[]);
  const annotateMessagesRef = useRef(annotateMessages);
  const setAnnotateMessages = (data: any[]) => {
    annotateMessagesRef.current = data;
    _setAnnotateMessages(data);
  };

  const [messages, _setMessages] = useState([] as any[]);
  const messagesRef = useRef(messages);
  const setMessages = (data: any[]) => {
    messagesRef.current = data;
    _setMessages(data);
  };

  const [usersUpdated, setUsersUpdated] = useState(false);
  const [watermarkDetails, setWatermarkDetails] = useState({} as any);

  const [invitedViewers, _setInvitedViewers] = useState([] as any);
  const invitedViewersRef = useRef(invitedViewers);
  const setInvitedViewers = (data: any) => {
    invitedViewersRef.current = data;
    _setInvitedViewers(data);
  };

  const [user_invited, setUserInvited] = useState(true);

  const [isDND, _setMyDND] = useState(false);
  const myDNDRef = useRef(isDND);
  const setMyDND = (data: boolean) => {
    myDNDRef.current = data;
    _setMyDND(data);
  };

  // Hud Stats Display
  const [activeStreamExists, setActiveStreamExists] = useState(false);
  const [activeStreamStatus, setActiveStreamStatus] = useState(false);
  const [statsModalOpen, setStatsModalOpen] = useState(false);

  //Virtual Background
  const [localParticipantBGModalOpen, setLocalParticipantBGModalOpen] = useState(false);
  
  const [user_imageurl, setProfileImageUrl] = useState('');
  const [user_chime_preferance, setUserChimePreferance] = useState(false);

  //Feature Flags
  const [featuresList, setFeaturesList] = useState()

  // Watermark margin
  const [preview, setPreview] = useState(false);

    //Pubnub
    const [input, setInput] = useState('');

    //OverLay
    const [overlay, setOverlay] = useState({ style: { display: "none" } });
    const [overlayConnected, setOverlayConnected] = useState({ style: { display: "none" } });
    const [overlayDisconnected, setOverlayDisconnected] = useState({ style: { display: "none" } });
    const [overlayMsg, setOverlaymsg] = useState("");
    const [overlayMsgConnected, setOverlayMsgConnected] = useState("");
    const [overlayMsgDisconnected, setOverlayMsgDisconnected] = useState("");
  
    //Uploadfile
    const [uploadFiles, setUploadFiles] = useState([] as any);
    const [fileName, setFileName] = useState('');
    // const [browseInput, setbrowseInput] = useState(false);
    const [uploadAlert, setUploadAlert] = useState(false);
  
    //popup
    const [showPopup, setShowPopup] = useState(false);
  
    // Layout
    const [layoutMode, setLayoutMode] = useState('normal');
  
    //Volume show and hide
    const [showVolume, setShowVolume] = useState(false);
    const [volumeLevel, setVolumeLevel] = useState(100);
    
    const [programaticChange, setProgramaticChange] = useState(false);
    //emoji
    const [showEmoji, setShowEmoji] = useState(false);
    //full screen
    const fullScreenHandler = useFullScreenHandle();
  
    // Camera and microphone selection
    const [cameraOptions, _setCameraOptions] = useState([]);
    const [micOptions, _setMicOptions] = useState([]);
  
    const [isTimeCodeFeatureIncluded, setTimeCodeFeatureIncluded] = useState(false);
    const [isGuestInviteIncluded, setGuestInviteIncluded] = useState(false);
    const [guestInviteEnabledforStream,setGuestInviteEnabledforStream] = useState(false);
    //watermark panel
    //const [watermarkPanel, setwatermarkPanel] = useState(false);
    const [watermarkFeatureIncluded, setWatermarkFeatureIncluded] = useState(false);
    const [watermarkTrack, setWatermarkTrack] = useState(false);
  
    //publisher panel
    //const [showPublisherPanel, setShowPublisherPanel] = useState(false);
    //const [showPublisherPanelCloseDisable, setShowPublisherPanelCloseDisable] = useState(false);
  
    const [isPublisher, setIsPublisher] = useState(false);
  
    const [cameraDropdownOpen, setCameraDropdownOpen] = useState(false);
  
    const [guestUsersList, setGuestUsersList] = useState([] as any[]);
  
    const [showDiagnostics, setShowDiagnostics] = useState(false);
   
    const [gLoader,setGloader] = useState(guestLoader);
    const [redIcon,setRedIcon] = useState(false);
    const [guestStatusCheck,setGuestStatusCheck] = useState(false);
    const [chatLoaded,setChatLoaded] = useState(false)

    const [showMultiStreamViewer, setShowMultiStreamViewer] = useState(false);
    //Encoder 
    const [encoderFeatureIncluded, setEncoderFeatureIncluded] = useState(false);

  // White Boarding
  const {
    canvasRef,
    showDrawingOption,
    isPencilLine,
    isPencilCircle,
    isEraser,
    onDrawingClick,
    pencilToggled,
    circleToggled,
    eraserToggled,
    clearWhiteBoard,
    clearWhiteBoardAll,drawLine, drawCircle, resetAllTools} = useWhiteBoarding(dataTrack)
  
  // VC Dependancy 
  const showProfileImageRef: any = useRef(null)
  useEffect(() => {
    showProfileImageRef.current = showProfileImage
  }, [])

  const hideProfileImageRef: any = useRef(null)
  useEffect(() => {
    hideProfileImageRef.current = hideProfileImage
  }, [])

  const updateGuestSessionRef: any = useRef(null)
  useEffect(() => {
    updateGuestSessionRef.current = updateGuestSession
  }, [])
  
  const signOutGlRef: any = useRef(null)
  useEffect(() => {
    signOutGlRef.current = signOutGl
  }, [])

  const changeMicrophoneTrackRef: any = useRef(null)
  useEffect(() => {
    changeMicrophoneTrackRef.current = changeMicrophoneTrack
  }, [])

  const cleanupRef: any = useRef(null)
  useEffect(() => {
    cleanupRef.current = cleanUpWhenUserLeftRoom
  }, [])

  // Twilio VC 
  const { 
    troom,
    troomRef, 
    setTRoom,
    localMute,
    setlocalMute,
    localAudioMute,
    localAudioMuteRef,
    localVideoMute,
    localVideoMuteRef,
    setLocalVideoMute,
    volumeMute,
    setVolumeMute,
    lParticipantRef,
    localParticipantTrackRef,
    setLocalParticipantTrack,
    roomParticipantsConnectionStatus,
    roomParticipantsConnectionStatusRef,
    joinTwilioRoom, 
    participantConnected, 
    roomParticipantsList,
    roomParticipantsListRef,
    trackSubscribed,
    trackUnsubscribed,
    showDominantSpeaker,
    dominantSpeakerIDRef,
    dominantSpeakerTrackRef,
    showStreamUpdateRef,
    setShowDominantSpeaker,
    setCameraId,
    setMicId,
    cameraIdRef,
    setTempActiveBG,
    setActiveBG,
    activeBGRef,
    tempActiveBG } = useTwilioVC(dataTrack,dataTrackRef,setOpen, setAnnotateMessages, 
      setMessages, setUsersUpdated, setWatermarkDetails, invitedViewersRef, 
      user_chime_preferance, disconnectedGuests, showProfileImageRef.current,
      hideProfileImageRef.current, updateGuestSessionRef.current, 
      signOutGlRef.current, changeMicrophoneTrackRef.current, setLocalParticipantBGModalOpen,drawLine, drawCircle,setStream,setStreamLabel,cleanupRef.current)

  // Canvas
  const {
    canvasMT, 
    canvasML, 
    canvasWidth,
    renderCanvas, 
    videoReady,
    resizeCanvas 
  } = useCanvas()
  
  let showVcIdref = useRef("")

  const loaderIcon = <LoadingOutlined style={{ fontSize:72 }} spin />;
  
  //Guest Invite
  //const [showGuestInvite, setShowGuestInvite] = useState(false);
  //const [showGuestInviteCloseDisable, setShowGuestInviteCloseDisable] = useState(false);

  const guests = useGuestUsers(troom);
  const [feNotification,setFeNotification] = useState(Array<[]>);
  const [globalGuestAutoAllow ,setGlobalGuestAutoAllow] = useState(false);
  const guestLockedNotifications = useDbTrigger('fe_notifications',(isGuestInviteIncluded && (Object.keys(troom).length !== 0)));
  const [updated,setUpdated] = useState(false);
  const { createGuestSessions } = useGuestSessions()
  const lockedList :never[] = useLockedGuestsList(guestLockedNotifications, troomRef.current,updated,setUpdated,isGuestInviteIncluded);
  const [watermarkChecks, setWatermarkChecks] = useState(
    get(watermarkDetails, "values", {}) as any
  );

  const featureAvailable = async (feature_name: string) => {
    if (featuresList) {
      //  feature as key present in feature_flags
      if (feature_name in featuresList) {
        return featuresList[feature_name];
      }
    } else {
      const list = await fetchFeatures(show_id);
      if (feature_name in list) {
        return list[feature_name];
      }
      setFeaturesList(list);
    }
  }

  //Chat hook
  const {
    downloadMessages,
    clearChat,
    leaveChat,
    leaveAnnotateChat,
    joinPubNubChat,
    pubnub,
    channels,
    typingInd,
    sendMessage,
    handleChatCopy,
    handleUploadCancel,
    handleUploadConfirm,
    chatClearLoading,
    fileUploadLoading,
    setVFXcolor,
    setSFXcolor,
    setMXcolor,
    setPIXcolor,
    VFXcolor,
    SFXcolor,
    MXcolor,
    PIXcolor,
    modalAnnotateColorOpen, 
    setModalAnnotateColorOpen,
    annotateChatClearLoading,
    downloadAnnotateMessages,
    annotateLoading,
    clearAnnotationChat,
    editingAnnotateMessageId,
    onChangeAnnotateEditMessage,
    annotateInput,
    editAnnotateInput,
    setEditAnnotateMessage,
    setEditingAnnotateMessageId,
    setAnnotateEditInput,
    handleAnnotateEditMessageClick,
    editAnnotateMessage,
    onChangeAnnotateMessage,
    sendAnnotationMessage,
    setAnnotateInput,
  } = useChatFunctions(
    streamLabel,
    dataTrackRef,
    setAnnotateMessages,
    setClearChatModalOpen,
    setMessages,
    user_imageurl,
    setRPState,
    messagesRef,
    annotateMessagesRef,
    featureAvailable,
    showId,
    setLoading,
    uploadFiles,
    setUploadFiles,
    setInput,
    setShowEmoji,
    setUploadAlert,
    chatInputFieldRef,
  )

  useEffect(() => {
    if (isVCHost) {
      const guestWaiting: any = [];

      roomParticipantsList.map((participant: any) => {
        let allowedGuest: any = sessionStorage.getItem('allowedGuest') || {};
        allowedGuest = typeof allowedGuest === 'string' ? JSON.parse(allowedGuest) : allowedGuest;
        const participantSid = get(participant, 'sid', '');
        const participantIdentitiy = get(participant, 'identity', '');
        const partcipantIsGuest: boolean =
          get(participant.identity.split('#'), '0', '').toLowerCase() === 'guestviewer';
        if (
          !disconnectedGuests.includes(participantSid) &&
          partcipantIsGuest &&
          get(participant, 'state', '').toLowerCase() === 'connected' &&
          allowedGuest[participantIdentitiy]?.status === 'waiting'
        ) {
          const displayName = get(participant.identity.split('_'), '1', '').toLowerCase();
          // Check whether the notification is already shown for the guest.
          if (!notifiedGuests.includes(participantIdentitiy)) {
            openNotification('bottomRight', displayName);
            //Add guest identity to the notified guest tab so that notification is not shown again for this guest
            notifiedGuests.push(participantIdentitiy);
          }
          // Update the guest waiting list
          guestWaiting.push(participantIdentitiy);
        } else if (
          allowedGuest[participantIdentitiy]?.status === 'allowed' &&
          notifiedGuests.includes(participantIdentitiy)
        ) {
          const index = notifiedGuests.indexOf(participantIdentitiy);
          //Since the guest is allowed he is removed from the notified guests list.
          notifiedGuests.splice(index, 1);
        }
      });

      if (guestWaiting.length !== 0) {
        setRedIcon(true);
      } else {
        setRedIcon(false);
      }
    }
  }, [guests.length, guestStatusCheck, roomParticipantsList, roomParticipantsConnectionStatus]);

  const openNotification = (placement: NotificationPlacement, name:string) => {
    notification.info({
      message: 'Pacpost',
      description: `${name} is in waiting room`,
      placement,
    });
  };

  const getAutoAllowStatus = async () => {
    try {
      setLoading(true);

      if (!stream_id) {
        return;
      }
    
      const data = {
        api: api.streams.getAutoAllowStatus,
        queryParam: { stream_id: stream_id},
      };
      const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
      const identity = troomRef.current;
      if (!get(result,"autoAllowStatus",false)) {
        setVolumeMute(true)
        setOpen(true);
      }
      setTimeout(()=>{setGloader(false)},1500);
      //setStatus(result.autoAllowStatus);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // console.log("useEffect-isGuest", isGuest);
    const identity = sessionStorage.getItem('localIdentity') || '';
    let guestSessions = sessionStorage.getItem('allowedGuest') || {};
    let allowedGuestSession =
      typeof guestSessions === 'string' ? JSON.parse(guestSessions) : guestSessions;
    if (isGuestViewer === true) {
      sessionStorage.setItem('stream_id', stream_id);
      sessionStorage.setItem('stream_label', streamLabel);

      if (!!allowedGuestSession && allowedGuestSession[identity]?.status === 'allowed') {
        setGloader(false);
        return;
      } else if (allowedGuestSession[identity]?.status === 'waiting') {
        setGloader(false);
        setOpen(true);
        setVolumeMute(true);
      } else {
        // setVolumeMute(true);
        getAutoAllowStatus();
      }
    } else {
      return;
    }
  }, []);

  //Back Button
  useEffect(() => {
    return () => {
      if (navigationType === 'POP') {
        if (troom && !isEmpty(troom)) {
          troom.disconnect();
        }
        clearWhiteBoard();
      } else if (troom && !isEmpty(troom) && navigationType === 'PUSH') {
        troom.localParticipant.tracks.forEach((publication: any) => {
          if (publication.kind === 'video') {
            publication.track.stop();
            publication.unpublish();
          }
        });

        troom.disconnect();
        leaveOrEndConference(false, troom.name);
      }
    };
  }, [navigationType, troom]);

  //Tab closing
  useEffect(() => {
    window.addEventListener('beforeunload', disconnectOnTabClose);

    const user = auth.getUser();
    const uid = get(user, 'uid', '');

    analytics.showViewerVisit(uid, show_id);

    return () => {
      window.removeEventListener('beforeunload', disconnectOnTabClose);
    };
  }, []);

  const disconnectOnTabClose = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
    if (troom && !isEmpty(troom)) {
      troom.disconnect();
    }
    clearWhiteBoard();
  };

  const getPublisherStatus = async () => {
    try {
      console.log("isShowPublisher - api call")
      const isShowPublisher = await backend.fetch({
        api: api.users.isShowPublisher,
        queryParam: { show_id },
      }, get(authValue, 'accessToken', {}));
      setIsPublisher(isShowPublisher);
      const giFeatureIncluded = await featureAvailable(FEATURE_NAME.ALLOW_GUEST_INVITE);
      setGuestInviteEnabledforStream(giFeatureIncluded);
      if(giFeatureIncluded && isShowPublisher) {
        const giAllowedForPublisher = get(isShowPublisher,"guest_invite",false);
        setGuestInviteIncluded(!!giAllowedForPublisher);
        if (giAllowedForPublisher) { 
          fetchGlobalAutoAllow()
        }
      }
      
    } catch (err: any) {
      console.log(err);
    }
  };

  const fetchGlobalAutoAllow = async () => {
    try {
      // setLoading(true);
      const data = {
        api: api.shows.fetchGlobalAutoAllow,
        queryParam: { show_id: show_id },
      };
      const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
      if (result) {
        setGlobalGuestAutoAllow(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const checkTimeCodeFeature = async () => {
    //console.log("timecode use effect")
    const timeCodeFeature = await featureAvailable(FEATURE_NAME.TIME_CODE)
    if (timeCodeFeature) {
      setTimeCodeFeatureIncluded(true);
    }
  };

  const watermarkStatus = async () => {
    const watermarkFeature = await featureAvailable(FEATURE_NAME.ALLOW_WATERMARK);
    setWatermarkFeatureIncluded(!!watermarkFeature);
    if(!!watermarkFeature) {
      await getipdata();
    }
  };

  const encoderStatus = async () => {
    const encoderFeature = await featureAvailable(FEATURE_NAME.ALLOW_ENCODER);
    setEncoderFeatureIncluded(!!encoderFeature);
  };

  

  const fetchIceServers = async () => {
    try {
      // setLoading(true);
      const response = await fetch(turnApiUrl, {
        method: 'PUT',
      });

      if (response.status >= 300) {
        throw new Error('TURN API return unexpected status ' + response.status);
      }

      const result = await response.json();
      const { iceServers = [] } = result.v;
      setLoading(false);

      setIceServers(iceServers);
    } catch (err: any) {
      const msg = 'Error while pulling ICE Servers !';
      console.error(msg);
    } finally {
      setLoading(false);
    }
  };

  // If profile image changed as the header is specific to viewer
  const refreshProfile = async () => {
    // Pull user data to show new profile image if it is changed
    if (isGuestViewer) {
      return;
    }
    const data = {
      api: api.users.get,
    };
    setLoading(true);
    const profiledata = await backend.fetch(data, get(authValue, 'accessToken', {}));
    setLoading(false);
    if (profiledata) {
      const { imageUrlPath, user_chime_preferance } = profiledata;
      if (imageUrlPath) {
        setProfileImageUrl(imageUrlPath);
      }
      if (user_chime_preferance) {
        setUserChimePreferance(true);
      }
    }
  };

  // Check access
  const checkAccess = async () => {
    if (isGuestViewer) {
      return;
    }
    console.log("isShowViewer - api call")
    const isShowViewer = await backend.fetch({
      api: api.users.isShowViewer,
      queryParam: { show_id },
    }, get(authValue, 'accessToken', {}));
    
    if (!isShowViewer && !isGuestViewer) {
      navigateToPage('/unauthorised');
    }
  };
  
  const fetch_Stream_Data = async (stream_id: string, stream_label: string) => {
    if (stream_id) {
      let stream = { stream_id, stream_label };
      setShowStreamMenu(true);
      setStreamMenuActive(false);
      setStreamId(stream_id);
      setStreamLabel(stream_label);

      setStream(stream);

      setStreamKey(Math.random());

      if (stream_label !== MULTI_STREAM_VIEWER && !get(screens, 'xs', false)) {
        // Open chat window
        setRPState((prevState) => ({
          ...prevState,
          annotationChatWindow: false,
          annotationCloseDisable: false,
          annotationChatDisable: true,
          showPublisherPanel: false,
          showPublisherPanelCloseDisable: false,
          showGuestInvite: false,
          showGuestInviteCloseDisable: false,
          watermarkPanel: false,
          chatWindow: true,
          closeDisable: true,
          chatDisable: false,
          hudStatsDisplay:false,
          encoder: false
        }));
      }

      // Pull VC Room Details and start/join VC
      if (showMultiStreamViewer) {
        fetchVCRoomAndStartOrJoin(stream_id, MULTI_STREAM_VIEWER);
      } else {
        fetchVCRoomAndStartOrJoin(stream_id, stream_label);
      }
    }
  };

  const fetchStreamListData = async (show_id: string) => {
    const traceTrack = trace(performance, "SideMenuListLoader");
    try {
      traceTrack.start();
      setSuccessMessage('');
      setErrorMessage('');
      setFetchStreamListLoader(true);
      // 1. Pull active stream
      const data = {
        api: api.streams.listViewerStreams,
        queryParam: { show_id: show_id },
      };

      const streams: any[] = await backend.fetch(data, get(authValue, 'accessToken', {}));

      setStreamList(streams);

      if (streams && streams.length) {
        const cleanStream = streams.filter(
          (arr: any, index: any, self: any) =>
            index === self.findIndex((temp: any) => temp.stream_id === arr.stream_id),
        );
        cleanStream.sort(
          firstBy(function (v: any) {
            return v.stream_label.toLowerCase();
          }),
        );
        
        const isGuest = isGuestAuthenticated();
        if(isGuest){
          guestStreams.push(cleanStream)
        };
       
        setActiveStreams(cleanStream);
      }

      // If navigated from Publisher invite screen
      if (stream_id && streams) {
        // Check if stream_id present in active streams
        const result: any[] = streams.filter((stream) => stream.stream_id === stream_id);
        if (result && result[0]) {
          const pub_owned_stream = result[0] || null;
          if (pub_owned_stream) {
            const { stream_label } = pub_owned_stream;
            fetch_Stream_Data(stream_id, stream_label);
          }
        }
      }
    } catch (err: any) {
      const msg = 'Error while pulling data';
      if (err.errCode === 30314) {
        const user = auth.getUser();
        const uid: any = get(user, 'uid', '');
        Sentry.configureScope((scope) => scope.setUser({ id: uid }).setTag('show_id', show_id));
        Sentry.captureMessage(err.errMessage);
      }
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
      setFetchStreamListLoader(false);
      traceTrack.stop()
    }
  };

  useEffect(() => {
    console.log("UE-show_id")
    setShowId(show_id);
    //Viewer check
    checkAccess(); //To-do: revisit later

    if (show_id) {
      refreshProfile();

      // Fetch Ice Servers
      fetchIceServers();

      // Featre inclusion
      checkTimeCodeFeature();
      watermarkStatus();

      //isEncoder enabled
      encoderStatus()

      //Publisher check && GuestInvite check
      if (!isGuestViewer) {
        getPublisherStatus();
      }

      // Fetch Streams List
      fetchStreamListData(show_id);
    } else {
      navigateToPage('/unauthorised');
    }
  }, [show_id]);

  useEffect(() => {
    if (!!dataTrackRef && !!dataTrackRef.current) {
      dataTrackRef.current.send(
        JSON.stringify({ streamId: updatedStreamId, streamName: updatedStreamLabel }),
      );
    }
  }, [updatedStreamLabelToAll]);

  useEffect(() => {
    const stream_id = sessionStorage.getItem('stream_id');
    const stream_label: any = sessionStorage.getItem('stream_label');

    if (stream_id && stream_label) {
      fetch_Stream_Data(stream_id, stream_label);
    }

    return () => {
      if(navigationType === 'PUSH'){
        sessionStorage.removeItem('stream_id');
        sessionStorage.removeItem('allowedGuest');
        sessionStorage.removeItem('stream_label');
        sessionStorage.removeItem('vc_id');
        sessionStorage.removeItem('auto_allow');
        sessionStorage.removeItem('urlId');
        sessionStorage.removeItem('publisher');
        sessionStorage.removeItem('localIdentity');
        sessionStorage.removeItem('pubname');
        sessionStorage.removeItem('previousStreamName');
      } 
    };
  }, []);

  const showOverlay = async (msg: any) => {
    if (msg) {
      setOverlaymsg(msg)
    }
    
    let sstyle: any = { style: { display: 'block' } };
    setOverlay(sstyle);

    await sleep(3000);

    let hstyle: any = { style: { display: 'none' } };
    setOverlay(hstyle);
  };

  const toggleAudioVideo = async () => {
    setCameraDropdownOpen(!cameraDropdownOpen);
    resetAllTools();
  };

  //Full screen video
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const focusedElement = document.activeElement;
      const focusedElementType = focusedElement?.tagName.toLowerCase() || '';
      const excludedElementTypes = ['input', 'textarea', 'select'];

      if (event.key.toLowerCase() === 'f' && !showDominantSpeaker && !excludedElementTypes.includes(focusedElementType)) {
        fullScreenVideo();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showDominantSpeaker]);
  
  const fullScreenVideo = async () => {
    // if (!!videoObj) {
    //   videoObj.enter()
    // }
    // const elem: any = document.getElementsByTagName("video")[0];
    const elem: any = document.getElementById('video-container');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const handleDiagnostics = () => {
    setShowDiagnostics(true);
  };

  const handleDND = async () => {
    // turn off my audio
    const locParticipant = lParticipantRef.current;

    const audioButtonElement: any = document.getElementById(locParticipant.sid + '_audio');
    const videoButtonElement: any = document.getElementById(locParticipant.sid + '_video');
    if (!isDND) {
      if (!localAudioMuteRef.current) {
        audioButtonElement.click();
      }
      if (!localVideoMuteRef.current) {
        videoButtonElement.click();
      }
    } else {
      if (localAudioMuteRef.current) {
        audioButtonElement.click();
      }
      if (localVideoMuteRef.current) {
        videoButtonElement.click();
      }
    }

    // console.log('troom.participants :>> ', troom.participants);
    await troom.participants.forEach(dndParticipant);

    // toggling the current dnd variable
    // console.log(`isDND : ${isDND}`)
    setMyDND(!isDND);
  };

  const dndParticipant = (participant: any, index: any) => {
    participant.tracks.forEach((publication: any) => {
      if (publication.kind === 'audio') {
        const audioButtonElement = document.getElementById(participant.sid + '_audio');

        if (!isDND) {
          trackUnsubscribed(publication.track);
          if (audioButtonElement) {
            audioButtonElement.className = 'icon overlay-audio-off';
          }
        } else {
          const div = document.getElementById(participant.sid);
          trackSubscribed(div, publication.track);

          if (
            audioButtonElement &&
            publication.track.kind === 'audio' &&
            publication.track.isEnabled
          ) {
            audioButtonElement.className = 'icon overlay-audio-on';
          }
        }
      }
      if (publication.kind === 'video') {
        const videoButtonElement = document.getElementById(participant.sid + '_video');

        if (!isDND) {
          trackUnsubscribed(publication.track);
          if (videoButtonElement) {
            videoButtonElement.className = 'icon overlay-video-off-tray';
          }
          const activeViewers = roomParticipantsListRef.current;
          showProfileImage(activeViewers, participant.sid, participant.identity);
        } else {
          const div = document.getElementById(participant.sid);
          trackSubscribed(div, publication.track);

          if (
            videoButtonElement &&
            publication.track.kind === 'video' &&
            publication.track.isEnabled
          ) {
            videoButtonElement.className = 'icon overlay-video-on-tray';
          }
          // Remove profile image when remote participant video unmuted
          const activeViewers = roomParticipantsListRef.current;
          hideProfileImage(activeViewers, participant.sid, participant.identity);
        }
      }
    });
  };

  const volumeUpDown = async (event: any) => {
    const elem: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
    if (elem) {
      const value = event.currentTarget.value;
      setVolumeLevel(value);
      elem.volume = value / 100;
      if (elem.volume === 0) {
        setVolumeMute(true);
      } else {
        setVolumeMute(false);
      }
    }
  };


  const leaveWatermark = () => {
    setRPState((prevState) => ({
      ...prevState,
      watermarkPanel: false,
    }));
  };

  const leaveGuestInvite = () => {
    setRPState((prevState) => ({
      ...prevState,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
    }));
  };

  const leavePublisherPanel = () => {
    setRPState((prevState) => ({
      ...prevState,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
    }));
  };

  const leaveHudStats = () => {
    setRPState((prevState) => ({
      ...prevState,
      hudStatsDisplay: false,
    }));
  }

  //Participant while joind the vc show the status in pubnub chat
  const vcParticipants = async (participant: any, status: any) => {
    const user = auth.getUser();
    const uid = get(user, 'uid', '');
    let identity = participant.identity.split('_');
    const displayName = identity[1];
    const currTime = new Date().getTime();
      // Update local state with new msg
      const new_msg: any = {
        message_id: uid + String(currTime),
        userDisplay: "Pacpost",
        description: `${displayName} has ${status} the room`,
        userid: uid,
        imageurl: pacpost_img,
        status:status,
        timetoken:currTime
      };
      const msgs = [...messagesRef.current];
      msgs.push(new_msg);
      setMessages(msgs);
  }

  const turnOffTypingIndicator = (channelIndex: number, displayName: string | undefined) => {
    pubnub.signal({
      channel: channels[channelIndex],
      message: `typing_off:${displayName}`,
    });
  }

  const gotDevices = (mediaDevices: any) => {
    let cameras: any = [];
    let microphones: any = [];

    mediaDevices.forEach((mediaDevice: any) => {
      if (mediaDevice.kind === 'videoinput') {
        if (mediaDevice.deviceId === 'default') {
          setCameraName(mediaDevice.label);
        }
        cameras.push({
          value: mediaDevice.deviceId,
          text: mediaDevice.label,
          key: mediaDevice.label,
        });
      } else if (mediaDevice.kind === 'audioinput') {
        if (mediaDevice.deviceId === 'default') {
          setMicName(mediaDevice.label);
        }
        microphones.push({
          value: mediaDevice.deviceId,
          text: mediaDevice.label,
          key: mediaDevice.label,
        });
      }
    });
    cameras.push({
      value: 'DESKTOP',
      text: 'Share Your Desktop',
      key: 'desktop',
    });

    _setCameraOptions(cameras);
    _setMicOptions(microphones);
  };

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = () => {
      navigator.mediaDevices.enumerateDevices().then(gotDevices);
    };
  }, []);

  const changeLocalView = (pid: string, track: LocalVideoTrack) => {
    const div = document.getElementById(pid);
    // Look for the video element for the local preview div:
    if (div) {
      const preview = div.getElementsByTagName('video');

      const vid_elem = track.attach();
      div.removeChild(preview[0]);
      div.appendChild(vid_elem);
    }
  };

  const changeLocalAudio = (pid: string, track: LocalAudioTrack) => {
    const div = document.getElementById(pid);
    // Look for the audio element for the local preview div:
    if (div) {
      const preview = div.getElementsByTagName('audio');
      div.removeChild(preview[0]);
      div.appendChild(track.attach());
    }
  };

  const getImage = (): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      const bgImg = backgrounds.filter((item: any) => item.value === activeBGRef.current);
      img.src = bgImg[0]?.image;
    });
  };

  const changeCameraTrack = async (cameraId: string | undefined) => {
    const localParticipant = troomRef.current.localParticipant;

    if (cameraId === 'DESKTOP') {
      // @ts-ignore
      navigator.mediaDevices
        .getDisplayMedia({ video: { height: 720, frameRate: 24, width: 1280 }, audio: true })
        .then((stream: any) => {
          const localVideoTrack = new LocalVideoTrack(stream.getTracks()[0]);
          const tracks: Array<LocalVideoTrackPublication> = Array.from(
            localParticipant.videoTracks.values(),
          );
          localParticipant.unpublishTrack(tracks[0].track);
          localParticipant
            .publishTrack(localVideoTrack)
            .then((publication: LocalVideoTrackPublication) => {
              changeLocalView(localParticipant.sid, localVideoTrack);
            });
        });
    } else {
      if (!localVideoMuteRef.current) {
        let localVideoTrack: LocalVideoTrack;
        if (!!cameraId) {
          localVideoTrack = await createLocalVideoTrack({
            deviceId: { exact: cameraId },
          });
        } else {
          localVideoTrack = await createLocalVideoTrack();
          setCameraId('');
        }
        const tracks: Array<LocalVideoTrackPublication> = Array.from(
          localParticipant.videoTracks.values(),
        );
        const bgVideoTrack = await applyBGFilter(localVideoTrack, tracks);
        setLocalParticipantTrack(bgVideoTrack);
        localParticipant.unpublishTrack(tracks[0].track);
        localParticipant
          .publishTrack(bgVideoTrack)
          .then((publication: LocalVideoTrackPublication) => {
            changeLocalView(localParticipant.sid, bgVideoTrack);
          });
      }
    }
  };

  const applyBGFilter = async (localVideoTrack: LocalVideoTrack, tracks: any) => {
    if (activeBGRef.current === 'none') {
      // remove processor -- not needed to do anything
      //localVideoTrack.removeProcessor(tracks[0].track.processor);
    } else if (activeBGRef.current === 'blur') {
      blurProcessor = new GaussianBlurBackgroundProcessor({
        assetsPath: virtualBackgroundAssets,
      });
      await blurProcessor.loadModel();
      localVideoTrack.addProcessor(blurProcessor);
    } else {
      virtualBackgroundProcessor = new VirtualBackgroundProcessor({
        assetsPath: virtualBackgroundAssets,
        backgroundImage: await getImage(),
        fitType: ImageFit.Fill,
      });
      await virtualBackgroundProcessor.loadModel();
      localVideoTrack.addProcessor(virtualBackgroundProcessor);
    }

    return localVideoTrack;
  };

  const changeCamera = (e: any, v: any) => {
    setCameraName(v.name);
    setCameraDropdownOpen(!cameraDropdownOpen);

    if (localVideoMute) {
      setCameraId(v.value);
      return;
    }

    // Video isn't muted - go ahead and do the switcheroo
    changeCameraTrack(v.value);
  };

  const changeMicrophoneTrack = (microphoneId: string) => {
    const localParticipant = troomRef.current.localParticipant;
    createLocalAudioTrack({
      deviceId: { exact: microphoneId },
      noiseCancellationOptions,
    }).then(function (localAudioTrack) {
      const tracks: Array<LocalAudioTrackPublication> = Array.from(
        localParticipant.audioTracks.values(),
      );
      localParticipant.unpublishTrack(tracks[0].track);
      localParticipant.publishTrack(localAudioTrack);
      const previewContainer = document.getElementById('local-media');
      changeLocalAudio(localParticipant.sid, localAudioTrack);
    });
  };

  const [hasNoiseCancellation, cancelNoise, setCancelNoise, clearCancelNoise] =
    useNoiseCancellation(troom);

  const changeMicrophone = (e: any, v: any) => {
    setMicName(v.name);
    setCameraDropdownOpen(!cameraDropdownOpen);

    if (localAudioMute) {
      setMicId(v.value);
      return;
    }
    changeMicrophoneTrack(v.value);
  };

  const changeState = () => {
    setGuestStatusCheck(!guestStatusCheck);
  };

  const handleAutoAllow = async () => {
    const allowState = !(sessionStorage.getItem('auto_allow') === 'true');
    try {
      setLoading(true);
      const input = { stream_id: sessionStorage.getItem('stream_id'), auto_allow: allowState };
      const data = {
        api: api.streams.addAutoAllowGuests,
        payLoad: JSON.stringify(input),
      };
      const result = await backend.save(data, get(authValue, 'accessToken', {}));
      //const sessionData:any= sessionStorage.getItem("allowedGuest") || "";
      //const parseResult:any = !!sessionData ? Object.keys(JSON.parse(sessionData)) : [] ;
      let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
      allowedGuestSession =
        typeof allowedGuestSession === 'string'
          ? JSON.parse(allowedGuestSession)
          : allowedGuestSession;
      const dataTrack = troom?.localParticipant?.dataTracks.values().next().value;
      troom.participants.forEach((roomParticipant: any) => {
        if (roomParticipant.identity.split('#')[0] === 'guestviewer' && allowState === true) {
          allowedGuestSession[roomParticipant.identity] = {
            sid: roomParticipant.sid,
            status: 'allowed',
          };
          sessionStorage.setItem(
            'allowedGuest',
            typeof allowedGuestSession === 'string'
              ? allowedGuestSession
              : JSON.stringify(allowedGuestSession),
          );
          dataTrack.track.send(
            JSON.stringify({
              allowedGuestSession: allowedGuestSession,
            }),
          );
        }
      });
      changeState();
      if (dataTrack) {
        dataTrack.track.send(
          JSON.stringify({
            auto_allow: `${allowState}`,
          }),
        );
      }
      sessionStorage.setItem('auto_allow', `${allowState}`);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };


  const updateAutoAllowAtStreamLevel = async () => {
    try {
      const stream_id = sessionStorage.getItem('stream_id')
      const input = { stream_id, auto_allow: true };
      const data = {
        api: api.streams.addAutoAllowGuests,
        payLoad: JSON.stringify(input),
      };
      const result = await backend.save(data, get(authValue, 'accessToken', {}));

      let allowedGuestSession: any = sessionStorage.getItem("allowedGuest") || {};
      allowedGuestSession = typeof allowedGuestSession === "string" ? JSON.parse(allowedGuestSession) : allowedGuestSession;
      const dataTrack = troom?.localParticipant?.dataTracks.values().next().value;
      if (troom.participants) {
        troom.participants.forEach((roomParticipant: any) => {
          const identity = roomParticipant?.identity?.split("#")[0];
          if (identity === "guestviewer") {
            allowedGuestSession[roomParticipant?.identity] = { sid: roomParticipant.sid, status: "allowed" };
          }
        });
      }

      sessionStorage.setItem(
        "allowedGuest",
        typeof allowedGuestSession === "string" ? allowedGuestSession : JSON.stringify(allowedGuestSession)
      );

      if (dataTrack) {
        dataTrack.track.send(
          JSON.stringify({
            allowedGuestSession: allowedGuestSession,
            auto_allow: 'true',
          })
        );
      }

      sessionStorage.setItem("auto_allow", "true");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchVCRoomDetails = async (stream_id: string) => {
    try {
      let input:any = "";
      const isGuest = isGuestAuthenticated();
      if (isGuest) {
        input = get(guestStreams[0], '0.vcRoomId', '');
      } else {
        input = sessionStorage.getItem('vc_id') || '';
      }
     
      const data = {
        api: api.vc.getVCRoom,
        urlParam: input,
      };

      const stream_vc_room: any = await backend.fetch(data, get(authValue, 'accessToken', {}));
      //setLoading(false);
      if (stream_vc_room) {
        if (guestInviteEnabledforStream || isGuestViewer) {
          sessionStorage.setItem("auto_allow", `${!!stream_vc_room.auto_allow}`);
        } 
      }

      return stream_vc_room;
    } catch (err: any) {
      console.log(err);
    }
  };

  const isUserInvited = async (stream_vc_room_id: any) => {
    if (isGuestViewer) {
      return true;
    }
    
    try {
      setLoading(true);
      const data = {
        api: api.vc.getVCRoomParticipant,
        queryParam: {
          show_id,
          show_vc_room_id: stream_vc_room_id,
        },
      };

      const participant: any = await backend.fetch(data, get(authValue, 'accessToken', {}));
      setLoading(false);
      if (participant) {
        setLoading(false);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    } catch (err: any) {
      setLoading(false);
      throw err;
    }
  };

  const cleanUpWhenUserLeftRoom = () => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatDisable: true,
      chatWindow: false,
      closeDisable: false,
      watermarkPanel: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      hudStatsDisplay:false,
      encoder: false
    }));
    leaveChat();
    setTRoom({});
    clearWhiteBoard();
    leaveGuestInvite();
    leavePublisherPanel();
    leaveAnnotateChat();
    setActiveStreamVCRoom({});
    leaveHudStats();
    // setStream({});
  };

  const cleanupWhenUserSwitchingStream = () => {
    setRPState((prevState: any) => ({
      ...prevState,
      chatDisable: true,
      chatWindow: false,
      closeDisable: false,
      watermarkPanel: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      hudStatsDisplay: false,
      encoder: false
    }))
    leaveChat();
    leaveAnnotateChat();
    leaveGuestInvite();
    leavePublisherPanel();
    leaveWatermark();
    leaveHudStats();
  }

  const getipdata = async () => {
    try {
      const endPoint = 'https://ipapi.co/json/';

      await fetch(endPoint)
        .then((res) => res.json())
        .then((data) => {
          setIpAddress(data.ip);
        });
    } catch (err: any) {
      console.error(err);
    }
  };
  /* ***********************  Main Method ************************** */
  
  const fetchVCRoomAndStartOrJoin = async (stream_id: string, stream_label: string) => {
    try {
      if (stream_label === MULTI_STREAM_VIEWER) {
        setShowMultiStreamViewer(true);
      } else {
        setShowMultiStreamViewer(false);
      }

      setlocalMute(true);
      setLocalVideoMute(true);
      setWatermarkDetails({});
      let stream_vc_room: any = await fetchVCRoomDetails(stream_id);
      
      if (stream_vc_room) { 
        setActiveStreamVCRoom(stream_vc_room);
        setWatermarkDetails(get(stream_vc_room, "watermark", {}));
        const previousStreamName = sessionStorage.getItem('previousStreamName') || '';

        // Switching Stream
        if (troom && !isEmpty(troom)) {
          if (
            troom.name !== stream_vc_room.twilio_room_name
            || stream_label === MULTI_STREAM_VIEWER
            || previousStreamName === MULTI_STREAM_VIEWER
          ) {
            // Disconnect from old one
            await troom.disconnect();
            leaveOrEndConference(false,troom.name);

            // CleanUp
            cleanupWhenUserSwitchingStream()
          }
        }

        if (previousStreamName !== stream_label) {
          sessionStorage.setItem('previousStreamName', stream_label);
        }

        const owner_user_id: string = stream_vc_room.owner_user_id;
        const canvas: any = document.getElementById('canvas');
        
        if (troom && !isEmpty(troom) && (troom.name === stream_vc_room.twilio_room_name && previousStreamName !== MULTI_STREAM_VIEWER) && stream_label !== MULTI_STREAM_VIEWER) {
          console.log('** fetchVCRoomAndStartOrJoin, rendering canvas')
          renderCanvas(canvas);
          return; //to-do: why we are rendering candvas now and returning, existing code
        }

        if (stream_vc_room.show_vc_room_id) {
          showVcIdref.current = stream_vc_room.show_vc_room_id;
        }
        //access token
        const accessToken = get(authValue, 'accessToken', {})

        // get idenity, uid, token
        let identity = '';
        let userUid = '';
        let displayName = ''

        if (isGuestViewer) {
          // Guests 
          identity = `guestviewer#${guestUid!}_${guestDisplayName!}`;
          userUid = guestUid!;
          setIsVCHost(false);
        }

        if (!isGuestViewer) {
          // Publishers and Viewers
          const user = auth.getUser();
          userUid = get(user, 'uid', '');
          displayName = get(user, 'displayName', '');
          identity = `member#${userUid}_${displayName}`;
          
          if (owner_user_id === auth.getUserId()) {
            //admin (host)
            setIsVCHost(true);
            sessionStorage.setItem('publisher', 'true');
            identity = `admin#${userUid}_${displayName}`;
          } else {
            setIsVCHost(false);
          }
        }

        // Pubnub
        joinPubNubChat(userUid, stream_vc_room.show_vc_room_id);

        if (stream_label !== MULTI_STREAM_VIEWER && !get(screens, 'xs', false)) {

          // Open chat window
          setRPState((prevState) => ({
            ...prevState,
            chatWindow: true,
            closeDisable: true,
            chatDisable: false
          }));
        }

        /* The chatloaded flag is used to prevent other 
          windows from opening before the chat is loaded*/
        setChatLoaded(true);

        //Get status of guests who have already joined the room from db.
        if(guestInviteEnabledforStream || isGuestViewer){
          const sessions = await getGuestSession();
          if (!!sessions) {
            sessionStorage.setItem(
              'allowedGuest',
              typeof sessions == 'string' ? sessions : JSON.stringify(sessions),
            );
          }
        }

        // Join VC
        if (stream_vc_room && stream_vc_room.twilio_room_name) {
          await joinTwilioRoom(stream_vc_room, identity, accessToken, show_id, gotDevices, 
            vcParticipants, cleanUpWhenUserLeftRoom, showOverlay);
        } else {
          // Start VC (Join VC and update twilio room link in backend)
          await joinTwilioRoom(stream_vc_room, identity, accessToken, show_id, gotDevices, 
            vcParticipants, cleanUpWhenUserLeftRoom, showOverlay);

          // Update room name in database so other viewer can auto join
          updateTwilioRoom(stream_vc_room, stream_vc_room.show_vc_room_id, stream_id);
        }
        
      } else {
        console.log("No room created at the time of stream creation");
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  //Show profile image when remote participant mutes video
  const showProfileImage = async (activeViewers: any, sid: any, uid: any) => {
    const identity = uid;
    if (get(identity.split('#'), '0', '').toLowerCase() === 'guestviewer') {
      const backupName = document.createElement('div');
      backupName.textContent = identity.split('_')[1];
      backupName.className = 'backup-name';
      backupName.id = `${sid}_backupName`;
      const remoteparticipantDiv = document.getElementById(sid);
      if (remoteparticipantDiv) {
        remoteparticipantDiv.insertBefore(backupName, remoteparticipantDiv.childNodes[0]);
      }
    }

    if (uid) {
      uid = /\#(.*?)\_/.exec(uid);
      uid = uid[1];
    }
    const remoteparticipantDiv = document.getElementById(sid);
    if (remoteparticipantDiv) {
      var disableVideoEle = remoteparticipantDiv.getElementsByTagName('video');
      if (disableVideoEle && disableVideoEle[0]) {
        disableVideoEle[0].classList.add('hide-profile');
      }
    }
    if (activeViewers) {
      activeViewers.forEach((viewer: any) => {
        const viewerIdentity = get(viewer, 'identity', '');
        const viewerUserId = get(get(viewerIdentity.split('#'), '1', '').split('_'), '0', '');
        const viewerUserName = get(get(viewerIdentity.split('#'), '1', '').split('_'), '1', '');
        if (viewer && viewerUserId === uid) {
          const user_profile_img = document.getElementById(sid + '_image');
          if (!user_profile_img) {
            const backupName = document.createElement('div');
            backupName.textContent = viewerUserName;
            backupName.className = 'backup-name';
            backupName.id = viewerUserId + '_backupName';

            if (remoteparticipantDiv) {
              remoteparticipantDiv.insertBefore(backupName, remoteparticipantDiv.childNodes[0]);
            }
          }
        }
      });
    }
  };

  //hide profile image when remote participant unmutes video
  const hideProfileImage = async (activeViewers: any, sid: any, uid: any) => {
    const tempUId: any = uid;
    if (get(tempUId.split('#'), '0', '').toLowerCase() === 'guestviewer') {
      const removeBackupName = document.getElementById(sid + '_backupName');
      const remoteparticipantDiv = document.getElementById(sid);

      if (removeBackupName) {
        if (remoteparticipantDiv) {
          remoteparticipantDiv.removeChild(removeBackupName);
        }
      }
    }

    if (uid) {
      uid = /\#(.*?)\_/.exec(uid);
      uid = uid[1];
    }
    const remoteparticipantDiv = document.getElementById(sid);
    if (remoteparticipantDiv) {
      var disableVideoEle = remoteparticipantDiv.getElementsByTagName('video');
      disableVideoEle[0]?.classList.remove('hide-profile');
    }
    activeViewers.map((viewer: any) => {
      const viewerIdentity = get(viewer, 'identity', '');
      const viewerUserId = get(get(viewerIdentity.split('#'), '1', '').split('_'), '0', '');
      if (viewerUserId === uid) {
        const removeBackupName = document.getElementById(viewerUserId + '_backupName');
        if (removeBackupName) {
          if (remoteparticipantDiv) {
            remoteparticipantDiv.removeChild(removeBackupName);
          }
        }
      }
    });
  };

  const updateGuestSession = async (guestSession: {}) => {
    try {
      const streamId = sessionStorage.getItem('stream_id');

      const data = {
        api: api.vc.updateGuestSession,
        urlParam: showVcIdref.current,
        payLoad: {
          guest_session: guestSession,
          showStream_id: !!stream_id ? stream_id : streamId,
          show_id: show_id,
        },
      };
      await backend.save(data, get(authValue, 'accessToken', {}));
    } catch (err: any) {
      console.log(err);
    }
  };

  const getGuestSession = async () => {
    try {
      const data = {
        api: api.vc.getGuestSession,
        urlParam: showVcIdref.current
      }; 
      const result = await backend.fetch(data, get(authValue, 'accessToken', {}))
      // console.log("::::::::::::",result)
      return result;
    } catch (err: any) {
      console.log(err);
    }
  };

  const updateTwilioRoom = async (
    stream_vc_room: any,
    twilio_room_name: string,
    stream_id: string,
  ) => {
    try {
      const data = {
        api: api.vc.updateStreamVCRoom,
        urlParam: stream_vc_room.show_vc_room_id,
        payLoad: {
          show_id,
          showStream_id: stream_id,
          twilio_room_name,
        },
      };
      await backend.save(data, get(authValue, 'accessToken', {}));
      
    } catch (err: any) {
      console.error('Not able to update twilio room in db');
    }
  };

  /*
   ****************************
   * VC Main Action Controls
   ****************************
   */

  const handleGlobalMute = () => {
    let muteFlag = !muteAll;
    setMuteAll(muteFlag);
    setlocalMute(muteFlag);
    resetAllTools();

    // here mute my self first
    const locParticipant = lParticipantRef.current;
    const audioButton = document.getElementById(locParticipant.sid + '_audio');

    if (muteFlag) {
      if (audioButton) {
        audioButton.className = 'icon overlay-audio-off';
      }
      locParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === 'audio') {
          if (publication.isTrackEnabled) {
            publication.track.disable();
          }
        }
      });
    } else {
      if (audioButton) {
        audioButton.className = 'icon overlay-audio-on';
      }
      locParticipant.tracks.forEach((publication: any) => {
        if (publication.kind === 'audio') {
          if (!publication.isTrackEnabled) {
            publication.track.enable();
          }
        }
      });
    }

    // here send data track with global mute variable and everybody will receive and close audio track respectively
    dataTrackRef.current.send(JSON.stringify({ globalMute: muteFlag }));
  };

  const handelAudio = async () => {
    setlocalMute(!localMute);
    resetAllTools();
    const audioButtonElement = document.getElementById(troomRef.current.localParticipant?.sid + '_audio');

    troomRef.current.localParticipant.tracks.forEach((publication: any) => {
      if (publication.kind === 'audio') {
        if (publication.isTrackEnabled) {
          publication.track.disable();

          // Update Participant Tray Audio Icon
          if (audioButtonElement) {
            audioButtonElement.className = 'icon overlay-audio-off';
          }
        } else {
          publication.track.enable();

          // Update Participant Tray Audio Icon
          if (audioButtonElement) {
            audioButtonElement.className = 'icon overlay-audio-on';
          }
        }
      }
    });
  };

  const handelVideo = async () => {
    resetAllTools();
    const videoButtonElement = document.getElementById(troomRef.current.localParticipant?.sid + '_video');
    videoButtonElement?.click();
  };

  const leaveOrEndConference = async (endConferenceFlag: boolean, vcRoomId?:string) => {
    try {
      const room_sid = troom.sid;
      const stream_id = sessionStorage.getItem('stream_id');
      const show_vc_room_id = !!vcRoomId ? vcRoomId : showVcIdref.current;
      const isGuest = isGuestViewer;
      const guestInviteFlag = isGuestInviteIncluded || isGuest;
      const waterMarkFlag = watermarkFeatureIncluded;
      const encoderFlag = encoderFeatureIncluded;
      
      if (!stream_id || !showId) {
        return;
      }

      const input = {
        showId,
        stream_id,
        show_vc_room_id,
        room_sid,
        endConferenceFlag,
        waterMarkFlag,
        guestInviteFlag,
        encoderFlag
      };
      const data = {
        api: api.vc.leaveOrEndConference,
        payLoad: JSON.stringify(input),
      };
      const result = backend.save(data, get(authValue, 'accessToken', {}));

      return result;
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };



  const handelVideoConfEnd = async (signOutFlag?:boolean) => {
    try {
      // check if room present
      let room = troom && !isEmpty(troom) ? troom : troomRef.current;
      setEndorLeaveConferenceLoading(true);
      const isGuest = isGuestAuthenticated();
      if (room && !isEmpty(room)) {
        room.disconnect();
        leaveOrEndConference(false)
        sessionStorage.removeItem('stream_id');
        sessionStorage.removeItem('stream_label');
        sessionStorage.removeItem('vc_id');
        sessionStorage.removeItem('previousStreamName');

        setTRoom({});
        setActiveStreamVCRoom({});
        setShowPopup(false);
      } else {
        console.log('Error: VC Room not present');
      }
      setStreamLabel('');
      setStream({});
      clearWhiteBoard();
      leaveChat();
      leaveAnnotateChat();
      leaveGuestInvite();
      leavePublisherPanel();
      leaveWatermark();
      leaveHudStats();
      if (isGuest) {
        navigateToPage('/logoutredirect', {state:{isGuest:true},replace : true});
      } else if (!signOutFlag) {
        const user = auth.getUser();
        const uid = get(user, 'uid', '');
        navigateToPage('/showselection', { state: { userId: uid },  replace: true },true);
      }
      return true;
    } catch (err) {
      console.error(err);
    } finally {
      setEndorLeaveConferenceLoading(false);
    }
  };

  const handelVideoConfEndAll = async () => {
    try {
      if (!isVCHost) {
        return;
      }
      setEndorLeaveConferenceLoading(true);
      const dataTrack = troom?.localParticipant?.dataTracks.values().next().value;
      troom.participants.forEach((roomParticipant: any) => {
        if (roomParticipant.identity.split('#')[0] === 'guestviewer') {
          let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
          allowedGuestSession =
            typeof allowedGuestSession === 'string'
              ? JSON.parse(allowedGuestSession)
              : allowedGuestSession;
          if (dataTrack && allowedGuestSession) {
            delete allowedGuestSession[roomParticipant.identity];
            dataTrack.track.send(
              JSON.stringify({
                allowedGuestSession: {},
              }),
            );
          }
        } else {
          if (dataTrack){
            dataTrack.track.send(
              JSON.stringify({
               endAll: true,
              }),
            );
          }
          
        }
      });

      leaveOrEndConference(true)

      setStreamLabel('');
      setStream({});
      clearWhiteBoardAll();
      leaveChat();
      leaveAnnotateChat();
      leaveGuestInvite();
      leavePublisherPanel();

      leaveWatermark();
      leaveHudStats();

      sessionStorage.removeItem('stream_id');
      sessionStorage.removeItem('stream_label');
      sessionStorage.removeItem('allowedGuest');
      sessionStorage.removeItem('auto_allow');
      sessionStorage.removeItem('gt');
      sessionStorage.removeItem('vc_id');
      sessionStorage.removeItem('previousStreamName');
      
      setShowPopup(false);
      const user = auth.getUser();
      const uid = get(user, 'uid', '');
      navigateToPage('/showselection', { state: { userId: uid },  replace: true  });

    } catch (err: any) {
      console.error(err);
    } finally {
      setEndorLeaveConferenceLoading(false);
    }
  };

  const updateWatermarkHandler = async (watermark: any) => {
    try {
      const streamId = sessionStorage.getItem('stream_id');
      const data = {
        api: api.vc.updateWatermark,
        urlParam: showVcIdref.current,
        payLoad: {
          watermark,
          showStream_id: streamId,
          show_id: show_id,
        },
      }; 
      const result = await backend.save(data, get(authValue, 'accessToken', {}));
      return result;
    } catch (err: any) {
      console.error('Not able to update watermark in vc room');
    }
  };

  const reloadPage = async () => {
    // wait a tick for any state updates to complete before reloading,
    // like any open menus to close.
    // await tick();
    // window.location.reload();
    resetAllTools();
    setStreamKey(Math.random());
  };

  const reloadSubStreams = async () => {
    setSubStreamKey(Math.random());
  };
  /*
   ****************************
   * Main Body Content
   ****************************
   */

  const handleUpdateStreamLabel = async () => {
    leftBarRef.current?.classList.add('left-bar-temp-width');

    if (!updatedStreamLabel) return;
    updatedStreamLabel.trim();
    if (updatedStreamLabel === '') return;

    try {
      const data = {
        api: api.streams.update,
        urlParam: updatedStreamId,
        payLoad: {
          update_data: { stream_label: updatedStreamLabel },
        },
      };
      setUpdateStreamLabelLoader(true);
      await backend.save(data, get(authValue, 'accessToken', {}));
      
      setUpdatedStreamId("");
      setStreamLabelUpdateMsg("");
      setUpdatedStreamLabelToAll(!updatedStreamLabelToAll);
      const updateStreams = activeStreams.map((stream: any) => {
        if (stream.stream_id === updatedStreamId) {
          stream.stream_label = updatedStreamLabel;
        }
        return stream;
      });
      if(isVCHost) sessionStorage.setItem('stream_label', updatedStreamLabel);
      setActiveStreams(updateStreams);
      leftBarRef.current?.classList.remove('left-bar-temp-width');
    } catch (err: any) {
      const msg = 'Error while updating stream  labe!';
      setUpdatedStreamLabel('')
      setUpdateStreamLabelErrorModal(true);
      console.log(err);
      if (err.errMessage) {
        setStreamLabelUpdateMsg('ERROR: ' + err.errMessage);
      } else {
        setStreamLabelUpdateMsg(msg);
      }
      leftBarRef.current?.classList.add('left-bar-temp-width');
    } finally {
      setUpdateStreamLabelLoader(false);
    }
  };

  const onStreamSubmit = (e: any) => {
    e.preventDefault();
    return false;
  };

  const renderMultiStreams: any = (list: any, currentStream: any) => {
    const updatedList = list.filter((item: any) => item.stream_id !== currentStream.stream_id)
    const multiViews = updatedList.slice(0, 3).map((currentStream: any) => (
      <ShowStreamMultiView
        show_id={showId}
        refreshKey={subStreamKey}
        stream={{
          stream_id: currentStream.stream_id,
          stream_label: currentStream.stream_label
        }}
        iceServers={iceServers}
      />
    ))

    return multiViews;
  }

  // This is show stream component
  const renderStreamContent = () => {
    if (!stream || (stream && isEmpty(stream))) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle, rgba(20,20,20,0.60) 30%, rgba(20,20,20,0) 100%)',
          }}
        >
          <p
            style={{
              fontSize: 16,
              color: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 'bold',
              margin: '0px 0px 4px 0px',
            }}
          >
            Welcome to the show!
          </p>
          <p
            style={{ fontSize: 32, color: 'white', fontWeight: 'bold', margin: '0px 0px 24px 0px' }}
          >
            Select a Stream Room to Start
          </p>
          <p
            style={{ fontSize: 20, color: 'rgba(255, 255, 255, 0.7)', margin: '0px 0px 24px 0px' }}
          >
            Hover over to the left and get it going
          </p>
        </div>
      );
    }
    console.log('**** render showstream')
    if (showMultiStreamViewer && (streamList && streamList.length > 1)) {
      return (
        <>
          <ShowStream
            show_id={showId}
            canvasRef={canvasRef}
            stream={stream}
            iceServers={iceServers}
            refreshKey={streamKey}
            setLoading={setLoading}
            setIsStreamSelected={setIsStreamSelected}
            isReady={videoReady}
            userId={get(auth.getUser(), 'uid', '')}
            guestDisplayName={guestDisplayName}
            isGuestUser={isGuestAuthenticated()}
            showDominantSpeaker={showDominantSpeaker}
            dominantSpeakerID={dominantSpeakerIDRef.current}
            localAudioMuteValue={localAudioMuteRef.current}
            localParticipantID={lParticipantRef.current.sid}
            localParticpantTrack={localParticipantTrackRef.current}
            dominantSpeakerTrack={dominantSpeakerTrackRef.current}
            showStreamUpdate={showStreamUpdateRef.current}
            activeViewers={invitedViewersRef.current}
            volumeMute={volumeMute}
            open={open}
            watermarkPanel={rpstate.watermarkPanel}
            watermarkDetails={watermarkDetails}
            watermarkTrack={watermarkTrack}
            signOut={signOut}
            programaticChange={programaticChange}
            isPublisher={isPublisher}
            canvasMT={canvasMT}
            canvasML={canvasML}
            canvasWidth={canvasWidth}
            leaveChat={leaveChat}
            leaveGuestInvite={leaveGuestInvite}
            leavePublisherPanel={leavePublisherPanel}
            watermarkFeatureIncluded={watermarkFeatureIncluded}
            watermarkChecks={watermarkChecks}
            preview={preview}
            setPreview={setPreview}
            setRPState={setRPState}
            setMillicastView={setMillicastView}
            setActiveStreamExists={setActiveStreamExists}
            setActiveStreamStatus={setActiveStreamStatus}
            isMultiViewerEnabled={showMultiStreamViewer}
            ipAddress={ipAddress}
          />
          <div className="multi-video-container">
            {renderMultiStreams(streamList, stream)}
          </div>
        </>
      )
    }
    return (
      <ShowStream
        show_id={showId}
        canvasRef={canvasRef}
        stream={stream}
        iceServers={iceServers}
        refreshKey={streamKey}
        setLoading={setLoading}
        setIsStreamSelected={setIsStreamSelected}
        isReady={videoReady}
        userId={get(auth.getUser(), 'uid', '')}
        isGuestUser={isGuestAuthenticated()}
        guestDisplayName={guestDisplayName}
        showDominantSpeaker={showDominantSpeaker}
        dominantSpeakerID={dominantSpeakerIDRef.current}
        localAudioMuteValue={localAudioMuteRef.current}
        localParticipantID={lParticipantRef.current.sid}
        localParticpantTrack={localParticipantTrackRef.current}
        dominantSpeakerTrack={dominantSpeakerTrackRef.current}
        showStreamUpdate={showStreamUpdateRef.current}
        activeViewers={invitedViewersRef.current}
        volumeMute={volumeMute}
        open={open}
        watermarkPanel={rpstate.watermarkPanel}
        watermarkDetails={watermarkDetails}
        watermarkTrack={watermarkTrack}
        signOut={signOut}
        programaticChange={programaticChange}
        isPublisher={isPublisher}
        canvasMT={canvasMT}
        canvasML={canvasML}
        canvasWidth={canvasWidth}
        leaveChat={leaveChat}
        leaveGuestInvite={leaveGuestInvite}
        leavePublisherPanel={leavePublisherPanel}
        watermarkFeatureIncluded={watermarkFeatureIncluded}
        watermarkChecks={watermarkChecks}
        preview={preview}
        setPreview={setPreview}
        setRPState={setRPState}
        setMillicastView={setMillicastView}
        setActiveStreamExists={setActiveStreamExists}
        setActiveStreamStatus={setActiveStreamStatus}
        isMultiViewerEnabled={showMultiStreamViewer}
        ipAddress={ipAddress}
      />
    );
  };

  const renderBodyContent = () => {

    let videoTilesProps = {
      overlay,
      overlayMsg,
      overlayConnected,
      overlayMsgConnected,
      overlayDisconnected,
      overlayMsgDisconnected,
      layoutMode,
      troom
    };

    let sidemenuProps = {
      activeStreams,
      handleLogoClick,
      isGuestViewer,
      setShowStreamMenu,
      setStreamMenuActive,
      streamLabel,
      updatedStreamId,
      onStreamSubmit,
      updatedStreamLabel,
      setUpdatedStreamLabel,
      setStreamLabelUpdateMsg,
      setUpdatedStreamId,
      handleUpdateStreamLabel,
      streamLabelUpdateMsg,
      showId,
      show_name,
      show_code,
      setStreamLabel,
      setStream,
      setStreamKey,
      fetchVCRoomAndStartOrJoin,
      layoutMode,
      rpstate,
      setRPState,
      endorLeaveConferenceLoading,
      fetchStreamListLoader,
      isUserInvited,
      updateStreamLabelLoader,
      updateStreamLabelErrorModal,
      setUpdateStreamLabelErrorModal
    };
    let headerProps = {
      handleLogoClick,
      renderHeaderTopVCControls,
      renderHeaderRightNavControls,
      showPublisherPanel: rpstate.showPublisherPanel,
      troom,
      usersUpdated,
      setUsersUpdated,
      roomParticipantsListRef,
      roomParticipantsConnectionStatusRef,
      setGuestUsersList,
      showGuestInvite: rpstate.showGuestInvite,
      watermarkPanel: rpstate.watermarkPanel,
    };

    let chatProps = {
      setExportChartConfirmationOpen,
      fileUploadLoading,
      chatClearLoading,
      setClearChatModalOpen,
      ProgressLoader,
      messagesRef,
      user_imageurl,
      editPencil,
      showEmoji,
      handleChatCopy,
      setInput,
      setShowEmoji,
      sendMessage,
      isModalOpen,
      input,
      typingInd,
      hideGiphyModal,
      pubnub,
      channels,
      setUploadAlert,
      setFileName,
      setUploadFiles,
      resizeCanvas,
      uploadFiles,
      chatInputFieldRef,
      guestDisplayName
    };

    let annotationChatProps = {
      isVCHost,
      user_imageurl,
      annotateMessagesRef,
      isModalOpen,
      hideGiphyModal,
      handleChatCopy,
      annotateChatClearLoading,
      typingInd,
      downloadAnnotateMessages,
      VFXcolor,
      SFXcolor,
      MXcolor,
      PIXcolor,
      annotateLoading,
      clearAnnotationChat,
      editingAnnotateMessageId,
      onChangeAnnotateEditMessage,
      annotateInput,
      editAnnotateInput,
      setEditAnnotateMessage,
      setEditingAnnotateMessageId,
      setAnnotateEditInput,
      pubnub,
      channels,
      handleAnnotateEditMessageClick,
      editAnnotateMessage,
      onChangeAnnotateMessage,
      sendAnnotationMessage,
      setAnnotateInput,
      setModalAnnotateColorOpen
    }

    return (
      <div className='show-new-viewer'>
        {/* <div> parent has to have position fixed, child only renders content, and the hover triggers the parent width and child content full extend </div>  */}
        <Sidemenu {...sidemenuProps} />
        <div className='main-content'>
          <HeaderView {...headerProps} />
          <div className='content-row'>
            <div
              className={`viewer-column ${layoutMode === 'inverse' ? 'inverse-layout' : ''} 
            ${rpstate.chatWindow ? 'viewer-column--with-chat' : ''} 
            ${rpstate.showPublisherPanel ? 'viewer-column--with-publisher-panel' : ''}
            ${rpstate.annotationChatWindow ? 'viewer-column--with-locators-for-avid' : ''}
            ${rpstate.showGuestInvite ? 'viewer-column--with-guest-viewer' : ''}
            ${rpstate.watermarkPanel ? 'viewer-column--with-watermarkPanel' : ''}`}
            >
              <div className='player-wrapper'>{renderStreamContent()}</div>
              {!stream || (stream && isEmpty(stream)) ? (
                <div className='empty-video-tiles-placeholder'></div>
              ) : (
                <VideoTiles {...videoTilesProps} />
              )}
            </div>
            {rpstate.encoder && <Encoder show_id={show_id} />}
            {rpstate.chatWindow && (
              <div className='chat-wrapper'>
                <ChatMenu {...chatProps} />
              </div>
            )}
            {/* {renderAnnotationChatWindowFrmHeader()} */}
            {
              rpstate.annotationChatWindow && (
                <AnnotationChat {...annotationChatProps} />
              )
            }
            {rpstate.showPublisherPanel && (
              <PublisherPanel
                troom={troom}
                usersUpdated={usersUpdated}
                setUsersUpdated={setUsersUpdated}
                roomParticipants={roomParticipantsListRef.current}
                roomParticipantsConnectionStatus={roomParticipantsConnectionStatusRef.current}
              />
            )}
            {rpstate.showGuestInvite && (
              <GuestInvite
                key={'GuestInvite'}
                handleAutoAllow={handleAutoAllow}
                auto_allow={sessionStorage.getItem('auto_allow')}
                troom={troom}
                setRedIcon={setRedIcon}
                setGuestStatusCheck={setGuestStatusCheck}
                guestStatusCheck={guestStatusCheck}
                feNotification={feNotification}
                setFeNotification={setFeNotification}
                globalGuestAutoAllow = {globalGuestAutoAllow}
                lockedList={lockedList}
                updated={updated}
                updateAutoAllowAtStreamLevel={updateAutoAllowAtStreamLevel}
              />
            )}
            {rpstate.watermarkPanel && watermarkFeatureIncluded && (
              <Watermark
                troom={troom}
                showId={showId}
                usersUpdated={usersUpdated}
                setUsersUpdated={setUsersUpdated}
                setWatermarkDetails={setWatermarkDetails}
                watermarkDetails={watermarkDetails}
                dataTrackRef={dataTrackRef}
                updateWatermarkHandler={updateWatermarkHandler}
                setProgramaticChange={setProgramaticChange}
                watermarkChecks={watermarkChecks}
                setWatermarkChecks={setWatermarkChecks}
                preview={preview}
                setPreview={setPreview}
                isGuestInviteIncluded={isGuestInviteIncluded}
              />
            )}
            {rpstate.hudStatsDisplay && (
              <HudStats
              handleCloseHudStatsDisplayPanel={handleCloseHudStatsDisplayPanel}
              hudStatsDisplay={rpstate.hudStatsDisplay}
              statsModalOpen={statsModalOpen}
              setStatsModalOpen={setStatsModalOpen}
              millicastView={millicastView}
              activeStreamExists={activeStreamExists}
              />
            )}
          </div>
        </div>

        <audio className='audio-element'>
          <source src={chimeAudioUrl}></source>
        </audio>
      </div>
    );
  };

  /*
   **************************
   * Header Right Nav Actions
   **************************
   */

  const handleChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    // setChatWindow(true);
    // setCloseDisable(true);
    // setChatDisable(false);

    // setUsers(false);
    // setCloseUsers(false);
    // setOpenUsers(true);
    // setAnnotationChatWindow(false);
    // setAnnotationCloseDisable(false);
    // setAnnotationChatDisable(true);
    // setShowPublisherPanel(false);
    // setShowPublisherPanelCloseDisable(false);
    // setShowGuestInvite(false);
    // setShowGuestInviteCloseDisable(false);
    // setwatermarkPanel(false);
    // resizeCanvas();
    // setWatermarkDetails(watermarkDetails);
    
    setRPState((prevState: any) => ({
      ...prevState,
      chatWindow: true,
      closeDisable: true,
      chatDisable: false,
      users: false,
      closeUsers: false,
      openUsers: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
    setWatermarkDetails(watermarkDetails);
  };

  const handleCloseChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
  };
  const handleCloseEncoderWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      encoder: false
    }));
    resizeCanvas();
  };

  const onTimecodePopupConfirm = async () => {
    setTimeCodePopup(false);
  };

  const handleAnnotationChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    let showTimeCodePopup = sessionStorage.getItem('showTimeCodePopup');
    if (!showTimeCodePopup) {
      sessionStorage.setItem('showTimeCodePopup', 'yes');
      setTimeCodePopup(true);
    }
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: true,
      annotationCloseDisable: true,
      annotationChatDisable: false,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
  };

  const handlePublisherPanelWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: true,
      showPublisherPanelCloseDisable: true,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
    setWatermarkDetails(watermarkDetails);
  };
  const handleClosePublisherPanelWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
  };
  const handleGuestInviteWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: true,
      showGuestInviteCloseDisable: true,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
    setWatermarkDetails(watermarkDetails);
  };
  const handleGuestInviteCloseWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
  };

  const handleAnnotationCloseChatWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      watermarkPanel: false,
      encoder: false
    }));
  
    resizeCanvas();
  };

  const handleWatermarkPanelWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      watermarkPanel: true,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      encoder: false
    }));
  
    resizeCanvas();
  };

  const handleCloseWatermarkPanelWindow = async () => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      annotationChatWindow: false,
      annotationChatDisable: true,
      annotationCloseDisable: false,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      watermarkPanel: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      encoder: false
    }));
  
    resizeCanvas();
  };

  const handleEncoderWindow = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      chatWindow: false,
      closeDisable: false,
      chatDisable: true,
      users: false,
      closeUsers: false,
      openUsers: true,
      annotationChatWindow: false,
      annotationCloseDisable: false,
      annotationChatDisable: true,
      showPublisherPanel: false,
      showPublisherPanelCloseDisable: false,
      showGuestInvite: false,
      showGuestInviteCloseDisable: false,
      watermarkPanel: false,
      encoder: true
    }));
  
    resizeCanvas();
  };

  const handleHudStatsDisplayPanel = async (event: React.MouseEvent<HTMLElement>) => {
    setRPState((prevState) => ({
      ...prevState,
      hudStatsDisplay: true,
    }));
    setStatsModalOpen(true);
    resizeCanvas();
  }

  const handleCloseHudStatsDisplayPanel = async () => {
    setRPState((prevState) => ({
      ...prevState,
      hudStatsDisplay: false,
    }));
    resizeCanvas();
  }

  /*
   **************************
   * Header Actions
   **************************
   */

  const handleLogoClick = async () => {
    setLoading(true);
    handleCloseWatermarkPanelWindow();
    await handelVideoConfEnd();
    setLoading(false);
    const user = auth.getUser();
    const uid = get(user, 'uid', '');
    navigateToPage('/showselection', { state: { userId: uid } });
  };

  const gotoPublisher = async () => {
    const stream_id = sessionStorage.getItem('stream_id');
    const stream_label = sessionStorage.getItem('stream_label');
    navigateToPage('/showpublisher', { state: { show_id, stream_id, stream_label } });
  };

  const gotoTeamAdmin = async () => {
    navigateToPage('/teamadmin', { state: { show_id, team_admin } });
  };

  const profile = async () => {
    const user = auth.getUser();
    const uid = get(user, 'uid', null);
    navigateToPage('/profile', { state: { show_id, userId: uid } });
  };

  const resetPassword = async () => {
    const user = auth.getUser();
    const uid = get(user, 'uid', null);
    navigateToPage('/resetpassword', { state: { show_id, userId: uid , from:'/showviewer'} },true);
  };

  const sendAlertEmail = async () => {
    try {
      const input = {
        showId: showId,
        streamId: stream.stream_id,
      };
      const sendEmail = {
        api: api.notification.tamperEmail,
        payLoad: JSON.stringify(input),
      };
      const result = await backend.save(sendEmail, get(authValue, 'accessToken', {}));
      return result;
    } catch (err: any) {
      console.log('err', err);
      return [];
    }
  };

  const signOut = async (watermarkFlag?: string) => {
    try {
      setLoading(true);
      handleCloseWatermarkPanelWindow();
      await handelVideoConfEnd(true);

      if (pubnub && !isEmpty(pubnub)) {
        pubnub.unsubscribeAll();
      }
      if (!!watermarkFlag && !!watermarkFeatureIncluded) {
        await sendAlertEmail();
      }
      await auth.doSignOut();
      clearCancelNoise();
      setLoading(false);
    } catch (err: any) {
      console.log(err);
    } finally {
      navigateToPage('/logoutredirect', {replace : true},true);
      setLoading(false);
    }
  };

  const hideGiphyModal = function () {
    setModalStatus(false);
  };

  const updateUserChimePref = async () => {
    try {
      const data: any = { api: '', payLoad: '' };
      data.api = api.users.edit;

      if (user_chime_preferance) {
        //User Chime is ON, make it OFF
        data.payLoad = JSON.stringify({ user_chime_preferance: CHIME_VALUES.CHIME_OFF });
        setUserChimePreferance(false);
      } else {
        //User Chime is OFF, make it ON
        data.payLoad = JSON.stringify({ user_chime_preferance: CHIME_VALUES.CHIME_ON });
        setUserChimePreferance(true);
      }
      await backend.save(data, get(authValue, 'accessToken', {}));
    } catch (err: any) {
      console.error(err);
    }
  };
  const renderHeaderAccountSettings = () => {
    let style = null;
    const isGuestUser = isGuestAuthenticated();
    return (
      <li className="account-settings">
        <Dropdown className='right-header-image' style={style} pointing='top right' icon={(<ImageCanvas imageUrl={user_imageurl}  className={'profile-image-property'}/>)}>
          <Dropdown.Menu>
            {!isGuestUser && team_admin ? (
              <Dropdown.Item
                text='Go to Team Admin'
                icon='user circle outline'
                onClick={gotoTeamAdmin}
              />
            ) : !isGuestUser && isVCHost ? (
              <Dropdown.Item
                text='Go to Publisher'
                icon='user circle outline'
                onClick={gotoPublisher}
              />
            ) : (
              ''
            )}
            {!showMultiStreamViewer && (
              <Dropdown.Item
                text='Media Stats'
                icon='info circle'
                onClick={handleHudStatsDisplayPanel}
              />
            )}
            {!isGuestUser && (
              <Dropdown.Item
                text='Chime Sound'
                icon={user_chime_preferance ? 'microphone' : 'microphone slash'}
                onClick={updateUserChimePref}
              />
            )}
            {/* {!isGuestUser && (
              <Dropdown.Item onClick={handleDND}>
                <img className='dnd-icon' src={isDND ? dndInactiveIcon : dndActiveIcon} alt='' />
                {isDND ? 'Deactivate DND' : 'Do Not Disturb'}
              </Dropdown.Item>
            )} */}

            {!isGuestUser && showDominantSpeaker ? (
              <Dropdown.Item onClick={showStreamContent}>
                <img className='dominant-speaker-icon' src={hideDominantSpeakerIcon} alt='' />
                Hide Speaker Viewer
              </Dropdown.Item>
            ) : (
              !isGuestUser && (
                <Dropdown.Item onClick={showDominantSpeakerView}>
                  <img className='dominant-speaker-icon' src={showDominantSpeakerIcon} alt='' />
                  Show Speaker Viewer
                </Dropdown.Item>
              )
            )}
            {!isGuestUser && (
              <Dropdown.Item text='Account Settings' icon='user circle outline' onClick={profile} />
            )}
            {/* <Dropdown.Item text="Help" icon="help circle" /> */}
            {!isGuestUser && (
              <Dropdown.Item text='Reset Password' icon='key' onClick={resetPassword} />
            )}
            <Dropdown.Item
              text='Log Out'
              icon='power'
              onClick={
                !isGuestUser
                  ? () => {
                      signOut();
                    }
                  : removeGuestUser
              }
            />
          </Dropdown.Menu>
        </Dropdown>
      </li>
    );
  };

  const removeGuestUser = async () => {
    const gidentity = troom?.localParticipant?.identity;
    const dataTrack = troom?.localParticipant?.dataTracks.values().next().value;
    let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
    allowedGuestSession =
      typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession;

    if (!!allowedGuestSession) {
      // Remove the guest user from session
      //console.log("remove guest2",gidentity)
      delete allowedGuestSession[gidentity];
      sessionStorage.setItem(
        'allowedGuest',
        typeof allowedGuestSession === 'string'
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession),
      );
      dataTrack.track.send(
        JSON.stringify({
          allowedGuestSession,
        }),
      );
    }
    signOutGl();
  };

  const signOutGl = async () => {
    let allowedGuestSession = sessionStorage.getItem('allowedGuest');
    updateGuestSession(
      typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession,
    );
    sessionStorage.removeItem('allowedGuest');
    handleCloseWatermarkPanelWindow();
    handelVideoConfEnd();
    logOutGuestUser();

    sessionStorage.removeItem('auto_allow');
    sessionStorage.removeItem('gt');
    sessionStorage.removeItem('guestAttached');
    navigateToPage('/logoutredirect', {state:{isGuest:true},replace : true},true);
    
  };

  const renderMiniHeaderIcons = () =>{
    return(
      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', gap:'5px'}}>
      
      {!!rpstate.encoder ? (
        <div className='miniheader-btn miniheader-btn-right'>
              <a id='chatArea' className='active' onClick={handleCloseEncoderWindow}>
                <img className='closeinvite' src={closeImg} alt='' style={{width:'25px', height:'25px'}}/>
              </a>
              </div>
            ) : (
              <div className='miniheader-btn miniheader-btn-right'>
              <a id='encoder' onClick={handleEncoderWindow}>
                <img className='transmission_img' src={transmission} alt='transmission' style={{width:'25px', height:'25px'}}/>
              </a></div>
            )}
    
     
      {rpstate.annotationChatDisable && isTimeCodeFeatureIncluded && (
          <li> <div className='miniheader-btn miniheader-btn-right'>
            <a id='annotationChatArea' onClick={handleAnnotationChatWindow}>
              <img className='tc_image' src={tc_Img} alt='TC' style={{width:'25px', height:'25px'}}/>
            </a>
            </div>
          </li>
        )}
      
    
      {rpstate.annotationCloseDisable && (
          <li>
              <div className='miniheader-btn miniheader-btn-right'>
            <a id='annotationChatArea' className='active' onClick={handleAnnotationCloseChatWindow}>
              <img className='closeinvite' src={closeImg} alt='' style={{width:'25px', height:'25px'}}/>
            </a></div>
          </li>
        )}
      
      
      {rpstate.chatDisable && (
          <li>
            <div className='miniheader-btn miniheader-btn-right'>
            <a id='chatArea' onClick={handleChatWindow}>
              <img className='tc_image' src={chatImg} alt='' style={{width:'25px', height:'25px'}}/>
            </a> 
            </div>
          </li>
        )}
       
        
        {rpstate.closeDisable && (
          <li>
            <div className='miniheader-btn miniheader-btn-right'>
            <a id='chatArea' className='active' onClick={handleCloseChatWindow}>
              <img className='closeinvite' src={closeImg} alt='' style={{width:'25px', height:'25px'}}/>
            </a></div>
          </li>
        )}
          
          
        {isVCHost && !isGuestViewer && isPublisher && isGuestInviteIncluded && (
          <li>
             <div className='miniheader-btn miniheader-btn-right'>
            <a
              id='guestInviteArea'
              className={
                rpstate.showGuestInviteCloseDisable ? 'active' : !chatLoaded ? 'disabled' : ''
              }
              onClick={
                rpstate.showGuestInviteCloseDisable
                  ? handleGuestInviteCloseWindow
                  : handleGuestInviteWindow
              }
            >
              {!redIcon ? (
                <img
                  className={rpstate.showGuestInviteCloseDisable ? 'closeinvite' : 'tc_image'}
                  src={rpstate.showGuestInviteCloseDisable ? closeImg : guestWhite}
                  alt=''
                  style={{width:'25px', height:'25px'}}
                />
              ) : rpstate.showGuestInviteCloseDisable ? (
                <img className={'closeinvite'} src={closeImg} alt=''  style={{width:'25px', height:'25px'}}/>
              ) : (
                <div className={'pulsating-icon'}></div>
              )}
            </a></div>
          </li>
        )}
         
        
        {isVCHost && isPublisher && (
          <li> <div className='miniheader-btn miniheader-btn-right'>
            <a
              id='publisherPanelArea'
              className={
                rpstate.showPublisherPanelCloseDisable ? 'active' : !chatLoaded ? 'disabled' : ''
              }
              onClick={
                rpstate.showPublisherPanelCloseDisable
                  ? handleClosePublisherPanelWindow
                  : handlePublisherPanelWindow
              }
            >
              <img
                className={rpstate.showPublisherPanelCloseDisable ? 'closeinvite' : 'tc_image'}
                src={rpstate.showPublisherPanelCloseDisable ? closeImg : group}
                alt=''
                style={{width:'25px', height:'25px'}}
              />
            </a></div>
          </li>
        )}
       
        {isVCHost && isPublisher && watermarkFeatureIncluded && (
          <li> <div className='miniheader-btn miniheader-btn-right'>
            <a
              id='watermarkPanelArea'
              className={rpstate.watermarkPanel ? 'active' : !chatLoaded ? 'disabled' : ''}
              onClick={
                rpstate.watermarkPanel
                  ? handleCloseWatermarkPanelWindow
                  : handleWatermarkPanelWindow
              }
            >
              <img
                className={rpstate.watermarkPanel ? 'closeinvite' : 'tc_image'}
                src={rpstate.watermarkPanel ? closeImg : watermarkIcon}
                alt=''
                style={{width:'25px', height:'25px'}}
              />
            </a></div>
          </li>
        )}
        
      </div>
    )
  }


  const renderRightNavVCControls = () => {
    if (isEmpty(stream)) {
      return;
    }
    return (
      <Fragment>
        {isVCHost && isPublisher && encoderFeatureIncluded && (
          <li>
            {!!rpstate.encoder ? (
              <a id='chatArea' className='active' onClick={handleCloseEncoderWindow}>
                <img className='closeinvite' src={closeImg} alt='' />
              </a>
            ) : (
              <a id='encoder' onClick={handleEncoderWindow}>
                <img className='transmission_img' src={transmission} alt='transmission' />
              </a>
            )}
          </li>
        )}

        {rpstate.annotationChatDisable && isTimeCodeFeatureIncluded && (
          <li>
            <a id='annotationChatArea' onClick={handleAnnotationChatWindow}>
              <img className='tc_image' src={tc_Img} alt='TC' />
            </a>
          </li>
        )}

        <ConfirmationalPopup
          open={timeCodePopup}
          data={'This is a BETA feature, are you sure you want to enable timecode capture ?'}
          onCancel={setTimeCodePopup(false)}
          onOk={onTimecodePopupConfirm}
        />

        {rpstate.annotationCloseDisable && (
          <li>
            <a id='annotationChatArea' className='active' onClick={handleAnnotationCloseChatWindow}>
              <img className='closeinvite' src={closeImg} alt='' />
            </a>
          </li>
        )}
        {rpstate.chatDisable && (
          <li>
            <a id='chatArea' onClick={handleChatWindow}>
              <img className='tc_image' src={chatImg} alt='' />
            </a>
          </li>
        )}
        {rpstate.closeDisable && (
          <li>
            <a id='chatArea' className='active' onClick={handleCloseChatWindow}>
              <img className='closeinvite' src={closeImg} alt='' />
            </a>
          </li>
        )}
        {isVCHost && !isGuestViewer && isPublisher && isGuestInviteIncluded && (
          <li>
            <a
              id='guestInviteArea'
              className={
                rpstate.showGuestInviteCloseDisable ? 'active' : !chatLoaded ? 'disabled' : ''
              }
              onClick={
                rpstate.showGuestInviteCloseDisable
                  ? handleGuestInviteCloseWindow
                  : handleGuestInviteWindow
              }
            >
              {!redIcon ? (
                <img
                  className={rpstate.showGuestInviteCloseDisable ? 'closeinvite' : 'tc_image'}
                  src={rpstate.showGuestInviteCloseDisable ? closeImg : guestWhite}
                  alt=''
                />
              ) : rpstate.showGuestInviteCloseDisable ? (
                <img className={'closeinvite'} src={closeImg} alt='' />
              ) : (
                <div className={'pulsating-icon'}></div>
              )}
            </a>
          </li>
        )}
        {isVCHost && isPublisher && (
          <li>
            <a
              id='publisherPanelArea'
              className={
                rpstate.showPublisherPanelCloseDisable ? 'active' : !chatLoaded ? 'disabled' : ''
              }
              onClick={
                rpstate.showPublisherPanelCloseDisable
                  ? handleClosePublisherPanelWindow
                  : handlePublisherPanelWindow
              }
            >
              <img
                className={rpstate.showPublisherPanelCloseDisable ? 'closeinvite' : 'tc_image'}
                src={rpstate.showPublisherPanelCloseDisable ? closeImg : group}
                alt=''
              />
            </a>
          </li>
        )}
        {isVCHost && isPublisher && watermarkFeatureIncluded && (
          <li>
            <a
              id='watermarkPanelArea'
              className={rpstate.watermarkPanel ? 'active' : !chatLoaded ? 'disabled' : ''}
              onClick={
                rpstate.watermarkPanel
                  ? handleCloseWatermarkPanelWindow
                  : handleWatermarkPanelWindow
              }
            >
              <img
                className={rpstate.watermarkPanel ? 'closeinvite' : 'tc_image'}
                src={rpstate.watermarkPanel ? closeImg : watermarkIcon}
                alt=''
              />
            </a>
          </li>
        )}
      </Fragment>
    );
  };

  const renderHeaderRightNavControls = () => {
    if(get(screens,'xs',false)){
      return <></>
    }
    return (
     
      <ul>
        {/* Header Right Nav VC control */}
        {!showMultiStreamViewer && renderRightNavVCControls()}

        {/* Header Right Account Settings control */}
        {renderHeaderAccountSettings()}
      </ul>
    );
  };

  const renderCameraAndMicrophoneSelection = () => {
    return (
      <div
        onClick={toggleAudioVideo}
        className={
          get(screens, 'xs', false) ? 'miniheader-btn' :
          `top-menu-button-item audiovisual-source-dropdown ${
          showDominantSpeaker ? 'disableddiv' : ''
        }`}
      >
        <img src={configSource} alt='' />
        <Dropdown icon='' open={cameraDropdownOpen} onClose={toggleAudioVideo}>
          <Dropdown.Menu className='cameraMenu'>
            <Dropdown.Item>
              <Dropdown
                className='cameraDropdown'
                id='cameraDropdown'
                text={
                  localVideoMute && cameraName === 'Select Camera' ? 'Camera Disabled' : cameraName
                }
                pointing='left'
                multiple={false}
                fluid
                selection
                placeholder='camera'
                disabled={localVideoMute}
                onChange={changeCamera}
                options={cameraOptions}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <Dropdown
                text={micName}
                id='cameraDropdown'
                placeholder='microphone'
                multiple={false}
                fluid
                selection
                pointing='left'
                onChange={changeMicrophone}
                options={micOptions}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
       {!get(screens, 'xs', false) && <span>Select Camera</span>} 
      </div>
    );
  };

  const handleVolumeControlMouseOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!showVolume) {
      setShowVolume(true);
    }
  };

  const handleVolumeMouseOut = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!!showVolume) {
      setShowVolume(false);
    }
  };

  const handleVolumeDoubleClick = () => {
    setShowVolume(false);
    resetAllTools();
    const elem: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
    elem.muted = !elem.muted;
    setVolumeMute(elem.muted);
    // if (!elem.muted) {
    //   setShowVolume(true);
    // }
  };

  const showStreamContent = () => {
    setShowDominantSpeaker(false);
  };

  const showDominantSpeakerView = () => {
    setShowDominantSpeaker(true);
  };

  const leaveConference = () =>{
    handelVideoConfEnd();
  }

  const renderConferenceSelection = (member?: string) => {
    const toggleTools = () => {
      setShowPopup(!showPopup);
      resetAllTools();
    };
    const isGuest = isGuestAuthenticated();
    return (
      <div className='top-menu-button-item call-options-dropdown' onClick={toggleTools}>
        <img src={phoneFilled} alt='' />
        <Dropdown icon='' open={showPopup} onClose={toggleTools}>
          <Dropdown.Menu className='cameraMenu'>
            {!member && (
              <Dropdown.Item>
                <Button
                  className='ui button'
                  onClick={handelVideoConfEndAll}
                  style={{ backgroundColor: 'white' }}
                >
                  End Conference for All
                </Button>
              </Dropdown.Item>
            )}
            <Dropdown.Item>
              <Button
                className='ui button'
                onClick={!isGuest ? leaveConference : removeGuestUser}
                style={{ backgroundColor: 'white', width: '183px' }}
              >
                Leave Conference
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span>Tools</span>
      </div>
    );
  };

  const renderDrawingOptions = (showDomSpeaker: boolean) => {
    if(showMultiStreamViewer) {
      return;
    }
    return (
      <>
      <Col>
        <div className={get(screens, 'xs', false) ? 'miniheader-btn' :'top-menu-button-item pencil-tool-dropdown'} onClick={onDrawingClick}>
          <img src={pencil} alt='' />
          {/* <i aria-hidden="true" className="dropdown icon" style={{ marginTop: "3px", marginLeft: "5px" }}></i> */}
          <Dropdown icon='' open={showDrawingOption} onClose={onDrawingClick}>
            <Dropdown.Menu className='cameraMenu'>
              <Dropdown.Item>
                <div className={showDominantSpeaker ? 'disableddiv' : ''}>
                  <Button
                    className='ui button'
                    onClick={pencilToggled}
                    style={{
                      backgroundColor: isPencilLine ? 'grey' : 'white',
                      paddingLeft: '70px',
                      paddingRight: '70px',
                    }}
                  >
                    Pencil
                  </Button>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className={showDominantSpeaker ? 'disableddiv' : ''}>
                  <Button
                    className='ui button'
                    onClick={circleToggled}
                    style={{
                      backgroundColor: isPencilCircle ? 'grey' : 'white',
                      paddingLeft: '70px',
                      paddingRight: '70px',
                    }}
                  >
                    Circle
                  </Button>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {!get(screens, 'xs', false) && <span>Annotation</span> }
        </div>
        </Col>
        <Col>
        <div
          className={ get(screens, 'xs', false) ? 'miniheader-btn' :`top-menu-button-item ${
            showDomSpeaker ? 'disableddiv' : isEraser ? 'eraser-on' : ''
          }`}
          onClick={eraserToggled}
        >
          <img src={erase} alt='' />
          {!get(screens, 'xs', false) && <span>Erase</span> }
        </div>
        </Col>
        <Col>
        <div
          onClick={clearWhiteBoardAll}
          className={
            get(screens, 'xs', false) ? 'miniheader-btn' :
            `top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
        >
          <img src={trash} alt='' />
          {!get(screens, 'xs', false) && <span>Clear</span>}
        </div>
        </Col>
      </>
    );
  };

  const toggleHeaderIcons = () => {
    setHeaderIconsModalOpen(prev => !prev); // Function to open the modal
  };

 

// New function to toggle right top options
const toggleRightTopOptions = () => {
  setRightTopOptionsOpen(prev => !prev); // Function to toggle right top options
};

  const renderHeaderTopVCControls = () => {
    const showDomSpeaker = showDominantSpeaker;

    const cancelNoiseButton = hasNoiseCancellation ? (
      <Col>
        <div
          className={get(screens,'xs',false) ? 'miniheader-btn' :`top-menu-button-item noise-cancellation-btn ${
            cancelNoise ? 'noise-cancellation-on' : ''
          }`}
          onClick={() => {
            setCancelNoise(!cancelNoise);
            resetAllTools();
          }}
        >
          <img src={noiseCancellation} alt='' />
          {!get(screens,'xs',false) && <span>Noise Cancellation</span>}
        </div>
      </Col>
    ) : null;

    if (get(screens,'xs',false) && !isEmpty(troom)) {
      return (
        <>
          {!isVCHost && <div>{renderConferenceSelection('member')}</div>}
          {isVCHost && <div>{renderConferenceSelection()}</div>}

          <div
            className={`top-menu-button-item`}
            onClick={toggleHeaderIcons} // Added onClick handler
          >
            <div>
              <RightOutlined style={{ fontSize: 20, color: 'white', paddingTop: '15px' }} />
              {/* <img src={ham} alt='' style={{ filter: 'brightness(1)', paddingTop:'10px' }} /> */}
            </div>
            <span>Toggle Video</span>
          </div>
          <div
            className={`top-menu-button-item`}
            onClick={toggleRightTopOptions} // Added onClick handler
          >
            <div>
              {/* <RightOutlined style={{ fontSize: 20, color: 'white', paddingTop: '10px' }} /> */}
              <img src={ham} alt='' style={{ filter: 'brightness(1)', paddingTop: '20px' }} />
            </div>
            <span>Toggle Video</span>
          </div>
          <div style={{ float: 'right', position: 'fixed', right: '2px' }}>
            {renderHeaderAccountSettings()}
          </div>

          {/* Modal for displaying header icons */}
          <AntdModal
            open={headerIconsModalOpen && get(screens, 'xs', true)} // State to control modal visibility
            // onClose={() => setHeaderIconsModalOpen(false)} // Close modal function
            className='miniheader-modal'
            bodyStyle={{ top: 100, backgroundColor: '#000' }}
            footer={null}
            onOk={() => {
              setHeaderIconsModalOpen(false);
            }}
            onCancel={() => {
              setHeaderIconsModalOpen(false);
            }}
          >
            {!isVCHost && !isEmpty(stream) && (
              <div className='miniheader-modal-wrapper'>
                {/* <Row>                       */}
                <div className='miniheader-modal-row'>
                  <div
                    style={{
                      // position: 'relative',
                      // width: '100%',
                      // display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      height: !!showVolume ? '100%' : '100%',
                    }}
                    className='miniheader-btn'
                    onMouseLeave={handleVolumeMouseOut}
                    onMouseEnter={handleVolumeControlMouseOver}
                  >
                    <img
                      onClick={handleVolumeDoubleClick}
                      className='fullscreen-img'
                      src={!!volumeMute ? volumeMuteIcon : volume}
                      alt=''
                      style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
                    />
                    {!!showVolume && (
                      <div className='volume-control'>
                        <input
                          type='range'
                          min='0'
                          max='100'
                          step='1'
                          value={volumeLevel}
                          style={{
                            background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                          }}
                          onInput={volumeUpDown}
                          onChange={volumeUpDown}
                        />
                      </div>
                    )}
                  </div>

                  <div className='miniheader-btn'>
                    <Reload
                      showDomSpeaker={showDomSpeaker}
                      reloadPage={reloadPage}
                      troom={troom}
                      activeStreamStatus={activeStreamStatus}
                      setActiveStreamStatus={setActiveStreamStatus}
                      isMultiStreamEnabled={showMultiStreamViewer}
                      reloadSubStreams={reloadSubStreams}
                      miniscreen={true}
                    />
                  </div>

                  {renderDrawingOptions(showDomSpeaker)}

                  {/* Audio and Video Controls */}
                  <div
                    onClick={handelAudio}
                    // className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                    className='miniheader-btn'
                  >
                    <img
                      src={!localMute ? microphoneOn : microphoneOff}
                      alt=''
                      style={{ filter: localMute ? 'brightness(1)' : '' }}
                    />
                  </div>
                </div>
                <div className='miniheader-modal-row'>
                  <div
                    onClick={handelVideo}
                    // className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                    className='miniheader-btn'
                  >
                    <img
                      src={!localVideoMute ? cameraOn : cameraOff}
                      alt=''
                      style={{ filter: localVideoMute ? 'brightness(1)' : '' }}
                    />
                  </div>

                  {cancelNoiseButton}
                  <div>{renderCameraAndMicrophoneSelection()}</div>
                  <div onClick={fullScreenVideo} className={`miniheader-btn`}>
                    <img className='fullscreen-img' src={fitToScreen} alt='' />
                  </div>
                  <div
                    className={`miniheader-btn`}
                    onClick={() => setLayoutMode(layoutMode === 'normal' ? 'inverse' : 'normal')}
                  >
                    <img src={changeView} alt='' />
                  </div>
                </div>

                {/* </Row> */}
              </div>
            )}

            {isVCHost && !isEmpty(stream) && (
              <div className='miniheader-modal-wrapper'>
                {/* <Row>                       */}
                <div className='miniheader-modal-row'>
                  <div
                    style={{
                      // position: 'relative',
                      // width: '100%',
                      // display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      height: !!showVolume ? '100%' : '100%',
                    }}
                    className='miniheader-btn'
                    onMouseLeave={handleVolumeMouseOut}
                    onMouseEnter={handleVolumeControlMouseOver}
                  >
                    <img
                      onClick={handleVolumeDoubleClick}
                      className='fullscreen-img'
                      src={!!volumeMute ? volumeMuteIcon : volume}
                      alt=''
                      style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
                    />
                    {!!showVolume && (
                      <div className='volume-control'>
                        <input
                          type='range'
                          min='0'
                          max='100'
                          step='1'
                          value={volumeLevel}
                          style={{
                            background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                          }}
                          onInput={volumeUpDown}
                          onChange={volumeUpDown}
                        />
                      </div>
                    )}
                  </div>

                  <div className='miniheader-btn'>
                    <Reload
                      showDomSpeaker={showDomSpeaker}
                      reloadPage={reloadPage}
                      troom={troom}
                      activeStreamStatus={activeStreamStatus}
                      setActiveStreamStatus={setActiveStreamStatus}
                      isMultiStreamEnabled={showMultiStreamViewer}
                      reloadSubStreams={reloadSubStreams}
                      miniscreen={true}
                    />
                  </div>

                  <div
                    onClick={handleGlobalMute}
                    // className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                    className='miniheader-btn'
                  >
                    <img
                      src={muteAll ? golbalAudioUnMute : golbalAudioMute}
                      alt=''
                      style={{ filter: 'brightness(1)' }}
                    />
                  </div>

                  {renderDrawingOptions(showDomSpeaker)}
                  
                </div>
                <div className='miniheader-modal-row'>
                  <div
                    onClick={handelAudio}
                    // className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                    className='miniheader-btn'
                  >
                    <img
                      src={!localMute ? microphoneOn : microphoneOff}
                      alt=''
                      style={{ filter: localMute ? 'brightness(1)' : '' }}
                    />
                  </div>
                  <div
                    onClick={handelVideo}
                    //  className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                    className='miniheader-btn'
                  >
                    <img
                      src={!localVideoMute ? cameraOn : cameraOff}
                      alt=''
                      style={{ filter: localVideoMute ? 'brightness(1)' : '' }}
                    />
                  </div>
                  {cancelNoiseButton}

                  {renderCameraAndMicrophoneSelection()}
                  <div
                    onClick={fullScreenVideo}
                    // className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                    className='miniheader-btn'
                  >
                    <img className='fullscreen-img' src={fitToScreen} alt='' />
                  </div>
                  <div
                    // className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                    className='miniheader-btn'
                    onClick={() => setLayoutMode(layoutMode === 'normal' ? 'inverse' : 'normal')}
                  >
                    <img src={changeView} alt='' />
                  </div>
                </div>
              </div>
            )}
          </AntdModal>
          <AntdModal
            open={rightTopOptionsOpen} // State to control modal visibility
            // onClose={() => setHeaderIconsModalOpen(false)} // Close modal function
            className='miniheader-modal'
            bodyStyle={{ top: 100, backgroundColor: '#000' }}
            footer={null}
            onOk={() => {
              setRightTopOptionsOpen(false);
            }}
            onCancel={() => {
              setRightTopOptionsOpen(false);
            }}
          >
            {renderMiniHeaderIcons()}
          </AntdModal>
        </>
      );
    }
  

    // Member
    if (!isVCHost) {
      // Show end button if conf is running and user participated
       if (!isEmpty(stream)) {
        return (
          <Row gutter={[10,10]}>
          <Fragment>
            <Col>
              {renderConferenceSelection('member')}
            </Col>
        
            <Col>
              <Reload
                showDomSpeaker={showDomSpeaker}
                reloadPage={reloadPage}
                troom={troom}
                activeStreamStatus={activeStreamStatus}
                setActiveStreamStatus={setActiveStreamStatus}
                isMultiStreamEnabled={showMultiStreamViewer}
                reloadSubStreams={reloadSubStreams}
                miniscreen={false}
              />
            </Col>
        
           
              {renderDrawingOptions(showDomSpeaker)}
           
        
            <Col>
              <div
                className={`top-menu-button-item ${showDomSpeaker ? 'ui disableddiv' : 'ui'}`}
                data-position='bottom center'
              >
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: !!showVolume ? '100%' : '100%',
                  }}
                  onMouseLeave={handleVolumeMouseOut}
                  onMouseEnter={handleVolumeControlMouseOver}
                >
                  <img
                    onClick={handleVolumeDoubleClick}
                    className='fullscreen-img'
                    src={!!volumeMute ? volumeMuteIcon : volume}
                    alt=''
                    style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
                  />
                  {!!showVolume ? (
                    <div className='volume-control'>
                      <input
                        type='range'
                        min='0'
                        max='100'
                        step='1'
                        value={volumeLevel}
                        style={{
                          background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                        }}
                        onInput={(event) => volumeUpDown(event)}
                        onChange={(event) => volumeUpDown(event)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <span className={'volumeSpan'}>Volume</span>
              </div>
            </Col>
        
            {cancelNoiseButton}
        
            <Col>
              <div
                onClick={handelAudio}
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
              >
                {!localMute ? (
                  <>
                    <img src={microphoneOn} alt='' />
                  </>
                ) : (
                  <>
                    <img src={microphoneOff} alt='' style={{ filter: 'brightness(1)' }} />
                  </>
                )}
                <span>Toggle Mic</span>
              </div>
            </Col>
        
            <Col>
              <div
                onClick={handelVideo}
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
              >
                {!localVideoMute ? (
                  <>
                    <img src={cameraOn} alt='' />
                  </>
                ) : (
                  <>
                    <img src={cameraOff} alt='' style={{ filter: 'brightness(1)' }} />
                  </>
                )}
                <span>Toggle Video</span>
              </div>
            </Col>
        
            <Col>
              <Fragment>{renderCameraAndMicrophoneSelection()}</Fragment>
            </Col>
        
            <Col>
              <div
                onClick={fullScreenVideo}
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
              >
                <img className='fullscreen-img' src={fitToScreen} alt='' />
                <span>FullScreen Video</span>
              </div>
            </Col>
        
            <Col>
              <div
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                onClick={() => {
                  setLayoutMode(layoutMode === 'normal' ? 'inverse' : 'normal');
                }}
              >
                <img src={changeView} alt='' />
                <span>Invert Layout</span>
              </div>
            </Col>
          </Fragment>
        </Row>
        
        );
      }
    }

    // Admin
    if (isVCHost) {
      // Show end button if conf is running
       if (!isEmpty(stream)) {
        // const sId = {localParticipant.sid}+"_audio";
        return (
          <Row gutter={[10,10]}>
          <Fragment>
            <Col>
              {renderConferenceSelection()}
            </Col>
        
            <Col>
              <Reload
                showDomSpeaker={showDomSpeaker}
                reloadPage={reloadPage}
                troom={troom}
                activeStreamStatus={activeStreamStatus}
                setActiveStreamStatus={setActiveStreamStatus}
                isMultiStreamEnabled={showMultiStreamViewer}
                reloadSubStreams={reloadSubStreams}
                miniscreen={false}
              />
            </Col>
        
            <Col>
              <div
                onClick={handleGlobalMute}
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
              >
                {muteAll ? (
                  <>
                    <img src={golbalAudioUnMute} alt='' style={{ filter: 'brightness(1)' }} />
                    <span>Unmute All</span>
                  </>
                ) : (
                  <>
                    <img src={golbalAudioMute} alt='' />
                    <span>Mute All</span>
                  </>
                )}
              </div>
            </Col>
        
            
              {renderDrawingOptions(showDomSpeaker)}
            
        
            <Col>
              <div
                className={`top-menu-button-item ${showDomSpeaker ? 'ui disableddiv' : 'ui'}`}
                data-position='bottom center'
              >
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: !!showVolume ? '100%' : '100',
                  }}
                  onMouseLeave={handleVolumeMouseOut}
                  onMouseEnter={handleVolumeControlMouseOver}
                >
                  <img
                    onClick={handleVolumeDoubleClick}
                    className='fullscreen-img'
                    src={!!volumeMute ? volumeMuteIcon : volume}
                    alt=''
                    style={{ filter: !!volumeMute ? 'brightness(1)' : '' }}
                  />
                  {!!showVolume ? (
                    <div className='volume-control'>
                      <input
                        type='range'
                        min='0'
                        max='100'
                        step='1'
                        value={volumeLevel}
                        style={{
                          background: `linear-gradient(to right, #E5E5E5 0%, #E5E5E5 ${volumeLevel}%, #282829  ${volumeLevel}%, #282829 100%)`,
                        }}
                        onInput={(event) => volumeUpDown(event)}
                        onChange={(event) => volumeUpDown(event)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <span className={'volumeSpan'}>Volume</span>
              </div>
            </Col>
        
            {cancelNoiseButton}
        
            <Col>
              <div
                onClick={handelAudio}
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
              >
                {!localMute ? (
                  <>
                    <img src={microphoneOn} alt='' />
                  </>
                ) : (
                  <>
                    <img src={microphoneOff} alt='' style={{ filter: 'brightness(1)' }} />
                  </>
                )}
                <span>Toggle Mic</span>
              </div>
            </Col>
        
            <Col>
              <div
                onClick={handelVideo}
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
              >
                {!localVideoMute ? (
                  <>
                    <img src={cameraOn} alt='' />
                  </>
                ) : (
                  <>
                    <img src={cameraOff} alt='' style={{ filter: 'brightness(1)' }} />
                  </>
                )}
                <span>Toggle Video</span>
              </div>
            </Col>
        
            <Col>
              <Fragment>{renderCameraAndMicrophoneSelection()}</Fragment>
            </Col>
        
            <Col>
              <div
                onClick={fullScreenVideo}
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
              >
                <img className='fullscreen-img' src={fitToScreen} alt='' />
                <span>FullScreen Video</span>
              </div>
            </Col>
        
            <Col>
              <div
                className={`top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
                onClick={() => {
                  setLayoutMode(layoutMode === 'normal' ? 'inverse' : 'normal');
                }}
              >
                <img src={changeView} alt='' />
                <span>Invert Layout</span>
              </div>
            </Col>
          </Fragment>
        </Row>
        
        );
      }
    }
  };

  const FallbackComponent = () => {
    return <div className='not-found'>Sorry there was a problem !</div>;
  };
  const myFallback = <FallbackComponent />;

  const annotationChatModalProps = {
    VFXcolor,
    SFXcolor,
    MXcolor,
    PIXcolor,
    setVFXcolor,
    setSFXcolor,
    setMXcolor,
    setPIXcolor,
    modalAnnotateColorOpen, 
    setModalAnnotateColorOpen
  }
  return (
    <Row>
      <Col>
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      {!!dataTrack && !!showDiagnostics && (
        <Diagnostics iceServers={iceServers} setShowDiagnostics={setShowDiagnostics} />
      )}
      <FullScreen handle={fullScreenHandler}>
        <Spin spinning={!!gLoader} size={'large'} indicator={loaderIcon}>
          <div
            className='show-viewer-main'
            id='show-viewer-main'
            onContextMenu={(event) => event.preventDefault()}
          >
            {/* <ProgressLoader loading={loading} /> */}

            {/* Header */}
            {/* {renderHeader()} */}

            {/* Body */}
            {renderBodyContent()}

            <GuestWaitingModal
              open={open}
              onClose={() => setOpen(false)}
              streamName={streamLabel}
              publisherName={sessionStorage.getItem('pubname') || 'publisher'}
              onLeave={() => {
                signOutGl();
              }}
            />
            <Modal
              open={clearChatModalOpen}
              size={'tiny'}
              onClick={() => setClearChatModalOpen(false)}
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>Clear Chat</Modal.Header>
              <Modal.Content>You are about to clear the chat messages</Modal.Content>
              <Modal.Actions>
                <Button color='green' onClick={() => clearChat()}>
                  OK
                </Button>
                <Button color='grey' onClick={() => setClearChatModalOpen(false)}>
                  CANCEL
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              open={exportChartConfirmationOpen}
              size={'tiny'}
              onClick={() => setExportChartConfirmationOpen(false)}
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>Export Chat</Modal.Header>
              <Modal.Content>Are you sure you want to export the chat log</Modal.Content>
              <Modal.Actions>
                <Button color='green' onClick={downloadMessages}>
                  YES
                </Button>
                <Button color='grey' onClick={() => setExportChartConfirmationOpen(false)}>
                  CANCEL
                </Button>
              </Modal.Actions>
            </Modal>
             <AnnotationChatModal {...annotationChatModalProps}/>

            <Modal
              open={localParticipantBGModalOpen}
              size={'tiny'}
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>Choose Virtual Background</Modal.Header>
              <Modal.Content>
                <div className='background-selector-wrapper'>
                  {backgrounds.map((item: any) => {
                    return (
                      <div
                        className={`background-selector ${
                          tempActiveBG === item.value ? 'active' : ''
                        }`}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setTempActiveBG(item.value);
                        }}
                      >
                        <img src={item.thumbImage} alt={item.value} />
                      </div>
                    );
                  })}
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color='green'
                  onClick={() => {
                    const tempActiveBGimg = tempActiveBG;
                    setActiveBG(tempActiveBGimg);
                    setLocalParticipantBGModalOpen(false);
                    changeCameraTrack(cameraIdRef.current);
                  }}
                >
                  APPLY
                </Button>
                <Button
                  color='grey'
                  onClick={() => {
                    setLocalParticipantBGModalOpen(false);
                  }}
                >
                  CANCEL
                </Button>
              </Modal.Actions>
            </Modal>
            <ConfirmationalPopup
              open={uploadAlert}
              data={`Are you sure you want to upload ${fileName} ?`}
              onCancel={handleUploadCancel}
              onOk={handleUploadConfirm}
            />            
          </div>
        </Spin>
      </FullScreen>
    </Sentry.ErrorBoundary>
    </Col>
    </Row>
  );
};

export default NewShowViewerDashboard;
