import { useEffect, useState, useRef } from 'react';
import { Alert } from 'antd';
import { CounterMessageProps } from './structs';

export default function CounterMsg({ message, type, counter }: CounterMessageProps) {
  const [seconds, setSeconds] = useState(counter);
  const timeId = useRef<NodeJS.Timeout | null>();
  const setTimer = () => {
    timeId.current = setInterval(() => {
      setSeconds((prevTime) => prevTime - 1);
    }, 1000);
  };
  useEffect(() => {
    setTimer();
    return () => {
      if (timeId.current) clearInterval(timeId.current);
    };
  }, []);
  return <Alert description={`${message} in ${seconds} seconds.`} type={type} />;
}
