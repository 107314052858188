import React, { useState, useEffect, useRef, useContext } from "react";
import algoliasearch from "algoliasearch/lite";

import { Layout } from "../PageLayout/Layout";

import CreatePublisherViewer from "../../Containers/ManageShowUsers/CreatePublisherViewer";
import EditPublisherViewer from "../../Containers/ManageShowUsers/EditPublisherViewer";
import EditPublisherViewerNew from "../../Containers/ManageShowUsers/EditPublisherViewer/EditPublisherViewerNew";
import ListOfShowUsers from "../../Containers/ListOfShowUsers";
import InvitedUsersList from "../../Containers/InvitedShowUsersList";
import ListOfShowStreams from "../../Containers/ListOfShowStreams";
import Subscribe from "../../Containers/Subscribe";
import ListOfInvitedViewer from "../../Containers/ListOfInvitedViewer";

import { PAGES, FEATURE_NAME } from "../../Utils/constants";
import { isFeatureIncluded } from "../../Utils/featureFlags";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../Common/ProgressLoader";
import { checkAccess } from "./DataProvider";

import CreateShow from "../SuperAdmin/CreateShow";
import ListAllChildShow from "./ListAllChildShow";
import ListAllChildStreams from "./ListAllChildStreams";
import ListAllChildUser from "./ListAllChildUser";

import { getSubscriptionList } from "../../Containers/Subscribe/DataHandler";
import ManageLicenseViewer from "../../Containers/ManageLicenseViewer";
import { auth } from "../../Firebase";
import { ManageShowGroupUsers } from "../../Containers/ManageShowGroup/GroupTab/CardOptions/ManageUsers";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import { fetchFeatures } from '../../Service/show';
import useNavigateToPage from "../../hooks/useNavigateToPage";
import { useLocationStore } from "../../store/useLocationStore";

const {
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_SHOW_GROUP_USER_INDEX_NAME,
} = process.env;

const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID || "",
  REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

interface IProps {
  selectedUser: any[];
}
const ShowAdminDashboard = (props: IProps) => {
  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  //let { show_id } = useParams();
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  
  const show_id = get(locationState,'state.show_id','');
  const teamAdmin = get(locationState,'state.team_admin','');
  const usersGroupsFlag = get(locationState,'state.usersGroupsFlag',false);

  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);

  const [currentShow, setCurrentShow] = useState("");
  const [showId, setShowId] = useState("");
  const [showCode, setShowCode] = useState("");
  const [show_name, setShowName] = useState("");
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [activeSubscriptionList, setActiveSubscriptionList] = useState([]);
  const [showGroup, setShowGroup] = useState([] as any);

  const [currentConfig, setCurrentConfig] = useState([]);

  const [renderChildShowCreate, setRenderChildShowCreate] = useState(true);
  const [renderChildShow, setRenderChildShow] = useState(false);
  const [renderChildStream, setRenderChildStream] = useState(false);
  const [renderChildUser, setRenderChildUser] = useState(false);

  const [selectedUser, setSelectedUser] = useState({} as any);

  const [userUpdated, setUserUpdated] = useState(false);
  const [stripeFlag, setStripeFlag] = useState(false);
  const [licenseMangerFlag, setLicenseMangerFlag] = useState(false);
  const [isUserGroup, setIsUserGroup] = useState([] as any);

  const [page, setPage] = useState("CreatePublisherViewer");

  const [configValue, setConfigValue] = useState(0);

  const [manageShowGroupUsersStatus, setManageShowGroupUsersStatus] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({} as any)

  const [showGroupUsers, setShowGroupUsers] = useState([] as any)

  const [allGroupsUsersList, setAllGroupsUsersList] = useState([] as any[])

  const [isUserGroupFeature, setisUserGroupFeature] = useState({} as any)

  const [isMasvFeature, setisMasvFeature] = useState({} as any)

  const showAdminHeaderMenuRef = useRef<any>([]);

  const addMenuToRef = (ele: any, menu: string, index: number) => {
    if (!!ele && !showAdminHeaderMenuRef.current[index]) {
      showAdminHeaderMenuRef.current[index] = { ele, menu };
    }
  };



  useEffect(() => {

    const validateAccess = async () => {
      setLoading(true);
      showAdminHeaderMenuRef.current = [];
      const isShowAdmin = await checkAccess(show_id, get(currentUser, "user.accessToken", ""));

      if (isShowAdmin) {
        setShowAdmin(true);
        await getShowAndConfig();
        await getLmShowAndConfig();
        setLoading(false);
      } else {
        setLoading(false);
        navigateToPage("/login");
      }
    };

    if (show_id) {
      setShowId(show_id);
      validateAccess();
    } else {
      navigateToPage("/login");
    }
    getFeatureFlagStatus()
  }, [show_id, showGroup]);

  useEffect(() => {
    if (
      !!stripeFlag &&
      (!stripeCustomerId || activeSubscriptionList.length === 0)
    ) {
      setPage("subscribe");
    }
  }, [stripeFlag]);

  useEffect(() => {
    if (licenseMangerFlag === true && configValue === 0) {
      setPage("ManageLicenseViewer");
    } else {
      setPage("CreatePublisherViewer");
    }
  }, [licenseMangerFlag]);

  useEffect(() => {
    // console.log('object invited users:>> ', invitedUsers);
    // console.log('showGroupUsers index :>> ', showGroupUsers);
    // console.log("================parent component manage show group status", manageShowGroupUsersStatus)

    if (manageShowGroupUsersStatus === true) {
      setPage("ManageShowGroupUser");
      // setSelectedGroup(selectedGroup)
    }
    // else {
    //   setPage("CreatePublisherViewer");
    // }
  }, [manageShowGroupUsersStatus, selectedGroup]);


  useEffect(() => {
    const fetchConfigDetails = async () => {
      // Validate publisher count limit
      if (show_id) {
        const { parent_show_id }: any = show_id;
        try {
          const getShowsConfig = {
            api: api.configs.listShowConfigsByShowId,
            urlParam: !!parent_show_id ? parent_show_id : show_id,
            queryParam: { config_name: "publisher_count" },
          };
          const showConfigDetails = await backend.fetch(getShowsConfig, get(currentUser, "user.accessToken", ""));

          const mergeConfig = showConfigDetails.map((showConfig: any) => {
            const filterConfig = currentConfig.filter(
              (config: any) => config["id"] === showConfig["config_id"]
            );
            showConfig["config_enable"] =
              filterConfig.length > 0 && !!filterConfig[0]["config_enable"];
            return showConfig;
          });

          if (!mergeConfig[0]?.config_value && licenseMangerFlag === true) {
            setPage("ManageLicenseViewer");
            setConfigValue(mergeConfig[0]?.config_value)
          } else if (mergeConfig[0]?.config_value > 0) {
            setPage("CreatePublisherViewer");
            setConfigValue(mergeConfig[0]?.config_value)
          }
        } catch (err: any) {
          console.log("err", err);
        }
      }
    }
    if (configValue === 0) {
      fetchConfigDetails()
    }
  }, [configValue]);

  //Get LmShow Details
  const getLmShowAndConfig = async () => {
    setLoading(true);
    try {
      const showData = {
        api: api.shows.get,
        urlParam: show_id,
      };
      const data = {
        api: api.configs.listConfigsByShowType,
      };

      const show: any = await backend.fetch(showData, get(currentUser, "user.accessToken", ""));
      setCurrentShow(show);
      const {
        show_name = "",
        parent_show_id = "",
      } = show;
      //LM Flag
      const lmflagData = {
        api: api.featureFlags.listShowFlagsName,
        queryParam: {
          show_id: !!parent_show_id ? parent_show_id : show_id,
          flag_name: "ALLOW_LICENSE_MANGER",
        },
      };
      const lmflag: any = await backend.fetch(lmflagData, get(currentUser, "user.accessToken", ""));
      if (!!lmflag && lmflag.length > 0) {
        console.log("enable lm feature flag::", lmflag[0].feature_enable)
        setLicenseMangerFlag(lmflag[0].feature_enable);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  //Get Feature Flag Details
  const getFeatureFlagStatus = async () => {
   const flagResult = await fetchFeatures(show_id);
   const {ALLOW_USERS_AND_GROUPS: userGroupFeature, ALLOW_MASSIV: masvFeature } = flagResult;
   setisUserGroupFeature(userGroupFeature)
   setisMasvFeature(masvFeature)
  };
  // Get ShowGroup Data
  const fetchShowGroupData = async () => {
    try {
      const getShowGroupListData = {
        api: api.group.listShowGroup,
        queryParam: { show_id: !!showId && showId },
      };
      // setLoading(true);
      if(isUserGroupFeature) {
      const listGroupRes = await backend.fetch(getShowGroupListData, get(currentUser, "user.accessToken", ""));
      if (listGroupRes) {
        setShowGroup(listGroupRes);

      }
    }
    }
    catch (err) {
      console.log(err)
      setLoading(false);
    }
  };

  useEffect(() => {
    searchClient.clearCache();
    if (showId) {
      fetchShowGroupUsers()
    }
    if (showGroup.length === 0 && showId) {
      fetchShowGroupData();

    }
  }, [showId])



  //Get Show and Config Details
  const getShowAndConfig = async () => {
    setLoading(true);
    try {
      const showData = {
        api: api.shows.get,
        urlParam: show_id,
      };
      const data = {
        api: api.configs.listConfigsByShowType,
      };

      const show: any = await backend.fetch(showData, get(currentUser, "user.accessToken", ""));
      const config = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      const {
        show_name = "",
        show_code = "",
        stripe_customer_id = "",
        parent_show_id = "",
      } = show;

      try {
        const flagData = {
          api: api.featureFlags.listShowFlagsName,
          queryParam: {
            show_id: !!parent_show_id ? parent_show_id : show_id,
            flag_name: "ALLOW_STRIPE_LICENCE",
          },
        };
        const flag: any = await backend.fetch(flagData, get(currentUser, "user.accessToken", ""));
        if (!!flag && flag.length > 0) {
          setStripeFlag(flag[0].feature_enable);
          if (!!flag[0].feature_enable && !!stripe_customer_id) {
            let subList = [];
            console.log("stripeFlag21233", flag[0].feature_enable, stripe_customer_id);
            subList = await getSubscriptionList(stripe_customer_id, "active", get(currentUser, "user.accessToken", ""));
            setActiveSubscriptionList(subList);
          }
        }

      } catch (err) {
        console.log("err", err);
      }


      setShowName(show_name);
      setShowCode(show_code);
      setCurrentShow(!!show ? show : "");
      setStripeCustomerId(stripe_customer_id);
      setCurrentConfig(!!config ? config : "");
    } catch (err: any) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const showAdminCreateChildShowClick = () => {
    setRenderChildShowCreate(true);
    setRenderChildShow(false);
    setRenderChildStream(false);
    setRenderChildUser(false);
  }

  const showAdminChildShowClick = () => {
    setRenderChildShowCreate(false);
    setRenderChildShow(true);
    setRenderChildStream(false);
    setRenderChildUser(false);
  };

  const showAdminChildStreamClick = () => {
    setRenderChildShowCreate(false);
    setRenderChildShow(false);
    setRenderChildStream(true);
    setRenderChildUser(false);
  };

  const showAdminChildUserClick = () => {
    setRenderChildShowCreate(false);
    setRenderChildShow(false);
    setRenderChildStream(false);
    setRenderChildUser(true);
  };

  const handleEditUserClick = (selectedUser: any) => {
    if (selectedUser) {
      setSelectedUser(selectedUser);
    }
    setPage("EditPublisherViewer");
  };

  const getChildShowCreate = () => {
    return (
      <>
        <div className="left-content">
          <div className="create-show"></div>
        </div>
        <div className="right-content">
          <div className="right-container-new">
            <CreateShow parent_show_id={show_id} />
          </div>
        </div>
      </>
    );
  };

  const getChildShow = () => <ListAllChildShow parent_show_id={show_id} usersGroupsFlag={usersGroupsFlag} />;

  const getChildStream = () => <ListAllChildStreams parent_show_id={show_id} usersGroupsFlag={usersGroupsFlag} />;

  const getChildUser = () => <ListAllChildUser parent_show_id={show_id} usersGroupsFlag={usersGroupsFlag} />;

  const handleShowAdminPage = (value: any) => {
    setPage(value)
  }

  //fetch list of users filtered by showID and show_viewer role
  const fetchShowGroupUsers = async () => {
    try {
      const input = {
        api: api.group.listUsersByShowId,
        queryParam: { show_id },
      };
      const getShowGroupUsersByShowId = await backend.fetch(input, get(currentUser, "user.accessToken", ""));

      setAllGroupsUsersList(getShowGroupUsersByShowId)
    }
    catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  };

  const renderCreateEditUser = () => (
    <>
      <div className="show-admin-page-layout">
        <div className={usersGroupsFlag ? "left-nav left-nav-new show-admin-left-nav" : "left-nav"}>
          <div className={usersGroupsFlag ? "vertical-menu vertical-menu-new" : "vertical-menu"}>
            <InvitedUsersList
              // setShowGroupUsers={setShowGroupUsers}
              showGroupUsers={showGroupUsers}
              showGroup={showGroup}
              fetchShowGroupData={fetchShowGroupData}
              currentPage={page}
              showId={showId}
              setManageShowGroupUsersStatus={setManageShowGroupUsersStatus}
              manageShowGroupUsersStatus={manageShowGroupUsersStatus}
              setSelectedGroup={setSelectedGroup}
              invitedUsers={invitedUsers}
              setInvitedUsers={setInvitedUsers}
              selectedUser={selectedUser}
              showAdminEditUserClick={handleEditUserClick}
              userUpdated={userUpdated}
              setUserUpdated={setUserUpdated}
              handleNavCreateUserClick={() => {
                setSelectedUser({});
                setPage("CreatePublisherViewer");
              }}
              allGroupsUsersList={allGroupsUsersList}
              usersGroupsFlag={usersGroupsFlag}
              isMasvFeature={isMasvFeature}
            />
          </div>
        </div>

        {page === "CreatePublisherViewer" ? (
          <div className="right-form-container">
            <div className="right-show-admin-createuser">
              <CreatePublisherViewer
                showGroup={showGroup}
                fetchShowGroupData={fetchShowGroupData}
                manageShowGroupUsersStatus={manageShowGroupUsersStatus}
                setManageShowGroupUsersStatus={setManageShowGroupUsersStatus}
                showId={showId}
                invitedUsers={invitedUsers}
                setInvitedUsers={setInvitedUsers}
                showName={show_name}
                setUserUpdated={setUserUpdated}
                currentShow={currentShow}
                currentConfig={currentConfig}
                setLoading={setLoading}
                pageName={handleShowAdminPage}
                licenseMangerFlag={licenseMangerFlag}
                configValue={configValue}
                show_admin_id={auth.getUserId()}
                fetchShowGroupUsers={fetchShowGroupUsers}
                isUserGroupFeature={isUserGroupFeature}
                usersGroupsFlag={usersGroupsFlag}
                isMasvFeature={isMasvFeature}
              />
            </div>
          </div>
        ) : (page === "ManageShowGroupUser" ? (

          <div className="right-form-container-new">
            <div className="right-show-admin-createuser">
              <ManageShowGroupUsers
                setShowGroupUsers={setShowGroupUsers}
                showGroupUsers={showGroupUsers}
                showId={showId}
                fetchShowGroupData={fetchShowGroupData}
                invitedUsers={invitedUsers}
                setLoading={setLoading}
                setSelectedGroup={setSelectedGroup}
                selectedGroup={selectedGroup}
                manageShowGroupUsersStatus={manageShowGroupUsersStatus}
                fetchShowGroupUsers={fetchShowGroupUsers}
                allGroupsUsersList={allGroupsUsersList}
                usersGroupsFlag={usersGroupsFlag}
              />
            </div>
          </div>
        ) :
          (page === "ManageLicenseViewer" ? (
            <div className="right-form-container" style={{ padding: '0' }}>
              <div className="right-show-admin-createuser">
                <ManageLicenseViewer
                  showId={show_id}
                  show_name={show_name}
                  show_admin={auth.getUserEmail()}
                  show_admin_id={auth.getUserId()}
                  currentShow={currentShow}
                  currentConfig={currentConfig}
                  pageName={handleShowAdminPage}
                  setLoading={setLoading}
                  loading={loading}
                  usersGroupsFlag={usersGroupsFlag}
                  setInvitedUsers={setInvitedUsers}
                  configValue={configValue}
                  setConfigValue={setConfigValue}
                />
              </div>
            </div>
          )
            : (
              <div className="right-form-container">
                <div className="right-show-admin-createuser">
                  {
                    usersGroupsFlag ?
                      <EditPublisherViewerNew
                        listShowGroup={showGroup}
                        showId={showId}
                        invitedUsers={invitedUsers}
                        setInvitedUsers={setInvitedUsers}
                        selectedUser={selectedUser}
                        setUserUpdated={setUserUpdated}
                        currentShow={currentShow}
                        currentConfig={currentConfig}
                        configValue={configValue}
                        licenseMangerFlag={licenseMangerFlag}
                        setLoading={setLoading}
                        pageName={handleShowAdminPage}
                        show_admin_id={auth.getUserId()}
                        isUserGroup={isUserGroup}
                        fetchShowGroupUsers={fetchShowGroupUsers}
                        usersGroupsFlag={usersGroupsFlag}
                        show_admin={auth.getUserEmail()}
                      /> :
                      <EditPublisherViewer
                        showId={showId}
                        invitedUsers={invitedUsers}
                        setInvitedUsers={setInvitedUsers}
                        selectedUser={selectedUser}
                        setUserUpdated={setUserUpdated}
                        currentShow={currentShow}
                        currentConfig={currentConfig}
                        configValue={configValue}
                        licenseMangerFlag={licenseMangerFlag}
                        setLoading={setLoading}
                        pageName={handleShowAdminPage}
                        show_admin_id={auth.getUserId()}
                        usersGroupsFlag={usersGroupsFlag}
                        show_admin={auth.getUserEmail()}
                        isMasvFeature={isMasvFeature}
                      />
                  }
                </div>
              </div>
            )
          ))}
      </div>
    </>
  );

  const renderListUsers = () => (
    <div className="right-container-new">
      <ListOfShowUsers
        show_id={show_id}
        showAdminEditUserClick={handleEditUserClick}
        setUserUpdated={setUserUpdated}
        userUpdated={userUpdated}
        usersGroupsFlag={usersGroupsFlag}
        invitedUsers={invitedUsers}
        setInvitedUsers={setInvitedUsers}
        isMasvFeature={isMasvFeature}
      />
    </div>
  );

  const renderListAllStream = () => (
    <div className="right-container-new">
      <ListOfShowStreams show_id={show_id} usersGroupsFlag={usersGroupsFlag} />
    </div>
  );
  const renderInvitedViewer = () => (
    <div className="right-container-new">
      <ListOfInvitedViewer
        show_id={show_id}
        usersGroupsFlag={usersGroupsFlag}
        isMasvFeature={isMasvFeature}
      />
    </div>
  );



  // const renderManageLicenseViewer = () => (
  //   <ManageLicenseViewer
  //     showId={show_id}
  //     show_name={show_name}
  //     show_admin={auth.getUserEmail()}
  //     show_admin_id={auth.getUserId()}
  //     currentShow={currentShow}
  //     currentConfig={currentConfig}
  //     pageName={handleShowAdminPage}
  //   />
  // );


  const renderSubscribe = () => (
    <Subscribe
      show_id={show_id}
      show_code={showCode}
      customerId={stripeCustomerId}
      showAdminHeaderMenuRef={showAdminHeaderMenuRef}
      setStripeCustomerId={setStripeCustomerId}
      activeSubscriptionList={activeSubscriptionList}
      usersGroupsFlag={usersGroupsFlag}
    />
  );

  const renderBodyContent = () => {
    switch (page) {
      case "CreatePublisherViewer":
      case "EditPublisherViewer":
        return renderCreateEditUser();
      case "ManageShowGroupUser":
        return renderCreateEditUser()
      case "ShowStreamList":
        return renderListAllStream();
      case "subscribe":
        return renderSubscribe();
      case "ListOfInvitedViewer":
        return renderInvitedViewer();
      case "ManageLicenseViewer":
        return renderCreateEditUser();
      default:
        return renderListUsers();
    }
  };

  const renderParentShowBodyContent = () => {
    if (renderChildShowCreate) {
      return getChildShowCreate();
    }

    if (renderChildShow) {
      return getChildShow();
    }

    if (renderChildStream) {
      return getChildStream();
    }

    if (renderChildUser) {
      return getChildUser();
    }

    return <div></div>;
  };
  return !showCode ? (
    <ProgressLoader loading={!showCode} />
  ) : !!showCode && !!isFeatureIncluded(FEATURE_NAME.CHILD_SHOWS, show_id) ? (
    <Layout
      page={PAGES.SHOW_ADMIN_PARENT_PAGE}
      showAdminCreateChildShowClick={showAdminCreateChildShowClick}
      showAdminChildShowClick={showAdminChildShowClick}
      showAdminChildStreamClick={showAdminChildStreamClick}
      showAdminChildUserClick={showAdminChildUserClick}
    >
      <ProgressLoader loading={loading} />

      {showAdmin ? renderParentShowBodyContent() : <div> </div>}
    </Layout>
  ) : (
    <Layout
      page={PAGES.SHOW_ADMIN_PAGE}
      currentPage={page}
      showAdminListClick={() => setPage("ShowUserList")}
      showAdminCreateUserClick={() => setPage("CreatePublisherViewer")}
      showAdminStreamsListClick={() => setPage("ShowStreamList")}
      showAdminUserEditView={page === "EditPublisherViewer"}
      showAdminSubscribeClick={() => setPage("subscribe")}
      showInvitedViewerListClick={() => setPage("ListOfInvitedViewer")}
      showManageLicenseViewerClick={() => setPage("ManageLicenseViewer")}
      teamAdmin={teamAdmin}
      stripeFlag={stripeFlag}
      licenseMangerFlag={licenseMangerFlag}
      addMenuToRef={addMenuToRef}
      showId={show_id}
      usersGroupsFlag={usersGroupsFlag}
    >
      <ProgressLoader loading={loading} />
      {showAdmin ? renderBodyContent() : <div> </div>}
    </Layout>
  );
};

export default ShowAdminDashboard;
