import { Progress, Space } from 'antd';

interface ProgressLoaderProps {
  progress: number;
  status?: any;
}

const ProgressLoader = (props: ProgressLoaderProps) => {
  const { progress, status } = props;
  return (
    <div>
      <Space wrap size={100}>
        <Progress type='circle' percent={progress} size={'default'} status={status} />
      </Space>
    </div>
  );
};
export default ProgressLoader;