import { useContext } from 'react';
import { Col, Row } from 'antd';
import { EncoderContext } from '../hooks/useEncoderContext';
import { get } from '../../../Utils/helpers';

export default function DeviceStatus() {
  const encoderValues = useContext(EncoderContext);
  const { settings } = encoderValues;

  return (
    <>
      <div className='encoder-device-details-header'>Stream</div>
      <Row className='encoder-device-details-body' gutter={6}>
        <Col span={6}>CHANNEL</Col>
        <Col span={10}>STATUS INPUT TYPE</Col>
        <Col span={8}>DURATION</Col>
      </Row>
      <Row className='encoder-device-details-body' gutter={6}>
        <Col span={6} style={{ wordBreak: 'break-all' }}>
          {get(settings, 'channel', '')}
        </Col>
        <Col span={10}>dolby.io Real-time WebRTC</Col>
        <Col span={8}>{get(settings, 'message.data.stream-duration', '')}</Col>
      </Row>
      <div className='encoder-device-details-header'>Input</div>
      <Row className='encoder-device-details-body'>
        <Col span={8}>HDMI</Col>
        <Col span={16}>{get(settings, 'message.data.hdmi-status', '')}</Col>
        <Col span={8}>SDI</Col>
        <Col span={16}>{get(settings, 'message.data.sdi-status', '')}</Col>
      </Row>
      <div className='encoder-device-details-header'>Video</div>
      <Row className='encoder-device-details-body'>
        <Col span={14}>CHANNEL</Col>
        <Col span={10}>1</Col>
      </Row>
      <Row className='encoder-device-details-body'>
        <Col span={14}>CODEC</Col>
        <Col span={10}>{get(settings, 'message.data.video-codec', '')}</Col>
      </Row>
      <Row className='encoder-device-details-body'>
        <Col span={14}>VIDEO BITRATE</Col>
        <Col span={10}>{get(settings, 'message.data.video-bitrate', '')}</Col>
      </Row>
      <Row className='encoder-device-details-body'>
        <Col span={14}>CURRENT VIDEO BITRATE</Col>
        <Col span={10}>{get(settings, 'message.data.current-video-bitrate', '')}</Col>
      </Row>
      <Row className='encoder-device-details-body'>
        <Col span={14}>METADATA BITRATE</Col>
        <Col span={10}>{get(settings, 'message.data.metadata-bitrate', '')}</Col>
      </Row>
      <div className='encoder-device-details-header'>Audio</div>
      <Row className='encoder-device-details-body'>
        <Col span={14}>CHANNEL</Col>
        <Col span={10}>{1}</Col>
        <Col span={14}>CODEC</Col>
        <Col span={10}>{get(settings, 'message.data.audio-codec', '')}</Col>
        <Col span={14}>AUDIO BITRATE</Col>
        <Col span={10}>{get(settings, 'message.data.audio-bitrate', '')}</Col>
        <Col span={14}>CURRENT AUDIO BITRATE</Col>
        <Col span={10}>{get(settings, 'message.data.current-audio-bitrate', '')}</Col>
      </Row>
    </>
  );
}
