import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import UserMessage from '../Common/UserMessage';
import { auth, analytics } from '../../Firebase';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import useNavigateToPage from '../../hooks/useNavigateToPage';
interface pageProps {
  pageHandler: any;
}

const Home = (props: pageProps) => {
  const navigateToPage = useNavigateToPage();
  const currentUser = get(useContext(AuthContext), 'currentUser', {});
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  const {
    securityClick,
    rolesClick,
    speedClick,
    toolsClick,
    colloborateClick,
    contactClick,
    avidRentalsClick,
    loginClick,
    bookDemoClick,
    videoClick,
  } = analytics;

  const videoSectionClick = () => {
    console.log('Video');
    if (!!sessionStorage.getItem('videoPlayed')) {
      videoClick();
    }
    sessionStorage.setItem('videoPlayed', 'true');
  };

  const gotoLogin = () => {
    const user = auth.getUser();
    const uid = get(user, 'uid', null);
    if (auth.isAuthenticated() && user && uid) {
      navigateToPage('/showselection', { state: { userId: uid } });
    }
    navigateToPage('/login');
  };

  const validateEmail = (event: any) => {
    setEmail(event.target.value);
    let validateEmail = event.target.value;
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail('Please enter email');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail('Invalid email format');
    } else {
      setEmailValid(true);
      setErrorEmail('');
    }
  };

  const validatePhone = (event: any) => {
    setMobile(event.target.value);
    if (!event.target.value) {
      setPhoneValid(false);
      setErrorPhone('Please enter phone number');
    } else if (!/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(event.target.value)) {
      setPhoneValid(false);
      setErrorPhone('Invalid phone number format');
    } else {
      setPhoneValid(true);
      setErrorPhone('');
    }
  };

  const redefineHandler = async (event: any) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      const toEmail = 'info@pacpost.com';
      // Call Backend to Create Publisher and Viewer
      const input = {
        toEmail,
        name,
        company,
        email,
        mobile,
        message,
      };
      const data = {
        api: api.notification.sendSignUpDetails,
        payLoad: JSON.stringify(input),
      };
      const response = await backend.save(data, get(currentUser, 'user.accessToken', ''));
      setName('');
      setCompany('');
      setEmail('');
      setMobile('');
      setMessage('');
      setResponseMessage(!!response && !!response.status ? response.status : '');
    } catch (err: any) {
      console.log('err', err);
    }
  };

  const redefine = () => {
    return (
      <form>
        {!!responseMessage && <UserMessage successMsg={responseMessage} errorMsg={''} />}
        <label>
          Name <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='text'
          placeholder='John Smith'
          name='name'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label>
          Name of company <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='text'
          placeholder='Example company'
          name='company'
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <label>
          Email <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='email'
          placeholder='example@doamin.com'
          name='email'
          value={email}
          onChange={validateEmail}
        />
        {emailValid === false && (
          <span className='error-message' style={{ lineHeight: '30px' }}>
            {errorEmail}
          </span>
        )}
        <label>
          Phone number <span style={{ color: 'red' }}>*</span>
        </label>
        <input
          type='tel'
          placeholder='Add phone number here'
          name='mobile'
          value={mobile}
          onChange={validatePhone}
        />
        {phoneValid === false && (
          <span className='error-message' style={{ lineHeight: '30px' }}>
            {errorPhone}
          </span>
        )}
        <label>What can we help you with?</label>
        <textarea
          placeholder='Add message here'
          name='message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <Button
          className='auth-violet-button'
          color='violet'
          loading={loading}
          onClick={redefineHandler}
          disabled={
            !name ||
            !company ||
            !emailValid ||
            !mobile ||
            !/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(mobile)
          }
        >
          Submit
        </Button>
      </form>
    );
  };

  return (
    <>
      <header className='block main-header'>
        <div className='wraparea flex'>
          <div>
            <button onClick={() => setMobileNav(true)} className='toogleBtn mobile'>
              <span>
                <img src='assets/images/menu.png' alt='' />
              </span>
            </button>
          </div>
          <div className='main-logo'>
            {' '}
            <img width='133' height='50' src='assets/images/main-logo.svg' alt='' />{' '}
          </div>
          <div className='main-nav'>
            <ul>
              <li>
                <a href='#security' onClick={securityClick}>
                  Security
                </a>
              </li>
              <li>
                <a href='#role' onClick={rolesClick}>
                  Roles
                </a>
              </li>
              <li>
                <a href='#speed' onClick={speedClick}>
                  Speed
                </a>
              </li>
              <li>
                <a href='#tools' onClick={toolsClick}>
                  Tools
                </a>
              </li>
              <li>
                <a href='#collaborate' onClick={colloborateClick}>
                  Collaborate
                </a>
              </li>
              <li>
                <a href='https://pacpost.com/contact' onClick={contactClick}>
                  Contact
                </a>
              </li>
              <li>
                <a href='https://pacpost.com/services' onClick={avidRentalsClick}>
                  Avid Rentals
                </a>
              </li>
              <li>
                <a>
                  <span
                    className='login'
                    onClick={() => {
                      loginClick();
                      gotoLogin();
                    }}
                  >
                    Login
                  </span>
                </a>
              </li>
            </ul>
            {/* <div className="buttonGroup mobile">
                        <button className="login" onClick={gotoLogin}>LOGIN</button>
                        <button className="signup"><a href="#redefine">SIGN UP</a></button>
                    </div>
                    </div>
                    <div className="buttonGroup desktop">
                        <button className="login" onClick={gotoLogin}>LOGIN</button>
                        <button className="signup"><a className="signup" href="#redefine">SIGN UP</a></button>
                    </div>
                    */}
          </div>
        </div>
      </header>
      <div style={{ display: mobileNav ? 'block' : 'none' }} className='mobile-nav'>
        <button className='nav_btn' type='button' onClick={() => setMobileNav(false)}>
          <svg width='14' height='14' viewBox='0 0 14 14'>
            <line fill='none' stroke='#000' strokeWidth='1.5' x1='1' y1='1' x2='13' y2='13'></line>
            <line fill='none' stroke='#000' strokeWidth='1.5' x1='13' y1='1' x2='1' y2='13'></line>
          </svg>
        </button>
        <ul>
          <li>
            <a href='#security' onClick={securityClick}>
              Security
            </a>
          </li>
          <li>
            <a href='#role' onClick={rolesClick}>
              Roles
            </a>
          </li>
          <li>
            <a href='#speed' onClick={speedClick}>
              Speed
            </a>
          </li>
          <li>
            <a href='#tools' onClick={toolsClick}>
              Tools
            </a>
          </li>
          <li>
            <a href='#collaborate' onClick={colloborateClick}>
              Collaborate
            </a>
          </li>
          <li>
            <a href='https://pacpost.com/contact' onClick={contactClick}>
              Contact
            </a>
          </li>
          <li>
            <a href='https://pacpost.com/services' onClick={avidRentalsClick}>
              Avid Rentals
            </a>
          </li>
          <li>
            <a>
              <span
                className='login'
                onClick={() => {
                  loginClick();
                  gotoLogin();
                }}
              >
                Login
              </span>
            </a>
          </li>
        </ul>
      </div>
      <section className='block banner-main'>
        <div className='captionText'>
          <h1>Enjoy the fastest, most secure remote editorial collaboration on Earth</h1>
          {/* <div className="logoInDemo" >
                        <img src="assets/images/latest/logo_pacpost_icon_w.png" />
                    </div> */}
          <a
            href={`https://calendly.com/pacpost-live/demo-pacpost-live?month=2021-05`}
            onClick={bookDemoClick}
          >
            BOOK A DEMO
          </a>
        </div>
        <img className='mobile' src='assets/images/foldarea_Image_minify.png' alt='' />
      </section>
      <section className='block'>
        <div className='pp-live-video'>
          <iframe
            title='Pac post Video'
            src='https://player.vimeo.com/video/740542854?color=ffffff&title=0&byline=0&portrait=0'
            // width="969"
            // height="545"
            frameBorder='0'
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen={true}
            className='video-frame'
          ></iframe>
        </div>
      </section>
      <section className='block section01'>
        <div className='linkA' id='getStart'></div>
        <div className='wraparea flex'>
          <div className='content-area'>
            <div className='border-content block'>
              <h3>Security</h3>
              <p>
                PacPost.live provides studio-level security for multi-user live streaming from
                editorial with unlimited projects or edit bays on the world's fastest network for
                video streaming content. Easily collaborate on Avid/Adobe sequences or VFX in
                real-time with 200-500 millisecond global latency anytime/anywhere.
              </p>
            </div>
            <div className='border-content block'>
              <h3>Flexibility</h3>
              <p>
                PacPost.live dashboard gives your production the flexibility to quickly provide live
                feedback to editors, compositors, visual effects teams, music, and sound engineers
                from a single pane of glass. Noise canceling technology prevents audio feedback
                during live streaming and video conferencing. No need for push to talk mics !
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="block section02">
                <div className="wraparea">
                    <h2 className="wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.3s">Our Clients</h2>
                    <div className="logosCenter wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.5s">
                    <div className="logosItem"> <img src="assets/images/updated/netflix-2015-logo.png" alt=""/> </div>
                    <div className="logosItem"> <img src="assets/images/updated/sony.png" alt=""/> </div>
                    <div className="logosItem"> <img src="assets/images/updated/warner-bros.png" alt=""/> </div>
                    <div className="logosItem"> <img src="assets/images/updated/hulu-2.png" alt=""/> </div>
                    <div className="logosItem"> <img src="assets/images/updated/paramount-pictures.png" alt=""/> </div>
                    </div>
                </div>
            </section>  */}
      <section className='block section03'>
        <div className='linkA' id='security'></div>
        <div className='wraparea'>
          <div className='headingText'>
            <h2>Studio Grade Security</h2>
            <p>
              Designed for live editorial workflows by world-premier production houses, studios, and
              networks, PacPost.live delivers unrivaled, token-based security to keep your content
              secure and easily accessible.
            </p>
          </div>
          <div className='areaListContent'>
            <ul>
              <li>
                <div className='dataDiv'>
                  <img src='assets/images/latest/virtual-private-cloud-alt_purple.png' alt='' />
                  <h3>Private connections</h3>
                  <p>
                    To protect your communications and production workflow with network-level
                    security and quality from end to end.
                  </p>
                </div>
              </li>
              <li>
                <div className='dataDiv'>
                  <img src='assets/images/latest/password_purple.png' alt='' />
                  <h3>Two Factor SMS Authentication</h3>
                  <p>
                    The lock on the front door of your production. PacPost.live provides
                    unparalleled security and a seamless user experience getting your crew to the
                    content they need to see quickly and securely.{' '}
                  </p>
                </div>
              </li>
              <li>
                <div className='dataDiv'>
                  <img src='assets/images/latest/fingerprint_purple.png' alt='' />
                  <h3>Watermarking</h3>
                  <p>Enable watermarking on your content to prevent leaks for sensitive content.</p>
                </div>
              </li>
              <li>
                <div className='dataDiv'>
                  <img src='assets/images/latest/private-cloud_purple.png' alt='' />
                  <h3>Private</h3>
                  <p>WebRTC CDN</p>
                </div>
              </li>
              <li>
                <div className='dataDiv'>
                  <img src='assets/images/latest/places_purple.png' alt='' />
                  <h3>Supports</h3>
                  <p>MAC/PC</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className='block multipleSec'>
        <div className='linkA' id='role'></div>
        <div className='wraparea'>
          <div className='headingText'>
            <h2>Multiple Roles</h2>
            <p>
              Our Platform keeps your production secure by offering multiple roles for crew members
              and production leads.{' '}
            </p>
          </div>
          <div className='sliderArea'>
            <div className='item'>
              <img src='assets/images/latest/roles_publisher.png' alt='' />
              <div className='areaunderslider'>
                <h3>Publisher</h3>
                <p>
                  The Publisher invites Viewers to watch specific streams within the production,
                  ensuring the production workflow is minimized for a need-to-see basis only.{' '}
                </p>
              </div>
            </div>
            <div className='item'>
              <img src='assets/images/latest/roles_showAdmin.png' alt='' />
              <div className='areaunderslider'>
                <h3>Show Administrator</h3>
                <p>
                  The Show Administrator invites crew members to the production. These roles include
                  Publishers and Viewers.
                </p>
              </div>
            </div>
            <div className='item'>
              <img src='assets/images/latest/roles_unlimited.png' alt='' />
              <div className='areaunderslider'>
                <h3>Unlimited Viewers</h3>
                <p>
                  The viewer can watch production video streams assigned by the publisher. They can
                  make annotations, chat, and video conference with other crew members.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='block section04'>
        <div className='linkA' id='speed'></div>
        <div className='wraparea'>
          <div className='headingText'>
            <h2>Speed</h2>
            <p>
              Our low-latency streaming and video conferencing platform has a global low-latency
              routing architecture, helping improve the routing between intermediate nodes in your
              calls.
            </p>
          </div>
          <div className='speed_video'>
            <video autoPlay muted loop>
              <source src='assets/videos/speed.mp4' type='video/mp4' />
            </video>
          </div>
          {/* <div>
                        <iframe src="https://drive.google.com/file/d/1iUoQfqFCLBVl3ehKLMsA9iuQBoN5ApM5/preview" width="1400" height="300" frameBorder="0" scrolling="no" ></iframe>
                        <div style={{width: "80px", height: "80px", position: "absolute", opacity: "0", right: "0px", top: "0px"}}> </div>
                    </div> */}
        </div>
      </section>
      <section className='block section05'>
        <div className='linkA' id='scale'></div>
        <div className='wraparea'>
          <div className='textareaCenter'>
            <h2>Scale</h2>
            <p>
              Whether your production is just getting started or is entering its largest stage,
              effortlessly and limitlessly scale your workflow to meet your production needs without
              compromising your workflow or content quality.{' '}
            </p>
          </div>
        </div>
      </section>
      <section className='block section06'>
        <div className='linkA' id='tools'></div>
        <div className='wraparea'>
          <div className='textareaCenter'>
            <h2>Enterprise Tools to Communicate</h2>
            <p>
              Our tools allow publishers and viewers to efficiently and collaboratively share
              feedback for live streams.{' '}
            </p>
          </div>
          <div>
            <img src='assets/images/latest/Annotation_v1.gif' alt='' />
          </div>
        </div>
      </section>
      <section className='block contentList-bottom'>
        <div className='wraparea'>
          <div className='textArea'>
            <h3>Annotations</h3>
            <p>
              Our intuitive online annotations panel was designed specifically for world-premier
              production houses. Efficiently provide your feedback in real-time by sketching and
              drawing on content.
            </p>
          </div>
          <div className='textArea'>
            <h3>Secure Video Conferencing</h3>
            <p>
              Invite up to 50 members to your video conference for production meetings and more.{' '}
            </p>
          </div>
          <div className='textArea'>
            <h3>Chat</h3>
            <p>All crew members will be able to video conference while watching a live stream</p>
          </div>
        </div>
      </section>
      <section className='block collaborate'>
        <div className='linkA' id='collaborate'></div>
        <div className='wraparea'>
          <div className='headingText'>
            <h2>Collaborate</h2>
            <p>
              Collaborate with your production crew members anytime/anywhere from almost any device
              with a high-speed internet connection.
            </p>
          </div>
          <div className='devicesArea'>
            <ul>
              <li>
                <img
                  width='150px'
                  height='88px'
                  src='assets/images/updated/noun_macbook_1226185.png'
                  alt=''
                />
              </li>
              <li>
                <img
                  width='150px'
                  height='115px'
                  src='assets/images/updated/noun_Computer_3281.png'
                  alt=''
                />
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className='block support'>
        <div className='linkA' id='support'></div>
        <div className='wraparea'>
          <div className='dataText'>
            <h2>Support</h2>
            <p>
              World class productions work 24-7 year-around, and PacPost.live provides telephone
              support to assist your production when you need us. Get assistance from the team on
              the ground here at our LA-based studios.{' '}
            </p>
          </div>
        </div>
      </section>
      <section className='block pricing'>
        <div className='linkA' id='pricing'></div>
        <div className='wraparea'>
          <div className='prices'>
            <h2>Pricing</h2>
            <p>
              Access to Pac Post Live for remote viewing and collaboration. Securely stream
              editorial with anyone, anywhere.
            </p>
            <ul>
              <li>
                <strong>LIVE STREAMING & VIDEO CONFERENCING</strong>
              </li>
              <li>
                <strong>LIVE CHAT</strong>
              </li>
              <li>
                <strong>UNLIMITED VIEWERS</strong>
              </li>
              <li>
                <strong>SUB-SECOND DELAY</strong>
              </li>
              <li>
                <strong>PLAYBACK ON ANY DEVICE</strong>
              </li>
            </ul>
            <p>$699 per month</p>
            <a
              target='new'
              href={`https://calendly.com/pacpost-live/demo-pacpost-live?month=2021-05`}
              className='book_demo'
              onClick={bookDemoClick}
            >
              BOOK A DEMO
            </a>
          </div>
          <div className='pricing_video'>
            <video autoPlay muted loop>
              <source src='assets/videos/retro_tv_sets.mp4' type='video/mp4' />
            </video>
          </div>
        </div>
      </section>
      <section style={{ display: 'none' }} className='block form-bottom'>
        <div className='linkA' id='redefine'></div>
        <div className='wraparea'>
          <div className='half'>
            <img src='assets/images/updated/icon_mail.png' alt='' />
            <h2>Redefine Your Workflow</h2>
          </div>
          <div className='half'>{redefine()}</div>
        </div>
      </section>
      <footer style={{ display: 'none' }} className='block footer-main'>
        <img src='assets/images/updated/tv_logo.png' alt='' />
        <div className='block copyrightArea'>
          <ul>
            <li>
              <a
                href='#'
                onClick={() => {
                  props.pageHandler('contact');
                }}
              >
                Contact Pacific Post
              </a>
            </li>
            <li>
              <a
                href='#'
                onClick={() => {
                  props.pageHandler('privacyPolicy');
                }}
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href='#'
                onClick={() => {
                  props.pageHandler('terms');
                }}
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                href='#'
                onClick={() => {
                  props.pageHandler('accessibility');
                }}
              >
                Accessibility
              </a>
            </li>
          </ul>
          <p>© 2020 Pacific Post Live.</p>
        </div>
      </footer>
    </>
  );
};
export default Home;
