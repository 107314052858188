import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import DeleteStream from "./DeleteStream";
import SendToken from "./SendToken";
import { Modal, message } from "antd";
import { get } from "../../Utils/helpers";


interface IProps {
  show_id: string;
  stream: any;
  index: number;
  teamAdmin: boolean;
  handleDelete: (deleteStream: any) => void;
}


const ListStreamTableRow = (props: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const renderCopyStreamButton = (stream_name: string) => {
    if (!stream_name) {
      return;
    }
    return (
      <Button
        color="violet"
        className="violet-button-list full-button-width"
        onClick={() => {
          navigator.clipboard.writeText(stream_name);
        }}
      >
        Copy Stream Name
      </Button>
    );
  };
  const renderAdvancedSettingsButton = (stream: any ) => {
    if (!stream.srt_stream_id || stream.status === "Deleted")  {
      return;
    }
    const copySrtStreamId = () => {
      navigator.clipboard.writeText(stream.srt_stream_id);
      message.success('SRT Stream ID copied to clipboard');
    };

    const copyIpAddress = () => {
      navigator.clipboard.writeText('srt-auto.millicast.com');
      message.success('SRT Ip Adsress copied to clipboard');
    };
    
    const copyPortValue = () => {
      navigator.clipboard.writeText('10000');
      message.success('Port details copied to clipboard');
    };

    const copyPassphrase = () => {
      navigator.clipboard.writeText(get(stream,'srtPassphrase',''));
      message.success('Passphrase copied to clipboard');
    };

    return (
      <>
        <Button
          color='violet'
          className='violet-button-list full-button-width'
          onClick={() => setModalVisible(true)}
        >
          Advanced Settings
        </Button>
        <Modal
          title='SRT Details : '
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          // width={1000}
        >
          <div className="advanced-settings-wrapper">
            <Button
              color='violet'
              className='copy-button'
              onClick={copySrtStreamId}
            >
              Copy SRT Stream ID
            </Button>
            <Button
              color='violet'
              className='copy-button'
              onClick={copyIpAddress}
            >
              Copy Ip Address
            </Button>
            <Button
              color='violet'
              className='copy-button'
              onClick={copyPortValue}
            >
              Copy Port Details
            </Button>
             <Button
              color='violet'
              className='copy-button'
              onClick={copyPassphrase}
            >
              Copy Passphrase
            </Button>  
          </div>
          <div><span className="error-message">* Please use passphrase while streaming</span></div>
        </Modal>
      </>
    );
  };


  const renderCopyTokenButton = (
    token: string,
    user_entered_stream_label: string
  ) => {
    if (!token) {
      return;
    }

    return (
      <Button
        color="violet"
        className="violet-button-list full-button-width"
        onClick={() => {
          navigator.clipboard.writeText(token);
        }}
      >
        Copy Token
      </Button>
    );
  };

  const renderDeleteStreamButton = (stream: any) => {
    if (stream && stream.status === "Deleted") {
      return;
    }
    return (
      <DeleteStream
        show_id={props.show_id}
        stream={stream}
        handleDelete={props.handleDelete}
      />
    );
  };

  const renderSendTokenButton = (stream: any) => {
    if (!stream) return;
    if (!stream.token) return;
    if (!stream.assigned_to) return;

    const user = stream.assigned_to;
    if (!user.user_email) return;

    return <SendToken show_id={props.show_id} stream={stream} />;
  };

  const getAssignedTO = (stream: any) => {
    let name = "";
    if (stream && stream.assigned_to) {
      const user: any = stream.assigned_to;
      if (user && user.user_name) {
        const user_name = user.user_name;
        if (user_name) {
          name = user_name.user_name_first || "";

          name = user_name.user_name_last
            ? name + " " + user_name.user_name_last
            : name;
        }
      }
    }
    return name;
  };

  return (
    <>
      <tr className="header-cell-without-border" key={props.index}>
        <td>{props.stream.user_entered_stream_label}</td>
        <td>{props.stream.status}</td>

        {props.teamAdmin && (
          <td className="right-border">{getAssignedTO(props.stream)}</td>
        )}

        <td className="right-border">{props.stream.stream_name}</td>

        <td className="left-border right-border button-padding-0">
          {renderCopyStreamButton(props.stream.stream_name)}
        </td>
        <td className="left-border right-border button-padding-0">
          {renderCopyTokenButton(
            props.stream.token,
            props.stream.user_entered_stream_label
          )}
        </td>
         
        {props.teamAdmin && (
          <td className="left-border right-border button-padding-0">
            {renderSendTokenButton(props.stream)}
          </td>
        )}

        <td className="left-border button-padding-0">
          {renderDeleteStreamButton(props.stream)}
        </td>
        <td className="left-border button-padding-0">
          {renderAdvancedSettingsButton(props.stream)}
         </td>
      </tr>
    </>
  );
};
export default ListStreamTableRow;
