const get = (source: any, path: string, defaultValue: any) => {
  if (!!source && !!path) {
    const parts = path.split(".");
    const length = parts.length;
    let result = source;
    for (let i = 0; i < length; i++) {
      let item = result[parts[i]];
      if (item === null || item === undefined) {
        return item || defaultValue;
      }
      result = item;
    }
    return result;
  }
  return defaultValue;
};

const sortTableDate = (a: any, b: any, key: string) => {
  const val1 = get(a, key, 0);
  const val2 = get(b, key, 0);
  if (val1 < val2) {
    return 1;
  } else if (val1 > val2) {
    return -1;
  } else {
    return 0;
  }
};

const getMilliSeconds = (seconds: number, nanoseconds: number) => seconds * 1000 + nanoseconds / 1000000

const scrollToTop = () => {
  window.scrollTo(0, 0);
}

const tick = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms));

const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && navigator.maxTouchPoints > 1);
}

//filter unique obj in array of obj using a field
const getUniqueObj = (arr: any[], fieldName: string) => {
  const unique: any[] = [];
  for (const item of arr) {
    const isDuplicate = unique.find((obj) => obj[fieldName] === item[fieldName]);
    if (!isDuplicate) {
      unique.push(item);
    }
  }
  return unique;
}

const isEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const formatFileSize = (fileSize: any) => {
  console.log("Helpers_fileSize", fileSize)
  if (fileSize < 1024) {
    return `${fileSize}B`;
  } else if (fileSize < 1024 * 1024) {
    return `${(fileSize / 1024).toFixed(2)}KB`;
  } else if (fileSize < 1024 * 1024 * 1024) {
    return `${(fileSize / (1024 * 1024)).toFixed(2)}MB`;
  } else if (fileSize < 1024 * 1024 * 1024 * 1024) {
    return `${(fileSize / (1024 * 1024 * 1024)).toFixed(2)}GB`;
  } else {
    return `${(fileSize / (1024 * 1024 * 1024 * 1024)).toFixed(2)}TB`;
  }
};

export { get, sortTableDate, getMilliSeconds, scrollToTop, tick, isIOS, getUniqueObj, isEmpty, sleep, formatFileSize };
