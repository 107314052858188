import { throttle } from 'lodash';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Modal } from 'antd';
import { trace } from "firebase/performance";
import { performance } from "../../Firebase/firebase";
import { View } from '@millicast/sdk';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import ProgressLoader from '../Common/ProgressLoader';
import { auth } from '../../Firebase';

interface IProps {
  show_id: string;
  canvasRef: any;
  stream: any;
  iceServers: any[];
  refreshKey: number;
  setLoading: (value: boolean) => void;
  isReady: () => void;
  showDominantSpeaker: boolean;
  dominantSpeakerID: any;
  localAudioMuteValue: boolean;
  localParticipantID: string;
  localParticpantTrack: any;
  dominantSpeakerTrack: any;
  showStreamUpdate: any;
  volumeMute: any;
  open: boolean;
  watermarkPanel: boolean;
  watermarkDetails: any;
  watermarkTrack: boolean;
  signOut: (arg: string) => void;
  programaticChange: boolean;
  isPublisher: boolean;
  canvasMT: number;
  canvasML: number;
  canvasWidth: number;
  leaveChat: any;
  leaveGuestInvite: any;
  leavePublisherPanel: any;
  watermarkFeatureIncluded: boolean;
  setIsStreamSelected: any;
  userId: string;
  isGuestUser: boolean;
  activeViewers: any;
  watermarkChecks: any;
  preview: boolean;
  setPreview: (value: boolean) => void;
  setRPState:any;
  setMillicastView:(value:any)=>void;
  setActiveStreamExists:(value: boolean) => void;
  setActiveStreamStatus:(value: boolean) => void;
  isMultiViewerEnabled: boolean; 
  guestDisplayName: string;
  ipAddress: string;
}

const ShowStream = (props: IProps) => {
  console.log('**** ShowStream *****');
  const {
    watermarkDetails,
    signOut,
    userId,
    isGuestUser,
    activeViewers,
    watermarkChecks,
    preview,
    setRPState,
    ipAddress,
  } = props;
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const user = get(watermarkDetails, 'user', {});
  const [errorMessage, setErrorMessage] = useState('');
  const [watermarkValues, setWatermarkValues] = useState({} as any);
  const [showModal, setShowModal] = useState(false);
  const enableAllGuests = get(watermarkDetails, "enableAllGuests", false);
  const [streamLoading, setStreamLoading] = useState(true);

  useEffect(() => {
    const elem: any = document.getElementById('video-container');
    elem.addEventListener('fullscreenchange', fullscreenchanged);
    return () => {
      props.leaveGuestInvite();
      props.leavePublisherPanel();
      props.leaveChat();
      elem.removeEventListener('fullscreenchange', fullscreenchanged);
    };
  }, []);

  const fullscreenchanged = () => {
    const videoElem: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;

    if (
      document.fullscreenElement &&
      document.fullscreenElement.id === 'video-container' &&
      videoElem
    ) {
      videoElem.controls = true;
    } else {
      videoElem.controls = false;
    }
  };


  const [streamResponse, setStreamResponse] = useState({
    url: '',
    jwt: ''
  });

  useEffect(() => {
    const videoElement: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
    /* 
      SAFARI STREAMING ISSUE:  
      We are changing the url to urls because each ice server may in 
      fact have multiple ways (URLs) in which it can be located, 
      depending on network topology.
    */

    if(!streamResponse?.url ||  !streamResponse?.jwt ){
      return;
    }
    props.iceServers.map((val: any) => {
      val.urls = val.url
      return val
    })
    const tokenGenerator = async () => {
      return {
        wsUrl: streamResponse?.url,
        urls: [streamResponse?.url],
        jwt: streamResponse?.jwt,
        iceServers: props.iceServers,
      };
    };

    const millicastView = new View(props.stream.stream_label, tokenGenerator, videoElement);

    millicastView.on('track', (event: any) => {
      // Create Datazoom context for the video element
      if('datazoom' in window){
        (window as any).datazoom.createContext(videoElement);
      }
      videoElement.srcObject = event.streams[0];
      videoElement.volume = 0.9;
    });

    millicastView.connect().catch((e: any) => {
      console.log('Connection failed, handle error', e);
      props.setLoading(false);
    });

    millicastView.on('reconnect', (err: any) => {
      console.log('Error connecting in', err.timeout);
      console.error(err.error);
    });

    const activeSources = new Set();

    millicastView.on('broadcastEvent', (event: any) => {
      const { name, data } = event;
      switch (name) {
        case 'active':
          activeSources.add(data.sourceId);
          console.log('Active Stream.');
          // add default params to initStats
          millicastView.webRTCPeer?.initStats({autoInitStats:true,statsIntervalMs:1000})
          setRPState((prevState: any) => ({
            ...prevState,
            hudStatsDisplay: false,
          }));
          props.setActiveStreamExists(true)
          props.setActiveStreamStatus(true)
          break;
        case 'inactive':
          activeSources.delete(data.sourceId);
          if (activeSources.size === 0) {
            setRPState((prevState: any) => ({
              ...prevState,
              hudStatsDisplay: false,
            }));
            console.log('No active Stream.');
            props.setActiveStreamExists(false)
            props.setActiveStreamStatus(false)
            millicastView.webRTCPeer?.stopStats()
          }
          break;
        default:
          break;
      }
    });
    props.setMillicastView(millicastView)

    return () => {
      millicastView.stop();
    };
  }, [streamResponse, props.iceServers])


  useEffect(() => {
    props.isReady();

    const connectToStream = async () => {
      const traceTrack = trace(performance, "StreamVideoLoader")
      try {
        traceTrack.start()
        // Subscribe to Stream
        console.log('** Subscribe to stream');
        props.setActiveStreamExists(false)
        props.setActiveStreamStatus(false)
        setStreamLoading(true);
        props.setIsStreamSelected(false);
        const input = {
          show_id: props.show_id,
          stream_id: props.stream.stream_id,
          client_type: 'web',
        };
        const data = {
          api: api.streams.subscribeStream,
          payLoad: JSON.stringify(input),
        };

        const result: any = await backend.save(data, get(authValue, 'accessToken', {}));

        if (result && result.jwt && result.url) {
          setStreamResponse(result)
        }
      } catch (err: any) {
        let message = 'Stream is NOT Live !';
        const videoElement: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
        if(videoElement){
          videoElement.srcObject = null;
        }
        console.log(err);
        if (err && err.status === 'fail') {
          message = err.data.message;
          console.log(message);
        }
        setStreamResponse({
          url: '',
          jwt: ''
        })
        setShowModal(true);
        props.setActiveStreamExists(false);
        props.setActiveStreamStatus(false);
      } finally {
        console.log('**** Finally Connect Process is done !!');
        setStreamLoading(false);
        traceTrack.stop();
      }
    };

    if (props.stream && props.show_id && props.iceServers.length) {
      connectToStream();
    }
  }, [props.show_id, props.stream, props.iceServers, props.refreshKey]);

  useEffect(() => {
    const dominantSpeakerContainer: any = document.getElementsByClassName('dominant-speaker-view');

    if (!props.showDominantSpeaker && !!dominantSpeakerContainer) {
      while (!!dominantSpeakerContainer[0] && dominantSpeakerContainer[0].firstChild) {
        dominantSpeakerContainer[0].removeChild(dominantSpeakerContainer[0].lastChild);
      }
    }
    const elem: HTMLVideoElement = document.getElementById('main-stream-video') as HTMLVideoElement;
    if (!!elem) {
      elem.muted = props.showDominantSpeaker ? true : props.volumeMute;
    }
  }, [
    props.showDominantSpeaker,
    props.localAudioMuteValue,
    props.dominantSpeakerID,
    props.localParticpantTrack,
    props.dominantSpeakerTrack,
    props.showStreamUpdate,
  ]);

  const mutationRef = useRef(1);
  const parentMutationRef = useRef(0);

  useEffect(() => {
    setWatermarkValues(get(props.watermarkDetails, 'values', {}));
    mutationRef.current = 1;
    parentMutationRef.current = 1;
  }, [props.watermarkDetails, props.programaticChange]);

  const waterMarkObserver = async (targetObserver: any) => {
    const mutationConfig = {
      attributes: true,
      // attributeFilter: ["style"],
      subtree: true,
      childList: true,
      attributeOldValue: true,
      characterDataOldValue: true,
      // NodeList:true,
    };
    const mutationList = document.querySelector('.show-watermark-ui');
    if (mutationList) {
      targetObserver.observe(mutationList, mutationConfig);
      return targetObserver;
    }

    return null;
  };

  const waterMarkParentObserver = async (targetObserver: any) => {
    const parentMutationConfig = {
      attributes: true,
      // attributeFilter: ["style"],
      subtree: true,
      childList: true,
      attributeOldValue: true,
      characterDataOldValue: true,
      // NodeList:true,
    };
    const parentMutationList = document.querySelector('.video-container');
    if (parentMutationList) {
      targetObserver.observe(parentMutationList, parentMutationConfig);
      return targetObserver;
    }

    return null;
  };

  useEffect(() => {
    if (props.watermarkFeatureIncluded) {
      const targetObserver = new MutationObserver(onMutation);
      const parentObserver = new MutationObserver(onParentMutation);
      waterMarkObserver(targetObserver);
      waterMarkParentObserver(parentObserver);
      return () => {
        targetObserver.disconnect();
        parentObserver.disconnect();
      };
    }
  }, []);

  let triggered = false;
  const onMutation = (mutations: any) => {
    mutations.forEach((mutation: any) => {
      const removedNodes = Array.from(mutation.removedNodes);

      if (mutation.type === 'characterData' && triggered === false) {
        triggered = true;
        signOut('waterMarkFlag');
      } else if (
        mutation.type === 'childList' &&
        !!removedNodes &&
        removedNodes.length > 0 &&
        triggered === false
      ) {
        triggered = true;
       signOut('waterMarkFlag');
      }
    });
    if (mutationRef.current === 0) {
      mutations.forEach((mutation: any) => {
        if (mutation.type !== 'childList' && triggered === false) {
          triggered = true;
         signOut('waterMarkFlag');
        }
      });
    }
    mutationRef.current = 0;
  };

  const onParentMutation = (mutations: any) => {
    mutations.forEach((mutation: any) => {
      const removedNodes = Array.from(mutation.removedNodes);

      if (
        mutation.type === 'childList' &&
        !!removedNodes &&
        removedNodes.length > 0 &&
        triggered === false
      ) {
        triggered = true;
        signOut('waterMarkFlag');
      }
    });
    if (parentMutationRef.current === 0 && triggered === false) {
      mutations.forEach((mutation: any) => {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          triggered = true;
         signOut('waterMarkFlag');
        }
      });
    }
    parentMutationRef.current = 0;
  };

  // Watermark use effect
  const renderDominantSpeakerView = () => {
    // Get speaker div
    let dominantSpeakerDiv: any = null;
    let track: any = {};
    let tagName = '';

    if (props.showDominantSpeaker) {
      if (!!props.dominantSpeakerID) {
        dominantSpeakerDiv = document.getElementById(props.dominantSpeakerID);
        track = props.dominantSpeakerTrack;
      } else {
        dominantSpeakerDiv = document.getElementById(props.localParticipantID);
        track = props.localParticpantTrack;
      }
    }

    if (!!dominantSpeakerDiv) {
      const div = document.createElement('div');
      div.id = `${dominantSpeakerDiv.id}_clone`;
      // div.style.height = "100%";
      // div.style.width = "calc(100% - 60px)";
      div.setAttribute('class', 'dominant-videoparticipant');

      let images = dominantSpeakerDiv.getElementsByTagName('img');

      if (images.length > 0) {
        const image = document.createElement('img');
        image.src = images[0].getAttribute('src');
        image.style.height = '100%';
        image.style.width = '100%';
        div.insertBefore(image, div.childNodes[0]);
        tagName = 'IMAGE';
      } else {
        if (!!track && track.attach && typeof track.attach === 'function') {
          div.appendChild(track.attach());
          tagName = 'VIDEO';
        }
      }

      const dominantSpeakerContainer: any =
        document.getElementsByClassName('dominant-speaker-view');

      if (
        dominantSpeakerContainer[0].getElementsByClassName('dominant-videoparticipant').length > 0
      ) {
        return null;
      }

      if (div.id !== dominantSpeakerContainer[0].childNodes[0]?.id) {
        while (dominantSpeakerContainer[0].firstChild) {
          dominantSpeakerContainer[0].removeChild(dominantSpeakerContainer[0].lastChild);
        }
        dominantSpeakerContainer[0].appendChild(div);
      } else {
        // if(tagName !== dominantSpeakerContainer[0]?.childNodes[0]?.childNodes[0]?.tagName){
        while (dominantSpeakerContainer[0]?.firstChild) {
          dominantSpeakerContainer[0].removeChild(dominantSpeakerContainer[0].lastChild);
        }
        dominantSpeakerContainer[0].appendChild(div);
        // }
      }
    }

    return null;
  };

  const getDominantSpeakerShowStatus = () => props.showDominantSpeaker;

  const scrollDirection = get(watermarkDetails, "scroll", null);
  const getDisplay = () => {
    let waterMarkDisplay;
    if (!!props.isMultiViewerEnabled) {
      waterMarkDisplay = 'none';
      return waterMarkDisplay;
    }
    if (isGuestUser) {
      waterMarkDisplay = enableAllGuests ? 'block' : 'none';
    } else waterMarkDisplay = !(userId in user) || user[userId] ? 'block' : 'none';

    return waterMarkDisplay;
  };

  const getWaterMarkStyles = () => {
    const styles = get(watermarkDetails, 'styles', {});
    if (!!scrollDirection) {
      const updatedStyles: any = {
        ...styles,
        display: getDisplay(),
      };
      return updatedStyles;
    }
    const updatedStyles: any = {
      ...styles,
      marginTop: styles.bottom !== 0 ? `${props.canvasMT}px` : "0",
      marginBottom: styles.bottom === 0 ? `${props.canvasMT}px` : "0",
      marginLeft: styles.right !== 0 ? `${props.canvasML}px` : "0",
      marginRight: styles.right === 0 ? `${props.canvasML}px` : "0",
      display: getDisplay(),
    };
    return updatedStyles;
  };

  const getLocalWaterMarkVales = () => {
    const user = auth.getUser();
      const displayName = get(user, 'displayName', '');
      const email = get(user, 'email', '');
      return {
        'Name': props.guestDisplayName || displayName,
        'Email': email,
        'DateTime': new Date().toLocaleString(),
        'IPAddress': ipAddress
      }
  }

  const getWaterMarkValues = () => {
    mutationRef.current = 1;
    parentMutationRef.current = 1;

    const watermarkCanvas: any = document.getElementById('watermarkCanvas');

    if (!!watermarkCanvas) {
      const ctx = watermarkCanvas.getContext('2d');

      const styles: any = get(watermarkDetails, 'styles', {});
      ctx.font = `${styles.fontSize} 'Nunito Sans', sans-serif`;
      ctx.fillStyle = styles.color;
      ctx.opacity = styles.opacity;

      //setting canvas height and width
      const currentWaterMarkValues = preview ? watermarkChecks : watermarkValues;
      const wmCanvasHeight = !!Object.values(currentWaterMarkValues)
        ? (Object.values(currentWaterMarkValues).length * 20).toString()
        : '20';
      const waValuesWidthArray: any = [];
      const localWaterMarkValues = getLocalWaterMarkVales();
      if (!!Object.values(currentWaterMarkValues)) {
        Object.keys(currentWaterMarkValues).forEach((item: string) => {
          // @ts-ignore
          waValuesWidthArray.push(ctx.measureText(`${localWaterMarkValues[item] || currentWaterMarkValues[item]}`).width);
        });
      }
      const maxWidth = Math.ceil(Math.max(...waValuesWidthArray));

      ctx.canvas.width = maxWidth;
      ctx.canvas.height = wmCanvasHeight;

      ctx.font = `${styles.fontSize} 'Nunito Sans', sans-serif`;
      ctx.fillStyle = styles.color;
      ctx.opacity = styles.opacity;
      ctx.wordWrap = 'break-word';

      let y = 16;
      const lineHeight = 20;
      const fillText = (key: string, value?: string) => {
        if (!!currentWaterMarkValues[key]) {
          // @ts-ignore
          ctx.fillText(localWaterMarkValues[key] || currentWaterMarkValues[key], 0, y);
          y = y + lineHeight;
        }
      };
        if (!!currentWaterMarkValues['Name']) fillText('Name');
        if (!isGuestUser && !!currentWaterMarkValues['Email']) fillText('Email');
        if (!!currentWaterMarkValues['DateTime']) fillText('DateTime');
        if (!!currentWaterMarkValues['IPAddress']) fillText('IPAddress');
        if (!!currentWaterMarkValues['CustomTexts']) fillText('CustomTexts');
      
    }
    return null;
  };

  return (
    <div className='viewerstream'>
      <div className='viewcontainer'>
        {/* <div className="vieweraspectratio"> </div> */}

        <div className='dominant-speaker-view'>
          {getDominantSpeakerShowStatus() && renderDominantSpeakerView()}
        </div>
        {/* <Spin spinning={streamLoading} size={"large"} tip={"Stream Loading"} > */}
        <div
          id='video-container'
          className={getDominantSpeakerShowStatus() ? 'd-none video-container' : 'video-container'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <video
            className='viewerstream-video'
            id='main-stream-video'
            width='100%'
            height='100%'
            controls={false}
            autoPlay
            disablePictureInPicture
            playsInline
            key={'videoPlayer'}
          ></video>
          <canvas
            className='viewerstream-overlay'
            ref={props.canvasRef}
            id='canvas'
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: 2,
              top: '0',
              left: '0',
              display: !!props.isMultiViewerEnabled ? 'none' : 'block'
            }}
          ></canvas>
          <div
            key={'watermark'}
            id='watermark'
            className='show-watermark-ui'
            style={getWaterMarkStyles()}
          >
            <div
              key={'watermarkvalues'}
              className={
                scrollDirection
                  ? scrollDirection === 'left'
                    ? 'marquee-left'
                    : 'marquee-right'
                  : ''
              }
              style={{
                marginLeft: !!props.canvasWidth && scrollDirection ? `${props.canvasML}px` : '0',
                width: !!props.canvasWidth && scrollDirection ? `${props.canvasWidth}px` : '100%',
              }}
            >
              <div>
                <canvas id='watermarkCanvas'></canvas>
                {getWaterMarkValues()}
              </div>
            </div>
          </div>
        </div>
        {/* </Spin> */}
      </div>
      <ProgressLoader loading={streamLoading} size={'mini'} />
      <Modal
        open={showModal}
        centered={true}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            type='primary'
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className='btn-close'>
          Stream is not currently running. Please reload the page once the publisher starts the
          stream.
        </div>
      </Modal>

    </div>
  );
};

export default ShowStream;
