import { useContext } from 'react';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { get } from '../../../Utils/helpers';
import { AuthContext } from '../../../Context/authContext';

export default function useGuestSessions(show_id: string) {
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const updateGuestSession = async (guestSession: {}, vc_id: string) => {
    try {
      const streamId = sessionStorage.getItem('stream_id');
      const data = {
        api: api.vc.updateGuestSession,
        urlParam: vc_id,
        payLoad: {
          guest_session: guestSession,
          showStream_id: streamId,
          show_id: show_id,
        },
      };
      await backend.save(data, get(authValue, 'accessToken', {}));
    } catch (err: any) {
      console.log(err);
    }
  };

  const getGuestSession = async (vc_id: string) => {
    try {
      const data = {
        api: api.vc.getGuestSession,
        urlParam: vc_id,
      };
      const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
      return result;
    } catch (err: any) {
      console.log(err);
    }
  };
  return { updateGuestSession, getGuestSession };
}
