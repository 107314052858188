import api from "./Api";
import backend from "./Backend";


// fetch country code data
export const countryCodeData = async (token: string) => {
    try {
        const countryCodesData = {
            api: api.countryCodes.listAll,
        };
       return await backend.fetch(countryCodesData, token);
    }
    catch (error) {
        console.log('error while fetching country code data:::', error)
    }
}