import { useState, useEffect, useContext } from 'react';
import { Drawer, Form, TableProps } from 'antd';
import { Table, Space, Button, notification, Tooltip, Input } from 'antd';
import { Checkbox } from "semantic-ui-react";
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { AuthContext } from '../../Context/authContext';
import { get } from '../../Utils/helpers';
import Download from './Download';
import ProgressLoader from '../Common/ProgressLoader';
import classNames from "classnames";

interface DataType {
  password_attempt_left: number;
  key: string;
  package_name: string;
  sender: string;
  file_name: string;
  package_expiry: string;
  created_ts: string;
  description: string;
  access_attempt: number;
  download_limit: number;
  status: string;
  dataString:string;
}
const { Search } = Input;
function Inbox(props: any) {
  const [form] = Form.useForm();
  const { show_id } = props;
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
  const [inboxData, setInboxData] = useState<DataType[]>([]);
  const [selectPackage, setSelectPackage] = useState(null as any);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [activeChecked, setActiveChecked] = useState(true);
  const [checkedAll, setCheckedAll] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [packageName, setPackageName] = useState("");
  const [showFailedDownloadsDrawerOpen, setFailedDownloadsDrawerOpen] = useState(false);
  const [failedDownloadsData, setFailedDownloadsData] = useState<DataType[]>([]);
  const [recLoading, setRecLoading] = useState(false);
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
 
  useEffect(() => {
    fetchInbox();
  }, [show_id, refreshState]);

  const fetchInbox = async () => {
    try {
      setLoading(true);
      const inboxPayload = {
        api: api.massive.recipients,
        urlParam: show_id,
      };
      const inboxResult = await backend.fetch(inboxPayload, get(authValue, 'accessToken', ''));

      const data = inboxResult
        .map((data: any) => ({
          package_name: get(data, 'package_name', ''),
          file_name: data.file.map((file: any) => file.name),
          created_ts: new Date(
            data.created_ts._seconds * 1000 + data.created_ts._nanoseconds / 1000000,
          ).toDateString(),
          package_expiry: new Date(data.package_expiry).toDateString(),
          key: data.massive_upload_id,
          sender: get(data,'sender',''),
          sender_name: get(data, 'sender_name', '') || 'DELETED USER',
          description: get(data, 'description', ''),
          id: get(data, 'package_id', ''),
          passwordRequired: data.password_required,
          downloadBlocked: data.download_blocked,
          download_limit: get(data, 'download_limit', 0),
          password_attempt_left: get(data, 'password_attempt_left', 0),
          access_count: get(data, 'access_count', 0),
          recipient_doc_id: get(data, 'id', ''),
          access_attempt: get(data, 'access_attempt', 0),
          status:
            get(data, 'status', '') === 'active' &&
            new Date().getTime() < new Date(data.package_expiry).getTime()
              ? 'active'
              : 'inactive',
        }))
        .map((data: DataType) => ({ ...data, dataString: getSearchString(data) }))
        .sort((a: any, b: any) => {
          const dateA = new Date(a.created_ts);
          const dateB = new Date(b.created_ts);
          return dateB.getTime() - dateA.getTime();
        });

      setInboxData(data);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchFailedDownloadsData = async () => {
    try {
      setRecLoading(true);
      const inboxPayload = {
        api: api.massive.failedDownloads,
        urlParam: show_id,    
      };
      const inboxResult = await backend.fetch(inboxPayload, get(authValue, 'accessToken', ''));
      setFailedDownloadsData(inboxResult)
    } catch (err: any) {
      console.error(err);
    } finally {
        setRecLoading(false);
    }
  }
  const showFailedDownloadsDrawer = () => {
    setFailedDownloadsDrawerOpen(true);
  }
  const closeDrawer = () => {
    setFailedDownloadsDrawerOpen(false);
  };
  const getPassword = async (package_id: string) => {
    try {
      setButtonLoader(true);
      const input = {
        api: api.massive.getPassword,
        payLoad: JSON.stringify({ package_id: package_id, show_id: show_id }),
      };
      const result = await backend.save(input, get(authValue, 'accessToken', ''));
      if (result) {
        notification.success({
          message: result,
        });
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setSortedInfo(sorter as SorterResult<DataType>);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Sender',
      dataIndex: 'sender_name',
      key: 'sender_name',
      sorter: (a, b) => a.sender.length - b.sender.length,
      sortOrder: sortedInfo.columnKey === 'sender' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Package Name',
      dataIndex: 'package_name',
      key: 'package_name',
      sorter: (a, b) => a.package_name.length - b.package_name.length,
      sortOrder: sortedInfo.columnKey === 'package_name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Created At',
      dataIndex: 'created_ts',
      key: 'created_ts',
      sorter: (a, b) => {
      const dateA = new Date(a.created_ts);
      const dateB = new Date(b.created_ts);
      return dateB.getTime() - dateA.getTime() // Compare in reverse order (newest to oldest)
    },
    sortOrder: sortedInfo.columnKey === 'created_ts' ? sortedInfo.order : null,
    ellipsis: true,
    },
    {
      title: 'Expire At',
      dataIndex: 'package_expiry',
      key: 'package_expiry',
      sorter: (a, b) => {
      const dateA = new Date(a.package_expiry);
      const dateB = new Date(b.package_expiry);
      return dateB.getTime() - dateA.getTime() // Compare in reverse order (newest to oldest)
      },
      sortOrder: sortedInfo.columnKey === 'package_expiry' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (data, row) => {
        let tooltipTitle = '';
    
        if (row.password_attempt_left <= 0) {
          tooltipTitle = 'You cannot download the file because of max wrong password attempts.';
        } else if (row.access_attempt >= 10) {
          tooltipTitle = 'Package is blocked due to recipients tried max password attempts. Contact Sender!';
        } else if (row.download_limit === 0) {
          tooltipTitle = 'Reached your download limit';
        } else if (get(row,'downloadBlocked',false)) {
          tooltipTitle = 'Sender has been deleted for this package';
        }

        const isDisabled = (
          row.status === 'inactive' ||
          get(row,'downloadBlocked',false)
        );

        return (
          <>
          <Space size='middle'>
          <Tooltip title={tooltipTitle}>
              <Button
                type='primary'
                className={`upload-btn ${isDisabled ? 'upload-btn-disabled' : ''}`}
                disabled={isDisabled}
                onClick={() => {
                  setSelectPackage(row);
                  setIsModalOpen(true);
                }}
              >
                Download
              </Button>
            </Tooltip>
          </Space>
          </>
        );
      },
    }
  ];

     /* Failed Downloads Column */
     const columnsFailedDownloads: ColumnsType<DataType> = [
      {
        title: 'Sender',
        dataIndex: 'senderName',
        key: 'senderName',
      },
      {
        title: 'Package Name',
        dataIndex: 'packageName',
        key: 'packageName',
        sorter: (a, b) =>{
          return get(a, "packageName", "").localeCompare(get(b, "packageName",""));
        }
      },
      {
        title: 'Created_ts',
        dataIndex: 'created_ts',
        key: 'created_ts',
        render:(_,data) => (
            <div>{ new Date(data.created_ts).toDateString()}</div>
        ),
        sorter: (a, b) => new Date(a.created_ts).getTime() - new Date(b.created_ts).getTime(),
      },
      {
        title: 'Reason For Fail',
        dataIndex: 'reasonForFailed',
        key: 'reasonForFailed',
      },
      {
        title: 'Status',
        dataIndex: '',
        key: '',
        render: (data, row) => (
          <span>Failed</span>
        )
      },
     
    ];
  const handleActiveChecked = (e: any, data:any) => {
    const value = data.checked;
    setCheckedAll(!value)
    console.log("Acive data",value);
    setActiveChecked(value)
  }
  const handleCheckedAll = (e: any, data:any) => {
    const value = data.checked;
    setActiveChecked(!value)
    setCheckedAll(value)
  }
  const getDownloadProps = () => ({
    inboxData,
    selectPackage,
    isModalOpen,
    setIsModalOpen,
    authValue,
    show_id,
    getPassword,
    buttonLoader,
    setRefreshState,
    refreshState,
  });

  const getSearchString = (rec:DataType) => {
    const packageName: string = get(rec, "package_name", "");
    const status: string = get(rec, "status", "");
    const desc: string = get(rec, "description", "");
    const sender: string = get(rec, "sender_name","");
    return`${packageName} ${status} ${desc} ${sender}`
            .toLowerCase()
  }

  return (
    <>
      <div className=''>
        <p className="heading-text-mailbox">
          Inbox
        </p>
      </div>
      <ProgressLoader loading={loading} />
      <div className='inbox-wrapper'>
      <div className='mailbox-search'>
          <Search
              placeholder="Search"
              allowClear
              className={classNames('')}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
          />
          
          <Checkbox
            label="Active"
            name="Active"
            className='checkbox-status'
            checked={activeChecked}
            onChange={handleActiveChecked}
          />
          <Checkbox
            label="All"
            name="All"
            className='checkbox-status'
            checked={checkedAll}
            onChange={handleCheckedAll}
          />
          <Button 
          className='upload-btn failed-tansaction-btn'
          type="primary"
          onClick={e => {showFailedDownloadsDrawer(); fetchFailedDownloadsData()}}
          >
          Failed Downloads
        </Button>
        </div>
      <Table
        size={'small'}
        className='inbox-wrapper-table recipients-table table-row-without-border'
        //bordered
        columns={columns}
        dataSource={inboxData
          .filter((data:DataType) => {
            const checks = (checkedAll && get(data,'status','')) || (activeChecked && get(data,'status','') === 'active');
            const searchFilter = searchText ? get(data,'dataString','').includes(searchText.toLowerCase()) : true;
            return checks && searchFilter;
          })}
        onChange={handleChange}
        rowClassName={rec => (rec.status === 'inactive' ? "inactive-row" : '')}
      />
      </div>
      {isModalOpen && <Download download={getDownloadProps()} />}

      <Drawer
        width={900}
        onClose={closeDrawer}
        open={showFailedDownloadsDrawerOpen}
      >
        <div className='inbox-wrapper'>
          <Form form={form} component={false}>
            <Table
              className='inbox-wrapper-table recipients-table'
              loading={recLoading}
              bordered
              dataSource={failedDownloadsData}
              columns={columnsFailedDownloads}
              onChange={handleChange}
            />
          </Form> 
          
        </div>
      </Drawer>
    </>
  );
}

export default Inbox;
