import React, { useState, useEffect } from "react";

interface IProps {
  searchText: string
  searchLabel: string
  searchSubmit(term: any): void;
}

const Search = (props: IProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchTerm(props.searchText)
  }, [props.searchText])

  const onSearchTerm = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    if (props.searchSubmit) {
      props.searchSubmit(searchTerm);
    }
  };

  return (
    <div className="ui search">
      <form className="ui form" onSubmit={onSearchTerm}>
        <div className="field">
          <label> {props.searchLabel} </label>
          <div className="ui icon input">
            <input
              className="prompt"
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />

            <i className="search icon"></i>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Search;
