import { Modal } from "antd";

interface ConfirmationModalProps {
  onOk: () => void;
  onCancel: () => void;
  open: boolean;
  data: any;
  okButtonProps?: any;
  className?: string;
}
const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <Modal
      className={props.className}
      centered={true}
      bodyStyle={{ height: "auto" }}
      open={props.open}
      onOk={props.onOk}
      onCancel={props.onCancel}
      okButtonProps={props.okButtonProps}
    >
      {props.data}
    </Modal>
  );
};

export default ConfirmationModal;
