import api from "../../../Service/Api";
import backend from "../../../Service/Backend";
import * as constants from "../../../Utils/constants";

const { REACT_APP_ENABLE_LOGGER = false } = process.env

const apiFetch = async (payload: any, token: string) => {
    try {
       return await backend.fetch(payload, token);
    } catch(err) {
        console.log("err", err);
    }
}

const apiSave = async (payload: any, token: string) => {
    try {
       return await backend.save(payload, token);
    } catch(err) {
        console.log("err", err);
    }
}

export const checkAccess = async (show_id: string, token: string) => {
    const publisherShowUserRole ={
        api: api.users.isShowPublisher,
        queryParam: { show_id },
    };
    return await apiFetch(publisherShowUserRole, token);
}

export const getActiveStreams = async (show_id: string, token: string) => {
    const callStartTime = new Date();
    const activeStreamData = {
        api: api.streams.pubActiveStream,
        queryParam: { show_id }
    }; 
    const result =  await apiFetch(activeStreamData, token);
    const callEndTime = new Date();
    
    if (REACT_APP_ENABLE_LOGGER) {
        logData(callStartTime, callEndTime, show_id, activeStreamData.api, token);
    }
    return result;
}

export const logData = async (callStartTime: any, callEndTime: any, show_id: string, path: any, token: string) => {
    const timeTaken: any = callEndTime.getTime() - callStartTime.getTime();
    const logData = {
        api: api.log.log,
        payLoad: JSON.stringify({
          logFrom: constants.PAGES.SHOW_PUBLISHER_PAGE,
          data: {
            startTime: callStartTime,
            endTime: callEndTime,
            timeTaken: timeTaken,
            api: path,
            show_id: show_id
          }
        })
      };
    await apiSave(logData, token);
}