import { useContext, useState } from 'react';
import { CaretRightFilled, ReloadOutlined } from '@ant-design/icons';
import { Button, Space, Modal } from 'antd';
import { EncoderContext } from '../hooks/useEncoderContext';
import useEncoder from '../hooks/useEncoder';
import useDbTrigger from '../../../hooks/useDbTrigger';
import { get } from '../../../Utils/helpers';
import PauseOutlined from '../../../Images/encoder-stop.png';
let streamingStatus = false;
let loading =false
export default function EncoderActions() {
  const streamingNotifications = useDbTrigger('devices', true);
  const deviceTriggerData = streamingNotifications?.doc?.data();
  const { setConfig, reboot, pauseEnd } = useEncoder();
  const encoderValues = useContext(EncoderContext);
  const {
    videoInput,
    videoBitrate,
    videoResolution,
    colorFormat,
    fps,
    showId,
    deviceList,
    playBtnRef,
    stopBtnRef,
    playLoading,
    stopLoading,
  } = encoderValues;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalHeader, setIsModalHeader] = useState('');
  const [isModalBody, setIsModalBody] = useState('');

  const id = get(deviceList, '0.id', '');
  const streaming = get(deviceList, '0.streaming', false);

  streamingStatus = !!streaming;

  if (
    streamingNotifications.type === 'modified' &&
    id === streamingNotifications?.doc?.id &&
    deviceTriggerData
  ) {
    console.log('deviceTriggerData?.streaming', deviceTriggerData?.streaming);
    if(!deviceTriggerData?.streaming) loading = false;
    if (streamingStatus !== deviceTriggerData?.streaming) {
      streamingStatus = deviceTriggerData?.streaming;
    }
  }
  console.log('streamingStatus', streamingStatus);

  if (!!streamingStatus) {
    playBtnRef?.current?.setAttribute('disabled', true);
    stopBtnRef?.current?.removeAttribute('disabled');
  } else if (!streamingStatus) {
    playBtnRef?.current?.removeAttribute('disabled');
    stopBtnRef?.current?.setAttribute('disabled', true);
  }
  if (!!playLoading) {
    stopBtnRef?.current?.setAttribute('disabled', true);
  }

  if (!!stopLoading) {
    playBtnRef?.current?.setAttribute('disabled', true);
  }

  const handleStart = () => {
    console.log('handleStart-id', id);
    try {
      setConfig({
        videoInput,
        videoBitrate,
        videoResolution,
        colorFormat,
        fps,
        id,
        show_id: showId,
      });
    } catch (error) {
      console.error('start-error', error);
    } finally {
    }
  };
  const GetModel = () => {
    return (
      <Modal
        title={isModalHeader}
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          reboot();
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>{isModalBody}</p>
      </Modal>
    );
  };
  return (
    <div className='encoder-action-wrapper'>
      <div className='encoder-action-btn'>
        <Space>
          <Button
            ref={playBtnRef}
            icon={<CaretRightFilled />}
            onClick={handleStart}
            size='large'
            loading={(playLoading && !streamingStatus) || loading }
          />
          <Button
            ref={stopBtnRef}
            icon={<img src={PauseOutlined} alt='stop device' />}
            size='large'
            onClick={() => {
              loading = true;
              pauseEnd('end');
            }}
            loading={stopLoading && streamingStatus}
          />
          <Button
            icon={<ReloadOutlined />}
            size='large'
            onClick={() => {
              setIsModalHeader('Reboot Confirmation');
              setIsModalBody('Are you sure you want to reboot on this device?');
              setIsModalOpen(true);
            }}
          />
        </Space>
      </div>
      <div className='encoder-action-label'>
        <Space>
          <label>play</label>
          <label>stop</label>
          <label>reboot</label>
        </Space>
      </div>
      <GetModel />
    </div>
  );
}
