import { create } from 'zustand';

interface StoreState {
  preview: boolean;
  // watermarkChecks: [];
  setPreview: (preview: boolean) => void;
  // setWatermarkChecks: (watermarkChecks: []) => void;
  watermarkDetails: {};
  setWatermarkDetails: (watermarkDetails: {}) => void;
}

export const useWatermarkStore = create<StoreState>((set) => ({
  preview: false,
  // watermarkChecks: [],
  setPreview: (preview) => set({ preview }),
  // setWatermarkChecks: (watermarkChecks) => set({ watermarkChecks }),
  watermarkDetails: {},
  setWatermarkDetails: (watermarkDetails) => set({ watermarkDetails }),
}));

interface WatermarkChecksStoreState {
  watermarkChecks: [];
  setWatermarkChecks: (watermarkChecks: []) => void;
}

export const useWatermarkChecksStore = create<WatermarkChecksStoreState>((set) => ({
  watermarkChecks: [],
  setWatermarkChecks: (watermarkChecks) => set({ watermarkChecks }),
}));