import React, { MouseEvent } from "react";
import { ActiveShowUserRole } from "./structs";
import { ROLES, PAGES } from "../../Utils/constants";
import teamAdmin from "../../Images/team_admin.svg";
import childShow from "../../Images/child-show.svg";
interface IProps {
  showUserRole: ActiveShowUserRole;
  index: number;
  selectShow: (e: any, showUserRole: ActiveShowUserRole) => void;
  someNote: boolean;
}

const UserShowRole = (props: IProps) => {
  const handleNoteLength =
    props.someNote && props.showUserRole.show_comments
      ? props.showUserRole.show_comments.length > 20
      : false;
  const getRow = () => (
    <tr
      className="header-cell-without-border"
      key={props.index}
      style={{ cursor: "pointer" }}
      onClick={(e: MouseEvent<HTMLTableRowElement>) => {
        props.selectShow(e, props.showUserRole);
      }}
    >
      <td className="play-icon">
        {props.showUserRole.role_type === ROLES.SHOW_FINANCE
          ? "Show Finance" : props.showUserRole.role_type === ROLES.SUPER_ADMIN ? ROLES.SUPER_ADMIN
          : props.showUserRole.show_name}
      </td>
      <td>
        {props.showUserRole.role_type === ROLES.SHOW_FINANCE
          ? "SHOW_FINANCE" : props.showUserRole.role_type === ROLES.SUPER_ADMIN ? 'SUPER_ADMIN'
          : props.showUserRole.show_code}
      </td>
      <td>{props.showUserRole.role_type === ROLES.SUPER_ADMIN ? 'Active' : props.showUserRole.show_status}</td>

      {/* 
      
      */}

      <td className="userrole-col">
        {props.showUserRole.role_type === ROLES.SHOW_ADMIN &&
        props.showUserRole.team_admin ? (
          <>
            <span>Team Admin</span>
            <span
              className="team-admin-image ui"
              data-tooltip="Team Admin"
              data-position="top center"
            >
              <img src={teamAdmin} className="teamAdmin-img" alt="Save" />
            </span>
          </>
        ) : !!props.showUserRole.parent_show_id ? (
          <>
            <span>{props.showUserRole.role_type}</span>
            <span
              className="team-admin-image ui"
              data-tooltip="Child Show"
              data-position="top center"
            >
              <img src={childShow} className="teamAdmin-img" alt="Save" />
            </span>
          </>
        ) : (
          <span style={{textTransform: "capitalize"}}>{props.showUserRole.role_type}</span>
        )}
      </td>
      {props.someNote && (
        <td className="show-note-data">
          {!!handleNoteLength ? (
            <span
              className="ui"
              data-position="left center"
              data-tooltip={props.showUserRole.show_comments}
            >
              {props.showUserRole.show_comments.slice(0, 20)}...
            </span>
          ) : (
            props.showUserRole.show_comments
          )}
        </td>
      )}
    </tr>
  );
  return props.showUserRole.role_type === ROLES.SHOW_ADMIN &&
    props.showUserRole.team_admin ? (
    getRow()
  ) : !props.showUserRole.team_admin ? (
    getRow()
  ) : (
    <></>
  );
};
export default UserShowRole;
