import React, { useState } from "react";
import { Icon, Input, Button, Dropdown, Popup, Grid, Modal } from "semantic-ui-react";
//import ReactGiphySearchbox from 'react-giphy-searchbox'

interface IProps {
  hideGiphyModal: () => void
}
const GiphyModal = (props: IProps) => {
    const [isModalOpen, setModalStatus] = useState(false);

    return (
      <div style={{width: '950px'}}>
          <Modal trigger={<Icon size="big" className="giphy-btn"/>}>
            {/* <Modal.Header>Profile Picture</Modal.Header> */}
            <Modal.Content style={{
        backgroundColor: '#6435c9',
        width: '950px'
      }}>
              {/* <Image wrapped size='medium' src='https://react.semantic-ui.com/images/wireframe/image.png' /> */}
              <Modal.Description>
                {/* <Header>Modal Header</Header> */}
                {/* <ReactGiphySearchbox
                  apiKey="it8nGRVrI2JXVBFSmE98oWRhdcM1odox" // Required: get your on https://developers.giphy.com
                  masonryConfig={[
                    {columns: 3, imageWidth: 300, gutter: 5 }
                  ]}
                  imageBackgroundColor="#000"
                  onSelect={(item: any) => console.log(item)}
                /> */}

          </Modal.Description>
            </Modal.Content>

          </Modal>
        </div>
      );
  };

export default GiphyModal