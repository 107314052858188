import React, { useEffect, useState, useContext } from "react";
import "./ManageLicense.css";
import { Button, Icon, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { get } from "../../Utils/helpers";
import PublisherLicences from "./PublisherLicenses";
import LicenseHistory from "./LicenseHistory";
import { AuthContext } from '../../Context/authContext';

interface IProps {
  showId: string | undefined;
  show_name: string | undefined;
  show_admin: string | undefined;
  show_admin_id: string | undefined;
  currentConfig: any;
  currentShow?: string | undefined;
  pageName: any;
  setLoading?: any;
  loading?: any;
  usersGroupsFlag?: boolean;
  setInvitedUsers: any;
  setConfigValue?: any;
  configValue?: any;
}

const ManageLicenseViewer = (props: IProps) => {
  const {
    showId,
    show_name,
    show_admin,
    show_admin_id,
    currentConfig,
    currentShow,
    pageName,
    setInvitedUsers,
    setConfigValue,
    configValue
  } = props;
  
  const currentUser = get(useContext(AuthContext), "currentUser", {} );

  const [publishers, setPublishers] = useState(null as any);
  const [showViewer, setShowViewer] = useState(null as any);
  const [historyData, setHistoryData] = useState([] as any);

  const [requestLicenceOpen, setRequestLicenceOpen] = useState(false);
  const [assignPubRole, setAssignPubRole] = useState(false);
  const [deleteUnassign, setDeleteUnassign] = useState(false);
  const [deletePubRole, setDeletePubRole] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(1);
  const [showconfig, setShowconfig] = useState(null as any);
  const [unassignedPubCount, setUnassignedPubCount] = useState(null as any);
  const [streamList, setstreamList] = useState([] as any);
  const [activePubList, setactivePubList] = useState([] as any);
  const [inactivePubList, setinactivePubList] = useState([] as any);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await Promise.all([fetchShowViewers(), fetchHistoryData()]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [assignPubRole, deleteUnassign, deletePubRole, successAlert]);

  const fetchShowViewers = async () => {
    try {
      const data = {
        api: api.users.listActiveUsersInShow,
        urlParam: showId,
        queryParam: { type: 'all' },
      };
      const invitedUsers = await backend.fetch(data, get(currentUser, 'user.accessToken', ''));
      setInvitedUsers(invitedUsers.filter((user: any) => user.role !== 'SuperAdmin'));
      const getActiveStreamList = {
        api: api.streams.listAllActive,
        urlParam: showId,
      };

      const listOfStreams = await backend.fetch(getActiveStreamList, get(currentUser, "user.accessToken", ""));
      setstreamList(listOfStreams);

      const showViewer = invitedUsers.filter(
        (user: any) => user.role === "ShowViewer"
      );
      const showPublishers = invitedUsers.filter(
        (user: any) => user.role === "ShowPublisher"
      );
      if (showPublishers.length > 0) {
        showPublishers.forEach((publisher: any) => {
          const index = showViewer.findIndex(
            (user: any) => user.user_id === publisher.user_id
          );
          if (index > -1) {
            const newviewer = showViewer.splice(index, 1);
          }
        });
      }
      setShowViewer(showViewer);
      setPublishers(showPublishers);
      if (showPublishers.length > 0) {
        let activePublisher: any = [];
        let inactivePub: any = [];
        showPublishers.map((item: any) => {
          const activePub = listOfStreams.findIndex(
            (user: any) => user.created_user_email === item.user_email,
          );
          if (activePub > -1) {
            activePublisher.push(item);
          }
          setactivePubList(activePublisher);
          if (activePub === -1) {
            inactivePub.push(item);
          }
          setinactivePubList(inactivePub);
        });
      } else {
        setinactivePubList([]);
      }

      if (!!currentShow) {
        const { parent_show_id }: any = currentShow;
        const getShowsConfig = {
          api: api.configs.listShowConfigsByShowId,
          urlParam: !!parent_show_id ? parent_show_id : showId,
          queryParam: { config_name: 'publisher_count' },
        };
        const showConfigDetails = await backend.fetch(
          getShowsConfig,
          get(currentUser, 'user.accessToken', ''),
        );
        const mergeConfig = showConfigDetails.map((showConfig: any) => {
          const filterConfig = currentConfig.filter(
            (config: any) => config.id === showConfig.config_id,
          );
          showConfig.config_enable = filterConfig.length > 0 && !!filterConfig[0].config_enable;
          return showConfig;
        });

        if (mergeConfig.length > 0) {
          setConfigValue(mergeConfig[0].config_value);
          setShowconfig(mergeConfig[0]);
          const licenceCount =
            mergeConfig[0].config_value - showPublishers.length;
          const unassignedPubCount = licenceCount < 0 ? 0 : licenceCount;
          setUnassignedPubCount(unassignedPubCount);
        }
      }
    } catch (err: any) {
      const msg = ' Error while pulling stream Viewer !';
      console.log(msg);
    }
  };

  const fetchHistoryData = async () => {
    try {
      setLoading(true);
      const getHistoryData = {
        api: api.licenseHistory.getHistory,
        queryParam: { show_id: showId },
      };
      const historyData = await backend.fetch(
        getHistoryData,
        get(currentUser, 'user.accessToken', ''),
      );

      historyData.sort((a: any, b: any) => {
        const aDate = new Date(a.action_time ? a.action_time : a._seconds);
        const bDate = new Date(b.action_time ? b.action_time : b._seconds);
        return bDate.getTime() - aDate.getTime();
      });

      setHistoryData(historyData);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (historyData.length > 0 && !!unassignedPubCount) {
      setLoading(false);
    }
  }, [historyData, unassignedPubCount]);

  const handleCountValue = (value: any) => {
    setCounter(value);
  };
  
  const confirmRequestLicence = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    let requestExists = false;
    historyData.every((historyObj: any) => {
      if (
        historyObj.showObj &&
        historyObj.showObj.id &&
        historyObj.showObj.id === showId &&
        historyObj.license_status === "pending"
      ) {
        requestExists = true;
        return false;
      }
      return true;
    });

    try {
      setLoading(true);
      setRequestLicenceOpen(false);
      const input = {
        show_id: showId,
        show_name,
        show_admin,
        license_count: counter,
      };
      const sendEmail = {
        api: api.users.requestLicense,
        payLoad: JSON.stringify(input),
      };
      const result = await backend.save(sendEmail, get(currentUser, "user.accessToken", ""));
      if (result) {
        const inputData = {
          show_id: showId,
          show_name,
          user_id: show_admin_id,
          action: "REQUEST_LICENSE",
          license_status: "pending",
          action_time: new Date().toUTCString(),
          expected_license_count: counter,
        };
        const requestLicense = {
          api: api.licenseHistory.requestLicense,
          payLoad: JSON.stringify(inputData),
        };
        await backend.save(requestLicense, get(currentUser, "user.accessToken", ""));
        setCounter(1);
        setSuccessAlert(true);
      }
    } catch (err: any) {
      console.log("err", err);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const cancelRequestLicence = () => {
    setRequestLicenceOpen(false);
    setCounter(1);
  };

  const manageAssignUser = (value: any) => {
    setLoading(true);
    setAssignPubRole(value);
  };

  const deleteUnassigned = (value: any) => {
    setLoading(true);
    setDeleteUnassign(value);
  };

  const deleteAssignedPublisher = (value: any) => {
    setLoading(true);
    setDeletePubRole(value);
  };
  
  const requestLicense = (value: any) => {
    setRequestLicenceOpen(value);
  };

  const getPublisherLicence = () => {
    return (
      <PublisherLicences
        setParentLoading={setLoading}
        loading={loading}
        configValue={configValue}
        show_name={show_name}
        showId={showId}
        show_admin={show_admin}
        show_admin_id={show_admin_id}
        publishers={publishers}
        activePublist={activePubList}
        inactivePublist={inactivePubList}
        viewers={showViewer}
        licenceApproved={unassignedPubCount}
        assignUserUpdate={manageAssignUser}
        deleteUnassign={deleteUnassigned}
        deletePubRole={deleteAssignedPublisher}
        showConfig={showconfig}
        requestLicenseFlag={requestLicense}
        pageName={pageName}
        fetchShowViewers={fetchShowViewers}
      />
    );
  };

  return (
    <div>
      <ProgressLoader loading={loading} />
      <div className="mlv-heading">
        <div>
          <div className="typography-h4">ADMIN BOARD</div>
          <div className="typography-h3">Manage Licenses</div>
        </div>
        <div>
          {loading ? (
            <Button className='mlv-req-license-button' disabled>
              <Icon name='add user' />
              <span>Request License</span>
            </Button>
          ) : (
            <Button className='mlv-req-license-button' onClick={() => setRequestLicenceOpen(true)}>
              <Icon name='add user' />
              <span>Request License</span>
            </Button>
          )}
        </div>
      </div>
      <div className="mlv-pub-license-container">
        {getPublisherLicence()}
        <LicenseHistory
          showId={showId}
          historyData={historyData}
        />
      </div>
      {requestLicenceOpen && (
        <Modal
          open={requestLicenceOpen}
          size={"tiny"}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="licence-modal"
          closeIcon
          onClose={cancelRequestLicence}
        >
          <Modal.Header className="licence-header">
            REQUEST LICENSE
          </Modal.Header>
          <Modal.Content className="licence-content">
            <div>Select the number of licenses to request.</div>
            <div className="quantity">
              <span
                className="count-minus"
                onClick={() => (counter > 1 ? setCounter(counter - 1) : 1)}
              >
                -
              </span>
              <input
                name="count"
                type="text"
                className="count-input"
                readOnly
                value={counter}
                onChange={(e: any) => handleCountValue(e.target.value)}
              />
              <span
                className="count-plus"
                onClick={() => setCounter(counter + 1)}
              >
                +
              </span>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button className="cancel-button" disabled={loading} onClick={cancelRequestLicence}>
              Cancel
            </Button>
            <Button
              className="confirm-button"
              loading={loading}
              onClick={confirmRequestLicence}
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {successAlert && (
        <Modal
          open={successAlert}
          size={"tiny"}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className="licence-modal"
          closeIcon
          onClose={() => setSuccessAlert(false)}
        >
          <Modal.Content className="licence-content">
            <div className="mlv-viewer-license-content">
              Your request has been sent successfully.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="confirm-button"
              onClick={() => setSuccessAlert(false)}
            >
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default ManageLicenseViewer;
