import { useCallback, useContext, useEffect } from 'react';
import ShowStream from '.';
import { auth } from '../../../Firebase';
import { isGuestAuthenticated } from '../../../Utils/guestHelpers';
import { get, isEmpty } from '../../../Utils/helpers';
import useWhiteBoard from '../hooks/useWhiteBoard';
import useCanvas from '../hooks/useCanvas';
import zoomContext from '../context/zoom-context';

const Stream = (props: any) => {
  type Coordinate = {
    x: number;
    y: number;
  };
  const { canvasRef } = useWhiteBoard();
  const zmClient = useContext(zoomContext);
  const { canvasMT, canvasML, canvasWidth, renderCanvas, videoReady, resizeCanvas } = useCanvas();
  const {
    show_id,
    // canvasRef,
    stream,
    iceServers,
    refreshKey,
    setIsStreamSelected,
    // isReady,
    userId,
    isGuestUser,
    guestDisplayName,
    open,
    watermarkPanel,
    signOut,
    programaticChange,
    isPublisher,
    // canvasMT,
    // canvasML,
    // canvasWidth,
    leaveChat,
    leaveGuestInvite,
    leavePublisherPanel,
    watermarkFeatureIncluded,
    watermarkChecks,
    preview,
    setPreview,
    setRPState,
    setMillicastView,
    setActiveStreamExists,
    setActiveStreamStatus,
    isMultiViewerEnabled,
    ipAddress,
    volumeMute
  } = props;

  const drawLine = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate,
    erase: Boolean,
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext('2d');
    if (context) {
      context.lineJoin = context.lineCap = 'round';

      context.beginPath();

      if (!erase) {
        context.globalCompositeOperation = 'source-over';
        context.strokeStyle = color;
        context.lineWidth = 5;
      } else {
        context.globalCompositeOperation = 'destination-out';
        context.lineWidth = 20;
      }

      context.moveTo(originalMousePosition.x, originalMousePosition.y);
      context.lineTo(newMousePosition.x, newMousePosition.y);
      context.closePath();

      context.stroke();
    }
  };

  const drawCircle = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate,
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext('2d');
    if (context) {
      context.lineJoin = context.lineCap = 'round';

      context.beginPath();
      context.globalCompositeOperation = 'source-over';
      context.strokeStyle = color;
      context.lineWidth = 3;

      // calculating the midX and midY
      var midY = originalMousePosition.y + (newMousePosition.y - originalMousePosition.y) * 0.5;
      var midX = originalMousePosition.x + (newMousePosition.x - originalMousePosition.x) * 0.5;
      var radius =
        Math.hypot(
          newMousePosition.x - originalMousePosition.x,
          newMousePosition.y - originalMousePosition.y,
        ) / 2;

      context.arc(midX, midY, radius, 0, 2 * Math.PI);

      context.stroke();
    }
  };

  const onCommandChannelMessage = useCallback(async (payload: any) => {
    const canvas: any = document.getElementById('canvas');
    // console.log('payload-onCommandChannelMessage', payload)
    if(!payload.text){
      return
    }
    const {
      isPainting,
      color,
      mousePosition,
      newMousePosition,
      incoming_canvas,
      erase,
      clear,
      isCircle,
    } = JSON.parse(payload.text);

    if (isPainting && canvas) {
      const curr_canvas: any = document.getElementById('canvas');
      let bb = curr_canvas?.getBoundingClientRect();
      const bbWidth = get(bb, 'width', 0);
      const bbHeight = get(bb, 'height', 0);
      const curr_canvas_width = bbWidth;
      const curr_canvas_height = bbHeight;

      let oldMousePos: Coordinate, newMousePos: Coordinate;

      if (curr_canvas) {
        oldMousePos = {
          x: (mousePosition.x / incoming_canvas.width) * curr_canvas_width,
          y: (mousePosition.y / incoming_canvas.height) * curr_canvas_height,
        };
        newMousePos = {
          x: (newMousePosition.x / incoming_canvas.width) * curr_canvas_width,
          y: (newMousePosition.y / incoming_canvas.height) * curr_canvas_height,
        };
        if (isCircle) {
          drawCircle(color, oldMousePos, newMousePos);
        } else {
          console.log('erase', erase);
          drawLine(color, oldMousePos, newMousePos, erase);
        }
        canvas.className = 'canvas-pencil-on';
      }
    }

    if (clear) {
      const canvas: any = document.getElementById("canvas");
      let context = canvas.getContext("2d");
      const canvasWidth = get(canvas, "width", 0);
      const canvasHeight = get(canvas, "height", 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }
  }, []);

  useEffect(() => {
    zmClient.on('connection-change', onCommandChannelMessage);

    zmClient.on('command-channel-message', onCommandChannelMessage);
    // zmClient.on('device-change', onDeviceChange)

    return () => {
      zmClient.off('command-channel-message', onCommandChannelMessage);

      // zmClient.off('device-change', onDeviceChange)
    };
  }, [zmClient, onCommandChannelMessage]);

  if (!stream || (stream && isEmpty(stream))) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          background: 'radial-gradient(circle, rgba(20,20,20,0.60) 30%, rgba(20,20,20,0) 100%)',
        }}
      >
        <p
          style={{
            fontSize: 16,
            color: 'rgba(255, 255, 255, 0.5)',
            fontWeight: 'bold',
            margin: '0px 0px 4px 0px',
          }}
        >
          Welcome to the show!
        </p>
        <p
          style={{
            fontSize: 32,
            color: 'white',
            fontWeight: 'bold',
            margin: '0px 0px 24px 0px',
          }}
        >
          Select a Stream Room to Start
        </p>
        <p
          style={{
            fontSize: 20,
            color: 'rgba(255, 255, 255, 0.7)',
            margin: '0px 0px 24px 0px',
          }}
        >
          Hover over to the left and get it going
        </p>
      </div>
    );
  }

  return (
    <ShowStream
      show_id={show_id}
      canvasRef={canvasRef}
      stream={stream}
      iceServers={iceServers}
      refreshKey={refreshKey}
      setIsStreamSelected={setIsStreamSelected}
      isReady={videoReady}
      userId={get(auth.getUser(), 'uid', '')}
      isGuestUser={isGuestAuthenticated()}
      guestDisplayName={guestDisplayName}
      open={open}
      watermarkPanel={watermarkPanel}
      //   watermarkDetails={watermarkDetails}
      signOut={signOut}
      programaticChange={false}
      isPublisher={isPublisher}
      canvasMT={canvasMT}
      canvasML={canvasML}
      canvasWidth={canvasWidth}
      leaveChat={false}
      leaveGuestInvite={false}
      leavePublisherPanel={false}
      watermarkFeatureIncluded={watermarkFeatureIncluded}
      watermarkChecks={false}
      preview={false}
      setPreview={() => {}}
      setRPState={setRPState}
      setMillicastView={setMillicastView}
      setActiveStreamExists={setActiveStreamExists}
      setActiveStreamStatus={setActiveStreamStatus}
      isMultiViewerEnabled={isMultiViewerEnabled}
      ipAddress={ipAddress}
      volumeMute={volumeMute}
    />
  );
};
export default Stream;
