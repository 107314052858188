import React from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import AlgoliaTableSearch from "./AlgoliaTableSearch";
import Pagination from "./Pagination";

const Table = (props: any) => {
  const { columns = [], data = [], sortBy = [], tooltip = "", pageSize = 10 } = props;
  const initialState: any = {
    pageIndex: 0,
    pageSize,
    globalFilter: "",
    sortBy,
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <AlgoliaTableSearch
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table
        {...getTableProps()}
        className="ui inverted selectable table list-table-view algolia-table"
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc-column"
                          : "sort-asc-column"
                        : "sort-none-column"
                    }
                  ></span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr className="header-cell-without-border" {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...(!!tooltip && !!cell.row.original[tooltip]
                        ? { title: String(cell.row.original[tooltip]) }
                        : {})}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {(globalFilter && page.length === 0) && <div className="search-alogolia-no-list-msg">No Data Found</div>}
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
      />
    </>
  );
};

export default Table;
