import React, { useState, useEffect, useContext } from "react";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { Modal,Button } from "semantic-ui-react";
import TermsOfService from "../Common/TermsOfService";
import PrivacyPolicy from "../Common/PrivacyPolicy";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';

export const Footer = () => {
	const [feVersion, setFeVersion] = useState("");
	const [tosModalOpen,setToSModal] = useState(false);
	const [ppModalOpen,setPPModal] = useState(false);
	const currentUser = get(useContext(AuthContext), "currentUser", {});
	
	useEffect(() => {
		const getVersionData = async () => {
			const data = {
				api: api.appVersions.get
			};
			const versionData = await backend.fetch(data, get(currentUser, "user.accessToken", ""));

			if (versionData) {
				setFeVersion(versionData[0].fe_version_number);
			}
		}

		getVersionData();
	}, []);

	const preventLinkOpen = (e: React.MouseEvent) => {
		e.preventDefault();
	};

	return (
		<div className="footerMain">
			<div className="footerRowLogo blockArea">
				<div className="footerLogo"></div>
			</div>
			<div className="blockArea footerNav">
				<ul className="inlineMenu">
					<li onClick={() => setPPModal(true)}>
						<a href="https://pacpost.live/privacypolicy" onClick={preventLinkOpen}>Privacy Policy</a>
					</li>
					<li onClick={() => setToSModal(true)}>
						<a href="https://pacpost.live/termsofservice" onClick={preventLinkOpen}>Terms of Service</a>
					</li>
					<li className="footer-text">Accessibility</li>
					<li><a href="https://pacpost.com/contact">Contact Us - Pacific Post - Post Production and AVID Rentals</a></li>
					<li className="footer-text">Version : {feVersion}</li>
				</ul>
			</div>
			{(tosModalOpen === true) &&
				<Modal
				centered
				open={tosModalOpen}
				size={"large"}
				onClose={() => setToSModal(false)}
				onOpen={() => setToSModal(true)}
				closeOnEscape={false}
				closeOnDimmerClick={false}
				>
				<Modal.Content>
					<TermsOfService />
				</Modal.Content>
				<Modal.Actions>
					<Button className="purple-button" onClick={()=> setToSModal(false)} >
						CLOSE
					</Button>              
				</Modal.Actions>
				</Modal>
			}
			{(ppModalOpen === true) &&
				<Modal
				centered
				open={ppModalOpen}
				size={"large"}
				onClose={() => setPPModal(false)}
				onOpen={() => setPPModal(true)}
				closeOnEscape={false}
				closeOnDimmerClick={false}
				>
				<Modal.Content>
					<PrivacyPolicy />
				</Modal.Content>
				<Modal.Actions>
					<Button className="purple-button" onClick={()=> setPPModal(false)} >
						CLOSE
					</Button>              
				</Modal.Actions>
				</Modal>
			}
		</div>
	);
};

