import api from '../../../Service/Api';
import backend from '../../../Service/Backend';

const apiFetch = async (payload: any, token: string) => {
  try {
    return await backend.fetch(payload, token);
  } catch (err) {
    console.log('err', err);
  }
};

export const checkAccess = async (show_id: string, token: string) => {
  const isShowAdmin = {
    api: api.users.isShowAdmin,
    queryParam: { show_id },
  };
  return await apiFetch(isShowAdmin, token);
};

export const getShowDetails = async (show_id: string, token: string) => {
  try {
    const showData = {
      api: api.shows.get,
      urlParam: show_id,
    };
    return await backend.fetch(showData, token);
  } catch (err) {
    console.log('err', err);
  }
};

export const getShowFlagsDetails = async (show_id: string, token: string) => {
  try {
    const flagData = {
      api: api.featureFlags.listShowFlagsName,
      queryParam: { show_id, flag_name: 'ALLOW_STRIPE_LICENCE' },
    };
    return await backend.fetch(flagData, token);
  } catch (err) {
    console.log('err', err);
  }
};

// export const getShowFlagDetails = async (show_id: string, flag_name: string) => {
//   //TODO: api to get all show flags
//   try {
//     const flagData = {
//       api: api.featureFlags.listShowFlagsName,
//       queryParam: { show_id, flag_name: flag_name },
//     };
//     return await backend.fetch(flagData);
//   } catch (err) {
//     console.log('err', err);
//   }
// };