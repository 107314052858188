import React, { useState, useEffect, useContext } from "react";
import { Button as SemanticButton, Checkbox, CheckboxProps} from "semantic-ui-react";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { Mixpanel } from "../../Service/Mixpanel";
import { PAGES } from "../../Utils/constants";
import ReactSelect from "../../Components/Common/ReactSelect";
import { get } from "../../Utils/helpers";
import "./UnInviteViewers.css";
import { Collapse, Modal, Button, notification, Tooltip } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import GroupsUsersIcon from "../../Images/users_groups.svg";
import GroupsIcon from "../../Images/group_icon.svg";
import UsersIcon from "../../Images/user_icon.svg";
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";
const { Panel } = Collapse;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IUnInviteViewersProps {
  showId: string;
  allActiveStream: any;
  selectedStreamIndex: number;
  handleChangeStream(index: number): void;
  activeStream: any;
  refreshInviteList(newUnInvitedUsers: any[]): void;
  invitedUsersList: any[];
  teamAdmin: boolean;
  invitedGroupsList: any[];
  refreshInviteGroupList(newUnInvitedGroups: any[]): void;
  setLoading(loadingStatus: boolean): void;
  loading: boolean;
  usersGroupsFlag?: boolean;
}

const UnInviteViewers = (props: IUnInviteViewersProps) => {

  const currentUser = get(useContext(AuthContext), "currentUser", {} );

  const [apiAntd, contextHolder] = notification.useNotification({top:60});

  const { invitedUsersList = [], invitedGroupsList = [], setLoading, loading } = props;
  const { REACT_APP_ENABLE_LOGGER = false } = process.env;
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // const [loading, setLoading] = useState(false);
  const [show_id, setShowId] = useState("");

  const [activeStream, setActiveStream] = useState({} as any);
  const [invitedViewers, setInvitedViewers] = useState([] as any);
  const [invitedGroups, setInvitedGroups] = useState(invitedGroupsList)

  const [selectAllUserAlertOpen, setSelectAllUserAlertOpen] = useState(false);
  const [
    allUserSelectCheckEventProps,
    setallUserSelectCheckEventProps,
  ] = useState({} as CheckboxProps);

  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [activeStreamOption, setActiveStreamOption] = useState([]);

  useEffect(() => {
    const invitedUsersListArr = props.invitedUsersList.sort((a: any, b: any) => get(a, "user_name.user_name_first", "").localeCompare(get(b, "user_name.user_name_first", "")));
    setInvitedViewers([...invitedUsersListArr]);

    const invitedGroupsListArr = props.invitedGroupsList.sort((a: any, b: any) => get(a, "show_group_name", "").localeCompare(get(b, "show_group_name", "")));
    setInvitedGroups([...invitedGroupsListArr]);
  }, [props])

  useEffect(() => {
    if (props.showId) {
      setShowId(props.showId);
      setActiveStream(props.activeStream);
      if (!!props.allActiveStream.length) {
        const streamListOption = props.allActiveStream.map((item: any) => {
          return {
            label: item.user_entered_stream_label,
            value: item.id,
            assignedTo: !!item.assigned_to
              ? `${get(item, "assigned_to.user_name.user_name_first", "")} ${get(item, "assigned_to.user_name.user_name_last", "")}`
              : "",
          };
        });
        setActiveStreamOption(streamListOption);
      }

      const invitedUsersListArr = props.invitedUsersList.sort((a: any, b: any) => get(a, "user_name.user_name_first", "").localeCompare(get(b, "user_name.user_name_first", "")));
      setInvitedViewers([...invitedUsersListArr]);

      const invitedGroupsListArr = props.invitedGroupsList
      setInvitedGroups([...invitedGroupsListArr]);
    }
  }, [
    props,
    REACT_APP_ENABLE_LOGGER,
  ]);

  const openNotificationWithIcon = (type: NotificationType, title: any) => {
    apiAntd[type]({
      message: title
    });
  };

  const getRemoveButtonStatus = () => {
    let selectedUsersLength: number = 0;
    let selectedGroupsLength: number = 0;

    invitedViewers.forEach((user: any) => {
      if (user.isChecked) {
        selectedUsersLength = selectedUsersLength + 1;
      }
    });

    invitedGroups.forEach((group: any) => {
      if (group.isChecked) {
        selectedGroupsLength = selectedGroupsLength + 1;
      }
    });

    if (selectedUsersLength === 0 && selectedGroupsLength === 0) {
      return true;
    } return false;
  }

  const unInviteUsers = async () => {
    try {
      let stream_id = activeStream.id;
      if (!stream_id) {
        const errMessage = "No active stream found. Can not uninvite user";
        const err = { errMessage };
        throw err;
      }

      let userids: string[] = [];
      let checkedUsers: any[] = [];

      let groups: any[] = [];
      let checkedGroups: any[] = [];

      invitedViewers.forEach((user: any) => {
        if (user.isChecked) {
          userids.push(user.user_id);
          checkedUsers.push(user);
        }
      });

      invitedGroups.forEach((group: any) => {
        if (group.isChecked) {
          const groupID: any = group.show_group_id
          const userIds: any = group.show_group_users.map((user: any) => user.user_id)
          groups.push({
            [groupID]: userIds
          })
          checkedGroups.push(group)
        }
      });

      if (!userids.length && !groups.length) {
        const errMessage = `Please select users ${props.usersGroupsFlag ? 'or groups ' : ''} to remove`;
        alert(errMessage);
        const err = { errMessage };
        throw err;
      }

      const input = { show_id, stream_id, userids: userids.toString(), show_groups: groups };

      const data = {
        api: api.streams.unInviteViewersToStream,
        payLoad: JSON.stringify(input),
      };

      const callStartTime = new Date();

      setLoading(true);
      const response = await backend.save(data, get(currentUser, "user.accessToken", ""));

      const callEndTime = new Date();
      const timeTaken: any = callEndTime.getTime() - callStartTime.getTime();

      const logData = {
        api: api.log.log,
        payLoad: JSON.stringify({
          logFrom: PAGES.SHOW_PUBLISHER_PAGE,
          data: {
            startTime: callStartTime,
            endTime: callEndTime,
            timeTaken: timeTaken,
            api: api.streams.unInviteViewersToStream.endPoint,
            show_id: show_id,
          },
        }),
      };

      if (REACT_APP_ENABLE_LOGGER) {
        backend.save(logData, get(currentUser, "user.accessToken", ""));
      }

      if (response) {
        Mixpanel.track("Publisher Stream Action", {
          Platform: "Web",
          "Stream Label": activeStream.user_entered_stream_label,
          "Action Type": "UnInviteUsers",
        });
        const message = `Selected Users ${props.usersGroupsFlag ? 'and Groups ' : ''}Uninvited successfully`
        openNotificationWithIcon('success', message)

        setSelectAllCheckBox(false);
        let msg = "";
        const { userIds } = response;
        if (userIds && userIds.length) {
          invitedViewers
            .filter((activeUser: any) => userIds.includes(activeUser.user_id))
            .map((user: any) => (msg = msg + `\n${user.user_email}, `));
        }
        msg = msg + "are successfully uninvited from the stream!";
        setSuccessMessage(msg);

        let remainingUsers = invitedViewers.filter(
          (user: any) => !userids.includes(user.user_id)
        );
        setInvitedViewers(remainingUsers);

        //Refresh Invite List
        checkedUsers.forEach((user: any) => {
          if (user.isChecked) {
            user.isChecked = false;
          }
        });
        props.refreshInviteList(checkedUsers);

        //refresh groups list
        checkedGroups.forEach((group: any) => {
          if (group.isChecked) {
            group.isChecked = false;
          }
        });
        props.refreshInviteGroupList(checkedGroups);
      }
    } catch (err: any) {
      const msg = "Error while pulling data";
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
        openNotificationWithIcon('error', err.errMessage)
      } else {
        setErrorMessage(msg);
        const message = `Error while uninviting the selected ${props.usersGroupsFlag ? 'groups and' : ''} users`;
        openNotificationWithIcon('error', message)
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAlertCancel = () => {
    setSelectAllUserAlertOpen(false);
    setSelectAllCheckBox(false);
  };

  const handleAlertConfirm = async () => {
    setSelectAllUserAlertOpen(false);
    setSelectAllCheckBox(true);

    const users = invitedViewers;
    let checkboxes: any = document.getElementsByName("removeAll");
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = allUserSelectCheckEventProps.checked
        ? true
        : false;
    }
    users.forEach((user: any) => {
      user.isChecked = allUserSelectCheckEventProps.checked;
    });
    setInvitedViewers([...users]);
  };

  const handleViewerSelect = (data: CheckboxProps) => {
    const users = invitedViewers;
    // Single Select
    users.forEach((user: any) => {
      if (user.user_id === data.value) {
        user.isChecked = data.checked;
      }
    });

    setInvitedViewers([...users]);
  };


  const handleGroupSelect = (data: CheckboxProps) => {
    const groups = invitedGroups;
    const checked = data.checked;
    //Single Selectg
    groups.forEach((group: any) => {
      if (group.show_group_id === data.value) {
        group.isChecked = checked;
      }
    });

    setInvitedGroups([...groups]);
  };

  const handleSelectAll = (data: CheckboxProps) => {
    const users = invitedViewers;
    const groups = invitedGroups;
    const checked: any = data.checked;

    users.forEach((user: any) => {
      user.isChecked = checked;
    });

    groups.forEach((group: any) => {
      group.isChecked = checked;
    });

    setInvitedViewers([...users]);
    setInvitedGroups([...groups]);
    setSelectAllCheckBox(checked);
  }

  const cardOption = (group: any) => {
    return (
      <Checkbox
        label=""
        name="options"
        value={group.show_group_id}
        checked={group.isChecked}
        onChange={(event, data: CheckboxProps) => {
          handleGroupSelect(data);
        }}
      />
    )
  }

  const renderRow = () => {
    return invitedViewers.map((user: any, index: any) => {
      return (
        <tr className="header-cell-without-border" key={user.user_id}>
          <td className="list-icon-user"> </td>
          <td>{user?.user_title ? user?.user_title : ""}</td>
          <td className="overflow-text-ellipse">{user?.user_name?.user_name_first}</td>
          <td className="overflow-text-ellipse">{user?.user_name?.user_name_last}</td>
          <td>{user?.role_type}</td>
          <td>
            <label className="checkbox-container">
              <Checkbox
                label=""
                name="removeAll"
                checked={user.isChecked}
                value={user.user_id}
                onChange={(event, data: CheckboxProps) => {
                  handleViewerSelect(data);
                }}
              />
            </label>
          </td>
        </tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="show-publisher-uninvite-users-table">
        <table className="ui inverted selectable table">
          <thead className="">
            <tr className="">
              <th className="custom-border list-icon-name "></th>
              <th className="custom-border">Title</th>
              <th className="custom-border">First Name</th>
              <th className="custom-border">Last Name</th>
              <th className="custom-border">Role</th>
              <th className="">
                Remove
                <span className="add-checkbox-header margin-right-20 margin-top-0">
                  <label className="checkbox-container">
                    <Checkbox
                      label=""
                      disabled={invitedViewers.length <= 0}
                      value="checkAll"
                      checked={selectAllCheckBox}
                      onChange={(event, data: CheckboxProps) => {
                        handleSelectAll(data);
                      }}
                    />
                  </label>
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="">{renderRow()}</tbody>
        </table>
        <div className="flex">
          <SemanticButton
            color="violet"
            onClick={unInviteUsers}
            disabled={invitedViewers.length <= 0}
          >
            Remove
          </SemanticButton>
        </div>
      </div>
    );
  };

  const renderTableNew = () => {
    return (
      <>
        <div className="show-pub-uninvite-users-table">
          <div className="show-pub-uninvite-users-header">
            <div className="col-name">
              <img src={GroupsUsersIcon} alt="group_users_header_icon" style={{ marginRight: "16px" }} /> Name
            </div>
            <div className="col-check-all">
              <Tooltip title="Select All">
                <Checkbox
                  //slabel="Select all"
                  // disabled={activeShowGroups.length <= 0}
                  value="checkAll"
                  checked={selectAllCheckBox}
                  onChange={(event, data: CheckboxProps) => {
                    handleSelectAll(data);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="show-pub-uninvite-users-body">
            {
              invitedGroups.map((group: any, index: number) => {
                return (
                  <Collapse defaultActiveKey={['0']} bordered={false} key={index}>
                    <Panel key="1"
                      showArrow={false}
                      extra={cardOption(group)}
                      header={
                        (
                          <>
                            <img src={GroupsIcon} alt="group_icon" style={{ marginRight: "22px" }} /> {group?.show_group_name}
                            <CaretRightOutlined style={{ margin: '4px' }}
                            // rotate={accordion ? 90 : 270} 
                            />
                          </>
                        )
                      }>
                      {group.show_group_users.length > 0 ? group.show_group_users.map((user: any, index: number) => {
                        return (
                          <div className="col-name" key={index}>
                            {user.full_name}
                          </div>
                        )
                      }) : <div>
                        No Users</div>}
                    </Panel>
                  </Collapse>
                )
              })
            }
            {
              invitedViewers.map((user: any, index: number) => {
                return (
                  <div className="show-pub-uninvite-users-row" key={index}>
                    <div className="col-name">
                      <img src={UsersIcon} alt="user_icon" style={{ marginRight: "22px" }} />{`${user?.user_name?.user_name_first} ${user?.user_name?.user_name_last}`}
                    </div>
                    <div className="col-remove">
                      <Checkbox
                        label=""
                        value={user.user_id}
                        checked={user.isChecked}
                        onChange={(event, data: CheckboxProps) => {
                          handleViewerSelect(data);
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* Remove Button */}
        <div className="remove-button-wrapper">
          <button className="remove-button" onClick={() => {
            if (selectAllCheckBox) {
              showConfirm()
            } else {
              unInviteUsers()
            }
          }} disabled={getRemoveButtonStatus()}>
            Remove
          </button>
        </div>
      </>
    );
  };

  const handleAssignPublisher = (value: any) => {
    const { allActiveStream, handleChangeStream } = props;
    const streamIndex = allActiveStream.findIndex(
      (stream: any) => stream.id === value.value
    );
    handleChangeStream(streamIndex);
  };
  const selectedActiveStream: any =
    activeStreamOption[props.selectedStreamIndex];

  const { confirm } = Modal;

  const destroyAll = () => {
    Modal.destroyAll();
    handleSelectAll({ checked:false })
  };

  const showConfirm = () => {
    confirm({
      title: `Are you sure you want to remove all the ${props.usersGroupsFlag ? 'groups and ' : ''}users?`,
      icon: <></>,
      closable: true,
      centered: true,
      footer: <div className="modal-footer-show-pub">
        <Button loading={false} onClick={destroyAll}>
          No
        </Button>
        <Button key="submit" type="primary"
          loading={loading}
          onClick={() => {
            destroyAll()
            unInviteUsers()
          }}>
          Yes
        </Button>
      </div>,
    });
  };

  return (
    <div className="stream-users-list">
      {contextHolder}
      {/* Header */}
      <div className="list-heading">
        <div className="heading-text">
          {!!props.teamAdmin ? (
            <>
              <div className="un-invite-flex">
                <div>Invited Viewers To : </div>
                <div style={{ width: "50%", margin: "10px 0 0 10px" }}>
                  <ReactSelect
                    name="publisher-select"
                    value={
                      !!selectedActiveStream ? [selectedActiveStream.value] : []
                    }
                    options={activeStreamOption}
                    onChange={handleAssignPublisher}
                  />
                </div>
              </div>
              <div className="un-invite-flex">
                <div>
                  Stream Assigned To:{" "}
                  <label style={{ fontSize: "18px" }}>
                    {!!selectedActiveStream
                      ? selectedActiveStream.assignedTo
                      : ""}
                  </label>{" "}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Invited viewers to: */}
              {!!activeStream && !!activeStream.user_entered_stream_label && (
                <div className="grey-text">
                  Invited viewers to: {activeStream.user_entered_stream_label}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Table */}
      <div className="list-content">{props.usersGroupsFlag ? renderTableNew() :renderTable()}</div>

      <ConfirmationalPopup
        open={selectAllUserAlertOpen}
        data={"Are you Sure you want to remove all"}
        onCancel={handleAlertCancel}
        onOk={handleAlertConfirm}
      />
    </div>
  );
};
export default UnInviteViewers;