import React from 'react';
import { PAGES } from '../../Utils/constants';
import { Layout } from '../PageLayout/Layout';
import useNavigateToPage from '../../hooks/useNavigateToPage';

const UnAuthorised: React.FC = () => {
  const navigateToPage = useNavigateToPage();
  return (
    <Layout page={PAGES.UNAUTHORISED_PAGE}>
      <span className='center-link' onClick={() => navigateToPage('/')}>
        {' '}
        Go to Login
      </span>
      <div className='un-authorised-text'>You don't have permission to access the page !</div>
    </Layout>
  );
};
export default UnAuthorised;
