import React, { useState, useEffect } from "react";
import { PAGES, SUPERADMIN_PAGES } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import AlgoliaTable from "../../Components/Algolia/AlgoliaTable";
import { get, sortTableDate } from "../../Utils/helpers";
import { Input, Row, Table } from "antd";
import { searchClient, showActiveStreams, showActiveUsers } from "../../Service/show";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import classNames from "classnames";

const { REACT_APP_ALGOLIA_STREAM_INDEX_NAME } = process.env;

interface IProps {
  show_id: string | undefined;
  usersGroupsFlag?: boolean;
}
const { Search } = Input;


const ListOfShowStreams = (props: IProps) => {
  const { show_id } = props;
  const [streams, setStreams] = useState([] as any);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchStreamData = async () => {
    try {
      let activeUsers = await showActiveStreams(show_id);
      setStreams(activeUsers?.hits);
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchStreamData()
  }, [])

  useEffect(() => {
    // Mixpanel
    searchClient.clearCache();
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SUPER_ADMIN_PAGE + "_" + SUPERADMIN_PAGES.STREAM_LIST,
    });
  }, [streams]);

  const renderCreatedTimeNew = (props: any) => {
    return new Date(
      props._seconds * 1000 +
      props._nanoseconds / 1000000
    ).toDateString();
  };

  const renderStartTimeNew = (props: any) => {
    return new Date(
      props._seconds * 1000 +
      props._nanoseconds / 1000000
    ).toDateString();
  };

  const renderEndTimeNew = (props: any) => {
    return (props && props !== null)
      ? new Date(
        (props._seconds * 1000) +
        (props._nanoseconds / 1000000)
      ).toDateString()
      : "NA";
  };

  const renderCreatedTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.created_ts._seconds * 1000 +
      original.created_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const renderStartTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.stream_start_ts._seconds * 1000 +
      original.stream_start_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const renderEndTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return original.stream_end_ts
      ? new Date(
        original.stream_end_ts._seconds * 1000 +
        original.stream_end_ts._nanoseconds / 1000000
      ).toDateString()
      : "NA";
  };

  const filterCriteria = (user: any) => {
    const streamVendorId: string = get(user, "stream_vendor_id", "");
    const showName: string = get(user, "show_name", "");
    const streamLabel: string = get(user, "stream_label", "");
    const streamName: string = get(user, "stream_name", "");
    return `${streamVendorId} ${showName} ${streamLabel} ${streamName}`
      .toLowerCase()
      .includes(searchText.toLowerCase());
  };

  const handleSearch = (e: any) => {
    const { value } = e.target;
       let iswhiteSpace =  value.trim().length === 0;
       if(!iswhiteSpace) {
        setSearchText(value);
       } else {
        setSearchText("")
       }
  };

  const getTableConfig = () => {
    return {
      columns: [
        // Headers array for the table
        {
          Header: "Id",
          accessor: "stream_vendor_id",
        },
        {
          Header: "Show Name",
          accessor: "show_name",
        },
        {
          Header: "Stream Label",
          accessor: "stream_label",
        },
        {
          Header: "Stream Name",
          accessor: "stream_name",
        },
        {
          Header: "Created TS",
          accessor: "created_ts",
          Cell: renderCreatedTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.created_ts._seconds"),
        },
        {
          Header: "Status",
          accessor: "stream_status",
        },
        {
          Header: "Start TS",
          accessor: "stream_start_ts",
          Cell: renderStartTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.stream_start_ts._seconds"),
        },
        {
          Header: "End TS",
          accessor: "stream_end_ts",
          Cell: renderEndTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.stream_end_ts._seconds"),
        },
        {
          Header: "Created By",
          accessor: "created_user_email",
        },
      ],
      algoliaIndex: REACT_APP_ALGOLIA_STREAM_INDEX_NAME, // Algolia Index Mandatory
      filters: `show_id:${show_id}`, // Algolia Filters
      setDataList: setStreams, // Temp set list of hits value
      dataList: streams, // Algolia list of hits value,
      sortBy: [
        {
          id: "stream_name",
          desc: false,
        },
      ],
      attributesToRetrieve: [
        // Response attributes
        "stream_vendor_id",
        "show_name",
        "stream_label",
        "stream_name",
        "created_ts",
        "stream_status",
        "stream_start_ts",
        "stream_end_ts",
        "created_user_email",
      ],
    };
  };

  const getTableConfigNew = () => {
    return {
      columns: [
        // Headers array for the table
        {
          title: "Id",
          dataIndex: "stream_vendor_id",
          sorter: (a: any, b: any) => {
            const nameA = `${a.stream_vendor_id}`.toLowerCase();
            const nameB = `${b.stream_vendor_id}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Show Name",
          dataIndex: "show_name",
          sorter: (a: any, b: any) => {
            const nameA = `${a.show_name}`.toLowerCase();
            const nameB = `${b.show_name}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Stream Label",
          dataIndex: "stream_label",
          sorter: (a: any, b: any) => {
            const nameA = `${a.stream_label}`.toLowerCase();
            const nameB = `${b.stream_label}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Stream Name",
          dataIndex: "stream_name",
          sorter: (a: any, b: any) => {
            const nameA = `${a.stream_name}`.toLowerCase();
            const nameB = `${b.stream_name}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Created TS",
          dataIndex: "created_ts",
          render: renderCreatedTimeNew,
          sorter: (a: any, b: any) => {
            const nameA = `${a.created_ts}`.toLowerCase();
            const nameB = `${b.created_ts}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Status",
          dataIndex: "stream_status",
          sorter: (a: any, b: any) => {
            const nameA = `${a.stream_status}`.toLowerCase();
            const nameB = `${b.stream_status}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Start TS",
          dataIndex: "stream_start_ts",
          render: renderStartTimeNew,
          sorter: (a: any, b: any) => {
            const nameA = `${a.stream_start_ts}`.toLowerCase();
            const nameB = `${b.stream_start_ts}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "End TS",
          dataIndex: "stream_end_ts",
          render: renderEndTimeNew,
          sorter: (a: any, b: any) => {
            const nameA = `${a.stream_end_ts}`.toLowerCase();
            const nameB = `${b.stream_end_ts}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
        {
          title: "Created By",
          dataIndex: "created_user_email",
          sorter: (a: any, b: any) => {
            const nameA = `${a.created_user_email}`.toLowerCase();
            const nameB = `${b.created_user_email}`.toLowerCase();
            return nameA.localeCompare(nameB);
          },
        },
      ],
      algoliaIndex: REACT_APP_ALGOLIA_STREAM_INDEX_NAME, // Algolia Index Mandatory
      filters: `show_id:${show_id}`, // Algolia Filters
      setDataList: setStreams, // Temp set list of hits value
      dataList: streams, // Algolia list of hits value,
      sortBy: [
        {
          id: "stream_name",
          desc: false,
        },
      ],
      attributesToRetrieve: [
        // Response attributes
        "stream_vendor_id",
        "show_name",
        "stream_label",
        "stream_name",
        "created_ts",
        "stream_status",
        "stream_start_ts",
        "stream_end_ts",
        "created_user_email",
      ],
      refresh: true
    };
  }
  const tableConfig = getTableConfigNew();

  return (
    <div className="superadmin-list-streams">
      {
        props.usersGroupsFlag ?
          <>
            <ProgressLoader loading={loading} />

            <div className="superadmin-list-heading users-group-list">
            <p className="heading-text  heading-text-new">
              Show Admin :<span className="grey-text"> Stream List </span>
            </p>
          </div>
          <div className="searchbar-wrapper searchbar-manage-users">
            <Search
              placeholder="Search"
              allowClear
              className={classNames('left-container-searchbar')}
              onChange={handleSearch}
            />
          </div>
          <Table className="antd-table-new"
            showSorterTooltip={{ title: 'Sort Alphabetically' }}
            columns={tableConfig.columns}
            dataSource={searchText ? tableConfig.dataList.filter(filterCriteria) : tableConfig.dataList}
            pagination={{ pageSize: 20 }}
            scroll={{x:true}}
          />
          </>
          :
          <>
            <div className="superadmin-list-heading">
              <p className="heading-text">
                Show Admin :<span className="grey-text"> Stream List </span>
              </p>
            </div>
            <AlgoliaTable config={getTableConfig()} />
          </>}
    </div>
  );
};

export default ListOfShowStreams;
