import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  DOMAttributes,
  HTMLAttributes,
  DetailedHTMLProps,
  useMemo,
  memo
} from 'react'
import ContentLoader from 'react-content-loader';
import classnames from 'classnames';
import { type VideoPlayerContainer, type VideoPlayer, VideoQuality, Participant, ExecutedFailure } from '@zoom/videosdk';
import ZoomContext from '../context/zoom-context';
import ZoomMediaContext from '../context/media-context';
import { useParticipantsChange } from '../hooks/useParticipantsChange';
import { useVideoAspect } from '../hooks/useVideoAspectRatio';
import { usePrevious } from '../hooks/usePrevious';
import chevronLeft from '../../../Assets/icons/chevron-left.svg';
import chevronRight from '../../../Assets/icons/chevron-right.svg';
import arrowDown from '../../../Assets/icons/arrow-down.svg';
import arrowUp from '../../../Assets/icons/arrow-up.svg';
import ZoomVideoTile from './ZoomVideoTile';

import './styles.scss';
import { sortedParticipants } from './VideoTrayHelpers';
import _ from 'lodash';
import { useLayoutSlice, useViewerSlice } from '../../../store';
import { get } from '../../../Utils/helpers';
import { isGuestAllowed, isGuestParticipant } from '../GuestInvite/guestHelpers';
import useCanvas from '../hooks/useCanvas';

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['video-player']: DetailedHTMLProps<HTMLAttributes<VideoPlayer>, VideoPlayer> & { class?: string };
      ['video-player-container']: CustomElement<VideoPlayerContainer> & { class?: string };
    }
  }
}

const ZoomVideoTray = (props: any) => {
  const { alreadyJoinedGuests } = props;
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const currentUser = zmClient.getCurrentUserInfo();
  const [participants, setParticipants] = useState(sortedParticipants(zmClient.getAllUser(), get(currentUser, 'userIdentity', '')))
  const [subscribers, setSubscribers] = useState<number[]>(participants.filter((user) => user.bVideoOn).map((u) => u.userId));
  const [toggleVideoTray, setToggleVideoTray] = useState(false);
  const videoPlayerListRef = useRef<Record<string, VideoPlayer>>({});
  // const activeVideo = useActiveVideo(zmClient);
  const initialTraysLoaded = useRef(false);
  const aspectRatio = useVideoAspect(zmClient);
  const previousSubscribers = usePrevious(subscribers);

  const { rightActivePanel } = useViewerSlice();
  const { layoutMode } = useLayoutSlice();

  const { resizeCanvas } = useCanvas();

  // Check the change in user object 
  type Changes = {
    [key: string]: 'Deleted' | 'Added' | { from: any, to: any }
  };

  function difference(obj1: object, obj2: object): Changes {
    const changes: Changes = {};
    const differences = {}
    function compare(obj1: object, obj2: object, changes: Changes, path: string[] = []): void {
      for (const key in obj1) {
        const currentPath = path.concat(key);
        if (_.isEqual((obj1 as any)[key], (obj2 as any)[key])) continue;
        if (!obj2.hasOwnProperty(key)) {
          changes[currentPath.join('.')] = 'Deleted';
        } else if (typeof (obj1 as any)[key] === 'object' && typeof (obj2 as any)[key] === 'object') {
          compare((obj1 as any)[key], (obj2 as any)[key], changes, currentPath);
        } else {
          Object.assign(differences, {
            userId: get(path, '0', ''),
            isVideoChanged: (key === 'bVideoOn'),
            videoState: get(obj2, 'bVideoOn', false),
            muteState: get(obj2, 'muted', false),
            userIdentity: get(obj2, 'userIdentity', '')
          })
          changes[currentPath.join('.')] = { from: (obj1 as any)[key], to: (obj2 as any)[key] };
        }
      }
      for (const key in obj2) {
        if (!obj1.hasOwnProperty(key)) {
          const currentPath = path.concat(key);
          changes[currentPath.join('.')] = 'Added';
        }
      }
    }

    compare(obj1, obj2, changes);
    return differences;
  }


  useParticipantsChange(zmClient, async (updatedparticipants) => {
    const updatedParticipantObject = Object.assign(
      {},
      ...updatedparticipants.map((item) => ({ [item.userId]: item }))
    );
    const participantObject = Object.assign(
      {},
      ...participants.map((item) => ({ [item.userId]: item }))
    );
    const paticipantUpdates = difference(
      participantObject,
      updatedParticipantObject
    );
    const user_id = Number(`${get(paticipantUpdates, "userId", "")}`);
    const user_identity = get(paticipantUpdates, "userIdentity", "")
    const participant_removed = participants.length && (updatedparticipants.length < participants.length)
    const changeInVideo = participantObject[Number(user_id)]?.bVideoOn !== paticipantUpdates.videoState;
    if (changeInVideo) {
      if (paticipantUpdates.videoState && mediaStream) {
        const userVideo: VideoPlayer | ExecutedFailure | undefined = await mediaStream?.attachVideo(user_id, 1);
        let video_player_container: any = document.getElementById(`player_${user_identity}`);
        if (!get(video_player_container, "children", "").length) {
          const childArray = Array.from(get(video_player_container, "children", ""));

          if (childArray.length) {
            childArray.forEach((child: any) => {
              video_player_container.removeChild(child);
            });
          }
          video_player_container.appendChild(userVideo);
          setVideoPlayerRef(user_identity, userVideo)
        }

      } else {
        //Detach
        let video_player: any = document.getElementById(`player_${user_identity}`);
        if (video_player?.children?.length && mediaStream) {
          Array.from(video_player?.children).forEach((child: any) => {
            video_player.removeChild(child);
          });
          await mediaStream?.detachVideo(user_id);
          delete videoPlayerListRef.current[`${user_identity}`]
        }
      }
    }

    //Reattach all the other participants
    if (participant_removed) {
      updatedparticipants.forEach((participant: Participant) => {
        const videoContainer = document.getElementById(`player_${participant.userIdentity}`)
        if (videoContainer && videoContainer.children.length) {
          videoContainer?.replaceChild(videoPlayerListRef.current[`${participant.userIdentity}`], videoContainer.children[0]);
        }
      })
    }

    setParticipants(updatedparticipants);
    const subscriberArray = updatedparticipants
      .filter((user) => user.bVideoOn)
      .map((u) => u.userId);
    setSubscribers(subscriberArray);
  });

  const setVideoPlayerRef = (userIdentity: number, element: any) => {
    if (element) {
      videoPlayerListRef.current[`${userIdentity}`] = element
    }
  };

  useEffect(() => {
    resizeCanvas();
    const checkforExisting = setTimeout(() => {
      if (!initialTraysLoaded.current && subscribers.length) {
        initialTraysLoaded.current = !!mediaStream;
        const addedUsers = subscribers.filter(
          (user) => !(previousSubscribers || []).includes(user),
        );
        const removedUsers = (previousSubscribers || []).filter(
          (user) => !subscribers.includes(user),
        );
        if (removedUsers.length > 0) {
          removedUsers.forEach(async (userId) => {
            if (mediaStream && userId !== currentUser.userId) {
              const parti = participants.find(
                (particpant: Participant) => particpant.userId === userId,
              );
              await mediaStream?.detachVideo(userId);
              alreadyJoinedGuests.current = alreadyJoinedGuests.current.filter(
                (user_ids: number) => user_ids !== userId,
              );
              delete videoPlayerListRef.current[`${userId}`];
            }
          });
        }

        if (subscribers.length > 0) {
          subscribers.forEach(async (userId) => {
            if (mediaStream) {
              const joinedParticipant = participants.find(
                (particpant: Participant) => particpant.userId === userId,
              );
              const userVideo = await mediaStream?.attachVideo(userId, 1);
              let video_player: any = document.getElementById(
                `player_${joinedParticipant?.userIdentity}`,
              );
              const childArray = Array.from(get(video_player, "children", ""));
              if (childArray.length) {
                childArray.forEach((child: any) => {
                  video_player.removeChild(child);
                });
              }
              video_player.appendChild(userVideo);
              setVideoPlayerRef(get(joinedParticipant, 'userIdentity', ''), userVideo);
              alreadyJoinedGuests.current.push(userId);
            }
          });
        }
        const screenSharingParticipants = participants.filter(
          (participant: Participant) => participant.sharerOn === true,
        );
        if (screenSharingParticipants.length) {
          screenSharingParticipants.forEach((participant: Participant) => {
            let video_player: any = document.getElementById(`player_${participant?.userIdentity}`);
            if (video_player && currentUser.userIdentity !== participant?.userIdentity) {
              const canvas: any = document.getElementById(
                `screen-share-content-canvas${participant.userIdentity}`,
              );
              if (canvas) mediaStream?.startShareView(canvas, participant.userId);
            }
          });
        }
      }
    }, 1000);

    return () => {
      clearTimeout(checkforExisting);
    };
  }, [mediaStream,subscribers]);

  const moveLeft = async (event: React.MouseEvent<HTMLElement>) => {
    document.getElementById('vtray')?.scrollBy(-250, 0);
  };
  const moveRight = async (event: React.MouseEvent<HTMLElement>) => {
    document.getElementById('vtray')?.scrollBy(250, 0);
  };


  const handleToggleVideoTray = () => {
    setToggleVideoTray(!toggleVideoTray)
  }

  const renderExpandCollapseArrow = () => {
    if (layoutMode === 'inverse') {
      return !toggleVideoTray ?
        <img src={arrowUp} alt="Collapse Video Tray" className="expand-collapse-icon" /> :
        <img src={arrowDown} alt="Collapse Video Tray" className="expand-collapse-icon" />;
    }
    return !toggleVideoTray ?
      <img src={arrowDown} alt="Collapse Video Tray" className="expand-collapse-icon" /> :
      <img src={arrowUp} alt="Collapse Video Tray" className="expand-collapse-icon" />;
  }

  return (
    <>
      <div className={`video-tray-collapse-bg ${layoutMode === 'inverse' ? 'inverse-layout' : ''}`}></div>
      <div className={`video-tiles-row-wrapper ${layoutMode === 'inverse' ? 'inverse-layout' : ''} ${toggleVideoTray ? 'collapse-tray' : ''}`}>
        <button className='flex-prev tray-btns' onClick={moveLeft}>
          <img src={chevronLeft} alt="left-btn" />
        </button>
        {participants.length ? (
          <div className="videotray" id="vtray">
            <div
              className={classnames(
                "video-container",
                "video-container-attech"
              )}
            >
              <div className="video-container-wrap">
                <ul className="user-list">
                  <video-player-container
                  >
                    {participants.map((user) => {
                     
                      return (
                        <li
                          id={`video-player-container-div${user.userIdentity}`}
                          key={`video-player-container-div${user.userIdentity}`}
                          style={{
                            display:
                              isGuestParticipant(get(user, 'userIdentity', '')) &&
                              !isGuestAllowed(get(user, 'userIdentity', ''))
                                ? 'none'
                                : 'block',
                          }}
                        >
                          <ZoomVideoTile
                            zmClient={zmClient}
                            mediaStream={mediaStream}
                            aspectRatio={aspectRatio}
                            videoPlayerListRef={videoPlayerListRef}
                            key={`video-tray-player-key${user.userIdentity}`}
                            user={user}
                            currentUser={currentUser}
                          />
                        </li>
                      );
                    })}
                  </video-player-container>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="empty-video-tiles-placeholder">
            <ContentLoader
              width={190}
              height={200}
              backgroundColor="#000005"
              foregroundColor="#1d1d1d"
            >
              <rect x="16" y="16" rx="2" ry="2" width="190" height="125" />
              <rect x="16" y="146" rx="2" ry="2" width="190" height="30" />
            </ContentLoader>
          </div>
        )}

        <button className='flex-next tray-btns' onClick={moveRight}>
          <img src={chevronRight} alt="right-btn" />
        </button>
      </div>
      <div className={layoutMode === "normal" ? "expand-collapse-btn" : "expand-collapse-btn expand-collapse-btn-inverse"} onClick={handleToggleVideoTray}>
        {renderExpandCollapseArrow()}
      </div>
    </>
  );
};
export default memo(ZoomVideoTray);
