import React from 'react'
import "./style.css"
import { Tooltip } from "antd";

export const GroupCardItem = (props: any) => {
    const { data } = props
    return (
        <div className="card-item-wrapper">
            <p className='first-child'>{data.full_name}</p>
            <Tooltip title={data.email}>
            <p className='last-child'>{data.email}</p>
            </Tooltip>
        </div>
    )
}

