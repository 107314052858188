import create from 'zustand';

type Coordinate = {
  x: number;
  y: number;
};

type State = {
  isEraser: boolean;
  setMyEraser: (newValue: boolean) => void;
  myEraserRef: React.MutableRefObject<boolean>;
  setEraserStateWithRef: (newValue: boolean) => void;
  isPencilLine: boolean;
  setMyPencilLine: (newValue: boolean) => void;
  myPencilLineRef: React.MutableRefObject<boolean>;
  setPencilLineStateWithRef: (newValue: boolean) => void;
  isPencilCircle: boolean;
  setMyPencilCircle: (newValue: boolean) => void;
  myPencilCircleRef: React.MutableRefObject<boolean>;
  setPencilCircleStateWithRef: (newValue: boolean) => void;
  showDrawingOption: boolean;
  setDrawingOption: (newValue: boolean) => void;
  isPainting: boolean;
  setIsPainting: (newValue: boolean) => void;
  mousePosition: Coordinate | undefined;
  setMousePosition: (newValue: Coordinate | undefined) => void;
  resetAllTools: () => void;
};

export const useWhiteboardingStore = create<State>((set) => ({
  isEraser: false,
  setMyEraser: (newValue: boolean) => set((state) => ({ ...state, isEraser: newValue })),
  myEraserRef: { current: false },
  setEraserStateWithRef: (newValue: boolean) => {
    set((state) => ({ 
        ...state, 
        isEraser: newValue,
        myEraserRef: {
            ...state.myEraserRef,
            current: newValue
        }
     }))
  },
  isPencilLine: false,
  setMyPencilLine: (newValue: boolean) => set((state) => ({ ...state, isPencilLine: newValue })),
  myPencilLineRef: { current: false },
  setPencilLineStateWithRef: (newValue: boolean) => {
    set((state) => ({ 
        ...state, 
        isPencilLine: newValue,
        myPencilLineRef: {
            ...state.myPencilLineRef,
            current: newValue
        }
     }))
  },
  isPencilCircle: false,
  setMyPencilCircle: (newValue: boolean) => set((state) => ({ ...state, isPencilCircle: newValue })),
  myPencilCircleRef: { current: false },
  setPencilCircleStateWithRef: (newValue: boolean) => {
    set((state) => ({ 
        ...state, 
        isPencilCircle: newValue,
        myPencilCircleRef: {
            ...state.myPencilCircleRef,
            current: newValue
        }
     }))
  },
  showDrawingOption: false,
  setDrawingOption: (newValue: boolean) => set((state) => ({ ...state, showDrawingOption: newValue })),
  isPainting: false,
  setIsPainting: (newValue: boolean) => set((state) => ({ ...state, isPainting: newValue })),
  mousePosition: undefined,
  setMousePosition: (newValue: Coordinate | undefined) => set((state) => ({ ...state, mousePosition: newValue })),
  resetAllTools: () => set((state) => ({
    ...state,
    isEraser: false,
    isPencilLine: false,
    isPencilCircle: false,
    myEraserRef: { ...state.myEraserRef, current: false },
    myPencilLineRef: { ...state.myPencilLineRef, current: false },
    myPencilCircleRef: { ...state.myPencilCircleRef, current: false }
  }))
}));
