import React, { useState, useEffect } from "react";
import { Popup, Button } from "semantic-ui-react";
import { useLocation } from 'react-router-dom'
import { firstBy } from "thenby";
import { get } from "../../Utils/helpers";
import SearchBox from "../../Components/Common/SearchBox";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import { showActiveUsers } from "../../Service/show";
import { Input, Tabs } from "antd";
import classNames from "classnames";
// import { GroupTab } from "./Tabs/GroupTab";
import { GroupTab } from "../ManageShowGroup/GroupTab";


// CSS 
import "./style.css"
import UserList from "../ManageShowGroup/UsersTab";
import PublisherUserList from "../ManageShowGroup/PublisherTab";

interface IProps {
  showId: string;
  selectedUser: any;
  showAdminEditUserClick(user: any): void;
  handleNavCreateUserClick: any;
  userUpdated: any;
  setUserUpdated: any;
  invitedUsers: any;
  setInvitedUsers: any;
  currentPage?: any,
  showGroup?: any,
  setManageShowGroupUsersStatus?: any;
  manageShowGroupUsersStatus?: any;
  setSelectedGroup?: any;
  selectedGroup?: any;
  fetchShowGroupData?: () => Promise<void>
  showGroupUsers?: any
  allGroupsUsersList?: any
  usersGroupsFlag?: boolean;
  isMasvFeature?: boolean;
}

const { TabPane } = Tabs;
const { Search } = Input;

const InvitedUsersList = (props: IProps) => {
  const { invitedUsers, setInvitedUsers, currentPage } = props;
  const [loading, setLoading] = useState(false);
  const [uniqueUsers, setUniqueUsers] = useState([] as any);
  const [publishers, setPublishers] = useState([]);
  const [showPublisher, setShowPublisher] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [isShowId, setIsShowId] = useState("")

  const {
    setManageShowGroupUsersStatus,
    setSelectedGroup,
    selectedGroup,
    manageShowGroupUsersStatus,
    fetchShowGroupData,
    showGroupUsers
  } = props


  // Show Admin
  const [showAdminCreateUserActive, setShowAdminCreateUserActive] =
    useState("");
  const [showAdminListUserAPublisher, setShowAdminListUserActive] =
    useState("");

  useEffect(() => {
    const sortedUser = invitedUsers.sort((a: any, b: any) => {
      const nameA = `${get(a, "user_name.user_name_first", "")} ${get(a, "user_name.user_name_last", "")}`.toLowerCase();
      const nameB = `${get(b, "user_name.user_name_first", "")} ${get(b, "user_name.user_name_last", "")}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });
    sortedUser.map((item: any) => {
      var findItem = uniqueUsers.find(
        (x: any) => x.user_id === item.user_id
      );
      if (!findItem) uniqueUsers.push(item);
    });
    uniqueUsers.sort(
      firstBy((v: any) =>
        get(v, "user_name.user_name_first", "").toLowerCase()
      ).thenBy((v: any) =>
        get(v, "user_name.user_name_last", "").toLowerCase()
      )
    );
    setUniqueUsers(uniqueUsers);

    const publishers: any = invitedUsers.filter(
      (user: any) => (user.role_type === "ShowPublisher" || user.role === "ShowPublisher")
    );
    publishers.sort(
      firstBy((v: any) =>
        get(v, "user_name.user_name_first", "").toLowerCase()
      ).thenBy((v: any) =>
        get(v, "user_name.user_name_last", "").toLowerCase()
      )
    ); 
    setPublishers(publishers);
  }, [invitedUsers])



  // useEffect(() => {
  //   console.log('manage check', manageShowGroupUsersStatus)
  //   console.log('showGroupUsers :>> ', showGroupUsers);
  // }, [manageShowGroupUsersStatus, showGroupUsers])


  useEffect(() => {
    const { selectedUser = {}, userUpdated } = props;
    const fetchData = async (show_id: string) => {
      try {
        setLoading(true);
        //Pull all active users of the show
        // fetch show list from algolia
        let activeUsers = await showActiveUsers(show_id);

        // To-do: Check in Backend whether you are part of the show and able to pull data
        const invitedUsers: any = activeUsers?.hits;
        invitedUsers.sort(
          firstBy((v: any) =>
            get(v, "user_name.user_name_first", "").toLowerCase()
          ).thenBy((v: any) =>
            get(v, "user_name.user_name_last", "").toLowerCase()
          )
        );

        setInvitedUsers(invitedUsers);

        const uniqueUsers: any = [];
        invitedUsers.map((item: any) => {
          var findItem = uniqueUsers.find(
            (x: any) => x.user_id === item.user_id
          );
          if (!findItem) uniqueUsers.push(item);
        });
        setUniqueUsers(uniqueUsers);

        const publishers: any = invitedUsers.filter(
          (user: any) => user.role_type === "ShowPublisher"
        );
        setPublishers(publishers);

        if (
          !!selectedUser.user_id &&
          ((typeof selectedUser.user_phone === "string" &&
            String(selectedUser.user_phone).toLowerCase().includes("x")) ||
            userUpdated)
        ) {
          const getRealUserData = invitedUsers.filter(
            (user: any) => user.user_id === selectedUser.user_id
          );
          props.showAdminEditUserClick(
            getRealUserData.length > 0 ? getRealUserData[0] : {}
          );
        }
        setLoading(false);
      } catch (err: any) {
        const msg = "Error while pulling invited show users data";
        console.error(msg);
      } finally {
        setLoading(false);
      }
    };

    if (props.showId && invitedUsers.length === 0) {
      fetchData(props.showId);
    }
    if (props.showId) {
      setIsShowId(props.showId);
    }
    const uniqueUsers: any = [];
    invitedUsers.map((item: any) => {
      var findItem = uniqueUsers.find((x: any) => x.user_id === item.user_id);
      if (!findItem) uniqueUsers.push(item);
    });
    setUniqueUsers(uniqueUsers);
    const publishers: any = invitedUsers.filter(
      (user: any) => user.role_type === "ShowPublisher"
    );
    setPublishers(publishers);
  }, [props.showId]);

  const showAdminEditUserClick = (user: any) => {
    if (props.showAdminEditUserClick) {
      setShowAdminCreateUserActive("Active");
      setShowAdminListUserActive("");
      props.showAdminEditUserClick(user);
    }
  };
  const handleUsers = () => {
    setSearchText('');
    setShowPublisher(false);
  };
  const renderUsers = () => {
    const filterData = uniqueUsers.filter((user: any) => {
      const firstName: string = get(user, "user_name.user_name_first", "");
      const lastName: string = get(user, "user_name.user_name_last", "");

      const email: string = get(user, "user_email", "");
      let phone: any = get(user, "user_phone", "");
      phone = (typeof phone === "string" ? phone : phone.user_phone) || "";
      if (!props.isMasvFeature) {
        return user.role_type !== "MailBox" && `${firstName} ${lastName} ${email} ${phone}`.toLowerCase().includes(searchText.toLowerCase());
      } else {
        return `${firstName} ${lastName} ${email} ${phone}`.toLowerCase().includes(searchText.toLowerCase());
      }
    });

    const uniqueUsersData = !!searchText ? filterData : uniqueUsers;
    if (uniqueUsersData.length === 0) {
      return <p className="invited-users-search-result">No Data Found</p>
    } else {
      return uniqueUsersData.map((user: any, index: number) => {
        return (

          <li key={index} className="vertical-list">
            <Popup
              content={user.user_email}
              size='small'
              position="top center"
              trigger={<span><img
                alt=""
                src={require("../../Images/logo.png")}
                className="user-image-icon"
              />
                <div className="info">
                  <div className="user-info"> {get(user, "user_name.user_name_first", "") +
                    " " + get(user, "user_name.user_name_last", "")}
                  </div>
                  <div className="user-mail"> {get(user, "user_email", "")}</div>
                </div></span>}
            />
            <span
              className="gear-image-icon"
              onClick={() => {
                showAdminEditUserClick(user);
              }}
            ></span>
          </li>

        );
      });
    };
  };
  const handlePublishers = () => {
    setSearchText('');
    setShowPublisher(true);
  };
  const handleSearch = (value: string) => {
    let iswhiteSpace = value.trim().length === 0;
    if (!iswhiteSpace) {
      setSearchText(value);
    } else {
      setSearchText("")
    }
  };
  const renderPublisherData = () => {
    const filterData = publishers.filter((user: any) => {
      const firstName: string = get(user, "user_name.user_name_first", "");
      const lastName: string = get(user, "user_name.user_name_last", "");
      const email: string = get(user, "user_email", "");
      let phone: any = get(user, "user_phone", "");
      phone = (typeof phone === "string" ? phone : phone.user_phone) || "";
      if (!props.isMasvFeature) {
        return user.role_type !== "MailBox" && `${firstName} ${lastName} ${email} ${phone}`.toLowerCase().includes(searchText.toLowerCase());
      } else {
        return `${firstName} ${lastName} ${email} ${phone}`.toLowerCase().includes(searchText.toLowerCase());
      }
    });
    const publishersData = !!searchText ? filterData : publishers;
    if (publishersData.length <= 0) {
      return <p className="invited-users-search-result">No Data Found</p>
    } else {
      return publishersData.map((user: any, index) => {
        return (
          <li key={index} className="vertical-list">
            <Popup
              content={user.user_email}
              size='small'
              position="top center"
              trigger={<span><img
                alt=""
                src={require("../../Images/logo.png")}
                className="user-image-icon"
              />
                <div className="info">
                  <div className="user-info"> {get(user, "user_name.user_name_first", "") +
                    " " + get(user, "user_name.user_name_last", "")}
                  </div>
                  <div className="user-mail"> {get(user, "user_email", "")}</div>
                </div></span>}
            />
            <span className="gear-image-icon"
              onClick={() => { showAdminEditUserClick(user) }}>
            </span>
          </li>
        );
      });
    };
  }
  return (
    <div className="">
      <ProgressLoader loading={loading} />
      {
        props.usersGroupsFlag ?
          <div className="left-container-wrapper">
            <Tabs
              centered={false}
              defaultActiveKey="1" >
              <TabPane
                tab="ALL USERS"
                key="1">
                <UserList
                  uniqueUsers={uniqueUsers}
                  handleNavCreateUserClick={props.handleNavCreateUserClick}
                  showAdminEditUserClick={props.showAdminEditUserClick}
                />
              </TabPane>
              <TabPane
                tab="PUBLISHERS"
                key="2">
                <PublisherUserList
                  publiserList={publishers}
                  handleNavCreateUserClick={props.handleNavCreateUserClick}
                  showAdminEditUserClick={props.showAdminEditUserClick}
                />
              </TabPane>
              {currentPage === "ManageLicenseViewer" ? (
                ''
              ) : (
                <TabPane
                  tab="GROUPS"
                  key="3"
                >
                  <GroupTab
                    setSelectedGroup={setSelectedGroup}
                    selectedGroup={selectedGroup}
                    fetchShowGroupData={fetchShowGroupData}
                    loading={loading}
                    setLoading={setLoading}
                    setManageShowGroupUsersStatus={setManageShowGroupUsersStatus}
                    manageShowGroupUsersStatus={manageShowGroupUsersStatus}
                    showGroup={props.showGroup}
                    showId={props.showId}
                    allGroupsUsersList={props.allGroupsUsersList}
                  />
                </TabPane>
              )}



            </Tabs>
          </div>
          :
          <>
            <div className="antd-invited-show-users">
              <Button
                color="violet"
                className="showuser-button"
                onClick={handleUsers}
              >
                All Users
              </Button>
              <Button
                color="violet"
                className="showuser-button"
                onClick={handlePublishers}
              >
                Publishers
              </Button>
            </div>
            <ul className="padding-left-0">
              <li className="invite-users-list-header vertical-header">
                <div> Invited Users</div>
                <div>
                  <span
                    className="invite-user-icon"
                    onClick={() => {
                      props.handleNavCreateUserClick();
                    }}
                  ></span>
                </div>
              </li>
              <div>
                <div className="show-admin-users">
                  <div className="show-admin-user-search">
                    <SearchBox searchSubmit={handleSearch} searchText={searchText} />
                  </div>
                </div>
                <div className="invited-users-list-wrapper">
                  {showPublisher === true && renderPublisherData()}
                  {showPublisher === false && renderUsers()}
                </div>
              </div>
            </ul>
          </>
      }

    </div>
  );
};
export default InvitedUsersList;
