import React, { useState, useContext, useEffect } from "react";
import { Layout } from "../PageLayout/Layout";
import { PAGES, ROLES } from "../../Utils/constants";
import CreateShow from "./CreateShow";
import CreateShowAdmin from "../../Containers/InviteAdmins/CreateShowAdmin";
import ListAllShows from "../../Containers/ListAllShows";
import ListAllStreams from "../../Containers/ListAllStreams";
import ListAllUsers from "../../Containers/ListAllUsers";
import ListAllUsersNew from "../../Containers/ListAllUsersNew";
import ListPPLDevices from "../../Containers/ListPPLDevices/ListPPLDevices";
import VcLists from "../../Containers/VcLists/Vclists";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../Common/ProgressLoader";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';

import "./superadmin.css";
import { useLocationStore } from "../../store/useLocationStore";
import useNavigateToPage from "../../hooks/useNavigateToPage";

const SuperAdminDashboard = () => {
  const locationState = useLocationStore((state) => state.locationState);
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const navigatefromFindUsersPage = get(locationState,'state.navigateToListAllUsersNew',false);
  const navigateToPage = useNavigateToPage();
  const [loading, setLoading] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
 
  interface ComponentState {
    renderShowCreate: boolean,
    renderInvite: boolean,
    renderListShows: boolean,
    renderListStreams: boolean,
    renderListUsers: boolean,
    renderListUsersNew: boolean,
    renderPplDevices: boolean,
    renderVCList:boolean
  }

  const [rpstate, setRPState] = useState<ComponentState>({
    renderShowCreate: !navigatefromFindUsersPage,
    renderInvite: false,
    renderListShows: false,
    renderListStreams: false,
    renderListUsers: false,
    renderListUsersNew: navigatefromFindUsersPage,
    renderPplDevices: false,
    renderVCList: false,
  });

  useEffect(() => {
    // Check access
    const checkAccess = async () => {
      setLoading(true);
      const isSuperAdmin = await backend.fetch({ api: api.users.isSuperAdmin }, get(currentUser, "user.accessToken", ""));
      console.log("isSuperAdmin : ", isSuperAdmin);
      setLoading(false);
      if (!isSuperAdmin) {
        console.log("Un-Authorised !!");
        navigateToPage("/login")
        
      }
      if (isSuperAdmin) {
        setSuperAdmin(true);
      }
    };
    checkAccess();
  }, []);

  const handleNavCreateShowClick = () => {
    console.log("Super Admin -> Navbar Create Show clicked");
    setRPState((prevrpstate) => ({
      ...prevrpstate,
    renderShowCreate: true,
    renderInvite: false,
    renderListShows: false,
    renderListStreams: false,
    renderListUsers: false,
    renderListUsersNew: false,
    renderPplDevices: false,
    renderVCList: false,
  }));}
  const handleNavInviteUserClick = () => {
    console.log("Super Admin -> Navbar Invite User clicked");
    setRPState((prevrpstate) => ({
      ...prevrpstate,
    renderShowCreate: false,
    renderInvite: true,
    renderListShows: false,
    renderListStreams: false,
    renderListUsers: false,
    renderListUsersNew: false,
    renderPplDevices: false,
    renderVCList: false,
  }));}
  const handleNavListShowsClick = () => {
    console.log("Super Admin -> Navbar List Shows clicked");
    setRPState((prevrpstate) => ({
      ...prevrpstate,
    renderShowCreate: false,
    renderInvite: false,
    renderListShows: true,
    renderListStreams: false,
    renderListUsers: false,
    renderListUsersNew: false,
    renderPplDevices: false,   
    renderVCList: false,
  }));}

  const handleNavListStreamsClick = () => {
    console.log("Super Admin -> Navbar List Streams clicked");
    setRPState((prevrpstate) => ({
      ...prevrpstate,
    renderShowCreate: false,
    renderInvite: false,
    renderListShows: false,
    renderListStreams: true,
    renderListUsers: false,
    renderListUsersNew: false,
    renderPplDevices: false,
    renderVCList: false,
  }));}

  const handleNavListUsersClick = () => {
    console.log("handleNavListUsersClick");
    console.log("Super Admin -> Navbar List users clicked");
    setRPState((prevrpstate) => ({
      ...prevrpstate,
    renderShowCreate: false,
    renderInvite: false,
    renderListShows: false,
    renderListStreams: false,
    renderListUsers: true,
    renderListUsersNew: false,
    renderPplDevices: false,
    renderVCList: false,
  }));}

  const handleNavListUsersNewClick = () => {
    console.log("handleNavListUsersNewClick");
    console.log("Super Admin -> Navbar List users new clicked");
    setRPState((prevrpstate) => ({
      ...prevrpstate,
    renderShowCreate: false,
    renderInvite: false,
    renderListShows: false,
    renderListStreams: false,
    renderListUsers: false,
    renderListUsersNew: true,
    renderPplDevices: false,
    renderVCList: false,
  }));}

  const handlePPLDeviceClick = () => {
    console.log("handleNavListUsersNewClick");
    console.log("Super Admin -> Navbar List users new clicked");
    setRPState((prevrpstate) => ({
      ...prevrpstate,
    renderShowCreate: false,
    renderInvite: false,
    renderListShows: false,
    renderListStreams: false,
    renderListUsers: false,
    renderListUsersNew: false,
    renderPplDevices: true,
    renderVCList: false,
    }));}

    const handleVcListClick = () => {
      console.log("handleNavListUsersNewClick");
      console.log("Super Admin -> Navbar List users new clicked");
      setRPState((prevrpstate) => ({
        ...prevrpstate,
      renderShowCreate: false,
      renderInvite: false,
      renderListShows: false,
      renderListStreams: false,
      renderListUsers: false,
      renderListUsersNew: false,
      renderPplDevices: false,
      renderVCList: true,
      }));}

  const renderCreateShow = () => {
    return (
      <>
        <div className="left-content">
          <div className="create-show"></div>
        </div>
        <div className="right-content">
          <div className="right-container">
            <CreateShow parent_show_id = {null} />
          </div>
        </div>
      </>
    );
  };

  const renderInviteShowAdmin = () => {
    return (
      <>
        <div className="left-content">
          <div className="invite_show_admin"></div>
        </div>
        <div className="right-content">
          <div className="right-container">
            <CreateShowAdmin roleLabel="Super Admin"/>
          </div>
        </div>
      </>
    );
  };

  const renderShowsList = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListAllShows roleLabel="Super Admin" role={ROLES.SUPER_ADMIN}/>
        </div>
      </>
    );
  };

  const renderStreamsList = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListAllStreams roleLabel="Super Admin"/>
        </div>
      </>
    );
  };
  const renderUsersList = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListAllUsers />
        </div>
      </>
    );
  };

  const renderUsersListNew = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListAllUsersNew  role={ROLES.SUPER_ADMIN}/>
        </div>
      </>
    );
  };

  const renderPplDeviceList = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListPPLDevices />
        </div>
      </>
    );
  };

  const renderVCList = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <VcLists />
        </div>
      </>
    );
  };

    const renderBodyContent = () => {
      if (rpstate.renderShowCreate) {
        return renderCreateShow();
      }

      if (rpstate.renderInvite) {
        return renderInviteShowAdmin();
      }
    
      if (rpstate.renderListShows) {
        return renderShowsList();
      }

      if (rpstate.renderListStreams) {
        return renderStreamsList();
      }

      if (rpstate.renderListUsers) {
        return renderUsersList();
      }

      if (rpstate.renderListUsersNew) {
      return renderUsersListNew();
      }

      if (rpstate.renderPplDevices) {
      return renderPplDeviceList();
      }
    
      if (rpstate.renderVCList) {
        return renderVCList();
      }

    return <div></div>;
  };
  return (
    <Layout
      page={PAGES.SUPER_ADMIN_PAGE}
      superAdminCreateShowClick={handleNavCreateShowClick}
      superAdminInviteUserClick={handleNavInviteUserClick}
      superAdminListShowsClick={handleNavListShowsClick}
      superAdminListStreamsClick={handleNavListStreamsClick}
      superAdminListUsersClick={handleNavListUsersClick}
      superAdminListUsersNewClick={handleNavListUsersNewClick}
      superAdminPPLDeviceClick={handlePPLDeviceClick}
      superAdminVcListsClick={handleVcListClick}
    >
      <ProgressLoader loading={loading} />

      {superAdmin ? renderBodyContent() : <div> </div>}
    </Layout>
  );
};
  
export default SuperAdminDashboard;