import React, { useEffect, useState, useContext } from 'react'
import { Button, Table, Input, Modal, Tooltip, message, notification, Checkbox } from 'antd';
import classNames from "classnames";
import type { TableProps } from 'antd/es/table';
import Title from '../../../../Components/UIKit/Title';
import {
    EditOutlined, UserDeleteOutlined
} from '@ant-design/icons';
// css
import "./style.css"
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import { get } from '../../../../Utils/helpers';
import ConfirmationModal from '../../../../Components/UIKit/ConfirmationModal/ConfirmationModal';
import { AuthContext } from '../../../../Context/authContext';

interface DataType {
    key: React.Key;
    name: string;
    role: number;
    emailId: string;
}
type Props = {
    filterValue: string | { searchString: string };
    handleFilterChange: (value: string) => void;
};
const { Search } = Input;

// render table config
const getTableConfig = ({ showGroupUserId, showGroupUsers, showUsers, filterValue, handleFilterChange, showModal, showAddModal }: any) => {
    // render add/remove show group user
    const renderAddShowUserButton = (text: string, records: any) => {
        const groupUserStatus = showGroupUserId.some((id: any) => id === records.user_id);
        let showGroupUser = showGroupUsers.filter((data: any) => data.user_id === records.user_id)

        return (
            <>
                {groupUserStatus && groupUserStatus ?
                    (
                        <Tooltip title="Remove">
                            <UserDeleteOutlined
                                onClick={() => showModal(showGroupUser)}
                                style={{ fontSize: '20px', color: '#ff2e2e' }} />
                        </Tooltip>
                    )
                    : (
                        null
                    )
                }
            </>
        );
    };


    const columns = [
        // Headers array for the table
        {
            title: "Name", // Header Name
            dataIndex: "user_name.user_name_first", // Algolia JSON key name,
            render: (text: string, record: any) => {
                return `${record.user_name.user_name_first} ${record.user_name.user_name_last} `;
            },
            sorter: (a: any, b: any) => {
                const nameA = `${a.user_name.user_name_first} ${a.user_name.user_name_last}`.toLowerCase();
                const nameB = `${b.user_name.user_name_first} ${b.user_name.user_name_last}`.toLowerCase();
                return nameA.localeCompare(nameB);
            },
        },
        {
            title: "Role",
            dataIndex: "role_type",
            sorter: (a: any, b: any) => {
                const nameA = `${a.role_type}`.toLowerCase();
                const nameB = `${b.role_type}`.toLowerCase();;
                return nameA.localeCompare(nameB);
            },
        },
        {
            title: "Email",
            dataIndex: "user_email",
            sorter: (a: any, b: any) => {
                const nameA = `${a.user_email}`.toLowerCase();
                const nameB = `${b.user_email}`.toLowerCase();;
                return nameA.localeCompare(nameB);
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: renderAddShowUserButton,
        },

    ];


    let userOrder = showUsers


    //  const isGroupUsers = selectedRow.some((x: any) => showGroupUsers.find((y: any) => x.user_id === y.user_id));
    //     setSelectedUsers(selectedRow)

    userOrder.sort((a: any, b: any) => {
        const isGroupUsers = showUsers.filter((x: any) => showGroupUsers.find((y: any) => x.user_id === y.user_id));
        const notGroupUsers = showGroupUsers.filter((i: any) => showUsers.map((y: any) => i.user_id === y.user_id));

        if (isGroupUsers) {
            // console.log('is group',isGroupUsers)
            return 1;
        } else if (notGroupUsers) {
            return -1
        } else {
            return a - b
        }
    })
    // console.log('showUsers :>> ', userOrder);
    let filteredDataSource = showUsers

    if (typeof filterValue === 'object' && filterValue !== null) {
        filteredDataSource = showUsers.sort((a: any, b: any) => {
            const nameA = `${a.user_name.user_name_first} ${a.user_name.user_name_last}`.toLowerCase();
            const nameB = `${b.user_name.user_name_first} ${b.user_name.user_name_last}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });
    }
    else if (filterValue == null) {
        let userOrder = showUsers
        return showUsers
    }

    return {
        columns,
        dataSource: filteredDataSource,
        onChange: handleFilterChange
    }
};

export const ManageShowGroupUsers = (props: any) => {
    const {
        manageShowGroupUsersStatus,
        selectedGroup,
        setSelectedGroup,
        showId,
        setLoading,
        invitedUsers,
        fetchShowGroupData,
        setShowGroupUsers,
        showGroupUsers,
        fetchShowGroupUsers,
        allGroupsUsersList
    } = props

    const currentUser = get(useContext(AuthContext), "currentUser", {} );
    //Group User
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isSelectedShowGroup, setIsSelectedShowGroup] = useState({} as any)
    const [selectedShowGroupUsers, setSelectedShowGroupUsers] = useState([] as any)
    const [showGroupUserId, setShowGroupUserId] = useState([] as any)
    const [isSelectedGroupUserStatus, setIsSelecteGroupUserStatus] = useState(false)

    // const [showGroupUsers, setShowGroupUsers] = useState([] as any) // added to the parent component


    const [showUsers, setShowUsers] = useState([] as any)
    const [showGroupId, setShowGroupId] = useState("")
    const [searchText, setSearchText] = useState("");
    const [filterValue, setFilterValue] = useState<string>('');
    const [openModal, setOpenModal] = useState(false);
    const [groupUser, setGroupUser] = useState([] as any)
    const [dis, setDis] = useState(true);
    const [messageApi, contextHolder] = message.useMessage();
    const [editedHeading, setEditedHeading] = useState('');
    const [oldHeading, setOldHeading] = useState(selectedGroup.show_group_name);
    const [successType, setSuccessType] = useState('');
    const [openNameModal, setOpenNameModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);

    const [addUsersSearchText, setAddUsersSearchText] = useState("")
    const [usersToBeAdded, setUsersToBeAdded] = useState([] as any[])

    // const success = () => {
    //     messageApi.open({
    //         type: 'success',
    //         content: 'Group name updated successfully',
    //     });
    // };


    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
    };
    const validateGroupName = async (e: any) => {
        const { value } = e.target
        if (e.keyCode === 13) {
            setOpenNameModal(true)
        }
    }
   
    const updateGroupName = async () => {
        setOpenNameModal(false)
        // const { value } = e.target
        const payload = {

            "show_group_id": selectedGroup.show_group_id,
            "show_id": showId,
            "show_group_name": editedHeading,
            "show_group_status": selectedGroup.show_group_status
        };
        try {
            setLoading(true);
            const data: any = {
                api: api.group.updateShowGroup,
                payLoad: JSON.stringify(payload)
            }

            const response = await backend.save(data, get(currentUser, "user.accessToken", ""));
            // console.log('response>>>>', response);
            setSelectedGroup(selectedGroup)
            if (response) {
                notification.success({
                    message: "Success",
                    description: 'Group name updated successfully',
                })
                setDis(true)

            }
            fetchShowGroupData()
            setOldHeading(editedHeading);
            //console.log('response>>>>', response);

        }
        catch (err) {
            notification.error({
                message: err.errMessage
            })
            setEditedHeading(oldHeading);
            document.getElementById("edit_text_header")?.blur()
            console.log(err)
        } finally {
            setLoading(false);
        }
    }


    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedShowGroupUsers(selectedRows)
    };


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };


    const handleFilterChange = (value: any) => {
        setFilterValue(value);
    }

    const showModal = (props: any) => {
        setOpenModal(true);
        setGroupUser(props);
    };

    const showAddModal = (props: any) => {
        setOpenAddModal(true);
        setSelectedShowGroupUsers(props);
    };

    const handleCancel = () => {
        setOpenModal(false);
        setUsersToBeAdded([]);
        setSelectedRowKeys([]);
        
    };

    const handleModalOk = () => {
        setOpenModal(false);
        removeShowGroupUser();
    }

    const handleSearch = (e: any) => {
        const { value } = e.target;
        let iswhiteSpace = value.trim().length === 0;
        if (!iswhiteSpace) {
            setSearchText(value);
        } else { setSearchText("") }
    };

    const handleGroupSuccess = (status = false) => {
        if (!status) {
            return
        }
        setSuccessType(' ')
        fetchShowGroupUsers()
    }


    const removeShowGroupUser = async () => {
        const selectedShowGroupUserIds = selectedShowGroupUsers.map((user: any) => showGroupUsers.find((gUser:any) => gUser.user_id === user.user_id).show_group_user_id)

        try {
            // let activeUsers = await fetchShows();
            const input = { showGroupUserIds: selectedShowGroupUserIds };
            const data = {
                api: api.group.deleteShowGroupUser,
                payLoad: (input)
            }
            props.setLoading(true)
            const result = await backend.save(data, get(currentUser, "user.accessToken", ""))
            if (!!result) {
                setSelectedShowGroupUsers([])
                setSelectedRowKeys([]);
            }
            setSuccessType('deleted')
            notification.success({
                message: groupUser.length > 1 ? 'Successfully deleted all the users from the group.'
                    : `Successfully deleted user from the group.`
            })
            setSelectedGroup(selectedGroup)
        } catch (error) {
            props.setLoading(false)
            notification.error({
                message: ' Error while deleting user!'
            })
            console.log(error)
        }
        finally {
            handleGroupSuccess(true)
        }
    }

    const addShowUsersToGroup = async (data: any) => {
        // const userList = data.filter((user: any) => ))
        setOpenAddModal(false)
        props.setLoading(true)
        const formattedUserList = !!data && data.map((user: any) => (
            {
                show_user_role_id: user.objectID || user.show_user_role_id || "",
                user_id: user.user_id
            }
        ))

        try {
            const input = {
                show_group_id: selectedGroup.show_group_id,
                user_arr_obj: formattedUserList
            };

            const data = {
                api: api.group.bulkAddShowGroupUser,
                // payLoad: (input)
                payLoad: JSON.stringify(input)

            }
            const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
            setSuccessType('added')

            if (!!result) {
                setSelectedShowGroupUsers([])
                setSelectedRowKeys([]);
                notification.success({
                    message: selectedShowGroupUsers.length > 1 ? 'Successfully added all the users to the group.'
                        : `Successfully added user to the group.`
                })
                handleGroupSuccess(true)

            }
        } catch (error) {
            console.log(error)
            notification.error({
                message: 'Error while adding user!'
            })
            props.setLoading(false)

        }
        finally {
            setOpenAddModal(false)
            setUsersToBeAdded([])
        }
    }

    useEffect(() => {
        if (selectedGroup) {
            setIsSelectedShowGroup(selectedGroup)
            setSelectedRowKeys([])

            setShowGroupId(selectedGroup.show_group_id)
            setEditedHeading(selectedGroup.show_group_name)
            setOldHeading(selectedGroup.show_group_name);

            // fetchShowGroupUsers() // fetch show group users list
            setShowGroupUsers(allGroupsUsersList.filter((groupUser: any) => groupUser.role_type === "ShowViewer" && groupUser.show_group_id === selectedGroup?.show_group_id))
            setShowGroupUserId(allGroupsUsersList.filter((groupUser: any) => groupUser.role_type === "ShowViewer" && groupUser.show_group_id === selectedGroup?.show_group_id).map((user: any) => user?.user_id))
        }

    }, [allGroupsUsersList, selectedGroup, selectedGroup?.show_group_id])

    const tableConfig: any = getTableConfig(
        { showGroupUserId, showUsers: invitedUsers, filterValue, showGroupUsers, handleFilterChange, showModal, showAddModal }
    );

    const getSearchData = (data: any, searchStr: string) => {
        const searchData = data.filter((user: any) => {
            const firstName: string = get(user, "user_name.user_name_first", "");
            const lastName: string = get(user, "user_name.user_name_last", "");
            const email: string = get(user, "user_email", "");

            let result = data;
            if (searchStr.trim() !== "") {
                result = `${firstName} ${lastName} ${email}`
                    .toLowerCase()
                    .includes(searchStr.toLowerCase());
            }
            return result
        });
        return searchData
    }

    return (
        <div className="manage-user-wrapper">
            <Title
                styleClass="page-title"
                size="18"
            >
                Manage Users
            </Title>
            <div className="page-header-title">
                <div className="page-header-title-left">
                    {contextHolder}
                    <Tooltip title="Edit Group Name">
                        <span className="edit-icon" onClick={() => {
                            setDis(false);
                            setTimeout(() => document.getElementById("edit_text_header")?.focus(), 0)
                        }}><EditOutlined /></span>
                    </Tooltip>
                    <Title>
                        <input type="text" id="edit_text_header" maxLength={20}
                            onChange={(e) => setEditedHeading(e.target.value)}
                            className="edit-text" value={editedHeading}
                            onKeyDown={validateGroupName}
                            disabled={dis} />
                    </Title>
                </div>
                <div>
                    <div className="searchbar-wrapper searchbar-manage-users">
                        <Search
                            placeholder="Search"
                            allowClear
                            className={classNames('left-container-searchbar')}
                            // @ts-ignore
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <ConfirmationModal
                onOk={updateGroupName}
                onCancel={() => {
                    setOpenNameModal(false);
                    setEditedHeading(oldHeading);
                    setDis(true)
                }}
                data={"Are you sure you want to edit group name?"}
                open={openNameModal}
            />
            <ConfirmationModal
                onOk={handleModalOk}
                onCancel={handleCancel}
                data={
                    groupUser.length > 1
                        ? "Are you sure you want delete all the selected users from the Group"
                        : "Are you sure you want delete selected user to the Group?"
                }
                open={openModal}
            />

            <Modal
                open={openAddModal}
                title="Add Users To Group"
                closable={false}
                centered={true}
                footer={[
                    <Button key="back" onClick={() => {
                        setOpenAddModal(false)
                        setUsersToBeAdded([])
                    }}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => { addShowUsersToGroup(usersToBeAdded) }} disabled={usersToBeAdded.length === 0}>
                        Add
                    </Button>
                ]}
            >
                {!!selectedGroup?.invited_streams && selectedGroup?.invited_streams.length > 0 ?
                    <div>
                        This group is already invited to streams and users can't be added now.
                    </div>
                    :
                    <div style={{ maxHeight: '600px', overflowY: 'auto', padding: '5px' }}>

                        <Search
                            placeholder="Search"
                            allowClear
                            className={classNames('left-container-searchbar')}
                            value={addUsersSearchText}
                            onChange={(e) => {
                                setAddUsersSearchText(e.target.value)
                            }}
                        />

                        {getSearchData((tableConfig.dataSource)
                            .filter((user: any) => !showGroupUserId.includes(user?.user_id) && user?.role_type === "ShowViewer"), addUsersSearchText)
                            .sort((a: any, b: any) => {
                                const nameA = `${a.user_name.user_name_first} ${a.user_name.user_name_last}`.toLowerCase();
                                const nameB = `${b.user_name.user_name_first} ${b.user_name.user_name_last}`.toLowerCase();
                                return nameA.localeCompare(nameB);
                            })
                            .map((user: any, index: number) => {
                                return (
                                    <div className="user-card" key={index}>
                                        <div className="data">
                                            <b>{user.user_name.user_name_first} {user.user_name.user_name_last}</b>
                                            <div><span>{user.user_email}</span></div>
                                        </div>
                                        <Checkbox checked={usersToBeAdded.filter((item: any) => item.objectID === user.objectID).length > 0} onChange={(e: any) => {
                                            let usersToBeAddedArr = [...usersToBeAdded];
                                            if (e.target.checked) {
                                                usersToBeAddedArr.push(user)
                                            } else {
                                                usersToBeAddedArr = usersToBeAddedArr.filter((item: any) => item.objectID !== user.objectID)
                                            }
                                            setUsersToBeAdded(usersToBeAddedArr)
                                        }} />
                                    </div>
                                )
                            })}
                    </div>
                }
            </Modal>
            <div className="action-buttons">
                <Button
                    disabled={selectedRowKeys.length === 0}
                    // type="primary"
                    danger
                    onClick={() => showModal(selectedShowGroupUsers)}
                    style={{ margin: '5px' }} >
                    Remove
                </Button>
                <Button
                    type="primary"
                    className="add-user-btn"
                    onClick={() => { setOpenAddModal(true) }}
                    style={{ margin: '5px' }} >
                    Add Users
                </Button>
            </div>

            <Table className='antd-table-new'
                showSorterTooltip={{ title: 'Sort Alphabetically' }}
                scroll={{ x: true }}
                rowSelection={rowSelection}
                onChange={tableConfig.onChange}
                columns={tableConfig.columns}
                dataSource={getSearchData(tableConfig.dataSource.filter((user: any) => user?.role_type === "ShowViewer"), searchText).filter((user: any) => showGroupUserId.includes(user?.user_id))}
                rowKey="user_id"
                pagination={{ pageSize: 20 }}
            />

        </div>
    )
}