import { Room } from "twilio-video";

export default function useGuestViewerCommands(
  updated: boolean,
  setUpdated: any,
  room?: Room
) {
  const dataTrack = room?.localParticipant?.dataTracks.values().next().value;

  const remove = (guestIdentity: string) => {
    let allowedGuestSession: any = sessionStorage.getItem("allowedGuest") || {};

    allowedGuestSession =
      typeof allowedGuestSession === "string"
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession;

    if (dataTrack && allowedGuestSession) {
      // Remove the guest user from session
      delete allowedGuestSession[guestIdentity];
      console.log("Admin - Guests present");
      sessionStorage.setItem(
        "allowedGuest",
        typeof allowedGuestSession === "string"
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession)
      );
      console.log(
        "remove-sessionStorage",
        allowedGuestSession,
        typeof allowedGuestSession
      );
      dataTrack.track.send(
        JSON.stringify({
          allowedGuestSession,
        })
      );
      setUpdated(!updated);
    }
  };

  const allow = (guestIdentity: string, sid: string) => {
    if (dataTrack) {
      let allowedGuestSession: any =
        sessionStorage.getItem("allowedGuest") || {};
      allowedGuestSession =
        typeof allowedGuestSession === "string"
          ? JSON.parse(allowedGuestSession)
          : allowedGuestSession;
      // Add the guest user into session
      allowedGuestSession[guestIdentity] = { sid, status: "allowed" };
      sessionStorage.setItem(
        "allowedGuest",
        typeof allowedGuestSession === "string"
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession)
      );
      setTimeout(()=>{
        dataTrack.track.send(
          JSON.stringify({
            allowedGuestSession: allowedGuestSession,
          })
        )}
        ,3500)
      
      setUpdated(!updated);
      const guestDiv: any = document.getElementById(sid);
      guestDiv.style.display = "block";
    }
  };

  return { remove, allow };
}
