import { MouseEvent } from 'react';
import { CaretUpOutlined, SyncOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import useEncoder from '../hooks/useEncoder';
import EncoderDeviceList from './EncoderDeviceList';
import EncoderStreamSettings from './EncoderStreamSettings';
import DeviceStatus from './DeviceStatus';
import { PanelProps } from './structs';

const { Panel } = Collapse;

function EncoderSettings() {
  const { getStatus } = useEncoder();

  const getIcon = (status: PanelProps) => {
    const { isActive } = status;
    return <CaretUpOutlined className='collapse-icon' rotate={isActive ? 0 : 180} />;
  };

  const getRefresh = () => (
    <SyncOutlined
      className='collapse-icon'
      onClick={(event: MouseEvent) => {
        event.stopPropagation();
        getStatus();
        console.log('refresh');
      }}
    />
  );

  return (
    <Collapse expandIconPosition='end' expandIcon={getIcon} defaultActiveKey={['encoder-1']}>
      <Panel header='Devices' key='encoder-1'>
        <EncoderDeviceList />
      </Panel>
      <Panel header='Stream Settings' key='encoder-2'>
        <EncoderStreamSettings />
      </Panel>
      <Panel
        className='encoder-device-status-wrapper'
        header='Device Settings'
        key='encoder-3'
        extra={getRefresh()}
      >
        <DeviceStatus />
      </Panel>
    </Collapse>
  );
}

export default EncoderSettings;
