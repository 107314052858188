import React from "react";
import SwaggerUI from "swagger-ui-react";
import { auth } from "../../Firebase";
import "swagger-ui-react/swagger-ui.css";
import SwaggerJson from "./swagger.json";

// const baseUrl = process.env.REACT_APP_SWAGGER_HOST || SwaggerJson.host;
// const swaggerObj: any = SwaggerJson;
// swaggerObj.host = baseUrl;

const requestInterceptor = async (params: any) => {
  const token = await auth.getToken();
  params.headers["Authorization"] = "Bearer " + token;
  params.headers["api_key"] = "f8e0a00d-dc3a-4509-91d6-d01b60b968ae";
  return params;
};

const Swagger = () => (
  <div style={{touchAction: "none"}}>
    <SwaggerUI spec={SwaggerJson} requestInterceptor={requestInterceptor} />
  </div>
);

export default Swagger;
