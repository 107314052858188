import React, { useState, useEffect, memo } from "react";
import { Button, Modal } from "semantic-ui-react";
import {
  AudioInputTest,
  AudioOutputTest,
  VideoInputTest,
  MediaConnectionBitrateTest,
  DiagnosticError,
  testAudioInputDevice,
  testAudioOutputDevice,
  testVideoInputDevice,
  testMediaConnectionBitrate,
  WarningName,
} from "@twilio/rtc-diagnostics";
import ReactSelect from "../../../Components/Common/ReactSelect";
import { get } from "../../../Utils/helpers";
import "./Diagnostics.css";
import basic_profile_img from "../../../Images/pp_logoblack.svg";

const AUDIO_LEVEL_THRESHOLD = 200;

const Diagnostics = (props: any) => {
  const { iceServers, setShowDiagnostics } = props;
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDevices, setSelectedDevices] = useState({
    speaker: "",
    microphone: "",
    video: "",
  });
  const [testResult, setTestResult] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    const getDevices = () =>
      navigator.mediaDevices
        .enumerateDevices()
        .then((mediaDevices) =>
          mediaDevices
            .filter(
              (device) =>
                device.kind === "audioinput" || device.kind === "audiooutput"
            )
            .every((device) => !(device.deviceId && device.label))
        )
        .then((shouldAskForMediaPermissions) => {
          if (shouldAskForMediaPermissions) {
            return navigator.mediaDevices.getUserMedia({ audio: true });
          }
        })
        .then(() =>
          navigator.mediaDevices
            .enumerateDevices()
            .then((mediaDevices) => setDevices(mediaDevices))
        );

    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  const audioInputTest = () => {
    setDisableBtn(true);
    const audioInputDeviceTest = testAudioInputDevice({
      deviceId: selectedDevices.microphone,
    });

    audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
      //console.log(volume);
    });

    audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
      console.error(error);
    });

    audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
      console.log("AudioInputTest", report);
      report.testName = "Audio Input Test";
      setTestResult(report);
      setDisableBtn(false);
    });
    setTimeout(() => {
      audioInputDeviceTest.stop();
      setTimeout(() => {
        setTestResult({});
      }, 10000);
    }, 10000);
  };

  const audioOutputTest = () => {
    setDisableBtn(true);
    const audioOutputDeviceTest = testAudioOutputDevice({
      deviceId: selectedDevices.speaker,
      doLoop: false,
      testURI: "ImperialMarch60.wav",
    });

    audioOutputDeviceTest.on(AudioOutputTest.Events.Volume, (volume) => {
      // setOutputLevel(getAudioLevelPercentage(volume));
    });

    audioOutputDeviceTest.on(AudioOutputTest.Events.Error, (error) => {
      console.error(error);
    });

    audioOutputDeviceTest.on(AudioOutputTest.Events.End, (report) => {
      //setOutputLevel(0);
      console.log("AudioOutputTest", report);
      report.testName = "Audio Output Test";
      setTestResult(report);
      setDisableBtn(false);
    });

    setTimeout(() => {
      audioOutputDeviceTest.stop();
      setTimeout(() => {
        setTestResult({});
      }, 10000);
    }, 10000);
  };

  const videoInputTest = () => {
    setDisableBtn(true);
    const videoElement = document.getElementById(
      "diagnostics-player"
    ) as HTMLVideoElement;
    const videoInputDeviceTest = testVideoInputDevice({
      element: videoElement,
    });

    videoInputDeviceTest.on(VideoInputTest.Events.Error, (error) => {
      //console.error(error);
    });

    videoInputDeviceTest.on(VideoInputTest.Events.End, (report) => {
      console.log("VideoInputTest", report);
      report.testName = "Video Input Test";
      setTestResult(report);
      setDisableBtn(false);
    });

    setTimeout(() => {
      videoInputDeviceTest.stop();
      setTimeout(() => {
        setTestResult({});
      }, 10000);
    }, 10000);
  };

  const mediaConnectionTest = () => {
    setDisableBtn(true);
    console.log("iceServers", iceServers);
    const mediaConnectionBitrateTest = testMediaConnectionBitrate({
      iceServers,
    });

    mediaConnectionBitrateTest.on(
      MediaConnectionBitrateTest.Events.Bitrate,
      (bitrate) => {
        console.log(bitrate);
      }
    );

    mediaConnectionBitrateTest.on(
      MediaConnectionBitrateTest.Events.Error,
      (error) => {
        console.log(error);
      }
    );

    mediaConnectionBitrateTest.on(
      MediaConnectionBitrateTest.Events.End,
      (report) => {
        report.testName = "Media Connection Bitrate Test";
        setTestResult(report);
        setDisableBtn(false);
        console.log("MediaConnectionBitrateTest", report);
      }
    );

    setTimeout(() => {
      mediaConnectionBitrateTest.stop();
      setTimeout(() => {
        setTestResult({});
      }, 10000);
    }, 10000);
  };

  function getAudioLevelPercentage(level: number) {
    return (level * 100) / AUDIO_LEVEL_THRESHOLD; // 0 to 100
  }
  const round = (num: number, decimals = 2) =>
    Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;

  function getStandardDeviation(values: number[]): number {
    if (values.length <= 0) {
      return 0;
    }

    const valueAverage: number =
      values.reduce(
        (partialSum: number, value: number) => partialSum + value,
        0
      ) / values.length;

    const diffSquared: number[] = values.map((value: number) =>
      Math.pow(value - valueAverage, 2)
    );

    const stdDev: number = Math.sqrt(
      diffSquared.reduce(
        (partialSum: number, value: number) => partialSum + value,
        0
      ) / diffSquared.length
    );

    return round(stdDev);
  }
  console.log("devices", devices);

  const speakerList = devices
    .filter((dev: any) => dev.kind === "audiooutput")
    .map((dev: any) => ({ label: dev.label, value: dev.deviceId }));
  const microphoneList = devices
    .filter((dev: any) => dev.kind === "audioinput")
    .map((dev: any) => ({ label: dev.label, value: dev.deviceId }));
  const videoList = devices
    .filter((dev: any) => dev.kind === "videoinput")
    .map((dev: any) => ({ label: dev.label, value: dev.deviceId }));
  console.log("selectedDevices", selectedDevices.speaker);
  return (
    <Modal
      open={true}
      size={"fullscreen"}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="diagnostics-modal"
    >
      <Modal.Header> Diagnostics Tool</Modal.Header>
      <Modal.Content>
        <div className="diagnostics-result">
          {!!testResult && !!get(testResult, "errors", false) && (
            <>
              {get(testResult, "errors", []).length > 0 ? (
                <div className="diagnostics-error"> 
                    Error: {get(testResult, "errors", []).toString()}
                </div>
              ) : (
                <div className="diagnostics-success">  
                    {get(testResult, "testName", "")} is Successful, No issues
                    found.
                </div>
              )}
            </>
          )}
        </div>
        <div className="diagnostics-wrapper">
          <div className="diagnostics-speaker">
            <h4>Speaker</h4>
            <div className="diagnostics-test-wrapper">
              <div>
                <ReactSelect
                  name="speaker-select"
                  value={[selectedDevices.speaker]}
                  options={speakerList}
                  onChange={(value: any) => {
                    setSelectedDevices({
                      ...selectedDevices,
                      speaker: value.value,
                    });
                  }}
                />
              </div>
              <div>
                {" "}
                <Button
                  color="green"
                  disabled={!selectedDevices.speaker || disableBtn}
                  className="diagnostics-action-btn"
                  onClick={audioOutputTest}
                >
                  Start Test
                </Button>
              </div>
            </div>
          </div>
          <div className="diagnostics-microphone">
            <h4>Microphone</h4>
            <div className="diagnostics-test-wrapper">
              <div>
                <ReactSelect
                  name="microphone-select"
                  value={[selectedDevices.microphone]}
                  options={microphoneList}
                  onChange={(value: any) => {
                    setSelectedDevices({
                      ...selectedDevices,
                      microphone: value.value,
                    });
                  }}
                />
              </div>
              <div>
                <Button
                  color="green"
                  disabled={!selectedDevices.microphone || disableBtn}
                  className="diagnostics-action-btn"
                  onClick={audioInputTest}
                >
                  Start Test
                </Button>
              </div>
            </div>
          </div>
          <div className="diagnostics-video">
            <h4>Video Input</h4>
            <div className="diagnostics-test-wrapper">
              <div>
                <ReactSelect
                  name="microphone-select"
                  value={[selectedDevices.video]}
                  options={videoList}
                  onChange={(value: any) => {
                    setSelectedDevices({
                      ...selectedDevices,
                      video: value.value,
                    });
                  }}
                />
              </div>
              <div>
                <Button
                  color="green"
                  disabled={!selectedDevices.video || disableBtn}
                  className="diagnostics-action-btn"
                  onClick={videoInputTest}
                >
                  Start Test
                </Button>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <video
                preload="metadata"
                poster={basic_profile_img}
                id="diagnostics-player"
                width="200"
                height="200"
                autoPlay
              ></video>
            </div>
          </div>
          <div className="diagnostics-connectivity">
            <h4>Media Connection Bitrate</h4>
            <Button
              color="green"
              disabled={disableBtn}
              className="diagnostics-action-btn"
              onClick={mediaConnectionTest}
            >
              Start Test
            </Button>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={() => setShowDiagnostics(false)}>
          CLOSE
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default memo(Diagnostics);
