import { Dropdown } from "semantic-ui-react";
import ImageCanvas from '../../../UserSettings/ImageCanvas';
import { auth } from "../../../../Firebase";
import { get, isEmpty } from "../../../../Utils/helpers";
import { isGuestAuthenticated } from '../../../../Utils/guestHelpers';

import dndInactiveIcon from '../../../../Images/dnd-inactive-icon.svg';
import dndActiveIcon from '../../../../Images/dnd-active-icon.svg';
import hideDominantSpeakerIcon from '../../../../Images/hide-dom-speaker.svg';
import showDominantSpeakerIcon from '../../../../Images/show-dom-speaker.svg';
import { useContext } from 'react';
import zoomContext from '../../context/zoom-context';
import useGuestSessions from '../../GuestInvite/useGuestSessions';
import { useViewerSlice } from '../../../../store/slices/viewer/viewerSlice';
import { useShowspeakerStore, useWatermarkStore } from '../../../../store';
import { useLocationStore } from '../../../../store/useLocationStore';
import useNavigateToPage from '../../../../hooks/useNavigateToPage';
import useCanvas from "../../hooks/useCanvas";



const HeaderAccountSettings = (props: any) => {
    const {
        isVCHost,
        user_imageurl,
        showMultiStreamViewer,
        // handleLeaveConference,
        handleHudStatsDisplayPanel,
        user_chime_preferance,
        updateUserChimePref,
        // handleDND,
        // isDND,
        // showDominantSpeaker,
        // showDominantSpeakerView,
        // showStreamContent,
        // signOut,
        // vc_id,
        removeGuestUser
    } = props
    let style = null;
    const isGuestUser = isGuestAuthenticated();
    const navigateToPage = useNavigateToPage();
    const {showSpeakerViewer, setShowSpeakerViewer} = useShowspeakerStore()
    const { resizeCanvas,videoReady,renderCanvas } = useCanvas();
    const { setPreview } = useWatermarkStore();
    const locationState = useLocationStore((state) => state.locationState);
    const show_id = get(locationState,'state.show_id','');
    const team_admin = get(locationState,'state.team_admin',false);
    const resetPassword = async () => {
        const user = auth.getUser();
        const uid = get(user, 'uid', null);
        navigateToPage('/resetpassword', { state: { userId: uid, from:'/viewer', show_id:show_id } },true);
    };

    const showSpeakerViewerVideo = () =>{
        setShowSpeakerViewer(true);
    }

    const hideSpeakerViewerVideo = () => {
      setShowSpeakerViewer(false);
      const videoPlayer = document.getElementById('video-container');
      if (videoPlayer) {
        videoPlayer.style.display = 'flex';
      }
      const canvas = document.getElementById('canvas');
      if (canvas) {
        canvas.style.height = '100%';
        canvas.style.width = '100%';
        renderCanvas(canvas);
      }
      videoReady();
      resizeCanvas();
    };

    const profile = async () => {
        const user = auth.getUser();
        const uid = get(user, 'uid', null);
        navigateToPage('/profile', { state: { userId: uid },show_id:show_id },true);
    };

    const gotoPublisher = async () => {
        const stream_id = sessionStorage.getItem('stream_id');
        const stream_label = sessionStorage.getItem('stream_label');
        navigateToPage('/showpublisher', { state: { show_id, stream_id, stream_label } },true);
    };

    const gotoTeamAdmin = async () => {
        navigateToPage('/teamadmin', { state: { show_id, team_admin } },true);
    };

    const zmClient = useContext(zoomContext);
    
    const {closeRightPanel} = useViewerSlice();

    
    
    // To do move to a hook
      const signOut = async()=>{
        try {
           
            closeRightPanel();
            await zmClient.leave();
            sessionStorage.clear();
            await auth.doSignOut();
          } catch (err: any) {
            console.log(err);
          } finally {
            navigateToPage('/logoutredirect', {replace : true},true);
          
          }
    }

    
    return (
        <li>
            <Dropdown className='right-header-image' style={style} pointing='top right' icon={(<ImageCanvas imageUrl={user_imageurl} className={'profile-image-property'} />)}>
                <Dropdown.Menu>
                    {!isGuestUser && team_admin ? (
                        <Dropdown.Item
                            text='Go to Team Admin'
                            icon='user circle outline'
                            onClick={gotoTeamAdmin}
                        />
                    ) : !isGuestUser && isVCHost ? (
                        <Dropdown.Item
                            text='Go to Publisher'
                            icon='user circle outline'
                            onClick={() => {
                                gotoPublisher()
                                setPreview(false);
                              }}
                        />
                    ) : (
                        ''
                    )}
                    {!showMultiStreamViewer && (
                        <Dropdown.Item
                            text='Media Stats'
                            icon='info circle'
                            onClick={handleHudStatsDisplayPanel}
                        />
                    )}
                    {!isGuestUser && (
                        <Dropdown.Item
                            text='Chime Sound'
                            icon={user_chime_preferance ? 'microphone' : 'microphone slash'}
                            onClick={updateUserChimePref}
                        />
                    )}
                    {/* {!isGuestUser && (
                        <Dropdown.Item onClick={handleDND}>
                            <img className='dnd-icon' src={isDND ? dndInactiveIcon : dndActiveIcon} alt='' />
                            {isDND ? 'Deactivate DND' : 'Do Not Disturb'}
                        </Dropdown.Item>
                    )} */}

                    {!isGuestUser && showSpeakerViewer ? (
                        <Dropdown.Item onClick={hideSpeakerViewerVideo}>
                            <img className='dominant-speaker-icon' src={hideDominantSpeakerIcon} alt='' />
                            Hide Speaker Viewer
                        </Dropdown.Item>
                    ) : (
                        !isGuestUser && (
                            <Dropdown.Item onClick={showSpeakerViewerVideo}>
                                <img className='dominant-speaker-icon' src={showDominantSpeakerIcon} alt='' />
                                Show Speaker Viewer
                            </Dropdown.Item>
                        )
                    )}
                    {!isGuestUser && (
                        <Dropdown.Item text='Account Settings' icon='user circle outline' onClick={profile} />
                    )}
                    {/* <Dropdown.Item text="Help" icon="help circle" /> */}
                    {!isGuestUser && (
                        <Dropdown.Item text='Reset Password' icon='key' onClick={resetPassword} />
                    )}
                    <Dropdown.Item
                        text='Log Out'
                        icon='power'
                        onClick={
                            !isGuestUser
                                ? () => {
                                    signOut();
                                }
                                : removeGuestUser
                        }
                    />
                </Dropdown.Menu>
            </Dropdown>
        </li>
    );
};

export default HeaderAccountSettings;