import { useContext, useState } from "react";
import { Col } from "antd";
import noiseCancellation from "../../../Images/noise-cancellation.svg";
import noiseCancellationOn from "../../../Images/noise-cancellation-on.svg";
import ZoomMediaContext from "../context/media-context";
import { useShowspeakerStore, useWhiteboardingStore } from "../../../store";

const NoiseCancellation = ({ resetDrawingButtonState }: any) => {
  const [cancelNoise, setCancelNoise] = useState(false);
  const { mediaStream } = useContext(ZoomMediaContext);
  const {showSpeakerViewer} = useShowspeakerStore()
  const { resetAllTools } = useWhiteboardingStore();
  const handleCancelNoise = () => {
    console.log('::::>>>>>',cancelNoise)
    if (!cancelNoise) {
    
      mediaStream?.enableBackgroundNoiseSuppression(true);
    } else {
      mediaStream?.enableBackgroundNoiseSuppression(false);
    }
    setCancelNoise(!cancelNoise);
    resetDrawingButtonState();
    resetAllTools();
  };

  return (
     <Col>
      <div className={`top-menu-button-item ${showSpeakerViewer ? "disableddiv" : ""}`} onClick={handleCancelNoise}>
        {/* Noise Cancellation */}
        <div
          className={cancelNoise ? "noise-cancellation-on" : "noise-cancellation"}
        >
          <img
            src={cancelNoise ? noiseCancellationOn : noiseCancellation}
            alt=""
          />
        </div>
        <span style={{marginLeft: "25px"}}>Noise Cancellation</span>
      </div>
    </Col>
  );
};
export default NoiseCancellation;
