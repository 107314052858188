import { Button, Checkbox } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import DeviceList from './DeviceList';
import { get } from '../../Utils/helpers';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import UserMessage from '../../Components/Common/UserMessage';
import { ErrorComponentState, FieldValidComponentState, EditDataComponentState } from './structs';
import AddorEditModal from './AddorEditDeviceModal';
import { AuthContext } from '../../Context/authContext';
import backend from '../../Service/Backend';
import api from '../../Service/Api';
import { fetchAllShows } from '../../Service/show';
import { FEATURE_NAME } from '../../Utils/constants';

const ListPPLDevices = () => {
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [refreshList, setRefreshList] = useState(false);

  const [shows, setShows] = useState([]);

  const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  const deviceNameRegex = /[^a-zA-Z0-9\s-]/g;

  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  const [errorState, setErrorState] = useState<ErrorComponentState>({
    errorMessage: '',
    macIdError: '',
    deviceNameError: '',
  });

  const [fieldValidationState, setFieldValidationState] = useState<FieldValidComponentState>({
    deviceNameVaild: false,
    macIdValid: false,
  });

  const [formValueState, setFormValueState] = useState<EditDataComponentState>({
    deviceName: '',
    macId: '',
    provision: false,
    show_id: '',
    stream_id: '',
  });

  useEffect(() => {
    const getDeviceData = async () => {
      try {
        setLoading(true);
        await getAllshows();
        const data = {
          api: api.encoder.device,
        };
        const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
        setDeviceData(result);
      } catch (err) {
        setErrorState((prevState) => ({
          ...prevState,
          errorMessage: err.errMessage,
        }));
      } finally {
        setLoading(false);
      }
    };
    getDeviceData();
  }, [authValue, refreshList]);

  const getAllshows = async () => {
    try {
      const result = await fetchAllShows();
      const shows = result?.hits.filter((item: any) => !item.parent_show_id);

      const encoderShows: any = shows?.filter((item: any) =>
        get(get(item, 'feature_flags', ''), `${FEATURE_NAME.ALLOW_ENCODER}`, false),
      );
      setShows(Object.values(encoderShows));
    } catch (err: any) {
      console.error(err);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const renderShowName = () => {
    const showList = shows.map((item: any) => {
      return { key: item.objectID, value: item.objectID, text: item.show_name };
    });

    return (
      <div className='field-and-icon'>
        <div className='field-icon-note left-icon'></div>
        <div className='form-field icon-input-field'>
          <label className='form-input-label mandatory'>Show Name</label>

          <Dropdown
            placeholder='Select'
            className='form-input'
            fluid
            search
            selection
            options={showList}
            name='auto_allow'
            value={formValueState.show_id}
            onChange={(event: any, data: any) => {
              // setProvision(event.target.value);
              setFormValueState((prevState) => ({
                ...prevState,
                show_id: data.value,
              }));
            }}
          ></Dropdown>
        </div>
      </div>
    );
  };

  const renderCheckBox = () => {
    return (
      <>
        {/* <div className='add-device-checkbox-item'> */}
        <Checkbox checked={formValueState.provision} onChange={handleProvision} />
        &nbsp;&nbsp;
        <label>
          <span className='label-checkbox'>Provision</span>
        </label>
        {/* </div> */}
      </>
    );
  };

  const validateDeviceName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    const deviceName = value.replace(deviceNameRegex, '');
    setFormValueState((prevState) => ({
      ...prevState,
      deviceName: deviceName.toLowerCase(),
    }));
    let deviceNameExists = false;
    if (
      deviceData.length &&
      deviceData.filter((device: any) => device.name.toLowerCase() === deviceName.toLowerCase())
        .length
    ) {
      deviceNameExists = true;
    }

    if (deviceName === '') {
      setErrorState((prevState) => ({
        ...prevState,
        deviceNameError: 'Please enter the name of the device',
      }));
      setFieldValidationState((prevState) => ({
        ...prevState,
        deviceNameVaild: false,
      }));
    } else if (deviceNameExists) {
      setErrorState((prevState) => ({
        ...prevState,
        deviceNameError: 'Device name already exists',
      }));
      setFieldValidationState((prevState) => ({
        ...prevState,
        deviceNameVaild: false,
      }));
    } else {
      setErrorState((prevState) => ({
        ...prevState,
        deviceNameError: '',
      }));
      setFieldValidationState((prevState) => ({
        ...prevState,
        deviceNameVaild: true,
      }));
    }
  };

  const handleProvision = (event: any) => {
    const checked = event.target.checked;
    setFormValueState((prevState) => ({
      ...prevState,
      provision: checked,
    }));
  };

  const validateMacId = (event: any) => {
    let value = event.target.value.trim();
    const macIdValue = value.replace(/-/g, ':');
    // setMacId(value);
    setFormValueState((prevState) => ({
      ...prevState,
      macId: macIdValue.toLowerCase(),
    }));
    let macIdExists = false;
    if (
      deviceData.length &&
      deviceData.filter((device: any) => device.mac_id.toLowerCase() === macIdValue.toLowerCase())
        .length
    ) {
      macIdExists = true;
    }
    if (value === '') {
      setErrorState((prevState) => ({
        ...prevState,
        macIdError: 'Please enter mac id',
      }));
      setFieldValidationState((prevState) => ({
        ...prevState,
        macIdValid: false,
      }));
    } else if (!macAddressRegex.test(value)) {
      setErrorState((prevState) => ({
        ...prevState,
        macIdError: 'Please enter the correct MAC address',
      }));
      setFieldValidationState((prevState) => ({
        ...prevState,
        macIdValid: false,
      }));
    } else if (macIdExists) {
      setErrorState((prevState) => ({
        ...prevState,
        macIdError: 'Mac id already exists',
      }));
      setFieldValidationState((prevState) => ({
        ...prevState,
        macIdValid: false,
      }));
    } else {
      setErrorState((prevState) => ({
        ...prevState,
        macIdError: '',
      }));
      setFieldValidationState((prevState) => ({
        ...prevState,
        macIdValid: true,
      }));
    }
  };

  const cleanupFunction = () => {
    setModalOpen(false);
    setFormValueState((prevState) => ({
      ...prevState,
      deviceName: '',
      macId: '',
      provision: false,
      show_id: '',
      stream_id: '',
    }));
    setErrorState((prevState) => ({
      ...prevState,
      errorMessage: '',
      macIdError: '',
      deviceNameError: '',
    }));
    setFieldValidationState((prevState) => ({
      ...prevState,
      deviceNameVaild: false,
      macIdValid: false,
    }));
  };

  return (
    <div className='superadmin-list-shows'>
      <ProgressLoader loading={loading} />
      <UserMessage errorMsg={errorState.errorMessage} />
      <div className='superadmin-list-heading'>
        <p className='heading-text showlist-heading'>
          Super Admin :<span className='grey-text'> PPL Devices</span>
        </p>

        <div className='heading-text-right'>
          <Button onClick={() => openModal()} className='add-device-btn'>
            {' '}
            + Add Device{' '}
          </Button>
        </div>
      </div>
      <DeviceList
        deviceData={deviceData}
        shows={shows}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
        setLoading={setLoading}
      />
      <div className='activeshowmodal update-show-modal'>
        <AddorEditModal
          modalOpen={modalOpen}
          cleanupFunction={cleanupFunction}
          formValueState={formValueState}
          setFormValueState={setFormValueState}
          fieldValidationState={fieldValidationState}
          errorState={errorState}
          setErrorState={setErrorState}
          validateDeviceName={validateDeviceName}
          validateMacId={validateMacId}
          renderShowName={renderShowName}
          renderCheckBox={renderCheckBox}
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};
export default ListPPLDevices;
