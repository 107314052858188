import { useContext } from 'react';
import { Form, Select, Typography } from 'antd';
import { EncoderContext } from '../hooks/useEncoderContext';
import useDeviceSettings from '../hooks/useDeviceSettings';

function EncoderStreamSettings() {
  const [form] = Form.useForm();
  const encoderValues = useContext(EncoderContext);
  const {
    videoResolution,
    setVideoResolution,
    colorFormat,
    setColorFormat,
    fps,
    setFps,
    videoBitrate,
    setVideoBitrate,
    videoInput,
    setVideoInput,
    playBtnRef,
    deviceSettings,
  } = encoderValues;

  const { getColourFormat, getFramerate, getVideoResolution, getVideoInput,getVideoBitrate } = useDeviceSettings(deviceSettings);
  const colorFormatOptions = getColourFormat();
  const frameRateOptions = getFramerate();
  const videoResolutionOptions = getVideoResolution();
  const videoInputOptions = getVideoInput();
  const videoBitrateOptions = getVideoBitrate();

  return (
    <Form
      form={form}
      name='validateOnly'
      className='encoder-stream-settings'
      layout='vertical'
      autoComplete='off'
    >
      <Form.Item name='audio_bitrate' label='Audio Bitrate'>
        <Typography.Text className='ant-form-text' type='secondary'>
          128000
        </Typography.Text>
      </Form.Item>
      <Form.Item name='video_bitrate' label='Video Bitrate' rules={[{ required: true }]} initialValue={videoBitrate}>
        <Select
          onChange={setVideoBitrate}
          bordered={false}
          disabled={playBtnRef.current.getAttribute('disabled')}
          options={videoBitrateOptions}
        />
      </Form.Item>
      <Form.Item name='video_input' label='Video Input' rules={[{ required: true }]} initialValue={videoInput}>
        <Select
          onChange={setVideoInput}
          bordered={false}
          disabled={playBtnRef.current.getAttribute('disabled')}
          options={videoInputOptions}
        />
      </Form.Item>
      <Form.Item name='resolution' label='Video Resolution' rules={[{ required: true }]} initialValue={videoResolution}>
        <Select
          onChange={setVideoResolution}
          bordered={false}
          disabled={playBtnRef.current.getAttribute('disabled')}
          options={videoResolutionOptions}
        />
      </Form.Item>
      <Form.Item name='color' label='Color Format' rules={[{ required: true }]} initialValue={colorFormat} >
        <Select
          bordered={false}
          onChange={setColorFormat}
          disabled={playBtnRef.current.getAttribute('disabled')}
          options={colorFormatOptions}
        />
      </Form.Item>
      <Form.Item name='Framerate' label='Framerate' rules={[{ required: true }]} initialValue={fps}>
        <Select
          bordered={false}
          onChange={setFps}
          disabled={playBtnRef.current.getAttribute('disabled')}
          options={frameRateOptions}
        />
      </Form.Item>
    </Form>
  );
}

export default EncoderStreamSettings;
