export const billingInfoJson = [
  {
    icon: "field-icon-name",
    label: "Select a Plan",
    className: "width_50",
    input: {
      type: "dropDown",
      name: "plan",
      options: "planList",
      value: "selectedProduct",
      onChange: "handlePlanOnChange"
    },
  },
  {
    icon: "field-icon-tv",
    label: "Show Code: ",
    input: {
      type: "readOnly",
      name: "show_code",
    },
  },
  {
    icon: "field-icon-name",
    label: "First Name",
    className: "width_50",
    input: {
      type: "text",
      name: "firstName",
    },
  },
  {
    label: "Last Name",
    className: "width_50 billing-field-alignment",
    input: {
      type: "text",
      name: "lastName",
    },
  },
  {
    icon: "field-icon-email",
    label: "E-mail",
    input: {
      type: "text",
      name: "email",
    },
  },
  {
    icon: "field-icon-email",
    label: "Secondary E-mail",
    input: {
      type: "text",
      name: "secondaryEmail",
    },
  },
  {
    icon: "field-icon-note",
    label: "Production Name",
    input: {
      type: "text",
      name: "productionName",
    },
  },
  {
    icon: "field-icon-note",
    label: "Billing Address",
    input: {
      type: "text",
      name: "line1",
    },
  },
  {
    icon: "field-icon-note",
    label: "Billing State",
    className: "width_50",
    input: {
      type: "dropDown",
      name: "state",
      options: "stateList",
      value: "state",
      onChange: "handleStateOnChange"
    },
  },
  {
    label: "Billing City",
    className: "width_50 billing-field-alignment",
    input: {
      type: "text",
      name: "city",
    },
  },
  {
    icon: "field-icon-phone",
    label: "Country Code",
    className: "width_50",
    input: {
      type: "dropDown",
      name: "countryCode",
      options: "countryCodeList",
      value: "countryCode",
      onChange: "handleCountryCodeOnChange"
    },
  },
  {
    label: "Phone",
    className: "width_50 billing-field-alignment",
    input: {
      type: "text",
      name: "phone",
    },
  },
  // {
  //   icon: "field-icon-note",
  //   label: "Billing Zip",
  //   input: {
  //     type: "text",
  //     name: "postal_code",
  //   },
  // },
  {
    icon: "field-icon-note",
    label: "Notes",
    input: {
      type: "text",
      name: "note",
    },
  },
];

export const paymentInfoJson = [
  {
    icon: "field-icon-name",
    label: "Selected Plan: ",
    paymentValid: true,
    input: {
      type: "readOnly",
      name: "customPlanLabel",
    },
  },
  {
    icon: "field-icon-name",
    label: "Select a Plan",
    paymentValid: false,
    input: {
      type: "dropDown",
      name: "plan",
      options: "planList",
      value: "selectedProduct",
      onChange: "handlePlanOnChange"
    },
  },
  {
    icon: "field-icon-name",
    label: "Show Code: ",
    input: {
      type: "readOnly",
      name: "show",
    },
  },
  {
    icon: "field-icon-name",
    label: "Payment Type",
    className: "width_50",
    input: {
      type: "dropDown",
      name: "paymentType",
      options: "paymentTypeList",
      value: "paymentType",
      onChange: "handlePaymentTypeOnChange"
    },
  },
  {
    label: "Offline",
    className: "note_label",
    input: {
      type: "readOnly",
      name: "offline",
    },
  },
  {
    label: "Online",
    className: "note_label",
    input: {
      type: "readOnly",
      name: "online",
    },
  },
];

export const poCodeJson = {
  icon: "field-icon-note",
  label: "PO Number",
  className: "width_50 billing-field-alignment",
  input: {
    type: "text",
    name: "poCode",
  },
};

export const stateList = [
  {"text":"Alabama","value":"AL"},
  {"text":"Alaska","value":"AK"},
  {"text":"Arizona","value":"AZ"},
  {"text":"Arkansas","value":"AR"},
  {"text":"California","value":"CA"},
  {"text":"Colorado","value":"CO"},
  {"text":"Connecticut","value":"CT"},
  {"text":"Delaware","value":"DE"},
  {"text":"District of Columbia","value":"DC"},
  {"text":"Florida","value":"FL"},
  {"text":"Georgia","value":"GA"},
  {"text":"Hawaii","value":"HI"},
  {"text":"Idaho","value":"ID"},
  {"text":"Illinois","value":"IL"},
  {"text":"Indiana","value":"IN"},
  {"text":"Iowa","value":"IA"},
  {"text":"Kansa","value":"KS"},
  {"text":"Kentucky","value":"KY"},
  {"text":"Lousiana","value":"LA"},
  {"text":"Maine","value":"ME"},
  {"text":"Maryland","value":"MD"},
  {"text":"Massachusetts","value":"MA"},
  {"text":"Michigan","value":"MI"},
  {"text":"Minnesota","value":"MN"},
  {"text":"Mississippi","value":"MS"},
  {"text":"Missouri","value":"MO"},
  {"text":"Montana","value":"MT"},
  {"text":"Nebraska","value":"NE"},
  {"text":"Nevada","value":"NV"},
  {"text":"New Hampshire","value":"NH"},
  {"text":"New Jersey","value":"NJ"},
  {"text":"New Mexico","value":"NM"},
  {"text":"New York","value":"NY"},
  {"text":"North Carolina","value":"NC"},
  {"text":"North Dakota","value":"ND"},
  {"text":"Ohio","value":"OH"},
  {"text":"Oklahoma","value":"OK"},
  {"text":"Oregon","value":"OR"},
  {"text":"Pennsylvania","value":"PA"},
  {"text":"Rhode Island","value":"RI"},
  {"text":"South Carolina","value":"SC"},
  {"text":"South Dakota","value":"SD"},
  {"text":"Tennessee","value":"TN"},
  {"text":"Texas","value":"TX"},
  {"text":"Utah","value":"UT"},
  {"text":"Vermont","value":"VT"},
  {"text":"Virginia","value":"VA"},
  {"text":"Washington","value":"WA"},
  {"text":"West Virginia","value":"WV"},
  {"text":"Wisconsin","value":"WI"},
  {"text":"Wyoming","value":"WY"}
  ]