import { Modal } from 'antd';
import { StatusDataType } from './structs';

interface DeviceStatusModalProps {
  statusModalOpen: boolean;
  setStatusModalOpen: (value: boolean) => void;
  deviceStatusData: StatusDataType;
}

const DeviceStatusModal = (props: DeviceStatusModalProps) => {
  const { deviceStatusData } = props;
  return (
    <Modal
      open={props.statusModalOpen}
      onOk={() => {
        props.setStatusModalOpen(false);
      }}
      onCancel={() => {
        props.setStatusModalOpen(false);
      }}
    >
      <h4>Device Status</h4>
      <table>
        <tr>
          <td> audioBitrate</td>
          <td> {deviceStatusData.audioBitrate}</td>
        </tr>

        <tr>
          {' '}
          <td>audioCodec</td>
          <td> {deviceStatusData.audioCodec}</td>
        </tr>
        <tr>
          {' '}
          <td>colorFormat</td>
          <td> {deviceStatusData.colorFormat}</td>
        </tr>
        <tr>
          {' '}
          <td>currentVideoBitrate</td>
          <td> {deviceStatusData.currentVideoBitrate}</td>
        </tr>
        <tr>
          {' '}
          <td>fps</td>
          <td> {deviceStatusData.audioBitrate}</td>
        </tr>
        <tr>
          {' '}
          <td>hdmiStatus</td>
          <td> {deviceStatusData.hdmiStatus}</td>
        </tr>
        <tr>
          {' '}
          <td>sdiStatus</td>
          <td> {deviceStatusData.sdiStatus}</td>
        </tr>
        <tr>
          {' '}
          <td>videoBitrate</td>
          <td> {deviceStatusData.videoBitrate}</td>
        </tr>
        <tr>
          {' '}
          <td>videoCodec</td>
          <td> {deviceStatusData.videoCodec}</td>
        </tr>
        <tr>
          {' '}
          <td>videoInput</td>
          <td> {deviceStatusData.videoInput}</td>
        </tr>
        <tr>
          {' '}
          <td>videoResolution</td>
          <td> {deviceStatusData.audioBitrate}</td>
        </tr>
      </table>
    </Modal>
  );
};
export default DeviceStatusModal;
