import { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../Context/authContext';
import { EncoderContext } from './useEncoderContext';
import backend from '../../../Service/Backend';
import api from '../../../Service/Api';
import { get } from '../../../Utils/helpers';

export default function useEncoder() {
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const encoderValues = useContext(EncoderContext);
  const {
    selectedDevice,
    deviceList,
    setSelectedDevice,
    setPlayLoading,
    setDeviceList,
    setLoading,
    setSettings,
    setSelectedDeviceIndex,
    setRebootLoading,
    setStopLoading,
    setDeviceSettings,
    setVideoResolution,
    setColorFormat,
    setFps,
    setVideoBitrate,
    setVideoInput,
  } = encoderValues;

  const [timers, setTimers] = useState([] as any);

  useEffect(() => {
    return () => {
      // Clear all timers when component unmounts
      timers.forEach((timer: any) => clearInterval(timer));
    };
  }, []);

   // Create a new timer with a specific interval
   const createTimer = (interval: number, type: string) => {
    const newTimer = setTimeout(() => {
      if (type === 'play') {
        setPlayLoading(false);
      } else if (type === 'reboot') {
        setRebootLoading(false);
      } else if (type === 'stop') {
        setStopLoading(false);
      }
    }, interval); 
    setTimers((prevTimers: any) => [...prevTimers, newTimer]);
  };

  const getStatus = async () => {
    try {
      const statusPayload = {
        api: api.encoder.status,
        urlParam: get(deviceList, '0.device_id', ''),
        payLoad: JSON.stringify({ id: get(deviceList, '0.id', '') }),
      };
      const statusResult = await backend.save(
        statusPayload,
        get(authValue, 'accessToken', {}),
      );
      setSettings(statusResult);
    } catch (error: any) {
      console.error('reboot-error', error);
    } finally {
    }
  };

  const getDeviceSettings = async () => {
    try {
      //Get device stream settings from collection device_settings
      const device_settings = await backend.fetch(
        {
          api: api.encoder.getDeviceSettings,
        },
        get(authValue, 'accessToken', {}),
      );
      setDeviceSettings(device_settings);
      return device_settings;
    } catch (error: any) {
      console.error('Error while fetching device settings', error);
    }
  };

  const getDeviceList = async () => {
    try {
      const deviceList = await backend.fetch(
        {
          api: api.encoder.streamDevice,
          urlParam: sessionStorage.getItem('stream_id'),
        },
        get(authValue, 'accessToken', {}),
      );
      setDeviceList(deviceList);
      const device_config = get(get(deviceList, '0', ''), 'device_config', '');
      const default_device_settings = await getDeviceSettings();
      const default_video_bitrate = Object.values(get(default_device_settings,'0.video_bitrate',''))[0]
      const default_video_resolution = Object.values(get(default_device_settings,'0.video_resolution',''))[0]
      const default_colour_format = Object.values(get(default_device_settings,'0.color_format',''))[0]
      const default_input = Object.values(get(default_device_settings,'0.video_input',''))[0]
      const default_fps = Object.values(get(default_device_settings,'0.framerate',''))[0]
      setVideoBitrate(get(device_config, 'video-bitrate', default_video_bitrate));
      setVideoResolution(get(device_config, 'video-resolution', default_video_resolution));
      setColorFormat(get(device_config, 'color-format', default_colour_format));
      setVideoInput(get(device_config, 'input', default_input));
      setFps(get(device_config, 'fps', default_fps));
      
      const getSelectedDevice = deviceList.findIndex(
        (device: any) => device.stream_id === sessionStorage.getItem('stream_id'),
      );
      if (getSelectedDevice >= 0) {
        setSelectedDevice(deviceList[getSelectedDevice]);
      }
    } catch (error: any) {
      console.error('Encoder-error', error);
    } finally {
      setLoading(false);
    }
  };

  const updateSelectedDevice = async (device: any) => {
    const { id } = device;
    try {
      const provisionPayload = {
        api: api.encoder.selectDevice,
        urlParam: id,
        payLoad: JSON.stringify({ stream_id: sessionStorage.getItem('stream_id') }),
      };
      const provisionResult = await backend.save(
        provisionPayload,
        get(authValue, 'accessToken', {}),
      );
      console.log('provisionResult', provisionResult);
      setSelectedDevice(device);
    } catch (error: any) {
      console.error('provision-error', error);
    } finally {
    }
  };

  const pauseEnd = async (type: string) => {
    try {
      setStopLoading(true);
      const stopPayload = {
        api: api.encoder.stop,
        urlParam: get(deviceList, '0.device_id', ''),
        payLoad: JSON.stringify({ type, id: get(deviceList, '0.id', '') }),
      };
      const stopResult = await backend.save(stopPayload, get(authValue, 'accessToken', {}));
      if (type === 'end') {
        setSelectedDevice({});
        setSelectedDeviceIndex('');
      }
      console.log('pauseEnd-Result', stopResult);
    } catch (error: any) {
      console.error('pauseEnd-error', error);
    } finally {
      createTimer(10000, 'stop');
    }
  };

  const reboot = async () => {
    try {
      setRebootLoading(true);
      const rebootPayload = {
        api: api.encoder.reboot,
        urlParam: get(deviceList, '0.device_id', ''),
        payLoad: JSON.stringify({ id: get(deviceList, '0.mac_id', '') }),
      };
      const rebootResult = await backend.save(
        rebootPayload,
        get(authValue, 'accessToken', {}),
      );
      console.log('rebootResult', rebootResult);
    } catch (error: any) {
      console.error('reboot-error', error);
    } finally {
      createTimer(70000, 'reboot');
    }
  };

  const setConfig = async (config: any) => {
    try {
      console.log('config', config);
      setPlayLoading(true);
      const configPayload = {
        api: api.encoder.config,
        urlParam: get(deviceList, '0.device_id', ''),
        payLoad: JSON.stringify(config),
      };
      const configResult = await backend.save(
        configPayload,
        get(authValue, 'accessToken', {}),
      );
      console.log('configResult', configResult);
    } catch (error: any) {
      console.error('config-error', error);
    } finally {
      createTimer(10000, 'play');
    }
  };

  return {
    selectedDevice,
    setSelectedDevice,
    setConfig,
    getDeviceList,
    pauseEnd,
    updateSelectedDevice,
    reboot,
    getStatus,
  };
}
