import { Button, Collapse, Form, Input, Modal, Tooltip, Select } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import InputComponent from "../../../Components/UIKit/InputComponent";
import useToggle from "../../../hooks/useToggle";
// import SettingUserGrp from "../../Images/settings_usergroup.png";
import SettingUserGrp from "../../../Images/settings_usergroup.png";
import { get } from "../../../Utils/helpers";
import type { SelectProps } from 'antd/es/select';

// CSS
import "./style.css"

const { Search } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;
const { Option } = Select

const UserList = (props: any) => {
    const [visible, openSearchBar, closeSearchBar] = useToggle(false)
    const [tabStatus, setTabStatus] = useState(false)
    const [groupUserName, setGroupUserName] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const [searchText, setSearchText] = useState("");

    const showModal = () => {
        setOpenModal(true);
    };
    const onSelect = (value: string) => {
        console.log('onSelect', value);
    };
    const handleSearch = (e: any) => {
        openSearchBar();
        const { value } = e.target;
       let iswhiteSpace =  value.trim().length === 0;
       if(!iswhiteSpace) {
        setSearchText(value);
       } else {setSearchText("")}
    };
    const handleUsers = () => {
        setSearchText('');
        //setShowPublisher(false);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpenModal(false);
    };

    const validateGroupName = (value: any) => {
        setGroupUserName(value);
        console.log(value, '')
        // let validatefName = value.trim();
    };

    const handleOk = async () => {

    };

    const filterData = props.uniqueUsers.filter((user: any) => {
        const firstName: string = get(user, "user_name.user_name_first", "");
        const lastName: string = get(user, "user_name.user_name_last", "");

        const email: string = get(user, "user_email", "");
        let phone: any = get(user, "user_phone", "");
        phone = (typeof phone === "string" ? phone : phone.user_phone) || "";

        let result = props.uniqueUsers;
        
        if (searchText != "") {
            result = `${firstName} ${lastName} ${email} ${phone}`
                .toLowerCase()
                .includes(searchText.toLowerCase())
        }
        return result;
    });

    const uniqueUsersData = !!searchText ? filterData : props.uniqueUsers;

    return (
      //<Collapse.Panel key="users" header={<></>}>
      // <div className="user-cards">
      <div className="GroupTab">
        <div className="searchbar-wrapper">
          <Button
            type="primary"
            className={classNames(
              "add-btn searchbar-btn ",
              !visible ? " " : "searchbar-toggle-btn"
            )}
            onClick={() => props.handleNavCreateUserClick()}
          >
            {/* <PlayCircleFilled style={{ fontSize: "20px" }} /> */}
            Add New User
          </Button>
          <Search
            placeholder="Search"
            allowClear
            className={classNames(
              "left-container-searchbar",
              !visible ? "search-toggle" : ""
            )}
            //onSelect={onSelect}
            onSearch={visible ? closeSearchBar : openSearchBar}
            // @ts-ignore
            onChange={handleSearch}
          />
        </div>
        <Tooltip title="Add New User">
        <Button
          type="primary"
          className={classNames(
            "add-btn-add",
            !visible ? "searchbar-toggle-btn " : " "
          )}
          onClick={closeSearchBar}
        >
          +
        </Button>
        </Tooltip>
        <div className="scrollable-pub-items-container">
        {searchText.length > 0 && uniqueUsersData.length === 0 &&
        <p className="invited-users-search-result-new">No Data Found</p>}
          { 
            uniqueUsersData.map((user: any, index: number) => (
              <div key={index} className="user-card">
                <Tooltip title={user.user_email}>
                  <div className="data">
                    <b>
                      {get(user, "user_name.user_name_first", "") +
                        " " +
                        get(user, "user_name.user_name_last", "")}
                    </b>
                    <div className="user-mail">
                      <span>{user.user_email}</span>
                    </div>
                  </div>
                </Tooltip>
                <div>
                  <img
                    alt="SettingUserGrp"
                    onClick={() => props.showAdminEditUserClick(user)}
                    className="gear-image-icon-group"
                    src={SettingUserGrp}
                  />
                </div>
              </div>
            ))
          }
        </div>
        <Modal
          title="Add New  User"
          open={openModal}
          onOk={handleOk}
          // confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <div className="manage-user-form">
            <Form name="form">
              <Form.Item name="user">
                <InputComponent
                  key={"user"}
                  type="text"
                  value={groupUserName}
                  onChange={validateGroupName}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
      //</div>

      //</Collapse.Panel>
    );
}

export default UserList;