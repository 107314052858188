import React, { useState, useEffect, useContext } from "react";
import { Layout } from "../PageLayout/Layout";
import { PAGES } from "../../Utils/constants";
import CreateStream from "../../Containers/CreateStream";
import MangeUser from "../../Containers/MangeShowViewers";
import ListStreams from "../../Containers/ListOfPublisherStreams/ListStreams";
import ProgressLoader from "../Common/ProgressLoader";
import { checkAccess } from "./DataProvider";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import useNavigateToPage from "../../hooks/useNavigateToPage";
import { useLocationStore } from "../../store/useLocationStore";

const ShowPublisher = () => {

  
  const locationState = useLocationStore((state) => state.locationState);
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const show_id = get(locationState,'state.show_id','');
  const streamIdLocation = get(locationState,'state.stream_id',"");
  const streamLabel = get(locationState,'state.stream_label','');
  const usersGroupsFlag = get(locationState,'state.usersGroupsFlag',false);

  const ShowPublisherDashboard = () => {

    const navigateToPage = useNavigateToPage();

    const [loading, setLoading] = useState(false);
    const [showPublisher, setShowPublisher] = useState(false);
    const [teamAdmin, setTeamAdmin] = useState(false);  

    const [streamupdated, setStreamUpdated] = useState(false);

    const [page, setPage] = useState("mangeUsers");

    useEffect(() => {
      
      const validateAccess = async () => {
        
        setLoading(true);
        const publisherShowUserRole = await checkAccess(show_id, get(currentUser, "user.accessToken", ""));
        setLoading(false);

        if (publisherShowUserRole) {
          setShowPublisher(true);
          if (publisherShowUserRole.team_admin) {
            setTeamAdmin(true);
          } 
        } else {
          navigateToPage("/unauthorised");
        }
      };

      if (show_id) {
        validateAccess();
      } else {
        navigateToPage("/login");
      }

    }, [show_id]); 
     

    const handleNavCreateStreamClick = () => {
      setPage("createStream"); 
    };

    const handleNavMangeUsersClick = () => {
      setPage("mangeUsers"); 
    };

    const handleNavListStreamsClick = () => {
      setPage("streamList");
    }; 

    const renderStreamCreate = () => {
      return (
        <>
          <div className="left-content">
            <div className="create-stream"></div>
          </div>
          <div className="right-content">
            <div className="right-container">
              <CreateStream showId={show_id} setStreamUpdated={setStreamUpdated} teamAdmin={teamAdmin} usersGroupsFlag={usersGroupsFlag}/>
            </div>
          </div>
        </>
      );
    };

    const renderMangeUser = () => { 
        return <MangeUser showId = { show_id } teamAdmin = { teamAdmin } gotoCreateStream = {handleNavCreateStreamClick} usersGroupsFlag={usersGroupsFlag}/> 
    };

    const renderListStreams = () => {
      return (
        <div className={`${usersGroupsFlag ? "right-container-new right-container-users" : "right-container"}`}>
          <ListStreams 
            key={Math.random()} 
            showId={show_id} 
            setStreamUpdated={setStreamUpdated} 
            gotoCreateStream = {handleNavCreateStreamClick}
            teamAdmin={teamAdmin}
            usersGroupsFlag={usersGroupsFlag}
          />
        </div>
      );
    };

    const renderBodyContent = () => {
      switch(page) {
        case "createStream":
          return renderStreamCreate();
        case "mangeUsers":
          return renderMangeUser();
        case "streamList":
          return renderListStreams();
        default:
          return <></>;
      }
    };

    return (
      <Layout
        page={PAGES.SHOW_PUBLISHER_PAGE}
        streamId={streamIdLocation}
        showId={show_id}
        activeClassStream={page === "createStream" ? "active" : ""}
        activeClassUser={page === "mangeUsers" ? "active" : ""}
        activeClassListOfStream={page === "streamList" ? "active" : ""}
        showPublisherCreateStreamClick={handleNavCreateStreamClick}
        showPublisherMangeUsersClick={handleNavMangeUsersClick}
        showPublisherListStreamsClick={handleNavListStreamsClick}
        stream_label={streamLabel}
        usersGroupsFlag={usersGroupsFlag}
      >
        <ProgressLoader loading={loading} />
        {showPublisher ? renderBodyContent() : <div> </div>}
      </Layout>
    );
  };

  return <ShowPublisherDashboard />;
};

export default ShowPublisher;