import microphoneOn from "../../../Images/micOn.svg";
import microphoneOff from "../../../Images/micOff.svg";
import { useAudio } from "../hooks/useAudio";
import { useContext } from "react";
import ZoomContext from '../context/zoom-context';
import ZoomMediaContext from '../context/media-context';
import { useWhiteboardingStore } from "../../../store";
import { Grid } from "antd";
import { get } from "../../../Utils/helpers";


const AudioToggle = (props: any) => {
    const { showDominantSpeaker, resetDrawingButtonState } = props;
    const zmClient = useContext(ZoomContext);
    const { mediaStream } = useContext(ZoomMediaContext);
    const { resetAllTools } = useWhiteboardingStore();
    const { isAudioOn, onMicClick } = useAudio(zmClient, mediaStream)
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    return (<div className={get(screens, 'xs', false) ? 'miniheader-btn' :`top-menu-button-item ${showDominantSpeaker ? "disableddiv" : ""}`} onClick={() => {
        onMicClick();
        resetDrawingButtonState();
        resetAllTools();
    }}>
        {isAudioOn ? (
            <>
                <img src={microphoneOn} alt="" />
            </>
        ) : (
            <>
                <img
                    src={microphoneOff}
                    alt=""
                    style={{ filter: "brightness(1)" }}
                />
            </>
        )}
       {!get(screens, 'xs', false) && <span>Toggle Mic</span>}
    </div>)
};

export default AudioToggle;
