import React, { useState, useEffect, useContext, Fragment, useRef } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { PAGES } from "../../Utils/constants";
import { Layout } from "../PageLayout/Layout";
import { auth } from "../../Firebase";
import ProgressLoader from "../Common/ProgressLoader";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { Mixpanel } from "../../Service/Mixpanel";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import useNavigateToPage from "../../hooks/useNavigateToPage";
import { useLocationStore } from "../../store/useLocationStore";

const ResetPassword = () => {
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const navigationType = useNavigationType();

  const green_icon = "green check circle outline small icon";
  const red_icon = "red exclamation circle small icon";
  const [errorMessage, setErrorMessage] = useState("");
  const [succeessMessage, setSuccessMessage] = useState("");
  const [user_password, setPassword] = useState("");
  const [pwd_match, setMatchPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [classApply, setClass] = useState("error-message");
  const [classApply1, setClass1] = useState("error-message");
  const [classApply2, setClass2] = useState("error-message");
  const [classApply3, setClass3] = useState("error-message");
  const [classApply4, setClass4] = useState("error-message");
  const [iconName, setIconName] = useState(red_icon);
  const [iconName1, setIconName1] = useState(red_icon);
  const [iconName2, setIconName2] = useState(red_icon);
  const [iconName3, setIconName3] = useState(red_icon);
  const [iconName4, setIconName4] = useState(red_icon);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [validationStatus, setValidation] = useState(false);

  const [loading, setLoading] = useState(false);
  const [passwordresetmessage, setPasswordResetMessage] = useState("");

  const [validateLoading, setValidateLoading] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [validOTP, setValidOTP] = useState(false);

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [otpmessage, setOtpMessage] = useState("");

  const [code, setCode] = useState("");
  const [otpValid, setOtpValid] = useState(true);
  const [errorOtp, setErrorOtp] = useState("");

  const [resendDisable, setResendDisable] = useState(false);
  const [twoFaType, setTwoFaType] = useState("SMS");
  const [googleAuthSecret, setGoogleAuthSecret] = useState("");
  //const [authyApprovalId, setAuthyApprovalId] = useState("");
  const [uid, setUid] = useState("");
  const [userDBData, setUserDBData] = useState(null as any);
  const [passwordEyeIcon, setPasswordEyeIcon] = useState("eye slash") as any;
  const [confirmPasswordEyeIcon, setConfirmPasswordEyeIcon] = useState("eye slash") as any
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const requestOtpRef = useRef(false);

  const location: any = useLocation();
  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  const userId =  get(locationState,'state.userId',"");
  const isLoading =  get(locationState,'loading',false);
  const trustedDeviceFeature = get(locationState,'state.trustedDeviceFeature',false);

  useEffect(() => {
    // Mixpanel
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.RESET_PASSWORD_PAGE,
    });
  }, []);

  useEffect(() => {
    return () => {
      const signout = async () => {
        await auth.doSignOut();

        // Mixpanel
        Mixpanel.track("Logout", {
          Platform: "Web",
        });
      };
      if (navigationType === "POP" && location && location.pathname === "/login") {
        signout();
      }
    };
  }, [navigationType, location.pathname]);

  useEffect(() => {
    const user: any = auth.getUser();

    if (user) {
      const { uid, displayName } = user;
      setName(displayName);
      setUid(uid); 
    } else {
      navigateToPage("/", { replace: true});
    }

    const requestOtp = async () => {
      try {
        setSuccessMessage("");
        setErrorMessage("");
        if(isLoading || requestOtpRef.current){
          return
        }
        const userDataValue = await getUserData();
        const { two_fa_type, google_auth_secret, twilio_authy_id } = userDataValue;
        setUserDBData(userDataValue);
        setTwoFaType(two_fa_type);
        if ((!!two_fa_type && two_fa_type === 'Google Auth' && google_auth_secret)) {
          setResendDisable(true);
          setGoogleAuthSecret(google_auth_secret); 
          setOtpMessage('Enter the otp from google authenticator app to verify');
        } else if (!!two_fa_type && two_fa_type === 'Authy' && twilio_authy_id) {
          setResendDisable(true);
          setOtpMessage('Enter the otp from authy app to verify');
        } else {
          const action = "reset password";
          const data = {
            api: api.otp.requestOtp,
            queryParam: { action } 
          };
          setLoading(true);
          let response = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
          console.log(response);
          if (response && response.message) {
            setOtpMessage(response.message);
            setMobile(response.phoneNumber);
          }
        }
        requestOtpRef.current = true
      } catch (err: any) {
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (!userId) {
      if(!isLoading){
        navigateToPage("/login");
      }   
    } else if (get(locationState,'state.from','') === '/otp') {
      setValidOTP(true);
    } else {
      requestOtp();
    }

  }, [location.pathname,locationState]);

  const validOtp = (event: any) => {
    const { value }  = event.target;
    const validateOtp = value.replace(/[^0-9]/,'');
    setCode(validateOtp);
    if (!validateOtp) {
      setOtpValid(false);
      //setErrorOtp('Please enter Otp');
    } else {
      setOtpValid(true);
      setErrorOtp('')
    }
  };
  const getUserData = async () => {
    try {
      const data = {
        api: api.users.get
      };
      setLoading(true);
      const userdata = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

      if (!userdata) {
        // Redirect to login page
        console.error('No User found')
        await auth.doSignOut();
        
        navigateToPage("/", { replace: true});
      }

      return userdata
    } catch (err: any) {
      console.log("Couldn't get Profile data")
    } finally {
      setLoading(false)
    }
  };
  const validateOtp = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage("");
      setErrorMessage("");

      let input: any = { otp: code, action: "reset password" };
      let data = {
        api: api.otp.validateOtp,
        payLoad: JSON.stringify(input)
      };

      if ((twoFaType && twoFaType === 'Google Auth')) {
        input = { userId: uid, token: code, action: "reset password", authSecret: googleAuthSecret };
        data = {
          api: api.otp.validateGoogleAuthOtp,
          payLoad: JSON.stringify(input),
        };
      }

      setValidateLoading(true);

      if (twoFaType && twoFaType === 'Authy' && userDBData.twilio_authy_id) {
        input = { userId: uid, authy_id: userDBData.twilio_authy_id, token: code, action: "reset password" };
        data = {
          api: api.otp.validateAuthyOtp,
          payLoad: JSON.stringify(input),
        };
      }

      var result = await backend.save(data, get(currentUser, "user.accessToken", ""));

      // Navigate
      // navigate();
      // Show reset password fields here
      if (result) {
        setValidOTP(true);
      }
    } catch (err: any) {
      const msg = "OTP Validation failed";
      if (err.errCode === 40104) {
        setErrorMessage(err.errMessage);
        setButtonsDisabled(true);
        setTimeout(async () => {
          await auth.doSignOut();
          navigateToPage("/", { replace: true });
        }, 3000);
      } else {
        if (err.errMessage && err.errMessage !== "Request failed with status code 401") {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      }
    }
    finally {
      setValidateLoading(false);
    }
  };

  const resendCode = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();
 
      const action = "reset password" ;
      const data = {
        api: api.otp.requestOtp,
        queryParam: { action }
      };

      setSuccessMessage("");
      setErrorMessage("");

      setResendOTPLoading(true);
      let response = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
      console.log(response);
       if (response && response.message) {
        setOtpMessage(response.message);
        setMobile(response.phoneNumber);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
    } finally {
      setResendOTPLoading(false);
    }
  };

  const cancelPage = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage("");
      setErrorMessage("");

      setCancelLoading(true);
      navigateToPage(get(locationState,'state.from',-1),locationState); 
    } catch (err: any) {
      setErrorMessage(err.message);
    } finally {
      setCancelLoading(false);
    }
  }

  const handlePassword = (event: any) => {
    setPassword(event.target.value);
    let pwd = event.target.value;

    // character length >=12 validation
    if (pwd.length >= 12) {
      setClass("success-message");
      setIconName(green_icon);
    } else {
      setClass("error-message");
      setIconName(red_icon);
    }
    // uppercase validation
    if (/([A-Z])/g.test(event.target.value)) {
      setClass1("success-message1");
      setIconName1(green_icon);
    } else {
      setClass1("error-message1");
      setIconName1(red_icon);
    }

    //lowercase validation
    if (/([a-z])/g.test(event.target.value)) {
      setClass2("success-message2");
      setIconName2(green_icon);
    } else {
      setClass2("error-message2");
      setIconName2(red_icon);
    }

    //number validation
    if (/([0-9])/g.test(event.target.value)) {
      setClass3("success-message3");
      setIconName3(green_icon);
    } else {
      setClass3("error-message3");
      setIconName3(red_icon);
    }

    if (/[!"#$%&'()*+,-./:;<=>?_@]/g.test(event.target.value)) {
      setClass4("success-message4");
      setIconName4(green_icon);
    } else {
      setClass4("error-message4");
      setIconName4(red_icon);
    }

    if (
      pwd.length >= 12 &&
      /([A-Z])/g.test(event.target.value) &&
      /([a-z])/g.test(event.target.value) &&
      /([0-9])/g.test(event.target.value) &&
      /[!"#$%&'()*+,-./:;<=>?_@]/g.test(event.target.value)
    ) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const handlePasswordMatch = (event: any) => {
    setMatchPassword(event.target.value);

    if (user_password === event.target.value) {
      setErrorMessage("");
      if (validationStatus === true) {
        setSuccessMessage("Password Matched");
        setButtonDisable(false);
      }
    } else {
      setSuccessMessage("");
      setErrorMessage("Password not matched!");
      setButtonDisable(true);
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setPasswordEyeIcon(passwordShown?"eye slash":"eye")
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
    setConfirmPasswordEyeIcon(confirmPasswordShown?"eye slash":"eye");
  };
 
  const changePassword = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setLoading(true);

      const data = {
        api: api.users.resetpassword,
        payLoad: JSON.stringify({ password: user_password }),
      };
      const response = await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);
      console.log(response);

      const user: any = auth.getUser();
      
      
      // getting new token with resetted pwd
      await auth.doSignInWithEmailAndPassword(
        user.email,
        user_password
      );
      const uid = get(user, "uid", null);
      requestOtpRef.current = true;
      navigateToPage("/showselection" , { state: {userId: uid}, replace: true  });
      if(trustedDeviceFeature) {
      const device = {
        api: api.trustedDevice.deactivateAllDevices,
        payLoad:{}
      }
      const devices = await backend.remove(device, get(currentUser, "user.accessToken", ""));
      console.log("devices", devices);
    }
    } catch (err: any) {
      const msg = "Not able to change password. Please talk to admin";
      setPasswordResetMessage(msg);
      console.error(err);
    }
  };

  return (
    <Layout page={PAGES.RESET_PASSWORD_PAGE}>
      <ProgressLoader loading={loading || isLoading} />
      <div className="left-content">
        <div className="twofa_image"></div>
      </div>

      <div className="right-content" style={{ paddingLeft: "25px" }}>
        <p className="profile_heading">Password Reset</p>
        <hr className="top" />

        <div className="form-content margin-left-0 padding-bottom-140">
          {!validOTP && <form className="otp ui form">
            {" "}
            {/* Semantic UI form */}
            <div className="form-field">
              <p className="lable-text">
                {otpmessage}
                <span className="purple_text"> {mobile}</span>
              </p>
              <input
                className={(otpValid === false) ? "form-input error-border" : "form-input"}
                type="text"
                value={code}
                onChange={validOtp}
              />
              {(otpValid === false) && (
                <span className="error-message">{errorOtp}</span>
              )}
            </div>
            <div className="auth-button flex">
              <Button
                className="auth-violet-button auth-centered-buttons-3"
                color="violet"
                loading={validateLoading}
                onClick={validateOtp}
                disabled={buttonsDisabled || (otpValid === false || code === '')}
              >
                Verify Code
                </Button>
              <Button
                className="auth-violet-button auth-centered-buttons-3"
                color="violet"
                loading={resendOTPLoading}
                onClick={resendCode}
                disabled={buttonsDisabled || resendDisable}
              >
                Resend Code
                </Button>
              <Button
                className="auth-violet-button auth-centered-buttons-3"
                color="violet"
                loading={cancelLoading}
                onClick={cancelPage}
                disabled={buttonsDisabled}
              >
                Cancel
                </Button>
            </div>
            {errorMessage && (
              <Fragment>
                <div className="flex">
                  <span className="alert-icon" ></span>
                  <span className="error-message alert-text-message">{errorMessage}</span>
                </div>
              </Fragment>

            )}
          </form>}
          {validOTP && <form className="password ui form">
            <div style={{ marginBottom: "25px", marginLeft: "37px" }}>
              <label className="form-input-label">Password Reset</label>
              <div>
                <div>
                  <i aria-hidden="true" className={iconName}></i>
                  <span className={classApply}>
                    MUST contain at least 12 characters
                  </span>
                </div>
                <div>
                  <i aria-hidden="true" className={iconName1}></i>
                  <span className={classApply1}>
                    MUST contain at least one uppercase letter
                  </span>
                </div>
                <div>
                  <i aria-hidden="true" className={iconName2}></i>
                  <span className={classApply2}>
                    MUST contain at least one lowercase letter
                  </span>
                </div>
                <div>
                  <i aria-hidden="true" className={iconName3}></i>
                  <span className={classApply3}>
                    MUST contain at least one number
                  </span>
                </div>
                <div>
                  <i aria-hidden="true" className={iconName4}></i>
                  <span className={classApply4}>
                    MUST contain at least one special character
                    {/* (!"#$%&'()*+,-./::<=>?) */}
                  </span>
                </div>
              </div>
            </div>
            <div className="field-and-icon">
              <div
                className="field-icon-lock left-icon"
                style={{ marginBottom: "20px" }}
              ></div>
              <div className="form-field icon-input-field">
                <input
                  className="form-input"
                  placeholder="Password"
                  type={passwordShown ? "text" : "password"}
                  value={user_password}
                  onChange={handlePassword}
                />
                <Icon
                  name={passwordEyeIcon}
                  size="large"
                  className="eye-icon-pwd"
                  onClick={togglePasswordVisiblity}
                />
              </div>
            </div>

            <div className="field-and-icon">
              <div className="field-icon-lock left-icon"></div>
              <div className="form-field icon-input-field">
                <label className="form-input-label">Confirm Password</label>
                <input
                  className="form-input"
                  placeholder="Password"
                  type={confirmPasswordShown ? "text" : "password"}
                  value={pwd_match}
                  onChange={handlePasswordMatch}
                />
                <Icon
                  name={confirmPasswordEyeIcon}
                  size="large"
                  className="eye-icon-pwd"
                  onClick={toggleConfirmPasswordVisiblity}
                />
                <div>
                  <div className="error-message">{errorMessage}</div>
                  <div className="success-message">{succeessMessage}</div>
                </div>
              </div>
            </div>
          </form>}
          {validOTP && <div className="auth-button flex">
            <Button
              className="auth-violet-button auth-centered-buttons-2"
              color="violet"
              disabled={buttonDisable}
              onClick={changePassword}
            >
              Change Password
            </Button>
            <Button
              className="auth-violet-button auth-centered-buttons-2"
              color="violet"
              loading={cancelLoading}
              onClick={cancelPage}
            >
              Cancel
            </Button>
            {passwordresetmessage && (
              <Fragment>
                <span className="alert-icon"></span>
                <span className="error-message">{passwordresetmessage}</span>
              </Fragment>
            )}
          </div>}
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
