import ColorHash from 'color-hash';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { get } from '../../../Utils/helpers';

const useWhiteBoarding = (dataTrack: any) => {

  //state
  const [isEraser, _setMyEraser] = useState(false);
  const myEraserRef = useRef(isEraser);
  const setMyEraser = (data: boolean) => {
    myEraserRef.current = data;
    _setMyEraser(data);
  };

  const [isPencilCircle, _setMyPencilCircle] = useState(false);
  const myPencilCircleRef = useRef(isPencilCircle);
  const setMyPencilCircle = (data: boolean) => {
    myPencilCircleRef.current = data;
    _setMyPencilCircle(data);
  };

  const [isPencilLine, _setMyPencilLine] = useState(false);
  const myPencilLineRef = useRef(isPencilLine);
  const setMyPencilLine = (data: boolean) => {
    myPencilLineRef.current = data;
    _setMyPencilLine(data);
  };

  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(undefined);
  const [showDrawingOption, setDrawingOption] = useState(false);

  // declarations
  type Coordinate = {
    x: number;
    y: number;
  };

  let colorHash = new ColorHash();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
// Added resetAllTools method
const resetAllTools = () => {
  setMyEraser(false);
  setMyPencilLine(false);
  setMyPencilCircle(false);

  // Reset refs
  myEraserRef.current = false;
  myPencilLineRef.current = false;
  myPencilCircleRef.current = false;
};
  /*
   * White boarding Methods
   */


  /* Private */

  const startPaint = (coordinates: Coordinate) => {
    if (coordinates) {
      setMousePosition(coordinates);
      setIsPainting(true);

      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;

      if (myPencilCircleRef.current || myPencilLineRef.current) {
        canvas.className = 'canvas-pencil-on';
      }

      if (myEraserRef.current) {
        canvas.className = 'canvas-eraser-on';
      }
    }
  };

  const startPaintMouse = useCallback((event: MouseEvent) => {
    const coordinates = getCoordinates(event);
    if (coordinates) {
      startPaint(coordinates);
    }
  }, []);

  const startPaintTouch = useCallback((event: TouchEvent) => {
    const coordinates = getTouchCoordinates(event);
    if (coordinates) {
      startPaint(coordinates);
    }
  }, []);

  const paint = (
    newMousePosition: Coordinate,
    isPainting: boolean,
    mousePosition: Coordinate | undefined,
    isCircle: boolean,
  ) => {
    let erase: Boolean = false;

    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    if (isPainting && (myPencilCircleRef.current || myPencilLineRef.current)) {
      if (mousePosition && newMousePosition) {
        const color = colorHash.hex(dataTrack.id);
        if (isCircle) {
          drawCircle(color, mousePosition, newMousePosition);
        } else {
          drawLine(color, mousePosition, newMousePosition, false);
        }
        canvas.className = 'canvas-pencil-on';

        const icanvas: any = document.getElementById('canvas');
        const canvasWidth = get(icanvas, 'width', 0);
        const canvasHeight = get(icanvas, 'height', 0);
        const incoming_canvas = {
          width: canvasWidth,
          height: canvasHeight,
        };

        dataTrack.send(
          JSON.stringify({
            isPainting,
            color,
            mousePosition,
            newMousePosition,
            incoming_canvas,
            erase,
            isCircle,
          }),
        );
        setMousePosition(newMousePosition);
      }
    } else if (isPainting && myEraserRef.current) {
      erase = true;
      if (mousePosition && newMousePosition) {
        const color = colorHash.hex(dataTrack.id);
        drawLine(color, mousePosition, newMousePosition, true);
        canvas.className = 'canvas-eraser-on';
        const icanvas: any = document.getElementById('canvas');
        const incoming_canvas = {
          width: icanvas.width,
          height: icanvas.height,
        };
        dataTrack.send(
          JSON.stringify({
            isPainting,
            color,
            mousePosition,
            newMousePosition,
            incoming_canvas,
            erase,
            isCircle,
          }),
        );
        setMousePosition(newMousePosition);
      }
    }
  };
  
  const paintMouse = useCallback(
    (event: MouseEvent) => {
      if (isPencilLine || isEraser) {
        const newMousePosition = getCoordinates(event);
        if (newMousePosition) {
          paint(newMousePosition, isPainting, mousePosition, false);
        }
      }
    },
    [isPainting, mousePosition],
  );

  const paintTouch = useCallback(
    (event: TouchEvent) => {
      const newMousePosition = getTouchCoordinates(event);
      if (newMousePosition) {
        paint(newMousePosition, isPainting, mousePosition, false);
      }
    },
    [isPainting, mousePosition],
  );

  const exitPaint = useCallback((event: TouchEvent) => {
    setIsPainting(false);
    setMousePosition(undefined);
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.className = 'canvas-cursor-auto';
  }, []);

  const mouseExitPaint = useCallback(
    (event: MouseEvent) => {
      if (isPencilCircle && event.type === 'mouseup') {
        const newMousePosition = getCoordinates(event);
        if (newMousePosition) {
          paint(newMousePosition, isPainting, mousePosition, true);
        }
      }

      if (isPencilLine) {
        setIsPainting(false);
        setMousePosition(undefined);
      }

      if (!canvasRef.current) {
        return;
      }

      const canvas: HTMLCanvasElement = canvasRef.current;
      canvas.className = 'canvas-cursor-auto';
    },
    [isPainting, mousePosition],
  );

  const preventDragging = useCallback((e: TouchEvent) => {
    e.preventDefault();
  }, []);


  const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const BB = canvas?.getBoundingClientRect();

    return {
      x: event.clientX - BB.left,
      y: event.clientY - BB.top,
    };
  };

  // Get the position of a touch relative to the canvas
  const getTouchCoordinates = (touchEvent: TouchEvent): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const BB = canvas?.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - BB.left,
      y: touchEvent.touches[0].clientY - BB.top,
    };
  };

  // UseEffect
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    canvas.addEventListener('mousedown', startPaintMouse);
    canvas.addEventListener('mousemove', paintMouse);
    canvas.addEventListener('mouseup', mouseExitPaint);
    canvas.addEventListener('mouseleave', mouseExitPaint);

    canvas.addEventListener('touchstart', startPaintTouch);
    canvas.addEventListener('touchmove', paintTouch);
    canvas.addEventListener('touchend', exitPaint);

    window.addEventListener('touchmove', preventDragging, { passive: false });

    return () => {
      canvas.removeEventListener('mousedown', startPaintMouse);
      canvas.removeEventListener('mousemove', paintMouse);
      canvas.removeEventListener('mouseup', mouseExitPaint);
      canvas.removeEventListener('mouseleave', mouseExitPaint);

      canvas.removeEventListener('touchstart', startPaintTouch);
      canvas.removeEventListener('touchmove', paintTouch);
      canvas.removeEventListener('touchend', exitPaint);

      window.removeEventListener('touchmove', preventDragging);
    };
  }, [startPaint, paint, exitPaint]);


  /* Public */

  const drawLine = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate,
    erase: Boolean,
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext('2d');
    if (context) {
      context.lineJoin = context.lineCap = 'round';

      context.beginPath();

      if (!erase) {
        context.globalCompositeOperation = 'source-over';
        context.strokeStyle = color;
        context.lineWidth = 5;
      } else {
        context.globalCompositeOperation = 'destination-out';
        context.lineWidth = 20;
      }

      context.moveTo(originalMousePosition.x, originalMousePosition.y);
      context.lineTo(newMousePosition.x, newMousePosition.y);
      context.closePath();

      context.stroke();
    }
  };

  const drawCircle = (
    color: any,
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate,
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    const context = canvas.getContext('2d');
    if (context) {
      context.lineJoin = context.lineCap = 'round';

      context.beginPath();
      context.globalCompositeOperation = 'source-over';
      context.strokeStyle = color;
      context.lineWidth = 3;

      // calculating the midX and midY
      var midY = originalMousePosition.y + (newMousePosition.y - originalMousePosition.y) * 0.5;
      var midX = originalMousePosition.x + (newMousePosition.x - originalMousePosition.x) * 0.5;
      var radius =
        Math.hypot(
          newMousePosition.x - originalMousePosition.x,
          newMousePosition.y - originalMousePosition.y,
        ) / 2;

      context.arc(midX, midY, radius, 0, 2 * Math.PI);

      context.stroke();
    }
  };

  const onDrawingClick = () => {
    setDrawingOption(!showDrawingOption);
  };

  // pencil toggle function
  const pencilToggled = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(!isPencilLine);
    setDrawingOption(!showDrawingOption);
  };

  //circle toggled function
  const circleToggled = async () => {
    setMyEraser(false);
    setMyPencilCircle(!isPencilCircle);
    setMyPencilLine(false);
    setDrawingOption(!showDrawingOption);
  };

  // eraser toggle function
  const eraserToggled = async () => {
    setMyEraser(!isEraser);
    setMyPencilCircle(false);
    setMyPencilLine(false);
  };

  // Clear WhiteBoard for host
  const clearWhiteBoard = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(false);
    const canvas: any = document.getElementById('canvas');
    if (canvas) {
      let context = canvas.getContext('2d');
      const canvasWidth = get(canvas, 'width', 0);
      const canvasHeight = get(canvas, 'height', 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }
  };

  // Clear WhiteBoard for all
  const clearWhiteBoardAll = async () => {
    setMyEraser(false);
    setMyPencilCircle(false);
    setMyPencilLine(false);
    const canvas: any = document.getElementById('canvas');
    if (canvas) {
      let context = canvas.getContext('2d');
      const canvasWidth = get(canvas, 'width', 0);
      const canvasHeight = get(canvas, 'height', 0);
      context.clearRect(0, 0, canvasWidth, canvasHeight);
    }

    // also send the clear function to others in the same twilio room
    const clear = true;
    dataTrack.send(
      JSON.stringify({
        clear,
      }),
    );
  };

  return {
    canvasRef,
    showDrawingOption,
    isPencilLine,
    isPencilCircle,
    isEraser,
    drawLine,
    drawCircle,
    onDrawingClick,
    pencilToggled,
    circleToggled,
    eraserToggled,
    clearWhiteBoard,
    clearWhiteBoardAll,
    resetAllTools,
  }
};
export default useWhiteBoarding