import * as Sentry from "@sentry/react";
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
  useReducer,
  useContext
} from "react";
import {
  Card,
  Icon,
  Input,
  Button,
  Dropdown,
  Popup,
  Grid,
  Modal,
  TextArea,
  Tab
} from "semantic-ui-react";

import axios from "axios";
import PubNub from "pubnub";
import { Parser } from "json2csv";
import Picker from '@emoji-mart/react'
import ConfirmationalPopup from "../../../Utils/ConfirmationalPopup";

import { get, tick } from "../../../Utils/helpers";
import ProgressLoader from "../../Common/ProgressLoader";
import { auth } from "../../../Firebase";
import { PAGES, FEATURE_NAME } from "../../../Utils/constants";
import { isFeatureIncluded } from "../../../Utils/featureFlags";
import { isViewInScreen, convertDateTimeFromUTCEpoc } from "../../../Utils/utils";
import { isGuestAuthenticated } from '../../../Utils/guestHelpers';
import { useChatStore } from '../../../store';
import ClearChatModal from "./ConfirmationModal/ClearChat";
import ExportChatModal from "./ConfirmationModal/ExportChat";
import greeTick from "../Images/green-tick.png";
import crossTick from "../Images/red-cancel.png";
import emoji from "../Images/emoji.svg";
import sendMsgIcon from "../Images/send-chat.svg"
import zoomContext from '../context/zoom-context';
import clear from "../Images/clear.png";
import downloadButtonIcon from "../Images/download-button-icon.svg"
import downloadIcon from "../Images/download-icon-white.png"
import pacpost_img from '../Images/logo_PP_white_header.png';
import { Participant } from "@zoom/videosdk";
import { useLocationStore } from "../../../store/useLocationStore";
import { useMessageStore } from "../../../store/slices/viewer/chatStore";

//import { Participant } from '../types/index-types';

const ShowChat = (props: any) => {
  const {
    user_imageurl,
    clearChatFlag,
    setclearChatFlag,
    vc_id
  } = props
  const zmClient = useContext(zoomContext);
  const locationState = useLocationStore((state) => state.locationState);
  const show_id = locationState.state ? locationState.state.show_id : "";
  const [chatExportLoading, setChatExportLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  
  const [troom, setTRoom] = useState({} as any);
  const troomRef = useRef<any>();
  troomRef.current = troom;

 
  //Pubnub
  const [pubnub, setPubNub] = useState({} as any);
  const [channels, setChannels] = useState([] as any[]);
  let [allMessages, setAllMessages] = useState([] as any[]);
  const [input, setInput] = useState("");
  const [typingInd, setTypingInd] = useState("");
  const [editInput, setEditInput] = useState("");
  const [editMessage, setEditMessage] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState("");

  const [showChat, setChatWindow] = useState(true);

  // let photoURL: any
  // if (auth && auth.getUser() && auth.getUser().photoURL) {
  //   //photoURL = auth.getUser().photoURL
  // }
  const [chatClearLoading, setChatClearLoading] = useState(false);

  //Uploadfile
  const [uploadFiles, setUploadFiles] = useState([] as any);
  const [fileName, setFileName] = useState("");
  //emoji
  const [showEmoji, setShowEmoji] = useState(false);
  //const [messages, _setMessages] = useState([] as any[]);
 

  const [dataTrack, _setDataTrack] = useState<any>();
  const dataTrackRef = useRef(dataTrack);
  const setDataTrack = (data: any) => {
    dataTrackRef.current = data;
    _setDataTrack(data);
  };

  const [annotateMessages, _setAnnotateMessages] = useState([] as any[]);
  const annotateMessagesRef = useRef(annotateMessages);
  const setAnnotateMessages = (data: any[]) => {
    annotateMessagesRef.current = data;
    _setAnnotateMessages(data)
  }
  const sendTextMessageInput = useRef<HTMLInputElement>(null);
  const isGuest = isGuestAuthenticated();

  let user = auth.getUser();
  let uid = get(user, "uid", "");
  let displayName = get(user, "displayName", "");

  if(isGuest){
     uid = get(locationState, 'state.guestUid', '')
     displayName = get(locationState, 'state.guestDisplayName', '')
  }
  const { exportChartConfirmationOpen, setExportChartConfirmationOpen,setClearChatModalOpen, setUploadAlert,uploadAlert} = useChatStore();
  const { streamId,setStreamId} = useMessageStore();
  const [messages,_setMessages] = useState<any>([]);
  const stream_id = sessionStorage.getItem("stream_id") || '';
  const stream_label: any = sessionStorage.getItem("stream_label");
  // const [messages, _setMessages] = useState([] as any[]);
  const messagesRef = useRef(messages);
  const setMessages = (data: any[]) => {
    messagesRef.current = data;
    _setMessages(data);
  };
 
  /*
   * PubNub
   */

  const vcParticipants = async (participant: any, status: any) => {
    /*const user = auth.getUser();
    const uid = get(user, "uid", "");*/
    let identity = participant.userIdentity.split("_");
    const participantdisplayName = identity[1];
    const currTime = new Date().getTime();

    console.log(`${participantdisplayName} has ${status} the room`,'status')

    // Update local state with new msg
    const new_msg: any = {
      message_id: uid + String(currTime),
      userDisplay: "Pacpost.io",
      description: `${participantdisplayName} has ${status} the room`,
      userid: uid,
      imageurl: pacpost_img,
      status: status,
      timetoken: currTime,
    };

    console.log(new_msg,'new_msg')

    const msgs = [...messagesRef.current];

    msgs.push(new_msg);
    setMessages(msgs);
  };
   const callbackAdded = useCallback(
   
     (updatedParticipants?: Participant[]) => {
      console.log('call two')
       vcParticipants(get(updatedParticipants, "0", ""), "joined");
     },
     []
   );
   const callbackRemoved = useCallback(
     (updatedParticipants?: Participant[]) => {

       vcParticipants(get(updatedParticipants, "0", ""), "left");
     },
     []
   );
   useEffect(() => {
    console.log('call one')
    zmClient.on('user-added', callbackAdded);
    zmClient.on('user-removed', callbackRemoved);

    return () => {
      zmClient.off('user-added', callbackAdded);
      zmClient.off('user-removed', callbackRemoved);

    };
  }, [zmClient]);
  
   const chatContentRef = useRef<HTMLDivElement>(null);
  const scrollChatToBottom = () => {
    chatContentRef.current?.scrollTo(0, 100000);
  }

  useEffect(()=>{
    scrollChatToBottom()
  },[messagesRef?.current?.length])


// Group Chat
  const sendMessage = useCallback(
    async (message: any,  action?:any) => {
      if (uploadFiles.length > 0) {
        setFileUploadLoading(true);
       
        const currTime = new Date().getTime();
        const result = await pubnub.sendFile({
          channel: channels[0],
          file: uploadFiles[0],
          message: {
            userDisplay: displayName,
            imageurl: user_imageurl,
            message_id: uid + currTime,
            description: "",
            userid: uid, // Needed for old msgs as they dont have publisher id
            publisher: uid,
            
          },
        })

      } else if (message.length > 0) {
        const currTime = new Date().getTime();
        await pubnub.publish({
          channel: channels[0],
          message: {
            message_id: uid + currTime,
            userDisplay: displayName,
            description: message,
            userid: uid, // Needed for old msgs as they dont have publisher id
            imageurl: user_imageurl,
            usecase:action
          },
        });

        await pubnub.signal({
          channel: channels[0],
          message: `typing_off:${displayName}`,
        });
      }

      setUploadFiles([]);
      setInput("");
      setShowEmoji(false);
    },
    [pubnub, channels, uploadFiles, user_imageurl]
  );

  const updateMessage = useCallback(
    async (input, message, messageIndex) => {
      if (input.length > 0) {
        let hideEdit = document.getElementById(
          "edit_button_" + message.timetoken
        );
        hideEdit?.removeAttribute("style");
        await pubnub.publish({
          channel: channels[0],
          message: {
            message_id: message.message_id,
            userDisplay: displayName,
            description: input,
            userid: uid, // Needed for old msgs as they dont have publisher id
            imageurl: user_imageurl,
            usecase: "update",
            deleted: false,
            is_update: true,
          },
        });

        await pubnub.signal({
          channel: channels[0],
          message: `typing_off:${displayName}`,
        });
      }

      setEditInput("");
      renderMessage(input, messageIndex);
    },
    [pubnub, channels, setEditInput]
  );


  const onCommandChannelMessage = useCallback(async (payload: any) => {
    // console.log('payload-onCommandChannelMessage', payload)
    if(!payload.text){
      return
    }
    const {
      clearGroupChat
    } = JSON.parse(payload.text);

    if (clearGroupChat) {
      console.log(":::::???clear chatrecieved")
      setMessages([])
    }},[])


    useEffect(() => {
   

      zmClient.on('command-channel-message', onCommandChannelMessage);
      // zmClient.on('device-change', onDeviceChange)
  
      return () => {
        zmClient.off('command-channel-message', onCommandChannelMessage);
  
        // zmClient.off('device-change', onDeviceChange)
      };
    }, [zmClient, onCommandChannelMessage]);

  const clearChat = useCallback(async () => {
    setClearChatModalOpen(false);
    setChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[0]}`;

    const deleteResponse = await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setMessages([]);
    _setMessages(messagesRef.current.slice(0,messagesRef.current.length))
    sessionStorage.removeItem('gmCount')
    const clearGroupChat = true;
    const commandChannel = zmClient.getCommandClient();
    commandChannel.send(JSON.stringify({clearGroupChat}));
    //dataTrackRef.current.send(JSON.stringify({ clearGroupChat }));

    setChatClearLoading(false);

    const user = auth.getUser();
    const currTime = new Date().getTime();
    const uid = get(user, "uid", "");
    const displayName = get(user, "displayName", "");
    await pubnub.publish({
      channel: channels[0],
      message: {
        message_id: uid + currTime,
        userDisplay: displayName,
        description: `${displayName} has just cleared all chat.`,
        userid: uid, // Needed for old msgs as they dont have publisher id
        imageurl: user_imageurl,
        usecase: "clear"
      },
    });

    await pubnub.signal({
      channel: channels[0],
      message: `typing_off:${displayName}`,
    });
  }, [channels]);

 
  const handleUploadCancel =() =>{
    setUploadAlert(false);
    setInput("");
    setUploadFiles([]);
  };

  const handleUploadConfirm = async (event: any) =>{
    setUploadAlert(false);
 }
 
  const onChangeMessage = async (event: any, input: any) => {
    if (event.target.files) {
      setUploadAlert(true);
      let files: any = event.target.files;
      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();
        let file = files[i];
        setFileName(file.name)
        reader.onloadend = () => {
          setUploadFiles([file]);
        }
        reader.readAsDataURL(file);
      }

    }
    //setbrowseInput(false);
    setInput(input);
    await pubnub.signal({
      channel: channels[0],
      message: `typing_on:${displayName}`,
    });
  };

  const onChangeEditMessage = async (input: string) => {
    setEditInput(input);
    await pubnub.signal({
      channel: channels[0],
      message: `typing_on:${displayName}`,
    });
  };


  const getAllMessages = async (
    timetoken: string,
    pubnubObj?: any,
    channelsArray?: any
  ) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[0],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allMessages;
        allMsgs.push(...historyResponse.messages);
        setAllMessages(allMsgs);
        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const addEmoji = (emoji: any) => {
    setInput(input + emoji.native);
    sendTextMessageInput?.current?.focus();
  }

  const downloadMessages = async () => {

    setChatExportLoading(true);
    await getAllMessages("");
    const newLine = '\n';
    const newTab = '\t';

    const allMessagesList = allMessages.map((message: any) => {
      return {
        userDisplay: `${newLine}${message.entry.userDisplay}${newTab}`,
        description: message.entry.description,
        timetoken: `${newLine}${convertDateTimeFromUTCEpoc(Math.ceil(parseInt(message.timetoken) / 10000))}`
      }
    })

    const fields = [
      'userDisplay',
      'description',
      'timetoken'
    ]

    const json2csvParser = new Parser({
      delimiter: '',
      fields: fields,
      quote: ''
    });

    const finalCSV = json2csvParser.parse(allMessagesList);

    const fileBlob = new Blob([finalCSV.replaceAll(",", " ")], {
      type: "application/octet-binary",
    });
    const hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = "_blank";
    hiddenElement.download = `${stream_label}.txt`;
    hiddenElement.click();

    const user = auth.getUser();
    const displayName = get(user, "displayName", "");
    sendMessage(`${displayName} exported chat`,'export');
    setExportChartConfirmationOpen(false)
    setChatExportLoading(false);

  }

  useEffect(()=>{
    if(isGuest){
      if(messages.length){
        sessionStorage.setItem('gmCount',`${messages.length}`)
      }else{
        // sessionStorage.removeItem('gmCount')
      }
    }
  },[messages])

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        //const channelsName = get(troom, "room_code", "");
        //channelNameRef.current = channelsName;
        const user = auth.getUser();
        let userUid = get(user, 'uid', '');
        
        if(streamId !== stream_id){
          setMessages([])
        }
        joinPubNubChat(userUid, vc_id);
      } catch (err) {
        console.error("Error while joining chat", err);
      }
    };
    console.log(":::::::>>",messages)
      fetchData();
    
  }, []);

  useEffect(() => {
    if(messages.length && clearChatFlag && streamId !== stream_id ) {
      setMessages([]) 
    }
  },[clearChatFlag]);

  // JOIN PUBNUB
  const joinPubNubChat = async (user: any, roomName: string) => {
    let new_pubnub;
    //const uid = get(user, "uid", "");
    new_pubnub = new PubNub({
      publishKey: process.env.REACT_APP_PN_PUBLISH || "",
      subscribeKey: process.env.REACT_APP_PN_SUBSCRIBE || "",
      uuid: uid,
      ssl: true,
    });

    if (!new_pubnub) {
      return;
    }
    setPubNub(new_pubnub);

    const mainChannelName = roomName;
    const annotateChannelName = `${roomName}_tc`;

    let new_channels = [mainChannelName];

    if (isFeatureIncluded(FEATURE_NAME.TIME_CODE, show_id)) {
      new_channels.push(annotateChannelName);
    }
    setChannels(new_channels);

    // Subscribe to channel
    new_pubnub.subscribe({ channels: new_channels, withPresence: true });

    let msgcount = 100
    if(isGuest){
      msgcount = Number(sessionStorage.getItem("gmCount") || 0)
    }

    // if(!isGuest || (clearChatFlag && isGuest)){
    // Fetch if you have any recent messages (Max 25 msg per channel)
    const recentmsg_responses: any = await new_pubnub.fetchMessages({
      channels: new_channels,
      count: msgcount,
    });

    if (recentmsg_responses && recentmsg_responses.channels) {
      const channel_msgs = recentmsg_responses.channels;

      if (channel_msgs && channel_msgs[mainChannelName]) {
        const recet_msgs: any[] = channel_msgs[mainChannelName] || [];

        const msgs = messagesRef.current;
        for (let msg of recet_msgs) {
          const old_msg: any = msg.message;

          // Recent msgs doesn't have publisher id, so need to pass userid in message
          old_msg.publisher = old_msg.userid;
          old_msg.timetoken = msg.timetoken;

          if (msg.channel === mainChannelName) {
            let outerIndex = -1;
            msgs.forEach((iMsg:any, index:any) => {
              if (
                msg.message.message_id &&
                iMsg.message_id &&
                iMsg.message_id === msg.message.message_id
              ) {
                outerIndex = index;
                return index;
              }
            });

            if (outerIndex < 0) {
              if (!old_msg.deleted) {
                msgs.push(old_msg);
              }
            } else {
              if (old_msg.deleted) {
                msgs.splice(outerIndex, 1);
              } else {
                msgs[outerIndex] = old_msg;
              }
            }
          }
        }

        setMessages([...msgs]);
      }

      if (channel_msgs && channel_msgs[annotateChannelName]) {
        const recet_msgs: any[] = channel_msgs[annotateChannelName] || [];

        const msgs = annotateMessagesRef.current;
        for (let msg of recet_msgs) {
          const old_msg: any = msg.message;

          // Recent msgs doesn't have publisher id, so need to pass userid in message
          old_msg.publisher = old_msg.userid;
          old_msg.timetoken = msg.timetoken;

          if (msg.channel === annotateChannelName) {
            let outerIndex = -1;
            msgs.forEach((iMsg, index) => {
              if (
                msg.message.message_id &&
                iMsg.message_id &&
                iMsg.message_id === msg.message.message_id
              ) {
                outerIndex = index;
                return index;
              }
            });

            if (outerIndex < 0) {
              if (!old_msg.deleted) {
                msgs.push(old_msg);
              }
            } else {
              if (old_msg.deleted) {
                msgs.splice(outerIndex, 1);
              } else {
                msgs[outerIndex] = old_msg;
              }
            }
          }
        }

        setAnnotateMessages([...msgs]);
      }
    }
  // }

    // Listen to messages
    new_pubnub.addListener({
      message: (messageEvent: any) => {
        const new_msg: any = messageEvent.message;
        new_msg.publisher = messageEvent.publisher;
        new_msg.timetoken = messageEvent.timetoken;

        if (messageEvent.channel === mainChannelName) {
          const msgs = messagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg:any, index:any) => {
            if (
              new_msg.message_id &&
              iMsg.message_id &&
              iMsg.message_id === new_msg.message_id
            ) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setMessages([...msgs]);
        }
        if (messageEvent.channel === annotateChannelName) {
          const msgs = annotateMessagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg, index) => {
            if (
              new_msg.message_id &&
              iMsg.message_id &&
              iMsg.message_id === new_msg.message_id
            ) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setAnnotateMessages([...msgs]);
        }
      },
      signal: (signal: any) => {
        var message = signal.message; // The Payload
        var publisher = signal.publisher; //The Publisher

        console.log(publisher,'publisher')
        console.log(uid,'uid2')

        if (publisher !== uid) {
          var signalName = message.split(":")[0];

          if (signalName === "typing_on") {
            var senderName = message.split(":")[1];
            if (senderName) {
              setTypingInd(senderName + " typing ...");
            }
          } else if (signalName === "typing_off") {
            setTypingInd("");
          }
        }
      },
      // @ts-ignore
      file: (event: any) => {
        const all_msgs = [...messagesRef.current]
        all_msgs.push(event)
        setMessages([...all_msgs])
        setFileUploadLoading(false);
      }
    });
    setStreamId(stream_id);
    return new_pubnub;
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [fileUploadLoading]);

  const downloadFiles = async (id: any, name: any, channel: any) => {
    const file = await pubnub.downloadFile({
      channel: channel,
      id: id,
      name: name
    });

    let fileContent = await file.toBlob();

    var hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(fileContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = name;
    hiddenElement.click();
  }

  
  const renderMessage = (messageDesc: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let div = messageDesc?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return div;
  };



  const renderMessagewithFile = (message: any) => {
    return (
      <Card>
        <Card.Content>
          <Card.Meta>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="file-name">{message?.file?.name}</div>
              <div>
                <img
                  alt={message?.file?.name}
                  src={downloadButtonIcon}
                  onClick={() => downloadFiles(message?.file?.id, message?.file?.name, channels[0])}
                  className="download-file-icon"
                />
              </div>
            </div>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  }


  const handleEditMessageClick = (messageId: string, messageDesc: string) => {
    setEditMessage(true);
    setEditingMessageId(messageId);
    setEditInput(messageDesc);
    let hideEdit = document.getElementById("edit_button_" + messageId);
    hideEdit?.setAttribute("style", "display: none");
  };


  const handleChatCopy = (value: any) => {
    const { userDisplay, description, timetoken } = value;
    const newTimeStamp = Math.ceil(parseInt(timetoken) / 10000)
    const formattedTimeStamp = convertDateTimeFromUTCEpoc(newTimeStamp);
    const copyElement = document.createElement("textarea");
    document.body.appendChild(copyElement);
    copyElement.value = `${userDisplay} - ${description} - ${formattedTimeStamp}`;
    copyElement.select();
    document.execCommand("copy");
    document.body.removeChild(copyElement);
  }

  const onBrowseClick = () => {
    const browse = document.getElementById('file-input');
    if (browse) {
      browse.click();
    }
  };

  const renderMessageWithEditButton = (message: any, messageIndex: number) => {
    let URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

    let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
    let div = message.description?.split(" ").map((part: any) => {
      if (URL_REGEX.test(part)) {
        let hrefUrl = part;
        if (part.startsWith("www")) {
          hrefUrl = "//" + hrefUrl;
        }

        return (
          <div>
            <a key={messageIndex} href={hrefUrl} target="_blank">
              {part}
            </a>
          </div>
        );
      } else {
        return part + " ";
      }
    });

    return (
      <div>
        <div id={message.timetoken} className="chat-message-white-bg">
          {div}
        </div>
        <div className="chat-time">{formatted_time}</div>
      </div>
    );
  };


  const renderEditableMessage = (message: any, messageIndex: number) => {
    if (editingMessageId === message.timetoken) {
      let msg_unix_timestamp = Math.ceil(parseInt(message.timetoken) / 10000);

      let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
      return (
        <div>
          <TextArea
            className="editTxtArea"
            autoFocus
            placeholder="Send a message here"
            rows="2"
            size="small"
            spellCheck="true"
            value={editInput}
            onChange={(event, data) =>
              onChangeEditMessage(data.value as string)
            }
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                event.preventDefault();
                updateMessage(editInput, message, messageIndex);
              } else if (event.key === "Esc") {
                event.preventDefault();
                renderMessage(message.description, messageIndex);
                let hideEdit = document.getElementById(
                  "edit_button_" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
              }
            }}
          />
          <span className="chat-time">{formatted_time}</span>
          <div className="float-right">
            <a
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setEditMessage(false);
                setEditingMessageId("");
                let hideEdit = document.getElementById(
                  "edit_button_" + message.timetoken
                );
                hideEdit?.removeAttribute("style");
                renderMessageWithEditButton(message, messageIndex);
              }}
            >
              <img src={crossTick} className="cancelTick-img" alt="cancel" />
            </a>
            <a
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                updateMessage(editInput, message, messageIndex);
              }}
            >
              <img src={greeTick} className="greenTick-img" alt="cancel" />
            </a>
          </div>
        </div>
      );
    } else {
      // Render this normal method message here.
      return renderMessageWithEditButton(message, messageIndex);
    }
  };



  const renderChatWindowFrmHeader = () => {

    console.log(showChat,'showChat')
    /* Don't comment this please */
    if (!showChat) {
      return;
    }
   /* if (!troom || (troom && isEmpty(troom))) {
      return;
    }*/
    /* We don't want to show chat window
    without conf running */

    return (
      <div className="chat-wrapper">
      <nav className="main-menu-right-chat" id="chat-tray">
        <div className="chat-h1">
          <span className="chat-text">Group Chat</span>
          <div className="chat-options-wrapper">
          {!isGuest &&  <Popup content='Clear Chat' position="left center" trigger={
              <img alt="" className="clear-image clear-chat-button" src={clear} onClick={() => { setClearChatModalOpen(true) }} />
            } />}
            {!isGuest && <Popup content='Export Chat' position="left center" trigger={
              <img alt="" className="clear-image clear-chat-button export-chat-img" src={downloadIcon} onClick={(e) => {
                e.preventDefault();
                setExportChartConfirmationOpen(true);
              }} />
            } />}
        </div>
        </div>
        {
          fileUploadLoading || chatClearLoading ?
            <div>
              <ProgressLoader loading={fileUploadLoading || chatClearLoading} size="small" />
            </div>
            :
            (
              <>
                <div className="chat-content" ref={chatContentRef}>
                  {messagesRef.current.map((message: any, messageIndex: number) => {
                    let editMessageIndex = messagesRef.current.findIndex(
                      (item:any) => item.timetoken === editingMessageId
                    );

                    if (editMessageIndex > -1) {
                      setTimeout(function () {
                        var chatTray = document.getElementById("chat-tray");
                        var currScrollHeight = chatTray?.scrollHeight || 0;
                        var scrollHeight: number =
                          currScrollHeight * (editMessageIndex / messagesRef.current.length);
                        chatTray?.scrollTo(0, scrollHeight - 20);
                      }, 250);
                    } else {
                      setTimeout(function () {
                        var chatTray = document.getElementById("chat-tray");
                        var currScrollHeight = chatTray?.scrollHeight || 0;
                        chatTray?.scrollTo(0, currScrollHeight);
                      }, 250);
                    }
                    
                    let message_timeToken:any = String(message.timetoken).length === 17 ? message.timetoken : String(message.timetoken) + "0000";
                    let msg_unix_timestamp = Math.ceil(
                      parseInt(message_timeToken) / 10000
                    );
                    let formatted_time = convertDateTimeFromUTCEpoc(msg_unix_timestamp);
                    let publisher = message?.file ? message?.message?.publisher : message.publisher;

                    if (publisher === uid) {
                      // Loggedin user
                      let style = {};
                      if (user_imageurl) {
                          style = {
                              background:
                                  'transparent url("' + user_imageurl + '") 0% 0% no-repeat padding-box',
                          };
                      }
                      return (
                        <div className="chat-list-item chat-local-message-body" key={`message-${messageIndex}`}>
                          <div className="chat-message-body">
                            <div className="chat-heading">
                              {message?.file ? message?.message?.userDisplay : message?.userDisplay}
                              {message?.file ? null :
                               (message.usecase !== 'export' && message.usecase !== 'clear') && (
                                <div className="chat-message-hover-tools-row">
                                    <div className="chat-message-tool-icon-container">
                                      <Popup content='Copy To Clipboard' position="top right" trigger={<span className='duplicate-icon' onClick={() => handleChatCopy(message)} />} />
                                    </div>
                                    <div className="chat-message-tool-icon-container">
                                      <Popup content='Edit Message' position="top right" trigger={<span id={"edit_button_" + message.timetoken} className='duplicate-icon edit-message-icon' onClick={() => handleEditMessageClick(message.timetoken, message.description)}/>}/>
                                    </div>                             
                              </div>
                               )
                              }
                            </div>
                            {message?.file ?
                              <div className="file-message-grey-bg">
                                {renderMessagewithFile(message)}
                              </div> :
                              !editMessage ?
                                renderMessageWithEditButton(message, messageIndex) :
                                renderEditableMessage(message, messageIndex)
                            }
                            {message?.file ?
                              <div className="chat-time">{formatted_time}</div> : null
                            }
                          </div>
                          <div className="chat-image" style={style}></div>
                          {scrollChatToBottom()}
                        </div>
                      );
                    } else {
                      // Other users
                      let style = {};
                      if (message.imageurl) {
                          const profile_Image_url = message.imageurl;
                          style = {
                              background:
                                  'transparent url("' + profile_Image_url + '") 0% 0% no-repeat padding-box',
                          };
                      }
                      // else if()
                      return (
                      
                        <div>
                           { (message?.status === 'left') || (message?.status === 'joined') ?
                            <div className="chat-list-item" key={`message-${messageIndex}`}>
                                 {console.log('Rendering chat-notification')}
                              <div className={'chat-notification'}>{renderMessage(message.description, messageIndex)}</div>
                            </div>
                          :
                        <div className="chat-list-item" key={`message-${messageIndex}`}>
                          <div className="chat-image" style={style}></div>
                          <div className="chat-message-body">
                            <div className="chat-heading">
                              {message?.file ? message?.message?.userDisplay : message.userDisplay}
                              {message?.file ? null :
                               (message.status) ? null:
                               (message.usecase !== 'export' && message.usecase !== 'clear') && (
                                <div className="chat-message-hover-tools-row">
                                <div className="chat-message-tool-icon-container">
                                  <Popup content='Copy To Clipboard' position="top right" trigger={<span className='duplicate-icon' onClick={() => handleChatCopy(message)} />} />
                                </div>
                              </div>
                               )
                              }
                            </div>
                            {message?.file ?
                                  <div className="file-message-white-bg">
                                    {renderMessagewithFile(message)}
                                  </div> : 
                                  <div className='chat-message-white-bg'>
                                    {renderMessage(message.description, messageIndex)}
                                  </div>
                                }
                            <div className="chat-time">{formatted_time}</div>
                          </div>
                        </div>
                         }
                         {scrollChatToBottom()}
                        </div>
                      );
                    }
                  })}             
                </div>
                { <div className="emoji-picker">
                  {showEmoji && <Picker onEmojiSelect={addEmoji} theme='dark' title='' emoji='' showSkinTones={true} />}
                </div>  }
                
                <div className="chat-msg-input">
                  <Input
                    className="sendTxt"
                    placeholder={channels.length ? "Send a message here" : "Loading..."}
                    size="small"
                    spellCheck="true"
                    onChange={(event, data) => {
                      if(uploadFiles.length > 0 && event.target.type === "text" && event.target.value === ""){
                        setUploadAlert(false);
                        setInput("");
                        setUploadFiles([]);
                      } else {
                        let input: any = data.value.replace("C:\\fakepath\\", "") as string;
                        onChangeMessage(event, input)
                      }
                    }}
                    onKeyPress={(event: any) => {
                      if (event.key === "Enter") {
                        const inputText = input;
                        setInput("");
                        event.preventDefault();
                        if (inputText.length > 0) sendMessage(inputText);
                      }
                    }}
                    disabled={!channels.length}
                  >
                    {/* <button>Show Modal</button> */}
                    {/* <Icon size="big" className="giphy-btn" onClick={showGiphyModal} /> */}
                    <span className="upload-btn" onClick={onBrowseClick}><img src="assets/images/latest/upload-icon.svg"/></span>
                    <input value={input} ref={sendTextMessageInput} />
                    {
                      // browseInput === true &&
                      <input type="file" id="file-input" style={{ display: 'none' }} />
                    }
                    <div className="emoji-img-wrapper">
                      <img
                        src={emoji}
                        className="emoji-img"
                        alt="Emoji"
                        onClick={() => {
                          setShowEmoji(!showEmoji);
                        }}
                      />
                    </div>
                    <Button
                      color="violet"
                      floated="right"
                      className="send-chat-btn"
                      size="medium"
                      onClick={(e) => {
                        const inputText = input;
                        setInput("");
                        e.preventDefault();
                        if (inputText.length > 0) sendMessage(inputText);
                      }}
                    ><img src={sendMsgIcon}/></Button>
                  </Input>

                </div>
                
                <div className="chat-typing-indicator">{typingInd}</div>
              </>
            )
        }


      </nav>
      </div>
    );
  };

  return (
    <>
    <div>
      {renderChatWindowFrmHeader()}
    </div>
     <ClearChatModal
     clearChat={clearChat}
     />
      <ExportChatModal
        open={exportChartConfirmationOpen}
        onClose={() => setExportChartConfirmationOpen(false)}
        onDownload={downloadMessages}
      />
       <ConfirmationalPopup
        open={uploadAlert}
        data={`Are you sure you want to upload ${fileName}`}
        onCancel={handleUploadCancel}
        onOk={handleUploadConfirm}
      />
     </>
  );



 
};

export default ShowChat;
