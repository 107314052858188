import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { paymentInfoJson, poCodeJson } from "./SubscribeJson";

interface IProps {
  paymentDetails: any;
}

const PaymentInfo = (props: IProps) => {
  const { paymentDetails } = props;
  const {
    paymentType,
    setPaymentType,
    customPlanLabel,
    poCode,
    setPoCode,
    productList,
    priceList,
    setSelectedProduct,
    setCustomPlanLabel,
    selectedProduct,
  } = paymentDetails;

  const handlePaymentTypeOnChange = (event: any, data: any) => {
    setPaymentType(data.value);
    console.log(data.value);
  };
  const paymentInfo: any = [...paymentInfoJson];
  const getIndex = paymentInfoJson.findIndex((com: any) =>
    !!customPlanLabel ? !com.paymentValid : !!com.paymentValid
  );
  paymentInfo.splice(getIndex, 1);
  const getPaymentTypeIndex = paymentInfo.findIndex(
    (com: any) => com.input.name === "paymentType"
  );
  if (paymentType === "offline") {
    paymentInfo.splice(getPaymentTypeIndex + 1, 0, poCodeJson);
  }

  const planList = priceList.map((value: any) => ({
    key: value.id,
    value: value.id,
    text: `${
      productList.filter((pro: any) => (pro.id = value.product))[0].name
    } - USD$${
      value.unit_amount / 100
    } / ${value.recurring.interval.toUpperCase()}`,
  }));
  const handlePlanOnChange = (event: any, data: any) => {
    setSelectedProduct(data.value);
    setCustomPlanLabel(
      data.options.filter((val: any) => val.key === data.value)[0].text
    );
  };
  const dropDownObj: any = {
    planList,
    handlePlanOnChange,
    selectedProduct,
    paymentTypeList: [
      { key: "1", value: "online", text: "Online" },
      { key: "2", value: "offline", text: "Offline" },
    ],
    paymentType,
    handlePaymentTypeOnChange,
  };
  const getTypeofComp = (input: any) => {
    const { type, name, value, options, onChange } = input;
    switch (type) {
      case "text":
        return (
          <input
            className="form-input"
            value={poCode}
            name={name}
            type={type}
            onChange={(e) => setPoCode(e.target.value)}
          />
        );
      case "dropDown":
        return (
          <Dropdown
            placeholder="Select"
            className="form-input"
            fluid
            search
            selection
            options={dropDownObj[options] || []}
            value={dropDownObj[value] || ""}
            onChange={dropDownObj[onChange]}
          />
        );
      default:
        return (
          <label>
            <b>{` ${paymentDetails[name]}`}</b>
          </label>
        );
    }
  };

  const getPaymentInfoComp = () =>
    paymentInfo.map((value: any) => {
      const { input, label, icon = "", className } = value;
      return (
        <div className={`billing-field ${className || ""}`} key={label}>
          {!!icon && <div className={`${value.icon} left-icon`}></div>}
          <div className="form-field icon-input-field">
            <label className="form-input-label">{value.label}</label>
            {getTypeofComp(input)}
          </div>
        </div>
      );
    });
  return (
    <div className="billing-info-wrapper">
      <form className="ui form">
        <div className="stripe-billing-info">{getPaymentInfoComp()}</div>
      </form>
    </div>
  );
};
export default PaymentInfo;
