import React from 'react';
import './styles.css';
import classNames from 'classnames';

const Title = (props?: any) => {
    const { size, value, textColor, styleClass } = props

    return (
        <div
            className={classNames(styleClass ? styleClass : "title-component")}
        >
            {props.children}
        </div>
    )
}

export default Title;