import { useLayoutSlice, useShowspeakerStore, useWhiteboardingStore } from "../../../store";
import changeView from "../../../Images/changeView.svg";
import { Grid } from "antd";
import { get } from "../../../Utils/helpers";

const InvertLayout = ({ resetDrawingButtonState }: any) => {
  const { layoutMode, setLayoutMode } =
    useLayoutSlice();
    const {showSpeakerViewer} = useShowspeakerStore()
    const { resetAllTools } = useWhiteboardingStore();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
  return (
    <div
      className={get(screens, 'xs', false) ? 'miniheader-btn' :`top-menu-button-item ${showSpeakerViewer ? "disableddiv" : ""
        }`}
      onClick={() => {
        setLayoutMode(layoutMode === "normal" ? "inverse" : "normal");
        resetDrawingButtonState();
        resetAllTools();
      }}
    >
      <img src={changeView} alt="" />
     {!get(screens, 'xs', false) &&<span>Invert Layout</span>} 
    </div>
  );
};

export default InvertLayout;
