import { fetchFeatures } from '../Service/show';
import { get } from '../Utils/helpers';
import useNavigateToPage from './useNavigateToPage';

export default function useNavigateToViewer(setLoading: (value: boolean) => void) {
  const navigateToPage = useNavigateToPage();

  const getViewerComponent = async (show_id: string) => {
    try {
      setLoading(true);
      const res = await fetchFeatures(show_id);
      return get(res, 'ALLOW_ZOOM_VIEWER', false);
    } catch (err) {
      console.error('Error fetching feature flag');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const navigateToViewer = async (show_id: string, stateVariables: {}) => {
    const isZoomViewer = await getViewerComponent(show_id);
    if (isZoomViewer) {
      navigateToPage('/viewer', stateVariables);
    } else {
      navigateToPage('/showviewer', stateVariables);
    }
  };

  return { navigateToViewer };
}
