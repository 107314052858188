import { useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Parser } from 'json2csv';
import axios from 'axios';
import PubNub from 'pubnub';
import { convertDateTimeFromUTCEpoc } from '../../../Utils/utils';
import { auth } from '../../../Firebase';
import { get, isEmpty } from '../../../Utils/helpers';
import { FEATURE_NAME } from '../../../Utils/constants';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { AuthContext } from '../../../Context/authContext';
import { useLocationStore } from '../../../store/useLocationStore';

export default function useChatFunctions(
  streamLabel?: string,
  dataTrackRef?: any,
  setAnnotateMessages?: any,
  setClearChatModalOpen?: any,
  setMessages?: any,
  user_imageurl?: any,
  setRPState?: any,
  messagesRef?: any,
  annotateMessagesRef?: any,
  featureAvailable?: any,
  showId?: string,
  setLoading?: any,
  uploadFiles?: any,
  setUploadFiles?: any,
  setInput?: any,
  setShowEmoji?: any,
  setUploadAlert?: any,
  chatInputFieldRef?: any,
) {
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  const locationState = useLocationStore((state) => state.locationState);
  const guestUid = get(locationState, 'state.guestUid', '');
  const guestDisplayName = get(locationState, 'state.guestDisplayName', '');
  const isGuestViewer = get(locationState, 'state.isGuestViewer', false);

  const [pubnub, setPubNub] = useState({} as any);
  const [channels, setChannels] = useState([] as any[]);

  let [allMessages, setAllMessages] = useState([] as any[]);
  let [allAnnotateMessages, setAllAnnotateMessages] = useState([] as any[]);

  const [annotateLoading, setAnnotateLoading] = useState(false);
  const [chatClearLoading, setChatClearLoading] = useState(false);
  const [annotateChatClearLoading, setAnnotateChatClearLoading] = useState(false);
  const [chatExportLoading, setChatExportLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [editAnnotateMessage, setEditAnnotateMessage] = useState(false);
  const [editingAnnotateMessageId, setEditingAnnotateMessageId] = useState('');
  const [annotateInput, setAnnotateInput] = useState('');
  const [editAnnotateInput, setAnnotateEditInput] = useState('');

  const [typingInd, setTypingInd] = useState('');

  const [VFXcolor, setVFXcolor] = useState('red');
  const [SFXcolor, setSFXcolor] = useState('red');
  const [MXcolor, setMXcolor] = useState('red');
  const [PIXcolor, setPIXcolor] = useState('red');

  const [modalAnnotateColorOpen, setModalAnnotateColorOpen] = useState(false);

  const getAllMessages = async (timetoken: string, pubnubObj?: any, channelsArray?: any) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[0],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allMessages;
        allMsgs.push(...historyResponse.messages);
        setAllMessages(allMsgs);
        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const getAllAnnotateMessages = async (
    timetoken: string,
    pubnubObj?: any,
    channelsArray?: any,
  ) => {
    const pubNubInstance = pubnubObj ? pubnubObj : pubnub;

    const channelsInstance = channelsArray ? channelsArray : channels;

    try {
      const historyResponse = await pubNubInstance.history({
        channel: channelsInstance[1],
        stringifiedTimeToken: true, // false is the default
        start: timetoken, // start time token to fetch
      });

      if (historyResponse) {
        let allMsgs = allAnnotateMessages;
        allMsgs.push(...historyResponse.messages);
        setAllAnnotateMessages(allMsgs);

        let start = historyResponse.startTimeToken;

        // if 100 msgs were retrieved, there might be more; call history again
        if (historyResponse.messages.length === 100) {
          await getAllAnnotateMessages(start, pubNubInstance, channelsInstance);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const downloadMessages = async () => {
    setChatExportLoading(true);

    await getAllMessages('');

    const newLine = '\n';
    const newTab = '\t';

    const allMessagesList = allMessages.map((message: any) => {
      return {
        userDisplay: `${newLine}${message.entry.userDisplay}${newTab}`,
        description: message.entry.description,
        timetoken: `${newLine}${convertDateTimeFromUTCEpoc(
          Math.ceil(parseInt(message.timetoken) / 10000),
        )}`,
      };
    });

    const fields = ['userDisplay', 'description', 'timetoken'];

    const json2csvParser = new Parser({
      delimiter: '',
      fields: fields,
      quote: '',
    });

    const finalCSV = json2csvParser.parse(allMessagesList);

    const fileBlob = new Blob([finalCSV.replaceAll(',', ' ')], {
      type: 'application/octet-binary',
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${streamLabel}.txt`;
    hiddenElement.click();

    const user = auth.getUser();
    const displayName = get(user, 'displayName', '');
    sendMessage(`${displayName} exported chat`);

    setChatExportLoading(false);
  };

  const downloadAnnotateMessages = async () => {
    setAnnotateLoading(true);

    await getAllAnnotateMessages('');
    // Now we need to filter only annotate messages which are not deleted and replace the updated messages
    allAnnotateMessages = allAnnotateMessages
      .map((annotateMsg: any) => annotateMsg.entry)
      .filter((msg: any) => msg.annotateNote && msg.annotateNote !== '');

    const msgs: any[] = [];
    for (let msg of allAnnotateMessages) {
      const old_msg: any = msg;
      old_msg.version = 'v1';
      old_msg.number = 1;
      let outerIndex = -1;
      msgs.forEach((iMsg, index) => {
        if (msg.message_id && iMsg.message_id && iMsg.message_id == msg.message_id) {
          outerIndex = index;
          return index;
        }
      });

      if (outerIndex < 0) {
        msgs.push(old_msg);
      } else {
        if (msg.deleted) {
          msgs.splice(outerIndex, 1);
        } else {
          msgs[outerIndex] = old_msg;
        }
      }
    }

    setAllAnnotateMessages([]);

    const fields = [
      'userDisplay',
      'annotationTitle',
      'version',
      'annotateColor',
      'description',
      'number',
    ];

    const json2csvParser = new Parser({
      delimiter: '\t',
      fields: fields,
      quote: '',
    });

    const finalCSV = json2csvParser.parse(msgs);

    var fileBlob = new Blob([finalCSV.replaceAll(',', ' ')], {
      type: 'application/octet-binary',
    });
    var hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(fileBlob);
    // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(finalCSV);
    hiddenElement.target = '_blank';
    hiddenElement.download = `Annotation Title.txt`;
    hiddenElement.click();

    setVFXcolor('red');
    setSFXcolor('red');
    setMXcolor('red');
    setPIXcolor('red');
    setAnnotateLoading(false);
  };

  const clearChat = useCallback(async () => {
    setClearChatModalOpen(false);
    setChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[0]}`;

    await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setMessages([]);

    const clearGroupChat = true;
    dataTrackRef.current.send(JSON.stringify({ clearGroupChat }));

    setChatClearLoading(false);

    const currTime = new Date().getTime();
    const user = auth.getUser();
    let displayName = get(user, 'displayName', '');
    let uid = get(user, 'uid', '');

    if (isGuestViewer) {
      displayName = guestDisplayName;
      uid = guestUid;
    }

    setTimeout(async () => {
      await pubnub.publish({
        channel: channels[0],
        message: {
          message_id: uid + currTime,
          userDisplay: displayName,
          description: `${displayName} has just cleared all chat.`,
          userid: uid, // Needed for old msgs as they dont have publisher id
          imageurl: user_imageurl,
        },
      });

      await pubnub.signal({
        channel: channels[0],
        message: `typing_off:${displayName}`,
      });
    }, 500);
  }, [channels]);

  const clearAnnotationChat = useCallback(async () => {
    setAnnotateChatClearLoading(true);

    const deleteUrl = `https://ps.pndsn.com/v3/history/sub-key/${process.env.REACT_APP_PN_SUBSCRIBE}/channel/${channels[1]}`;

    await axios.delete(deleteUrl);

    // Removing the local messages too so the local chat window will be clear.
    setAnnotateMessages([]);

    const clearAnnotationChat = true;
    dataTrackRef.current.send(JSON.stringify({ clearAnnotationChat }));

    setAnnotateChatClearLoading(false);
  }, [channels]);

  const leaveChat = () => {
    // Disconnect from chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: [channels[0]],
      });

      const msgs = messagesRef.current;
      msgs.length = 0;
      setMessages(msgs);

      //Close chat window
      setRPState((prevState: any) => ({
        ...prevState,
        chatDisable: true,
        chatWindow: false,
        closeDisable: false,
      }));
    }
  };

  const leaveAnnotateChat = () => {
    // Disconnect from annotate chat channel
    if (pubnub && !isEmpty(pubnub)) {
      pubnub.unsubscribe({
        channels: [channels[1]],
      });

      const msgs = annotateMessagesRef.current;
      msgs.length = 0;
      setAnnotateMessages(msgs);

      //Close chat window
      setRPState((prevState: any) => ({
        ...prevState,
        annotationChatWindow: false,
        annotationCloseDisable: false,
        annotationChatDisable: true,
      }));
    }
  };

  // JOIN PUBNUB
  const joinPubNubChat = async (uid: string, roomName: string) => {
    console.log("Connect to PubNub Chat")

    let new_pubnub;
    new_pubnub = new PubNub({
      publishKey: process.env.REACT_APP_PN_PUBLISH || '',
      subscribeKey: process.env.REACT_APP_PN_SUBSCRIBE || '',
      uuid: uid,
      ssl: true,
    });

    if (!new_pubnub) {
      return;
    }
    setPubNub(new_pubnub);

    const mainChannelName = roomName;
    const annotateChannelName = `${roomName}_tc`;
    let new_channels = [mainChannelName];
    if (await featureAvailable(FEATURE_NAME.TIME_CODE)) {
      new_channels.push(annotateChannelName);
    }
    setChannels(new_channels);

    // Subscribe to channel
    new_pubnub.subscribe({ channels: new_channels, withPresence: true });

    // Fetch if you have any recent messages (Max 25 msg per channel)
    if (!isGuestViewer) {
      const recentmsg_responses: any = await new_pubnub.fetchMessages({
        channels: new_channels,
        count: 100,
      });

      if (recentmsg_responses && recentmsg_responses.channels) {
        const channel_msgs = recentmsg_responses.channels;

        if (channel_msgs && channel_msgs[mainChannelName]) {
          const recet_msgs: any[] = channel_msgs[mainChannelName] || [];

          const msgs = messagesRef.current;
          for (let msg of recet_msgs) {
            const old_msg: any = msg.message;

            // Recent msgs doesn't have publisher id, so need to pass userid in message
            old_msg.publisher = old_msg.userid;
            old_msg.timetoken = msg.timetoken;

            if (msg.channel === mainChannelName) {
              let outerIndex = -1;
              msgs.forEach((iMsg: any, index: number) => {
                if (
                  msg.message.message_id &&
                  iMsg.message_id &&
                  iMsg.message_id === msg.message.message_id
                ) {
                  outerIndex = index;
                  return index;
                }
              });

              if (outerIndex < 0) {
                if (!old_msg.deleted) {
                  msgs.push(old_msg);
                }
              } else {
                if (old_msg.deleted) {
                  msgs.splice(outerIndex, 1);
                } else {
                  msgs[outerIndex] = old_msg;
                }
              }
            }
          }

          setMessages([...msgs]);
        }

        if (channel_msgs && channel_msgs[annotateChannelName]) {
          const recet_msgs: any[] = channel_msgs[annotateChannelName] || [];

          const msgs = annotateMessagesRef.current;
          for (let msg of recet_msgs) {
            const old_msg: any = msg.message;

            // Recent msgs doesn't have publisher id, so need to pass userid in message
            old_msg.publisher = old_msg.userid;
            old_msg.timetoken = msg.timetoken;

            if (msg.channel === annotateChannelName) {
              let outerIndex = -1;
              msgs.forEach((iMsg: any, index: number) => {
                if (
                  msg.message.message_id &&
                  iMsg.message_id &&
                  iMsg.message_id === msg.message.message_id
                ) {
                  outerIndex = index;
                  return index;
                }
              });

              if (outerIndex < 0) {
                if (!old_msg.deleted) {
                  msgs.push(old_msg);
                }
              } else {
                if (old_msg.deleted) {
                  msgs.splice(outerIndex, 1);
                } else {
                  msgs[outerIndex] = old_msg;
                }
              }
            }
          }

          setAnnotateMessages([...msgs]);
        }
      }
    }
    
    // Listen to messages
    new_pubnub.addListener({
      message: (messageEvent: any) => {
        const new_msg: any = messageEvent.message;
        new_msg.publisher = messageEvent.publisher;
        new_msg.timetoken = messageEvent.timetoken;

        if (messageEvent.channel === mainChannelName) {
          const msgs = messagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg: any, index: number) => {
            if (new_msg.message_id && iMsg.message_id && iMsg.message_id === new_msg.message_id) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setMessages([...msgs]);
        }
        if (messageEvent.channel === annotateChannelName) {
          const msgs = annotateMessagesRef.current;
          let outerIndex = -1;
          msgs.forEach((iMsg: any, index: number) => {
            if (new_msg.message_id && iMsg.message_id && iMsg.message_id === new_msg.message_id) {
              outerIndex = index;
              return index;
            }
          });

          if (outerIndex < 0) {
            if (!new_msg.deleted) {
              msgs.push(new_msg);
            }
          } else {
            if (new_msg.deleted) {
              msgs.splice(outerIndex, 1);
            } else {
              msgs[outerIndex] = new_msg;
            }
          }

          setAnnotateMessages([...msgs]);
        }
      },
      signal: (signal: any) => {
        var message = signal.message; // The Payload
        var publisher = signal.publisher; //The Publisher
        let authUserID:string='';
        if(isGuestViewer){
          authUserID=guestUid
        }
        else{
          authUserID=auth.getUserId()
        }
        if (publisher !== authUserID) {
          var signalName = message.split(':')[0];

          if (signalName === 'typing_on') {
            var senderName = message.split(':')[1];
            if (senderName) {
              setTypingInd(senderName + ' typing ...');
            }
          } else if (signalName === 'typing_off') {
            setTypingInd('');
          }
        }
      },
      // @ts-ignore
      file: (event: any) => {
        const all_msgs = [...messagesRef.current];
        all_msgs.push(event);
        setMessages([...all_msgs]);
        setFileUploadLoading(false);
      },
    });

    return new_pubnub;
  };

  // Group Chat
  const sendMessage = useCallback(
    async (message: any) => {
      if (isGuestViewer) {
        if (uploadFiles.length > 0) {
          setFileUploadLoading(true);
          const uid = guestUid!;
          const currTime = new Date().getTime();
          await pubnub.sendFile({
            channel: channels[0],
            file: uploadFiles[0],
            message: {
              userDisplay: guestDisplayName,
              imageurl: user_imageurl,
              message_id: uid + currTime,
              description: '',
              userid: uid, // Needed for old msgs as they dont have publisher id
              publisher: auth.getUserId(),
            },
          });
        } else if (message.length > 0) {
          const uid = guestUid!;
          const currTime = new Date().getTime();
          await pubnub.publish({
            channel: channels[0],
            message: {
              message_id: uid + currTime,
              userDisplay: guestDisplayName,
              description: message,
              userid: uid, // Needed for old msgs as they dont have publisher id
              imageurl: user_imageurl,
            },
          });

          await pubnub.signal({
            channel: channels[0],
            message: `typing_off:${guestDisplayName}`,
          });
        }
      } else {
        if (uploadFiles.length > 0) {
          setFileUploadLoading(true);
          const user = auth.getUser();
          const uid = get(user, 'uid', '');
          const displayName = get(user, 'displayName', '');
          const currTime = new Date().getTime();
          await pubnub.sendFile({
            channel: channels[0],
            file: uploadFiles[0],
            message: {
              userDisplay: displayName,
              imageurl: user_imageurl,
              message_id: uid + currTime,
              description: '',
              userid: uid, // Needed for old msgs as they dont have publisher id
              publisher: auth.getUserId(),
            },
          });
        } else if (message.length > 0) {
          const user = auth.getUser();
          const currTime = new Date().getTime();
          const uid = get(user, 'uid', '');
          const displayName = get(user, 'displayName', '');
          await pubnub.publish({
            channel: channels[0],
            message: {
              message_id: uid + currTime,
              userDisplay: displayName,
              description: message,
              userid: uid, // Needed for old msgs as they dont have publisher id
              imageurl: user_imageurl,
            },
          });

          await pubnub.signal({
            channel: channels[0],
            message: `typing_off:${displayName}`,
          });
        }
      }

      setUploadFiles([]);
      setInput('');
      setShowEmoji(false);
    },
    [pubnub, channels, uploadFiles, user_imageurl],
  );

  /*
   * Timecode Annotation Supported Actions
   */

  const getTimeCode = async (dataURI: string) => {
    if (!dataURI) {
      return;
    }
    try {
      const data = {
        api: api.streams.timeCode,
        payLoad: JSON.stringify({ show_id: showId, file: dataURI }),
      };

      const result: any = await backend.save(data, get(authValue, 'accessToken', {}));
      return result;
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTimecodeImage = () => {
    const video_player = document.getElementById('main-stream-video') as HTMLVideoElement;

    const canvas = document.getElementById('canvas') as HTMLCanvasElement;

    const v_canvas = document.createElement('canvas') as HTMLCanvasElement;
    const canvasWidth = get(canvas, 'width', 0);
    const canvasHeight = get(canvas, 'height', 0);
    if (!!v_canvas) {
      v_canvas.width = canvasWidth;
      v_canvas.height = canvasHeight;
    }

    let dataURI: any = '';
    if (video_player && v_canvas) {
      // get complete image
      const v_canvasWidth = get(v_canvas, 'width', '');
      const v_canvasHeight = get(v_canvas, 'height', 0);
      const ctx = v_canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx.drawImage(video_player, 0, 0, v_canvasWidth, v_canvasHeight);
      var imageData = ctx.getImageData(0, 0, v_canvasWidth, 30);

      // destination canvas
      const img_capture_canvas = document.createElement('canvas') as HTMLCanvasElement;
      if (!!img_capture_canvas) {
        img_capture_canvas.width = v_canvasWidth;
        img_capture_canvas.height = 30;
      }
      const ctx1 = img_capture_canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx1.putImageData(imageData, 0, 0);

      // Convert to desired file format
      dataURI = img_capture_canvas.toDataURL('image/jpg');

      img_capture_canvas.parentNode?.removeChild(img_capture_canvas);

      v_canvas.parentNode?.removeChild(v_canvas);

      // Clear the captured image at the end
      ctx.clearRect(0, 0, v_canvasWidth, v_canvasHeight);
    }

    return dataURI;
  };

  const handleAnnotateEditMessageClick = (messageId: string, messageDesc: string) => {
    setEditAnnotateMessage(true);
    setEditingAnnotateMessageId(messageId);
    setAnnotateEditInput(messageDesc);
    let hideEdit = document.getElementById('edit_button' + messageId);
    hideEdit?.setAttribute('style', 'display: none');
  };

  // Annotation Chat
  const sendAnnotationMessage = async (
    message: string,
    annotateNote: string,
    annotateColor: string,
  ) => {
    if (annotateNote === '' && message.length < 1) {
      return;
    }

    setAnnotateInput('');

    const user: any = auth.getUser();
    let currTime = new Date().getTime();
    const uid = get(user, 'uid', '');
    const displayName = get(user, 'displayName', '');
    // Update local state with new msg
    const new_msg: any = {
      publisher: uid,
      message_id: uid + currTime,
      annotationTitle: 'Loading...',
      userDisplay: displayName,
      description: message,
      userid: uid,
      imageurl: user_imageurl,
      annotateNote: annotateNote,
      annotateColor: annotateColor,
      timecode: 'Loading...',
    };

    const msgs = [...annotateMessagesRef.current];
    msgs.push(new_msg);
    setAnnotateMessages(msgs);

    let dataURI: string = '';
    let timeCodeResponse: any = {};
    dataURI = getTimecodeImage();

    if (dataURI) {
      let result: any = await getTimeCode(dataURI);

      if (result) {
        // Delete the local message
        let filteredMsgs = msgs.filter((msg: any) => msg.message_id !== new_msg.message_id);

        setAnnotateMessages([...filteredMsgs]);

        timeCodeResponse = result;

        currTime = new Date().getTime();
        let title = 'Annotation Title';
        if (timeCodeResponse && timeCodeResponse.title) {
          title = timeCodeResponse.title;
        }
        const uid = get(user, 'uid', '');
        const displayName = get(user, 'displayName', '');
        const pubnubResponse = await pubnub.publish({
          channel: channels[1],
          message: {
            message_id: uid + currTime,
            annotationTitle: title,
            userDisplay: displayName,
            description: message,
            userid: uid,
            imageurl: user_imageurl,
            annotateNote: annotateNote,
            annotateColor: annotateColor,
            timecode: timeCodeResponse.timecode,
          },
        });

        if (message === '') {
          handleAnnotateEditMessageClick(pubnubResponse.timetoken, message);
        }
      }
    }

    await pubnub.signal({
      channel: channels[1],
      message: `typing_off:${displayName}`,
    });
  };

  const handleUploadCancel = () => {
    setUploadAlert(false);
    setInput('');
    setUploadFiles([]);
  };

  const handleUploadConfirm = async (event: any) => {
    setUploadAlert(false);
    chatInputFieldRef?.current && chatInputFieldRef?.current?.focus();
  };

  const onChangeAnnotateMessage = async (input: string) => {
    setAnnotateInput(input);
    const user = auth.getUser();
    const displayName = get(user, 'displayName', '');
    await pubnub.signal({
      channel: channels[1],
      message: `typing_on:${displayName}`,
    });
  };

  const onChangeAnnotateEditMessage = async (input: string) => {
    setAnnotateEditInput(input);
    const user = auth.getUser();
    const displayName = get(user, 'displayName', '');
    await pubnub.signal({
      channel: channels[1],
      message: `typing_on:${displayName}`,
    });
  };

  const handleChatCopy = (value: any) => {
    const { userDisplay, description, timetoken } = value;
    const newTimeStamp = Math.ceil(parseInt(timetoken) / 10000);
    const formattedTimeStamp = convertDateTimeFromUTCEpoc(newTimeStamp);
    const copyElement = document.createElement('textarea');
    document.body.appendChild(copyElement);
    copyElement.value = `${userDisplay} - ${description} - ${formattedTimeStamp}`;
    copyElement.select();
    document.execCommand('copy');
    document.body.removeChild(copyElement);
  };

  return {
    downloadMessages,
    downloadAnnotateMessages,
    clearChat,
    clearAnnotationChat,
    leaveChat,
    leaveAnnotateChat,
    joinPubNubChat,
    pubnub,
    channels,
    typingInd,
    sendMessage,
    sendAnnotationMessage,
    handleChatCopy,
    handleUploadCancel,
    handleUploadConfirm,
    onChangeAnnotateMessage,
    onChangeAnnotateEditMessage,
    annotateLoading,
    chatClearLoading,
    annotateChatClearLoading,
    chatExportLoading,
    fileUploadLoading,
    setVFXcolor,
    setSFXcolor,
    setMXcolor,
    setPIXcolor,
    VFXcolor,
    SFXcolor,
    MXcolor,
    PIXcolor,
    editAnnotateMessage,
    editingAnnotateMessageId,
    annotateInput,
    editAnnotateInput,
    setEditAnnotateMessage,
    setEditingAnnotateMessageId,
    setAnnotateEditInput,
    handleAnnotateEditMessageClick,
    setAnnotateInput,
    modalAnnotateColorOpen, 
    setModalAnnotateColorOpen
  };
}
