import { useContext } from 'react';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import { notification } from 'antd';
import { DeviceDisabledButtonDataType, StatusDataType } from './structs';

export default function useDeviceActions(
  setRefreshKey: (value: boolean) => void,
  refreshKey: boolean,
  setLoading: (value: boolean) => void,
  setDeviceStatusData: (value: React.SetStateAction<StatusDataType>) => void,
  setStatusModalOpen: (value: boolean) => void,
  setDisableButton: (value: React.SetStateAction<DeviceDisabledButtonDataType>) => void,
  searchResults: never[],
  setSearchResults: (value: never[]) => void,
) {
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});

  const reboot = async (device_id: string) => {
    try {
      setLoading(true);
      setDisableButton((prevState) => ({
        ...prevState,
        device_id: device_id,
        disabled_state: true,
      }));

      const data = {
        api: api.encoder.reboot,
        urlParam: device_id,
      };
      const result = await backend.save(data, get(authValue, 'accessToken', {}));
      if (result) {
        notification.success({
          message: 'Reboot successfull',
        });
      }
    } catch (err: any) {
      console.log(err);
      notification.error({
        message: err.errMessage,
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        setDisableButton((prevState) => ({
          ...prevState,
          device_id: '',
          disabled_state: false,
        }));
      }, 70000);
    }
  };

  const getStatus = async (object_id: string, device_id: string) => {
    try {
      setLoading(true);
      const statusPayload = {
        api: api.encoder.status,
        urlParam: device_id,
        payLoad: JSON.stringify({ id: object_id }),
      };

      const result = await backend.save(statusPayload, get(authValue, 'accessToken', {}));
      if (result) {
        setStatusModalOpen(true);
        const statusData: any = get(get(result, 'message', ''), 'data', '');
        setDeviceStatusData((prevState) => ({
          ...prevState,
          audioBitrate: get(statusData, 'audio-bitrate', ''),
          audioCodec: get(statusData, 'audio-codec', ''),
          colorFormat: get(statusData, 'color-format', ''),
          currentVideoBitrate: get(statusData, 'current-video-bitrate', ''),
          fps: get(statusData, 'fps', ''),
          hdmiStatus: get(statusData, 'hdmi-status', ''),
          sdiStatus: get(statusData, 'sdi-status', ''),
          videoBitrate: get(statusData, 'video-bitrate', ''),
          videoCodec: get(statusData, 'video-codec', ''),
          videoInput: get(statusData, 'video-input', ''),
          videoResolution: get(statusData, 'video-resolution', ''),
        }));
      }
    } catch (err: any) {
      console.error(err);
      notification.error({
        message: err.errMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const provisionDevice = async (object_id: string, device_id: string) => {
    try {
      setLoading(true);
      const statusPayload = {
        api: api.encoder.provision,
        urlParam: device_id,
        payLoad: JSON.stringify({ id: object_id }),
      };

      const result = await backend.save(statusPayload, get(authValue, 'accessToken', {}));
      if (result) {
        notification.success({
          message: 'Device provisioned successfully',
        });
        setRefreshKey(!refreshKey);
      }
    } catch (err: any) {
      console.log(err);
      notification.error({
        message: err.errMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const unProvisionDevice = async (object_id: string, device_id: string) => {
    try {
      setLoading(true);
      const statusPayload = {
        api: api.encoder.unProvision,
        urlParam: device_id,
        payLoad: JSON.stringify({ id: object_id }),
      };

      const result = await backend.save(statusPayload, get(authValue, 'accessToken', {}));
      if (result) {
        notification.success({
          message: 'Device un-provisioned successfully',
        });
        setRefreshKey(!refreshKey);

        if (searchResults.length) {
          const refreshedList = searchResults.filter(
            (device: any) => device.device_id !== device_id,
          );
          setSearchResults(refreshedList);
        }
      }
    } catch (err: any) {
      console.log(err);
      notification.error({
        message: err.errMessage,
      });
    } finally {
      setLoading(false);
    }
  };
  return { reboot, getStatus, provisionDevice, unProvisionDevice };
}
