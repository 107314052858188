import axios from 'axios';

export const uploadData = async (
  uploadURLResult: any,
  upload: any,
  setUploadProgress: any,
  progress: number,
  etagArr: any,
  foldersize: number,
  setAwsRequestData:any,
  uploadedData:any,
  setUploadedData:any
) => {
  const file = upload.originFileObj;
  const fileSize = file.size;
  const baseFileSize = 104857600;
  const minimumChunkFileSize = Math.min(fileSize,baseFileSize); 
  // const chunk_extras: any = [];
  let endByte = 0;
  const no_of_chunks = Math.ceil(foldersize / minimumChunkFileSize);
  const progressUnit = 80 / no_of_chunks;
  let updatedRequestData:any = [];
  const apiCallPromises: any = [];
  uploadURLResult.forEach((doc: any) => {
    const partNumber = uploadURLResult.indexOf(doc) + 1;
    const startByte = (partNumber - 1) * minimumChunkFileSize;
    endByte = Math.min(partNumber * minimumChunkFileSize, fileSize);
    const cancelTokenSource = axios.CancelToken.source();
    if (startByte < endByte) {
      const partData = file.slice(startByte, endByte);
      const uploadConfig = {
        ...doc,
        data: partData,
        cancelToken: cancelTokenSource.token
      };
     
      apiCallPromises.push({
        partNumber: partNumber,
        endByte: endByte,
        promise: axios.request(uploadConfig),
        
      });

      updatedRequestData.push({source:cancelTokenSource, doc:doc})
      setAwsRequestData(updatedRequestData)
    }
  });

  const promises = apiCallPromises.map((item: any) => {
    return { promise: item.promise, partNumber: item.partNumber, endbyte: item.endByte };
  });

  const promiseStates = promises.map((promise: any) =>
    promise.promise.then((response: any) => {
      response.partNumber = promise.partNumber;
      etagArr.push(response);
      progress = progress + progressUnit;
      if (progress < 80 || no_of_chunks === 1) {
        setUploadProgress(progress);
        setUploadedData(uploadedData+minimumChunkFileSize);
        
      }
    }),
  );
  await Promise.all(promiseStates);
  const chunk_extras: any = [];
  etagArr.forEach((item: any, key: any) => {
    chunk_extras.push({
      partNumber: String(item.partNumber),
      etag: item.headers.etag,
    });
  });
  sessionStorage.setItem("progress",String(progress))
  return { chunk_extras, endByte };
};
