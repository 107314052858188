import React, { useState, useEffect, useContext } from 'react';
import { Button, Dropdown} from 'semantic-ui-react';
import { PAGES } from '../../Utils/constants';
import { Layout } from '../PageLayout/Layout';
import { useDropzone } from 'react-dropzone';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import ProgressLoader from '../Common/ProgressLoader';
import { auth } from '../../Firebase';
import { storage } from '../../Firebase/firebase';
import { Mixpanel } from '../../Service/Mixpanel';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import './Profile.css';
import ConfirmationalPopup from '../../Utils/ConfirmationalPopup';
import { isTrustedDeviceFeatureEnabled } from '../../Utils/featureFlags';
import ImageCanvas from './ImageCanvas';
import useNavigateToPage from '../../hooks/useNavigateToPage';
import { useLocationStore } from '../../store/useLocationStore';

const Profile = () => {
  const navigateToPage = useNavigateToPage();
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //const [errorMessage1, setErrorMessage1] = useState("");
  const [successMessage, setSuccessMessage] = useState('');

  const [overlay, setOverlay] = useState({ style: { display: 'none' } });

  const [classApply, setClass] = useState('form-input');
  const [files, setFiles] = useState([]);
  const [imgPrev, setImagePreview] = useState(true);
  const [fileReject, setFileReject] = useState(false);
  const [fileRejectMsg, setFileRejectMsg] = useState('');
  const maxSize = 524288 * 2;

  const [uid, setUserId] = useState('');
  const [user_title, setUserTitle] = useState('');
  const [user_name_first, setFirstName] = useState('');
  const [user_name_last, setLastName] = useState('');
  const [user_email, setEmail] = useState('');
  const [user_phone, setPhone] = useState('');
  const [country_code, setCountryCode] = useState('');
  //const [user_password, setPassword] = useState("");
  const [user_imageurl, setProfileImageUrl] = useState('');

  // const [pwd_match, setMatchPassword] = useState("");

  //const [titleValid, setTitleValid] = useState(true);
  const [fNameValid, setfNameValid] = useState(true);
  const [lNameValid, setlNameValid] = useState(true);
  //const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);

  //const [errorTitle, setErrorTitle] = useState("");
  const [errorfName, setErrorfName] = useState('');
  const [errorlName, setErrorlName] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [googleAuthValid, setGoogleAuthValid] = useState(false);

  const [saveAlertOpen, setSaveAlertOpen] = useState(false);
  const [profileData, setProfileData] = useState({} as any);
  const [removePicAlert, setRemovePicAlert] = useState(false);

  const twoFaTypes = [
    { text: 'SMS', value: 'SMS' },
    { text: 'Google Auth', value: 'Google Auth' },
    { text: 'Twilio Authy', value: 'Authy' },
  ];
  const [twoFaType, setTwoFaType] = useState('SMS');
  const [qrcodeImg, setQRCodeimg] = useState('');
  const [googleAuthSecret, setGoogleAuthSecret] = useState();
  const [authyId, setAuthyId] = useState();
  const [code, setCode] = useState('');
  const [otpValid, setOtpValid] = useState(true);
  const [errorOtp, setErrorOtp] = useState('');
  const [otpErrorMessage, setOtpErrorMessage] = useState('');

  const locationState = useLocationStore((state) => state.locationState);
  const userId = get(locationState,'state.userId','');
  const isLoading = get(locationState,'loading',false);
  //trusted device flag
  const [trustedDeviceEnable, setTrustedDeviceEnable] = useState(false)

  useEffect(() => {
    // Mixpanel
    Mixpanel.track('Visit', {
      Platform: 'Web',
      'Page Name': PAGES.USER_PROFILE_SETTINGS_PAGE,
    });

    const fetchUserprofile = async () => {
      try {
        const data = {
          api: api.users.get,
        };
        setLoading(true);
        const profiledata = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        if (profiledata) {
          profiledata.two_fa_type = profiledata.two_fa_type || 'SMS';
          setProfileData(profiledata);
          const { title, email, phone, name, uid, two_fa_type = 'SMS', imageUrlPath } = profiledata;
          const { user_name_first, user_name_last } = name;
          const { user_phone, country_code } = phone;

          setUserTitle(title ? title : '');
          setFirstName(user_name_first);
          setTwoFaType(two_fa_type);
          setLastName(user_name_last);
          setEmail(email);
          setPhone(user_phone);
          setCountryCode(country_code);
          setUserId(uid);
          setProfileImageUrl(imageUrlPath);
        }
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (!userId) {
      if(!isLoading){
        navigateToPage('/login');
      }  
    }
    fetchUserprofile();
    updateTrustedDeviceStatus();
  }, [user_imageurl]);

  const {
    getRootProps,
    getInputProps,
    // isDragActive,
    // isDragAccept,
    // isDragReject,
    acceptedFiles,
    open,
    //fileRejections,
  } = useDropzone({
    accept: 'image/png, image/jpeg, image/jpg',
    noClick: true,
    noKeyboard: true,
    minSize: 0,
    maxSize,
    onDrop: (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      setImagePreview(false); // Shouldn't be otherway round, imagepreview should be true here ?
      setFileReject(false);
    },
    onDropRejected: (fileRejections: any) => {
      if (fileRejections.length > 0) {
        setFileReject(true);
        setImagePreview(true);
        setFileRejectMsg('File is rejected! Image size should be less than 1MB');
      } else {
        setFileReject(false);
        setImagePreview(false);
        setFileRejectMsg('');
      }
    },
  });

  const thumbs = files.map((file: any, index: any) => (
    <img key={index} alt='profileImg' className='profile_img1' src={file.preview} />
  ));

  // const filepath = acceptedFiles.map((file: any) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  const removeProfileClick = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const test = profileData.imageUrlPath;
    if (test !== '') {
      setRemovePicAlert(true);
    }
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const showOverlayForThreeSeconds = async () => {
    let sstyle: any = { style: { display: 'block' } };
    setOverlay(sstyle);

    await sleep(3000);

    let hstyle: any = { style: { display: 'none' } };
    setOverlay(hstyle);
  };

  const save = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSaveAlertOpen(true);
    //setSuccessMessage("");
    setErrorMessage('');
  };

  //Confirm Alerts
  const handleSaveCancel = async () => {
    try {
      setSaveAlertOpen(false);

      if (profileData) {
        const { email, phone, name, uid, imageUrlPath } = profileData;
        const { user_name_first, user_name_last } = name;
        const { user_phone } = phone;

        setFirstName(user_name_first);
        setLastName(user_name_last);
        setEmail(email);
        setPhone(user_phone);
        setUserId(uid);
        setProfileImageUrl(imageUrlPath);
        setImagePreview(true);
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  //Confirm Alerts
  const handleRemoveCancel = async () => {
    try {
      setRemovePicAlert(false);

      if (profileData) {
        const { imageUrlPath } = profileData;
        setProfileImageUrl(imageUrlPath);
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveConfirm = async () => {
    try {
      setRemovePicAlert(false);
      const deleteRef = storage.ref(storage.storageData, `profile_images/${uid}`);

      // Delete the file
      storage.deleteObject(deleteRef).then(() => {
        setProfileImageUrl('');
        saveProfileData('');
        setImagePreview(true);
      });
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const saveProfileToStorage = async () => {
    if (files[0]) {
      try {
        // Upload to google storage
        const profile_image = files[0];
        const uploadTask = storage.ref(storage.storageData, `/profile_images/${uid}`);
        storage.uploadBytes(uploadTask, profile_image).then(() => {
          storage
            .getDownloadURL(storage.ref(storage.storageData, `profile_images/${uid}`))
            .then((url) => {
              setProfileImageUrl(url);

              // Send profile details to backend
              saveProfileData(url);
            });
        });
      } catch (err: any) {
        throw err;
      }
    }
  };

  const setTwoFa = async (type: string) => {
    if (type) {
      setTwoFaType(type);
      setSuccessMessage('');
      setGoogleAuthValid(false);
    }
    if (type !== profileData.two_fa_type) {
      if (type && type === 'Authy') {
        try {
          if (!user_email || !user_phone || !country_code) {
            throw new Error('User email/phone details are missing');
          }
          const countryCode = country_code.replace(/^\+/, '');
          const input = {
            userEmail: user_email,
            userMobile: user_phone,
            userCountryCode: countryCode,
          };
          const data = {
            api: {
              method: 'POST',
              baseEndPoint: '/app',
              endPoint: '/otp/authy',
            },
            payLoad: JSON.stringify(input),
          };
          setLoading(true);
          const authyId = await backend.save(data, get(currentUser, "user.accessToken", ""));
          if (authyId) {
            setAuthyId(authyId);
          }
        } catch (error) {
          const errorObj: any = error;
          setErrorMessage(errorObj.message);
        } finally {
          setLoading(false);
        }
      }
      if (type && type === 'Google Auth') {
        try {
          const data = {
            api: {
              method: 'GET',
              baseEndPoint: '/app',
              endPoint: '/otp/googleAuth',
            },
          };
          setLoading(true);
          const result = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
          if (result && result.qrcodeImg) {
            setQRCodeimg(result.qrcodeImg);
          }
          if (result && result.googleAuthSecret) {
            setGoogleAuthSecret(result.googleAuthSecret);
          }
        } catch (error) {
          const errorObj: any = error;
          setErrorMessage(errorObj.message);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const saveProfileData = async (imageurl: string) => {
    try {
      let input: any = {
        user_title,
        user_image_url_path: imageurl,
        two_fa_type: twoFaType,
      };
      if (twoFaType && twoFaType === 'Google Auth') {
        input['google_auth_secret'] = googleAuthSecret;
      }
      if (twoFaType && twoFaType === 'Authy') {
        input['twilio_authy_id'] = authyId;
      }
      const data = {
        api: api.users.edit,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      const response = await backend.save(data, get(currentUser, "user.accessToken", ""));

      setLoading(false);

      await showOverlayForThreeSeconds();
      return response;
    } catch (err: any) {
      throw err;
    }
  };

  const updateTrustedDeviceStatus = async () => {
    const trustedDeviceFeature = await isTrustedDeviceFeatureEnabled(get(currentUser, "user.accessToken", ""));
    setTrustedDeviceEnable(!!trustedDeviceFeature)
  };

  const handleSaveConfirm = async () => {
    try {
      setQRCodeimg('');
      setSaveAlertOpen(false);
      //setSuccessMessage("");
      setErrorMessage('');

      setLoading(true);

      if (files && files[0]) {
        // Upload image and send profile data to backend
        await saveProfileToStorage();
      } else {
        await saveProfileData(user_imageurl);
        if (twoFaType !== profileData.two_fa_type) {
          if(trustedDeviceEnable) {
            const device = {
              api: api.trustedDevice.deactivateAllDevices,
              payLoad: {}
            }
            await backend.remove(device, get(currentUser, "user.accessToken", ""));
          }
          setLoading(true);
          await auth.doSignOut();
          navigateToPage('/login', { replace: true });
          // Mixpanel.track("Logout", { Platform: "Web" });
          setLoading(false);
        }
      }
    } catch (error) {
      const msg = 'Error while updating user !';
      console.error(msg);
      const errorObj: any = error;
      if (errorObj.errMessage) {
        setErrorMessage(errorObj.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const handelProfileClick = async () => {
    const user = auth.getUser();
    const uid = get(user, 'uid', '');
    navigateToPage('/showselection', { state: { userId: uid } });
  };

  const validateTitle = (event: any) => {
    setUserTitle(event.target.value);
  };

  const validateFirstName = (event: any) => {
    setFirstName(event.target.value);
    let validatefName = event.target.value;
    if (!validatefName) {
      setfNameValid(false);
      setErrorfName('Please enter your first name');
    } else {
      setfNameValid(true);
      setErrorfName('');
    }
  };

  const validateLastName = (event: any) => {
    setLastName(event.target.value);
    let validatelName = event.target.value;
    if (!validatelName) {
      setlNameValid(false);
      setErrorlName('Please enter your last name');
    } else {
      setlNameValid(true);
      setErrorlName('');
    }
  };

  const validatePhone = (event: any) => {
    setPhone(event.target.value);
    let validatePhone = event.target.value;
    if (!validatePhone) {
      setPhoneValid(false);
      setErrorPhone('Please enter phone number');
    } else if (!/^\d{10}$/.test(validatePhone)) {
      setPhoneValid(false);
      setErrorPhone('Please enter 10 digit number');
    } else {
      setPhoneValid(true);
      setErrorPhone('');
    }
  };

  const handleEmail = (event: any) => {
    setEmail(event.target.value);
    let validateEmail = event.target.value;
    if (!validateEmail) {
      //setEmailValid(false);
      setErrorMessage('Invalid Email!');
      setClass('form-input emailError');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      //setEmailValid(false);
      setErrorMessage('Invalid email format');
      setClass('form-input emailError');
    } else {
      //setEmailValid(true);
      setErrorMessage('');
      setClass('form-input');
    }
  };

  const validOtp = (event: any) => {
    const { value } = event.target;
    const validateOtp = value.replace(/[^0-9]/, '');
    setCode(validateOtp);
    if (!validateOtp) {
      setOtpValid(false);
      //setErrorOtp("Otp contain only digit");
    } else {
      setOtpValid(true);
      setErrorOtp('');
    }
  };

  const validateOtp = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      setSuccessMessage('');
      setOtpErrorMessage('');

      let input: any = { otp: code, action: 'login' };
      let data = {
        api: api.otp.validateOtp,
        payLoad: JSON.stringify(input),
      };

      if (twoFaType && twoFaType === 'Google Auth') {
        input = { userId: uid, token: code, authSecret: googleAuthSecret };
        data = {
          api: api.otp.validateGoogleAuthOtp,
          payLoad: JSON.stringify(input),
        };
      }
      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));

      if (result) {
        setSuccessMessage('Verified. Click Save changes for the changes to reflect');
        setGoogleAuthValid(true);
      } else {
        throw new Error('Invalid/Expired OTP');
      }
    } catch (error) {
      console.error(error);
      const msg = 'OTP123 Validation failed';
      const errorObj: any = error;
      if (errorObj && errorObj.errMessage) {
        setOtpErrorMessage(errorObj.errMessage);
      } else {
        setOtpErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };
  const saveChangesValid = () => {
    const titleChange = user_title !== profileData.title;
    const imgPrevChange = !imgPrev;
    //const removeImgChange = removePic == true;
    let twoFaTypeValid = false;
    if (twoFaType !== profileData.two_fa_type) {
      twoFaTypeValid = twoFaType === 'Google Auth' ? !!googleAuthValid : true;
    }
    return !(titleChange || imgPrevChange || twoFaTypeValid);
  };
  return (
    <Layout page={PAGES.USER_PROFILE_SETTINGS_PAGE} profile_image_url={user_imageurl}>
      <ProgressLoader loading={loading} />

      <div className='left-content'>
        <div className='profile_image'></div>
      </div>

      <div className='right-content' style={{ paddingLeft: ' 25px' }}>
        <p className='profile_heading'>Account Settings</p>
        <hr className='top' />

        <div className='form-content margin-left-0 padding-bottom-140'>
          <form className='ui form'>
            <div className='field-and-icon'>
              <div className=''>
                <div id='profile-text'>
                  <div>.img, .png and .jpeg are supported files - Max size upto 1MB </div>
                  <div
                    className='remove_profilepic'
                    data-tooltip='Remove Pic'
                    onClick={removeProfileClick}
                  />
                </div>

                {user_imageurl && imgPrev && (
                  <ImageCanvas
                  className={`profile_img`}
                  imageUrl={user_imageurl}
                  onClick={handelProfileClick}
                  style={{maxWidth: "100px"}}
                />

                )}
                {!user_imageurl && imgPrev && (
                  <div className='profile_img profile_no_img' onClick={handelProfileClick}></div>
                )}
                {!imgPrev && (
                  <div className='' onClick={handelProfileClick}>
                    {thumbs}
                  </div>
                )}

                <div {...getRootProps()} className='dragContainer' onClick={open}>
                  <input {...getInputProps()} />
                  <p style={{ marginLeft: '40px', paddingTop: '20px' }}>Drag profile image here</p>
                  {fileReject && <div style={{ color: 'red' }}>{fileRejectMsg}</div>}
                </div>
              </div>
            </div>

            <div className='field-and-icon'>
              <div className='field-icon-name left-icon'></div>
              <div className='form-field  icon-input-field'>
                <label className='form-input-label'>Title</label>
                <input
                  className='form-input'
                  type='text'
                  value={user_title}
                  onChange={validateTitle}
                />
                {/* {(titleValid === false) && (
                  <span className="error-message">{errorTitle}</span>
                )} */}
              </div>
            </div>

            <div className='field-and-icon'>
              <div className='field-icon-name left-icon'></div>
              <div className='form-field icon-input-field'>
                <div className='first-name'>
                  <label className='form-input-label'>First Name</label>
                  <input
                    className={fNameValid === false ? 'form-input error-border' : 'form-input'}
                    disabled={true}
                    style={{ color: '#9E9E9E' }}
                    type='text'
                    value={user_name_first}
                    onChange={validateFirstName}
                  />
                  {fNameValid === false && <span className='error-message'>{errorfName}</span>}
                </div>
                <div className='last-name'>
                  <label className='form-input-label'>Last Name</label>
                  <input
                    className={lNameValid === false ? 'form-input error-border' : 'form-input'}
                    disabled={true}
                    style={{ color: '#9E9E9E' }}
                    type='text'
                    value={user_name_last}
                    onChange={validateLastName}
                  />
                  {lNameValid === false && <span className='error-message'>{errorlName}</span>}
                </div>
              </div>
            </div>

            <div className='field-and-icon'>
              <div className='field-icon-email left-icon'></div>
              <div className='form-field  icon-input-field'>
                <label className='form-input-label'>E-mail</label>
                <input
                  disabled={true}
                  style={{ color: '#9E9E9E' }}
                  className={classApply}
                  type='email'
                  value={user_email}
                  onChange={handleEmail}
                />
                {errorMessage && <span className='error-message'>{errorMessage}</span>}
              </div>
            </div>

            <div className='field-and-icon'>
              <div className='field-icon-phone left-icon'></div>

              <div className='form-field icon-input-field'>
                <div className='country-code'>
                  <label className='form-input-label'>Country Code</label>
                  <input
                    disabled={true}
                    style={{ color: '#9E9E9E' }}
                    className='form-input'
                    value={country_code}
                  />
                  {/* {ccValid === false && (
                    <span className="error-message">{errorCountryCode}</span>
                  )} */}
                </div>
                <div className='phone-number'>
                  <label className='form-input-label'>Phone</label>
                  <input
                    disabled={true}
                    style={{ color: '#9E9E9E' }}
                    className={phoneValid === false ? 'form-input error-border' : 'form-input'}
                    type='number'
                    value={user_phone}
                    onChange={validatePhone}
                  />
                  {phoneValid === false && <span className='error-message'>{errorPhone}</span>}
                </div>
              </div>
            </div>
            <div className='field-and-icon'>
              <div className='field-icon-email left-icon'></div>
              <div className='form-field icon-input-field'>
                <div className=''>
                  <label className='form-input-label'>2FA Type</label>
                  <Dropdown
                    placeholder='Select Type'
                    className='form-input'
                    fluid
                    selection
                    options={twoFaTypes}
                    value={twoFaType}
                    onChange={(e, data) => {
                      const twoFaType: string = data.value as string;
                      setTwoFa(twoFaType);
                    }}
                  />
                </div>
              </div>
            </div>
            {twoFaType === 'Google Auth' && !googleAuthValid && (
              <div
                className='field-and-icon'
                style={{
                  display: qrcodeImg && !successMessage ? 'block' : 'none',
                  marginBottom: '150px',
                }}
              >
                <div className='field-icon-phone left-icon'></div>
                <div className='form-field icon-input-field'>
                  <div>
                    <label className='form-input-label'>
                      Scan the QR code and enter the OTP to verify
                    </label>
                  </div>
                  <div style={{ textAlign: 'center', width: '30%', float: 'left' }}>
                    <img alt='qrcodeImage' src={qrcodeImg} />
                  </div>
                  <div
                    style={{
                      width: '70%',
                      float: 'left',
                      marginTop: '30px',
                      padding: '15px',
                    }}
                  >
                    <input
                      className={otpValid === false ? 'form-input error-border' : 'form-input'}
                      type='text'
                      value={code}
                      onChange={validOtp}
                    />
                    {otpValid === false && <span className='error-message'>{errorOtp}</span>}
                    <div className='auth-button' style={{ marginTop: '20px' }}>
                      <Button
                        className='auth-violet-button'
                        color='violet'
                        onClick={validateOtp}
                        disabled={otpValid === false || code === ''}
                      >
                        Verify Code
                      </Button>
                    </div>
                  </div>
                  {otpErrorMessage && (
                    <div style={{ textAlign: 'center' }}>
                      <span className='alert-icon'></span>
                      <span className='error-message alert-text-message'>{otpErrorMessage}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* <div style={{ marginBottom: "25px", marginLeft: "37px" }}>
              <label className="form-input-label">Password Reset</label>

              <div>
                <i aria-hidden="true" className={iconName}></i>
                <span className={classApply0}>
                  MUST contain at least 12 characters
                </span>
              </div>
              <div>
                <i aria-hidden="true" className={iconName1}></i>
                <span className={classApply1}>
                  MUST contain at least one uppercase letter
                </span>
              </div>
              <div>
                <i aria-hidden="true" className={iconName2}></i>
                <span className={classApply2}>
                  MUST contain at least one lowercase letter
                </span>
              </div>
              <div>
                <i aria-hidden="true" className={iconName3}></i>
                <span className={classApply3}>
                  MUST contain at least one number
                </span>
              </div>
              <div>
                <i aria-hidden="true" className={iconName4}></i>
                <span className={classApply4}>
                  MUST contain at least one special character
                </span>
              </div>
            </div> */}

            {/* <div className="field-and-icon">
              <div
                className="field-icon-lock left-icon"
                style={{ marginBottom: "20px" }}
              ></div>
              <div className="form-field icon-input-field">
                <input
                  className="form-input"
                  placeholder="Password"
                  type={passwordShown ? "text" : "password"}
                  value={user_password}
                  onChange={handlePassword}
                />
                <Icon
                  name="eye slash"
                  size="large"
                  className="eye-icon-pwd"
                  onClick={togglePasswordVisiblity}
                />
              </div>
            </div>

            <div className="field-and-icon">
              <div className="field-icon-lock left-icon"></div>
              <div className="form-field icon-input-field">
                <label className="form-input-label">Confirm Password</label>
                <input
                  className="form-input"
                  placeholder="Password"
                  type={confirmPasswordShown ? "text" : "password"}
                  value={pwd_match}
                  onChange={handlePasswordMatch}
                />
                <Icon
                  name="eye slash"
                  size="large"
                  className="eye-icon-pwd"
                  onClick={toggleConfirmPasswordVisiblity}
                />
                <div>
                  <div className="error-message">{errorMessage1}</div>
                  <div className="success-message">{succeessMessage}</div>
                </div>
              </div>  
            </div> */}
            {successMessage && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <span className='success-message'>{successMessage}</span>
                </div>
              </>
            )}
            <div className='center-button'>
              <Button
                className='violet-button'
                color='violet'
                loading={loading}
                onClick={save}
                disabled={saveChangesValid()}
                style={{ left: '30%' }}
              >
                Save Changes
              </Button>
              <Button
                className='violet-button'
                color='violet'
                style={{ left: '30%' }}
                onClick={() => {
                  const user: any = auth.getUser();
                  const uid = get(user, 'uid', null);
                  navigateToPage('/showselection', {
                    state: { userId: uid },
                    replace: true,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
            <ConfirmationalPopup
              open={saveAlertOpen}
              data={'Are you sure you want to change ?'}
               onCancel={handleSaveCancel}
               onOk={handleSaveConfirm}
            />
            <ConfirmationalPopup
              open={removePicAlert}
              data={'Are you sure you want to Delete the Picture ?'}
              onCancel={handleRemoveCancel}
              onOk={handleRemoveConfirm}
            />
          </form>

          <div className='overlay' style={overlay.style}>
            <div className='overlay-image'>
              <img
                alt='SUCCESS'
                src={require('../../Images/icon_overlay.svg').default}
                height='80px'
                width='80px'
              />
              <br />
              <span className='overlay-text'>Profile has been updated</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Profile;
