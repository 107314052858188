import React from "react";
import PublisherPanel from "../PublisherPanel";
import GuestInvite from "../GuestInvite";

interface Props {
  handleLogoClick: any, renderHeaderTopVCControls: any, renderHeaderRightNavControls: any, showPublisherPanel: any, troom: any, usersUpdated: any, setUsersUpdated: any, roomParticipantsListRef: any, roomParticipantsConnectionStatusRef: any, setGuestUsersList: any, showGuestInvite: any,watermarkPanel:any
}

const HeaderView: React.FC<Props> = ({ handleLogoClick, renderHeaderTopVCControls, renderHeaderRightNavControls, showPublisherPanel, troom, usersUpdated, setUsersUpdated, roomParticipantsListRef, roomParticipantsConnectionStatusRef, setGuestUsersList, showGuestInvite,watermarkPanel}) => {

    return (
      <div className="topbar">
        {/* Left  Logo*/}
        {/* <div className="logo-viewer" onClick={handleLogoClick}></div> */}

        {/* Top VC Controls*/}
        <div className="centerMenuTop" style={!Object.keys(troom).length ?{pointerEvents:"none"}:{}}>
          {renderHeaderTopVCControls()}
        </div>

        {/* Right Nav Controls with Settings */}
        <div className="menuTopRight">{renderHeaderRightNavControls()}</div>

        {/*  Chat window */}


        {/*  Invited Users for Non Stream VC (Case-2)*/}
        {/* {renderVCInvitedUsersFrmHeader()} */}
      </div>
    );
};

export default HeaderView