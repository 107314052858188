import React, { useState, useEffect, useContext } from "react";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import UserMessage from "../../Components/Common/UserMessage";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import { PAGES, SHOWPUBLISHER_PAGES, STATUS } from "../../Utils/constants";
import { firstBy } from "thenby";
import { Mixpanel } from "../../Service/Mixpanel";
import ListStreamTable from "./ListStreamTable"; 
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import { searchClient } from "../../Service/show";

interface IListStreamsProps {
  showId: string;
  gotoCreateStream: any;
  setStreamUpdated: any;
  teamAdmin: boolean;
  usersGroupsFlag?:boolean;
}
const ListStreams = (props: IListStreamsProps) => {
  const currentUser = get(useContext(AuthContext), "currentUser", {} );
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [show_id, setShowId] = useState("");
  const [show_name, setShowName] = useState("");

  const [streams, setStreams] = useState([]);
  
  useEffect(() => {
    // Mixpanel
    searchClient.clearCache();
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name":
        PAGES.SHOW_PUBLISHER_PAGE + "_" + SHOWPUBLISHER_PAGES.LIST_STREAM,
    });

    const getStreams = async (show_id: string) => {
      try {
        const activeStreamData = {
          api: api.streams.listPublisherStreams,
          queryParam: { show_id },
        };
        setLoading(true);
        const streams = await backend.fetch(activeStreamData, get(currentUser, "user.accessToken", ""));
        console.log("getStreams", streams);
        return streams;
      } catch (err: any) {
        console.log("err", err);
        console.log("errMessage", err.errMessage);
        setTimeout(() => props.gotoCreateStream(), 3000);
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async (show_id: string) => {
      try {
        setSuccessMessage("");
        setErrorMessage("");

        const data = {
          api: api.shows.get,
          urlParam: show_id,
        };

        setLoading(true);
        const show: any = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);

        if (show) {
          setShowName(show.show_name);
        }

        const streams = await getStreams(show_id);

        if (streams && streams.length) {
          setStreams(streams);
          setSuccessMessage(`Successfully pulled active stream list`);
        } else {
          const errMessage = "No Streams found, Please create one !";
          const err = { errMessage };
          setTimeout(() => props.gotoCreateStream(), 3000);
          throw err;
        }
      } catch (err: any) {
        const msg = " Error while pulling streams !";
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };

    if (props.showId) {
      setShowId(props.showId);
      fetchData(props.showId);
    }
  }, [props.showId]);

  const handleDelete = async (deleteStream: any) => {
    setSuccessMessage("");
    setErrorMessage("");
    try {
      if (deleteStream) {
        const allStreams = streams;
        let index = allStreams.findIndex(
          (stream: any) => stream.id === deleteStream.id
        );
        const dStream: any = allStreams[index];
        dStream.status = STATUS.DELETED;
        dStream.token = "";
        dStream.stream_name = "";

        allStreams.sort(
          firstBy(function (v: any) {
            return v.stream_name.toLowerCase();
          }).thenBy(function (v: any) {
            return v.status.toLowerCase();
          })
        );
        setStreams(allStreams);
        setSuccessMessage(`Successfully deleted the stream`);

        Mixpanel.track("Publisher Stream Action", {
          Platform: "Web",
          "Stream Label": deleteStream.user_entered_stream_label,
          "Action Type": "DeleteStream",
        });
      } else {
        const msg = "Error while deleting stream !";
        setErrorMessage(msg);
      }
    } catch (err: any) {
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      }
    } finally {
      props.setStreamUpdated(true);
    }
  };


  return (
    <div className={`${props.usersGroupsFlag ? 'superadmin-list-streams' : 'list-pub-streams'}`}>
      <ProgressLoader loading={loading} />
      <UserMessage errorMsg={errorMessage} />

      <div className={`${props.usersGroupsFlag ? "superadmin-list-heading users-group-list" : 'list-heading'}`}>
        <p className={`heading-text ${props.usersGroupsFlag ? 'heading-text-new' : ''}`}>
          Active Stream For: <span className="grey-text">{show_name}</span>
        </p>
      </div>

      <div className="list-content">
        <ListStreamTable
          show_id={show_id}
          streams={streams}
          teamAdmin={props.teamAdmin}
          handleDelete={handleDelete}
          usersGroupsFlag={props.usersGroupsFlag}
        />
      </div>
    </div>
  );
};

export default ListStreams;
