import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Modal } from "semantic-ui-react";
import {
  ROLES,
  FEATURE_NAME,
  PAGES,
  SHOWADMIN_PAGES,
} from "../../../Utils/constants";
import api from "../../../Service/Api";
import { algoliaShowGroupUsers, searchClient } from "../../../Service/show";
import backend from "../../../Service/Backend";
import { get } from "../../../Utils/helpers";

import { Mixpanel } from "../../../Service/Mixpanel";
// import { isFeatureIncluded } from "../../../Utils/featureFlags";
import { isFeatureIncluded } from "../../../Service/show";
import OverLay from "../../../Components/Common/OverLay";

// CSS
import './style.css'
import { Button, Checkbox, Form } from "antd";
import InputComponent from "../../../Components/UIKit/InputComponent";
import { SelectPP } from "../../../Components/UIKit/Select";
import { AuthContext } from '../../../Context/authContext';
import ConfirmationModal from "../../../Components/UIKit/ConfirmationModal/ConfirmationModal";

interface IProps {
  showId: string;
  selectedUser: any;
  setUserUpdated: any;
  currentShow?: any;
  currentConfig?: any;
  setLoading: any;
  pageName?: any;
  configValue?: any;
  licenseMangerFlag?: boolean;
  show_admin_id?: any;
  setInvitedUsers: any;
  invitedUsers: any;
  listShowGroup?: any[];
  isUserGroup?: any;
  fetchShowGroupUsers?: any;
  usersGroupsFlag?:boolean;
  show_admin?:any;
}

const EditPublisherViewer = (props: IProps) => {
  const { invitedUsers, setInvitedUsers } = props;

  const currentUser = get(useContext(AuthContext), "currentUser", {} );

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [overlayMsg, setOverlaymsg] = useState("");

  const [user_id, setUserId] = useState("");
  const [user_title, setUserTitle] = useState("");
  const [user_name_first, setFirstName] = useState("");
  const [user_name_last, setLastName] = useState("");
  const [user_email, setEmail] = useState("");
  const [user_phone, setPhone] = useState("");
  const [comments, setComments] = useState("");
  const [isPrevPublisher, setIsPrevPublisher] = useState(false);

  const [show_id, setShowId] = useState("");

  const [roles, setRoles] = useState({
    [ROLES.SHOW_VIEWER]: false,
    [ROLES.SHOW_PUBLISHER]: false,
  });
  const [isViewerChecked, setViewerCheck] = useState(false);
  const [isPublisherChecked, setPublisherCheck] = useState(false);
  const [isGuestInviteChecked, setGuestInviteCheck] = useState(false);

  const [configEnabled, setConfigEnabled] = useState(false);
  const [configActualValue, setConfigActualValue] = useState(0);
  const [newConfigValue, setNewConfigValue] = useState(0);
  const [showConfigId, setShowConfigId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [emailValid, setEmailValid] = useState(true);
  const [titleValid, setTitleValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [fNameValid, setfNameValid] = useState(true);
  const [lNameValid, setlNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [checkboxValid, setCheckboxValid] = useState(true);

  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [ccValid, setCCValid] = useState(true); // cc --> Country Code
  const [errorCountryCode, setErrorCountryCode] = useState("");
  const [user_country_code, setUserCountryCode] = useState("");

  const [errorEmail, setErrorEmail] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorfName, setErrorfName] = useState("");
  const [errorlName, setErrorlName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorCheckbox, setErrorCheckbox] = useState("");

  const [isGuestInviteIncluded, setGuestInviteIncluded] = useState(false);

  const [isUserTeamAdmin, setTeamAdmin] = useState(false);
  const [activePub, setActivePub] = useState(false);
  const [activePublisher, setActivePublisher] = useState(false);
  const [showRequestPub, setShowRequestPub] = useState(false);
  const [showname, setShowname] = useState("");

  const [user_group, setUserGroup] = useState([] as any);
  const [uniqueUserGroup, setUniqueUserGroup] = useState([] as any);
  const [isListShowGroup, setIsShowListGroup] = useState([] as any);

  const { selectedUser } = props
  const [isDeleteActiveStreamPub, setDeleteActiveStreamPub] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);


  useEffect(() => {
    searchClient.clearCache();
    // Mixpanel
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SHOW_ADMIN_PAGE + "_" + SHOWADMIN_PAGES.EDIT_USER,
    });

    const fetchAllCountryCodes = async () => {
      try {
        const countryCodesData = {
          api: api.countryCodes.listAll,
        };
        return await backend.fetch(countryCodesData, get(currentUser, "user.accessToken", ""));
      } catch (error: any) {
        console.log(error.message);
      } finally {
        props.setLoading(false);
      }

    };
    if (props.showId && props.showId !== show_id) {
      setShowId(props.showId);
      deleteActiveStreamFlag();
      algoliaFeatureFlags();
    }
    if (props.selectedUser) {
      const { country_code, user_phone } = props.selectedUser;
      setUserData();
      let countryCode: any;
      if (country_code) {
        countryCode = country_code ? country_code : "+1";
      } else if (user_phone?.country_code) {
        countryCode = user_phone?.country_code ? user_phone?.country_code : "+1";
      }

      if (!countryCodes || countryCodes.length < 1) {
        fetchAllCountryCodes().then((data) => {
          setCountryCodes(data);
          const currCountry: any = data.find(
            (cc: any) => cc.code === countryCode
          );
          // setCountryCode(get(currCountry, "value", ""));
          setCountryCode(country_code);

        });
      } else {
        const currCountry: any = countryCodes.find(
          (cc: any) => cc.code === countryCode
        );
        // setCountryCode(get(currCountry, "value", ""));
        setCountryCode(country_code);
        console.log('code 2 :>> ', countryCode);

      }

      if (props.showId && props.selectedUser.user_id && user_id !== props.selectedUser.user_id) {
        fetchAllUserRoles(props.showId, props.selectedUser.user_id);
        fetchActivePub(props.showId)
      }
    }
    if (props.listShowGroup) {

      // update list
      const updateShowGroupList = props.listShowGroup.map(({
        show_group_id: value,
        show_group_name: label,
        show_group_status
      }) => ({
        value,
        label,
        // label:value,
        show_group_status
      }));

      const {
        user_title,
        user_email,
        user_id,
        user_name,
        country_code,
      } = props.selectedUser;
      console.log('===show group', props.listShowGroup)
      // console.log('===show group changes', user_group)
      console.log('selected user infor :>> ', props.selectedUser);

      setIsShowListGroup(updateShowGroupList)


      // let result = updateShowGroupList.filter((x:any) => )

      // setUniqueUserGroup

    }
  }, [props.showId, props.selectedUser, props.listShowGroup]);

  const algoliaFeatureFlags = async () => {
    const result = await isFeatureIncluded(FEATURE_NAME.ALLOW_GUEST_INVITE, props.showId)
    await searchClient.clearCache();
    if (result) {
      setGuestInviteIncluded(true);
    }
  }


  const deleteActiveStreamFlag = async () => {
    const result = await isFeatureIncluded(FEATURE_NAME.ALLOW_DELETE_ACTIVE_STREAM, props.showId)
    await searchClient.clearCache();
    if (result) {
      setDeleteActiveStreamPub(true);
    }
  }

  const removeLicenseMail = async () => {
    try {
      const remove_name = `${user_name_first} ${user_name_last}`;
      const input = {
        show_id: show_id,
        remove_name,
        show_name: props.currentShow.show_name,
        show_admin: props.show_admin,
        license_count: 1,
      };
      console.log("input new", input);
      const sendEmail = {
        api: api.users.removeLicense,
        payLoad: JSON.stringify(input),
      };
      await backend.save(sendEmail, get(currentUser, "user.accessToken", ""));
    } catch (err) {
      console.log("err", err);
    }
  };

 const reducePubCount = async (totalPublisher: number ,config_value:number,show_config_id:string) => {
    try {
   
      const configInput = {
        show_id: show_id,
        show_config_id: show_config_id,
        config_value: config_value - 1,
        config_actual_value: totalPublisher - 1,
      };
      console.log("configInput:: new ", configInput);
      const config = {
        api: api.configs.updateShowConfigs,
        payLoad: JSON.stringify(configInput),
      };
      console.log("config::", config);
      await backend.save(config, get(currentUser, "user.accessToken", ""));
    } catch (err: any) {
      console.log("err", err);
    }
  };

  const fetchActivePub = async (showId: string) => {

    const getActiveStreamList = {
      api: api.streams.listAllActive,
      urlParam: showId
    };
    const listOfStreams = await backend.fetch(getActiveStreamList, get(currentUser, "user.accessToken", ""));
    const index = listOfStreams.findIndex((user: any) => user.stream_status === "Active" && user.created_user_email === props.selectedUser.user_email);

    setActivePub(index > -1 ? true : false);
    setActivePublisher(false)

  }

  const fetchAllUserRoles = async (show_id: string, user_id: string) => {
    try {
      props.setLoading(true);
      setErrorCheckbox("");
      const allUserRoles: any[] = await backend.fetch({
        api: api.users.getAllUserRole,
        urlParam: user_id,
        queryParam: { show_id },
      }, get(currentUser, "user.accessToken", ""));
      //Filter the role names from all role
      const filterUserRole = allUserRoles
        .filter(
          (role) =>
            role.show_id === show_id &&
            [ROLES.SHOW_VIEWER, ROLES.SHOW_PUBLISHER].includes(role.role_type)
        )
        .map((role) => role.role_type);

      //Role flag set
      const isRolePublisher = filterUserRole.includes(ROLES.SHOW_PUBLISHER);
      const isRoleViewer = filterUserRole.includes(ROLES.SHOW_VIEWER);

      if (isRolePublisher) {
        for (const role of allUserRoles) {
          if (role && role.role_type === ROLES.SHOW_PUBLISHER && role.show_id === show_id) {
            setTeamAdmin(role.team_admin);
            setGuestInviteCheck(role.guest_invite);
          }
        }
      }

      setPublisherCheck(isRolePublisher);
      setViewerCheck(isRoleViewer);
      setIsPrevPublisher(isRolePublisher);
      setRoles({
        [ROLES.SHOW_PUBLISHER]: isRolePublisher,
        [ROLES.SHOW_VIEWER]: isRoleViewer,
      });
    } catch (error: any) {
      console.log(error.message);
    } finally {
      props.setLoading(false);
    }
  };
  // const algoliaFetchShowGroupUsers = async () => {
  //   setUserGroup([])

  //   try {
  //     if (!props.showId) return
  //     let activeUsers = await algoliaShowGroupUsers(props.showId, selectedUser.user_id);
  //     console.log('activeUsers :>> ', activeUsers);

  //     const newArray = activeUsers?.hits.map((item: any, index: any) => {
  //       return {
  //         ...item,
  //         key: index + 1,
  //       };
  //     });

  //     const result = newArray?.filter((x: any) => x.user_id === selectedUser.user_id);

  //     const listUsersGroup = result?.map(({
  //       show_group_id: value,
  //       group_name: label
  //     }: any) => ({
  //       value,
  //       label
  //     }));

  //     console.log('listUsersGroup :>> ', listUsersGroup);
  //     setUserGroup(listUsersGroup);

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const getShowGroupsByShowIdUserId = async() => {
    try{
      const input = {
        api: api.group.listGroupsByShowIdUserId,
        urlParam: props.showId,
        queryParam: { user_id: selectedUser.user_id },
      };

      const getShowGroups = await backend.fetch(input, get(currentUser, "user.accessToken", ""));

      const listUsersGroup = getShowGroups?.map(({
        show_group_id: value,
        group_name: label
      }: any) => ({
        value,
        label
      }));

      setUserGroup(listUsersGroup);
    } catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // algoliaFetchShowGroupUsers();
    getShowGroupsByShowIdUserId();
    console.log('user_group ok here  useeffect:>> ', selectedUser);
  }, [selectedUser.user_id])

  const setUserData = () => {
    // console.log('set data')
    const {
      user_title,
      user_email,
      user_id,
      user_name,
      country_code,
      user_phone,
      comments,
      guest_invite = false,
      team_admin = false,
    } = props.selectedUser;

    console.log('country_code :>> ', country_code);


    let user_name_first,
      user_name_last = "";
    if (user_name && user_name.user_name_first) {
      user_name_first = user_name.user_name_first;
    }
    if (user_name && user_name.user_name_last) {
      user_name_last = user_name.user_name_last;
    }

    let phone_no: any;
    if (user_phone) {
      phone_no = user_phone.user_phone
        ? user_phone.user_phone
        : user_phone;
    }

    let countryCode: any;
    if (country_code) {
      countryCode = country_code;
    } else if (user_phone) {
      countryCode = user_phone?.country_code ? user_phone?.country_code : "+1";
    }

    setUserId(user_id);
    setUserTitle(user_title ? user_title : "");
    setFirstName(user_name_first);
    setLastName(user_name_last);
    setEmail(user_email);
    setPhone(phone_no);
    setComments(comments || "");
    setUserCountryCode(countryCode);
    setGuestInviteCheck(guest_invite);
    setTeamAdmin(team_admin);
    setErrorMessage("");
    setCountryCode(country_code)

    // console.log('object data :>> ',
    // user_title, 
    // user_name_first,
    // user_name_last,
    // user_email,
    // phone_no,
    // countryCode
    // );
    // console.

  };

  const clearFormFields = () => {
    setUserTitle("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setCountryCode("");
    setPhone("");
    setComments("");
    setRoles({
      [ROLES.SHOW_VIEWER]: false,
      [ROLES.SHOW_PUBLISHER]: false,
    });
    setUserGroup([])
    setViewerCheck(false);
    setPublisherCheck(false);
    setGuestInviteCheck(false);
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const showOverlayForThreeSeconds = async () => {
    //props.setLoading(false);
    setOverlayDisplay(true);
    await sleep(3000);
    setOverlayDisplay(false);
  };

  const publisherExists = (arrayOfPublishers: any, publisherId: any) => {
    return arrayOfPublishers.some((objEle: any) => {
      return objEle.user_id === publisherId
    })
  }

  const hideShowPubRequest = () => {
    setShowRequestPub(false)
    props.pageName("ManageLicenseViewer")

  }

  const getActiveShowPublishersList = async (getActiveShowPublishers: any) => {
    try {

      const result = await backend.fetch(
        getActiveShowPublishers, get(currentUser, "user.accessToken", "")
      );
      return result;
    } catch (err: any) {
      console.log("err", err);
      return [];
    }
  }

  const update = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage("");
      setErrorMessage("");
      props.setLoading(true);

      // No show
      if (!show_id) {
        console.log("No show");
        throw new Error("No Show !");
      }

      if (!roles.ShowPublisher && !roles.ShowViewer) {
        console.log("No role");
        throw new Error("No Role !");
      }
      // Validate publisher count limit
      if (!!props.currentShow) {
        setShowname(props.currentShow.show_name)
        const { parent_show_id } = props.currentShow;
        const currentConfig = props.currentConfig;
        try {
          const getShowsConfig = {
            api: api.configs.listShowConfigsByShowId,
            urlParam: !!parent_show_id ? parent_show_id : show_id,
            queryParam: { config_name: "publisher_count" },
          };

          const getActiveShowPublishers = {
            api: !!parent_show_id
              ? api.users.listActiveChildShowPublisherInShow
              : api.users.listActiveShowPublisherInShow,
            urlParam: !!parent_show_id ? parent_show_id : show_id,
          };

          const showConfigDetails = await backend.fetch(getShowsConfig, get(currentUser, "user.accessToken", ""));
          const listOfShowPublishersInShow = await backend.fetch(
            getActiveShowPublishers, get(currentUser, "user.accessToken", "")
          );
          const totalPublisher = listOfShowPublishersInShow.length;
          const mergeConfig = showConfigDetails.map((showConfig: any) => {
            const filterConfig = currentConfig.filter(
              (config: any) => config["id"] === showConfig["config_id"]
            );
            showConfig["config_enable"] =
              filterConfig.length > 0 && !!filterConfig[0]["config_enable"];
            return showConfig;
          });
          if (mergeConfig.length > 0) {
            setConfigEnabled(mergeConfig[0].config_enable);
            setConfigActualValue(totalPublisher);
            setNewConfigValue(mergeConfig[0].config_value);
            setShowConfigId(mergeConfig[0].id);
            let publisherExistsFlag = publisherExists(listOfShowPublishersInShow, user_id);

            if (
              !!mergeConfig[0].config_enable &&
              !!isPublisherChecked &&
              mergeConfig[0].config_value > 0 &&
              (listOfShowPublishersInShow.length + 1) > mergeConfig[0].config_value &&
              !publisherExistsFlag
            ) {
              setModalOpen(true);
              setPublisherCheck(false);
              setRoles({
                ...roles,
                [ROLES.SHOW_PUBLISHER]: false,
              });
              return false;
            }
          }
          if (roles.ShowPublisher === true && props.licenseMangerFlag === true) {
            const historyPayload = {
              show_name: props.currentShow.show_name,
              show_id: props.showId,
              user_id: props.show_admin_id,
              action: "ADD_PUBLISHER_LICENSE",
              action_userid: user_id,
              action_time: new Date()
            }
            const historyData = {
              api: api.licenseHistory.addPublisherLicense,
             // urlParam: user_id,
              payLoad: JSON.stringify(historyPayload),
            };
            await backend.save(historyData, get(currentUser, "user.accessToken", ""));

          } else if (roles.ShowPublisher === false && roles.ShowPublisher !== isPrevPublisher && props.licenseMangerFlag === true) {
            const historyPayload = {
              show_name: props.currentShow.show_name,
              show_id: props.showId,
              user_id: props.show_admin_id,
              action: "REMOVE_PUBLISHER",
              action_userid: user_id,
              action_time: new Date()
            }
            const historyData = {
              api: api.licenseHistory.removePublisherLicense,
             // urlParam: user_id,
              payLoad: JSON.stringify(historyPayload),
            };
            await backend.save(historyData, get(currentUser, "user.accessToken", ""));
            if (isDeleteActiveStreamPub && props.licenseMangerFlag === true && activePublisher) {
              await removeLicenseMail();
              await reducePubCount(totalPublisher, mergeConfig[0].config_value, mergeConfig[0].id);
            }
          }
        } catch (err: any) {
          console.log("err", err);
        } finally {
          //props.setLoading(false);
        }
      }

      // Call Backend to update user details
      const input = {
        user_title,
        user_name_first,
        user_name_last,
        user_email,
        show_id,
        roles,
        comments,
        show_group_ids: isViewerChecked ? user_group || [] : [],
        guest_invite: isGuestInviteChecked,
      };

      console.log("input for update : ", user_group);

      const data = {
        api: api.users.adminuseredit,
        urlParam: user_id,
        payLoad: JSON.stringify(input),
      };

      await backend.save(data, get(currentUser, "user.accessToken", ""));
      await searchClient.clearCache();
      const allUserRoles: any[] = await backend.fetch({
        api: api.users.getAllUserRole,
        urlParam: user_id,
        queryParam: { show_id },
      }, get(currentUser, "user.accessToken", ""));
      const filterInvitedUsers = invitedUsers.filter((user: any) => user.user_id !== user_id)
      const filterUserRole = allUserRoles
        .filter(
          (role) =>
            role.show_id === show_id &&
            [ROLES.SHOW_VIEWER, ROLES.SHOW_PUBLISHER].includes(role.role_type)
        )
      for (const val of filterUserRole) {
        filterInvitedUsers.push(val);
      }
      setInvitedUsers(JSON.parse(JSON.stringify(filterInvitedUsers)));

      setSuccessMessage(`Updated user`);
      setOverlaymsg(
        `User ${user_name_first + " " + user_name_last
        } details have been updated`
      );

      props.fetchShowGroupUsers();
      
      Mixpanel.track("Admin User Action", {
        Platform: "Web",
        "Action Email": user_email,
        "Action Type": "EditUser",
        "Admin Type": "ShowAdmin",
      });
      clearFormFields();
      props.setLoading(false);
      await showOverlayForThreeSeconds();
    } catch (err: any) {
      const msg = "Error while updating user !";
      if (err.errMessage) {
        if (err.errMessage === "TOO_SHORT" || err.errMessage === "TOO_LONG") {
          setErrorMessage(`Given mobile number is ${err.errMessage}`);
        } else {
          setErrorMessage(err.errMessage);
        }
      } else {
        setErrorMessage(msg);
      }
    } finally {
      if (props.selectedUser) {
        // console.log('object :>> ', props.selectedUser);

        await setUserData();
        await getShowGroupsByShowIdUserId();
        await fetchAllUserRoles(props.showId, props.selectedUser.user_id);
        //props.setLoading(false);
      }

    }
  };

  const handelRole = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCheckbox("");
    console.log('handle role works User and groups')
    const { value, checked } = event.target;

    if (value === ROLES.SHOW_PUBLISHER) {
      if (!checked) {
        if (isDeleteActiveStreamPub) {
          setErrorCheckbox("");
          setShowConfirmationModal(true);
        }
        else {
          setGuestInviteCheck(false);
        }
      }
      setPublisherCheck(!!checked);
      setRoles({
        ...roles,
        [ROLES.SHOW_PUBLISHER]: !!checked,
      });

      setActivePublisher(activePub && checked === false ? true : false);
      setPublisherCheck(activePub === true ? true : !!checked);
      //publisher count and lmflag conditions
      if (props.configValue === 0 && props.licenseMangerFlag === true && checked === true) {
        setShowRequestPub(true)
        setPublisherCheck(false);
        setRoles({
          ...roles,
          [ROLES.SHOW_PUBLISHER]: false,
        });
      } else if (props.configValue === 0 && props.licenseMangerFlag === false && checked === true) {
        setShowRequestPub(false)
        setPublisherCheck(true);
        if (isGuestInviteIncluded) {
          setGuestInviteCheck(true);
        }
      } else if (props.configValue > 0 && checked === true) {
        setShowRequestPub(false)
        setPublisherCheck(true);
        if (isGuestInviteIncluded) {
          setGuestInviteCheck(true);
        }
      }
    } else if (value === ROLES.SHOW_VIEWER) {
      setViewerCheck(!!checked);
      setRoles({
        ...roles,
        [ROLES.SHOW_VIEWER]: !!checked,
      });
    } else if (value === "AllowGuestInvite") {
      setGuestInviteCheck(!!checked);
    }

    //Handle the validation from current value and previous state
    const viewerStatus =
      value === ROLES.SHOW_VIEWER ? !!checked : isViewerChecked;
    const publisherStatus =
      value === ROLES.SHOW_PUBLISHER ? !!checked : isPublisherChecked;

    setCheckboxValid(!!viewerStatus || !!publisherStatus);
    setErrorCheckbox(
      !!viewerStatus || !!publisherStatus ? "" : "Please check your role"
    );

  };

  //Separate functionality when we have deleteActiveStream = true for a active publisher
  const handleConfirmation = (value: any) => {
    if (value) {
      setGuestInviteCheck(false);
      setPublisherCheck(false);
      setRoles({
        ...roles,
        [ROLES.SHOW_PUBLISHER]: false,
        [ROLES.SHOW_VIEWER]: true,
      });
      setErrorCheckbox("");
      setViewerCheck(true);
      setCheckboxValid(true);
    }
    setShowConfirmationModal(false);
  };

  const handleCancelConfirmation = (value: any) => {
    setPublisherCheck(true);
    setErrorCheckbox("");
    setCheckboxValid(true);
    setRoles({
      ...roles,
      [ROLES.SHOW_PUBLISHER]: true,
    });
    setShowConfirmationModal(value)
  };

  const validateEmail = (event: any) => {
    setEmail(event.target.value);
    let validateEmail = event.target.value;
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail("Please enter email");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail("Invalid email format");
    } else {
      setEmailValid(true);
      setErrorEmail("");
    }
  };

  const validateTitle = (event: any) => {
    setUserTitle(event.target.value);
  };

  const validateFirstName = (event: any) => {
    setFirstName(event.target.value);
    let validatefName = event.target.value;
    if (!validatefName) {
      setfNameValid(false);
      setErrorfName("Please enter your first name");
    } else {
      setfNameValid(true);
      setErrorfName("");
    }
  };

  const validateLastName = (event: any) => {
    setLastName(event.target.value);
    let validatelName = event.target.value;
    if (!validatelName) {
      setlNameValid(false);
      setErrorlName("Please enter your last name");
    } else {
      setlNameValid(true);
      setErrorlName("");
    }
  };
  var formatPhoneNumber = function formatPhoneNumber(phoneNumberString: any) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  const validatePhoneText = (data: any) => {
    const currCountry: any = countryCodes.find(
      (cc: any) => get(cc, "value", "") === data.value
    );

    if (data && currCountry) {
      if (
        user_phone &&
        !currCountry?.ignore_count &&
        user_phone.length !== currCountry.count
      ) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone("");
      }
    }
  };

  const regexPhoneNumber = (str: any) => {
    const regexPhoneNumber = /^((\+)33|0)[1-9](\d{2}){4}$/;

    if (str.match(regexPhoneNumber)) {
      return true;
    } else {
      return false;
    }
  }

  const validatePhone = (event: any) => {
    setPhone(event.target.value);
    let validatePhone = event.target.value;

    if (!validatePhone) {
      setPhoneValid(false);
      setErrorPhone("Please enter phone number");
    } else if (countryCode) {
      const currCountry: any = countryCodes.find(
        (cc: any) => get(cc, "value", "") === countryCode
      );
      const regexString = "^\\d{" + currCountry.count + "}$";
      const regex = new RegExp(regexString);
      // /////////

      var cleaned = ("" + validatePhone).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);


      // if (match) {
      //   return "(" + match[1] + ") " + match[2] + "-" + match[3];
      // }

      if (
        countryCode === get(currCountry, "value", "") &&
        !currCountry?.ignore_count &&
        !regex.test(validatePhone)
      ) {
        setPhoneValid(false);
        setErrorPhone(`Please enter ${currCountry.count} digit phone no`);
      } else {
        setPhoneValid(true);
        setErrorPhone("");
      }
    } else if (!countryCode && !regexPhoneNumber(validatePhone)) {
      setPhoneValid(false);
      setErrorPhone("Please enter 10 digit phone no");
    } else {
      setPhoneValid(true);
      setErrorPhone("");
    }
  };

  const handleShowGroupChange = (value: any) => {
    // console.log(`show group val ${value}`);
    setUserGroup(value)
  };

  const validateCountryCode = (event: any, data: any) => {
    console.log(event, data)
    if (!data.value) {
      setCCValid(false);
      setErrorCountryCode("Please select country code");
    } else {
      setCCValid(true);
      setErrorCountryCode("");
      validatePhoneText(data);
    }
  };
  const searchCountryCode = (value: any) => {
    // const { value } = event.target;
    // console.log('check country', value)
    if (value === "") {
      // const countryCodes = props.countryCodes.sort((a: any, b: any) => get(a, "value", "").localeCompare(get(b, "value", "")));
      // setCountryCodes(countryCodes)


    } else {
      const lower_val = value.charAt(0).toLowerCase();

      const startsWith = countryCodes.filter((code: any) => code.value.toLowerCase().indexOf(lower_val) === 0);
      setCountryCodes(startsWith)
      // console.log('check starts', startsWith)

    }
  };

  const handleCountryCodeChange = (e: any, data: any) => {
    // console.log(`selected ${data.value}`);

    setCountryCode(data.value);
    // const currCountry: any = props.countryCodes.find(
    //   (cc: any) => get(cc, "value", "") === data.text
    // );
    // setUserCountryCode(currCountry.code);
    // validateCountryCode(e, data);
  };
  // console.log('object 2:>> ',
  //   user_phone,
  //   user_email,
  //   comments,
  //   user_name_first,
  //   user_name_last,
  //   countryCode
  // );
  const isGroupSelection = () => {
    let selection = true;
    if (isViewerChecked && isPublisherChecked) {
      return !selection
    }
    else if (isPublisherChecked) {
      return selection
    }
    return !selection
  }

  const isGroupActive = isGroupSelection()

  return (
    <div className="createshowadmin">
      <div className="heading margin-0 ">
        <p className="heading-text-center">{user_name_first}'s Information</p>
        {isUserTeamAdmin && (
          <div>
            <p>* User is a Team Admin</p>
          </div>
        )}
        <hr className="top" />
      </div>
      <div className="form-content margin-left-0">
        <>
          <Form
            name="form"

            layout="vertical">
            <div className="-form-wrapper">
              <Form.Item
                name="user_title"
                label="TITLE"
                rules={[
                  { required: true, message: <span className="error-message">Please Enter Title</span> },
                  { max: 20 },
                ]}
              >
                <>
                  <InputComponent
                    disabled
                    key={"title"}
                    type="text"
                    value={user_title}
                    onChange={validateTitle}
                  />
                </>
              </Form.Item>
              <Form.Item
                name="email"
                label="EMAIL"
                rules={[
                  { required: true },
                  {
                    validator: async (_: any, val: any) => {
                      let validEm = val.trim();
                      if (validEm === "") {
                        setEmailValid(false);
                        return Promise.reject(
                          <span className="error-message">Please Enter Email</span>
                        );
                      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validEm)) {
                        setEmailValid(false);
                        return Promise.reject(
                          <span className="error-message">Invalid Email Format</span>
                        );
                      } else {
                        setEmailValid(true);
                        setErrorEmail("");
                        Promise.resolve()
                      }
                    },
                  },
                  { max: 35 },
                ]}
              >
                <>
                  <InputComponent
                    disabled
                    key={"email"}
                    type="email"
                    maxLength="35"
                    value={user_email}
                    onChange={validateEmail}
                  />
                </>
              </Form.Item>
            </div>
            <div className="-form-wrapper">
              <Form.Item
                name="firstname"
                label="FIRST NAME"
                rules={[
                  { required: true, message: <span className="error-message">Please Enter Your First Name</span> },
                  { max: 20 },
                ]}
              >
                <>
                  <InputComponent
                    disabled
                    key={"firstname"}
                    type="text"
                    value={user_name_first}
                    onChange={validateFirstName}
                  />
                </>
              </Form.Item>
              <Form.Item
                label="LAST NAME"
                name="lastname"
                rules={[
                  { required: true, message: <span className="error-message">Please Enter Your Last Name</span> },
                  { max: 20 },
                ]}
              >
                <>
                  <InputComponent
                    key={"lastname"}
                    disabled
                    type="text"
                    value={user_name_last}
                    onChange={validateLastName}
                  />
                </>
              </Form.Item>
            </div>
            <div className="-form-wrapper">
              <Form.Item name="countryCode" label="COUNTRY CODE" rules={[{ required: true }]}>
                <>
                  <InputComponent
                    disabled
                    key="country_code"
                    type="text"
                    value={countryCode}
                  />
                  {phoneValid === false && (
                    <span className="error-message">{errorPhone}</span>
                  )}
                </>
              </Form.Item>
              <Form.Item
                label="PHONE"
                name="phone"
                rules={[
                  { required: true, message: <span className="error-message">Please Enter Phone Number</span> },
                  { max: 20 },
                ]}
              >
                <>
                  <InputComponent
                    disabled
                    key="phone"
                    type="tel"
                    value={user_phone}
                    onChange={validatePhone}
                  />
                  {phoneValid === false && (
                    <span className="error-message">{errorPhone}</span>
                  )}
                </>
              </Form.Item>
            </div>
            <div className="-form-wrapper">
              <Form.Item
                label="NOTE"
                //  rules={[
                //   { required: true, message: "Please Enter " },
                //   { max: 30 }
                // ]}
                name="note">
                <>
                  <InputComponent
                    disabled
                    key={"note"}
                    placeholder="Notes goes here"
                    type="text"
                    rows={2}
                    isTextArea
                    value={comments}
                    onChange={(value: any) => setComments(value)}
                  />
                </>
              </Form.Item>
                <Form.Item
                  name="group">
                  <>
                    <SelectPP
                      key={user_group.length}
                      disabled={isGroupActive}
                      defaultValue={user_group && user_group}
                      entry={isListShowGroup}
                      mode='multiple'
                      label="GROUP NAME"
                      // value={user_group}
                      onChange={handleShowGroupChange}
                    />
                  </>
                  
                </Form.Item>
            </div>
            <div className="checkbox-wrapper">
              <Checkbox
                value={ROLES.SHOW_VIEWER}
                checked={isViewerChecked}
                onChange={(e: any) => handelRole(e)}
              >
                Viewer
              </Checkbox>
              <Checkbox
                disabled={isUserTeamAdmin || ( activePublisher && !isDeleteActiveStreamPub)}

                value={ROLES.SHOW_PUBLISHER}
                checked={isPublisherChecked}
                onChange={(e: any) => handelRole(e)}
              >
                Publisher
              </Checkbox>
              {isPublisherChecked && isGuestInviteIncluded && (
                <Checkbox
                  value="AllowGuestInvite"
                  checked={isGuestInviteChecked}
                  onChange={(e: any) => handelRole(e)}
                >
                  Allow Guest Invite
                </Checkbox>
              )}

            </div>
            <div className="text-center" style={{ margin: "8px" }}>
            {checkboxValid === false && (
              <span className="error-message">{errorCheckbox}</span>
            )}
              {isDeleteActiveStreamPub && activePublisher ? (
                <ConfirmationModal
                  onOk={() => handleConfirmation(true)}
                  onCancel={() => handleCancelConfirmation(false)}
                  data={props.licenseMangerFlag === true
                    ? "The publisher is having an active stream. Are you sure, do you want to remove the publisher license ?"
                    : "The publisher is having an active stream. Are you sure, do you want to remove the publisher role ?"}
                  open={showConfirmationModal}
                />
              ) : (
              <>
                {activePublisher && props.licenseMangerFlag === true ? (
                  <span className="error-message">
                    Can’t remove this publisher license because the user has an active stream.
                    Please have the publisher delete the stream before re-assigning the license.
                  </span>
                ) : activePublisher && props.licenseMangerFlag === false ? (
                  <span className="error-message">
                    Can’t remove this publisher because the user has an active stream.
                    Please have the publisher delete the stream before removing.
                  </span>
                ) : (
                  <span className="error-message"></span>
                )}
              </>
            )}
          </div>
            <div className="text-center" style={{ margin: "20px" }}>
              <span className="copy-text">If invited user already has an active account they will not receive an e-mail, only new users will get an e-mail invitation to set up an account</span>
            </div>

            <div className="btn-wrapper">
              <Button
                type="primary"
                className="invite-btn"
                // @ts-ignore
                onClick={update}
                disabled={
                  fNameValid === false ||
                  user_name_first === "" ||
                  lNameValid === false ||
                  user_name_last === "" ||
                  emailValid === false ||
                  user_email === "" ||
                  ccValid === false ||
                  // country_code === "" ||
                  // phoneValid === false ||
                  user_phone === "" ||
                  checkboxValid === false ||
                  (!isDeleteActiveStreamPub && activePublisher && (props.licenseMangerFlag === true || props.licenseMangerFlag === false)) ||
                  (isPublisherChecked === false && isViewerChecked === false)
                }
              >
                Update User
              </Button>
            </div>
            {errorMessage && (
              <>
                <div className="flex">
                  <span className="alert-icon"></span>
                  <span className="error-message alert-text-message">
                    {errorMessage}
                  </span>
                </div>
              </>
            )}
          </Form>
        </>
        <Modal
          open={modalOpen}
          size={"tiny"}
          onClick={() => setModalOpen(false)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Publisher Limit Reached</Modal.Header>
          <Modal.Content>
            You have already assigned ({newConfigValue}) publishers. Please remove
            one or purchase another license to add another
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={() => setModalOpen(false)}>
              CLOSE
            </Button>
          </Modal.Actions>
        </Modal>
        {showRequestPub &&
          <Modal
            open={showRequestPub}
            size={"tiny"}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            className="licence-modal"
            closeIcon
            onClose={() => setShowRequestPub(false)}
          >
            <Modal.Content className="licence-content">
              <div className="mlv-viewer-license-content">
                As there is no Publisher, Please request licenses from License Manager and then add users to the show.
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button className="confirm-button" onClick={() => setShowRequestPub(false)}>
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
        }

        <OverLay overlayMsg={overlayMsg} display={overlayDisplay} />
      </div>
    </div>

  );
};

export default EditPublisherViewer;
