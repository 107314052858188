import { message } from 'antd';
import useDbTrigger from '../../hooks/useDbTrigger';
import refreshIcon from '../../Images/reload.svg';
import { get } from '../../Utils/helpers';
let streamingStatus = false;

interface ReloadProps {
  showDomSpeaker: any;
  reloadPage: any;
  troom: any;
  activeStreamStatus: boolean;
  setActiveStreamStatus: (value: boolean) => void;
  isMultiStreamEnabled: boolean;
  reloadSubStreams: any;
  miniscreen:boolean
}
export default function Reload(props: ReloadProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const vcRoomNotifications = useDbTrigger('show_vc_room', true);
  const vcRoomTriggerData = vcRoomNotifications?.doc?.data();
  const {
    showDomSpeaker,
    reloadPage,
    troom,
    activeStreamStatus,
    setActiveStreamStatus,
    isMultiStreamEnabled,
    reloadSubStreams,
    miniscreen
  } = props;
  
  if (
    !!troom &&
    vcRoomNotifications.type === 'modified' &&
    troom.name === vcRoomNotifications?.doc?.id &&
    vcRoomTriggerData
  ) {
    if (
      Object.keys(vcRoomTriggerData).includes('streaming') &&
      !get(vcRoomTriggerData, 'streaming', false)
    ) {
      setActiveStreamStatus(false);
    }
    if (streamingStatus !== get(vcRoomTriggerData, 'streaming', false)) {
      streamingStatus = get(vcRoomTriggerData, 'streaming', false);

      if (streamingStatus && !activeStreamStatus) {
        messageApi.open({
          type: 'success',
          content: `The streaming has started.`,
        });
        console.log('reload');
        reloadPage();
      }
    }
  }
  return (
    <div
      onClick={() => {
        reloadPage();
        if (isMultiStreamEnabled) {
          reloadSubStreams();
        }
      }}
      className={miniscreen ? 'miniscreen-btn' : `top-menu-button-item ${showDomSpeaker ? 'disableddiv' : ''}`}
    >
      {contextHolder}
      <img src={refreshIcon} alt='' />
      {!miniscreen && <span>Reload Page</span>}
    </div>
  );
}
