import React, { useState, useContext, useEffect } from "react";
import ProgressLoader from "../Common/ProgressLoader";
import { Layout } from "../PageLayout/Layout";
import { PAGES, SHOWFINANCE_PAGES, ROLES } from "../../Utils/constants";
import CreateShowAdmin from "../../Containers/InviteAdmins/CreateShowAdmin";
import ListallShows from "../../Containers/ListAllShows";
import ListAllStreams from "../../Containers/ListAllStreams";
import ListAllUsersNew from "../../Containers/ListAllUsersNew";
import backend from "../../Service/Backend";
import api from "../../Service/Api";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import "../SuperAdmin/superadmin.css";
import useNavigateToPage from "../../hooks/useNavigateToPage";
import { useLocationStore } from "../../store/useLocationStore";


const ShowFinanceDashboard = () => {
 
  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const show_id = locationState.state ? locationState.state.show_id : "";

  const [loading, setLoading] = useState(false);
  const [showFinance, setShowFinance] = useState(false);

  const [page, setPage] = useState(SHOWFINANCE_PAGES.SHOW_LIST);

  useEffect(() => {
    const checkAccess = async () => {
      setLoading(true);
      
      const isShowFinance = await backend.fetch({ api: api.users.isShowFinance}, get(currentUser, "user.accessToken", ""));
      
      setLoading(false);
      if(!isShowFinance) {
        console.log("Un-Authorised!!"); 
        navigateToPage("/login");
      } else {
        setShowFinance(true);
      }
    };

    checkAccess();
  },[])

  const renderListShows = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListallShows roleLabel="Show Finance" role={ROLES.SHOW_FINANCE}/>
        </div>
      </>
    )
  }

  const renderListStreams = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListAllStreams roleLabel="Show Finance"/>
        </div>
      </>
    )
  }

  const renderListUsersNew = () => {
    return (
      <>
        <div className="right-container" style={{marginTop: 0}}>
          <ListAllUsersNew role={ROLES.SHOW_FINANCE}/>
        </div>
      </>
    )
  }

  const renderInviteShowAdmin = () => {
    return (
      <>
        <div className="left-content">
          <div className="invite_show_admin"></div>
        </div>
        <div className="right-content">
          <div className="right-container">
            <CreateShowAdmin roleLabel="Show Finance"/>
          </div>
        </div>
      </>
    );
  }; 

  const renderBodyContent = () => {
    switch(page) {
      case SHOWFINANCE_PAGES.SHOW_LIST: 
        return renderListShows();
      case SHOWFINANCE_PAGES.STREAM_LIST: 
        return renderListStreams(); 
      case SHOWFINANCE_PAGES.USER_LIST: 
        return renderListUsersNew();
      case SHOWFINANCE_PAGES.INVITE_SHOW_ADMIN:
        return renderInviteShowAdmin();
      default:  
        return <div></div>
    }
  }
 
  return (
    <Layout
      page={PAGES.SHOW_FINANCE_PAGE}
      showFinanceListShowsClick={() => setPage(SHOWFINANCE_PAGES.SHOW_LIST)}
      showFinanceListStreamsClick={() => setPage(SHOWFINANCE_PAGES.STREAM_LIST)}
      showFinanceListUsersClick={() => setPage(SHOWFINANCE_PAGES.USER_LIST)}
      showFinanceInviteUserClick={() => setPage(SHOWFINANCE_PAGES.INVITE_SHOW_ADMIN)}
    >
      <ProgressLoader loading={loading} />

      {showFinance ? renderBodyContent() : <div></div>}
    </Layout>
  )   

}

export default ShowFinanceDashboard;