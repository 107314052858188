import { isGuestAuthenticated, logOutGuestUser } from '../../../Utils/guestHelpers';
import { ZoomClient } from '../types/index-types';
import { get } from '../../../Utils/helpers';
import { useContext } from 'react';
import { AuthContext } from '../../../Context/authContext';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import useNavigateToPage from '../../../hooks/useNavigateToPage';
import { useLocationStore } from '../../../store/useLocationStore';

export default function useConferenceCommands(
  zmClient: ZoomClient,
  vc_id: string,
  isGuestInviteIncluded: boolean,
  watermarkFeatureIncluded: boolean,
  encoderFeatureIncluded: boolean,
) {
  const isGuest = isGuestAuthenticated();
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const navigateToPage = useNavigateToPage();
  const locationState = useLocationStore((state) => state.locationState);
  
  const showId = get(locationState,'state.show_id','');

  const leaveOrEndConference = async (endConferenceFlag: boolean) => {
    try {
      const stream_id = sessionStorage.getItem('stream_id');
      const show_vc_room_id = vc_id;

      const guestInviteFlag = isGuestInviteIncluded || isGuest;
      const waterMarkFlag = watermarkFeatureIncluded;
      const encoderFlag = encoderFeatureIncluded;

      if (!stream_id || !showId) {
        return;
      }

      const input = {
        showId,
        stream_id,
        show_vc_room_id,

        endConferenceFlag,
        waterMarkFlag,
        guestInviteFlag,
        encoderFlag,
      };
      const data = {
        api: api.vc.leaveOrEndConference,
        payLoad: JSON.stringify(input),
      };
      const result = backend.save(data, get(authValue, 'accessToken', {}));

      return result;
    } catch (err: any) {
      console.error(err);
    }
  };

  const leaveConference = async () => {
    try {
      await zmClient.leave();
      leaveOrEndConference(false);
      if (isGuest) {
        logOutGuestUser();
        navigateToPage('/logoutredirect', { state: { isGuest: true }, replace: true },true);
      } else {
        sessionStorage.removeItem('stream_id');
        sessionStorage.removeItem('stream_label');
        sessionStorage.removeItem('vc_id');
        sessionStorage.removeItem('previousStreamName');
        sessionStorage.removeItem('streaming');
        navigateToPage('/showselection', { state: { userId: get(authValue, 'uid', '') }, replace: true },true);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const endConference = async () => {
    try {
      await zmClient.leave(true);
      leaveOrEndConference(true);
      if (isGuest) {
        navigateToPage('/logoutredirect', { state: { isGuest: true }, replace: true },true);
      } else {
        navigateToPage('/showselection', { state: { userId: get(authValue, 'uid', '') }, replace: true },true);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return { leaveConference, endConference };
}
