import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../Common/ProgressLoader";
import { PAGES, SUPERADMIN_PAGES } from "../../Utils/constants";
import { Mixpanel } from '../../Service/Mixpanel';
//import OverLay from "../Common/OverLay";
import { pacpostRefreshPage } from "../../Utils/utils";
import UserMessage from "../../Components/Common/UserMessage";
import { get } from "../../Utils/helpers";
import AlertModal from "../../Components/UIKit/AlertModal/AlertModal";
import { AuthContext } from '../../Context/authContext';
import { searchClient } from "../../Service/show";
interface IProps {
  parent_show_id: string | null;
}


const CreateShow = (props: IProps) => {
  const { parent_show_id = null } = props;
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const [, setSuccessMessage] = useState("");
  const [appFeatureFlags, setAppFeatureFlags] = useState([] as any[]);
  const [configList, setConfigList] = useState([]);
  const [config_details, setConfigDetails] = useState({} as any);
  //const [configDisabled, setConfigDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [createShowDisplay, setCreateShowDisplay] = useState(false);
  const [createShowMsg, setCreateShowMsg] = useState(<></>);

  const [show_code, setShowCode] = useState("");
  const [show_po_code, setShowPOCode] = useState("");
  const [show_name, setShowName] = useState("");
  const [show_sub_domain, setShowSubDomain] = useState("");
  const [show_custom_domain, setShowCustomDomain] = useState("");
  const [show_comments, setShowComments] = useState("");
  const [number_of_allowed_guests, setNumberOfAllowedGuests] = useState(0);
  const [max_guest_invitations, setMaxGuestInvitations] = useState(0);

  const [showNameValid, setShowNameValid] = useState(true);
  const [errorShowName, seterrorShowName] = useState("");
  const [showCodeValid, setShowCodeValid] = useState(true);
  const [errorShowCode, seterrorShowCode] = useState("");
  const [showPOCodeValid, setShowPOCodeValid] = useState(true);
  const [errorShowPOCode, seterrorShowPOCode] = useState("");
  const [newPubCount, setNewPubCount] = useState(0);
  const [newStreamCount, setNewStreamCount] = useState(0);
  const [openMaxGuestInput, setOpenMaxGuestInput] = useState(false);
  const [openAutoAllow, setOpenAutoAllow] = useState(false);
  const [auto_allow, setAutoAllow] = useState(false);
  const [guest_link_expiry, setGuestLinkExpiry] = useState('');
  const [number_of_hours_options, setNumber_of_hours_options] = useState([] as any)

  const flagDropDownRef = useRef<any>([]);
  const showConfigRef = useRef<any>([]);
  useEffect(() => {
    searchClient.clearCache();
    // Mixpanel
    Mixpanel.track('Visit', {
      "Platform": "Web",
      "Page Name": `${!!parent_show_id ? PAGES.SHOW_ADMIN_PARENT_PAGE : PAGES.SUPER_ADMIN_PAGE} _ ${SUPERADMIN_PAGES.CREATE_SHOW}`
    });
    flagDropDownRef.current = [];
    showConfigRef.current = [];
    //Get Config Details
    const getConfig = async () => {
      try {
        const data = {
          api: api.configs.listConfigsByShowType
        };
        setLoading(true);
        const response = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        //Removed the publisher count config for stripe integration
        //setConfigList(response.filter((config: any) => config.config_name !== "publisher_count"));
        setConfigList(response);
        setLoading(false);
      } catch (err: any) {
        const msg = "Error getting config info";
        if (err.errMessage) {
          console.error(err.errMessage);
        }
      } finally {
        setLoading(false);
      }
    }

    // Get App Flags
    const getAppFeatureFlags = async () => {
      try {
        const data = {
          api: api.featureFlags.listAppFlags
        };
        setLoading(true);
        const flags = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        const index = flags.findIndex((featurflag: any) => featurflag.feature_name === "ALLOW_GUEST_INVITE");
        if (index !== -1) {
          const removed = flags.splice(index, 1);
          const newFlags = flags.concat(removed);
          const filteredFlags = newFlags.filter((flag:any) => !flag.ui_disabled);
          setAppFeatureFlags(filteredFlags)
          
          for (const flag of filteredFlags) {
            if (flag.feature_name === "ALLOW_GUEST_INVITE" && !!flag.feature_enable) {
              setOpenMaxGuestInput(true)
              setMaxGuestInvitations(flag.max_guest_invitations)
              setNumber_of_hours_options(get(flag,'number_of_hours_options',[]));
              setOpenAutoAllow(true)
              setAutoAllow(flag.auto_allow)
              setGuestLinkExpiry(guest_link_expiry);
            }
          }
        } 
      } catch (err: any) {
        const msg = "Couldn't get application level feature flag details";
        if (err.errMessage) {
          console.error(err.errMessage);
        }
      } finally {
        setLoading(false);
      }
    }

    getConfig();
    getAppFeatureFlags();

  }, [])

  const addToRef = (ele: any, feature_name: string, index: number) => {
    if (ele && !flagDropDownRef.current[index]) {
      flagDropDownRef.current[index] = { ele, feature_name };
    }
  }

  const clearFormFields = () => {
    setShowCode("");
    setShowName("");
    setShowSubDomain("");
    setShowPOCode("");
    setShowCustomDomain("");
    setShowComments("");
    setConfigDetails("");
    const flags = appFeatureFlags;
    flags.forEach((flag: any) => {
      flag.feature_enable = false
    })
    setAppFeatureFlags([...flags]);
    setOpenMaxGuestInput(false)
    setNumberOfAllowedGuests(0);
    setOpenAutoAllow(false)
    setAutoAllow(false);
  };

  const create = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (errorMessage !== "") return;
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage("");
      setErrorMessage("");
      if (!!openMaxGuestInput) {
        setNumberOfAllowedGuests(number_of_allowed_guests)
        setGuestLinkExpiry(guest_link_expiry)
        // setNumber_of_hours_options(number_of_hours_options);
      } else {
        setNumberOfAllowedGuests(0)
        setGuestLinkExpiry('');
        // setNumber_of_hours_options(false);
      }
      // Call Backend to Create Show
      const input = {
        show_code,
        show_po_code,
        show_name,
        show_sub_domain,
        show_custom_domain,
        show_comments,
        config_details,
        parent_show_id,
        show_feature_flags: appFeatureFlags,
        number_of_allowed_guests,
        auto_allow,
        guest_link_expiry
      };
      const data = {
        api: api.shows.create,
        payLoad: JSON.stringify(input),
      };
      setLoading(true);
      const response = await backend.save(data, get(currentUser, "user.accessToken", ""));
      await searchClient.clearCache();
      setLoading(false);
      setSuccessMessage(`Show created with id as ${response}`);
      if (response) {
        setCreateShowDisplay(true);
        setCreateShowMsg(<p>Show <span className="bold-font">"{show_name}"</span> has been successfully created.</p>);
        clearFormFields()
      }

    } catch (err: any) {
      const msg = "Error while creating show !";
      console.error(err);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };
  //***validation of fields ***//

  const validateShowName = (event: any) => {
    setShowName(event.target.value);
    setErrorMessage("");
    let validateStream = event.target.value;
    if (!validateStream) {
      setShowNameValid(false);
      seterrorShowName('Please enter Show Name');
    } else {
      setShowNameValid(true);
      seterrorShowName('')
    }
  }

  const validateShowCode = (event: any) => {
    setShowCode(event.target.value);
    setErrorMessage("");
    let validateStream = event.target.value;
    if (!validateStream) {
      setShowCodeValid(false);
      seterrorShowCode('Please enter Show Code');
    } else {
      setShowCodeValid(true);
      seterrorShowCode('')
    }
  }

  const validateShowPOCode = (event: any) => {
    setShowPOCode(event.target.value);
    setErrorMessage("");
    let validateStream = event.target.value;
    if (!validateStream) {
      setShowPOCodeValid(false);
      seterrorShowPOCode('Please enter Show PO Code');
    } else {
      setShowPOCodeValid(true);
      seterrorShowPOCode('')
    }
  }


  const configOnChange = (event: any, config: any) => {
    const { value } = event.target;
    const { id } = config;
    const trimValue = Number(value);
    const regexString = "^[0-9][0-9]{0,2}$";
    const regex = new RegExp(regexString);
    if (regex.test(value) || value ==="") {
      if (config?.config_name && config.config_name === "publisher_count") {
        setNewPubCount(Number(value))
        if (value !== "" && newStreamCount > Number(value)) {
          setSuccessMessage("");
          setErrorMessage(
            `active streams count should not be more than total publishers count`
          );
        } else {
          setErrorMessage("");
        }
      }
      if (config?.config_name && config.config_name === "stream_count") {
        setNewStreamCount(Number(value))
        if (value !== "" && Number(value) > newPubCount) {
          setSuccessMessage("");
          setErrorMessage(
            `active streams count should not be more than total publishers count`
          );
        } else {
          setErrorMessage("");
        }
      }
      setConfigDetails({
        ...config_details,
        [id]: {
          value: trimValue,
          name: config.config_name
        }
      });
    }
  }
  const handleMaxGuestInvite = (e: any) => {
    const { value } = e.target;
    const regexString = "^[0-9][0-9]{0,2}$";
    const regex = new RegExp(regexString);
    if (regex.test(value) || value === "") {
      setNumberOfAllowedGuests(Number(value))
      if (Number(value) > max_guest_invitations) {
        setErrorMessage(`You can Invite Maximum ${max_guest_invitations} Guests`);
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage(`Please enter only numbers and you can invite maximum ${max_guest_invitations} Guests`);
    }
  }
  const renderMaxGuestInviteCount = () => {
    return (
      <div className="field-and-icon">
        <div className="field-icon-note left-icon"></div>
        <div className="form-field  icon-input-field">
          <label className="form-input-label mandatory">Number of guests count</label>
          <input
            className="form-input"
            type="text"
            name="number_of_allowed_guests"
            onChange={handleMaxGuestInvite}
          />
        </div>
      </div>
    )
  }

  const handleDropdownChange = (e: React.SyntheticEvent, { value }: any) => {
    // Log the value to see what is being selected
    console.log("Guest link expiry selected:", value);
    // setNumber_of_hours_options(value);
    setGuestLinkExpiry(value);
  };
  const renderGuestLinkHoursDropdown = () => {
    return (
      <div className="field-and-icon">
        <div className="field-icon-note left-icon"></div>
        <div className="form-field icon-input-field">
          <label className="form-input-label mandatory">Number of hours guest link is valid</label>
          <Dropdown
            placeholder="Select hours"
            className="form-input"
            fluid
            selection
            options={number_of_hours_options}
            value={guest_link_expiry}
            onChange={handleDropdownChange}
          />
        </div>
      </div>
    );
  }; 
  const autoAllowOptions = [
    { key: '1', value: true, text: 'Enable' },
    { key: '2', value: false, text: 'Disable' },
  ];

  const handleAutoAllow = (e: any, data: any) => {
    const value: boolean = data.value;
    setAutoAllow(value);
  };

  const renderAutoAllow = () => {
    return (
      <div className='field-and-icon'>
        <div className='field-icon-note left-icon'></div>
        <div className='form-field icon-input-field'>
          <label className='form-input-label'>Global Guest Auto Allow</label>
          <Dropdown
            placeholder='Select'
            className='form-input'
            fluid
            search
            selection
            options={autoAllowOptions}
            name='auto_allow'
            value={auto_allow}
            onChange={handleAutoAllow}
          ></Dropdown>
        </div>
      </div>
    );
  };

  const featureFlagOnChange = (appFeatureFlag: any, value: boolean) => {
    appFeatureFlag.feature_enable = value;
    const current = flagDropDownRef.current.find((ref: any) => ref.feature_name === appFeatureFlag.feature_name);
    const flags = appFeatureFlags;
    const filteredIndex: number = flags.findIndex((flag: any) => flag.id === appFeatureFlag.id);
    flags[filteredIndex] = appFeatureFlag;
    if (current?.feature_name === "ALLOW_TEAM_ADMIN" || current?.feature_name === "CHILD_SHOWS") {
      const disableFlagName = current.feature_name === "ALLOW_TEAM_ADMIN" ? "CHILD_SHOWS" : "ALLOW_TEAM_ADMIN";
      const childShowRef = flagDropDownRef.current.find((ref: any) => ref.feature_name === disableFlagName);
      if (childShowRef && childShowRef.ele) {
        childShowRef.ele.childNodes[1].style.pointerEvents = !!value ? "none" : "auto";
        childShowRef.ele.childNodes[1].style.opacity = !!value ? "0.3" : "1";
        !!value ? childShowRef.ele.setAttribute("data-tooltip", current.feature_name === "ALLOW_TEAM_ADMIN" ?
          "Allow child show disabled because allow team admin is enabled" : "Allow team admin disabled because child show is enabled") :
          childShowRef.ele.removeAttribute("data-tooltip")
      }
    }
    // Check if either ALLOW_STREAM_MULTIVIEWER or ALLOW_ENCODER is being toggled
    if (
      current?.feature_name === "ALLOW_STREAM_MULTIVIEWER" ||
      current?.feature_name === "ALLOW_ENCODER"
    ) {
      const disableFlagName =
        appFeatureFlag.feature_name === "ALLOW_STREAM_MULTIVIEWER" ? "ALLOW_ENCODER" : "ALLOW_STREAM_MULTIVIEWER";
      const disableFlagRef = flagDropDownRef.current.find((ref: any) => ref.feature_name === disableFlagName);
      if (disableFlagRef && disableFlagRef.ele) {
        disableFlagRef.ele.childNodes[1].style.pointerEvents = !!value ? "none" : "auto";
        disableFlagRef.ele.childNodes[1].style.opacity = !!value ? "0.3" : "1";
        !!value
          ? disableFlagRef.ele.setAttribute(
            "data-tooltip",
            appFeatureFlag.feature_name === "ALLOW_STREAM_MULTIVIEWER"
              ? "Allow encoder disabled because Multiviewer is enabled"
              : "Multiviewer disabled because Encoder is enabled"
          )
          : disableFlagRef.ele.removeAttribute("data-tooltip");
      }
    }
    if (appFeatureFlag.feature_name === "ALLOW_STRIPE_LICENCE") {
      const publisherCount = showConfigRef.current.find((ref: any) => ref.config_name === "publisher_count");
      if (publisherCount && publisherCount.ele) {
        if (!!value) {
          publisherCount.ele.style.pointerEvents = "none";
          publisherCount.ele.style.opacity = "0.3";
          setConfigDetails({
            ...config_details,
            [publisherCount.ele.id]: {
              value: "",
              name: "publisher_count"
            }
          });
          publisherCount.ele.parentNode.setAttribute("data-tooltip", "Publisher count disabled because Allow Stripe Licence enabled")
        } else {
          publisherCount.ele.style.pointerEvents = "auto";
          publisherCount.ele.style.opacity = "1";
          publisherCount.ele.parentNode.removeAttribute("data-tooltip")
        }
      }
    }
    if (appFeatureFlag.feature_name === "ALLOW_GUEST_INVITE") {
      if (!!value) {
        setMaxGuestInvitations(appFeatureFlag.max_guest_invitations);
        setNumber_of_hours_options(get(appFeatureFlag,'number_of_hours_options',[]));
        setOpenMaxGuestInput(true);
        setErrorMessage('');
        if (typeof get(appFeatureFlag, 'auto_allow', undefined) !== "undefined") {
          setOpenAutoAllow(true);
          setAutoAllow(appFeatureFlag.auto_allow);
        }
      } else {
        setOpenMaxGuestInput(false);
        setOpenAutoAllow(false);
        setErrorMessage('');
      }

    }
    setAppFeatureFlags([...flags])
  }

  const renderAppFeatureFlagsInputs = () => {
    const dropDownOptions = [
      { key: '1', value: true, text: 'Enable' },
      { key: '2', value: false, text: 'Disable' }
    ]

    return appFeatureFlags.map((appFeatureFlag: any, index: number) => {
      const { feature_label, feature_enable, feature_name } = appFeatureFlag;
      return (
        <div className="field-and-icon" key={index} >
          <div className="field-icon-note left-icon"></div>
          <div className="form-field icon-input-field ui" data-position="top center"
            ref={(ele) => addToRef(ele, feature_name, index)}>
            <label className="form-input-label">{feature_label}</label>
            <Dropdown
              placeholder="Select"
              className="form-input"
              fluid
              search
              selection
              options={dropDownOptions}
              value={feature_enable}
              onChange={(e, data) => {
                const value: boolean = data.value as boolean;
                featureFlagOnChange(appFeatureFlag, value);
              }}
            />
          </div>
        </div>
      )
    })
  }

  const renderShowConfigInputs = () => {
    const configEnabled = configList.filter(config => !!config["config_enable"])
    return configEnabled.map((config, index) => {
      const { id, config_label, config_name } = config;
      return (
        <div className="field-and-icon" key={index}>
          <div className="field-icon-note left-icon"></div>
          <div className="form-field  icon-input-field">
            <label className="form-input-label">{config_label}</label>
            <input
              className="form-input"
              type="text"
              name={config_name}
              //disabled={configDisabled}
              id={id}
              ref={(ele) => showConfigRef.current[index] = { ele, config_name }}
              value={get(config_details[id], "value", "")}
              onChange={(e) => configOnChange(e, config)}
            />
          </div>
        </div>
      )
    })
  }

  return (
    <div className="createshow">
      <ProgressLoader loading={loading} />
      <UserMessage errorMsg={errorMessage} />
      <div className="heading margin-0">
        <p className="heading-text-center">
          {!!parent_show_id && <>Show Admin : <span className="grey-text">Create Child Show</span></>}
          {!parent_show_id && <>Super Admin : <span className="grey-text">Create Show</span></>}
        </p>
        <hr className="top" />
      </div>

      <div className="form-content margin-left-0">
        <form className="ui form">
          <div className="field-and-icon">
            <div className="field-icon-tv left-icon"></div>
            <div className="form-field  icon-input-field">
              <label className="form-input-label mandatory">Show Name</label>
              <input
                className={(showNameValid === false) ? "form-input error-border" : "form-input"}
                type="text"
                value={show_name}
                //onChange={(e) => setShowName(e.target.value)}
                onChange={validateShowName}
              />
              {(showNameValid === false) && (
                <span className="error-message">{errorShowName}</span>
              )}
            </div>
          </div>

          <div className="field-and-icon">
            <div className="field-icon-dot left-icon"></div>
            <div className="form-field  icon-input-field">
              <label className="form-input-label mandatory">Show Code</label>
              <input
                className={(showCodeValid === false) ? "form-input error-border" : "form-input"}
                type="text"
                value={show_code}
                //onChange={(e) => setShowCode(e.target.value)}
                onChange={validateShowCode}
              />
              {(showCodeValid === false) && (
                <span className="error-message">{errorShowCode}</span>
              )}
            </div>
          </div>
          {!parent_show_id && <>
            <div className="field-and-icon">
              <div className="field-icon-dot left-icon"></div>
              <div className="form-field  icon-input-field">
                <label className="form-input-label">Show PO Code</label>
                <input
                  className={(showPOCodeValid === false) ? "form-input error-border" : "form-input"}
                  type="text"
                  value={show_po_code}
                  //onChange={(e) => setShowCode(e.target.value)}
                  onChange={validateShowPOCode}
                />
                {(showPOCodeValid === false) && (
                  <span className="error-message">{errorShowPOCode}</span>
                )}
              </div>
            </div>

            <div className="field-and-icon">
              <div className="field-icon-subdomain left-icon"></div>
              <div className="form-field  icon-input-field">
                <label className="form-input-label">Show Sub Domain</label>
                <input
                  className="form-input"
                  type="text"
                  value={show_sub_domain}
                  onChange={(e) => {
                    setShowSubDomain(e.target.value);
                    setErrorMessage("");
                  }}
                />
              </div>
            </div>

            <div className="field-and-icon">
              <div className="field-icon-customdomain left-icon"></div>
              <div className="form-field  icon-input-field">
                <label className="form-input-label">Show Custom Domain</label>
                <input
                  className="form-input"
                  type="text"
                  value={show_custom_domain}
                  onChange={(e) => setShowCustomDomain(e.target.value)}
                />
              </div>
            </div>
          </>
          }

          {!parent_show_id && renderShowConfigInputs()}

          {!parent_show_id && renderAppFeatureFlagsInputs()}

          {!!openMaxGuestInput && renderMaxGuestInviteCount()}
          {!!openMaxGuestInput && renderGuestLinkHoursDropdown()}
          {!!openAutoAllow && renderAutoAllow()}

          <div className="field-and-icon">
            <div className="field-icon-note left-icon"></div>
            <div className="form-field  icon-input-field">
              <label className="form-input-label">Notes</label>
              <input
                className="form-input"
                type="text"
                value={show_comments}
                onChange={(e) => setShowComments(e.target.value)}
              />
            </div>
          </div>
          <div className="center-button">
            <Button
              className="violet-button"
              color="violet"
              loading={loading}
              onClick={create}
              disabled={(showNameValid === false || show_name === "") ||
                (showCodeValid === false || show_code === "") || errorMessage !== ""
                || (openMaxGuestInput === true && number_of_allowed_guests === 0)
                || (openMaxGuestInput === true && guest_link_expiry === '')}
            >
              Create
            </Button>
          </div>
          {errorMessage && (
            <>
              <div className="flex">
                <span className="alert-icon" ></span>
                <span className="error-message alert-text-message">{errorMessage}</span>
              </div>
            </>
          )}
        </form>
        <AlertModal
          onOk={() => {
            setCreateShowDisplay(false)
            pacpostRefreshPage()
          }}
          onCancel={() => setCreateShowDisplay(false)}
          data={createShowMsg}
          open={createShowDisplay}
        />
        {/* <OverLay overlayMsg={overlayMsg} display={overlayDisplay} /> */}

      </div>
    </div>
  );
};

export default CreateShow;
