import React, { useState, useContext } from "react";
import { Button} from "semantic-ui-react";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import OverLay from "../../Components/Common/OverLay";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";
interface IProps {
  show_id: string;
  stream: any;
}

const SendToken = (props: IProps) => {
  const currentUser = get(useContext(AuthContext), "currentUser", {} );

  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [overlayMsg, setOverlaymsg] = useState("");

  const sendTokenToPublisher = async (stream: any) => {
    try {
      if (!stream) return;
      if (!stream.token) return;
      if (!stream.assigned_to) return;

      const user = stream.assigned_to;
      if (!user.user_email) return;

      const data = {
        api: api.streams.sendTokenToPublisher,
        payLoad: {
          show_id: props.show_id || "",
          stream_id: stream.id || "",
          streamlabel: stream.user_entered_stream_label || "",
          token: stream.token || "",
          email: user.user_email || "",
        },
      };

      console.log(data);
      console.log(stream);

      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
      setLoading(false);

      if (result) {
        showOverlayForThreeSeconds();
        setOverlaymsg("Sent token email successfully");
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showOverlayForThreeSeconds = async () => {
    setOverlayDisplay(true);
    await sleep(3000);
    setOverlayDisplay(false);
  };

  const handleConfirm = () => {
    sendTokenToPublisher(props.stream);
    setAlertOpen(false);
  }

  return (
    <>
      <ProgressLoader loading={loading} />
      <Button
        color="violet"
        className="violet-button-list full-button-width"
        onClick={() => {
          setAlertOpen(true);
        }}
      >
        Send Token
      </Button>
      <ConfirmationalPopup
        open={alertOpen}
        data={"Are you sure you want to send the token ?"}
        onCancel={() => setAlertOpen(false)}
        onOk={handleConfirm}
        
      />
      <OverLay overlayMsg={overlayMsg} display={overlayDisplay} />
    </>
  );
};
export default SendToken;
