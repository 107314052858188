import { Button, Form, Input, Modal, Select, notification } from 'antd';
import classNames from 'classnames';
import React, { useContext, useState } from 'react'
// import useToggle from '../../../../hooks/useToggle';
import useToggle from '../../../hooks/useToggle';
import InputComponent from '../../../Components/UIKit/InputComponent';
import { GroupCardList } from './GroupCardList';

// css
import "./style.css"

import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { get } from '../../../Utils/helpers';
import { AuthContext } from '../../../Context/authContext';
interface IPropsGroupTab {
    showId?: any,
    showGroup: any,
    setManageShowGroupUsersStatus?: any;
    manageShowGroupUsersStatus?: any;
    setSelectedGroup?: any;
    selectedGroup?: any;
    loading?: any;
    setLoading?: any,
    fetchShowGroupData?: any
    allGroupsUsersList?: any
}
type showGroupProps = {
    show_group_id: string,
    show_group_name: string,
    show_group_status: string
}

const { Search } = Input;


export const GroupTab = (props: IPropsGroupTab) => {

    const currentUser = get(useContext(AuthContext), "currentUser", {} );

    const [visible, openSearchBar, closeSearchBar] = useToggle(false)
    const [tabStatus, setTabStatus] = useState(false)
    const [groupName, setGroupName] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const {
        showGroup,
        setManageShowGroupUsersStatus,
        manageShowGroupUsersStatus,
        setSelectedGroup,
        setLoading,
        fetchShowGroupData,
        selectedGroup,
        allGroupsUsersList
    } = props
    // // sorting list 
    const sortedShowGroupList = showGroup && showGroup.sort((a: any, b: any) => {
        const nameA = `${a.show_group_name}`.toLowerCase();
        const nameB = `${b.show_group_name}`.toLowerCase();;
        return nameA.localeCompare(nameB);
    }
    )



    const [searchText, setSearchText] = useState("");


    const onChange = (key: string | string[]) => {
        setTabStatus(!tabStatus)
    };


    const handleSearch = (e: any) => {
        const { value } = e.target;
        openSearchBar()
        let iswhiteSpace = value.trim().length === 0;
        if (!iswhiteSpace) {
            setSearchText(value);
        } else { setSearchText("") }

    };
    const showModal = () => {
        setOpenModal(true);
    };

    const handleReset = () => {
        setGroupName('')
    }

    const handleAddGroup = async () => {
        setOpenModal(false);
        setLoading(true)

        // check if group name exist
        const isGroupExist = showGroup.some((x: any) => x.show_group_name === groupName);
        console.log('is group exist? :>> ', isGroupExist);
        if (!!isGroupExist) {
            notification.warning({
                message: 'This group name already exist!'
            })
            setLoading(false)
        }
        else {
            try {
                if (props.showId) {
                    let input: any = { show_id: props.showId, show_group_name: groupName };
                    const createShowGroupData = {
                        api: api.group.createShowGroup,
                        payLoad: JSON.stringify(input),
                        queryParam: { show_id: props.showId },
                    };
                    // call fetch country code data function to get country code data
                    const res = await backend.save(createShowGroupData, get(currentUser, "user.accessToken", ""));
                    fetchShowGroupData()
                    notification.success({
                        message: 'Group Added successfully!'
                    })
                }

                setLoading(false)
            }
            catch (err) {
                console.log(err)
                notification.error({
                    message: 'Error while adding Group!'
                })
                setLoading(false)
            }
        }

    }

    const handleCancel = () => {
        console.log('Clicked cancel button');
        console.log('Clicked cancel button', showGroup);
        setOpenModal(false);
        handleReset();
    };

    const validateGroupName = (value: any) => {
        setGroupName(value);
        // let validatefName = value.trim();
    };

    const filterData = showGroup.filter((group: any) => {
        const groupName: string = get(group, "show_group_name", "");
        const groupId: string = get(group, "show_group_id", "");

        let result = showGroup;

        if (searchText !== "") {
            result = `${groupName} ${groupId}`
                .toLowerCase()
                .includes(searchText.toLowerCase());
        }
        return result
    });

    const groupSearchResult = !!searchText ? filterData : showGroup;
    return (
        <div className="GroupTab">
            <div className="searchbar-wrapper">
                <Button
                    type="primary"
                    className={classNames(
                        "add-btn searchbar-btn ",
                        !visible ? " " : "searchbar-toggle-btn"
                    )}
                    onClick={showModal}
                >
                    Add New Group
                </Button>
                <Search
                    placeholder="Search"
                    allowClear
                    className={classNames(
                        "left-container-searchbar",
                        !visible ? "search-toggle" : ""
                    )}
                    onSearch={visible ? closeSearchBar : openSearchBar}
                    onChange={handleSearch}
                />
            </div>
            <Button
                type="primary"
                className={classNames(
                    "add-btn-add",
                    !visible ? "searchbar-toggle-btn " : " "
                )}
                onClick={closeSearchBar}
            >
                {" "}
                +
            </Button>
            <div className="scrollable-grp-items-container">
            {searchText.length > 0 && groupSearchResult.length === 0 &&
        <p className="invited-users-search-result-new">No Data Found</p>}
                {groupSearchResult &&
                    groupSearchResult.map((list: showGroupProps) => (
                        <GroupCardList
                            showGroupUsersList={allGroupsUsersList.filter((group_user: any) => group_user.show_group_id === list.show_group_id)}
                            setLoading={setLoading}
                            setSelectedGroup={setSelectedGroup}
                            selectedGroup={selectedGroup}
                            fetchShowGroupData={fetchShowGroupData}
                            manageShowGroupUsersStatus={manageShowGroupUsersStatus}
                            setManageShowGroupUsersStatus={setManageShowGroupUsersStatus}
                            showId={props.showId}
                            show={list}
                            key={list.show_group_id}
                        />
                    ))}
            </div>

            <Modal
                title="Add New Group"
                open={openModal}
                onOk={handleAddGroup}
                centered={true}
                // confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okButtonProps={{ disabled: !groupName }}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Form name="form">
                    <Form.Item name="group">
                        <InputComponent
                            key={"group"}
                            type="text"
                            value={groupName}
                            onChange={validateGroupName}
                        />
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    );
}

