import React, { useState, useEffect } from "react";
import { STATUS } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import AlgoliaTable from "../../Components/Algolia/AlgoliaTable";
import { sortTableDate } from "../../Utils/helpers";
import { firstBy } from "thenby";
import DeleteStream from "../ListOfPublisherStreams/DeleteStream";

const { REACT_APP_ALGOLIA_STREAM_INDEX_NAME } = process.env;

interface ListStreamProps {
  roleLabel?: string
}

const ListAllStreams = (props: ListStreamProps) => {
  const [streams, setStreams] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleDelete = async (deleteStream: any) => {
    try {
      if (deleteStream) {
        const allStreams = streams;
        let index = allStreams.findIndex(
          (stream: any) => stream.objectID === deleteStream.objectID
        );
      const dStream: any = allStreams[index];
      dStream.stream_status = STATUS.DELETED;
      dStream.token = "";
      dStream.stream_name = "";

      allStreams.sort(
        firstBy(function (v: any) {
          return v.stream_name.toLowerCase();
        }).thenBy(function (v: any) {
          return v.stream_status.toLowerCase();
        })
      );
      setStreams(allStreams);
      setSuccessMessage(`Successfully deleted the stream`);

      Mixpanel.track("Publisher Stream Action", {
        Platform: "Web",
        "Stream Label": deleteStream.user_entered_stream_label,
        "Action Type": "DeleteStream",
      });
    } else {
      const msg = "Error while deleting stream !";
      setErrorMessage(msg);
    }
  } catch (err: any) {
    if (err.errMessage) {
      setErrorMessage(err.errMessage);
    }
  }
};


  const renderCreatedTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.created_ts._seconds * 1000 +
        original.created_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const renderStartTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.stream_start_ts._seconds * 1000 +
        original.stream_start_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const renderEndTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    //console.log("original.stream_end_ts", original.stream_end_ts);
    return original.stream_end_ts
      ? new Date(
          original.stream_end_ts._seconds * 1000 +
            original.stream_end_ts._nanoseconds / 1000000
        ).toDateString()
      : "NA";
  };
  const renderDeleteButton = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (original && original.stream_status === "Deleted") {
      return "";
    }
    return (
      <DeleteStream
        show_id={original.show_id}
        stream={original}
        handleDelete={handleDelete}
      />
    );
  };
  const getTableConfig = () => {
    return {
      columns: [
        // Headers array for the table
        {
          Header: "Id",
          accessor: "stream_vendor_id",
        },
        {
          Header: "Show Name",
          accessor: "show_name",
        },
        {
          Header: "Stream Label",
          accessor: "stream_label",
        },
        {
          Header: "Stream Name",
          accessor: "stream_name",
        },
        {
          Header: "Created TS",
          accessor: "created_ts",
          Cell: renderCreatedTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.created_ts._seconds"),
        },
        {
          Header: "Status",
          accessor: "stream_status",
        },
        {
          Header: "Start TS",
          accessor: "stream_start_ts",
          Cell: renderStartTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.stream_start_ts._seconds"),
        },
        {
          Header: "End TS",
          accessor: "stream_end_ts",
          Cell: renderEndTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.stream_end_ts._seconds"),
        },
        {
          Header: "Created By",
          accessor: "created_user_email",
        },
        {
          Header: "Delete Stream",
          Cell: renderDeleteButton,
        },
      ],
      algoliaIndex: REACT_APP_ALGOLIA_STREAM_INDEX_NAME, // Algolia Index Mandatory
      setDataList: setStreams, // Temp set list of hits value
      dataList: streams, // Algolia list of hits value,
      sortBy: [
        {
          id: "created_ts",
          desc: false,
        },
      ],
      pageSize: 50,
      attributesToRetrieve: [
        // Response attributes
        "stream_vendor_id",
        "show_name",
        "stream_label",
        "stream_name",
        "created_ts",
        "stream_status",
        "stream_start_ts",
        "stream_end_ts",
        "created_user_email",
        "show_id"
      ],
    };
  };


  return (
    <div className="superadmin-list-streams">
      <div className="superadmin-list-heading">
        <p className="heading-text">
          {props.roleLabel}:<span className="grey-text"> Stream List </span>
        </p>
      </div>
      <AlgoliaTable config={getTableConfig()} />
    </div>
  );
};

export default ListAllStreams;
