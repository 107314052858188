import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import backend from '../Service/Backend';
import api from '../Service/Api';
import { Dimmer, Loader } from 'semantic-ui-react';
import { get } from "../Utils/helpers";
import { AuthContext } from './authContext';
export interface Config {
  maxPasswordRetries: number;
}

export const ConfigContext = createContext<Config | undefined>(undefined);

const loader = (
  <div>
    <Dimmer active inverted>
      <Loader size="massive" />
    </Dimmer>
  </div>
);

export const ConfigProvider = ({ children }: PropsWithChildren<{}>) => {
  const currentUser = get(useContext(AuthContext), "currentUser", {} );
  const [config, setConfig] = useState({} as Config);
  const [status, setStatus] = useState<'idle' | 'loading' | 'success'>('idle');

  useEffect(() => {
    backend.fetch({
      api: api.config.get,
    },  get(currentUser, "user.accessToken", ""))
      .then((response) => {
        setConfig(response);
        setStatus('success');
      })
  }, []);

  const contentByStatus = {
    idle: loader,
    loading: loader,
    success: (
      <ConfigContext.Provider value={config}>
        {children}
      </ConfigContext.Provider>
    ),
  };

  return contentByStatus[status];
};

export function useConfig() {
  const config = useContext(ConfigContext);

  if (!config) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return config;
}