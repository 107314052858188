import { Button, Divider, Form, Input, Table, Space, notification } from 'antd';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { useContext, useState } from 'react';
import { get } from '../../Utils/helpers';
import { AuthContext } from '../../Context/authContext';
import InputComponent from "../../Components/UIKit/InputComponent";
import MfaLogin from "./MfaLogin";

import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import ProgressLoader from '../../Components/Common/ProgressLoader';
import ConfirmationModal from '../../Components/UIKit/ConfirmationModal/ConfirmationModal';


interface CreateKeysProps {
  show_id: string;
  apiList: never[];
  setRefreshKey: (value: boolean) => void;
  refreshKey: boolean;
}

interface ApiTableDataType {
  id: string;
  api_key: string;
  expiry: string;
  state: string;
}

const CreateKeys = (props: CreateKeysProps) => {
  const { show_id, apiList, setRefreshKey, refreshKey } = props;
  const [form] = Form.useForm();
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [loading, setLoading] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [description, setDescription] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showMfaLogin, setShowMfaLogin] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [isVerificationCodeEntered, setIsVerificationCodeEntered] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setErrorMessage("");
      setUploading(true);
      const { email, description, password } = values;
      const data = {
        api: api.massive.createapiKeys,
        urlParam: show_id,
        payLoad: JSON.stringify({
          email,
          description,
          password,
        }),
      };
      const result = await backend.save(data, get(authValue, 'accessToken', ''));
      if (result) {
        setRefreshKey(!refreshKey);
        notification.success({
          message: result,
        });
        clearFormFields();
      }
    } catch (err: any) {
      console.error(err);
      setApiError(err.errMessage);
      handleError(err);
    }
    finally {
      setUploading(false);
    }
  };

  const deleteApiKey = async () => {
    try {
      setLoading(true);
      setDeleteAlertOpen(false);
      const data = {
        api: api.massive.deleteApi,
        urlParam: show_id,
        queryParam: { verificationCode }
      };

      const result = await backend.remove(data, get(authValue, "accessToken", ""));
      if (result) {
        setRefreshKey(!refreshKey);
        notification.success({
          message: result,
        });
      }
      form.resetFields();
      form.validateFields();
    } catch (err: any) {
      console.error('Error deleting API key:', err);
      setIsVerificationCodeEntered(false);
      setVerificationCode('');

      const { errCode, errMessage } = err;
      if (errCode === 41102 && errMessage === 'mfa required') {
        setMfaEnabled(true);
        notification.error({
          message: "Multi-factor Authentication is required. Please Try Again",
          placement: "topRight",
          duration: 3,
        });
      } else {
        notification.error({
          message: errMessage,
          placement: "topRight",
          duration: 3,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    await deleteApiKey();
  };

  const handleDeleteCancel = () => {
    setVerificationCode('');
    setDeleteAlertOpen(false);
  };

  const handleMfaLoginSuccess = () => {
    clearFormFields();
    form.resetFields();
    setShowMfaLogin(false);
  };

  const handleVerificationCodeChange = (e: any) => {
    const code = e.target.value;
    setVerificationCode(code);
    setIsVerificationCodeEntered(code.trim() !== ''); // Check if code is not empty
  };

  const renderPopup = () => {
    return (
      <div>
        {mfaEnabled ? (
          <div>
            <p style={{ fontWeight: 'bold' }}>Enter verification code:</p>
            <Input
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              style={{ width: '150px' }}
            />
          </div>
        ) : (
          <b>Are you sure you want to delete this API key?</b>
        )}
      </div>
    );
  };

  const handleError = (err: any) => {
    const { errCode, errMessage } = err;

    if (errCode === 41102 && errMessage === "mfa required") {
      setErrorMessage("Multi-Factor Authentication is enabled for this account. You will be redirected to the MFA login page.");
      setTimeout(() => {
        setErrorMessage("");
        setShowMfaLogin(true);
      }, 3000);
    } else if (errCode === 41102 && errMessage !== "mfa required") {
      setErrorMessage('Incorrect username or password');
    } else if (errCode === 41103) {
      setErrorMessage('User has already created Masv api key');
    } else {
      setErrorMessage(errMessage);
    }
  };

  const clearFormFields = () => {
    setEmail("");
    setPassword("");
    setDescription("");
    setErrorMessage("");
  }

  // validation of form fields
  const validateEmail = (value: any) => {
    setEmail(value.trim());
  };

  const validatePassword = (value: any) => {
    setPassword(value.trim());
    let validatePassword = value.trim();
    setPassword(validatePassword);
    if (!validatePassword) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
  };

  const validateDescription = (value: any) => {
    setDescription(value.trim());
  };

  const renderForm = () => {
    const disableSubmit = !email ||
      !emailValid ||
      !password ||
      !passwordValid ||
      !description;
    return (
      <div className='section'>
        <Divider className='section-header-form'>
          <h3>Login with massive users details</h3>
        </Divider>
        <Form
          name='basic'
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 400, margin: '3% 0', width: '500px' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete='none'
        >
          <div className='right-side-mail-pass'>
            <Form.Item label='Massive Email'
              name='email'
              rules={[
                { required: true, message: <span className="error-message">Please Enter Email</span> },
                {
                  validator: async (_: any, val: any) => {
                    let validEm = val.trim();
                    if (validEm === "") {
                      setEmailValid(false);
                    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validEm)) {
                      setEmailValid(false);
                      return Promise.reject(
                        <span className="error-message">Invalid Email Format</span>
                      );
                    } else {
                      setEmailValid(true);
                      Promise.resolve()
                    }
                  },
                },

              ]} >
              <InputComponent
                maxLength={35}
                key={"email"}
                type="email"
                value={email}
                onChange={validateEmail}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item label='Password' name='password' rules={[
              { required: true, message: <span className="error-message">Please Enter Passwowrd</span> }]}>
              <InputComponent
                key={"password"}
                type="password"
                value={password}
                onChange={validatePassword}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item label='Description' name='description' rules={[
              { required: true, message: <span className="error-message">Please Enter Description</span> },
              { max: 35 }
            ]}>
              <InputComponent
                key={"description"}
                type="description"
                value={description}
                onChange={validateDescription}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{ span: 18, offset: 4 }} style={{ marginTop: '8%' }}>
              {/* <Space> */}
              <div className='create-api-btn'>
                <Button type='primary' htmlType='submit'
                  disabled={disableSubmit}
                  loading={uploading}
                  className='create-api-right'>
                  CREATE API
                </Button>
              </div>
              {/* </Space> */}
            </Form.Item>
            <div className='err-message'>{errorMessage}</div>
          </div>
        </Form>
      </div>
    );
  };

  const tableColumns: ColumnsType<ApiTableDataType> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
    },

    {
      title: 'Api_Key',
      dataIndex: 'api_key',
      key: 'api_key',
      render: (api_key: string) => (
        <div className='key-holder'>
          {' '}
          <span>{api_key}</span>
        </div>
      ),
    },

    {
      title: 'Expiry',
      dataIndex: 'expiry',
      key: 'expiry',
      render: (expiry: string) => {
        const formattedDate = moment(expiry).format('YYYY-MM-DD');
        return <span>{formattedDate}</span>;
      },
    },

    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state: string) => <span>{state}</span>,
    },

    {
      title: 'Action',
      key: 'action',
      render: () => (
        <Space>
          <Button type='primary' className='delete-api-btn' onClick={() => setDeleteAlertOpen(true)}>
            DELETE API
          </Button>
        </Space>
      ),
    },
  ];

  const renderApiList = () => {
    return (
      <div className='masv-create-keys'>
        <div className='section'>
          <Divider className='section-header-form'>
            <h3>API Information</h3>
          </Divider>
          <div className='right-side-mail-pass2'>
            <Table dataSource={apiList} className='api-table' columns={tableColumns}></Table>
          </div>
        </div>
      </div>
    );
  };

  const renderBodyContent = () => {
    if (apiList.length) {
      return renderApiList();
    }
    return renderForm();
  };
  return (
    <div className='masv-create-keys'>
      <ProgressLoader loading={loading} />
      {showMfaLogin ? (
        <MfaLogin
          show_id={show_id}
          setRefreshKey={setRefreshKey}
          refreshKey={refreshKey}
          onMfaLoginSuccess={handleMfaLoginSuccess}
          user_email={email}
          user_password={password}
          user_description={description}
        />
      ) : (
        renderBodyContent()
      )}
      <ConfirmationModal
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        data={renderPopup()}
        open={deleteAlertOpen}
        okButtonProps={{
          disabled: (!isVerificationCodeEntered && mfaEnabled)
        }}
      />
    </div>
  );
};

export default CreateKeys;