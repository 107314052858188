import { isGuestAuthenticated } from '../../../Utils/guestHelpers';

export function createGuestSessions(localIdentity: string) {
  const checkAutoAllow = sessionStorage.getItem('auto_allow');

  const isGuest: boolean = isGuestAuthenticated();
  if (isGuest && checkAutoAllow === 'true') {
    sessionStorage.setItem('localIdentity', localIdentity.slice(0,47));

    let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};

    allowedGuestSession =
      typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession;

    if (!!localIdentity) {
      allowedGuestSession[localIdentity.slice(0,47)] = {
        status: 'allowed',
      };
      sessionStorage.setItem(
        'allowedGuest',
        typeof allowedGuestSession === 'string'
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession),
      );
    }
    return true;
  }

  if (isGuest && checkAutoAllow === 'false') {
    sessionStorage.setItem('localIdentity', localIdentity.slice(0,47));
    let allowedGuestSession: any = sessionStorage.getItem('allowedGuest') || {};
    allowedGuestSession =
      !!allowedGuestSession && typeof allowedGuestSession === 'string'
        ? JSON.parse(allowedGuestSession)
        : allowedGuestSession;
    if (!!localIdentity && !allowedGuestSession[localIdentity.slice(0,47)]) {
      allowedGuestSession[localIdentity.slice(0,47)] = {
        status: 'waiting',
      };
      sessionStorage.setItem(
        'allowedGuest',
        typeof allowedGuestSession === 'string'
          ? allowedGuestSession
          : JSON.stringify(allowedGuestSession),
      );
    }
    return true;
  }
  return false;
}

export const isGuestAllowed = (guestId: string) => {
  const guestSessions: any = sessionStorage.getItem('allowedGuest') || '';
  if (guestSessions) {
    const guestSessionObject = JSON.parse(guestSessions);
    if (
      !guestSessionObject[guestId.slice(0, 47)] ||
      (!!guestSessionObject[guestId.slice(0, 47)] &&
        guestSessionObject[guestId.slice(0, 47)]?.status === 'waiting')
    ) {
      return false;
    }
    return true;
  }
};

export const isGuestParticipant = (identity: string) => {
  return identity.toLowerCase().startsWith('guest');
};
