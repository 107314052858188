import React, { useState, useContext } from "react";
import { Button } from "semantic-ui-react";
import { Layout } from "../PageLayout/Layout";
import { PAGES } from "../../Utils/constants";
import backend from "../../Service/Backend";
import api from "../../Service/Api";
import { get } from "../../Utils/helpers";
import { AuthContext } from '../../Context/authContext';
import useNavigateToPage from "../../hooks/useNavigateToPage";

const ForgotPassword = () => {
  const navigateToPage = useNavigateToPage();
  const currentUser = get(useContext(AuthContext), "currentUser", {});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  //Get Component Email or OTP
  const [showComponent, setShowComponent] = useState("email");

  //OTP
  const [code, setCode] = useState("");
  const [otpValid, setOtpValid] = useState(true);
  const [errorOtp, setErrorOtp] = useState("");
  const [otpmessage, setOtpMessage] = useState("");
  const [mobile, setMobile] = useState("");

  const validateEmail = (event: any) => {
    setEmail(event.target.value);
    let validateEmail = event.target.value;
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail("Please enter email");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail("Invalid email format");
    } else {
      setEmailValid(true);
      setErrorEmail("");
    }
  };

  const handlePasswordReset = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setErrorMessage("");
      setSuccessMessage("");
      setLoading(true);

      const input = { user_email: email };

      const forgotPasswordRetriesData = {
        api: api.users.forgotPasswordRetries,
        payLoad: JSON.stringify(input),
      };

      const forgotPasswordRetriesResult = await backend.save(
        forgotPasswordRetriesData,
        get(currentUser, "user.accessToken", "")
      );

      let otpType = "OTP";
      const twoFaType = forgotPasswordRetriesResult?.twoFaType;
      if (twoFaType) {
        if (twoFaType === "Google Auth") {
          otpType = "Google Auth"
        } else if (twoFaType === "Authy") {
          otpType = "Authy"
        }
      }
      setShowComponent(otpType);
      if (forgotPasswordRetriesResult && forgotPasswordRetriesResult.message) {
        setOtpMessage(forgotPasswordRetriesResult.message);
      }
      if (forgotPasswordRetriesResult && forgotPasswordRetriesResult.phoneNumber) {
        setMobile(forgotPasswordRetriesResult.phoneNumber);
      }
    } catch (err: any) {
      console.error(err);
      setErrorMessage(err.errMessage || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleSignInButton = async () => {
    navigateToPage("/login");
  };

  const getEmail = () => {
    return (
      <>
        <div className="form-field">
          <label className="form-input-label">E-mail</label>
          <input
            className={
              emailValid === false ? "form-input error-border" : "form-input"
            }
            type="text"
            name="email"
            onChange={validateEmail}
          />
          {emailValid === false && (
            <span className="error-message">{errorEmail}</span>
          )}
        </div>
        <div className="auth-button flex">
          <Button
            className="auth-violet-button auth-centered-buttons-2"
            color="violet"
            loading={loading}
            onClick={handlePasswordReset}
            disabled={emailValid === false || email === ""}
          >
            Get Password
          </Button>
          <Button
            className="auth-violet-button auth-centered-buttons-2"
            color="violet"
            onClick={handleSignInButton}
          >
            Sign-In
          </Button>
        </div>
      </>
    );
  };

  const getOTP = () => {
    return (
      <>
        <div className="form-field">
          <p className="lable-text">
            {showComponent === "OTP" && otpmessage ? otpmessage : 'code'}
            <span className="purple_text"> {mobile}</span>
          </p>
          <input
            className={
              otpValid === false ? "form-input error-border" : "form-input"
            }
            type="text"
            value={code}
            onChange={validOtp}
          />

          {otpValid === false && (
            <span className="error-message">{errorOtp}</span>
          )}
        </div>
        <div className="auth-button flex">
          <Button
            className="auth-violet-button auth-centered-buttons-2"
            color="violet"
            loading={loading}
            onClick={validateOtp}
            disabled={otpValid === false || code === ""}
          >
            Verify Code
          </Button>
          <Button
            className="auth-violet-button auth-centered-buttons-2"
            color="violet"
            onClick={handleSignInButton}
          >
            Sign-In
          </Button>
        </div>
      </>
    );
  };

  const validOtp = (event: any) => {
    const { value } = event.target;
    const validateOtp = value.replace(/[^0-9]/, '');
    setCode(validateOtp);
    if (!validateOtp) {
      setOtpValid(false);
      //setErrorOtp("Please enter Otp");
    } else {
      setOtpValid(true);
      setErrorOtp("");
    }
  };

  const validateOtp = async (event: any) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSuccessMessage("");
      setErrorMessage("");

      const input = { user_email: email, otp: code };

      const data = {
        api: api.users.forgotPasswordRetriesReset,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      const OtpVerification = await backend.save(data, get(currentUser, "user.accessToken", ""));
      if (OtpVerification && OtpVerification.accountstatus === "Disabled") {
        setErrorMessage(
          "Your account is suspended, as you have exceeded maximum tries for forgot password. Contact Admin!"
        );
      } else {
        const forgotPasswordData = {
          api: api.users.forgotpassword,
          payLoad: JSON.stringify(input),
        };
        await backend.save(forgotPasswordData, get(currentUser, "user.accessToken", ""));
        setSuccessMessage("Please check your email for further instructions");
      }
      console.log("OtpVerification", OtpVerification);
    } catch (err: any) {
      console.log(err);

      const msg = "OTP Validation failed";
      console.error(msg);
      if (err.errMessage && err.errMessage !== "Request failed with status code 401") {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const getComponent = () => {
    switch (showComponent) {
      case "OTP":
        return getOTP();
      case "Google Auth":
        return getOTP();
      case "Authy":
        return getOTP();
      default:
        return getEmail();
    }
  };

  return (
    <Layout page={PAGES.FORGOT_PASSWORD_PAGE}>
      <div className="left-content">
        <div className="login_image"></div>
      </div>

      <div className="right-content ipad-forgot-password">
        <div className="right-container">
          <div className="auth-heading">
            <p className="auth-heading-text">
              {showComponent === "OTP"
                ? "Enter OTP Code to get password"
                : showComponent === "Google Auth" ? "Enter OTP Code from google authenticator app to get password"
                  : showComponent === "Authy" ? "Enter OTP Code from twilio authy app to get password" : "Enter Email to get password"}
            </p>
            <hr className="top" />
          </div>

          <div className="form-content">
            <form className="ui form">
              {getComponent()}
              {errorMessage && (
                <>
                  <div className="flex">
                    <span className="alert-icon"></span>
                    <span className="error-message alert-text-message">
                      {errorMessage}
                    </span>
                  </div>
                </>
              )}
              {successMessage && (
                <>
                  <div className="flex">
                    <span className="success-message font-14">
                      {successMessage}
                    </span>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
