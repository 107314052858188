import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Modal,Dropdown } from "semantic-ui-react";
import { ExportToCsv } from "export-to-csv";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../../Components/Common/ProgressLoader";
import UserMessage from "../../Components/Common/UserMessage";
import { PAGES, ROLES, SUPERADMIN_PAGES , EVENT_TYPES} from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import AlgoliaTable from "../../Components/Algolia/AlgoliaTable";
import { sortTableDate, get } from "../../Utils/helpers";
import "../../Components/SuperAdmin/superadmin.css";
import AlertModal from "../../Components/UIKit/AlertModal/AlertModal";
//import { pacpostRefreshPage } from "../../Utils/utils";
//import { auth } from "../../Firebase";
import { searchClient } from "../../Service/show";
import { logEvent } from "../../Service/LogEvent";
import { AuthContext } from '../../Context/authContext';
import ConfirmationalPopup from "../../Utils/ConfirmationalPopup";
import MasvPopup from "../MasvOnboarding/MasvPopup";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

const { REACT_APP_ALGOLIA_SHOW_INDEX_NAME } = process.env;

interface ListShowProps {
  role: string,
  roleLabel?: string
}

const ListallShows = (props: ListShowProps) => {
  const currentUser = get(useContext(AuthContext), "currentUser", {} );
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show_name, setshow_name ] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [shows, setShows] = useState([] as any);
  const [configList, setConfigList] = useState([]);
  const [config_details, setConfigDetails] = useState(
    {} as { [index: string]: any }
  );

  const [superAdminEmail, setSuperAdminEmail] = useState("");
  const [activePublisher, setActivePublisher] = useState(0);
  const [oldPublisherCount, setOldPublisherCount] = useState(0);
  const [oldStreamCount, setOldStreamCount] = useState(0);
  const [newPubCount, setNewPubCount] = useState(0);
  const [newStreamCount, setNewStreamCount] = useState(0);

  const [showAdminList, setShowAdminList] = useState([]);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [dshow, setDeleteShow] = useState({} as any);

  const [disableAlertOpen, setDisableAlertOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [confirmStatus, setConfirmStatus] = useState("")

  const [modalOpen, setModalOpen] = useState(false);
  const [editShow, setEditShow] = useState({} as any);

  const [editShowStatus, setEditShowStatus] = useState("");

  const [featureFlags, setFeatureFlags] = useState([] as any[]);

  const [show_po_code, setShowPOCode] = useState("");
  //const [showStatus, setShowStatus] = useState("");
  const [number_of_allowed_guests, setNumberOfAllowedGuests] = useState(0);
  const [max_guest_invitations, setMaxGuestInvitations] = useState(0);
  const flagDropDownRef = useRef<any>([]);
  const [guest_link_expiry, setGuestLinkExpiry] = useState('');
  const [lmFlagStatus, setLMFlagStatus] = useState(false)
  const [validChange, setValidChange] = useState(false);
  const [openMaxGuestInput, setOpenMaxGuestInput] = useState(false);
  const [openAutoAllow, setOpenAutoAllow] = useState(false);
  const [auto_allow, setAutoAllow] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [deleteShowDisplay, setDeleteShowDisplay] = useState(false);
  const [deleteShowMsg, setDeleteShowMsg] = useState(<></>);
  const [showStatusDisplay, setShowStatusDisplay] = useState(false);
  const [showStatusMsg, setShowStatusMsg] = useState(<></>);
  const [openMasvModal, setOpenMasvModal] = useState(false);
  const [masvShowDetails, setMasvShowDetails] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filteredShows, setFilteredShows] = useState([]);
  const [filterOption, setFilterOption] = useState('active');
  const [number_of_hours_options, setNumber_of_hours_options] = useState([] as any);

  //let show_id = "y4snzi2C8WEyYD8lWVSZ"
  //const check =  isFeatureIncluded(FEATURE_NAME.ALLOW_LICENSE_MANGER, show_id);
  //console.log('checkkk',check)
  useEffect(() => {
    // Mixpanel
    searchClient.clearCache();
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SUPER_ADMIN_PAGE + "_" + SUPERADMIN_PAGES.SHOW_LIST,
    });
    flagDropDownRef.current = [];

    // 
   
    //Get Config Details (to-do: move to index page and pass it as paramas to other components)
    const getConfig = async () => {
      try {
        const data = {
          api: api.configs.listConfigsByShowType,
        };

        setLoading(true);
        const response = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        //console.log("config response::",response)
        setConfigList(response);
        setLoading(false);
      } catch (err: any) {
        const msg = "Error getting config info";
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      }
    };

    getConfig();
  }, [shows]);

  useEffect(() => {
    // Get App Flags
    const getAppFeatureFlags = async () => {
      try {
        const data = {
          api: api.featureFlags.listAppFlags,
        };
        setLoading(true);
        const flags = await backend.fetch(data, get(currentUser, "user.accessToken", ""));
        setLoading(false);
        const index = flags.findIndex((featurflag: any) => featurflag.feature_name === "ALLOW_GUEST_INVITE");
        if (index !==-1) {
          const removed = flags.splice(index, 1);
          const newFlags = flags.concat(removed);
          // Filter flags with disabled:true
          const filteredFlags = newFlags.filter((flag:any) => !flag.ui_disabled);
          setFeatureFlags(filteredFlags)
        } else {
          const filteredFlags = flags.filter((flag:any) => !flag.ui_disabled);
          setFeatureFlags(filteredFlags)
        }
      } catch (err: any) {
        const msg = "Error while pulling feature flags";
        if (err.errMessage) {
          setErrorMessage(err.errMessage);
        } else {
          setErrorMessage(msg);
        }
      } finally {
        setLoading(false);
      }
    };
    getAppFeatureFlags();
  }, []);

  useEffect(() => {
    if (errorMessage !== "") {
      const editModal: any = document.getElementsByClassName('dimmer')[0]
      if (!!editModal) {
        editModal.scrollTo(0, 0)
      }
    }
  }, [errorMessage])

  const deleteShow = async () => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
      const show_id = dshow.objectID;
      const show_name = dshow.show_name;
      const data = {
        api: api.shows.delete,
        urlParam: show_id,
      };
      const deleteShowDetails = {
        show_id,
        show_name,
      };
      setLoading(true);
      const result = await backend.remove(data, get(currentUser, "user.accessToken", ""));
      await searchClient.clearCache();
      setLoading(false);

      if (result) {
        setSuccessMessage(result);
        setDeleteShowDisplay(true);
        setDeleteShowMsg(<p>Show <span className="bold-font">"{show_name}"</span> has been successfully deleted.</p>);
        const deleteShowEvent = logEvent(get(currentUser, 'accessToken', ''), EVENT_TYPES.SHOW_DELETION, deleteShowDetails);
        console.log("Show Deletion:", deleteShowEvent);
        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": show_id,
          "Action Type": "DeleteShow",
          "Admin Type": "SuperAdmin",
        });
        const filterListShows = shows
          .filter((show: any) => show.objectID !== show_id)
          .filter((show: any) => show.parent_show_id !== show_id);
        setShows([...filterListShows]);
      }
    } catch (err: any) {
      const msg = "Error while deleting user";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteAlertOpen(false);
  };

  const handleDeleteConfirm = async () => {
    setDeleteAlertOpen(false);
    await deleteShow();
  };

  const showDeleteConfirmAlert = (show: any) => {
    setDeleteAlertOpen(true);
    setDeleteShow(show);
  };

  const showInactive = async (show: any) => {
    const showStatus = show.show_status;
    if (showStatus === "Disable" || showStatus === "Disabled" || showStatus === "InActive") {
      show.show_status = "Active";
    } else if (showStatus === "Active" || showStatus === "active") {
      show.show_status = "Disable";
    }
    try {
      const objectID = show.objectID;
      const updateStatus = show.show_status;
      setSuccessMessage("");
      setErrorMessage("");
      const input = {
        show_id: show.objectID,
        show_po_code: show_po_code,
        show_details: {
          show_status: show.show_status,
        },
        config_details,
        show_feature_flags: featureFlags,
        number_of_allowed_guests: show?.number_of_allowed_guests || 0,
        auto_allow: show?.auto_allow || false,
        guest_link_expiry: show?.guest_link_expiry || '24h'
      };
      const data = {
        api: api.shows.edit,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));
      const updateShows = shows.map((show: any) => {

        if (show.objectID === objectID) {
          show.show_status = updateStatus;
        }
        return show;
      });
      setShows([...updateShows]);
      setLoading(false);
      if (result) {
        setSuccessMessage(`Show status is changed to ${updateStatus} Successfully`);
        setShowStatusDisplay(true);
        if(updateStatus === "Active"){
          setShowStatusMsg(<p>Show <span className="bold-font">"{show.show_name}"</span> has been successfully activated.</p>);
        }else if (updateStatus === "Disable") {
          setShowStatusMsg(<p>Show <span className="bold-font">"{show.show_name}"</span> has been successfully disabled.</p>);
        }
        
        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": dshow.objectID,
          "Action Type": "EditShow",
          "Admin Type": "SuperAdmin",
        });
        const filterListShows = shows
          .filter((show: any) => show.objectID !== dshow.objectID)
          .filter((show: any) => show.parent_show_id !== dshow.objectID);
        setShows([...filterListShows]);
      }
    } catch (err: any) {
      const msg = "Error while inactivating the show";
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDisableCancel = () => {
    setDisableAlertOpen(false);
  };

  const handleDisableConfirm = () => {
    showInactive(rowData);
    setDisableAlertOpen(false);
  };

  const showDisableConfirmAlert = () => {
    setDisableAlertOpen(true);
  };

  const getActiveShowAdminAndPublisherList = async (show: any) => {
    try {
      const activeShowAdminList = {
        api: api.users.listUsersInShow,
        urlParam: show.objectID,
      };
      const result = await backend.fetch(activeShowAdminList, get(currentUser, "user.accessToken", ""));
      const activeShowadmin = result.filter(
        (user: any) =>
          user.role_type === "ShowAdmin" && user.user_status === "Active"
      );
      //getting showAdmin
      const showAdminList =
        activeShowadmin.length > 0
          ? activeShowadmin.map((showAdmin: any) => showAdmin.user_email)
          : [];
      setShowAdminList(showAdminList);
      //Publisher
      const activePublisher = result.filter(
        (user: any) =>
          user.role_type === "ShowPublisher" && user.user_status === "Active"
      );
      setActivePublisher(activePublisher.length);
    } catch (err: any) {
      console.log("err", err);
    }
  };

  const fetchShowFeatureFlags = async (show: any) => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
 
      const show_specific_flags = show?.feature_flags;
      if (show_specific_flags) {
        // const show_id = show.objectID;
        // const show_specific_flags = allflags[show_id];

        const flags = featureFlags;
        for (const flag of flags) {
          // if (show_specific_flags) {
          // Update the featureFlags with show override
          flag.feature_enable = show_specific_flags[flag.feature_name] || false;

          const disableFlagName =
            flag.feature_name === "ALLOW_TEAM_ADMIN"
              ? "CHILD_SHOWS"
              : flag.feature_name === "CHILD_SHOWS"
                ? "ALLOW_TEAM_ADMIN"
                : flag.feature_name === "ALLOW_STREAM_MULTIVIEWER"
                  ? "ALLOW_ENCODER"
                  : flag.feature_name === "ALLOW_ENCODER"
                    ? "ALLOW_STREAM_MULTIVIEWER"
                    : "";

          const childShowRef = flagDropDownRef.current.find(
            (ref: any) => ref.feature_name === disableFlagName
          );
          if (
            childShowRef &&
            childShowRef.ele &&
            show_specific_flags[flag.feature_name]
          ) {
            childShowRef.ele.childNodes[1].style.pointerEvents =
              !!show_specific_flags[flag.feature_name] ? "none" : "auto";
            childShowRef.ele.childNodes[1].style.opacity =
              !!show_specific_flags[flag.feature_name] ? "0.3" : "1";
            !!show_specific_flags[flag.feature_name]
              ? childShowRef.ele.setAttribute(
                "data-tooltip",
                flag.feature_name === "ALLOW_TEAM_ADMIN"
                  ? "Allow child show disabled because allow team admin is enabled"
                  : flag.feature_name === "CHILD_SHOWS"
                    ? "Allow team admin disabled because child show is enabled"
                    : flag.feature_name === "ALLOW_STREAM_MULTIVIEWER"
                      ? "Allow Encoder disabled because Stream Multiviewer is enabled"
                      : "Allow Stream Multiviewer disabled because Encoder is enabled"
              )
              : childShowRef.ele.removeAttribute("data-tooltip");
          }
          //ELSE TO DO
          // } else {
          //   // Set the app level
          //   const app_flag = allflags["-1"];
          //   flag.feature_enable = app_flag[flag.feature_name];
          // }

          if (flag.feature_name === "ALLOW_LICENSE_MANGER" && !!flag.feature_enable) {
            setLMFlagStatus(true)
          }
          if (flag.feature_name === 'ALLOW_GUEST_INVITE' && !!flag.feature_enable) {
            setOpenMaxGuestInput(true);
            setMaxGuestInvitations(flag.max_guest_invitations);
            setNumberOfAllowedGuests(show?.number_of_allowed_guests);
            setNumber_of_hours_options(flag.number_of_hours_options);
            setGuestLinkExpiry(get(show,'guest_link_expiry','24h'))
            if (typeof get(flag, 'auto_allow', undefined) !== "undefined") {
              setOpenAutoAllow(true);
              setAutoAllow(show?.auto_allow);
            }
          } else {
            setOpenMaxGuestInput(false);
            setNumberOfAllowedGuests(0);
            setOpenAutoAllow(false);
          }
        }
        setFeatureFlags([...flags]);
      }
    } catch (err: any) {
      const msg = "Error while pulling feature flags";
      console.error(msg);
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
      } else {
        setErrorMessage(msg);
      }
    }
  };

  const showEditModal = async (show: any) => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    setSaveDisabled(true);
    setModalOpen(true);
    setEditShow(show);
    setShowPOCode(show.show_po_code);
    setEditShowStatus(show.show_status);
    setConfigDetails(show?.configs);
    setSuperAdminEmail(show?.created_user_email)
    //setNumberOfAllowedGuests(show?.number_of_allowed_guests || 0)
    // await getActivePublisher(show);
    await getActiveShowAdminAndPublisherList(show)
    //await fetchShowConfigs(show);
    if (show.configs) {
      Object.keys(show?.configs).forEach((configId: any) => {
        const showConfig: any = show.configs[configId]
        if (showConfig?.config_name && showConfig.config_name === "publisher_count") {
          setOldPublisherCount(showConfig.config_value);
        }
        if (showConfig?.config_name && showConfig.config_name === "stream_count") {
          setOldStreamCount(showConfig.config_value);
        }
      });
    }

    await fetchShowFeatureFlags(show);
    setLoading(false);
  };

  const onClose = () => {
    setModalOpen(false);
    setErrorMessage("");
    setSaveDisabled(false);
    setActivePublisher(0);
    setNewPubCount(0);
    setOldPublisherCount(0);
    setNewStreamCount(0);
    setOldStreamCount(0);
  };

  const ApprovedLicenseMail = async () => {
    try {
      const input = {
        show_id: editShow.objectID,
        show_name: editShow.show_name,
        show_admin: showAdminList,
        superadmin_email: superAdminEmail,
        license_count: newPubCount - oldPublisherCount,
      };
      const sendEmail = {
        api: api.users.approvedLicense,
        payLoad: JSON.stringify(input),
      };
      await backend.save(sendEmail, get(currentUser, "user.accessToken", ""));
    } catch (err: any) {
      console.log("err", err);
    }
  };

  const editShowDetails = async () => {
    try {
      const allowLicenseManagerEnabled = featureFlags.some(flag => flag.feature_name === "ALLOW_LICENSE_MANGER" && flag.feature_enable);

      if (allowLicenseManagerEnabled && (activePublisher || activePublisher > 0)) {
        if (!newPubCount || newPubCount <= 0) {
          if (!oldPublisherCount || oldPublisherCount <= 0) {
            setErrorMessage("Publisher count should not be less than active publishers count");
            return;
          }
        }
        if ((newPubCount && newPubCount < activePublisher) || (!newPubCount && oldPublisherCount && oldPublisherCount < activePublisher)) {
          setErrorMessage(`Publisher count should not be less than active publishers count (${activePublisher})`);
          return;
        }
      }

      //handling stream count value before saving
      if ((newStreamCount && newStreamCount < activePublisher) || (!newStreamCount && oldStreamCount && oldStreamCount < activePublisher)) {
        setErrorMessage(`Stream count should not be less than active publishers count (${activePublisher})`);
        return;
      }

      // Validate each config
      if (config_details) {
        let hasEmptyPublisherCount = false;
        let hasEmptyStreamCount = false;

        Object.keys(config_details).forEach(key => {
          const config = config_details[key];
          if (config.config_name === "publisher_count" && config.config_value === "") {
            hasEmptyPublisherCount = true;
          }
          if (config.config_name === "stream_count" && config.config_value === "") {
            hasEmptyStreamCount = true;
          }
        });

        if (hasEmptyPublisherCount) {
          setErrorMessage("Publisher count should not be empty.");
          return;
        }

        if (hasEmptyStreamCount) {
          setErrorMessage("Stream count should not be empty.");
          return;
        }
      }

      setLoading(true);

      const input = {
        show_id: editShow.objectID,
        show_name: editShow.show_name,
        show_po_code: show_po_code,
        show_details: {
          show_status: editShowStatus,
        },
        config_details,
        show_feature_flags: featureFlags,
        oldPublisherCount: oldPublisherCount,
        activePublisherCount: activePublisher,
        number_of_allowed_guests: number_of_allowed_guests,
        auto_allow: !!auto_allow,
        guest_link_expiry: guest_link_expiry
      };

      const data = {
        api: api.shows.edit,
        payLoad: JSON.stringify(input),
      };

      const result = await backend.save(data, get(currentUser, "user.accessToken", ""));

      if (result) {
        const updatedFeatureFlags = featureFlags.reduce((acc, flag) => {
          acc[flag.feature_name] = flag.feature_enable;
          return acc;
        }, {});

        const updateShows = shows.map((show: any) => {
          if (show.objectID === editShow.objectID) {
            show.show_status = editShowStatus;
            show.show_po_code = show_po_code;
            show.configs = config_details;
            show.feature_flags = updatedFeatureFlags;
            show.number_of_allowed_guests = number_of_allowed_guests;
            show.auto_allow = auto_allow;
            show.guest_link_expiry = guest_link_expiry;
          }
          return show;
        });
        setShows([...updateShows]);

        const license_count = Number(newPubCount - oldPublisherCount);
        if (!!lmFlagStatus && license_count > 0) {
          await ApprovedLicenseMail();
        }

        Mixpanel.track("Admin User Action", {
          Platform: "Web",
          "Action Email": editShow.objectID,
          "Action Type": "EditShow",
          "Admin Type": "SuperAdmin",
        });

        setValidChange(false);
        setModalOpen(false);
        setErrorMessage("");
        setSuccessMessage("Successfully Updated Show");
        setStatusModal(true);
      }
    } catch (err: any) {
      const msg = "Error while editing show";
      console.error(msg, err);
      setErrorMessage(err?.errMessage || msg);
    } finally {
      setLoading(false);
      setValidChange(false);
      // const editModal: any = document.getElementsByClassName('dimmer')[0]
      // editModal.scrollTo(0,0)
    }
  };

  // Function for handling publisher count changes
  const handlePublisherCountChange = (newValue: any) => {
    setNewPubCount(newValue);
    if (newValue <= 0) {
      setErrorMessage("Please provide a valid publisher count.");
    } else if (newValue < activePublisher) {
      setErrorMessage(`Please give publisher count more than the active publisher count (${activePublisher})`);
    } else {
      setErrorMessage("");
    }
    return true;
  };

  // Function for handling stream count changes
  const handleStreamCountChange = (newValue: any) => {
    setNewStreamCount(newValue);
    if (newValue <= 0) {
      setErrorMessage("Please provide a valid stream count.");
    } else if (newValue < activePublisher) {
      setErrorMessage("Active streams count should not be less than active publishers count");
    } else {
      setErrorMessage("");
    }
    return true;
  };


  // Main handler for config changes
  const configOnChange = (event: any, config: any) => {
    const { value } = event.target;
    const { config_name } = config;
    const newValue = value === "" ? "" : Number(value);
    const regexString = "^[0-9][0-9]{0,2}$";
    const regex = new RegExp(regexString);
    const validInput = regex.test(value) || value === "";
    setValidChange(validInput);
    setSaveDisabled(!validInput);
    if (config_name === "publisher_count") {
      if (handlePublisherCountChange(newValue)) {
        setConfigDetails({
          ...config_details,
          [config.id]: {
            ...config_details[config.id],
            config_value: validInput ? newValue : "",
            config_name: config_name
          }
        });
      }
    }
    if (config_name === "stream_count") {
      if (handleStreamCountChange(newValue)) {
        setConfigDetails({
          ...config_details,
          [config.id]: {
            ...config_details[config.id],
            config_value: validInput ? newValue : "",
            config_name: config_name
          }
        });
      }
    }
  };

  const showfeatureFlagOnChange = (appFeatureFlag: any, value: boolean) => {
    setValidChange(true)
    setSaveDisabled(false)
    appFeatureFlag.feature_enable = value;
    const current = flagDropDownRef.current.find(
      (ref: any) => ref.feature_name === appFeatureFlag.feature_name
    );
    const flags = featureFlags;
    const filteredIndex: number = flags.findIndex(
      (flag: any) => flag.id === appFeatureFlag.id
    );
    flags[filteredIndex] = appFeatureFlag;
    if (
      current.feature_name === "ALLOW_TEAM_ADMIN" ||
      current.feature_name === "CHILD_SHOWS"
    ) {
      const disableFlagName =
        current.feature_name === "ALLOW_TEAM_ADMIN"
          ? "CHILD_SHOWS"
          : "ALLOW_TEAM_ADMIN";
      const childShowRef = flagDropDownRef.current.find(
        (ref: any) => ref.feature_name === disableFlagName
      );
      if (childShowRef && childShowRef.ele) {
        childShowRef.ele.childNodes[1].style.pointerEvents = !!value
          ? "none"
          : "auto";
        childShowRef.ele.childNodes[1].style.opacity = !!value ? "0.3" : "1";
        !!value
          ? childShowRef.ele.setAttribute(
            "data-tooltip",
            current.feature_name === "ALLOW_TEAM_ADMIN"
              ? "Allow child show disabled because allow team admin is enabled"
              : "Allow team admin disabled because child show is enabled"
          )
          : childShowRef.ele.removeAttribute("data-tooltip");
      }
    }
    //check for ALLOW_STREAM_MULTIVIEWER or ALLOW_ENCODER
    if (
      current?.feature_name === "ALLOW_STREAM_MULTIVIEWER" ||
      current?.feature_name === "ALLOW_ENCODER"
    ) {
      const disableFlagName =
        appFeatureFlag.feature_name === "ALLOW_STREAM_MULTIVIEWER" ? "ALLOW_ENCODER" : "ALLOW_STREAM_MULTIVIEWER";
      const disableFlagRef = flagDropDownRef.current.find((ref: any) => ref.feature_name === disableFlagName);
      if (disableFlagRef && disableFlagRef.ele) {
        disableFlagRef.ele.childNodes[1].style.pointerEvents = !!value ? "none" : "auto";
        disableFlagRef.ele.childNodes[1].style.opacity = !!value ? "0.3" : "1";
        !!value
          ? disableFlagRef.ele.setAttribute(
            "data-tooltip",
            appFeatureFlag.feature_name === "ALLOW_STREAM_MULTIVIEWER"
              ? "Allow Encoder disabled because Stream Multiviewer is enabled"
              : "Allow Stream Multiviewer disabled because Encoder is enabled"
          )
          : disableFlagRef.ele.removeAttribute("data-tooltip");
      }
    }
    if (current.feature_name === "ALLOW_LICENSE_MANGER" && !!value) {
      setLMFlagStatus(true)
    }
    if (current.feature_name === "ALLOW_GUEST_INVITE") {
      setMaxGuestInvitations(appFeatureFlag.max_guest_invitations)
      setAutoAllow(appFeatureFlag.auto_allow)
      setNumber_of_hours_options(get(appFeatureFlag,'number_of_hours_options',[]));
      if (!!value) {
        setOpenMaxGuestInput(true);
        setNumberOfAllowedGuests(number_of_allowed_guests);
        if (typeof get(appFeatureFlag, 'auto_allow', undefined) !== "undefined") {
          setOpenAutoAllow(true);
        }
      } else {
        setOpenAutoAllow(false)
        setOpenMaxGuestInput(false)
        setErrorMessage("")
        // if(!!old_number_of_allowed_guests){
        //   setNumberOfAllowedGuests(old_number_of_allowed_guests)
        // } else{
          setNumberOfAllowedGuests(0)
        //}
      }
    }
    setFeatureFlags([...flags]);
  };

  const getConfigInput = () => {
    let configEnabled = configList;
    const isStripeLicenceEnabled = featureFlags.filter((flag: any) => flag.feature_name === "ALLOW_STRIPE_LICENCE");
    //Removed the publisher count config if stripe flag is true 
    if (get(isStripeLicenceEnabled, "0.feature_enable", false)) {
      configEnabled = configList.filter(
        (config) => !!config["config_enable"] && config["config_name"] !== "publisher_count"
      );
    }
    return configEnabled
      .filter((config) => !!config['config_enable'])       //Filter out config enabled is true
      .map((config, index) => {
        const { id, config_label, config_name } = config;
        return (
          <div className='field-and-icon' key={index}>
            <div className='field-icon-note left-icon'></div>
            <div className='form-field  icon-input-field'>
              <label className='form-input-label'>{config_label}</label>

              <input
                className='form-input'
                type='text'
                name={config_name}
                value={
                  !!config_details[id] &&
                  (!!config_details[id].config_value || config_details[id].config_value === 0)
                    ? config_details[id].config_value
                    : ''
                }
                onChange={(e) => configOnChange(e, config)}
              />
            </div>
          </div>
        );
      });
  };

  const addToRef = (ele: any, feature_name: string, index: number) => {
    if (ele) {
      flagDropDownRef.current[index] = { ele, feature_name };
    }
  };

  const renderAppFeatureFlagsInputs = () => {
    const dropDownOptions = [
      { key: "1", value: true, text: "Enable" },
      { key: "2", value: false, text: "Disable" },
    ];
    //ALLOW_STRIPE_LICENCE
    const removedChildShowFlag = featureFlags.filter((flag: any) => !["ALLOW_STRIPE_LICENCE", "CHILD_SHOWS"].includes(flag.feature_name));
    // console.log("removedChildShowFlag", removedChildShowFlag);
    return removedChildShowFlag.map((appFeatureFlag: any, index: number) => {
      const { feature_label, feature_enable, feature_name} = appFeatureFlag;
      return (
        <div className="field-and-icon" key={index}>
          <div className="field-icon-note left-icon"></div>
          <div
            className="form-field icon-input-field ui"
            data-position="top center"
            ref={(ele) => addToRef(ele, feature_name, index)}
          >
            <label className="form-input-label">{feature_label}</label>
            <Dropdown
              placeholder="Select"
              className="form-input"
              fluid
              search
              selection
              options={dropDownOptions}
              value={feature_enable}
              onChange={(e, data) => {
                const value: boolean = data.value as boolean;
                showfeatureFlagOnChange(appFeatureFlag, value);
              }}
            />
          </div>
        </div>
      );
    });
  };
  // const numberOfHoursOptions = [
  //   { key: '24', value: '24h', text: '24 hours' },
  //   { key: '48', value: '48h', text: '48 hours' },
  // ];
  const handleDropdownChange = (e: React.SyntheticEvent, { value }: any) => {
    setGuestLinkExpiry(value);
    setValidChange(true);
    setSaveDisabled(false);
  };
  
  
  const renderGuestLinkHoursDropdown = () => {
    return (
      <div className="field-and-icon">
        <div className="field-icon-note left-icon"></div>
        <div className="form-field icon-input-field">
          <label className="form-input-label mandatory">Number of hours guest link is valid</label>
          <Dropdown
            placeholder="Select hours"
            className="form-input"
            fluid
            selection
            options={number_of_hours_options}
            value={guest_link_expiry}
            onChange={handleDropdownChange}
          />
        </div>
      </div>
    );
  }; 
  const handleMaxGuestInvite = (e: any) => {
    setValidChange(true);
    setSaveDisabled(false)
    const { value } = e.target;
    const regexString = "^[0-9][0-9]{0,2}$";
    const regex = new RegExp(regexString);
    if (regex.test(value) || value === "") {
      setNumberOfAllowedGuests(Number(value))
      if (Number(value) > max_guest_invitations) {
        setErrorMessage(`You can Invite Maximum ${max_guest_invitations} Guests`);
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage(`Please enter only numbers and you can invite maximum ${max_guest_invitations} Guests`);
    }
  }

  const renderMaxGuestInviteCount = () => {
    return (
      <div className="field-and-icon">
        <div className="field-icon-note left-icon"></div>
        <div className="form-field  icon-input-field">
          <label className="form-input-label mandatory">Number of guests count</label>
          <input
            className="form-input"
            type="text"
            name="number_of_allowed_guests"
            value={number_of_allowed_guests}
            onChange={handleMaxGuestInvite}
          />
        </div>
      </div>
    )
  }

  const autoAllowOptions = [
    { key: '1', value: true, text: 'Enable' },
    { key: '2', value: false, text: 'Disable' },
  ];

  const handleAutoAllow = (e: any, data: any) => {
    setValidChange(true);
    setSaveDisabled(false)
    const value: boolean = data.value;
    setAutoAllow(value);
  };

  const renderAutoAllow = () => {
    return (
      <div className='field-and-icon'>
        <div className='field-icon-note left-icon'></div>
        <div className='form-field icon-input-field'>
          <label className='form-input-label'>Global Guest Auto Allow</label>
          <Dropdown
            placeholder='Select'
            className='form-input'
            fluid
            search
            selection
            options={autoAllowOptions}
            name='auto_allow'
            value={auto_allow}
            onChange={handleAutoAllow}
          ></Dropdown>
        </div>
      </div>
    );
  };

  const renderShowStatusInputs = () => {
    const dropDownOptions = [
      { key: "1", value: "Active", text: "Active" },
      { key: "2", value: "Disable", text: "Disable" },
    ];
    return (
      <div className="field-and-icon">
        <div className="field-icon-note left-icon"></div>
        <div className="form-field  icon-input-field">
          <label className="form-input-label">Show Status</label>
          <Dropdown
            placeholder="Select"
            className="form-input"
            fluid
            search
            selection
            options={dropDownOptions}
            value={editShowStatus}
            onChange={(e, data) => {
              const value: string = data.value as string;
              setEditShowStatus(value);
              setValidChange(true)
              setSaveDisabled(false)
            }}
          />
        </div>
      </div>
    );
  };

  const renderEditContent = () => {
    return (
      <>
        <Modal.Content /*scrolling*/>
          <ProgressLoader loading={loading} />
          <div className="heading margin-0">
            <p className="heading-text-center">
              Super Admin : <span className="grey-text">Edit Show</span>
            </p>
            <hr className="top" />
          </div>
          <UserMessage successMsg={successMessage} errorMsg={errorMessage} />

          <div className="form-content margin-left-0">
            <form className="ui form">
              <div className="field-icon-tv left-icon"></div>
              <div className="form-field icon-input-field icon-label-field ">
                <label className="form-input-label">
                  Show Name : {editShow.show_name}
                </label>
              </div>
              <div
                className="field-icon-dot left-icon"
                style={{ margin: "" }}
              ></div>
              <div className="form-field  icon-input-field icon-label-field">
                <label className="form-input-label">
                  Show Code: {editShow.show_code}
                </label>
              </div>
              {!!editShow && !editShow.parent_show_id && (
                <div className="field-and-icon">
                  <div className="field-icon-dot left-icon"></div>
                  <div className="form-field  icon-input-field">
                    <label className="form-input-label">Show PO Code</label>
                    <input
                      className="form-input"
                      type="text"
                      value={show_po_code}
                      onChange={(e) => {
                        setShowPOCode(e.target.value);
                        setValidChange(true)
                        setSaveDisabled(false)
                      }}
                    />
                  </div>
                </div>
              )}
              <div
                className="field-icon-dot left-icon"
                style={{ margin: "" }}
              ></div>
              <div className="form-field  icon-input-field icon-label-field">
                <label className="form-input-label">
                  No of Active Publishers: {activePublisher}
                </label>
              </div>
              {getConfigInput()}
              {renderAppFeatureFlagsInputs()}
              {!!openMaxGuestInput && renderMaxGuestInviteCount()}
              {!!openMaxGuestInput && renderGuestLinkHoursDropdown()}
              {!!openAutoAllow && renderAutoAllow()}
              {renderShowStatusInputs()}
            </form>
          </div>
        </Modal.Content>
        {!loading ? (
          <Modal.Actions>
            <Button
              className="violet-button"
              color="violet"
              loading={loading}
              onClick={editShowDetails}
              // disabled={
              //   Object.keys(config_details).some(
              //     (check) => config_details[check].config_value === ""
              //   )
              // }
              /* if we get a error - save is disabled */
              disabled={validChange === false || (openMaxGuestInput === true && number_of_allowed_guests === 0) ||
                (openMaxGuestInput === true && guest_link_expiry === '') ||
                errorMessage !== "" || saveDisabled === true}
                        >
              SAVE
            </Button>
            <Button
              className="violet-button"
              color="violet"
              loading={loading}
              onClick={onClose}
            >
              CLOSE
            </Button>
          </Modal.Actions>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderCreatedTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.created_ts._seconds * 1000 +
      original.created_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const renderStatus = (props: any) => {
    const { row } = props;
    const { original } = row;
    if (original.show_status === "InActive" || original.show_status === "Disabled" || original.show_status === "Disable") {
      return ("Disable");
    } else {
      return ("Active");
    }
  }

  const renderLicenseCount = (props: any) => {
    const { row } = props;
    const { configs, publisherCount, feature_flags } = row.original;
    const publisherConfig: any = Object.values(configs).find(
      (config: any) => config.config_name === 'publisher_count',
    );
    let licenseCount = publisherConfig ? publisherConfig?.config_value : 0;
    const assignedCount = publisherCount || 0;
    //to handle when acive pub is greater than license count for old shows - only for display purpose
    if (licenseCount < publisherCount) {
      licenseCount = publisherCount;
    }

    const unassignedCount = licenseCount - assignedCount;

    if (get(feature_flags, 'ALLOW_LICENSE_MANGER', false)) {
      return (
        <span className='license-count'>
          <span>{licenseCount}</span>
          <span>&nbsp;</span>
          <span className='tooltip'>
            <Popover
              title={<span style={{ fontWeight: 'bold' }}>LICENSE INFORMATION</span>}
              content={
                <span>
                  Assigned: {assignedCount}, Unassigned: {unassignedCount}
                </span>
              }
            >
              <InfoCircleOutlined className='superadmin-license-info' />
            </Popover>
          </span>
        </span>
      );
    }

    return <></>;
  };

  const hiddenColumns: any = {
    [ROLES.SHOW_FINANCE]: [
      'Edit',
      'Delete',
      'Disable'
    ]
  }

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const renderActionButtons = (props: any) => {
    const { row } = props;
    const { original } = row;
    return (
      <div>
        <span>
          {(original && (original.show_status === "InActive" || original.show_status === "Disabled" || original.show_status === "Disable")) ?
            <Button
              color="violet"
              className="action-button"
              onClick={() => {
                setRowData(original)
                setConfirmStatus("Enable")
                showDisableConfirmAlert();
              }}
            >
              ACTIVE
            </Button>
            :
            <Button
              color="violet"
              className="action-button"
              onClick={() => {
                setRowData(original)
                setConfirmStatus("Disable");
                showDisableConfirmAlert();
              }}
            >
              DISABLE
            </Button>
          }
        </span>
        <span>
          <Button
            color="violet"
            onClick={() => {
              showDeleteConfirmAlert(original);
            }}
            disabled={get(get(original, 'feature_flags', ''), 'ALLOW_MASSIV', false)}
          >
            DELETE
          </Button>
        </span>
        <span>
          <Button
            color="violet"
            onClick={() => {
              showEditModal(original);
            }}
          >
            EDIT
          </Button>
        </span>
        {get(get(original, 'feature_flags', ''), 'ALLOW_MASSIV', false) && (
          <span>
            <Button
              color='violet'
              onClick={
                e => {
                setMasvShowDetails(row);
                showDrawer();
              }
            }
            >
              MASSIVE ONBOARDING
            </Button>
          </span>
        )}
      </div>
    );
  }

  const column = [
    {
      Header: "Show Code",
      accessor: "show_code",
    },
    {
      Header: "Show Name",
      accessor: "show_name",
    },
    {
      Header: "Created TS",
      accessor: "created_ts",
      Cell: renderCreatedTime,
      sortType: (a: any, b: any) =>
        sortTableDate(a, b, "values.created_ts._seconds"),
    },
    {
      Header: "Status",
      accessor: "show_status",
      Cell: renderStatus,
      sortType: (a: any, b: any) =>
        sortTableDate(a, b, "values.show_status"),
    },
    {
      Header: "Active Publishers",
      accessor: "publisherCount",
    },
    {
      Header: "License Count",
      accessor: "configs",
      Cell: renderLicenseCount,
    },
    {
      Header: "Created By",
      accessor: "created_user_email",
    },
    {
      Header: "Action Buttons",
      Cell: renderActionButtons,
    },
    {
      Header: "Show Id",
      accessor: "objectID",
    },
    {
      Header: "Custom Domain Name",
      accessor: "show_custom_domain",
    },
  ]

  const filteredColumns = () => {
    return column.filter((each: { Header: string }) => hiddenColumns[props.role].indexOf(each.Header) === -1)
  }

  const getTableConfig = () => {
    const tableColumn = hiddenColumns[props.role]?.length ? filteredColumns() : column;
    return {
      columns: tableColumn,
      algoliaIndex: REACT_APP_ALGOLIA_SHOW_INDEX_NAME, // Algolia Index Mandatory
      setDataList: setShows, // Temp set list of hits value
      dataList: shows, // Algolia list of hits value,
      sortBy: [
        {
          id: "created_ts",
          desc: false,
        },
        {
          id: "show_status",
          desc: false,
        },
      ],
      publisherCount: true,
      tooltip: "created_user_email",
      pageSize: 50,
      attributesToRetrieve: [
        // Response attributes
        "objectID",
        "show_po_code",
        "show_code",
        "show_name",
        "created_ts",
        "show_status",
        "show_sub_domain",
        "parent_show_id",
        "created_user_email",
        "feature_flags",
        "configs",
        "number_of_allowed_guests",
        'guest_link_expiry',
        "auto_allow",
        "show_custom_domain",
      ],
      refresh: shows.length === 0,
    };
  };

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    filename: `Pacpost Shows List - ${new Date()}`,
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  useEffect(() => {
    const activeShows = shows.filter((show: { show_status: string; }) => show.show_status === "Active");
    setFilteredShows(filterOption === 'active' ? activeShows : shows);
  }, [shows, filterOption]);


  const getActiveTableConfig = (filteredShows= shows.filter((show: { show_status: string; }) => show.show_status === "Active")) => {
   
    const tableColumn = hiddenColumns[props.role]?.length ? filteredColumns() : column;
    return {
      columns: tableColumn,
      algoliaIndex: REACT_APP_ALGOLIA_SHOW_INDEX_NAME,
      setDataList: setShows,
      dataList: filteredShows, // Use the filtered shows instead of all shows
      sortBy: [
        {
          id: "created_ts",
          desc: false,
        },
        {
          id: "show_status",
          desc: false,
        },
      ],
      publisherCount: true,
      tooltip: "created_user_email",
      pageSize: 50,
      attributesToRetrieve: [
        "objectID",
        "show_po_code",
        "show_code",
        "show_name",
        "created_ts",
        "show_status",
        "show_sub_domain",
        "parent_show_id",
        "created_user_email",
        "feature_flags",
        "configs",
        "number_of_allowed_guests",
        "guest_link_expiry",
        "auto_allow",
        "show_custom_domain",
      ],
      refresh: shows.length === 0,
    };
  };

  const handleActiveButtonClick = () => {
    // Filter and set only active shows
    const activeShows = shows.filter((show: { show_status: string; }) => show.show_status === "Active");
    setFilteredShows(activeShows);
  };

  const handleAllButtonClick = () => {
    // Set all shows
    setFilteredShows(shows);
  };
  
  const handleFilterOptionChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFilterOption(event.target.value);
    if (event.target.value === 'active') {
      handleActiveButtonClick();
    } else {
      handleAllButtonClick();
    }
  };
  const exportCSV = () => {
    const csvExporter = new ExportToCsv(options);
    const csvShows: any = [];
    shows.forEach((show: any) => {
      const licenseConfig: any = Object.values(show.configs).find((config: any) => config.config_name === "publisher_count");
      const licenseCount = licenseConfig ? licenseConfig?.config_value : 0;
      const temp: any = {};
      temp["Show Code"] = show.show_code;
      temp["Show Name"] = show.show_name;
      temp["Created TS"] = new Date(
        show.created_ts._seconds * 1000 + show.created_ts._nanoseconds / 1000000
      ).toDateString();
      temp["Status"] = show.show_status;
      temp["Active Publishers"] = show.publisherCount;
      temp["License Count"] = licenseCount;
      temp["Created By"] = show.created_user_email;
      temp["Show Id"] = show.objectID;
      temp["Custom Domain Name"] = show.show_custom_domain;
      csvShows.push(temp);
    });
    csvExporter.generateCsv(JSON.stringify(csvShows));
  };

  return (
    <div className="superadmin-list-shows">
      <ProgressLoader loading={loading} />
      <UserMessage errorMsg={errorMessage} />

      <div className="superadmin-list-heading">
        <p className="heading-text showlist-heading">
          {props.roleLabel ? props.roleLabel : ""} :<span className="grey-text"> Show List </span>
        </p>
        <div className="heading-text-right">
          {/* <span className="superadmin-googleauth">
          <input 
              className="googleAuth-checkbox" 
              type="checkbox" 
              data-tooltip="Search GoogleAuth Shows"
              data-position="top center"
            ></input>
            <label className="googleAuth-label" >GoogleAuth</label>  
          </span> */}
       <div className="radio-buttons-container">
          <label className="radio-label">
            <input
              type="radio"
              value="active"
              checked={filterOption === 'active'}
              onChange={handleFilterOptionChange}
            />
            <span className="radio-text">ACTIVE</span>
          </label>
          
          <label className="radio-label">
            <input
              type="radio"
              value="all"
              checked={filterOption === 'all'}
              onChange={handleFilterOptionChange}
            />
            <span className="radio-text">ALL</span>
          </label>
      </div>
          <span
            className="csv-button"
            onClick={exportCSV}
            data-tooltip="Export CSV"
            data-position="top center"
          ></span>
        </div>
      </div>
      <AlgoliaTable config={filterOption === 'active' ? getActiveTableConfig() : getTableConfig()} />
      {/* <AlgoliaTable config={getTableConfig()} /> */}
      <ConfirmationalPopup
        open={disableAlertOpen}
        data={`This will ${confirmStatus} the show , do you want to continue ?`}
        onCancel={handleDisableCancel}
        onOk={handleDisableConfirm}
      />
      <ConfirmationalPopup
        open={statusModal}
        data={<p>Successfully Updated Show : <strong> {editShow.show_name} </strong></p>}
        onCancel={()=> { setStatusModal(false)}}
        onOk={() => { setStatusModal(false) }}
      />
      <ConfirmationalPopup
        className={'delete-show-confirm'}
        open={deleteAlertOpen}
        data={"This will delete the show and corresponding data, do you want to continue ?"}
        onCancel={handleDeleteCancel}
        onOk={handleDeleteConfirm}  
      />
      {drawerOpen && (<MasvPopup drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} masvShowDetails={masvShowDetails}/>)}

      <div className="activeshowmodal update-show-modal">
        <Modal
          open={modalOpen}
          className="update-show-modal"
          closeOnEscape={false}
          closeOnDimmerClick={false}
          basic
          closeIcon
          size={"small"}
        >
          {renderEditContent()}
        </Modal>
        <AlertModal
          onOk={() => setDeleteShowDisplay(false)}
          onCancel={() => setDeleteShowDisplay(false)}
          data={deleteShowMsg}
          open={deleteShowDisplay}
        />
        <AlertModal
          onOk={() => setShowStatusDisplay(false)}
          onCancel={() => setShowStatusDisplay(false)}
          data={showStatusMsg}
          open={showStatusDisplay}
        />
      </div>
    </div>
  );
}

export default ListallShows;