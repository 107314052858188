import React from "react";
import { get } from "../../Utils/helpers";
import "./ManageLicense.css";

const LicenseHistory = (props: any) => {
  const { historyData } = props;

  const getFirstLastName = (
    historyObj: any,
    key: string,
    defaultVal: string,
    substring: string
  ) => {
    if (!!historyObj && !!historyObj[key] && !!historyObj[key].user_name) {
      return `${get(
        historyObj,
        `${key}.user_name.user_name_first`,
        ""
      )} ${get(
        historyObj,
        `${key}.user_name.user_name_last`,
        ""
      )} ${substring}`;
    }
    return defaultVal;
  };

  return (
    <div className="mlv-pub-history-main">
      <div className="typography-h4">HISTORY</div>
      <div className="mlv-pub-history-content">
        {historyData.map((historyObj: any, index: any) => {
          const actionTime: any = new Date(get(historyObj, "action_time", ""));
          const currentTime: any = new Date();
          let dateDifference;
          if (actionTime < currentTime) {
            dateDifference = currentTime - actionTime;
          } else {
            dateDifference = actionTime - currentTime;
          }
          const days = Math.round(dateDifference / (24 * 60 * 60 * 1000));
          const min_diff = Math.round((dateDifference / (1000 * 60)) % 60);
          const hrs_diff = Math.floor((dateDifference / (1000 * 60 * 60)) % 24);
          return (
            <div className="mlv-card" key={index}>
              <div className="mlv-card-left">
                {get(historyObj, "action", "") === "REQUEST_LICENSE" ||
                get(historyObj, "action", "") === "ADDED_LICENSE" ? (
                  <>
                    <div>
                      {get(historyObj, "license_status", "") === "pending"
                        ? `Request License Pending : ${get(
                            historyObj,
                            "expected_license_count",
                            ""
                          )}`
                        : `Added ${get(
                            historyObj,
                            "approved_license_count",
                            ""
                          )} publisher Licenses`}
                    </div>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "adminUserObj",
                        "Admin",
                        ""
                      )}
                      {get(historyObj, "license_status", "") === "pending"
                        ? " requested a publisher license"
                        : " approved a publisher license"}
                    </div>
                  </>
                ) : get(historyObj, "action", "") === "UPDATED_LICENSE" ? (
                  <>
                    <div>
                      {get(historyObj, "license_status", "") === "pending"
                        ? `Request License Pending : ${get(
                            historyObj,
                            "expected_license_count",
                            ""
                          )}`
                        : `Updated from ${
                            get(historyObj, "old_license_count", 0) || 0
                          } to ${get(
                            historyObj,
                            "approved_license_count",
                            ""
                          )} publisher Licenses`}
                    </div>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "adminUserObj",
                        "Admin",
                        ""
                      )}
                      {get(historyObj, "license_status", "") === "pending"
                        ? " requested a publisher license"
                        : " approved a publisher license"}
                    </div>
                  </>
                ) : get(historyObj, "action", "") === "REMOVE_LICENSE" ? (
                  <>
                    <div>Unassigned License Removed</div>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "adminUserObj",
                        "Admin canceled an unassigned license",
                        "canceled an unassigned license"
                      )}
                    </div>
                  </>
                ) : get(historyObj, "action", "") ===
                  "ADD_PUBLISHER_LICENSE" ? (
                  <>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "userObj",
                        "User Added",
                        "Added"
                      )}
                    </div>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "adminUserObj",
                        "Admin added a publisher license",
                        "added a publisher license"
                      )}
                    </div>
                  </>
                ) : get(historyObj, "action", "") ===
                  "REMOVE_PUBLISHER_LICENSE" ? (
                  <>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "userObj",
                        "User Removed",
                        "Removed"
                      )}
                    </div>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "adminUserObj",
                        "Admin canceled a license",
                        "canceled a license"
                      )}
                    </div>
                  </>
                ) : get(historyObj, "action", "") === "REMOVE_PUBLISHER" ? (
                  <>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "userObj",
                        "User Removed",
                        "Removed"
                      )}
                    </div>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "adminUserObj",
                        "Admin removed a publisher",
                        "removed a publisher"
                      )}
                    </div>
                  </>
                ) : get(historyObj, "action", "") ===
                  "REASSIGN_PUBLISHER_LICENSE" ? (
                  <>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "oldUserObj",
                        "",
                        "Removed"
                      )}
                      {" "}
                      {getFirstLastName(
                        historyObj,
                        "userObj",
                        "User Removed",
                        "Added"
                      )}
                    </div>
                    <div>
                      {getFirstLastName(
                        historyObj,
                        "adminUserObj",
                        "Admin removed a publisher license",
                        "re-assigned a publisher license"
                      )}
                    </div>
                  </>
                ) : null}
              </div>
              <div>
                {days > 0
                  ? `${days} days`
                  : hrs_diff > 0
                  ? `${hrs_diff} hrs`
                  : `${min_diff} mins ago`}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default LicenseHistory;
