import { Modal } from "antd";

interface AlertModalProps {
  onOk: () => void;
  onCancel: () => void;
  open: boolean;
  data: any;
}
const AlertModal = (props: AlertModalProps) => {
  return (
    <Modal
      centered={true}
      bodyStyle={{ height: "auto" }}
      open={props.open}
      onOk={props.onOk}
      onCancel={props.onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {props.data}
    </Modal>
  );
};

export default AlertModal;