import React, { useState, useEffect } from "react";
import { PAGES, SUPERADMIN_PAGES } from "../../Utils/constants";
import { Mixpanel } from "../../Service/Mixpanel";
import AlgoliaTable from "../Algolia/AlgoliaTable";
import { sortTableDate } from "../../Utils/helpers";
import { searchClient } from "../../Service/show";

const { REACT_APP_ALGOLIA_SHOW_INDEX_NAME } = process.env;

interface IProps {
  parent_show_id: string | undefined;
  usersGroupsFlag?:boolean;
}

const ListAllChildShow = (props: IProps) => {
  const { parent_show_id } = props;
  const [childShow, setChildSHow] = useState([]);

  useEffect(() => {
    // Mixpanel
    searchClient.clearCache();
    Mixpanel.track("Visit", {
      Platform: "Web",
      "Page Name": PAGES.SUPER_ADMIN_PAGE + "_" + SUPERADMIN_PAGES.STREAM_LIST,
    });
  }, [childShow]);

  const renderCreatedTime = (props: any) => {
    const { row } = props;
    const { original } = row;
    return new Date(
      original.created_ts._seconds * 1000 +
        original.created_ts._nanoseconds / 1000000
    ).toDateString();
  };

  const getTableConfig = () => {
    return {
      columns: [
        // Headers array for the table
        {
          Header: "Show Id",
          accessor: "objectID",
        },
        {
          Header: "Show Code",
          accessor: "show_code",
        },
        {
          Header: "Show Name",
          accessor: "show_name",
        },
        {
          Header: "Created TS",
          accessor: "created_ts",
          Cell: renderCreatedTime,
          sortType: (a: any, b: any) =>
            sortTableDate(a, b, "values.created_ts._seconds"),
        },
        {
          Header: "Status",
          accessor: "show_status",
        },
        {
          Header: "Sub Domain Name",
          accessor: "show_sub_domain",
        },
        {
          Header: "Custom Domain Name",
          accessor: "show_custom_domain",
        },
        {
          Header: "Created By",
          accessor: "created_user_email",
        }
      ],
      algoliaIndex: REACT_APP_ALGOLIA_SHOW_INDEX_NAME, // Algolia Index Mandatory
      filters: `parent_show_id:${parent_show_id}`, // Algolia Filters
      setDataList: setChildSHow, // Temp set list of hits value
      dataList: childShow, // Algolia list of hits value,
      refresh: childShow.length === 0,
      sortBy: [
        {
          id: "show_name",
          desc: false,
        },
      ],
      attributesToRetrieve: [
        // Response attributes
        "objectID",
        "show_po_code",
        "show_code",
        "show_name",
        "created_ts",
        "show_status",
        "show_sub_domain",
        "show_custom_domain",
        "created_user_email",
      ],
    };
  };

  return (
    <div className="superadmin-list-streams" style={{width:"97%", margin: "0 auto"}}>
      <div className="superadmin-list-heading">
        <p className="heading-text">
          Show Admin :<span className="grey-text"> Child Show List </span>
        </p>
      </div>
      <AlgoliaTable config={getTableConfig()} />
    </div>
  );
};

export default ListAllChildShow;
