import React, { useEffect, useState, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import Delete from "../../Images/delete_th.svg";
import guestLinkCopy from "../../Images/guestLinkCopy.svg";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import ProgressLoader from "../Common/ProgressLoader";
import GuestUsersList from "./GuestUsersList";
import { get } from "../../Utils/helpers";
import GuestTimer from "./GuestTimer";
import { Tooltip, Popconfirm, Badge, Modal } from "antd";
import { NotificationTwoTone } from "@ant-design/icons";
import WarningModal from "./WarningModal";
import { AuthContext } from '../../Context/authContext';
import { useLocationStore } from "../../store/useLocationStore";

let lockedGuests: any = [];
const GuestInvite = (props: any) => {
  const { troom, auto_allow, handleAutoAllow, setRedIcon, setGuestStatusCheck, guestStatusCheck,
    feNotification, setFeNotification, globalGuestAutoAllow, lockedList, updated, updateAutoAllowAtStreamLevel} = props;
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const locationState = useLocationStore((state) => state.locationState);
  const show_id = locationState.state ? locationState.state.show_id : "";
  const stream_id = sessionStorage.getItem("stream_id");
  const stream_name = sessionStorage.getItem("stream_label");

  const [url, setUrl] = useState<string | null>(null);
  const [pin, setPin] = useState<string | null>(null);

  const [expDate, setExpDate] = useState(0 as number);
  const [linkexpired, setLinkExpired] = useState(true);

  const [uRLModalOpen, setURLModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [guestInviteCount, setGuestInviteCount] = useState(0);
  
  // const [notificationLength, setNotificationLength] = useState([])
  const [notificationOpen,setNotificationOpen] = useState(false);
  
  const notificationIcon = <NotificationTwoTone style={{ fontSize:20 }}/>
  const [renderCheck,setRenderCheck] = useState(false)
  
  const currentDate = Date.now();

  useEffect(() => {
    const checkGuestNotifications = () =>{
      if (!!url) {
        lockedList.forEach((name: any) => {
          if (!lockedGuests.includes(name)) {
            lockedGuests.push(name);
          }
        });
      setFeNotification(lockedGuests);
      }
      setRenderCheck(!renderCheck)
    }
    checkGuestNotifications();
  }, [updated]);

  const getFElength = () => {
    return feNotification.length;
  };

  const notificationCount = useMemo(() => getFElength(), [renderCheck, feNotification.length]);
  
  const createGuestLink = async () => {
    try {
      setLoading(true);
      setOpen(false);
      const input = { show_id, stream_id, stream_name };
      const data = {
        api: api.guestUsers.createGuestData,
        payLoad: JSON.stringify(input),
      };
      const result = await backend.save(data, get(authValue, 'accessToken', {}));
      console.log("Create GL result::::", result);
      if (!!result) {
        if(sessionStorage.getItem("auto_allow") === "true" && !globalGuestAutoAllow ){
          handleAutoAllow();
        }
        getGuestURL();
        setURLModalOpen(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getGuestURL = async () => {
    try {
      setLoading(true);
      const input = { stream_id };
      lockedGuests = [];
      const data = {
        api: api.streams.getGuestLink,
        queryParam: { stream_id: stream_id },
      };
      const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
      const notificationData = get(result, 'notifications', null);
      if (!!notificationData) {
        notificationData.forEach((data: any) => {
          lockedGuests.push(get(get(data, 'notification_metadata', null), 'guestName', null));
        });
        setFeNotification(lockedGuests);
       
      }
      if (Object.keys(result || {}).length) {
        const shortUrl = get(result, "guestURL.shortUrl", null);
        const guestCode = get(result, "guestCode", null);
        setUrl(shortUrl);
        setPin(guestCode);

        const token =
          shortUrl !== null
            ? get(get(result, "guestURL.destination", "").split("="), "1", null)
            : null;

        const tokenDecoded =
          shortUrl !== null
            ? JSON.parse(atob(get(token.split("."), "1", "")))
            : null;

        const expTime: number =
          shortUrl !== null ? get(tokenDecoded, "exp", 0) * 1000 : 0;

        setExpDate(expTime);
        const urlId = get(result, "guestURL.id", null);
        sessionStorage.setItem("urlId", urlId);

        // const currentDate = Date.now();
        if (currentDate > expTime) {
          setLinkExpired(true);
        } else {
          setLinkExpired(false);
        }
      } else {
        setUrl(null);
        setPin(null);
        setLinkExpired(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleCopy = (link: any, code: any) => {
    let text = `URL: https://${link}` + "\n" + `PASSCODE: ${code}`;
    navigator.clipboard.writeText(text);
    console.log(text);
  };

  useEffect(() => {
    getGuestURL();
    fetchGuestInviteCount();
    if(globalGuestAutoAllow && sessionStorage.getItem('auto_allow') === 'false'){
      updateAutoAllowAtStreamLevel();
    }
  }, []);

  const deleteGuestURL = async () => {
    try {
      setLoading(true);
      setDeleteModalOpen(false);
      closeNotificationModal();
      if(!url){
        return
      }
      const urlId = sessionStorage.getItem("urlId");
      const input = { stream_id, urlId };
      const data = {
        api: api.streams.deleteGuestLink,
        payLoad: JSON.stringify(input),
      };
      const result = await backend.save(data, get(authValue, 'accessToken', {}));
      setExpDate(0);
      if (result) {
        lockedGuests = [];
        setFeNotification([]);
        setUrl(null);
        setPin(null);
      }
      
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  const fetchGuestInviteCount = async () => {
    try {
      setLoading(true);
      const data = {
        api: api.shows.fetchGuestInviteCount,
        queryParam: { show_id: show_id },
      };
      const result = await backend.fetch(data, get(authValue, 'accessToken', {}));
      if (result) {
        setGuestInviteCount(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const openNotificationModal = () =>{
    setNotificationOpen(true)
  }

  const closeNotificationModal =()=>{
    // setNotificationLength(0)
    //  setFeNotification([""]);
    setNotificationOpen(false)
  }

  const confirmDeleteLink = () => {
    setDeleteModalOpen(true);
    closeNotificationModal();
  }

  return (
    <div className="guest-panel-wrapper show-viewer" >
      <nav className="main-menu-right-chat" id="guest-invite-tray">
        <ProgressLoader loading={loading} />
        <div className='chat-h1'>
          <div className='guest-invite-header'>
            <Modal className='guest-invite-notification-modal' centered={true} open={open} closable={false} footer={null}>
              <div className='newguest-invite-modal'>
                <div className='guest-content'>
                  Do you want to generate guest viewer link & Passcode ?
                </div>
                <div className='guest-action-buttons'>
                  <button className='guest-button' onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                  <button className='guest-button' onClick={createGuestLink}>
                    Yes
                  </button>
                </div>
              </div>
            </Modal>
            <Modal open={uRLModalOpen} className='guest-invite-notification-modal' centered={true} closable={false} footer={null}>
              {url ? (
                <div className='newguest-invite-modal'>
                  <div className='guest-content'>
                    Successfully generated link & Passcode <br />
                    <br />
                    <span>URL: {url}</span>
                    <br />
                    <span>Passcode: {pin}</span>
                    <br />
                  </div>
                  <div className='guest-action-buttons'>
                    <button
                      className='guest-button'
                      onClick={() => {
                        setURLModalOpen(false);
                        setLoading(false);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              ) : (
                <div className='guest-invite-loader'>
                  <ProgressLoader loading={true} />
                </div>
              )}
            </Modal>
            <Modal
              open={deleteModalOpen}
              closable={false}
              className='guest-invite-notification-modal'
              centered={true}
              footer={null}
            >
              <div className='newguest-invite-modal'>
                <div className='guest-content'>Are you sure you want to delete link?</div>
                <div className='guest-action-buttons'>
                  <button className='guest-button' onClick={() => setDeleteModalOpen(false)}>
                    Cancel
                  </button>
                  <button className='guest-button' onClick={deleteGuestURL}>
                    Yes
                  </button>
                </div>
              </div>
            </Modal>
            {!url ? (
              <Tooltip title={'Please click here to generate link'} placement='left'>
                <button
                  className={'guest-invite-modal-trigger-btn'}
                  disabled={!!url}
                  onClick={() => setOpen(true)}
                >
                  +
                </button>
              </Tooltip>
            ) : (
              <button className={'guest-invite-modal-trigger-btn-disabled'} disabled={!!url}>
                +
              </button>
            )}
            <span className="chat-text">Guest List</span>
            <Badge count={!!url ?  (notificationCount) : 0}><div style={{marginTop:12}} onClick={openNotificationModal}>{notificationIcon}</div></Badge> 
          </div>
        </div>

        <div className="guest-invite-content">
          <div className="publisher-panel-content">
            {!!url && !!pin ? (
              <div className="guest-viewer-url-container">
                <div className="guest-viewer-url-container-item">
                  {!!url && (
                    <span>
                      <b>Link:</b>&nbsp;
                      <i>{`${
                        currentDate >= expDate ? "Expired" : "Active"
                      }`}</i>
                    </span>
                  )}
                </div>
                <div className="guest-viewer-url-container-item">
                  {!!url && (
                    <span>
                      {!linkexpired? (
                        <GuestTimer
                          expiryTime={expDate}
                          currentDate={currentDate}
                          setLinkExpired={setLinkExpired}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div className="guest-viewer-url-container-hidden"></div>
            )}
            {!!url && !!pin ? (
              <div className="guest-viewer-url-container">
                <div className="guest-viewer-url-container-item">
                  <Popup
                    trigger={
                      <div className="copy-icon">
                        <img
                          alt="Copy URL"
                          src={guestLinkCopy}
                          onClick={() => {
                            handleCopy(url, pin);
                          }}
                        />
                      </div>
                    }
                    content={
                      currentDate >= expDate
                        ? "Link expired please delete link "
                        : "Copy link and passcode"
                    }
                    position="right center"
                  />
                  <div>
                    <input
                      className={
                        currentDate >= expDate
                          ? "url-holder-expired"
                          : "url-holder"
                      }
                      value={url || ""}
                      disabled
                    />
                    <br />
                    <input
                      className={
                        currentDate >= expDate
                          ? "passcode-holder-expired"
                          : "passcode-holder"
                      }
                      value={`Passcode: ${pin}`}
                      disabled
                    />
                  </div>
                </div>
                <div className="guest-viewer-url-container-item">
                  {!!url && !!pin ? (
                    <Popup
                      trigger={
                        <img
                          className="delete_image"
                          src={Delete}
                          alt="delete-icon"
                          onClick={() => setDeleteModalOpen(true)}
                        />
                      }
                      content="Delete link and passcode"
                      position="left center"
                    />
                  ) : null}

                  {!!url && !!pin ? (
                    <div className="guest-user-status-circle">
                      <div
                        className={
                          currentDate >= expDate
                            ? "guest-url-status-expired"
                            : "guest-url-status-active"
                        }
                      ></div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="guest-viewer-url-container">
                No link created yet
              </div>
            )}

            <div className="guest-viewer-url-container">
              <div className="guest-viewer-url-container-item">
                <div>Auto Accept Guests</div>
              </div>
              <div className="guest-viewer-url-container-item">
                {globalGuestAutoAllow ? (
                  <span
                    className="allowGuestToggleOn"
                    style={{
                      color: 'gray',
                      opacity: '0.6',
                      cursor: 'not-allowed',
                    }}
                  ></span>
                ) : (
                  <Popconfirm
                    title={auto_allow === "false" ?
                      "Do you want to turn ON Auto Allow" : "Do you want to turn OFF Auto Allow"}
                    onConfirm={handleAutoAllow}
                    placement={"topLeft"}>
                    <span
                      className={
                        auto_allow === "true"
                          ? "allowGuestToggleOn"
                          : "allowGuestToggleOff"
                      }
                    ></span></Popconfirm>
                )}
              </div>
            </div>
            <GuestUsersList
              room={troom}
              auto_allow={auto_allow}
              guestInviteCount={guestInviteCount}
              setRedIcon={setRedIcon}
              setGuestStatusCheck={setGuestStatusCheck}
              guestStatusCheck={guestStatusCheck}
            />
            <WarningModal openFlag={notificationOpen} content={!!url ? feNotification :[]} onCancel={closeNotificationModal} onOk={!!url ? confirmDeleteLink :closeNotificationModal} />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default GuestInvite;
