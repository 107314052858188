import { useState, useContext, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
  Button,
  Form,
  Input,
  Space,
  Upload,
  Select,
  DatePicker,
  message,
  Switch,
  Modal,
  InputNumber
} from 'antd';
import { get } from '../../Utils/helpers';
import api from '../../Service/Api';
import backend from '../../Service/Backend';
import { AuthContext } from '../../Context/authContext';
import { uploadData } from './actions';
import ProgressLoader from './ProgressLoader';
import axios from 'axios';
import { logEvent } from '../../Service/LogEvent';
import { EVENT_TYPES } from '../../Utils/constants';
import InputComponent from '../UIKit/InputComponent';
import moment from 'moment';
import ConfirmationModal from '../UIKit/ConfirmationModal/ConfirmationModal';
import Password from 'antd/es/input/Password';
import React from 'react';
import { formatFileSize } from '../../Utils/helpers';

const normFile = (e: any) => {
  if (Array.isArray(e)) return e;
  return e?.fileList;
};

let etagArray: any = [];
let progress: number = 0;
function SendFiles(props: any) {
  const [form] = Form.useForm();
  const { show_id, usersList, showName } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [folderList, setFolderList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(true);
  const [loader, setLoader] = useState(false);
  const [awsRequestData, setAwsRequestData] = useState([]);
  const [userRequestData, setUserRequestData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const authValue = get(useContext(AuthContext), 'currentUser.user', {});
  const [messageApi, contextHolder] = message.useMessage();
  const [validChange, setValidChange] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [masvId, setMasvId] = useState('');
  const [uploadedData, setUploadedData] = useState<number>(0);

  const disabledDate = (current: any) => {
    const dateRange = new Date(); // Now
    dateRange.setDate(dateRange.getDate() + 5); // Set now + 5 days as the new date
    return (current && current < new Date()) || (current && current > dateRange);
  };
  const handleSuccess = () => {
    Modal.success({
      title: 'Successfully uploaded the file.',
      footer: (
        <Button className='okay-upload-btn' onClick={() => {
          Modal.destroyAll();
          progress = 0;
          setUploadProgress(progress);
          setUploadStatus('');
          setUploadedData(0);
        } }>
          Ok
        </Button>
      ),
    });
    setFileList([]);
    setFolderList([]);
    setValidChange(false);
    setPasswordChecked(true);
  };

  const sendEmail = async (finalizeFileData: any, packageName: string) => {
    try {
      let fileName = "";
      let totalSize = "";
      if (Array.isArray(finalizeFileData)) {
        fileName = finalizeFileData.map((fileItem: any) => fileItem.fileName).join();
        totalSize = finalizeFileData.reduce((acc, item) => acc + item.size, 0);
      }
      const input = {
        mailType: "uploadFailure",
        showName: showName, 
        package_name: packageName,
        fileName: fileName,
        fileSize: totalSize
      };
  
      const sendEmail = {
        api: api.massive.superAdminNotificationEmail,
        payLoad: JSON.stringify(input),
      };
      await backend.save(sendEmail, get(authValue, 'accessToken', ''));
      console.log('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  

  const handleError = (error?: any) => {
    setUploadStatus('exception');
    setUploadProgress(100);
    let errorMessage;
  
    if (error && error.name === 'CanceledError') {
      errorMessage = error.message || 'Request canceled';
    } else if (error && error.errMessage) {
      errorMessage = error.errMessage;
    }
    errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
    if (!errorMessage.endsWith('.')) {
      errorMessage += '.';
    }
    Modal.error({
      title: (
        <div>
          <strong>Failed to upload the file:</strong>
          <br />
          <span style={{ color: 'red' }}>{errorMessage}</span>
        </div>
      ),
      footer: (
        <Button className='okay-upload-btn' onClick={() => {
          Modal.destroyAll();
          progress = 0;
          setUploadProgress(progress);
          setUploadStatus('');
          setUploadedData(0);
        } }>
          OK
        </Button>
      ),
    });
    setFileList([]);
    setFolderList([]);
    form.resetFields();
    setValidChange(false);
    setPasswordChecked(true);
  };

  const onFinish = async (values: any) => {
    const startTime = new Date().getTime();
    let success = false;
    let finalizeFileData;
    let packageName = '';
    let errorMessage = '';
    let masvID;
    try {
      setUploading(true);
      progress = progress + 3;
      setUploadProgress(progress);
      const {
        package_name,
        passwordValue,
        recipients,
        upload,
        uploadFolder,
        description,
        expiry,
        access_count,
      } = values;
      packageName = values.package_name;
      let uploadDetails: any = [];
      if (!upload || !upload.length) {
        uploadDetails.push(...uploadFolder);
      } else {
        uploadDetails.push(...upload);
      }

      let password = null;
      if (passwordChecked) {
        password = passwordValue;
      }
      let requestData: any = [];
      const uploadFiles = uploadDetails.map((file: any) => {
        return {
          rawFile: file,
          file: get(file, 'originFileObj', ''),
          fileName: get(file, 'originFileObj.name', ''),
          size: get(file, 'originFileObj.size', ''),
        };
      });

      finalizeFileData = uploadFiles.map((file: any) => {
        return {
          fileName: get(file, 'fileName', ''),
          size: get(file, 'size', ''),
        };
      });

      const fileSize = finalizeFileData
        .map((fileItem: any) => fileItem.size)
        .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
      let cancelTokenSource = axios.CancelToken.source();
      const uploadPayload = {
        api: api.massive.upload,
        cancelToken: cancelTokenSource.token,
        payLoad: JSON.stringify({
          name: package_name,
          password,
          recipients: recipients.map((rec: string) => JSON.parse(rec)),
          uploadFiles: uploadFiles,
          show_id,
          description: description || '',
        }),
      };
      requestData.push({ source: cancelTokenSource });
      setUserRequestData(requestData);
      const uploadURLResult = await backend.save(
        uploadPayload,
        get(authValue, 'accessToken', ''),
      );

      progress = progress + 7;
      setUploadProgress(progress);
      masvID = get(uploadURLResult, 'masvId', '');
      setMasvId(masvID);
      const urlArr = get(uploadURLResult, 'url', []);
      if (!urlArr.length) {
        handleError();
        return;
      }
      const newuploadFiles: any = [];
      urlArr.forEach((item: any, key: any) => {
        item.rawFile = uploadFiles[key]?.rawFile;
        newuploadFiles.push(item);
      });
      const uploadActionResult: any = [];
      for (const value of newuploadFiles) {
        progress = 10 + Number(sessionStorage.getItem('progress'));
        await uploadData(
          get(value, 'url', []),
          get(value, 'rawFile', ''),
          setUploadProgress,
          progress,
          etagArray,
          fileSize,
          setAwsRequestData,
          uploadedData,
          setUploadedData
        ).then((response: any) => {
          uploadActionResult.push(response);
          etagArray.length = 0;
        });
      }

      const chunk_extras = uploadActionResult.map((item: any) => {
        let sorted_chunk_extras = item.chunk_extras.sort((a: any, b: any) => {
          const partNumberA = parseInt(a.partNumber);
          const partNumberB = parseInt(b.partNumber);
          return partNumberA - partNumberB;
        });
        return { chunk_extras_data: sorted_chunk_extras, endByte: item.endByte };
      });

      sessionStorage.removeItem('progress');
      cancelTokenSource = axios.CancelToken.source();

      const finalizePayload = {
        api: api.massive.finalize,
        cancelToken: cancelTokenSource.token,
        payLoad: JSON.stringify({
          chunk_extras,
          recipients: recipients.map((rec: string) => JSON.parse(rec)),
          expiry: expiry.toISOString(),
          masvId: masvID,
          access_count,
          show_id,
          finalizeFileData: finalizeFileData,
        }),
      };

      requestData.push({ source: cancelTokenSource });
      setUserRequestData(requestData);
      const finalizeResult = await backend.save(
        finalizePayload,
        get(authValue, 'accessToken', ''),
      );
      if (finalizeResult) {
        progress = 100;
        setUploadedData(finalizeFileData.reduce((acc:number,{ size }:any) => acc+ size,0));
        setUploadProgress(progress);
      }
      console.log('finalizeResult', finalizeResult);
      handleSuccess();
      success = true;
      form.resetFields();
    } catch (error:any) {
      console.error('Error: ', error);
      errorMessage = error.errMessage;
      handleError(error);
      await sendEmail(finalizeFileData, packageName);
    } finally {
      const endTime = new Date().getTime();
      const timeTakenMilliseconds = endTime - startTime;
      const timeTaken = moment.duration(timeTakenMilliseconds);
      const hours = timeTaken.hours();
      const minutes = timeTaken.minutes();
      const seconds = timeTaken.seconds();
      const fileUploadDetails: any = {
        finalizeFileData,
        show_id,
        packageName,
        totalTimeTaken: `${hours}h ${minutes}m ${seconds}s`,
        senderID: get(authValue, 'uid', ''),
        masvId: masvID,
      };
      if (success) {
        fileUploadDetails.successMessage = 'Upload successful';
        const fileUploadSuccessEvent = logEvent(
          get(authValue, 'accessToken', ''),
          EVENT_TYPES.FILE_UPLOAD_SUCCESS,
          fileUploadDetails,
        );
        setValidChange(false);
        form.setFieldValue('PasswordCheck', true);
        console.log('FileUpload:', fileUploadSuccessEvent);
      } else {
        fileUploadDetails.errorMessage = errorMessage;
        const fileUploadFailureEvent = logEvent(
          get(authValue, 'accessToken', ''),
          EVENT_TYPES.FILE_UPLOAD_FAILURE,
          fileUploadDetails,
        );
        console.log('FileUpload:', fileUploadFailureEvent);
      }
      setUploading(false); 
    }
  };

  const uploadProps: UploadProps = {
    onRemove: (file) => {
    const newFileList = fileList.filter((item:any)=> item.uid !== file.uid )
      setFileList(newFileList);
      if(!newFileList.length)form.validateFields(['upload', 'uploadFolder']);
    },
    beforeUpload: (file) => {
      let arr:any=[];
      arr = fileList;
      arr.push(file);
    
      setFileList([...arr]);
      form.validateFields(['upload', 'uploadFolder']);
      // setFileList([...fileList, file]);
      return false;
    },
    fileList:fileList,
    multiple:true
  };

  const handleDeleteConfirm = () => {
    setDeleteAlertOpen(false);
    form.setFieldValue('uploadFolder', [] );
    form.validateFields(['upload', 'uploadFolder']);
    setFolderList([]);
    const allValues = form.getFieldsValue()
    onFieldValChange(null, allValues);
  };

  const handleDeleteCancel = () => {
    setDeleteAlertOpen(false);
  };

  const renderPopup = () => (
    <div>
      <b>Are you sure you want to delete the selected folder?</b>
    </div>
  );

  const uploadFolderProps: UploadProps = {
    directory: true,
    onRemove: (file) => {
      setDeleteAlertOpen(true);
    },
    beforeUpload: (file) => {
      let arr: any = [];
      arr = folderList;
      arr.push(file);
      setFolderList([arr]);
      form.validateFields(['upload', 'uploadFolder']);
      return false;
    },
    fileList: get(folderList, '0', []),
  };
  

  const passwordCheck = (checked: boolean) => {
    setPasswordChecked(checked);
    console.log("true",checked)
    if (!checked) {
      form.setFieldsValue({ passwordValue: '' });
    }
  };

  const validPassword = (e: any) => {
    const val = e.target.value.trim()
    form.setFieldValue('passwordValue',val);
    form.validateFields(['passwordValue'])
    onFieldValChange(null, form.getFieldsValue())
  }

  const validAccessLimit = (value: any) => {
    const val = value.trim();
    form.setFieldValue('access_count',val);
    form.validateFields(['access_count']);
  }

  const generatePassword = async () => {
    try {
      setLoader(true);
      const input = {
        api: api.massive.generatePassword,
        urlParam: show_id,
      };
      const result = await backend.fetch(input, get(authValue, 'accessToken', ''));
      form.setFieldsValue({ passwordValue: result });
      const allValues = form.getFieldsValue()
      onFieldValChange(null, allValues);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoader(false);
    }
  };
  const onFieldValChange = (changedValues:any, allValues:any) => {
    let acc_limit_count = parseInt(get(allValues, 'access_count', 0));
    let isValid =
      get(allValues, 'recipients', []).length &&
      get(allValues, 'package_name', '') &&
      (acc_limit_count <= 3 && acc_limit_count > 0) &&
      get(allValues, 'expiry', '') &&
      (get(allValues, 'upload', []).length || get(allValues, 'uploadFolder', []).length);

    if (get(allValues, 'PasswordCheck', false)) {
      isValid = isValid && get(allValues, 'passwordValue', '');
    }
    setValidChange(!!isValid);
  }

  const handleCancel = async () => {
    try {
      if (masvId) {
        const data = {
          api: api.massive.deleteUploadDoc,
          urlParam: masvId,
        };
        const result = await backend.remove(data, get(authValue, 'accessToken', ''));
        console.log("result ", result);
      }
      setUploadProgress(0);
      form.resetFields();
      setFileList([]);
      setFolderList([]);
      setValidChange(false);
      setPasswordChecked(true);
      awsRequestData.forEach((item: any) => {
        item.source.cancel('Request canceled');
      });
      userRequestData.forEach((item: any) => {
        item.source.cancel('Request canceled');
      });
    } catch (error) {
      console.error('Error in handleCancel:', error);
    }
  };
  
  const userListDropdown = usersList.map((user: any) => {
    const { user_name, user_email, user_id } = user;
    const { user_name_first, user_name_last } = user_name;
    return ({
        value: JSON.stringify({ id: user_id, email: user_email, fName: user_name_first, lName: user_name_last }),
        label: `${user_name_first} ${user_name_last} (${user_email})`.toLowerCase()
    })});
    const filesUploaded = fileList.length > 0 || folderList.length > 0;
    const getFileRules = (str:string) => [{ required: !filesUploaded, message: `Please upload a ${str}!` }]
    const downloadLimitDropdown = [
      { 
        key : '1',
        value : '1'
      },
      {
        key : '2',
        value : '2',
      },
      {
        key : '3',
        value : '3'
      }
      ]
  return (
    <>
      <div className=''>
        <p className='heading-text-mailbox send-file-header'>Send Files</p>
      </div>
      <div className='send-file-container'>
        <Form
          name='basic'
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, margin: '3% 0', width: '500px' }}
          initialValues={{ PasswordCheck: true }}
          onFinish={onFinish}
          autoComplete='new-password'
          onReset={() => {
            setFileList([]);
            setFolderList([]);
            progress = 0;
            setUploadProgress(progress);
            setUploadStatus('');
            setValidChange(false);
            form.resetFields();
            form.setFieldValue('PasswordCheck', true);
            setPasswordChecked(true);
            
          }}
          onValuesChange={onFieldValChange}
        >
          <Form.Item
            name='recipients'
            label='Recipients'
            rules={[{ required: true, message: 'Please select recipients!' }]}
          >
            <Select
              mode='multiple'
              showSearch={true}
              optionFilterProp='children'
              placeholder='Select recipients'
              filterOption={
                // @ts-ignore
                (input, option) =>
                  get(option, 'label', '').toLowerCase().includes(input.toLowerCase())
              }
              options={userListDropdown}
            />
          </Form.Item>
          <Form.Item
            label='Package Name'
            name='package_name'
            rules={[{ required: true, message: 'Please enter the filename!' }, { max: 20 }]}
          >
            <InputComponent/>
          </Form.Item>

          <Form.Item
            label='Download Limit'
            name='access_count'
            className='send-files-input'
            rules={[{ required: true, message: 'Please select download limit!' }]}
          >
            <Select
              optionFilterProp='children'
              className='download-limit-drop-down'
              placeholder='Select download limit'
              filterOption={
                // @ts-ignore
                (input, option) =>
                  get(option, 'label', '').toLowerCase().includes(input.toLowerCase())
              }
              options={downloadLimitDropdown}
            />
          </Form.Item>

          <Form.Item
            label='Expiration Date'
            name='expiry'
            rules={[{ required: true, message: 'Please input your expiry date!' }]}
          >
            <DatePicker
              disabledDate={disabledDate}
              showToday={false}
              autoComplete='off'
              placeholder='Select a date'
              onFocus={(e: { target: { blur: () => any } }) => e.target.blur()} 
            />
          </Form.Item>

          <Form.Item label='Enable Password Protection' name='PasswordCheck' className='password-enable-switch'>
            <Switch className='password-protection' onChange={passwordCheck} checked={passwordChecked} defaultChecked />
          </Form.Item>
          {passwordChecked && (
            <>
              <Form.Item
                label='Password'
                name='passwordValue'
                rules={[{ required: passwordChecked, message: 'Please enter your password!' }]}
              >
                <Input.Password autoComplete='new-password' onChange={validPassword} maxLength={10} />
              </Form.Item>
              <div className='gen-pass'>
                <Button
                  type='link'
                  className='gen-pass-btn'
                  loading={loader}
                  onClick={generatePassword}
                >
                  Generate Password
                </Button>
              </div>
            </>
          )}

          <Form.Item label='Description' name='description'>
            <InputComponent maxLength={50} />
          </Form.Item>

          <div className='flex-Container'>
            <Form.Item
              name='upload'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              rules={getFileRules('file')}
            >
              <Upload {...uploadProps} className='upload-file'>
                <Button
                  className='add-Files-Btn'
                  icon={<UploadOutlined />}
                  disabled={folderList.length !== 0}
                >
                  Add Files{' '}
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name='uploadFolder'
              valuePropName='folderList'
              getValueFromEvent={normFile}
              rules={getFileRules('folder')}
            >
              <Upload {...uploadFolderProps} className='upload-file'>
                <Button
                  className='add-Folder-Btn'
                  icon={<UploadOutlined />}
                  disabled={folderList.length !== 0 || fileList.length !== 0}
                >
                  Add Folders
                </Button>
              </Upload>
            </Form.Item>
          </div>

          <Form.Item wrapperCol={{ span: 18, offset: 10 }} style={{ marginTop: '8%' }}>
            <Space>
              <Button
                type='primary'
                htmlType='submit'
                loading={uploading}
                className={`upload-btn ${!validChange ? 'btn-disabled' : ''}`}
                disabled={!validChange}
              >
                <b>Upload</b>
              </Button>
              <Button htmlType='reset'>
                {' '}
                <b>Reset</b>
              </Button>
            </Space>
          </Form.Item>
        </Form>
        {contextHolder}
      </div>

      <Modal
        title={`Uploading File`}
        open={!!uploadProgress}
        maskClosable={false}
        closable={false}
        className='progress-modal'
        footer={
          <Button
            className='cancel-upload-btn'
            onClick={handleCancel}
          >
            Cancel
          </Button>
        }
      >
        <div className='progress-loader-container'>
          <span className='status-text'> Please do not leave this page. </span>
          <span className='status-text'> Upload is in progress, Please wait.</span>
          <br />
          <ProgressLoader progress={Math.floor(uploadProgress)} status={uploadStatus} />
          <br />
          <span className='status-text'> Uploaded:{formatFileSize(uploadedData)}</span>
        </div>
      </Modal>
      <ConfirmationModal
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        data={renderPopup()}
        open={deleteAlertOpen}
      />
    </>
  );
}

export default SendFiles;
